import React from 'react';
import {Route} from 'react-router-dom';
import AltaMasiva from "../../../components/mantenimiento/AltaMasiva";
import Facturacion from "../../../components/mantenimiento/Facturacion";
import MetodoPago from "../../../components/mantenimiento/MetodoPago";
const MantenimientoRoutes = ({path}) =>{

    const BajaMasiva = () =>{
        return(
        <h2>Baja masiva</h2>)
    }

    return(
        <>
            <Route path={`${path}/alta-masiva`} component={AltaMasiva}/>
            <Route path={`${path}/baja-masiva`} component={BajaMasiva}/>
            <Route path={`${path}/facturacion`} component={Facturacion}/>
            <Route path={`${path}/metodos-pago`} component={MetodoPago}/>



        </>
    )
}
export default MantenimientoRoutes;