const optionsContract = [
  {
    value: 'Contrato de trabajo por tiempo indeterminado',
    label: 'Contrato de trabajo por tiempo indeterminado',
  },
  {
    value: 'Contrato de trabajo para obra determinada',
    label: 'Contrato de trabajo para obra determinada',
  },
  {
    value: 'Contrato de trabajo por temporada',
    label: 'Contrato de trabajo por temporada',
  },
  {
    value: 'Contrato de trabajo sujeto a prueba',
    label: 'Contrato de trabajo sujeto a prueba',
  },
  {
    value: 'Contrato de trabajo con capacitación inicial',
    label: 'Contrato de trabajo con capacitación inicial',
  },
  {
    value: 'Modalidad de contratación por pago de hora laborada',
    label: 'Modalidad de contratación por pago de hora laborada',
  },
  {
    value: 'Modalidad de trabajo por comisión laboral',
    label: 'Modalidad de trabajo por comisión laboral',
  },
  {
    value: 'Modalidades de contratación donde no existe relación de trabajo',
    label: 'Modalidades de contratación donde no existe relación de trabajo',
  },
  {
    value: 'Jubilación, pensión, retiro',
    label: 'Jubilación, pensión, retiro',
  },
];

export default optionsContract;
