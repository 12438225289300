import React from 'react';
import yisus from '../../assets/img/yisus.jpg';
import './ColaboradorCard.css';
import { useSelector } from 'react-redux';
import {
  Nombre,
  JobPosition,
  InfoHeader,
  InfoHeaderBlue,
  BackgroundImageDetail,
  SectionColaborator,
} from './styles/styledColaboradorCard';

const DetailCardSucursalCard = React.memo(() => {
  const { logotipo, informacionLegal} = useSelector(
    (state) => state.empresas
  );
  const { nombreSucursal,numeroColaboradores=0} = useSelector(
    (state) => state.sucursal
  );
  const { listaDepartamentos } = useSelector((state) => state.departamentos);
  return (
    <SectionColaborator className='colaborador-container'>
      <BackgroundImageDetail
        imageUrl={
          logotipo
            ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
            : yisus
        }
      />
      <div className='description'>
        <Nombre>{nombreSucursal}</Nombre>
        <JobPosition>{informacionLegal? informacionLegal.razon_social : ''}</JobPosition>
        <br /><br /><br />
        <div className='info'>
          <div className='id'>
            <InfoHeader>#Departamentos</InfoHeader>
            <InfoHeaderBlue>{listaDepartamentos.length}</InfoHeaderBlue>
          </div>
          <div className='salary'>
            <InfoHeader>#Colaboradores</InfoHeader>
            <InfoHeaderBlue>{numeroColaboradores}</InfoHeaderBlue>
          </div>
          {/* <div className='salary'>
            {    <InfoHeader>Salario</InfoHeader>
              <InfoHeaderGrey>K</InfoHeaderGrey> }*/}
          </div> 
        </div>
    </SectionColaborator>
  );
});


export default DetailCardSucursalCard;