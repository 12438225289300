import React from 'react';
import { Tr } from '../../VacacionesViews/styled';
import moment from 'moment';
import ButtonDowload from '../../../colaboradores/Forms/FormExpedienteDigital/ButtonDownload';

const TableBody = React.memo(({ arrayInfo = [] }) => {
  
  return (
    <>
      {arrayInfo.map((info) => (
        <Tr
          key={info.id}
        >
          <td style={{ width: '20%',paddingTop:'22px' }}>{moment(info.fecha).format('DD/MM/YYYY')}</td>
          <td style={{ width: '60%',paddingTop:'22px' }}>{info.nombre}</td>
          <td style={{ width: '20%' }}>{<ButtonDowload
            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${info.id}`}
          />}</td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;
