import React,{useEffect,useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";
import {Row, Col, Button} from "reactstrap";
import Search from "../ui/Search";
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import TableBody from './Finiquitos/TableBody';
import {fetchListaFiniquito,buscarFiniquito} from "../../store/finiquitosDuck";
import colors from '../../constans/colors';

const JuridicoFiniquitos = () =>{
    const dispatch = useDispatch();
	const history = useHistory();
	const { listaFiniquitos = [] } = useSelector(
	  (state) => state.finiquitos
	);
    const onSearch = useCallback(text => {
        dispatch(buscarFiniquito(text))
    })
	useEffect(() => {
		(async () => {
		  try {
			await dispatch(fetchListaFiniquito());
		  } catch (e) {
			console.log(e);
		  }
		})();
	  }, [dispatch]);
    return (
        <>
        <HeaderStyle style={{marginBottom:50}}>
            <h1>Finiquitos</h1>
            <h3>Encuentra aquí tus finiquitos</h3>
        </HeaderStyle>
        <Row>
            <Col lg={12}>
                <Search onChange={onSearch} placeholder="Buscar por nombre"/>
            </Col>
            {/* <Col lg={2}>
                <Button style={{
                    fontFamily:'Roboto',
                    height:'59px',
                    padding:'15px',
                    width:'170px',
                    background:'#2B388F'
                }}
                >En Proceso</Button>
            </Col> */}
        </Row>
        <TableContainer style={{ marginTop:10}}>
            <TableCommon 
                keysAceepted={['Colaborador', 'Fecha', 'Autoridad laboral', 'Ciudad / Edo','','Estatus']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
            >
            <TableBody listaFiniquitos={listaFiniquitos} />
            </TableCommon>
            <Button type="button" onClick={()=>{
                history.push('nuevo-finiquito')
            }} 
            style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}>+ Agregar</Button>
        </TableContainer>
        </>
    )
}
export default JuridicoFiniquitos;