import React, {useCallback} from 'react';
import colors from '../../../constans/colors';
import { Tr } from './styled';
import moment from 'moment';
import { agregarVaca } from '../../../store/vacacionesDucks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const TableBody = React.memo(({listaVacaciones = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((vacacion) => {
        dispatch(agregarVaca(vacacion))
        history.push('/vacaciones')
    }, [dispatch]);
    return(
        <>
            {listaVacaciones.map((vacaciones, index) => (
              
          <Tr
            key={vacaciones._id + index}
            onClick={() => showUtil(vacaciones)}
          >
            <td style={{paddingLeft:40}}>{vacaciones.anosTrabajando+' '}
              {vacaciones.anosTrabajando > 1? 'Años' : 'Año'}</td>
            <td>{vacaciones.periodos? vacaciones.periodos.length : 0}</td>
            <td>{vacaciones.numeroDiasPendientes}</td>
            <td>{vacaciones.notas}</td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;