import React, {useCallback,useState} from 'react';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { agregarPago } from '../../../store/finiquitosDuck';
import CurrencyFormat from 'react-currency-format';
import ModalFiniquitoEdit from './ModalFiniquitoEdit';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../constans/colors';


const TableBodyPagos = React.memo(({listaFiniquitos,setListaFiniquitos,montoConvenio,setMontoConvenio}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    const [i,setI] = useState('');
    console.log(listaFiniquitos);
    const showUtil = async (f,index) => {
        setI(index)
        setData(f)
        await dispatch(agregarPago(f));
        setIsOpen(true);
    };
    const deleteConvenio = async (c,index) => {
        const removed = listaFiniquitos.splice(index,1);
		await setListaFiniquitos([...listaFiniquitos]);	
    }

    return(
        <>
        <ModalFiniquitoEdit 
            isOpen={isOpen} 
            setIsOpen={setIsOpen} 
            data={data} 
            index={i}
            listaFiniquitos={listaFiniquitos}
            setListaFiniquitos={setListaFiniquitos} 
            montoConvenio={montoConvenio}
            setMontoConvenio={setMontoConvenio}
        />
            {listaFiniquitos.map((f, index) => (
              
          <Tr
            key={f.index}
          >
            <td onClick={() => showUtil(f,index)}>{index + 1}</td>
            <td onClick={() => showUtil(f,index)}>{
                <CurrencyFormat
                    value={f.monto}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={value => <span>{value}</span>}
                />
            }</td>
            <td onClick={() => showUtil(f,index)}>{moment(f.fecha).format('DD/MM/YYYY')}</td>
            <td onClick={() => showUtil(f,index)}>{f.formaPago}</td>
            <td onClick={() => showUtil(f,index)}>{f.recordatorio === 'true' || f.recordatorio === true ? 'Si' : 'No'}</td>
            <td>
				<a href='#' 
				onClick={(e) => deleteConvenio(f,index)} 
				style={{textDecoration:'none',color:colors.tashColor}}
				>
				<FontAwesomeIcon 
					color={colors.tashColor}
					icon={faTrashAlt} 
				/>
				</a>
			</td>
          </Tr>
        ))}
        </>
    );
});

export default TableBodyPagos;