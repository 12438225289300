/*
 * Spects return array like: 
 * [
 *  {name: nombre Colaborador, d0, d1, d2, d3, d4, d5, d6, descanso, total}
 * ] 
 */

const makeTableData = (listaColaboradores, date) =>{
    
    return listaColaboradores.map(c => ({
        key: c.id,
        name: `${c.informacionPersonal.apellidoPaterno} ${c.informacionPersonal.apellidoMaterno}, ${c.informacionPersonal.nombre}`,
        totalDobles: 0,
        totalTriples: 0,
        ...c
    }))
}

export default makeTableData;