import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaUtilitario } from '../../store/utilitarioDucks';
import {
  Switch,
  Route,
  HashRouter,
  useHistory,
  useLocation,
} from 'react-router-dom';
import FormUniforme from './UniformeViews/FormUniforme';
import TablaUniforme from './UniformeViews/Uniformes';
import FirstAddUtil from './UniformeViews/FirstAddUtil';
import ShowUtil from './UniformeViews/ShowUtil';

const Uniformes = () => {
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { listaUtilitarios = [] } = useSelector((state) => state.utilitario);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchListaUtilitario('uniforme'));
      } catch (error) {}
    })();
  }, [dispatch, history, location.pathname]);

  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/agregar-uniforme' component={FormUniforme} />
        <Route path='/tabla-uniforme' component={TablaUniforme} />
        <Route path='/uniforme' component={ShowUtil} />
        {listaUtilitarios.length > 0 ? (
          <Route path='/' component={TablaUniforme} />
        ) : (
          <Route path='/' component={FirstAddUtil} />
        )}
      </Switch>
    </HashRouter>
  );
};

export default Uniformes;
