import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import sweet from "sweetalert2";
import CalcularNomina from "../../components/nomina/CalcularNomina";
import { HeaderStyle, ModalStyle, StyleInputs } from "../../components/ui/styles/StyleNomina";
import { CardEmpresas, TitleMenu } from "../../components/ui/styles/styles";
import { fetchEmpresas } from "../../store/empresasDuck";

const api = process.env.REACT_APP_API_URL;

const MenuEmpresas = ({ title = "", onNavigate }) => {
  const history = useHistory();
  const { empresas = [], loading } = useSelector((state) => state.empresas);
  const { permisos = {} } = useSelector(store => store.user);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const dispatch = useDispatch();
  const [periodo, setPeriodo] = useState({
    periodicidad: "",
    fechaInicio: moment(Date.now()).format("YYYY-MM-DD"),
    fechaFin: moment(Date.now()).format("YYYY-MM-DD"),
  });
  const [idEmpresa, setIdEmpresa] = useState("");
  const [totalesEmpresas, setTotalesEmpresas] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEmpresas());
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!empresas.length) return;
    if (empresas[0].informacionLegal) loadTotales();
  }, [empresas]);

  const loadTotales = async () => {
    let query = `?empresasIds=${empresas.map((e) => e._id).join(",")}`;
    if (title === 'Timbrado') (query += "&timbrado=true")
    let { data } = await axios.get(`${api}/nomina/obtener-totales/${query}`, headers);
    console.log(data.response);
    const totales = data.response.reduce((a, v) => {
      !a[v.empresaId] ? (a[v.empresaId] = [v]) : a[v.empresaId].push(v);
      return a;
    }, {});

    setTotalesEmpresas(
      empresas.map(({ nombre_empresa = "", informacionLegal = {}, _id }) => ({
        totales: totales[_id] ? totales[_id] : [],
        razonSocial: informacionLegal.razon_social,
        _id,
      }))
    );
  };

  const onNav = (_id, status = "", nomina) => {
    const navigate = title === "Timbrado" ? "timbrar-empresa" : "prenomina";
    history.push({ pathname: `${navigate}/${_id}/${status}`, state: nomina || periodo });
  };

  const acceptPeriodicidad = () => {
    onNav(idEmpresa);
    setIdEmpresa("");
  };

  const cambiarStatus = async (nomina, status) => {
    sweet.showLoading();
    await axios.post(`${api}/nomina/estado/?global=true&periodicidad=${nomina.periodicidad}&periodo=${nomina.periodo}&estado=${status}&empresaId=${nomina.empresaId}`, [], headers);
    await loadTotales();
    sweet.close();
  };

  const openDialog = async (nomina, status) => {
    let result = await sweet.fire({
      title: "Nomina",
      text: `Seguro que desea cambiar a ${status}?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      await cambiarStatus(nomina, status);
    }
  };
  const onConsultar = (empresa, nomina) => {
    let [first] = nomina.totales;
    history.push({ pathname: `nomina/${empresa}`, state: first });
  };

  return (
    <>
      <TitleMenu></TitleMenu>
      <HeaderStyle>
        <h1>{title}</h1>
        <h3>Calcula aquí tú nómina</h3>
      </HeaderStyle>
      <Modalperiodicidad onAccept={acceptPeriodicidad} disabled={periodo.periodicidad === ""} onCancel={() => setIdEmpresa("")} toggle={idEmpresa !== ""}>
        <CalcularNomina setPeriodo={setPeriodo} periodo={periodo} />
      </Modalperiodicidad>
      <Row className="px-2">
        {totalesEmpresas.map((e, i) => (
          <Col key={i} className="px-1 col-xl-6 col-lg-12">
            <CardEmpresas className="my-2 text-left">
              <Row>
                <Col className="col-12">
                  <Row>
                    <Col className="col">
                      <h2 style={{ width: "23vw", color: "#404041" }} className="mx-0 mt-2">
                        {e.razonSocial}
                      </h2>
                    </Col>
                    <Col className="col text-right">
                      <StyleInputs>
                        {title !== "Timbrado" && (
                          <Button color="primary" onClick={() => setIdEmpresa(e._id)}>
                            Nueva
                          </Button>
                        )}
                        {title === "Timbrado" && (
                          <Button color="primary" onClick={() => onConsultar(e._id, e)}>
                            Consultar
                          </Button>
                        )}
                      </StyleInputs>
                    </Col>
                  </Row>
                  {e.totales.map((a, it) => {
                    console.log(a)
                    if (title === "Timbrado" && a.detalle.filter((d) => d.estado === "revision").length) {
                      return null;
                    }
                    return (
                      <div key={it}>
                        <hr className="m-2 mt-3" />
                        <div className="mt-4 b" key={it}>
                          <Row style={{ color: "rgb(146, 146, 146)" }}>
                            <Col className="col-4">
                              <h6 style={{ fontSize: "20px" }}>{a.periodicidad}{a.type && ` - ${a.type.toUpperCase()}`}</h6>
                            </Col>
                            <Col className="col-4 text-center">
                              <span style={{ fontSize: "12px" }}>{a.fechaInicio || ''} - {a.fechaFin || ''}</span>
                            </Col>
                            <Col className="col-4 align-self-end text-center">
                              <h6> {a.periodo}</h6>
                            </Col>
                          </Row>
                          {a.detalle.map((det, idet) => {
                            if (det.estado === "revision" && title === "Timbrado") return null;
                            return (
                              <div key={idet}>
                                {det.estado === "revision"}
                                <Row>
                                  <Col className="col-4">
                                    <h6>Colaboradores</h6>
                                    <h6>{det.empleados}</h6>
                                  </Col>
                                  <Col className="col-4 text-center">
                                    <h6>Total a Pagar</h6>
                                    <h6>
                                      {det.total
                                        ? det.total.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        })
                                        : ""}
                                    </h6>
                                  </Col>
                                </Row>
                                <Row>
                                  <StyleInputs>
                                    <Col className="col-2  align-self-start col mt-1">
                                      <h6>Estatus:</h6>
                                    </Col>
                                    <Col className="col-4  align-self-start col">
                                      <h6 style={{ color: "#2b388f", fontSize: "20px", textTransform: "capitalize" }}>{det.estado}</h6>
                                    </Col>
                                    {title !== "Timbrado" && (
                                      <>
                                        {det.estado === "revision" && (
                                          <>
                                            <Col className="col-4 text-right">
                                              <Button size={"sm"} color="primary" onClick={() => onNav(e._id, det.estado, a)} style={{ width: "70px" }}>
                                                Ver
                                              </Button>
                                            </Col>
                                            {permisos.autorizacionNomina === "write" ? (
                                              <Col className="col-2 px-0 ">
                                                <Button size={"sm"} color="primary" onClick={() => openDialog(a, "aprobada")} style={{ width: "70px" }}>
                                                  Aprobar
                                                </Button>
                                              </Col>
                                            ) : null}
                                          </>
                                        )}
                                        {det.estado === "aprobada" && (
                                          <>
                                            {permisos.autorizacionNomina === "write" ? (
                                              <Col className="col-4 text-right">
                                                <Button size={"sm"} color="primary" onClick={() => openDialog(a, "revision")} style={{ width: "70px" }}>
                                                  Revisar
                                                </Button>
                                              </Col>
                                            ) : null}
                                            <Col className={permisos.autorizacionNomina === "write" ? "col-2 px-0 " : "col-4 text-right"}>
                                              <Button size={"sm"} color="primary" style={{ width: "70px" }}>
                                                Layout
                                              </Button>
                                            </Col>
                                          </>
                                        )}
                                      </>
                                    )}
                                    {title === "Timbrado" && (
                                      <Col className="col-2 offset-4 px-0 ">
                                        <Button
                                          size={"sm"}
                                          color="primary"
                                          onClick={() => {
                                            return onNav(e._id, det.estado, { fechaInicio: a.fechaInicio, fechaFin: a.fechaFin, periodicidad: a.periodicidad, periodo: a.periodo, type: a.type })
                                          }
                                          }
                                        >
                                          Timbrar
                                        </Button>
                                      </Col>
                                    )}
                                  </StyleInputs>
                                </Row>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </CardEmpresas>
          </Col>
        ))}
      </Row>
    </>
  );
};

const Modalperiodicidad = ({ children, toggle, onAccept, onCancel, disabled }) => {
  return (
    <Modal centered isOpen={toggle} toggle={onCancel} size={"lg"}>
      <ModalStyle>
        <ModalHeader style={{ background: "#2B388F", color: "white" }} toggle={onCancel}>
          Periodicidad
        </ModalHeader>
      </ModalStyle>
      <ModalBody>{children}</ModalBody>
      <StyleInputs>
        <ModalFooter>
          <Button color="secondary" onClick={onCancel}>
            Cancelar
          </Button>{" "}
          <Button color="primary" onClick={onAccept} disabled={disabled}>
            Aceptar
          </Button>
        </ModalFooter>
      </StyleInputs>
    </Modal>
  );
};

export default MenuEmpresas;
