import React from 'react';
import colors from '../../constans/colors';
import PropTypes from 'prop-types';
import { TitleMenu } from './styles/styles';


const ComponentDescrip = React.memo(
  ({ title = '', description = '', descSize = '', ...propTypes}) => {
    return (
      <div className='row'>
        <div className='col-12 pl-0'>
          <TitleMenu>{title}</TitleMenu>
        </div><br />
        <div className='col-12'>
          <p
            style={{
              color: colors.textColorSecondary,
              fontSize: '21px',
              fontWeight: '600',
              lineHeight: '24px',
              fontFamily: 'Roboto'
            }}>
            {description}
          </p>
        </div>
      </div>
    );
  }
);

ComponentDescrip.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  descSize: PropTypes.string,
};

export default ComponentDescrip;
