import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, FormFeedback, FormText } from 'reactstrap';
import '../colaboradores/PermisosLaboralesViews/PermisosLaborales.css';

const CustomSelect = React.memo(
  ({ options = [], control, defultValue = '', placeholder = 'Seleccione opción', ...rest }) => {
    if (!control || options.length === 0) return <span>...</span>;
  
    return (
      <>
        <Controller
          control={control}
          {...rest}
          defaultValue=''
          render={() => {
            return (
              <Input {...rest} className='shadow-custom'>
                <option value=''>{placeholder}</option>
                {defultValue
                  ? options.map(({ value, label }, index) => {
                      return value ? (
                        <option
                          key={value + index}
                          value={value}
                          selected={defultValue === value ? true : false}
                        >
                          {label}
                        </option>
                      ) : null;
                    })
                  : options.map(({ value, label }, index) => {
                      return value ? (
                        <option key={value + index} value={value}>
                          {label}
                        </option>
                      ) : null;
                    })}
              </Input>
            );
          }}
        />
        <FormFeedback>{rest.error}</FormFeedback>
        <FormText>{rest.formText}</FormText>
      </>
    );
  }
);

export default CustomSelect;
