import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons';
import colors from '../../constans/colors';

const Arrows = React.memo(({ right, down, up, ...props }) => {
  if (down) {
    return (
      <>
        <FontAwesomeIcon
          color={colors.iconsColors}
          icon={faAngleDown}
          style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          {...props}
        />
      </>
    );
  }
  return (
    <>
      {right ? (
        <FontAwesomeIcon
          color={colors.iconsColors}
          icon={faAngleRight}
          style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          {...props}
        />
      ) : (
        <FontAwesomeIcon
          color={colors.iconsColors}
          icon={faAngleLeft}
          style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          {...props}
        />
      )}
    </>
  );
});

export default Arrows;
