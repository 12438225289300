import React, { useState, useEffect } from "react";
import { Input, Col, Row, Label, FormGroup, Form, Table } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../services/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";
import InputCurrency from "react-currency-format";

export default ({ label, empresaId, colaboradorId, prestacionesOrdinarias = [], type = "", addPrestacion, onDeletePrestacion, onChange }) => {
  const [paquetes, setPaquetes] = useState([]);
  const [prestaciones, setPrestaciones] = useState([]);
  const [prestacion, setPrestacion] = useState("");
  const [paquete, setPaquete] = useState("");
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token")
    }
  }

  useEffect(() => {
    getPrestaciones();
    getPaquetes();
  }, []);

  const getPrestaciones = async () => {
    let query = `sat=true&catalogo=${type}`
    if (type === 'percepciones') query += '&tipo=Ordinaria'
    const { data } = await axios.get(`catalogos/?${query}`, headers);
    setPrestaciones(data.response);
  };

  const getPaquetes = async () => {
    const { data } = await axios.get(`/nomina/paquetes/prestaciones/ordinarias/${empresaId}/${colaboradorId}/?prestacion=${type}`, headers);
    setPaquetes(data.response);
  };

  const addOrdinaria = (codigo) => {
    if (!prestacionesOrdinarias.map((d) => d.codigo).includes(codigo) && codigo !== "") {
      const item = prestaciones.find((c) => c.codigo === codigo);
      addPrestacion(item, type);
    }
  };

  const onDelete = async (i) => {
    let result = await sweet.fire({
      title: "Borrar ",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      onDeletePrestacion(i, type);
    }
  };

  const addPaquete = (_id) => {
    if (_id === "") return;
    const item = paquetes.find((v) => v._id === _id);
    item.prestaciones.map((p) => {
      addPrestacion(p, type);
    });
  };

  return (
    <>
      <Form>
        <FormGroup row>
          <Col sm={12}>
            <h5 className="px-1 py-3">{label} </h5>
          </Col>
          <Col md={5}>
            <Input type="select" value={paquete} onChange={(e) => addPaquete(e.target.value)}>
              <option value="">Paquetes..</option>
              {paquetes.map((p) => (
                <option value={p._id} key={p._id}>
                  {p.nombre}
                </option>
              ))}
            </Input>
          </Col>
          <Col md={7}>
            <Input type="select" value={prestacion} onChange={(e) => addOrdinaria(e.target.value)}>
              <option value="">{label}..</option>
              {prestaciones.map((p) => (
                <option value={p.codigo} key={p.codigo}>
                  {p.descripcion}
                </option>
              ))}
            </Input>
          </Col>
        </FormGroup>
        <Row>
          <Col>
            <Table striped>
              <thead>
                <tr>
                  <th>Codigo</th>
                  <th style={{ width: "500px" }}>Nombre</th>
                  <th>Monto</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {prestacionesOrdinarias.map((item, i) => (
                  <tr key={i}>
                    <td>{item.clave || item.codigo}</td>
                    <td>{item.nombreCorto || item.descripcion}</td>
                    <td>
                      <InputCurrency
                        fixedDecimalScale={true}
                        decimalScale={2}
                        className="inputMoneda"
                        value={item.monto || ''}
                        thousandSeparator={true}
                        prefix={"$"}
                        onValueChange={(values) => {
                          const { formattedValue, value } = values;
                          onChange(value, i, type);
                        }}
                      />
                      {!item.monto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                          El monto es requerido
                        </span>
                      )}
                      {/* <Input type="number" placeholder="$" className="col-4" value={item.monto} size="sm" onChange={(e) => onChange(e.target.value, i, type)} /> */}
                    </td>
                    <td>
                      <FontAwesomeIcon icon={faTrash} className="mx-1" cursor={"pointer"} onClick={() => onDelete(i)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Form>
    </>
  );
};
