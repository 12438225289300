const tiposExamenes = {
  selccionar: ['Seleccionar'],
  hormonas: [
    "Adrenocorticotropica",
    "Hormona de Crecimiento",
    "Paratohomona intacta",
    "Cortisol",
    "Beta=HCG Gonodotropina Corionica",
    "Insulina",
    "Procalcitoina",
  ],
  cardiacas: [
    "Perfil de Liproteinas",
    "Perfil Cardiaco",
    "Enzimas Cardiacas",
    "Perfil de Disnea",
    "Troponina I",
    "Peptido Natriuretico B",
    "Lipoproteina A",
    "Preteina C Reactiva Alta Sensibilidad",
  ],
  anemia: [
    "Hierro Serico",
    "Saturacion de Transferrina",
    "Ferritina",
    "Vitamina B12",
    "Acido Folico",
  ],
  dobleMarcador: [
    "Gonadotropina Corionica Libre",
    "Proteina Plasmatica Asociada a Embarazo",
  ],
  tripleMarcador: [
    "Alfa Fetorpoteina",
    "Gonadotropina Corionica Humana",
    "Estriol Libre",
  ],
  bancoDeSangre: [
    "Pruebas Cruzadas",
    "Pruebas Pre-Transfuncionales",
    "Plaquetoaferesis",
    "Paquete Globular",
    "Plasma",
  ],
  tiroides: [
    "Perfil Tiroide Completo",
    "T4T Tiroxina Total",
    "T4F Tiroxina Libre",
    "T3U T3 Triyodotironina Total",
    "T3F Triyodotironina Libre",
    "TSH Hormona Estimulante de Tiroides",
    "AC. Anti-Tiroglobulina",
    "Ac. Anti-Preoxiadasa Tiroidea",
    "TiroGlobulina",
  ],
  ovaricoGinecologico: [
    "Perfil Ovarico",
    "LH Hormona Luteinizante",
    "FSH Hormona Foliculo Estimulante",
    "E2 Estradiol",
    "Protactina",
    "Progesterona",
    "Testosterona Total",
    "Perfil Hormonal",
  ],
  hepatitis: [
    "Pefil de Hepattis ABC Actividad",
    "Ac. Hepatitis A lgM",
    "AC. Hepatitis B Core Total",
    "Ac. Hepatitis B Core lgm",
    "Ag. Hepatitis B Superficies",
    "Ac. Hepatitis B e",
    "Ag. Hepatitis B e",
    "Ac. Hepatitis C Total",
  ],
  quimicaClinica: [
    "SMAC 28 / Lipoproteinas",
    "SMAC 25/ Hierro",
    "SMAC Critico",
    "Quimica 3",
    "Quimica 6",
    "Funcion Hepatica",
    "Electrolitos 3",
    "Glucosa",
    "Glucosa Postpradial 50 gr",
    "Glucosa Postprandial 75 gr",
    "Glucosa Curva de tolerancia",
    "Urea",
    "Creatinina",
    "Acido Urico",
    "Colesterol",
    "HDL Colesterol",
    "Trigliceridos",
    "Bilirubinas Totales",
    "Proteinas Totales A/G",
    "Albumina Serica",
    "Sodio",
    "Potasio",
    "Cloro",
    "Calcio",
    "Fosforo",
    "Magnesio",
    "Lactato",
    "Amonio",
  ],
  hematologia: [
    "Biometria Hematica Completa",
    "Formula Roja",
    "Plaquetas",
    "Sedimentacion Globular",
    "Reticulocitos",
    "Frotis de Sangre Periferica",
    "Gota Gruesa",
    "Cooms Directo",
    "Tiempo de Protrombina(TP)",
    "Tiempo Parcila de Tromboplastina(TPT)",
    "Tiempo de Sangrado",
    "Tipo y Rh Sanguienos",
  ],
  enzimas: [
    "CPK Fraccion MB",
    "CPK Total",
    "LDH Lactato Deshidrogenasa",
    "ALT Transaminasa Piruvica",
    "AST Transaminasa Oxalcecetica",
    "GGTP Glumatil Transfersa",
    "Fosfatasa Alcalina",
    "Fosfatasa Acida Total",
    "Fosfatasa Acida Prostatica",
    "Amilasa",
    "Lipasa",
    "Colinesterasa",
  ],
  inmunologico: [
    "Ac Antinucleares (ANA-IFA)",
    "Ac. Treponema Palidum (FTA-ABS)",
    "Ac. Ans-DNA Screen",
    "Ac.Anti-Cardiolipinas lgG/gm",
    "Ac. Anti-Fospolipidos lgG/lgM",
    "Ac.Chiamydia Trachomatis lgG/lgM",
    "Ac. Cocodioldes lmmtis lgG/lgM",
    "Ac. Dengue lgG/lgM",
    "Ac.Epstein-Barr Antigeno Temprano lgM/lgG",
    "Ac.Epstein-Barr Antigneo Nuclear lgM/lgG",
    "Ac. Epstein-Barr Antigeno Capsular lgM/lgG",
    "Ac. Helicobacter Pulori lgA/LgG/lgM",
    "Ac.Helicobacter Pylori lgA/lgG/lgM",
    "Ac.HIV 1+2",
    "Ac.Mycobacterium Tuberculosis lgG/lgM",
    "Ac. Mycoplasma Pneumoriae lgG/lgM",
    "Ag. CA-125 Ovario",
    "Ag.CA-14.3-Mama",
    "Ag. CA-19.9 Pancreas y Colon",
    "Ag. Carcinoembrionario - ACE",
    "Ag. Especifico Prostatico-PSA",
    "Ag.Especifico Prostatico Libre",
  ],
  serologia: [
    "Antiestreplolisinas-ASLO",
    "Factor Reumatoide lgA/lgG/lgM",
    "Proteina C Reactiva",
    "VDRL",
    "Reacciones Febrites",
    "Prueba de Embarazo en Sangre",
    "Monotest",
    "Rosa de Bengala",
  ],
  microbiologia: [
    "Gram",
    "Baar",
    "KOH",
    "Fresco",
    "Cultivo Faringeo",
    "Cultimo Amigdalino",
    "LinoCultivo",
    "Coprocultivo",
    "Cultivmo osco",
    "Cultivo Nasal",
    "Cultivo Cervico Vaginal",
    "Cultivo Ureatral",
    "Cultivo Expectoracion",
    "Hemocultivo",
    "Espermocultivo",
    "Cultivo de LCR",
    "Cultivo Myco. Tuberculosis",
    "Cultivo Hongos",
  ],
  dograsDebasuso: [
    "Perfil Toxicologico",
    "cocaina Metabolica",
    "Canabinoides",
    "Opiaceos",
    "Anfetaminas",
    "Metanfetaminas",
    "Barziodacipinas",
    "Ecstasy",
    "Barbituricos",
    "Metadoma",
    "Antidepresivos triciclicos",
  ],
  torch: [
    "Perfil TORCH lgG() lgM()",
    "Ac. Tacoplasma Gondi lgG/lgM",
    "Ac. Rubecla lgG/lgM",
    "Ac. Citomegalovinus lgG/lgM",
    "Ac. Heroes Simplex I lgG/lgM",
    "Ac. Herpes simples II lgG/lgM",
  ],
  turbidimetria: [
    "Inmunoglobinas Perfil",
    "Inmunoglobinas A",
    "Inmunoglobinas G",
    "Inmunoglobinas M",
    "Inmunoglobinas E",
    "Complemento C3",
    "Complemento C4",
    "Complemento Hemlitico CH50",
    "Fibrinogeno",
    "Gicohemoglobina(HbA1c)",
  ],
  varios: [
    "Gasometria Arterial",
    "Gasoemtria Venosa",
    "Espermatobioscopia Direct",
    "Coagulacion Bacteriana",
    "Citoquimico de Liquido Cefalorraquideo",
    "Citoquimico de Liquido Pleural",
    "Citoquimico de Liquido Dialisis",
    "Plomo en la Sangre",
    "Eosinofilos en Moco Nasal",
    "Tamiz Metabolico Neonatal",
    "Tamiz Metabolico Noenatal Plus",
  ],
  dograsTerapeuticas: [
    "Salicatos",
    "Acetaminofen",
    "Acido Valpeoico",
    "Aminofilina",
    "Carbamazepina",
    "Difenihidantoina",
    "Digoxina",
    "Fenobartbital",
    "Litio",
  ],
  pruebaDeOrina: [
    "Examen General de Orina",
    "Depuracion de Creatina de Orina 24 Hs",
    "Cuantificacion de Proteinas en Orina 24Hs",
  ],
  pruebasFecales: [
    "Ag. Entamoeba Histolyca",
    "Ag. Giarda Lambia",
    "Ag. Cryplosporidum",
    "Ag. Helicobater Pylori",
    "Rotavirus",
    "Adenovirus",
    "Coproparasitoscopico",
    "Coprologico Completado",
    "Observacion en Fresco",
    "Sangre Oculta",
    "Azucares Reductores",
    "Raspado Perianal=Graham",
  ],
};

export default tiposExamenes;
