import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sucursalRuta } from '../../../store/sucursalDuck';
import { NavMenu } from '../EmpresaMenu/styles';
import { useHistory } from 'react-router-dom';

const SideMenu = React.memo(() => {
  const dispatch = useDispatch();
  const { rutaSucursal = {} } = useSelector((state) => state.sucursal);
  const history = useHistory();
  const { sucursal, expediente, departamentos } = rutaSucursal;
  return (
    <NavMenu>
      <ul>
        <li>
          <span
            className={sucursal ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                sucursalRuta({
                    sucursal: true,
                })
              );
              history.replace('/');
            }}
          >
           Detalle Sucursal
          </span>
        </li>
        <li>
          <span
            className={expediente ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                sucursalRuta({
                    expediente: true,
                })
              );
              history.replace('/expediente');
            }}
          >
          Expediente Digital
          </span>
        </li>
        <li>
          <span
            className={departamentos ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                sucursalRuta({
                    departamentos: true,
                })
              );
              history.replace('/departamentos');
            }}
          >
             Departamentos
          </span>
        </li>
      </ul>
    </NavMenu>
  );
});

export default SideMenu;
