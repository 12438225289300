import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
import '../../../App.css';
import { default as Colors, default as colors } from '../../../constans/colors';
import AppRoutes from '../../../routes/ProtectedRoutes/AppRoutes';
import GridContainerSidebar from '../../ui/GridContainerSidebar';
import SweetAlert from '../../ui/SweetAlet';
import { LogoContainer } from '../Sidebar/styled/styledSideBar';
import './sidebar.css';
import {logoutAction} from '../../../store/userDuck';

const Sidebar = ({ routes, history }) => {
  const dispatch = useDispatch();

  const activeClass = (page) =>{
    const pages = page.split('/');
    if (pages.length > 2){
      return pages[2];
    }
  }

  const handleclick = async () => {
    const { isConfirmed, isDismissed } = await SweetAlert({
      title: '¿Seguro que desea salir?',
      showCancelButton: 'Cancelar',
    });
    if (isConfirmed) {
      await dispatch(logoutAction());
      // history.replace('/');
    }
  };

  const { permisos = {} } = useSelector((state) => state.user);

  return (
    <GridContainerSidebar>
      <Nav
        className='navbar-nav fullViewport Sidebar'
        style={{ background: colors.secondary }}
      >
        <LogoContainer />
        {routes.map(({ redirect, path, icon, name }, key) => {
          if (name === 'cuenta' && permisos.empresas === 'none') return null;
          if (name === 'empresa' && permisos.usuarios === 'none' && permisos.roles === 'none' && permisos.reportes === 'none') return null;
          if (redirect) return null;
          return (
            <li className={`d-block link-wrapper text-light ${activeClass(history.location.pathname)}-active`}  key={key}>
              <Link
                replace={true}
                to={key === 0 ? '/dashboard' : '/dashboard' + path}
                className={`text-ligth ${name}`}
              >
                <span>
                  <FontAwesomeIcon
                    icon={icon}
                    color={Colors.primary}
                    className='lead '
                  />
                </span>
              </Link>
            </li>
          );
        })}
        <li className='d-block link-wrapper text-light pointer' onClick={handleclick}>
          <span>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={Colors.primary}
              className='lead'
            />
          </span>
        </li>
      </Nav>
      <AppRoutes />
    </GridContainerSidebar>
  );
};
export default Sidebar;
