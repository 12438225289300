import React, {useCallback, useEffect, useState} from 'react';
import {Col, Form, FormGroup, Input, Label, Button, CustomInput} from "reactstrap";
import CustomSelect from "../../ui/CustomSelect";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {demandaSchema} from "../../../constans/yupSchemas";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SweetAlert from "../../ui/SweetAlet";
import swal from "sweetalert2";
import {eliminarDemandaAction,getDemandasAction,editarDemandaAction} from "../../../store/demandaDuck";
import {fetchEmpresasToDropdown} from "../../../store/empresasDuck";
import {fetchCreateAudiencias,fetchDeleteAudiencia,fetchListaAudiencias} from "../../../store/audienciasDuck";
import moment from "moment";
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import TableCommon from '../../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer, TextAreaUtils } from '../../colaboradores/VacacionesViews/styled';
import TableAudiencias from './TableAudiencias';
import colors from '../../../constans/colors';
import Spiner from "../../ui/Spiner";
import estados from '../../../constans/estados';
import { SaveButton,CancelButton } from '../../ui/styles/styles';
import { HeaderStyle } from "../../../components/ui/styles/StyleNomina";
import { Fragment } from 'react';
import InputFile from '../../ui/InputFile';
import { getListaColaboradoresByEmpresa } from '../../../store/colaboradorDucks';
import CurrencyFormat from 'react-currency-format';
import axios from '../../../services/axios';
import {salarioCaido} from '../../../helpers/index';

const tiposAudiencia = [
	{label:'CONCILIACIÓN, DEMANDA y EXCEPCIONES',value:'CONCILIACIÓN'},
	{label:'OFRECIMIENTO Y ADMISIÓN A PRUEBAS',value:'OFRECIMIENTO'},
	{label:'DESAHOGO DE PRUEBAS',value:'DESAHOGO DE PRUEBAS'},
	{label:'OTROS',value:'OTROS'}
];

const tiposPruebas = [
	{label: 'Confesional',value:'Confesional'},
	{label: 'Testimonial',value:'Testimonial'},
	{label: 'Pericial',value:'Pericial'},
	{label: 'Inspeccion',value:'Inspeccion'},
	{label: 'Otros',value:'Otros'},
];

const api = process.env.REACT_APP_API_URL;

const DetalleDemanda = () =>{
    const {register, handleSubmit, errors, getValues, control} = useForm({
        resolver: yupResolver(demandaSchema)
    })
    console.log(errors);
	const {empresas=[]} = useSelector(state => state.empresas );
	const { listaColaboradores = [] } = useSelector((state) => state.colaborador);
	const {demanda, loading, editarDemanda} = useSelector(state => state.demanda )
	const {listaAudiencias=[]} = useSelector(state => state.audiencias)
    const [err, setErr] = useState({});
	const [isEdit, setIsEdit] = useState(true);
	const [showAdd, setShowAdd] = useState(false);
	const [showAdjuntar, setShowAdjuntar] = useState(false);
	const [formAudiencias, setFormAudiencias] = useState(false);
	const [formTipoPrueba, setFormTipoPrueba] = useState(false);
	const [confesional, setConfesional] = useState(false);
	const [testimonial, setTestimonial] = useState(false);
	const [pericial, setPericial] = useState(false);
	const [inspeccion, setInspeccion] = useState(false);
	const [formOtros, setFormOtros] = useState(false);
	const [salarioD, setSalario] = useState({salarioDiario:demanda['salario']});
	const [sCaido, setSCaido] = useState('');
	const [contingencia, setContingencia] = useState('');
	const [notEmail, setNotEmail] = useState(demanda['notificar']);
  	const [depto, setDepto] = useState('');
  	const [pues, setPues] = useState('');
	const [jefe, setJefe] = useState('');
	const {salarioDiario} = salarioD;
    const history= useHistory();
    const dispatch = useDispatch();

    const { empresa , fechaDemanda , domicilioTrabajo, puesto, salario, horario,
        jefeInmediato, fechaDespido, fechaActualizacion, noExpediente, estado,
        ciudad, autoridadLaboral, colaborador,departamento, demandado, contraparte: {abogado, correo, telefono, celular, direccion},
        antecedentes, estadoActual} = demanda
	
	const headers = {
		headers: {
			token: localStorage.getItem("pv2token"),
		},
	};

    const getEmpresas = useCallback(async () => {
            try {
                await dispatch(fetchEmpresasToDropdown())
				await dispatch(getListaColaboradoresByEmpresa(demanda['empresa']));
            } catch (error) {}
        },
        [dispatch]
    );
	const onChange = useCallback(
		async ({ target }) => {
		  if (!target.value) return;
		  try {
			await dispatch(getListaColaboradoresByEmpresa(target.value));
		  } catch (error) {}
		},
		[dispatch]
	  );
	const updateDemanda = useCallback(async(objDemanda)=>{
        try {
			console.log(objDemanda);
            dispatch(editarDemandaAction(objDemanda))
			await SweetAlert({
				icon: 'success',
				title: 'Demanda editada correctamente',
			  });
            history.push('/dashboard/nomina/juridico-demandas')
        }catch (error) {
            await SweetAlert({icon: 'error', title: 'Error!', text:error});
        }
    }, [dispatch])
    const submit = (data) => {
        const {empresa,fechaDemanda,domicilioTrabajo,puesto,horario,email,
            jefeInmediato,fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral, colaborador,
            demandado, abogado, departamento, correo, telefono, celular, direccion, antecedentes, estadoActual, audiencias} = data;
        const objDemanda={
            empresa : empresa,
            fechaDemanda: fechaDemanda,
            domicilioTrabajo: domicilioTrabajo,
            puesto: puesto,
            salario: salarioDiario,
            horario: horario,
			email:email,
            jefeInmediato: jefeInmediato,
            fechaDespido: moment(fechaDespido).format('x'),
            fechaActualizacion: moment(fechaActualizacion).format('x'),
            noExpediente: noExpediente,
			departamento:departamento,
            estado: estado,
            ciudad: ciudad,
            autoridadLaboral: autoridadLaboral,
            colaborador: colaborador,
            demandado: demandado,
            contraparte: {
                abogado: abogado,
                correo: correo,
                telefono: telefono,
                celular: celular,
                direccion: direccion
            },
            antecedentes: antecedentes,
            estadoActual: estadoActual,
            audiencias: audiencias,
        }
		
		updateDemanda(objDemanda)
        
    }
	const now = moment();
	const despido = moment(fechaDespido);
	const diff = now.diff(despido,'days');
	const toggleAdd = () => {
		setShowAdd(!showAdd);
		setShowAdjuntar(false);
		setFormAudiencias(false);
	}
    const getListaAudiencias = useCallback(async () => {
            try {
                dispatch(fetchListaAudiencias(demanda._id))
            } catch (error) {}
        },
        [dispatch]
    );
	const elminateUtil = async () =>{
		try {
			const { isConfirmed } = await SweetAlert({
			  title: 'Seguro que desea eliminar?',
			  showCancelButton: 'Cancelar',
			});
			if (isConfirmed) {
			  await dispatch(eliminarDemandaAction());
			  await dispatch(getDemandasAction());
			  history.replace('/dashboard/nomina/juridico-demandas');
			}
		  } catch (error) {}
	}
    useEffect(()=>{
		async function getscaido(){
			const salCaido = await salarioCaido(fechaDespido,salarioDiario);
			console.log(salCaido);
			setSCaido(salCaido.total);
			setContingencia(salCaido.contingencia)
		};
		if (Object.keys(errors).length) setErr(errors);	
        getEmpresas()
		getListaAudiencias()
		setDepto(departamento);
		setJefe(jefeInmediato);
		setPues(puesto);
		getscaido();
    }, [dispatch,errors])

	const changeColaborador = async ({ target }) => {
		const id = target.value;
		const { data } = await axios.get(`${api}/colaborador/obtener_colaborador/${id}`, headers)
		const {resultado} = data;
		setSalario({salarioDiario:resultado.InformacionNomina.salarioDiario});
		setPues(resultado.condicionesLaborales.puesto);
		setDepto(resultado.pertenencia.nombreDepartamento);
		setJefe(resultado.condicionesLaborales.jefeInmediato);
	};
	const handleChange = ({target}) =>{
		let value = target.value;
		console.log(value);
		if (!value) {
			setShowAdjuntar(false);
			setFormAudiencias(false);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
			setFormTipoPrueba(false);
		}
		if (value === 'CONCILIACIÓN') {
			setFormAudiencias(true);
			setShowAdjuntar(true);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
			setFormTipoPrueba(false);
		}if(value === 'OFRECIMIENTO'){
			setFormAudiencias(true);
			setShowAdjuntar(false);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
			setFormTipoPrueba(false);
		}if(value === 'DESAHOGO DE PRUEBAS'){
			setFormTipoPrueba(true);
			setFormAudiencias(true);
			setShowAdjuntar(true);
		}if(value === 'OTROS'){
			setFormAudiencias(true);
			setShowAdjuntar(false);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
			setFormTipoPrueba(false);
		}
	}

	const changePruebas = ({target}) =>{
		let value = target.value;
		console.log(value);
		if (value === '') {
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}
		if (value === 'Confesional') {
			setConfesional(true);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Testimonial'){
			setTestimonial(true);
			setConfesional(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Pericial'){
			setPericial(true);
			setConfesional(false);
			setTestimonial(false);
			setInspeccion(false);
			setFormOtros(false);
		}if(value === 'Inspeccion'){
			setInspeccion(true);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setFormOtros(false);
		}if(value === 'Otros'){
			setFormOtros(true);
			setConfesional(false);
			setInspeccion(false);
			setPericial(false);
			setTestimonial(false);
		}
	}

	const audienciaAdd = async () => {
		const { audiencias } = getValues();
		try {
			swal.showLoading();
			audiencias.empresa = empresa;
			audiencias.demanda = demanda._id;
			await dispatch(fetchCreateAudiencias(audiencias))
			getListaAudiencias();
			setShowAdd(false);
			setShowAdjuntar(false);
			setFormAudiencias(false);
			setConfesional(false);
			setTestimonial(false);
			setPericial(false);
			setInspeccion(false);
			setFormOtros(false);
			swal.close();
		} catch (error) {
			console.log(error)
		}

	}
	const deleteAudiencia = async (e,audiencia) => {
		if(!isEdit){
			try {
			const { isConfirmed } = await SweetAlert({
				title: 'Seguro que desea eliminar?',
				showCancelButton: 'Cancelar',
			  });
				if (isConfirmed) {
					swal.showLoading();
					await dispatch(fetchDeleteAudiencia(audiencia._id));
					getListaAudiencias();
					setShowAdd(false);
					setShowAdjuntar(false);
					setFormAudiencias(false);
					swal.close();
				}
			} catch (error) {
				console.log(error)
			}
		}
	}

    if(loading) return <Spiner/>
    return(
		<>
		<HeaderStyle style={{marginBottom:50}}>
      	<h1>Editar Demanda</h1>
    	</HeaderStyle>
		<Form onSubmit={handleSubmit(submit)}>
			<FormGroup row>
				<Col sm={1}>
					<Arrows
						onClick={() => {
							history.replace('/dashboard/nomina/juridico-demandas');
						}}
					/>
				</Col>
				<Col sm={1}>
					<Icons onClick={elminateUtil} />
				</Col>
				<Col sm={1}>
					<Icons
						pencil
						onClick={() => {
							setIsEdit((state) => !state);
						}}
					/>
				</Col>
			</FormGroup>
			<FormGroup row style={{border:"1px solid #404041",borderRadius:"5px"}}>
				<Col sm={9}>
					<h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
					Resumen
				</h5>
				</Col>
				<Col sm={3}>
					<CurrencyFormat
						value={contingencia}
						displayType={'text'}
						decimalScale={2}
						thousandSeparator={true}
						prefix={'$'}
						renderText={value => <h6 style={{float:'right',color:'#D91C5C',fontWeight:700}}>{value}</h6>}
					/> 
					
				</Col>
				<Col sm={12}>
					<h7 style={{float:'right',color:'#676565de',fontSize:'0.9rem',fontWeight:200}}>Contingencia General Aproximada</h7>
				</Col>
				<Col style={{border:"1px solid #404041",borderRadius:"5px",textAlign:'center',margin:'0.6% 0.6% 0.6% 1.5%',flex:'0 0 23%',maxWidth:'23%'}}>
					<span style={{fontWeight:'bold'}}>{moment(fechaDespido).format('YYYY/MM/DD')}</span>
					<br/>
					<span>Fecha de despido</span>
				</Col>
				<Col style={{border:"1px solid #404041",borderRadius:"5px",textAlign:'center',margin:'0.6%',flex:'0 0 23%',maxWidth:'23%'}}>
					<span style={{fontWeight:'bold'}}>{`${diff} dias`}</span>
					<br/>
					<span>Dias transcurridos</span>
				</Col>
				<Col style={{border:"1px solid #404041",borderRadius:"5px",textAlign:'center',margin:'0.6%',flex:'0 0 23%',maxWidth:'23%'}}>
					<span style={{fontWeight:'bold'}}>
						<CurrencyFormat
							value={salarioDiario}
							displayType={'text'}
							decimalScale={2}
							thousandSeparator={true}
							prefix={'$'}
							renderText={value => value}
						/> 
					</span>
					<br/>
					<span>Salario Diario Integrado</span>
				</Col>
				<Col style={{border:"1px solid #404041",borderRadius:"5px", textAlign:'center',margin:'0.6%',flex:'0 0 23%',maxWidth:'23%'}}>
					<span style={{fontWeight:'bold'}}>
						<CurrencyFormat
							value={sCaido}
							displayType={'text'}
							decimalScale={2}
							thousandSeparator={true}
							prefix={'$'}
							renderText={value => value}
						/> 
					</span>
					<br/>
					<span>Salarios Caidos</span>
				</Col>
			</FormGroup>
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Datos de la demanda
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label for='empresa' sm={2}>Empresa</Label>
				<Col sm={10}>
					<CustomSelect
						defultValue={empresa}
						type='select'
						name='empresa'
						id='empresa'
						control={control}
						disabled={errors.empresa ? true : false}
						innerRef={register({ required: true })}
						options={empresas}
						formText=''
						onChange={onChange}
						register={register({ required: true })}
						disabled={isEdit}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='domicilioTrabajo' sm={2}>
					Domicilio del Trabajo*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={domicilioTrabajo}
						type='text'
						name='domicilioTrabajo'
						placeholder='Ingrese el domicilio'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.domicilioTrabajo && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Domicilio del trabajo es requerido
						</span>
					)}
				</Col>
			</FormGroup>

			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Datos del colaborador
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Colaborador</Label>
				<Col sm={10}>
				<CustomSelect
					control={control}
					options={listaColaboradores}
					disabled={listaColaboradores.length > 0 ? false : true}
					type='select'
					name='colaborador'
					innerRef={register()}
					defultValue={colaborador._id}
					onChange={changeColaborador}
					disabled={isEdit}
				/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='salario' sm={2}>
					Salario
				</Label>
				<Col sm={10}>
					<CurrencyFormat
						fixedDecimalScale={true}
						decimalScale={2}
						className='inputMoneda'
						thousandSeparator={true}
						value={salarioDiario}
						name='salario'
						thousandSeparator={true}
						prefix={'$'}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setSalario({salarioDiario: value})
						}}
						innerRef={register()}
						placeholder='Salario'
						disabled={isEdit}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Departamento</Label>
				<Col sm={10}>
				<Input
					type='text'
					defaultValue={depto}
					name='departamento'
					innerRef={register()}
					placeholder='Departamento'
					disabled={isEdit}
				/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='puesto' sm={2}>
					Puesto*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={pues}
						type='text'
						name='puesto'
						placeholder='Ingrese el puesto'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.puesto && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Puesto es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='jefeInmediato' sm={2}>
					Jefe Inmediato*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={jefe}
						type='text'
						name='jefeInmediato'
						placeholder='Ingrese el nombre del jefe inmediato'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.jefeInmediato && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Jefe inmediato es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='fechaDespido' sm={2}>
					Fecha de Despido*
				</Label>
				<Col sm={3}>
					<Input
						defaultValue={moment(fechaDespido).format('YYYY-MM-DD')}
						type='date'
						name='fechaDespido'
						placeholder='Ingrese la fecha de despido'
						innerRef={register({ required: true })}
						disabled={isEdit}
					/>
					{err.fechaDespido && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Fecha de despido es requerida
						</span>
					)}
				</Col>
			</FormGroup>

			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Datos de la Demanda
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label for='noExpediente' sm={2}>
					N°de expediente*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={noExpediente}
						type='text'
						name='noExpediente'
						placeholder='Ingrese el n° de expediente'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.noExpediente && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Numero de expediente es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='fechaDemanda' sm={2}>
					Fecha Demanda*
				</Label>
				<Col sm={3}>
					<Input
						defaultValue={moment(fechaDemanda).format('YYYY-MM-DD')}
						type='date'
						name='fechaDemanda'
						placeholder='Ingrese la fecha de demanda'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.fechaDemanda && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Fecha de la demanda es requerida
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='horario' sm={2}>
					Horario*
				</Label>
				<Col sm={3}>
					<Input
						defaultValue={horario}
						type='time'
						name='horario'
						placeholder='Ingrese el horario'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.horario && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Horario es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='demandado' sm={2}>
					Demandado*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={demandado}
						type='text'
						name='demandado'
						placeholder='Ingrese el demandado'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.demandado && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Demandado es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='estado' sm={2}>
					Estado*
				</Label>
				<Col sm={10}>
					<CustomSelect
						control={control}
						options={estados}
						type='select'
						name='estado'
						placeholder='Ingrese el estado'
						innerRef={register()}
						disabled={isEdit}
						defaultValue={estado}
					/>
					{err.estado && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Estado es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='ciudad' sm={2}>
					Ciudad*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={ciudad}
						type='text'
						name='ciudad'
						placeholder='Ingrese la ciudad'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.ciudad && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Ciudad es requerida
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='autoridadLaboral' sm={2}>
					Autoridad Laboral*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={autoridadLaboral}
						type='text'
						name='autoridadLaboral'
						placeholder='Ingrese la autoridad Laboral'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.autoridadLaboral && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Autoridad laboral es requerida
						</span>
					)}
				</Col>
			</FormGroup>
			
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Datos de la contraparte
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label for='abogado' sm={2}>
					Abogado*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={abogado}
						type='text'
						name='abogado'
						placeholder='Ingrese el abogado'
						innerRef={register()}
						disabled={isEdit}
					/>
					{err.abogado && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El abogado es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='correo' sm={2}>
					Correo
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={correo}
						disabled={isEdit}
						type='text'
						name='correo'
						placeholder='Ingrese el correo del abogado'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='telefono' sm={2}>
					Teléfono
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={telefono}
						disabled={isEdit}
						type='number'
						name='telefono'
						placeholder='Ingrese el teléfono'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='celular' sm={2}>
					Celular
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={celular}
						disabled={isEdit}
						type='number'
						name='celular'
						placeholder='Ingrese el teléfono'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='direccion' sm={2}>
					Dirección
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={direccion}
						disabled={isEdit}
						type='string'
						name='direccion'
						placeholder='Ingresa la dirección'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
				Atención
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Representante</Label>
				<Col sm={10}>
				<Input
					type='text'
					name='representante'
					defaultValue={demanda['representante']}
					innerRef={register()}
					placeholder=' '
					disabled={isEdit}
				/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Notificar</Label>
				<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="SI"
					value={true}
					disabled={isEdit}
					defaultChecked={demanda['notificar']===true}
					name="notificar"
					onClick={() => setNotEmail(true)}
				/>
				</Col>
				<Col sm={1}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="NO"
					defaultChecked={demanda['notificar']===false}
					value={false}
					name="notificar"
					disabled={isEdit}
					onClick={() => setNotEmail(false)}
				/>
				</Col>
			</FormGroup>
			{notEmail ?
			<FormGroup row>
				<Label sm={2}>Email</Label>
				<Col sm={10}> 
					<Input 
					type='text'
					name='email'
					innerRef={register}
					placeholder='Email'
					disabled={isEdit}
					defaultValue={demanda['email']}
					/>
				</Col>
			</FormGroup>:null}
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Antecedentes
				</h5>
			</FormGroup>
			<FormGroup row>
				<Label for='antecedentes' sm={2}>
					Antecedentes*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={antecedentes}
						disabled={isEdit}
						type='textarea'
						name='antecedentes'
						placeholder='Ingrese los antecedentes'
						innerRef={register()}
					/>
					{err.antecedentes && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							Los Antecedentes son requeridos
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='estadoActual' sm={2}>
					Estado actual*
				</Label>
				<Col sm={10}>
					<Input
						defaultValue={estadoActual}
						disabled={isEdit}
						type='textarea'
						name='estadoActual'
						placeholder='Ingrese el estado actual'
						innerRef={register()}
					/>
					{err.estadoActual && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							El Estado actual es requerido
						</span>
					)}
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='fechaActualizacion' sm={2}>
					Fecha de Recordatorio
				</Label>
				<Col sm={3}>
					<Input
						defaultValue={moment(fechaActualizacion).format('YYYY-MM-DD')}
						type='date'
						name='fechaActualizacion'
						placeholder='Ingrese la fecha de actualizacion'
						innerRef={register({ required: true })}
						disabled={isEdit}
					/>
					{/* {err.fechaActualizacion && (
						<span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
							La Fecha de Recordatorio es requerida
						</span>
					)} */}
				</Col>
			</FormGroup>
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Audiencias
				</h5>
			</FormGroup>
			{listaAudiencias.length?
			<TableContainer style={{ marginTop:10}}>
				<TableCommon 
					keysAceepted={['Fecha', 'Hora', 'Abogado', 'Tipo','Documento','']}
					extraTag={{ key: 'Valor Total', value: 1200 }}
					striped
				>
				<TableAudiencias listaAudiencias={listaAudiencias} deleteAudiencia={deleteAudiencia} isEdit={isEdit}/>
				</TableCommon>
			</TableContainer>
			:null}
			{!isEdit ?
				<FormGroup>
				<Button type="button" onClick={e => toggleAdd()} 
				style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}>+ Agregar</Button>
			</FormGroup>
			:null}

			{showAdd ?

			<FormGroup row>
				<Label for='tipo' sm={2}>
					Tipo
				</Label>
				<Col sm={10}>
					<CustomSelect
						control={control}
						options={tiposAudiencia}
						type='select'
						name='audiencias.tipo'
						placeholder='Seleccione el tipo de audiencia'
						innerRef={register()}
						onChange={handleChange}
					/>
				</Col>
			</FormGroup>
			:null}
			{formAudiencias ? 
			<Fragment>
			<FormGroup row>
				<Label for='fecha' sm={2}>
					Fecha*
				</Label>
				<Col sm={3}>
					<Input
						type='date'
						name='audiencias.fecha'
						placeholder='Ingrese la fecha de la audiencia'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Hora*</Label>
				<Col sm={3}>
				<Input 
					type='time' 
					name='audiencias.hora' 
					innerRef={register()} 
					placeholder='' 
				/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='abogado' sm={2}>
					Abogado*
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.abogado'
						placeholder='Abogado que atenderá la audiencia'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='cedula' sm={2}>
					Cédula*
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.cedula'
						placeholder='Cédula'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='celular' sm={2}>
					Celular*
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.celular'
						placeholder='Celular'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='email' sm={2}>
					Email*
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.email'
						placeholder='Email'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Notificar</Label>
				<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="SI"
					value={true}
					name="audiencias.notificar"
				/>
				</Col>
				<Col sm={1}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="NO"
					value={false}
					name="audiencias.notificar"
				/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='notas' sm={2}>
					Notas
				</Label>
				<Col sm={10}>
					<TextAreaUtils
						name='audiencias.notas'
						placeholder='Notas'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2} for='documento'>
				Documento
				</Label>
				<Col sm={10}>
				<InputFile
					name='audiencias.documento'
					type='file'
					id='documento'
					register={register}
				/>
				</Col>
			</FormGroup>
			</Fragment>
			:null}
			{formTipoPrueba
			?
			<FormGroup row>
				<Label for='tipoPrueba' sm={2}>
					Tipo de prueba
				</Label>
				<Col sm={10}>
					<CustomSelect
						control={control}
						options={tiposPruebas}
						type='select'
						name='audiencias.tipoPrueba'
						placeholder='Seleccione el tipo de prueba'
						innerRef={register()}
						onChange={changePruebas}
					/>
				</Col>
			</FormGroup>
			:null}
			{confesional
			?
			<FormGroup row>
				<Col sm={3}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="Confesiones por posiciones"
					value="Confesion"
					name="audiencias.confesional.tipo"
				/>
				</Col>
				<Col sm={3}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="Para hechos propios"
					value="hechos"
					name="audiencias.confesional.tipo"
				/>
				</Col>
				<Col sm={3}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="Declaracion por parte"
					value="declaracion"
					name="audiencias.confesional.tipo"
				/>
				</Col>
				<Col sm={3}>
				<CustomInput
					innerRef={register}
					type="radio"
					label="Otros"
					value="otros"
					name="audiencias.confesional.tipo"
				/>
				</Col>
			</FormGroup>
			:null}
			{testimonial
			?
			<Fragment>
			<FormGroup row>
				<Col sm={12}>
					<Input
						type='text'
						name='audiencias.testimonial.testigo1'
						placeholder='Testigos'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Col sm={12}>
					<Input
						type='text'
						name='audiencias.testimonial.testigo2'
						placeholder='Testigos'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Col sm={12}>
					<Input
						type='text'
						name='audiencias.testimonial.testigo3'
						placeholder='Testigos'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Col sm={12}>
					<Input
						type='text'
						name='audiencias.testimonial.testigo4'
						placeholder='Testigos'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			</Fragment>
			:null}
			{pericial
			?
			<Fragment>
			<FormGroup row>
				<Label for='tipo' sm={2}>
					Tipo de pericial
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.pericial.tipo'
						placeholder='Tipo de pericial'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='nombrePerito' sm={2}>
					Nombre del perito
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.pericial.nombrePerito'
						placeholder='Tipo de perito'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			</Fragment>
			:null}
			{inspeccion
			?
			<Fragment>
			<FormGroup row>
				<Label for='tipo' sm={2}>
					Tipo de inspección
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.inspeccion.tipo'
						placeholder='Tipo de inspección'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='nombrePerito' sm={2}>
					Nombre del perito
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.inspeccion.nombrePerito'
						placeholder='Tipo de perito'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			</Fragment>
			:null}
			{formOtros
			?
			<Fragment>
			<FormGroup row>
				<Label for='tipoAudiencia' sm={2}>
					Tipo de prueba
				</Label>
				<Col sm={10}>
					<Input
						type='text'
						name='audiencias.otros.tipoAudiencia'
						placeholder='Tipo de prueba'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label for='informacion' sm={2}>
					Información
				</Label>
				<Col sm={10}>
					<TextAreaUtils
						name='audiencias.otros.informacion'
						placeholder='Información'
						innerRef={register()}
					/>
				</Col>
			</FormGroup>
			</Fragment>
			:null}
			{formAudiencias 
			?
			<FormGroup row>
				<Col sm={3}>
					<SaveButton type='button' onClick={e => audienciaAdd()}>Agregar Audiencia</SaveButton>
				</Col>
			</FormGroup>
			:null}
			

			<FormGroup row>
				<Col sm={{ offset: 6, size: 3 }}>
					<CancelButton
						onClick={() => {
							history.replace('/dashboard/nomina/juridico-demandas');
						}}
						style={{ marginLeft: 'auto' }}>
						Cancelar
					</CancelButton>
				</Col>
				<Col sm={3}>
					{!isEdit ?
						<SaveButton type='submit'>Guardar</SaveButton>
					:null}
				</Col>
			</FormGroup>
		</Form>
      </>
    )
}
export default DetalleDemanda;