import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CatalogoContainer, ContainerSelector, Search, ListContainer, Subtitle, ButtonContainer, StyleInputs } from "./styles/styles";
import { CancelButton as ButtonTab, InputUtils, LabelForms } from "../ui/styles/styles";
import { H3 } from "./styles/PrestacionesStyles";
import CatalogoModal from "./CatalogoModal";
import { Row, Col, Button, Table } from "reactstrap";
import PaquetePrestacionList from "./PrestacionesList";
import PrestacionForm from "./PrestacioneForm";
import moment from "moment";
import axios from "../../services/axios";

const prestacionLabels = {
  percepciones: "Percepciones",
  deducciones: "Deducciones",
  otrosPagos: "Otros Pagos",
};

const Catalogo = () => {
  const { _id } = useSelector((state) => state.empresas);
  const [prestacion, setPrestacion] = useState("percepciones");
  const [type, setType] = useState("list");
  const [prestaciones, setPrestaciones] = useState([]);
  const [paquete, setPaquete] = useState({});
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token")
    }
  };

  useEffect(() => {
    setType("list");
    getPaquetes();
    setPaquete({});
  }, [prestacion]);

  const getPaquetes = async () => {
    let { data } = await axios.get(`/nomina/prestaciones/paquetes/${_id}/?prestacion=${prestacion}`, headers);
    setPrestaciones(data.response);
  };
  const onCancel = () => {
    setType("list");
    setPaquete({});
    getPaquetes();
  };
  const onDelete = async (item) => {
    await axios.delete(`/nomina/prestaciones/paquetes/${item.empresaId}/${item._id}`, headers);
    getPaquetes();
  };

  const onEdit = (item) => {
    setPaquete(item);
    setType("new");
  };

  return (
    <CatalogoContainer>
      <Row className="mb-4">
        <Col>
          <ButtonContainer>
            <ButtonTab className={prestacion === "percepciones" ? "activeCatalogo" : null} type="ButtonTab" onClick={() => setPrestacion("percepciones")}>
              Percepciones
            </ButtonTab>
            <ButtonTab className={prestacion === "deducciones" ? "activeCatalogo" : null} type="ButtonTab" onClick={() => setPrestacion("deducciones")}>
              Deducciones
            </ButtonTab>
            <ButtonTab className={prestacion === "otrosPagos" ? "activeCatalogo" : null} type="ButtonTab" onClick={() => setPrestacion("otrosPagos")}>
              Otros Pagos
            </ButtonTab>
          </ButtonContainer>
        </Col>
      </Row>

      {type === "list" && (
        <PaquetePrestacionList text={prestacionLabels[prestacion]} data={prestaciones} prestacion={prestacion} onAdd={() => setType("new")} onDelete={onDelete} onEdit={onEdit} />
      )}
      {type === "new" && <PrestacionForm text={prestacionLabels[prestacion]} prestacion={prestacion} onCancel={onCancel} prestacionPaquete={paquete} />}
    </CatalogoContainer>
  );
};

export default Catalogo;
