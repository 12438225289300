import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../constans/colors';

const LabelInputImage = styled.label`
  width: 11vw;
  min-height: 11vw;
  padding-top: 2rem;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  cursor: pointer;
  text-align: center;
  background-color: ${(props) =>
  props.imagePreview ? '#c4ccdf' : '#ebecf4'};
  background-image: url(${(props) => props.imagePreview});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
  &:hover {
    border: 2px solid black;
  }
`;
const SpanInputImage = styled.span`
  color: ${colors.iconsColors};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
`;
const InputFile = styled.input`
  outline: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  width: 10%;
`;

const InputImage = React.memo(({ register = () => {}, ...rest }) => {
  const [url, setUrl] = useState(rest.loadImageUrl ? rest.loadImageUrl : '');
  const onChange = ({ target }) => {
    if (target.files[0]) setUrl(URL.createObjectURL(target.files[0]));
  };
  useEffect(() => {
    if (rest.loadImageUrl === 'clean') setUrl(() => '');
  }, [rest.loadImageUrl]);
  return (
    <LabelInputImage
      imagePreview={url ? url : ''}
      htmlFor={rest.id}
      disabled={rest.disabled}
    >
      {url ? null : (
        <>
          <FontAwesomeIcon
            color={colors.iconsColors}
            icon={faPlus}
            style={{
              fontSize: '0.9rem',
              fontFamily: 'Roboto',
              fontWeight: 500,
            }}
          />
          <SpanInputImage>Agrega Imagen</SpanInputImage>
        </>
      )}
      <InputFile
        type='file'
        ref={register}
        {...rest}
        accept='image/*'
        onChange={onChange}
      />
    </LabelInputImage>
  );
});

export default InputImage;
