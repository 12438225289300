import React from 'react';
import TableCommon from '../ui/TableCommon';
import TableBodyEmpresas from './TableBodyEmpresas';

const TablaEmpresas = React.memo(({ lista = [] }) => {
  return (
    <>
      <TableCommon>
        <TableBodyEmpresas listaEmpresas={lista} />
      </TableCommon>
    </>
  );
});

export default TablaEmpresas;
