import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import axios from "../../../services/axios";
import { Input, Col, Row, Label, FormGroup, Form, Table, Button } from "reactstrap";
import CurrencyFormat from "react-currency-format";
import { optionsModalidadPago } from "../../../constans/informacionNomina";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import InputCurrency from "react-currency-format";
import { StyleInputs } from "../../empresa/styles/styles";
import moment from "moment";
import sweet from "sweetalert2";
import ResumenModal from "../../nomina/ResumenModal";
import ReciboSimulador from "./ReciboSimulador";

export default () => {
    const { _id, informacionPersonal = {}, InformacionNomina = {}, pertenencia = {} } = useSelector((state) => state.colaborador);
    const headers = { headers: { token: localStorage.getItem("pv2token") } };
    const [prestacionesOr, setPrestacionesOr] = useState({ percepciones: [], deducciones: [] });
    const [basicos, setBasicos] = useState({ riesgoPuesto: '', periodicidad: '', empresaId: '', empleadoId: '', salarioDiario: 0, salarioBase: 0, numeroEmpleado: '' })
    const [prestacion, setPrestacion] = useState("");
    const [prestaciones, setPrestaciones] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [dataModal, setDataModal] = useState({});

    useEffect(() => {
        const nombre = `${informacionPersonal.nombre} ${informacionPersonal.apellidoPaterno} ${informacionPersonal.apellidoMaterno}`
        setBasicos(prevState => ({ ...prevState, riesgoPuesto: InformacionNomina.riesgoPuesto, periodicidad: InformacionNomina.modalidadDePago, empresaId: pertenencia.empresa, empleadoId: _id, nombre, salarioDiario: InformacionNomina.salarioDiario, salarioBase: InformacionNomina.salarioBase, numeroEmpleado: InformacionNomina.numeroEmpleado }))
        getPrestacionesOrdinarias();
        getPrestaciones();
    }, [InformacionNomina])

    const getPrestaciones = async () => {
        const { data } = await axios.get(`catalogos/?sat=true&catalogo=percepciones&tipo=Ordinaria`, headers);
        setPrestaciones(data.response);
    };

    const getPrestacionesOrdinarias = async () => {
        const { data } = await axios.get(`/nomina/colaborador/prestaciones/ordinarias/${pertenencia.empresa}/${_id}/`, headers);
        setPrestacionesOr(data.response);
    };

    const addPrestacion = (item, type) => {
        let idx = prestacionesOr[type].findIndex((p) => p.codigo === item.codigo);
        if (idx >= 0) {
            prestacionesOr[type][idx] = item;
        } else {
            prestacionesOr[type].push(item);
        }
        setPrestacionesOr({ ...prestacionesOr });
    };

    const addOrdinaria = (codigo) => {
        if (!prestacionesOr.percepciones.map((d) => d.codigo).includes(codigo) && codigo !== "") {
            const item = prestaciones.find((c) => c.codigo === codigo);
            addPrestacion(item, 'percepciones');
        }
    };

    const onChange = (value, idx, type) => {
        prestacionesOr[type][idx].monto = value;
        setPrestacionesOr({ ...prestacionesOr });
    };

    const onDelete = (i, type) => {
        prestacionesOr[type] = prestacionesOr[type].filter((p, id) => id !== i);
        setPrestacionesOr({ ...prestacionesOr });
    };


    const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);

    const getDocumento = (data) => {
        setDataModal(data);
        toggleModal();
    }

    const onCalculate = async () => {
        if (basicos.salarioBase === '' || basicos.salarioDiario === '' || basicos.periodicidad === '') return await sweet.fire({
            title: "Datos Requeridos",
            text: "Todos los datos en esta vista son requeridos",
            icon: "info",
            confirmButtonText: "Continuar",
        });
        prestacionesOr.percepciones = prestacionesOr.percepciones.filter(f => f.monto && f.monto !== '')
        const { data } = await axios.post(`/simulador/salarial`, { basicos, fechaInicio: moment().format("YYYY-MM-DD"), prestaciones: prestacionesOr }, headers)
        getDocumento(data.response)
    }

    return (
        <Form>
            {/* <ResumenModal
                isOpen={isOpen}
                data={dataModal}
                setIsOpen={setIsOpen}
            /> */}
            <Col sm={12}>
                <h5 className="px-1 py-3">Simulador Salarial</h5>
            </Col>
            <FormGroup row>
                <Col sm={4}>
                    <Label for='modalidadDePago'>
                        Modalidad de pago*
                    </Label>
                    <Input type="select" value={basicos.periodicidad} onChange={(e) => setBasicos({ ...basicos, periodicidad: e.target.value })}>
                        <option value="">Modalidad de pago...</option>
                        {optionsModalidadPago.map((p) => (
                            <option value={p.value} key={p.value}>
                                {p.label}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={4}>
                    <Label for='salarioDiario'>
                        Salario Diario*
                    </Label>
                    <CurrencyFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        className='inputMoneda'
                        name='salarioDiario'
                        value={basicos.salarioDiario}
                        placeholder="Ingrese el salario diario del colaborador"
                        thousandSeparator={true}
                        prefix={'$'}
                        onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setBasicos(prevState => ({ ...prevState, salarioDiario: value }));
                        }}
                    />
                </Col>
                <Col sm={4}>
                    <Label for='salarioDiario'>
                        Salario Base*
                    </Label>
                    <CurrencyFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        className='inputMoneda'
                        name='salarioBase'
                        value={basicos.salarioBase}
                        placeholder="Ingrese el salario base del colaborador"
                        thousandSeparator={true}
                        prefix={'$'}
                        onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            setBasicos(prevState => ({ ...prevState, salarioBase: value }));
                        }}
                    />
                </Col>
            </FormGroup>
            <Col sm={12}>
                <h5 className="px-1 py-3">Percepciones</h5>
            </Col>
            <FormGroup row>
                <Col md={7}>
                    <Input type="select" value={prestacion} onChange={(e) => addOrdinaria(e.target.value)}>
                        <option value="">Percepciones...</option>
                        {prestaciones.map((p) => (
                            <option value={p.codigo} key={p.codigo}>
                                {p.descripcion}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col className="text-right" sm={5}>
                    <StyleInputs>
                        <Button style={{ height: 40 }} color="primary" onClick={onCalculate}>
                            Calcular
                        </Button>
                    </StyleInputs>
                </Col>
            </FormGroup>
            <Row>
                <Col>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Codigo</th>
                                <th style={{ width: "500px" }}>Nombre</th>
                                <th>Monto</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {prestacionesOr.percepciones.map((item, i) => (
                                <tr key={i}>
                                    <td>{item.clave || item.codigo}</td>
                                    <td>{item.nombreCorto || item.descripcion}</td>
                                    <td>
                                        <InputCurrency
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            className="inputMoneda"
                                            value={item.monto || ''}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                            onValueChange={(values) => {
                                                const { formattedValue, value } = values;
                                                onChange(value, i, 'percepciones');
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon icon={faTrash} className="mx-1" cursor={"pointer"} onClick={() => onDelete(i, 'percepciones')} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Col sm={12}>
                <h5 className="px-1 py-3">Recibo</h5>
            </Col>
            <Col>
                <ReciboSimulador data={dataModal} />
            </Col>
        </Form >
    )
}
