import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { HeaderStyle, StyleInputs } from "../../components/ui/styles/StyleNomina";
import { CardEmpresas, TitleMenu } from "../../components/ui/styles/styles";
import { fetchEmpresas } from "../../store/empresasDuck";
import { Button, ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, ListGroupItemHeading, Row } from "reactstrap";
import sweet from "sweetalert2";
import Spiner from "../../components/ui/Spiner";

const api = process.env.REACT_APP_API_URL;

const MenuExtraordinaria = ({ title = "" }) => {
    const history = useHistory();
    const { empresas = [], loading } = useSelector(state => state.empresas);
    const { permisos = {} } = useSelector(store => store.user);
    const dispatch = useDispatch();
    const [totalesEmpresas, setTotalesEmpresas] = useState([]);
    const [showOptions, setShowOptions] = useState('');
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    }

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchEmpresas());
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (!empresas.length) return;
        if (empresas[0].informacionLegal) loadTotales();
    }, [empresas]);

    const loadTotales = async () => {
        let { data } = await axios.get(`${api}/extraordinaria/totales/?path=ptu&empresasIds=${empresas.map((e) => e._id).join(",")}`, headers);
        const totales = data.response.reduce((a, v) => {
            !a[v.empresaId] ? (a[v.empresaId] = [v]) : a[v.empresaId].push(v);
            return a;
        }, {});

        setTotalesEmpresas(
            empresas.map(({ nombre_empresa = "", informacionLegal = {}, _id }) => ({
                totales: totales[_id] ? totales[_id] : [],
                razonSocial: informacionLegal.razon_social,
                _id,
            }))
        );
    }

    const handleToggleOptions = (rs) => {
        if (rs === showOptions) setShowOptions('')
        else setShowOptions(rs)
    }

    const onNav = (empresaId, page) => {
        history.push({ pathname: `configuracion-extraordinaria/${empresaId}/${page}`, state: { page } })
    }

    const onNavReview = (empresaId, page, nomina) => {
        history.push({ pathname: `configuracion-extraordinaria/${empresaId}/${page}`, state: { page, nomina } })
    }

    const cambiarStatus = async (nomina, status) => {
        sweet.showLoading();
        await axios.put(`${api}/extraordinaria/estado/${nomina.empresaId}/?path=ptu&global=true&periodo=${nomina.periodo}&estado=${status}`, [], headers);
        await loadTotales();
        sweet.close();
    };

    const openDialog = async (nomina, status) => {
        let result = await sweet.fire({
            title: "Nomina Extraordinaria",
            text: `Seguro que desea cambiar a ${status}?`,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
        });
        if (result.isConfirmed) {
            await cambiarStatus(nomina, status);
        }
    };

    if (loading) {
        return <Spiner />;
    }

    return (
        <>
            <TitleMenu></TitleMenu>
            <HeaderStyle>
                <h1>{title}</h1>
                <h3>Genera tus nominas extraordinarias</h3>
            </HeaderStyle>
            <Row className="px-2">
                {totalesEmpresas.map((e, i) => (
                    <Col key={i} className="px-1 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <CardEmpresas className="my-2 text-left">
                            <Row>
                                <Col className="col-12">
                                    <Row>
                                        <Col className="col">
                                            <h2 style={{ width: "23vw", color: "#404041" }} className="mx-0 mt-2">
                                                {e.razonSocial}
                                            </h2>
                                        </Col>
                                        <Col className="col text-right">
                                            <ButtonDropdown size="lg" isOpen={e.razonSocial === showOptions} toggle={() => handleToggleOptions(e.razonSocial)}>
                                                <DropdownToggle style={{ height: "auto", background: "rgb(43, 56, 143)" }} caret>
                                                    Nueva
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => onNav(e._id, 'ptu')}>PTU </DropdownItem>
                                                </DropdownMenu>
                                            </ButtonDropdown>
                                        </Col>
                                    </Row>
                                    {e.totales.map((a, it) => {
                                        return (
                                            <div key={it}>
                                                <hr className="m-2 mt-3" />
                                                <div className="mt-4 b" key={it}>
                                                    <Row style={{ color: "rgb(146, 146, 146)" }}>
                                                        <Col className="col-9">
                                                            <h6 style={{ fontSize: "22px" }}>{a.periodicidad} - {a.type ? a.type.toUpperCase() : ''}</h6>
                                                        </Col>
                                                        <Col className="col-3 align-self-end text-center">
                                                            <h6> {a.periodo}</h6>
                                                        </Col>
                                                    </Row>
                                                    {a.detalle.map((det, idet) => {
                                                        if (det.estado === "revision" && title === "Timbrado") return null;
                                                        return (
                                                            <div key={idet}>
                                                                {det.estado === "revision"}
                                                                <Row>
                                                                    <Col className="col-4">
                                                                        <h6>Colaboradores</h6>
                                                                        <h6>{det.empleados}</h6>
                                                                    </Col>
                                                                    <Col className="col-4 text-center">
                                                                        <h6>Total a Pagar</h6>
                                                                        <h6>
                                                                            {det.total
                                                                                ? det.total.toLocaleString("en-US", {
                                                                                    style: "currency",
                                                                                    currency: "USD",
                                                                                })
                                                                                : ""}
                                                                        </h6>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <StyleInputs>
                                                                        <Col className="col-2  align-self-start col mt-1">
                                                                            <h6>Estatus:</h6>
                                                                        </Col>
                                                                        <Col className="col-4  align-self-start col">
                                                                            <h6 style={{ color: "#2b388f", fontSize: "20px", textTransform: "capitalize" }}>{det.estado}</h6>
                                                                        </Col>
                                                                        {det.estado === "revision" && (
                                                                            <>
                                                                                <Col className="col-4 text-right">
                                                                                    <Button size={"sm"} color="primary" onClick={() => onNavReview(e._id, a.type, a)} style={{ width: "70px" }}>
                                                                                        Ver
                                                                                    </Button>
                                                                                </Col>
                                                                                {permisos.autorizacionNomina === "write" ? (
                                                                                    <Col className="col-2 px-0 ">
                                                                                        <Button size={"sm"} color="primary" onClick={() => openDialog(a, "aprobada")} style={{ width: "70px" }}>
                                                                                            Aprobar
                                                                                        </Button>
                                                                                    </Col>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                        {det.estado === "aprobada" && (
                                                                            <>
                                                                                {permisos.autorizacionNomina === "write" ? (
                                                                                    <Col className="col-4 text-right">
                                                                                        <Button size={"sm"} color="primary" onClick={() => openDialog(a, "revision")} style={{ width: "70px" }}>
                                                                                            Revisar
                                                                                        </Button>
                                                                                    </Col>
                                                                                ) : null}
                                                                                {/* <Col className={permisos.autorizacionNomina === "write" ? "col-2 px-0 " : "col-4 text-right"}>
                                                                                    <Button size={"sm"} color="primary" style={{ width: "70px" }}>
                                                                                        Layout
                                                                                    </Button>
                                                                                </Col> */}
                                                                            </>
                                                                        )}
                                                                    </StyleInputs>
                                                                </Row>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </Col>
                            </Row>
                        </CardEmpresas>
                    </Col>
                ))}
            </Row>
        </>
    )
}

export default MenuExtraordinaria;
