import { faUsers, faUsersCog } from '@fortawesome/pro-solid-svg-icons';
import { permisosStart } from '../store/permisosLaboralesDuck';

const empresa_routes = (permisos) => {
  let subRoutes = []
  if (permisos.usuarios !== 'none' && permisos.roles !== 'none')  {
    subRoutes = [
      {
        path: '/usuarios',
        name: 'Usuarios',
        icon: faUsers,
      },
      {
        path: '/rol',
        name: 'Roles',
        icon: faUsersCog,
      }
    ]
  } else if (permisos.usuarios === 'none' && permisos.roles !== 'none')  {
    subRoutes = [
      {
        path: '/rol',
        name: 'Roles',
        icon: faUsersCog,
      }
    ]
  } else if (permisos.usuarios !== 'none' && permisos.roles === 'none')  {
    subRoutes = [
      {
        path: '/usuarios',
        name: 'Usuarios',
        icon: faUsers,
      }
    ]
  }

  return [
    {
      subMenuTitle: 'Usuarios',
    },
    {
      subMenuTitle: '',
      icon: '',
      subRoutes: subRoutes,
    },
    {
      subMenuTitle: 'reportes',
      subRoutes: [],
    },
  ]
};
export default empresa_routes;
