import React, { useCallback, useState } from 'react';
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Col,
    CustomInput, FormGroup, Input, Label,
    Table
} from 'reactstrap';
import { CancelButton } from '../../ui/styles/styles';
import '../../colaboradores/Forms/CondicionesLaborales.css';

const HorarioLaboral = ({ register, horario, setHorario, getValues, setValue, readOnly }) => {

    const [variableHorario, setVariableHorario] = useState({ end: false, start: false });

    const deleteHorario = (index) => {
        setHorario(horario.filter((rep, i) => i !== index))
    };

    const addingForm = useCallback(() => {
        const { horarioTrabajo } = getValues();
        const cleanInputData = {
            days: [],
            end: '',
            start: ''
        }
        setHorario([...horario, horarioTrabajo]);
        setValue('horarioTrabajo', cleanInputData, { shouldDirty: true });
    }, [getValues]);

    return (
        <div>
            <FormGroup row>
                <h5>Horario Laboral</h5>

                {horario && horario.length > 0 && (
                    <Table borderless style={{ marginLeft: '0.2rem' }}>
                        <thead>
                            <tr>
                                <th>Entrada</th>
                                <th>Salida</th>
                                <th>Dias</th>
                            </tr>
                        </thead>
                        <tbody>
                            {horario.map(({ start = 'variable', end = 'variable', days }, index) => (
                                <tr key={start + end + index}>
                                    <td>{start}</td>
                                    <td>{end}</td>
                                    <td>{days.length > 0 ? days.join(' ') : ''}</td>
                                    <td><FontAwesomeIcon
                                        cursor="pointer"
                                        onClick={() => deleteHorario(index)}
                                        icon={faTrash}
                                    /></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                )}
                </FormGroup>

                <FormGroup row>
                    <Label for='horarioEntrada' sm={3}> Horario Entrada </Label>
                </FormGroup>

                <FormGroup row>
                    <Col sm={3}>
                        { variableHorario.start ? null : (
                            <Input
                                type='time'
                                name='horarioTrabajo.start'
                                innerRef={register}
                            />
                        )}
                    </Col>

                    <Col sm={3}>
                        <CustomInput
                            value={true}
                            id='varialbe1'
                            type='checkbox'
                            label='Variable'
                            name='start'
                            onChange={() => { setVariableHorario(e => ({ ...e, start: !e.start })) }}
                        />
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for='horarioSalida' sm={3}> Horario Salida </Label>
                </FormGroup>

                <FormGroup row>
                    <Col sm={3}>
                        { variableHorario.end ? null : (
                            <Input
                                type='time'
                                name='horarioTrabajo.end'
                                innerRef={register}
                            />
                        )}
                    </Col>
                    <Col sm={3}>
                        <CustomInput
                            value={true}
                            id='varialbe2'
                            type='checkbox'
                            label='Variable'
                            name='end'
                            onChange={() => { setVariableHorario(e => ({ ...e, end: !e.end })) }}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className='col-sm-12' row inline>

                    <CustomInput
                        className='mr-3'
                        id='lunes'
                        name='horarioTrabajo.days'
                        value={'L'}
                        type='checkbox'
                        label='L'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-3'
                        id='martes'
                        name='horarioTrabajo.days'
                        value={'M'}
                        type='checkbox'
                        label='M'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-3'
                        id='miercoles'
                        name='horarioTrabajo.days'
                        type='checkbox'
                        value={'MI'}
                        label='MI'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-1'
                        id='jueves'
                        name='horarioTrabajo.days'
                        value={'J'}
                        type='checkbox'
                        label='J'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-3'
                        id='viernes'
                        name='horarioTrabajo.days'
                        value={'V'}
                        type='checkbox'
                        label='V'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-3'
                        id='sabado'
                        name='horarioTrabajo.days'
                        value={'S'}
                        type='checkbox'
                        label='S'
                        innerRef={register}
                    />
                    <CustomInput
                        className='mr-3'
                        id='domingo'
                        name='horarioTrabajo.days'
                        value={'D'}
                        type='checkbox'
                        label='D'
                        innerRef={register}
                    />
                </FormGroup>

                <FormGroup row>

                <Col sm={3} hidden={readOnly}>
                    <CancelButton type='button' onClick={addingForm}>
                    Agregar Horario
                    </CancelButton>
                </Col>

            </FormGroup>
        </div>
    );
};

export default HorarioLaboral;