import axios from './axios';
/**
 * @param {Object} file - es un objecto tipo file
 * @returns {String} retorna un String ObjectId del file subido
 */
export default async (file = {}) => {
  if (Object.entries(file).length === 0) return;
  const formData = new FormData();
  formData.append('file', file[0]);
  const {
    data: { upload },
  } = await axios.post('/archivo/carga', formData, {
    headers: { token: localStorage.getItem('pv2token') },
  });
  return upload
};
