import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { schemaDescuentoInfonavit } from '../../../constans/yupSchemas';
import { createDescuento, descuentoEditFinish, editDescuento, deleteDescuento } from '../../../store/descuentosDuck';
import Arrows from '../../ui/Arrows';
import ButtonDowload from '../../ui/ButtonDowload';
import CustomSelect from '../../ui/CustomSelect';
import Icons from '../../ui/Icons';
import InputFile from '../../ui/InputFile';
import SweetAlert from '../../ui/SweetAlet';
import moment from 'moment';
import {
    CancelButton,
    InputUtils,
    LabelForms,
    SaveButton,
    SubTitleMenu
} from '../VacacionesViews/styled';

const tiposDescuentos = [
    { value: 'Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores', label: 'Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores' },
    { value: 'Pago por crédito de vivienda', label: 'Pago por crédito de vivienda' }
];

const tiposMonto = [
    { value: 'tasa', label: 'Porcentaje' },
    { value: 'monto', label: 'Cuota fija'},
    { value: 'factor', label: 'Factor de descuento'}
];

const FormDescuentoSalario = React.memo(({ history }) => {
    const periodo = useSelector(state => state.colaborador.InformacionNomina ? state.colaborador.InformacionNomina.modalidadDePago : '');
    const isEdit = useSelector(state => state.descuentos.isEdit);
    let dto = useSelector(state => state.descuentos.dto);
    const { permisos = {} } = useSelector((state) => state.user);

    const dispatch = useDispatch();
    const [isFormEdit, setFormEdit] = useState(false);
    const [tipo, setTipo] = useState('');
    const [err, setErr] = useState({});
    
    const { register, handleSubmit, errors, control, setValue } = useForm({
        resolver: yupResolver(schemaDescuentoInfonavit),
    });

    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);
    
    useEffect(()=>{
        if(isEdit){
            setFormEdit(true);
        }
    },[isEdit]);
    

    const cancel = async () =>{
        await dispatch(descuentoEditFinish());
    }

    const elminateUtil = async () => {
        try {
            const { isConfirmed } = await SweetAlert({
                title: 'Seguro que desea eliminar?',
                showCancelButton: 'Cancelar',
            });
            if (isConfirmed) {
                await dispatch(deleteDescuento(dto._id));
                SweetAlert({title:'Descuentos actualizados', icon: 'success'});
                history.push('/');
            }
        } catch (err) {
            SweetAlert({title:'Error!', text: err, icon: 'error'});
            console.log(err);
        }
    };

    const onSubmit = async (data) => {
        console.log('action btn');
        try {
            data.infonavit = true;
            if(isEdit)
                await dispatch(editDescuento(data));
            else
                await dispatch(createDescuento(data));
            SweetAlert({title:'Descuentos actualizados', icon: 'success'});
            history.push('/');
        } catch (err) {
            SweetAlert({title:'Error!', text: err, icon: 'error'});
            console.log(err);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
            {isEdit ?
            <FormGroup row>
                <Col sm={1}>
                    <Arrows
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                    />
                </Col>
                <Col sm={1} hidden={!dto.active || permisos.colaboradorInfonavit !== 'write'}>
                    <Icons onClick={elminateUtil} />
                </Col>
                <Col sm={1} hidden={!dto.active || permisos.colaboradorInfonavit !== 'write'}>
                    <Icons
                        pencil
                        onClick={() => {
                            setFormEdit(!isFormEdit);
                        }}
                    />
                </Col>
            </FormGroup>
            :''}

            
            <FormGroup row>
                <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                    <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >{isEdit ? 'Editar' : 'Nuevo'} Crédito Infonavit</SubTitleMenu>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo de Descuento*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        onChange={e => setTipo(e.target.value)}
                        name='tipoDescuento'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposDescuentos}
                        defaultValue={dto.tipoDescuento ? dto.tipoDescuento : ''}
                        disabled={isFormEdit}
                    />
                    {err.tipoDescuento && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El tipo de descuento es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        name='tipoMonto'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposMonto}
                        defaultValue={dto.tipoMonto ? dto.tipoMonto : ''}
                        disabled={isFormEdit}
                    />
                {err.tipoMonto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El tipo de monto es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='monto'
                        step='0.0001'
                        type='number'
                        ref={register}
                        placeholder='Ingrese el monto'
                        defaultValue={dto.monto ? dto.monto : ''}
                        disabled={isFormEdit}
                    />
                    {err.monto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El monto es requerido y debe ser numérico
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Número de Crédito*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='numeroCredito'
                        type='text'
                        ref={register}
                        defaultValue={dto.numeroCredito ? dto.numeroCredito : ''}
                        placeholder='Ingrese el número de crédito'
                        disabled={isFormEdit}
                    />
                    {err.numeroCredito && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El número de crédito es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Periodicidad</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='periodicidad.periodo'
                        type='text'
                        ref={register}
                        // disabled={ true }
                        value={ periodo }
                    />
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Fecha de Aplicación*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='fechaAplicacion'
                        type='date'
                        ref={register}
                        defaultValue={dto.fechaAplicacion ? moment(dto.fechaAplicacion).utc().format('YYYY-MM-DD') : ''}
                    />
                    {err.fechaAplicacion && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        La fecha de aplicación es requerida
                        </span>
                    )}
                </Col>
            </FormGroup>
            
            

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Aviso de Retención</LabelForms>
                </Label>

                <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
                    {isEdit && dto.archivo && isFormEdit
                        ?
                        <ButtonDowload
                            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${dto.archivo}`}
                        />
                        : <InputFile name='archivo' id='archivo' register={register} disabled={isFormEdit} />}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col sm={dto.active === false ? {offset: 9, size: 3} : { offset: 6, size: 3 }}>
                    <CancelButton
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                        style={{ marginLeft: 'auto' }}>
                        Cancelar
                    </CancelButton>
                </Col>
                <Col sm={3} hidden={dto.active === false || permisos.colaboradorInfonavit !== 'write'}>
                    <SaveButton type='submit' disabled={isFormEdit}>Guardar</SaveButton>
                </Col>
            </FormGroup>
        </Form>
    );
});

export default FormDescuentoSalario;