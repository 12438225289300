import { faBalanceScale, faFileContract, faUsdSquare, faUsersCog, faFileInvoiceDollar } from '@fortawesome/pro-light-svg-icons';
import {
  faList,
  faUserClock,
  faUserMinus, faUserPlus, faClock, faUsersClass,faMoneyBill,faExclamationCircle, faNotesMedical
} from '@fortawesome/pro-solid-svg-icons';

const routes = (permisos) => {
  let subRoutesTimbrado = []
  if (permisos.timbradoNomina === 'none') {
    subRoutesTimbrado = [
      {
        path: "/configuracion-nomina",
        name: "Prenomina",
        icon: faList,
      },
      {
        path: "/extraordinaria",
        name: "Extraordinaria",
        icon: faExclamationCircle,
      }
    ]
  } else {
    subRoutesTimbrado = [
      {
        path: "/configuracion-nomina",
        name: "Prenomina",
        icon: faList,
      },
      {
        path: "/extraordinaria",
        name: "Extraordinaria",
        icon: faExclamationCircle,
      },
      {
        path: "/timbrar",
        name: "Timbrado",
        icon: faUsdSquare,
      },
      // {
      //   path: "/conciliacion-sat",
      //   name: "Conciliación SAT",
      //   icon: faFileInvoiceDollar,
      // }
    ]
  }
  return [
    {
      subMenuTitle: "",
      subRoutes: [
        {
          path: "/lista-colaboradores",
          name: "Listado",
          icon: faList,
        },
        {
          path: "/proceso",
          name: "En proceso",
          icon: faUserClock,
        },
        {
          path: "/altas",
          name: "Dar de alta",
          icon: faUserPlus,
        },

        {
          path: "/bajas",
          name: "Dar de baja",
          icon: faUserMinus,
        },
      ],
    },
    {
      subMenuTitle: "Calendario",
      subRoutes: [],
    },
    {
      subMenuTitle: "Juridico RH",
      subRoutes: [
        {
          path: "/juridico-citatorios",
          name: "Citatorios",
          icon: faFileContract,
        },
        {
          path: "/juridico-finiquitos",
          name: "Finiquitos",
          icon: faMoneyBill
        },
        {
          path: "/juridico-demandas",
          name: "Demandas",
          icon: faBalanceScale,
        },
      ],
    },
    {
      subMenuTitle: "",

      subRoutes: subRoutesTimbrado,
    },
    {
      subMenuTitle: "",
      subRoutes: [
        {
          path: "/horas-extra",
          name: "Horas Extra",
          icon: faClock,
        },
        {
          path: "/faltas-masivo",
          name: "Faltas",
          icon: faUsersClass,
        },
        {
          path: "/ajustes-masivo",
          name: "Ajustes",
          icon: faUsersCog,
        },
      ],
    },
    {
      subMenuTitle: "IMSS/INFONAVIT",
      subRoutes: [
        {
          path: "/imss-idse",
          name: "IDSE",
          icon: faNotesMedical,
        },
        {
          path: "/imss-conciliacion",
          name: "Conciliación",
          icon: faNotesMedical,
        },
        {
          path: "/imss-variabilidad",
          name: "Variabilidad",
          icon: faNotesMedical,
        },
      ],
    },
  ]
};
export default routes;
