import React, { useState } from "react";
import { FormGroup, CustomInput, Label, Col } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../../../constans/colors';

const Notificacion = React.memo(({ register = () => {} }) => {

  return (
    <FormGroup className="dm-c">
      <Label className="labelGray">¿Notificar al Colaborador? </Label> &nbsp;
      <FontAwesomeIcon
            id='svg'
            color={colors.secondary}
            icon={faInfoCircle}
            title='Se le enviará un correo al colaborador avisando del estudio agendado'
          />
      <FormGroup check >
        <Label sm={2} check style={{ color: "#B1B1B1" }}  inline="true" className="radio-organize">
          <CustomInput
            type="radio"
            id='notificacion1'
            name="notificacion"
            value={true}
            innerRef={register()}
          />
          Si
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
          <CustomInput
            type="radio"
            id='notificacion2'
            name="notificacion"
            value={false}
            innerRef={register()}
          />
          No
        </Label>
      </FormGroup>
    </FormGroup>
  );
});

export default Notificacion;
