import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import { yupResolver } from '@hookform/resolvers/yup';
import InputImage from '../../ui/InputImage';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from './styled';
import { schemaUtilitario } from '../../../constans/yupSchemas';
import { fetchCreateUtilitario, fetchListaUtilitario, fetchUpdateUtilitario } from '../../../store/utilitarioDucks';
import CurrencyFormat from 'react-currency-format';
import '../Forms/CondicionesLaborales.css';
import atob from 'atob';
import { saveAs } from 'file-saver';
import axios from 'axios';
import SweetAlert from '../../ui/SweetAlet';


const api = process.env.REACT_APP_API_URL;

const estado = [
  { value: 'usado', label: 'usado' },
  { value: 'nuevo', label: 'nuevo' },
];

const TablaUtilitario = React.memo(({ history }) => {
  const [valor, setValor] = useState({ valorF: '' });
  const [err, setErr] = useState({});
  const { valorF } = valor;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(schemaUtilitario),
  });
  const [utilitario, setUtilitario] = useState({});
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const downloadPdf = async (e) => {
    e.preventDefault()
    if (schemaUtilitario.isValidSync(getValues())) {
      const save = await onSubmit(getValues())
      if (save) {
        setUtilitario(save)
        const file = await axios.get(`${api}/utilitario/generar-oficio/utilitario?idUtilitario=${save._id}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `Responsiva${moment().format('YYYYMMDD')}`)
      }
    } else {
      SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const save = async (data) => {
    const save = await onSubmit(data)
    if (save) {
      SweetAlert({ title: 'Utilitario guardado', icon: 'success' });
      history.push('/tabla-utilitario');
    }
  }

  const onSubmit = async (data) => {
    try {
      let save
      data.valorFactura = !valor.valorF ? 0 : valor.valorF;
      data.fechaEntrega = parseInt(moment(data.fechaEntrega).format('x'));
      data.fechaRestitucion = parseInt(
        moment(data.fechaRestitucion).format('x')
      );
      if (utilitario._id) {
        save = await dispatch(fetchUpdateUtilitario(data, 'herramienta'));
      } else {
        save = await dispatch(fetchCreateUtilitario(data, 'herramienta'));
      }
      await dispatch(fetchListaUtilitario('herramienta'));
      return save
    } catch (error) {
      SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(save)}>
      <FormGroup row>
        <Col sm={3}>
          <InputImage name='fotos[0]' id='foto0' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[1]' id='foto1' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[2]' id='foto2' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[3]' id='foto3' register={register} />
        </Col>
      </FormGroup>
      <br />
      <br />
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Nombre*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4, push: 12 }}>
          <InputUtils
            name='nombre'
            type='text'
            placeholder='Ej. Macbook'
            ref={register}
          />
          {err.nombre && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Cantidad</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='cantidad'
            min='1'
            type='number'
            defaultValue={1}
            placeholder='Ej. $15000'
            ref={register({
              min: 1,
            })}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>No. de Serie</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='numeroSerie'
            type='text'
            ref={register}
            placeholder='Ej. 12yu28un'
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>No. de Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='numeroFactura'
            type='text'
            placeholder='Ej. 451'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Color</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='color'
            type='text'
            placeholder='Ej. Plata'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Estado*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CustomSelect
            type='select'
            name='estado'
            innerRef={register}
            control={control}
            options={estado}
          />
          {err.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Entregado por*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='entregadoPor'
            type='text'
            placeholder='Entregado por'
            ref={register}
          />
          {err.entregadoPor && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Debes especificar por quien fue entregado
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Valor Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            value={valorF}
            name='valorFactura'
            placeholder='Valor Factura'
            innerRef={register()}
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setValor({ valorF: value })
            }}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Fecha Entrega*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='fechaEntrega'
            type='date'
            placeholder='Fecha entrega'
            ref={register}
          />
          {err.fechaEntrega && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de entrega es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Fecha Restitución*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            name='fechaRestitucion'
            type='date'
            placeholder='Fecha restitucion'
            ref={register}
          />
          {err.fechaRestitucion && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de restitucion es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Otras Características</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name='otrasCaracteristicas'
            placeholder='Otras carecteristicas'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Observaciones Generales</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name='observaciones'
            placeholder='Observaciones'
            ref={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} className='mb-3'>
          <SaveButton onClick={(e) => downloadPdf(e)}>Generar Responsiva</SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
        <Label sm={3}>
          <LabelForms>Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile name='factura' id='factura' register={register({ required: true })} />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Oficio de Entrega</LabelForms>{' '}
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile
            name='oficioEntrega'
            id='oficioEntrega'
            register={register({ required: true })}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Comodato</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile name='comodato' id='comodato' register={register({ required: true })} />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  );
});

export default TablaUtilitario;
