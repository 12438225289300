export const VACACIONES_START = 'VACACIONES_START';

export const VACACIONES_LISTA_EXITO = 'VACACIONES_LISTA_EXITO';
export const VACACIONES_LISTA_ERROR = 'VACACIONES_LISTA_ERROR';
export const CREAR_VACACIONES_EXITO = 'CREAR_VACACIONES_EXITO';
export const CREAR_VACACIONES_ERROR = 'CREAR_VACACIONES_ERROR';
const initialState = {
  loading: false,
  error: null,
  listaVacaciones: [],
  vaca: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VACACIONES_START:
      return { ...state, loading: true };
    case VACACIONES_LISTA_EXITO:
      return { ...state, loading: false, listaVacaciones: [...payload] };
    case VACACIONES_LISTA_ERROR:
      return { ...state, loading: false };
    case CREAR_VACACIONES_EXITO:
      return { ...state, loading: false };
    case CREAR_VACACIONES_ERROR:
      return { ...state, loading: false };
    case 'AGREGAR_VACA':
      return { ...state, vaca: { ...payload } };
    default:
      return state;
  }
};

export const vacacionesStart = () => ({
  type: VACACIONES_START,
});

export const listaVacacionesExito = (payload) => ({
  type: VACACIONES_LISTA_EXITO,
  payload,
});

export const listaVacacionesError = () => ({
  type: VACACIONES_LISTA_ERROR,
});

export const createVacacionesExito = () => ({
  type: CREAR_VACACIONES_EXITO,
});

export const createVacacionesError = () => ({
  type: CREAR_VACACIONES_ERROR,
});

export const agregarVaca = (payload) => ({
  type: 'AGREGAR_VACA',
  payload,
});

export const fetchListaVacaciones = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const { _id } = getState().colaborador;
    const {
      data: { resultado = [] },
    } = await axios.get(`/vacaciones/vacaciones_colaborador/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaVacacionesExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaVacacionesError());
    throw data;
  }
};

export const fetchCreateVacaciones = (dataVacaciones) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const { _id } = getState().colaborador;
    const {
      constancia = {},
      ...rest
    } = dataVacaciones;
    const formData = new FormData();
    formData.append('constancia', constancia[0]);
    formData.append('colaborador', _id);
    formData.append(
      'dataVacaciones',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.post('/vacaciones/crear', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createVacacionesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createVacacionesError());
    throw data;
  }
};

export const fetchUpdateVacaciones = (dataVacaciones) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const { _id } = getState().vacaciones.vaca;
    const {
      constancia = {},
      pago = {},
      ...rest
    } = dataVacaciones;
    const formData = new FormData();
    formData.append('constancia', constancia[0]);
    formData.append('pago', pago[0]);
    formData.append(
      'dataVacaciones',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.put(`/vacaciones/editar_vacacion/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(createVacacionesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createVacacionesError());
    throw data;
  }
};

export const fetchActualizarVacaciones = (dataVacaciones) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const {
      constancia = {},
      id,
      ...rest
    } = dataVacaciones;
    const formData = new FormData();
    formData.append('constancia', constancia[0]);
    formData.append(
      'dataVacaciones',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.put(`/vacaciones/editar_vacacion/${id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(createVacacionesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createVacacionesError());
    throw data;
  }
};

export const deleteVaca = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const { _id } = getState().vacaciones.vaca;
    await axios.delete(`/vacaciones/eliminar_vacacion/${_id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createVacacionesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createVacacionesError());
    throw data;
  }
};

export const listaVacacionesFiniquitos = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(vacacionesStart());
    const {
      data: { resultado = [] },
    } = await axios.get(`/vacaciones/vacaciones_colaborador/${id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaVacacionesExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaVacacionesError());
    throw data;
  }
};
