import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import CustomInput from 'reactstrap/lib/CustomInput';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  TextAreaUtils,
} from '../VacacionesViews/styled';
import {
  fetchUpdateIncapacidades,
  fetchListaIncapacidades,
  deleteIncapacidad
} from '../../../store/incapacidadesDuck';
import {
  controlEnfermedadGeneral,
  controlMaternidad,
  controlIncapacidadRT,
  escenariosRT,
  scrollTipo,
  secuelaConsecuenciaRT,
  vacio
} from './Constantes';
import ButtonDowload from '../../ui/ButtonDowload';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaIncapacidades } from '../../../constans/yupSchemas';

const IncapacidadesEdit = React.memo(({ history }) => {
  const [isEdit, setIsEdit] = useState(true);
  const dispatch = useDispatch();
  const { incapacidad = {} } = useSelector((state) => state.incapacidades);
  const { permisos = {} } = useSelector((state) => state.user);
  
  const {
    tipo = '',
    escenario = '',
    secuelaConsecuencia = '',
    controlIncapacidad = '',
    folio = '',
    porcentaje = '',
    especificaOtros = '',
    fechaInicio = '',
    fechaFin = '',
    personaResponsable = '',
    observaciones = '',
    calendario = '',
    archivo = '',
  } = incapacidad;
  const [porcentajeValue, setPorcentajeValue] = useState({ valPercent: porcentaje })
  const { valPercent } = porcentajeValue;
  const [riesgoTrabajo, setRiesgoTrabajo] = useState(tipo === 'Riesgo de trabajo');
  const [enfermedadGral, setEnfermedadGral] = useState(tipo === 'Enfermedad general');
  const [maternidad, setMaternidad] = useState(tipo === 'Maternidad');
  const [otros, setOtros] = useState(tipo === 'Otros');

  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(schemaIncapacidades)
  });
  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(deleteIncapacidad());
        await dispatch(fetchListaIncapacidades());
        history.push('/');
      }
    } catch (error) { }
  };
  const onSubmit = async (data) => {
    try {
      if (data.porcentaje > 0 && data.porcentaje <= 100) {
        console.log(data);
        await dispatch(fetchUpdateIncapacidades(data));
        SweetAlert({ title: 'Incapacidad actualizada', icon: 'success' });
        history.push('/');
      } else {
        await SweetAlert({ icon: 'error', title: 'Valores de porcentaje entre 0 y 100' })
      }
    } catch (error) {
      SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    }
  };

  const handleTipoIncapacidad = (valor) => {
    if (valor === 'Riesgo de trabajo') {
      setRiesgoTrabajo(true);
      setEnfermedadGral(false);
      setMaternidad(false);
      setOtros(false);
      setPorcentajeValue({ valPercent: '100' });
    } if (valor === 'Enfermedad general') {
      setRiesgoTrabajo(false);
      setEnfermedadGral(true);
      setMaternidad(false);
      setOtros(false);
      setPorcentajeValue({ valPercent: '60' });
    } if (valor === 'Maternidad') {
      setRiesgoTrabajo(false);
      setEnfermedadGral(false);
      setMaternidad(true);
      setOtros(false);
      setPorcentajeValue({ valPercent: '100' });
    } if (valor === 'Otros') {
      setRiesgoTrabajo(false);
      setEnfermedadGral(false);
      setMaternidad(false);
      setOtros(true);
      setPorcentajeValue({ valPercent: '' });
    }
  };
  const fillPercent = e => {
    setPorcentajeValue({
      ...porcentajeValue,
      valPercent: e.target.value
    })

  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.push('/');
            }}
          />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorIncapacidades !== 'write'}>
          <Icons onClick={elminateUtil} />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorIncapacidades !== 'write'}>
          <Icons
            pencil
            onClick={() => {
              setIsEdit((state) => !state);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Inicio*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            name='fechaInicio'
            defaultValue={moment(fechaInicio).utc().format('YYYY-MM-DD')}
            type='date'
            ref={register}
          />
          {err.fechaInicio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de inicio es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Conclusión*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            name='fechaFin'
            type='date'
            defaultValue={moment(fechaFin).utc().format('YYYY-MM-DD')}
            ref={register}
          />
          {err.fechaFin && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de conclusión es requerida
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={3}>
          <LabelForms>Ramo de Seguro*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CustomSelect
            disabled={isEdit}
            onChange={(e) => handleTipoIncapacidad(e.target.value)}
            name='tipo'
            type='select'
            innerRef={register}
            defaultValue={tipo}
            control={control}
            options={scrollTipo}
          />
          {err.tipo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Ramo de Seguro es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      {riesgoTrabajo
        ? <FormGroup row>
          <Label sm={3}>
            <LabelForms>Escenarios*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
            <CustomSelect
              disabled={isEdit}
              name='escenario'
              type='select'
              defaultValue={escenario}
              innerRef={register}
              control={control}
              options={escenariosRT}
            />
            {err.escenario && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El escenario es requerido
              </span>
            )}
          </Col>
          <Col sm={4}></Col>
          <Label sm={3}>
            <LabelForms>Secuela o Consecuencia*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <CustomSelect
              disabled={isEdit}
              name='secuelaConsecuencia'
              type='select'
              defaultValue={secuelaConsecuencia}
              innerRef={register}
              control={control}
              options={secuelaConsecuenciaRT}
            />
            {err.secuelaConsecuencia && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La secuela o consecuencia es requerida
              </span>
            )}
          </Col>
          <Col sm={4}></Col>
        </FormGroup>
        : null}
      {otros
        ? <FormGroup row>
          <Label sm={3}>
            <LabelForms>Especifica Otros*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <InputUtils
              disabled={isEdit}
              name='especificaOtros'
              type='text'
              defaultValue={especificaOtros}
              placeholder='Especifica otros'
              ref={register}
            />
            {err.especificaOtros && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Otros es requerido
              </span>
            )}
          </Col>
          <Col sm={4}></Col>
        </FormGroup>
        : null}
      <FormGroup row>
        {!otros
          ? <>
            <Label sm={3}>
              <LabelForms>Tipo de Incapacidad*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
              <CustomSelect
                disabled={isEdit}
                name='controlIncapacidad'
                type='select'
                defaultValue={controlIncapacidad}
                innerRef={register}
                control={control}
                options={
                  riesgoTrabajo ? controlIncapacidadRT
                    : maternidad ? controlMaternidad
                      : enfermedadGral ? controlEnfermedadGeneral
                        : vacio
                }
              />
              {err.controlIncapacidad && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El tipo de incapacidad es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
          </> : null
        }
        <Label sm={3}>
          <LabelForms>Folio del Certificado*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
          <InputUtils
            disabled={isEdit}
            name='folio'
            type='text'
            defaultValue={folio}
            placeholder='Folio del certificado'
            ref={register}
          />
          {err.folio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El folio del certificado' es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Porcentaje (%)*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
          <InputUtils
            disabled={isEdit}
            name='porcentaje'
            type='number'
            onChange={fillPercent}
            defaultValue={valPercent}
            placeholder='Porcentaje'
            ref={register}
          />
          {err.porcentaje && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El porcentaje es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Persona Responsable*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
          <InputUtils
            disabled={isEdit}
            name='personaResponsable'
            type='text'
            defaultValue={personaResponsable}
            placeholder='Persona responsable'
            ref={register}
          />
          {err.personaResponsable && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La persona responsable es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
            <LabelForms>Agregar a Calendario*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
            <Label sm={2} check style={{ marginLeft: '20px' }}>
            <CustomInput id='calendario1' type='radio' name='calendario' defaultChecked={calendario === true} value='true' innerRef={register()} />
                Si
            </Label>
            <Label sm={2} check style={{ marginLeft: '40px' }}>
            <CustomInput id='calendario2' type='radio' name='calendario'  defaultChecked={calendario === false} value='false' innerRef={register()} />
                No
            </Label>
            {err.calendario && (
            <FormGroup row>
                <Label>
                </Label>
                <Col sm={9}>
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    Indique si desea mostrar esta incapacidad en el calendario
                </span>
                </Col>
            </FormGroup>
            )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Observaciones</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
          <TextAreaUtils
            disabled={isEdit}
            name='observaciones'
            defaultValue={observaciones}
            placeholder='Observaciones'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Comprobante</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {archivo
            ?
            !isEdit ? <InputFile disabled={isEdit} name='archivo' id='archivo' register={register} />
              : <ButtonDowload Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${archivo}`} />
            : <InputFile disabled={isEdit} name='archivo' id='archivo' register={register} />}
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          {!isEdit && (
            <SaveButton type='submit'>Guardar</SaveButton>
          )}
        </Col>
      </FormGroup>
    </Form>
  );
});

export default IncapacidadesEdit;