export const scrollTipo =[
    { value: 'Riesgo de trabajo', label: 'Riesgo de trabajo' },
    { value: 'Enfermedad general', label: 'Enfermedad general' },
    { value: 'Maternidad', label: 'Maternidad' },
    { value: 'Otros', label: 'Otros' },
];

export const escenariosRT = [
    { value: 'Accidente de trabajo', label: 'Accidente de trabajo' },
    { value: 'Accidente de trayecto', label: 'Accidente de trayecto' },
    { value: 'Enfermedad profesional', label: 'Enfermedad profesional' },
];

export const secuelaConsecuenciaRT =[
    { value: 'Ninguna', label: 'Ninguna' },
    { value: 'Incapacidad Temporal', label: 'Incapacidad Temporal' },
    { value: 'Valuación inicial provisional', label: 'Valuación inicial provisional' },
    { value: 'Valuación inicial definitiva', label: 'Valuación inicial definitiva' },
    { value: 'Defunción', label: 'Defunción' },
    { value: 'Recaida', label: 'Recaida' },
    { value: 'Valuación posterior a la fecha de alta', label: 'Valuación posterior a la fecha de alta' },
    { value: 'Revaluación provisional', label: 'Revaluación provisional' },
    { value: 'Revaluación sin alta medica', label: 'Revaluación sin alta medica' },
    { value: 'Revaluación definitiva', label: 'Revaluación definitiva' },
];

export const controlIncapacidadRT = [
    { value: 'Ninguna', label: 'Ninguna' },
    { value: 'Unica, inicial, subsecuente, alta medica o ST-2', label: 'Unica, inicial, subsecuente, alta medica o ST-2' },
    { value: 'Valuación o ST-3', label: 'Valuación o ST-3' },
    { value: 'Defunción o ST-3', label: 'Defunción o ST-3' },
];

export const controlEnfermedadGeneral = [
    { value: 'Unica', label: 'Unica' },
    { value: 'Inicial', label: 'Inicial' },
    { value: 'Subsecuente', label: 'Subsecuente' },
    { value: 'Alta medica o ST-2', label: 'Alta medica o ST-2' },
];

export const controlMaternidad = [
    { value: 'Prenatal', label: 'Prenatal' },
    { value: 'Enlace', label: 'Enlace' },
    { value: 'Postnatal', label: 'Postnatal' },
];

export const vacio = [  
    { value: 'Seleccione tipo', label: 'Seleccione tipo' },
]