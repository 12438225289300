import React, { useCallback } from 'react';
import { faTrash, faKey } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { eliminarUsuarioAction } from "../../store/usuariosDuck";
import BotonEditar from "../ui/BotonEditar";
import Spiner from "../ui/Spiner";
import SweetAlert from '../ui/SweetAlet';

const UserItem = ({ usuario, toggle, modal, setUser }) => {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.usuarios)
    const { push } = useHistory();

    const eliminarUsuario = useCallback(async(idUsuario)=>{
        try{
            await dispatch(eliminarUsuarioAction(idUsuario))
            SweetAlert({ icon: 'success', title: 'Usuario Eliminado' });
            push('/dashboard/empresa/usuarios')
        }catch (e) {
            SweetAlert({ icon: 'error', title: 'Error!', text: e });
        }
    },[dispatch])

    const eliminarBtn = async () => {
        const { isConfirmed, isDismissed } = await SweetAlert({
            title: '¿Seguro que desea eliminar?',
            text: 'Esta acción no se podrá revertir.',
            showCancelButton: 'Cancelar',
        });
        if (isConfirmed) {
            eliminarUsuario(usuario._id)
        }
    };
    const editarBtn = () =>{
        setUser(usuario._id);
        toggle();
    }

    const tipoUsuario = (tipoUsuario)=>{
        switch (tipoUsuario) {
            case 'owner': return 'Dueño'
            case 'user': return 'Usuario'
            case 'admin': return 'Administrador'
            default : return 'Sin asignar'
        }
    }

    if(loading) return <Spiner/>

    return (
        <tr>
            <td>{usuario.usuario}</td>
            <td>{tipoUsuario(usuario.tipoUsuario)}</td>
            <td>{ !usuario.principal ?
                <BotonEditar clickHandler={eliminarBtn} icon={ faTrash } style={{color:'#737373'}}/>
                : ''}
            </td>
            <td>
                <BotonEditar clickHandler={editarBtn} icon={ faKey } style={{color:'#737373'}}/>
            </td>
        </tr>
    )};

export default UserItem;