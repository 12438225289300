import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Label, Input, Button, Col, Row, FormGroup, CustomInput, UncontrolledCollapse } from "reactstrap";
import { StyleInputs, ContainerVariables } from "./styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Variables from "./Variables";

const topButtons = ["Max", "Min", "Promedio", "Sum", "Abs"];
const numberButtons = [7, 8, 9, 4, 5, 6, 1, 2, 3, "<--", 0, "."];
const calculateButtons = ["(", ")", "+", "-", "*", "/", "Validar", "Ok"];

export default ({ toggle, open = false, onSave, catalogo = "" }) => {
  const [formulaData, setFormulaData] = useState({ ISR: false, IMSS: false, excentoISR: "", excentoIMSS: "", monto: "" });
  const [formula, setFormula] = useState("");
  const [varToggle, setVarToggle] = useState({});
  const [propText, setPropText] = useState("monto");
  useEffect(() => {
    setFormula(formulaData[propText]);
  }, [propText]);

  const setFormulaBtn = (value) => {
    let cv = formula;
    switch (value) {
      case "<--":
        if (cv.endsWith("] ")) {
          cv = cv.slice(0, cv.lastIndexOf("["));
          break;
        }
        if (cv.endsWith("( ")) {
          cv = cv.slice(0, cv.slice(0, -1).lastIndexOf(" "));
          break;
        }
        cv.endsWith(" ") && (cv = cv.slice(0, -1));
        cv = cv.slice(0, -1);
        break;
      case "Ok":
        setFormulaData({ ...formulaData, [propText]: cv });
        cv = "";
        break;
      default:
        let space = "";
        if (cv === "") cv = " ";
        if (!numberButtons.includes(value)) space = " ";
        if (numberButtons.includes(parseInt(cv.slice(-1))) && !numberButtons.includes(value)) cv = cv + " ";
        cv = `${cv}${value}${space}`;
        break;
    }
    setFormula(cv);
  };

  return (
    <Modal centered isOpen={open} toggle={toggle} size="lg" style={{ width: "1500px", height: "900px", margin: "10px auto" }}>
      <ModalHeader style={{ background: "#2B388F", color: "white" }}>Nueva Formula</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label md={2}>Monto</Label>
          <Col className="col-5">
            <Input type="text" readOnly value={formulaData.monto} onFocus={() => setPropText("monto")} />
          </Col>
          <Col className="col-4 offset-1 text-right">
            <StyleInputs>
              <Button disabled={formulaData.monto === ""} color="primary" size="sm" onClick={() => onSave(formulaData)}>
                Guardar
              </Button>
            </StyleInputs>
          </Col>
        </FormGroup>
        <FormGroup row>
          <div className="col-2">
            <CustomInput type="radio" value={true} checked={formulaData.IMSS} onClick={(e) => setFormulaData({ ...formulaData, IMSS: !formulaData.IMSS })} label="IMSS" />
          </div>
          <Col md={4}>
            <Input
              step=".01"
              placeholder="Excento"
              type="text"
              min="0.00"
              onFocus={() => setPropText("excentoIMSS")}
              value={formulaData.excentoIMSS}
              // onChange={(e) => setFormulaData({ ...formulaData, excentoIMSS: e.target.value })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <div className="col-2">
            <CustomInput type="radio" label="ISR" value={true} checked={formulaData.ISR} onClick={(e) => setFormulaData({ ...formulaData, ISR: !formulaData.ISR })} />
          </div>
          <Col md={4}>
            <Input
              placeholder="Excento"
              type="text"
              min="0.00"
              value={formulaData.excentoISR}
              onFocus={() => setPropText("excentoISR")}
              // onChange={(e) => setFormulaData({ ...formulaData, excentoISR: e.target.value })}
            />
          </Col>
        </FormGroup>
        <Row>
          <ContainerVariables className="col">
            <h4 style={{ margin: "5px" }}>Variables:</h4>
            {Object.keys(Variables).map((v) => (
              <div key={v}>
                <h4 id={`toggler-${v}`} block style={{ margin: "5px" }} onClick={() => setVarToggle({ [v]: !varToggle[v] })}>
                  {v} <FontAwesomeIcon icon={varToggle[v] ? faAngleDown : faAngleRight} />
                </h4>
                <UncontrolledCollapse toggler={`toggler-${v}`}>
                  {Variables[v].map((vi) => (
                    <h5 onClick={() => setFormulaBtn(`[${vi}]`)} key={vi}>
                      {vi}
                    </h5>
                  ))}
                </UncontrolledCollapse>
              </div>
            ))}
          </ContainerVariables>
          <Col className="px-2">
            <Row className="mb-3">
              <Col>
                <Input
                  style={{ width: "500px", height: "80px", background: "#EBECF4", direction: "rtl", textAlign: "right", unicodeBidi: "plaintext", textOverflow: "ellipsis" }}
                  value={formula}
                  readOnly={true}
                />
              </Col>
            </Row>
            <StyleInputs>
              <Row className="mb-3">
                {topButtons.map((btn) => (
                  <Col key={btn} className="col-auto pr-1">
                    <Button block size="sm" style={{ width: "85px", height: "35px" }} onClick={() => setFormulaBtn(`${btn}(`)}>
                      {btn}
                    </Button>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col className="col-7">
                  <Row>
                    {numberButtons.map((btn, i) => (
                      <Col key={btn} className="col-auto pr-1 pb-3">
                        <Button block size="sm" style={{ width: "79px", height: "61px" }} onClick={() => setFormulaBtn(btn)}>
                          {btn}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>

                <Col className="col-5">
                  <Row>
                    {calculateButtons.map((btn) => (
                      <Col key={btn} className="col-auto pr-1 pb-3">
                        <Button block disabled={btn === "Validar"} color="primary" size="sm" style={{ width: "79px", height: "61px" }} onClick={() => setFormulaBtn(btn)}>
                          {btn !== "Validar" && btn}
                        </Button>
                      </Col>
                    ))}{" "}
                  </Row>
                </Col>
              </Row>
            </StyleInputs>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};
