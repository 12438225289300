import styled from "styled-components";
import colors from "../../../constans/colors";

const blue = "#2b388f";
export const BtnGroup = styled.div`
  button {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.258333px;
    width: 203px;
    height: 51px;
  }
  button:not(.dropdown-item) {
    border: 2px solid ${blue};
    color: ${blue};
    width: 200px;
    background: transparent;
  }

  button:hover {
    background: ${blue} !important;
    border: 2px solid transparent;
    color: white;
  }
  button:active {
    background: ${blue} !important;
    border: 2px solid transparent;
    color: white;
  }
`;

export const StyleInputs = styled.div`
  input,
  button,
  select {
    border: 2px solid ${blue};
    height: 50px;
    color: ${blue};
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.258333px;
  }

  display: contents;
  button.btn-primary,
  button.btn-primary:active,
  button.btn-primary:hover {
    border: 2px solid ${blue};
    color: white;
    background-color: ${blue} !important;
    height: 50px;
  }

  button.btn-secondary,
  button.btn-secondary:active,
  button.btn-secondary:hover {
    border: 2px solid ${blue};
    color: ${blue};
    background-color: white !important;
    height: 50px;
  }
  button.btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    height: 40px;
  }
  button.btn-sm:hover {
    font-size: 0.875rem;
    line-height: 1.5;
    height: 40px;
  }
`;
export const ModalStyle = styled.div`
  h5.modal-title {
    color: white;
  }
`;
export const ListContent = styled.div`
  width: 100%;
  background: #ebecf4;
  padding: 21px;
  color: #404041;
  border-radius: 4px;
  input[type="text"] {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.19375px;
    height: 46px;
    color: #a6a6a6;
  }
  .modal-body .active {
    text-decoration: none !important;
  }
  .page-item.active {
    text-decoration: none;
    min-width: auto !important;
  }
`;

export const HeaderStyle = styled.div`
  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58.2px;
    color: #404041;
    font-style: normal;
    letter-spacing: 0.62px;
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.258333px;
    color: #9b9b9b;
    margin-bottom: 8px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    /* identical to box height */
    letter-spacing: 0.297083px;
    color: #404041;
  }
`;


export const Card = styled.div`
  border-color: ${colors.secondary};
  border-width: 2px;
  border-style: solid;
  padding: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: ${(props) => (props.text ? props.text : "center")};
  transition: all 500ms ease;
  opacity: ${(props) => (props.disabled ? 0.4 : 1)};
  h2 {
    font-size: 30px;
    text-overflow: ellipsis;
    width: 340px;
    overflow: hidden;
    white-space: nowrap;
  }

`;
