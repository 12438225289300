import React , {useState} from 'react';
import ComponentDescrip from "../ui/ComponentDescrip";
import {Container, Row, Table, Button, Collapse , Card, CardBody} from 'reactstrap';
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import { CancelButton } from '../ui/styles/styles';

const Facturacion = () =>{
    const title= 'Facturación';
    const icon = faUsers;
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    return(
        <Container>
            <Row>
                <ComponentDescrip icon={icon}  title={title}/>
            </Row>
            <Row>
                <Table>
                    <thead>
                    <tr>
                        <th>N° de Factura</th>
                        <th>Fecha de emisión </th>
                        <th>Monto</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <p onClick={toggle}>Toggle</p>
                    </tr>
                    <tr>
                        <th>034</th>
                        <th>10 de Abril</th>
                        <th>$1500</th>
                        <th><CancelButton>Descargar</CancelButton></th>

                    </tr>
                    <tr>
                        <th>034</th>
                        <th>10 de Abril</th>
                        <th>$1500</th>
                        <th><CancelButton>Descargar</CancelButton></th>
                    </tr>
                    </tbody>
                </Table>
            </Row>

        </Container>
    )
}
export default Facturacion;