import React from 'react';
import {Route} from 'react-router-dom';
import RegistroEmpresa from "../../../components/empresa/RegistroEmpresa";
import Empresa from "../../../components/empresa/Empresa";
import DetalleEmpresa from "../../../components/empresa/DetalleEmpresa";
import Sucursal from "../../../components/Sucursal/Sucursal";
import NuevaSucursal from "../../../components/Sucursal/NuevaSucursal";
import DetalleDepartamento from "../../../components/departamento/DetalleDepartamento";
import NuevoDepartamento from "../../../components/departamento/NuevoDepartamento";

const CuentaRoutes = ({path,history}) =>{
    const HashEmpresas = React.memo(() =>{
        return(
            <DetalleEmpresa history={history}/> 
        )
    })
    const Facturacion = props =>{
        return(
            <h1>Facturacion</h1>
        )
    }
    return(
        <>
            <Route path={`${path}/crear-empresa`} component={RegistroEmpresa}/>
            <Route path={`${path}/facturacion`} component={Facturacion}/>
            <Route path={`${path}/empresa`} component={Empresa}/>
            <Route path={`${path}/detalle-empresa`} component={HashEmpresas} />
            <Route path={`${path}/sucursal`} component={Sucursal}/>
            <Route path={`${path}/crear-sucursal`} component={NuevaSucursal}/>
            <Route path={`${path}/departamento`} component={DetalleDepartamento}/>
            <Route path={`${path}/nuevo-departamento`} component={NuevoDepartamento}/>
        </>

    )
}
export default CuentaRoutes;