import moment from 'moment';
import currency from 'currency.js';

const percepcionBase = (codigo, clave, descripcion, tipo = 'Extraordinaria') => ({ codigo, clave, descripcion, tipo });

const rn = num => { return Math.round((num + Number.EPSILON) * 1000) / 1000 };

const calcSDI = (sd, dv, da) => currency(sd).add((dv * sd * 0.25) / 365).add(da * sd / 365).value;

const factAnt = (fi, fs) => Math.floor((moment(fs).diff(moment(fi), 'months') + 1) / 12);

const propDias = (fi, fs) => (moment(fs).diff(moment(fi), 'days')) % 365;

const aguinaldo = (fs, sd, da, fi) => {
    const percepcion = percepcionBase('002', '002', 'Aguinaldo');
    const fechaDespido = moment(fs);

    const diasTrabajados = moment(fs).diff(moment(fi), 'years') > 0 ?
        fechaDespido.diff(moment([fechaDespido.year(), 0, 1]), 'days') + 1 :
        fechaDespido.diff(moment(fi), 'days') + 1;
        
    const diasProporcionales = rn(((da / 365) * diasTrabajados));
    percepcion.monto = currency(sd).multiply(diasProporcionales).value;
    percepcion.detalle = { diasAguinaldo: da, diasTrabajados, diasProporcionales }
    console.log(percepcion.detalle);
    return percepcion;
}

const vacaciones = (fi, fs, sd, dv) => {
    const percepcion = percepcionBase('038', '038', 'Vacaciones');
    const diasProporcionales = rn(propDias(fi, fs) * dv / 365);
    percepcion.monto = currency(sd).multiply(diasProporcionales).value;
    percepcion.detalle = { diasVacaciones: dv, diasProporcionales }
    return [percepcion, primaVacacional(percepcion.monto)];
}

const primaVacacional = (montoVacaciones) => {
    const percepcion = percepcionBase('021', '021', 'Prima Vacacional');
    percepcion.monto = currency(montoVacaciones).multiply(0.25).value;
    percepcion.detalle = { montoVacaciones: montoVacaciones, factorPrima: 0.25 }
    return percepcion;
};

const indemnizaciones = (fi, fs, sdi) => {
    const indemnizacion = percepcionBase('025', '025', 'Indemnizacion');
    const factorAntiguedad = factAnt(fi, fs);
    indemnizacion.monto = currency(sdi).multiply(20 * factorAntiguedad).value;
    indemnizacion.detalle = { factorAntiguedad, diasPagados: 20, sdi };

    const separacion = percepcionBase('023', '023', 'Pagos por separacion');
    separacion.monto = currency(sdi).multiply(90).value;
    separacion.detalle = { diasPagados: 90, sdi };

    return [indemnizacion, separacion];
}

export const primaAntiguedad = (fi, fs, sd, SM) => {
    const percepcion = percepcionBase('022', '022', 'Prima por antiguedad');
    const salario = Math.min(2 * SM, sd);
    const factorAntiguedad = factAnt(fi, fs);
    const diasProporcionales = propDias(fi, fs);
    const proporcional = factorAntiguedad + diasProporcionales / 360;
    percepcion.monto = currency(salario).multiply(12).multiply(proporcional).value;
    percepcion.detalle = { salario, factorAntiguedad, diasProporcionales, factorCalculo: rn(proporcional) };
    return percepcion;
}

export const finiquit = (fechaIngreso, fechaSalida, sd, diasVacaciones, diasAguinaldo) => {
    const percepciones = [
        aguinaldo(fechaSalida, sd, diasAguinaldo, fechaIngreso),
        ...vacaciones(fechaIngreso, fechaSalida, sd, diasVacaciones),
    ];
    const total = percepciones.reduce((t, p) => t.add(p.monto), currency(0)).value;
    return { total, percepciones };
}

export const liquidacion = (fechaIngreso, fechaSalida, sd, diasVacaciones, diasAguinaldo, SM) => {
    const percepciones = [
        ...indemnizaciones(fechaIngreso, fechaSalida, calcSDI(sd, diasVacaciones, diasAguinaldo)),
        primaAntiguedad(fechaIngreso, fechaSalida, sd, SM)
    ];
    const total = percepciones.reduce((t, p) => t.add(p.monto), currency(0)).value;
    return { total, percepciones };
}


