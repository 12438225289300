import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { schemaCitatorios } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { getListaColaboradoresByEmpresa } from '../../store/colaboradorDucks';
import { createCitatorio } from '../../store/citatorioDuck';
import { Form, Input, FormGroup, Label, Col,CustomInput } from 'reactstrap';
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import InputMultiFile from '../ui/InputMultiFile';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import estados from '../../constans/estados';
import CurrencyFormat from 'react-currency-format';
import '../colaboradores/Forms/CondicionesLaborales.css';
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import axios from '../../services/axios';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";

const numerocitatorio = [
  { label: 'Primer Citatorio', value: 1 },
  { label: 'Segundo Citatorio', value: 2 },
  { label: 'Tercer Citatorio', value: 3 },
  { label: 'Cuarto Citatorio', value: 4},
  { label: 'Quinto Citatorio', value: 5},
  { label: 'Sexto Citatorio', value:6}
];
const estatus = [
  { label: 'Activo', value: 'activo' },
  { label: 'Cerrado', value: 'cerrado' },
];
const api = process.env.REACT_APP_API_URL;

const AgregarCitatorio = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [salario, setSalario] = useState({salarioDiario:''});
  const [depto, setDepto] = useState('');
  const [pues, setPues] = useState('');
  const [err, setErr] = useState({});
  const [empCitada, setEmpCitada] = useState('');
  const [notEmail, setNotEmail] = useState(false);
  const {salarioDiario} = salario;
  const { empresas = [] } = useSelector((state) => state.empresas);
  const { listaColaboradores = [] } = useSelector((state) => state.colaborador);
  
  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(schemaCitatorios),
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const getFiles = (files) => {
    setValue('archivos', files);
  };
  const getEmpresas = useCallback(async () => {
    try {
      await dispatch(fetchEmpresasToDropdown());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);
  const onChange = async ({ target }) => {
    if (!target.value) return;
    const filter = empresas.filter(x =>x.value === target.value);
    console.log(filter);
    setEmpCitada(filter[0].label);
    if (listaColaboradores.length > 0) {
      setPues('');
      setSalario({salarioDiario:''});
      setDepto('');
    }
    try {
      await dispatch(getListaColaboradoresByEmpresa(target.value));
    } catch (error) {}
  };
  
  const handleChange = async ({ target }) => {
      const id = target.value;
      const { data } = await axios.get(`${api}/colaborador/obtener_colaborador/${id}`, headers)
      const {resultado} = data;
      setSalario({salarioDiario:resultado.InformacionNomina.salarioDiario});
      setPues(resultado.condicionesLaborales.puesto);
      setDepto(resultado.pertenencia.nombreDepartamento)
    };
  
  
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    getEmpresas();
    register({ name: 'archivos' }, { required: false });
  }, [getEmpresas, register,errors]);

  const onSubmit = async (data) => {
    try {
      console.log(data);
      data.salarioDiarioIntegrado = salarioDiario;
      // data.fecha = parseInt(moment(data.fecha).format('x'));
      await dispatch(createCitatorio(data));
      await SweetAlert({
        icon: 'success',
        title: 'Citatorio agregado correctamente',
      });
      history.replace('/dashboard/nomina/juridico-citatorios');
    } catch (error) {}
  };

  return (
    <>
    <HeaderStyle style={{marginBottom:50}}>
      <h1>Nuevo Citatorio</h1>
      <h3>Agrega un nuevo citatorio</h3>
    </HeaderStyle>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Datos de la empresa
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Empresa relacionada*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={empresas}
            disabled={empresas.length > 0 ? false : true}
            type='select'
            name='empresa'
            innerRef={register()}
            onChange={onChange}
          />
          {err.empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Empresa citada*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='empresaCitada'
            innerRef={register()}
            defaultValue={empCitada}
            placeholder='Empresa citada'
          />
          {err.empresaCitada && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa citada es requerida
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Datos del colaborador
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Colaborador</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={listaColaboradores}
            disabled={listaColaboradores.length > 0 ? false : true}
            type='select'
            name='colaborador'
            innerRef={register()}
            onChange={handleChange}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Otro colaborador</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='otroColaborador'
            innerRef={register()}
            placeholder='Introduzca otro colaborador'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Salario diario integrado</Label>
        <Col sm={10}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            thousandSeparator={true}
            value={salarioDiario}
            name='salarioDiarioIntegrado'
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const {formattedValue, value} = values;
              setSalario({salarioDiario: value})
            }}
            innerRef={register()}
            placeholder='Salario diario integrado'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Departamento</Label>
        <Col sm={10}>
          <Input
            type='text'
            defaultValue={depto}
            name='departamento'
            innerRef={register()}
            placeholder='Introduzca a que departamento de la empresa va dirigido'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Puesto</Label>
        <Col sm={10}>
          <Input
            type='text'
            defaultValue={pues}
            name='puesto'
            innerRef={register()}
            placeholder='Puesto'
          />
        </Col>
      </FormGroup>
      
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Citatorio
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Numero de citatorio*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={numerocitatorio}
            type='select'
            name='numeroCitatorio'
            innerRef={register()}
            placeholder='Seleccione el numero de citatorio'
          />
          {err.numeroCitatorio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Numero del citatorio es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Estatus*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={estatus}
            type='select'
            name='status'
            innerRef={register()}
            placeholder='Seleccione el estatus activo/inactivo'
          />
          {err.status && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Estatus es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Autoridad laboral*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='autoridadLaboral'
            innerRef={register()}
            placeholder='Autoridad laboral'
          />
          {err.autoridadLaboral && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La autoridad laboral es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Inspector*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='inspector'
            innerRef={register()}
            placeholder='Inspector'
          />
          {err.inspector && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El inspector es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Estado*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={estados}
            type='select'
            name='estado'
            innerRef={register()}
            placeholder='Seleccione un Estado'
          />
          {err.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Ciudad*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='ciudad'
            innerRef={register()}
            placeholder='Ej. Hermosillo'
          />
          {err.ciudad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Fecha*</Label>
        <Col sm={3}>
          <Input
            type='date'
            name='fecha'
            innerRef={register()}
            placeholder='Introduzca la fecha del Citatorio'
          />
          {err.fecha && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Fecha es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Hora*</Label>
        <Col sm={3}>
          <Input 
            type='time' 
            name='hora' 
            innerRef={register()} 
            placeholder='' 
          />
          {err.hora && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Hora es requerida
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Atención
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Representante</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='representante'
            innerRef={register()}
            placeholder='Representante'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Notificar</Label>
        <Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="SI"
            value={true}
            name="notificar"
            onClick={() => setNotEmail(true)}
          />
          {err.fecha && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Fecha es requerida
            </span>
          )}
        </Col>
        <Col sm={1}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="NO"
            value={false}
            name="notificar"
            onClick={() => setNotEmail(false)}
          />
        </Col>
      </FormGroup>
      {notEmail ?
      <FormGroup row>
        <Label sm={2}>Email</Label>
        <Col sm={10}> 
            <Input 
              type='text'
              name='email'
              innerRef={register}
              placeholder='Email'
            />
        </Col>
      </FormGroup>:null}
      <FormGroup row>
        <Label sm={2}>Comentarios relacionados con la situación del citatorio</Label>
        <Col sm={10}>
          <TextAreaUtils
            name='notaCitatorio'
            ref={register}
            placeholder='Comentarios relacionados con la situación del citatorio'
          />
        </Col>
      </FormGroup>

      
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Adjuntar citatorios
        </h5>
      </FormGroup>
      <FormGroup row>
        <Col sm={10}>
          <InputMultiFile name='archivos' getFiles={getFiles} />
        </Col>
      </FormGroup>

      <FormGroup row>
          <Col sm={{ offset: 6, size: 3 }}>
            <CancelButton
              onClick={() => {
                history.replace('/dashboard/nomina/juridico-citatorios');
              }}
              style={{ marginLeft: 'auto' }}>
              Cancelar
            </CancelButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='submit'>Guardar</SaveButton>
          </Col>
      </FormGroup>
    </Form>
    </>
  );
};

export default AgregarCitatorio;
