import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetState } from '../../store/colaboradorDucks';
import OpcionesAlta from '../../components/colaboradores/OpcionesAlta';
import ColaboradorCard from '../../components/ui/ColaboradorCard';
import './DarAlta.css';
import EditForms from '../../components/colaboradores/Forms/EditForms';
import EmptyList from '../../components/ui/EmptyList';

const EditarColaborador = React.memo(() => {
  const colaborador = useSelector((state) => state.colaborador);
  const dispatch = useDispatch();
  const [SelectOption, setSelectOption] = useState(1);
  const onChange = useCallback(({ target }) => {
    setSelectOption(() => parseInt(target.value));
  }, []);
  
  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch, setSelectOption]);

  return (
    <section className='grid-alta'>
      <div className='ComponentDescript'>
        <ColaboradorCard {...colaborador} />
      </div>
      <div className='Opciones'>
        <OpcionesAlta onChange={onChange} {...colaborador} />
      </div>
      {SelectOption === 0 ?
        <EmptyList
          align={"center"}
          title={'Comienza aquí'}
          description={
            'Selecciona la sección que deseas editar.'
          }
        />
        :(
        <div className='Forms'>
          <EditForms form={SelectOption} />
        </div>
      )}
    </section>
  );
});

export default EditarColaborador;
