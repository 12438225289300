import styled from "styled-components";
import colors from "../../../constans/colors";

export const GirdContainerRoutes = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 2fr;
  grid-template-rows: 1fr;
`;

export const CatalogoContainer = styled.section`
  padding: 1rem;
  /* display: -webkit-box; */
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  /* -webkit-justify-content: center; */
  /* justify-content: center; */
  /* -webkit-flex-direction: column; */
  -ms-flex-direction: column;
  flex-direction: column;

  /* -webkit-box-align: center; */
  -ms-flex-align: center;
  /* align-items: center; */
  /* -webkit-align-content: center; */
  -ms-flex-line-pack: center;
  /* align-content: center; */
  /* height: 120vh; */
  gap: 2rem;
`;

export const ContainerVariables = styled.div`
  width: 274px;
  height: 442px;
  background: #ebecf4;
  border-radius: 11px;
  overflow-y: scroll;
  h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.219583px;
    color: #3c3c3c;
    cursor: pointer;
  }
  h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.219583px;
    cursor: pointer;

    color: #404041;
  }
`;

export const ContainerSelector = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: baseline;
  background-color: ${colors.primary};
  width: 100%;
  min-height: 45%;
  border-radius: 0.4rem;
  padding: 1.5rem;
  .catalog-list {
    min-height: 75%;
    background-color: white;
  }
`;

export const Search = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  > input {
    width: 100%;
    padding: 5px;
    height: 3rem;
    border-radius: 5px;
  }
  .searchButton {
    width: 0px;
    height: 3rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    font-size: 20px;
    right: 2rem;
  }
  .placehold::placeholder {
    font-size: 1.2rem;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  min-height: 65%;
  max-height: 35vh;
  background-color: white;
  > div {
    width: 100%;
    max-height: 5vh;
    padding: 1rem;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
  > div:hover {
    background-color: ${(props) => (props.edit ? "#ebecf4" : "none")};
    cursor: ${(props) => (props.edit ? "pointer" : "context-menu")};
  }
  > div > span:nth-child(1) {
    width: 10%;
  }
  > div > span {
    width: ${(props) => (props.personalizado ? "25%" : "85%")};
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }

  > div > svg {
    cursor: pointer;
    width: ${(props) => (props.personalizado ? "4.5%" : "5%")} !important;
    color: ${(props) => (props.personalizado ? "#212529" : colors.secondary)};
  }
  .one-child {
    margin-bottom: 10rem;
  }
`;
export const Subtitle = styled.h2`
  width: 94%;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #3c3c3c;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 3rem;
  > button {
    border-radius: 8px;
    width: 11vw !important;
  }
  .activeCatalogo {
    background-color: #2b388f;
    color: white;
    font-weight: 600;
  }
`;

export const TablePersonalizado = styled.table`
  width: 100%;
  overflow-y: scroll;
  font-family: "Roboto";
  text-align: center;
  font-weight: 500;
  .config {
    min-width: 3rem;
  }
  .line {
    border-bottom: 1px solid;
  }
`;
export const TableContainer = styled.div`
  width: 100%;
  background-color: white;
  max-height: 75%;
  min-height: 75%;
  overflow-y: scroll;
  padding: 1rem;
`;

const blue = "#2b388f";

export const StyleInputs = styled.div`
  input,
  button,
  select {
    height: 50px;
    color: ${blue};
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.258333px;
    width: 182px;
    height: 51px;
    border: 1px solid ${blue};
  }

  display: contents;
  button.btn-primary,
  button.btn-primary:active,
  button.btn-primary:hover {
    border: 2px solid ${blue};
    color: white;
    background-color: ${blue} !important;
    height: 50px;
  }

  button.btn-secondary,
  button.btn-secondary:active,
  button.btn-secondary:hover {
    border: 2px solid ${blue};
    color: ${blue};
    background-color: white !important;
    height: 50px;
  }
  button.btn-sm {
    font-size: 0.875rem;
    line-height: 1.5;
    height: 40px;
  }
  button.btn-sm:hover {
    font-size: 0.875rem;
    line-height: 1.5;
    height: 40px;
  }
  h4 {
    font-size: 17px;
    line-height: 20px;    
    letter-spacing: 0.219583px;
    color: #3c3c3c;
  }
`;

export const StyleVariable = styled.div``;
