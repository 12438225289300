import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import CustomInput from 'reactstrap/lib/CustomInput';
import InputFile from '../../ui/InputFile';
import {
    CancelButton,
    InputUtils,
    LabelForms,
    SaveButton,
    SubTitleMenu,
    TextAreaUtils,
} from '../VacacionesViews/styled';
import { fetchCreateIncapacidades } from '../../../store/incapacidadesDuck';
import {
    controlEnfermedadGeneral,
    controlMaternidad,
    controlIncapacidadRT,
    escenariosRT,
    scrollTipo,
    secuelaConsecuenciaRT,
    vacio
} from './Constantes';
import SweetAlert from '../../ui/SweetAlet';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaIncapacidades } from '../../../constans/yupSchemas';

const IncapacidadesAdd = React.memo(({ history }) => {
    const [riesgoTrabajo, setRiesgoTrabajo] = useState(false);
    const [enfermedadGral, setEnfermedadGral] = useState(false);
    const [porcentajeValue, setPorcentajeValue] = useState({ valPercent: '' })
    const { valPercent } = porcentajeValue;
    const [maternidad, setMaternidad] = useState(false);
    const [otros, setOtros] = useState(false);
    const dispatch = useDispatch();

    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(schemaIncapacidades)
    });
    const [err, setErr] = useState({});
    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);

    console.log(errors);

    const onSubmit = async (data) => {
        try {
            if (data.porcentaje > 0 && data.porcentaje <= 100) {
                console.log(data);
                await dispatch(fetchCreateIncapacidades(data));
                SweetAlert({ title: 'Incapacidad guardada', icon: 'success' });
                history.push('/');
            } else {
                await SweetAlert({ icon: 'error', title: 'Valores de porcentaje entre 0 y 100' })
            }
        } catch (error) {
            SweetAlert({ title: 'Error!', text: error, icon: 'error' });
            console.log(error);
        }
    };

    const handleTipoIncapacidad = (value) => {
        let valor = value.target.value;
        if (valor === 'Riesgo de trabajo') {
            setRiesgoTrabajo(true);
            setEnfermedadGral(false);
            setMaternidad(false);
            setOtros(false);
            setPorcentajeValue({ valPercent: '100' });
        } if (valor === 'Enfermedad general') {
            setRiesgoTrabajo(false);
            setEnfermedadGral(true);
            setMaternidad(false);
            setOtros(false);
            setPorcentajeValue({ valPercent: '60' });
        } if (valor === 'Maternidad') {
            setRiesgoTrabajo(false);
            setEnfermedadGral(false);
            setMaternidad(true);
            setOtros(false);
            setPorcentajeValue({ valPercent: '100' })
        } if (valor === 'Otros') {
            setRiesgoTrabajo(false);
            setEnfermedadGral(false);
            setMaternidad(false);
            setOtros(true);
            setPorcentajeValue({ valPercent: '' });
        }
    };
    const fillPercent = e => {
        setPorcentajeValue({
            ...porcentajeValue,
            valPercent: e.target.value
        })
    }


    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
            <FormGroup row>
                <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                    <SubTitleMenu
                        style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
                    >Nuevo Registro de Incapacidad</SubTitleMenu>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Fecha de Inicio*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='fechaInicio'
                        defaultValue={moment().format('YYYY-MM-DD')}
                        type='date'
                        ref={register}
                    />
                    {err.fechaInicio && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            La fecha de inicio es requerida
                        </span>
                    )}
                </Col>
                <Col sm={4}></Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Fecha de Conclusión*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <InputUtils
                        name='fechaFin'
                        type='date'
                        ref={register}
                    />
                    {err.fechaFin && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            La fecha de conclusión es requerida
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3}>
                    <LabelForms>Ramo de Seguro*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        onChange={(value) => handleTipoIncapacidad(value)}
                        name='tipo'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={scrollTipo}
                    />
                    {err.tipo && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El ramo de seguro es requerido
                        </span>
                    )}
                </Col>
                <Col sm={4}></Col>
            </FormGroup>
            {riesgoTrabajo
                ? <FormGroup row>
                    <Label sm={3}>
                        <LabelForms>Escenarios*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
                        <CustomSelect
                            name='escenario'
                            type='select'
                            innerRef={register}
                            control={control}
                            options={escenariosRT}
                        />
                        {err.escenario && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                El escenario es requerido
                            </span>
                        )}
                    </Col>
                    <Col sm={4}></Col>
                    <Label sm={3}>
                        <LabelForms>Secuela o Consecuencia*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <CustomSelect
                            name='secuelaConsecuencia'
                            type='select'
                            innerRef={register}
                            control={control}
                            options={secuelaConsecuenciaRT}
                        />
                        {err.secuelaConsecuencia && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                La secuela o consecuencia es requerida
                            </span>
                        )}
                    </Col>
                    <Col sm={4}></Col>
                </FormGroup>
                : null}
            {otros
                ? <FormGroup row>
                    <Label sm={3}>
                        <LabelForms>Especifica Otros*</LabelForms>
                    </Label>
                    <Col sm={{ offset: 1, size: 4 }}>
                        <InputUtils
                            name='especificaOtros'
                            type='text'
                            placeholder='Especifica otros'
                            ref={register}
                        />
                        {err.especificaOtros && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Otros es requerido
                            </span>
                        )}
                    </Col>
                    <Col sm={4}></Col>
                </FormGroup>
                : null}
            <FormGroup row>
                {!otros
                    ? <>
                        <Label sm={3}>
                            <LabelForms>Tipo de Incapacidad*</LabelForms>
                        </Label>
                        <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
                            <CustomSelect
                                name='controlIncapacidad'
                                type='select'
                                innerRef={register}
                                control={control}
                                options={
                                    riesgoTrabajo ? controlIncapacidadRT
                                        : maternidad ? controlMaternidad
                                            : enfermedadGral ? controlEnfermedadGeneral
                                                : vacio
                                }
                            />
                            {err.controlIncapacidad && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El tipo de incapacidad es requerido
                                </span>
                            )}
                        </Col>
                        <Col sm={4}></Col>
                    </> : null
                }
                <Label sm={3}>
                    <LabelForms>Folio del Certificado*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
                    <InputUtils
                        name='folio'
                        type='text'
                        placeholder='Folio del certificado'
                        ref={register}
                    />
                    {err.folio && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El folio del certificado es requerido
                        </span>
                    )}
                </Col>
                <Col sm={4}></Col>
                <Label sm={3}>
                    <LabelForms>Porcentaje (%)*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
                    <InputUtils
                        name='porcentaje'
                        value={valPercent}
                        type='number'
                        placeholder='Porcentaje'
                        onChange={fillPercent}
                        ref={register}
                    />
                    {err.porcentaje && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El porcentaje es requerido
                        </span>
                    )}
                </Col>
                <Col sm={4}></Col>
                <Label sm={3}>
                    <LabelForms>Persona Responsable*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '19px' }}>
                    <InputUtils
                        name='personaResponsable'
                        type='text'
                        placeholder='Persona responsable'
                        ref={register}
                    />
                    {err.personaResponsable && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            La persona responsable es requerida
                        </span>
                    )}
                </Col>
                <Col sm={4}></Col>
                <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
                    <LabelForms>Agregar a Calendario*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <Label sm={2} check style={{ marginLeft: '20px' }}>
                    <CustomInput id='calendario1' type='radio' name='calendario' defaultChecked={true} value='true' innerRef={register()} />
                        Si
                    </Label>
                    <Label sm={2} check style={{ marginLeft: '40px' }}>
                    <CustomInput id='calendario2' type='radio' name='calendario'  defaultChecked={false} value='false' innerRef={register()} />
                        No
                    </Label>
                    {err.calendario && (
                    <FormGroup row>
                        <Label>
                        </Label>
                        <Col sm={9}>
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Indique si desea mostrar esta incapacidad en el calendario
                        </span>
                        </Col>
                    </FormGroup>
                    )}
                </Col>
                <Col sm={4}></Col>
                <Label sm={3}>
                    <LabelForms>Observaciones</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }} style={{ marginBottom: '17px' }}>
                    <TextAreaUtils
                        name='observaciones'
                        placeholder='Observaciones'
                        ref={register}
                    />
                </Col>
                <Col sm={4}></Col>
                <Label sm={3}>
                    <LabelForms>Comprobante</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
                    <InputFile name='archivo' id='archivo' register={register} />
                </Col>
                <Col sm={4}></Col>
            </FormGroup>
            <FormGroup row>
                <Col sm={{ offset: 6, size: 3 }}>
                    <CancelButton
                        onClick={() => {
                            history.push('/');
                        }}
                        style={{ marginLeft: 'auto' }}>
                        Cancelar
                    </CancelButton>
                </Col>
                <Col sm={3}>
                    <SaveButton type='submit'>Guardar</SaveButton>
                </Col>
            </FormGroup>
        </Form>
    );
});

export default IncapacidadesAdd;