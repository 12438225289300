export const UTILITARIO_START = 'UTILITARIO_START';

export const UTILITARIO_LISTA_EXITO = 'UTILITARIO_LISTA_EXITO';
export const UTILITARIO_LISTA_ERROR = 'UTILITARIO_LISTA_ERROR';
export const CREAR_UTILITARIO_EXITO = 'CREAR_UTILITARIO_EXITO';
export const CREAR_UTILITARIO_ERROR = 'CREAR_UTILITARIO_ERROR';
export const AGREGAR_UTIL = 'AGREGAR_UTIL';
const initialState = {
  loading: false,
  error: null,
  listaUtilitarios: [],
  util: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UTILITARIO_START:
      return { ...state, loading: true };
    case UTILITARIO_LISTA_EXITO:
      return { ...state, loading: false, listaUtilitarios: [...payload] };
    case UTILITARIO_LISTA_ERROR:
      return { ...state, loading: false };
    case CREAR_UTILITARIO_EXITO:
      return { ...state, loading: false };
    case CREAR_UTILITARIO_ERROR:
      return { ...state, loading: false };
    case AGREGAR_UTIL:
      return { ...state, util: { ...payload } };
    default:
      return state;
  }
};

export const utlitarioStart = () => ({
  type: UTILITARIO_START,
});

export const listaUtilitarioExito = (payload) => ({
  type: UTILITARIO_LISTA_EXITO,
  payload,
});

export const listaUtilitarioError = () => ({
  type: UTILITARIO_LISTA_ERROR,
});

export const createUtilitarioExito = () => ({
  type: CREAR_UTILITARIO_EXITO,
});

export const createUtilitarioError = () => ({
  type: CREAR_UTILITARIO_ERROR,
});
export const agregarUtil = (payload) => ({
  type: 'AGREGAR_UTIL',
  payload,
});

export const fetchListaUtilitario = (tipoActivo='herramienta') => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    const { _id } = getState().colaborador;
    const {
      data: { resultado = [] },
    } = await axios.get('/utilitario/lista_utilitarios', {
      params: {
        idColaborador: _id,
        tipoActivo,
      },
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaUtilitarioExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaUtilitarioError());
    throw data;
  }
};

export const fetchCreateUtilitario = (dataUtilitario, tipoActivo) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    const { _id } = getState().colaborador;
    const {
      fotos = [],
      comodato = {},
      factura = {},
      oficioEntrega = null,
      ...rest
    } = dataUtilitario;
    const formData = new FormData();
    fotos.forEach((foto) => {
      if (foto[0]) {
        formData.append('fotos', foto[0]);
      }
    });
    formData.append('comodato', comodato[0]);
    formData.append('factura', factura[0]);
    formData.append('oficioEntrega', oficioEntrega[0]);
    formData.append('colaborador', _id);
    formData.append(
      'dataUtilitario',
      JSON.stringify({ ...rest, tipoActivo: tipoActivo })
    );
    const {
      data: { resultado },
    } = await axios.post('/utilitario/crear', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(agregarUtil(resultado));
    dispatch(createUtilitarioExito());
    return resultado
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createUtilitarioError());
    throw data;
  }
};

export const fetchUpdateUtilitario = (dataUtilitario, tipoActivo) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    const { _id } = getState().utilitario.util;
    const { fotos = [], comodato = {}, factura = {}, oficioEntrega = {}, colaborador, ...rest } = dataUtilitario;
    const formData = new FormData();
    fotos.forEach((foto) => {
      if (foto[0]) {
        formData.append('fotos', foto[0]);
      }
    });
    formData.append('comodato', comodato[0]);
    formData.append('factura', factura[0]);
    formData.append('oficioEntrega', oficioEntrega[0]);
    formData.append('colaborador', colaborador);
    formData.append(
      'dataUtilitario',
      JSON.stringify({ ...rest, tipoActivo: tipoActivo })
    );
    const {
      data: { resultado },
    } = await axios.put(`/utilitario/modificar_utilitario/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(createUtilitarioExito());
    return resultado
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createUtilitarioError());
    throw data;
  }
};

export const patchStatus = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    const { _id } = getState().utilitario.util;
    const {
      data: { resultado },
    } = await axios.patch(`/utilitario/cambiar_estatus_utilitario/${_id}`,{}, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(agregarUtil(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createUtilitarioError());
    throw data;
  }
};

export const deleteUtil = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    const { _id } = getState().utilitario.util;
    await axios.delete(`/utilitario/eliminar_utilitario/${_id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createUtilitarioExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createUtilitarioError());
    throw data;
  }
};

export const deleteUtilFiniquitos = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(utlitarioStart());
    await axios.delete(`/utilitario/eliminar_utilitario/${id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createUtilitarioExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createUtilitarioError());
    throw data;
  }
};

export const fetchListaUtilitarioFiniquitos = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const tipoActivo = 'herramienta';
    dispatch(utlitarioStart());
    const {
      data: { resultado = [] },
    } = await axios.get('/utilitario/lista_utilitarios', {
      params: {
        idColaborador: id,
        tipoActivo,
      },
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaUtilitarioExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaUtilitarioError());
    throw data;
  }
};

