import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spiner from '../../components/ui/Spiner';
import {
  getListaColaboradores,
  creandoColaboradorSuccees,
  buscarColaboradorPorNombre
} from '../../store/colaboradorDucks';
import ComponentDescrip from '../../components/ui/ComponentDescrip';
import EmptyList from '../../components/ui/EmptyList';
import Search from '../../components/ui/Search';
import TableCommon from '../../components/ui/TableCommon';
import { BackgroundImage } from '../../pages/styled';
import './ListadoColaboradores.css';
import {
  Col,
  FormGroup,
  Input,
} from 'reactstrap';
import axios from 'axios';

const api = process.env.REACT_APP_API_URL;

const ListadoColaboradores = React.memo(({ history, ...rest }) => {
  const { listaColaboradores, loading } = useSelector(
    (state) => state.colaborador
  );
  const dispatch = useDispatch();
  const fetchLista = useCallback(async () => {
    try {
      await dispatch(getListaColaboradores());
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchLista();
  }, [fetchLista]);
  //const keysAceepted = ['', 'Nombre', 'Puesto', 'Salario Mensual', 'Sucursal', 'Departamento'];
  const keysAceepted = ['', 'Nombre', 'Puesto', '# Empleado', 'Sucursal', 'Departamento'];

  useEffect(() => {
    setColaboradores(listaColaboradores)
  }, [listaColaboradores]);

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  const handleClick = useCallback(
    (colaborador) => {
      dispatch(creandoColaboradorSuccees(colaborador));
      history.push('/dashboard/nomina/editar-colaborador');
    },
    [dispatch, history]
  );

  const onSearch = (text) => {
    setFilterNP(text)
    setColaboradores(listaColaboradores.filter(
      (c) => 
        c.informacionPersonal.nombre.toLowerCase().includes(text.toLowerCase()) ||
        c.informacionPersonal.apellidoPaterno.toLowerCase().includes(text.toLowerCase()) ||
        c.informacionPersonal.apellidoMaterno.toLowerCase().includes(text.toLowerCase()) ||
        c.condicionesLaborales.puesto.toLowerCase().includes(text.toLowerCase())
    ));
    if (text === '') {
      console.log('onserach')
      if (empresa && empresa._id !== '') setColaboradores(listaColaboradores.filter(e => e.pertenencia.empresa === empresa._id &&
        e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase())
      ))
      if (sucursal && sucursal._id !== '' && sucursal._id !== 'all') setColaboradores(listaColaboradores.filter(e => e.pertenencia.sucursal._id === sucursal._id &&
        e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase())
      ))
      if (departamento && departamento._id !== '' && departamento._id !== 'all') setColaboradores(listaColaboradores.filter(e => e.pertenencia.departamento._id === departamento._id &&
        e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase())
      ))
    }
  }
  const [listaEmpresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState({ _id: '' });
  const [listaSucursales, setSucursales] = useState([]);
  const [sucursal, setSucursal] = useState({ _id: '' });
  const [listaDepartamentos, setDepartamentos] = useState([]);
  const [departamento, setDepartamento] = useState({ _id: '' });
  const [listColaboradores, setColaboradores] = useState([]);
  const [filterNP, setFilterNP] = useState('');
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const fetchEmpresas = async () => {
    let { data } = await axios.get(`${api}/empresa/obtener_empresas`, headers);
    setEmpresas(data.empresas)
  }

  const fetchSucursales = async (empresa) => {
    setColaboradores(listaColaboradores.filter(e => e.pertenencia.empresa === empresa._id && (filterNP !== '' ?
      e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase()) : true)
    ))
    let { data } = await axios.get(`${api}/sucursales/por_empresa?empresa=${empresa._id}`, headers);
    data.resultado.unshift({ nombreSucursal: 'Todas', _id: 'all' })
    setSucursales(data.resultado);
  }

  const fetchDepartamentos = async (sucursal) => {
    if (sucursal && sucursal._id !== '' && sucursal._id !== 'all') {
      setColaboradores(listaColaboradores.filter(e => e.pertenencia.sucursal._id === sucursal._id && (filterNP !== '' ?
        e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase()) : true)
      ))
      let { data } = await axios.get(`${api}/departamentos/por_sucursal?sucursal=${sucursal._id}`, headers);
      data.resultado.unshift({ nombreDepartamento: 'Todos', _id: 'all' })
      setDepartamentos(data.resultado);
    }
  }

  useEffect(() => {
    fetchEmpresas();
  }, [])

  useEffect(() => {
    console.log('empresa')
    if (empresa && empresa._id && empresa._id !== '') fetchSucursales(empresa);
    else if (empresa._id === '') {
      onSearch(filterNP)
      setSucursales([])
      setDepartamentos([])
    }
  }, [empresa])

  useEffect(() => {
    console.log('sucursal')
    if (sucursal && sucursal._id !== 'all' && sucursal._id !== '') {
      fetchDepartamentos(sucursal);
    }
    else if (sucursal._id === 'all' || sucursal._id === '') {
      fetchSucursales(empresa);
      setDepartamentos([])
    } 
  }, [sucursal])

  useEffect(() => {
    if (departamento && departamento._id !== 'all' && departamento._id !== '') setColaboradores(listaColaboradores.filter(e => e.pertenencia.departamento._id === departamento._id && (filterNP !== '' ?
      e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
      e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase()) : true)
    ))
    else if (departamento._id === 'all') {
      setColaboradores(listaColaboradores.filter(e => e.pertenencia.sucursal._id === sucursal._id && (filterNP !== '' ?
        e.informacionPersonal.nombre.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoPaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.informacionPersonal.apellidoMaterno.toLowerCase().includes(filterNP.toLowerCase()) ||
        e.condicionesLaborales.puesto.toLowerCase().includes(filterNP.toLowerCase()) : true)
      ))
    }
  }, [departamento])

  if (loading) {
    return <Spiner />;
  }
  return (
    <div className='grid-list'>
      <ComponentDescrip
        title={'Colaboradores'}
        description={
          'Encuentra aqui todos los colaboradores. Para ver mas detalles, da click en el nombre del colaborador'
        }
      />
      <FormGroup row>
        <Col sm={3.5}>
          <Search onChange={onSearch} placeholder='Busca por nombre o puesto' />
        </Col>
        <Col sm={3}>
          <Input style={{ height: "100%" }}
            type="select"
            name="select"
            onChange={(e) => setEmpresa({ _id: e.target.value })}
            value={empresa._id}
          >
            <option value={""} key={""}>Empresa...</option>
            {listaEmpresas.map((e) => (
              <option value={e._id} key={e.nombre_empresa}>
                {e.nombre_empresa}
              </option>
            ))}
          </Input>
        </Col>
        <Col sm={3}>
          <Input style={{ height: "100%" }}
            type="select"
            name="select"
            onChange={(e) => setSucursal({ _id: e.target.value })}
            value={sucursal.nombreSucursal}
          >
            <option value={""}>Sucursal...</option>
            {listaSucursales.map((s) => (
              <option value={s._id} key={s.nombreSucursal}>
                {s.nombreSucursal}
              </option>
            ))}
          </Input>
        </Col>
        <Col sm={3}>
          <Input style={{ height: "100%" }}
            type="select"
            name="select"
            onChange={(e) => setDepartamento({ _id: e.target.value })}
            value={departamento.nombreDepartamento}
          >
            <option value={""}>Departamento...</option>
            {listaDepartamentos.map((d) => (
              <option value={d._id} key={d.nombreDepartamento}>
                {d.nombreDepartamento}
              </option>
            ))}
          </Input>
        </Col>
      </FormGroup>

      {!listColaboradores || listColaboradores.length === 0 ?
        <EmptyList
          align={"center"}
          title={'Sin colaboradores'}
          description={
            'Primero debes registrar tus colaboradores o limpia el filtro de búsqueda'
          }
        />
        :
        <TableCommon data={listColaboradores} keysAceepted={keysAceepted}>
          {listColaboradores.map(
            (
              {
                _id,
                informacionPersonal = '',
                condicionesLaborales = '',
                pertenencia = {},
                foto = '',
              },
              index
            ) => {
              const {
                nombre = '',
                apellidoPaterno = '',
                apellidoMaterno = '',
              } = informacionPersonal;
              const { puesto = '', salarioMensualBruto = '', numeroEmpleado = '' } = condicionesLaborales;
              const { departamento, sucursal } = pertenencia;
              const nombreCompleto = `${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
              return (
                <tr
                  key={_id}
                  onClick={() => handleClick(listColaboradores[index])}
                >
                  <td>
                    <BackgroundImage
                      imageUrl={
                        foto
                          ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${foto}`
                          : null
                      }
                    />
                  </td>
                  <td className='span-label'>{nombreCompleto}</td>
                  <td className='span-label'>{puesto}</td>
                  <td className='span-label'>{numeroEmpleado/*`$ ${salarioMensualBruto ? numberWithCommas(salarioMensualBruto.toFixed(2)) : 0}`*/}</td>
                  <td className='span-label'>{sucursal ? sucursal.nombreSucursal : ''}</td>
                  <td className='span-label'>{departamento ? departamento.nombreDepartamento : ''}</td>
                </tr>
              );
            }
          )}
        </TableCommon>
      }
    </div>
  );
});

export default ListadoColaboradores;
