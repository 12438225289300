import React from 'react';
import { Table } from 'reactstrap';
import './TableCommon.css';
import styled from 'styled-components';
import colors from '../../../constans/colors';

const TR = styled.tr`
  color: ${colors.tashColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
`;
const SpanFloat = styled.span`
  display: inline-block;
  position: relative;
  top: 2vh;
  right: 4rem;
`;

const TableCommon = React.memo(
  ({ children, striped = false, keysAceepted = [], extraTag = null }) => {
    return (
      <div className='table-container'>
        <Table borderless hover striped={striped} responsive autoCorrect='true' style={{marginBottom: '20px'}}>
          <thead>
            <TR>
              {keysAceepted.map((element, index) => {
                return (
                  <th key={element + index}>
                    <span> {element}</span>

                    {extraTag ? (
                      extraTag.key === element ? (
                        <>
                          <SpanFloat>${extraTag.value}</SpanFloat>
                        </>
                      ) : (
                        <span></span>
                      )
                    ) : null}
                  </th>
                );
              })}
            </TR>
          </thead>
          <tbody style={{fontSize:'17px',fontWeight:'300'}}>{children}</tbody>
        </Table>
        {/*   <TablePagination /> */}
      </div>
    );
  }
);

export default TableCommon;
