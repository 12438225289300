import React, { useState } from 'react';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaBajasColaborador } from '../../constans/yupSchemas';
import { dropColaborador, resetState } from '../../store/colaboradorDucks';
import { Col, FormGroup, Input, Label, Form, CustomInput } from 'reactstrap';
import InputFile from '../../components/ui/InputFile';
import { cleanInvalidFieldsInObject } from '../../helpers';
import SweetAlert from '../../../src/components/ui/SweetAlet';
import CustomSelect from '../../components/ui/CustomSelect';
import Row from 'reactstrap/lib/Row';
import { SaveButton } from '../../components/ui/styles/styles';

const BajaProceso = () => {
  const opciones = [
    { label: 'ABANDONO DE EMPLEO', value: 'ABANDONO DE EMPLEO' },
    { label: 'SEPARACIÓN VOLUNTARIA', value: 'SEPARACIÓN VOLUNTARIA' },
    { label: 'TÉRMINO DE CONTRATO', value: 'TÉRMINO DE CONTRATO' },
    { label: 'AUSENTISMO', value: 'AUSENTISMO' },
    { label: 'PENSIÓN', value: 'PENSIÓN' },
    { label: 'OTRAS', value: 'OTRAS' },
    { label: 'CLAUSUARA', value: 'CLAUSUARA' },
    { label: 'DEFUNCIÓN', value: 'DEFUNCIÓN' },
    { label: 'JUBILACIÓN', value: 'JUBILACIÓN' },
    { label: 'RESCISIÓN DE CONTRATO', value: 'DEMANDA' },
  ]
  const dispatch = useDispatch();
  const history = useHistory();
  const { condicionesLaborales = {} } = useSelector(state => state.colaborador);
  const { fechaIngreso = '' } = condicionesLaborales;
  const [recontratar, setRecontratar] = useState(true);
  const { register, handleSubmit, errors, control } = useForm({
    //resolver: yupResolver(schemaBajasColaborador),
  });
  const onSubmit = async (data) => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: '¿Estás seguro?',
        text: 'Recomendamos llevar a cabo dicho trámite ante la autoridad laboral competente, generar su documentación y digitalizarlo. (Ver articulo 804, Ley Federal del Trabajo)',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        data.fechaEntrada = parseInt(moment(data.fechaEntrada).format("x"));
        data.fechaSalida = parseInt(moment(data.fechaSalida).format("x"));
        data.fechaBajaImss = parseInt(moment(data.fechaBajaImss).format("x"));
        const cleanData = cleanInvalidFieldsInObject(data);
        await dispatch(dropColaborador(cleanData));
        await SweetAlert({ icon: 'success', title: 'Baja realizada' });
        dispatch(resetState());
        history.push('/dashboard/nomina/bajas');
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(errors);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for='fechaEntrada' sm={3}>
          Fecha entrada del Colaborador
        </Label>
        <Col sm={3}>
          <Input
            type='date'
            name='fechaEntrada'
            defaultValue={moment(fechaIngreso).format('YYYY-MM-DD')}
            innerRef={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='fechaSalida' sm={2}>
          Fecha salida del Colaborador
        </Label>
        <Col sm={{ offset: 1, size: 3 }}>
          <Input
            type='date'
            name='fechaSalida'
            innerRef={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='fechaBajaImss' sm={2}>
          Fecha de Baja en el IMSS
        </Label>
        <Col sm={{ offset: 1, size: 3 }}>
          <Input
            type='date'
            name='fechaBajaImss'
            innerRef={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='causaSalida' sm={2}>
          Seleccione la razón de la baja
        </Label>
        <Col sm={{ offset: 1, size: 3 }}>
          <CustomSelect
            className='mb-3'
            type='select'
            name='causaSalida'
            id='causaSalida'
            control={control}
            innerRef={register}
            options={opciones}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='nota' sm={3}>
          Notas
        </Label>
        <Col sm={7}>
          <Input
            type='textarea'
            name='nota'
            placeholder='Ingrese alguna nota para el proceso de bajas'
            innerRef={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='recontratar' sm={3}>
          Recomienda recontratar
        </Label>
        <Label check sm={1} className="radio-organize" inline="true" style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="recontratar" id="recontratar" value={true} innerRef={register()} onClick={e => setRecontratar(true)} />
          Si
        </Label>
        <Label check sm={1} className="radio-organize" inline="true">
          <CustomInput type="radio" name="recontratar" id="recontratar" value={false} innerRef={register()} onClick={e => setRecontratar(false)} />
          No
        </Label>
      </FormGroup>
      {
        !recontratar ?
          <React.Fragment>
            <FormGroup row>
              <Label for='nota' sm={3}>
                Razón
        </Label>
              <Col sm={7}>
                <Input
                  type='textarea'
                  name='razon'
                  placeholder='Ingrese la razón por la cual no recomienda recontratar'
                  innerRef={register}
                />
              </Col>
            </FormGroup>
          </React.Fragment>
          : ''
      }
      <FormGroup row>
        <Label for='bajaImss' sm={3}>
          Documento Baja IMSS
        </Label>
        <Col sm={9}>
          <InputFile
            name='bajaImss'
            register={register()}
            type='file'
            id='bajaImss'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='finiquitoAutoridad' sm={3}>
          Documento Finiquito Ante la autoridad
        </Label>
        <Col sm={9}>
          <InputFile
            name='finiquitoAutoridad'
            register={register()}
            type='file'
            id='finiquitoAutoridad'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='finiquitoPrivado' sm={3}>
          Documento Finiquito Privado
        </Label>
        <Col sm={9}>
          <InputFile
            name='finiquitoPrivado'
            register={register()}
            type='file'
            id='finiquitoPrivado'
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='renuncia' sm={3}>
          Documento Renuncia
        </Label>
        <Col sm={9}>
          <InputFile
            name='renuncia'
            register={register()}
            type='file'
            id='renuncia'
          />
        </Col>
      </FormGroup>

      <FormGroup className='text-center'>
        <Row>
          <Col sm={{ offset: 8, size: 5 }}>
            <SaveButton type='submit'>Guardar</SaveButton>
          </Col>
        </Row>
      </FormGroup>
    </Form>


  );
};

export default BajaProceso;
