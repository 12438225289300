import React, {useState} from 'react';
import RegistroUsuarioA from './RegistroUsuarioA';
import RegistroUsuarioB from './RegistroUsuarioB';

const RegistroComponente = React.memo(({ registro = 0 }) => {
    const [componentRegister, setComponentRegister] = useState(1);
    let component = null;
    switch (componentRegister) {
        case 1:
            component = <RegistroUsuarioA setComponentRegister={setComponentRegister}/>;
            break;
        case 2:
            component = <RegistroUsuarioB />;
            break;
        default:
            component = null;
    }
    return <>{component}</>;
});


export default RegistroComponente;