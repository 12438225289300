import { faCalendarAlt, faCheckDouble, faGavel, faMoneyBillWave, faUserFriends, faNotesMedical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import routes from "../../routes/nominas-routes";
import NominaRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/NominaRoutes";
// import "./Nomina.css";
import { LettersContainer, PoweredByNomizor } from "../styled";

const Nomina = ({ match, location }) => {
  const {permisos = {}} = useSelector(store => store.user);

  const [colaboradores, calendario, juridico, nomina, incidencias, imss] = routes(permisos);
  const [actualPage, setActualpage] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);


  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.primary }} className="sub-menu-container">
        <LettersContainer />
        {
          permisos.nomina !== 'none' ?
            <SubSideBar
              icon={faMoneyBillWave}
              title="Nomina"
              routes={[nomina]}
              match={match}
              className={`${actualPage === "configuracion-nomina" || actualPage === "timbrar" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.incidencias !== 'none' ?
            <SubSideBar
              icon={faCheckDouble}
              title="Incidencias"
              routes={[incidencias]}
              match={match}
              className={`${actualPage === "horas-extra" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.colaboradores !== 'none' ?
            <SubSideBar
              icon={faUserFriends}
              title="Colaboradores"
              routes={[colaboradores]}
              match={match}
              className={`${actualPage === "proceso" || actualPage === "altas" || actualPage === "bajas" || actualPage === "lista-colaboradores" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.calendario !== 'none' ?
            <Button
              className={`sub-menu-btn ${actualPage === "calendario" ? "active" : ""}`}
              onClick={(e) => history.push("/dashboard/nomina/calendario")}
              style={{
                marginBottom: "1rem",
                fontSize: "small",
                backgroundColor: colors.buttonColorSecondary,
              }}
            >
              <FontAwesomeIcon color={colors.secondary} icon={faCalendarAlt} style={{ fontSize: "1.2rem", marginRight: "0.5rem", color: "inherit" }} />
              <span className="mt-5 mb-5 ml-3">Calendario</span>
            </Button> : null
        }
        {
          permisos.serviciosJuicios !== 'none' ?
            <SubSideBar
              icon={faGavel}
              title="Jurídico RH"
              routes={[juridico]}
              match={match}
              className={`${actualPage === "juridico-demandas" || actualPage === "juridico-citatorios" ? "active" : ""}`}
            /> : null
        }
        {
          permisos.imss !== 'none' ?
            <SubSideBar
              icon={faNotesMedical}
              title="IMSS/INFONAVIT"
              routes={[imss]}
              match={match}
              className={`${actualPage === "imss-idse"  || actualPage === "imss-conciliacion" || actualPage === "imss-variabilidad" ? "active" : ""}`}
            /> : null
        }
        <PoweredByNomizor />
      </div>
      <div className="frame-content">
        <NominaRoutes path={match.path} />
      </div>
    </section>
  );
};

export default Nomina;
