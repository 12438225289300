export default process.env.REACT_APP_USE === 'sign4life' ? ({
    primary: '#EBECF4',
    secondary: 'linear-gradient(180deg, #006F58 0%, #004499 61.86%)',
    borderColor: '#979797',
    textColorPrimary: '#767676',
    textColorSecondary: '#797979',
    labelText: '#7B7B7B',
    submenuTextColor: '#3C3C3C',
    cardSecondaryText: '#9B9B9B',
    buttonColorPrimary: '#A6AFB9',
    buttonColorSecondary: '#B2BDC8',
    iconsColors: '#969696',
    tashColor: '#3C3C3C',
    closeStatus: '#E96060',
    yellow: '#ffc107'
}) : ({
    primary: '#EBECF4',
    secondary: '#2B388F',
    borderColor: '#979797',
    textColorPrimary: '#767676',
    textColorSecondary: '#797979',
    labelText: '#7B7B7B',
    submenuTextColor: '#3C3C3C',
    cardSecondaryText: '#9B9B9B',
    buttonColorPrimary: '#A6AFB9',
    buttonColorSecondary: '#B2BDC8',
    iconsColors: '#969696',
    tashColor: '#3C3C3C',
    closeStatus: '#E96060',
    yellow: '#ffc107'
})