import React, {useEffect,useCallback} from 'react';
import { Button,Row,Col } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { getCitatoriosAction,buscarCitatorio } from '../../store/citatorioDuck';
import Search from "../ui/Search";
import Citatorios from '../citatorios/Citatorios';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import TableBody from '../citatorios/TableBody';
import colors from '../../constans/colors';


const JuridicoCitatorios = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { listaCitatorios = [] } = useSelector(
	  (state) => state.citatorio
	);

  const onSearch = useCallback(text => {
    dispatch(buscarCitatorio(text))
  })

	useEffect(() => {
		(async () => {
		  try {
			await dispatch(getCitatoriosAction());
		  } catch (e) {
			//SweetAlert({ icon: 'error', title: 'Error!', text: e });
		  }
		})();
	  }, [dispatch]);
  return (
    <>
      <HeaderStyle>
        <h1>Citatorios</h1>
        <h3>Estos son los citatorios en jurídico</h3>
      </HeaderStyle>
      <Row>
        <Col lg={12}>
          <Search onChange={onSearch} placeholder="Buscar por nombre"/>
        </Col>
      </Row>
	    <TableContainer style={{ marginTop:10}}>
        <TableCommon
          keysAceepted={['Colaborador', 'Empresa Citada', 'Ciudad', 'Estado','No. Citatorio','Estatus','']}
          extraTag={{ key: 'Valor Total', value: 1200 }}
          striped
        >
        <TableBody listaCitatorios={listaCitatorios}/>
        </TableCommon>
        <Button type="button" onClick={()=>{
          history.push('agregar-citatorio')
        }} 
      style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}>+ Agregar</Button>
      </TableContainer>
    </>
  );
};
export default JuridicoCitatorios;
