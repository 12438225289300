import { faArrowAltFromLeft, faArrowAltFromRight, faArrowAltLeft, faArrowAltRight, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { CustomInput } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Row from 'reactstrap/lib/Row';
import SweetAlert from 'sweetalert2';
import SemanaControl from '../../components/incidencias/semanaControl/SemanaControl';
import ReactTableStyle from '../../components/incidencias/styles';
import ComponenDescrip from '../../components/ui/ComponentDescrip';
import { DarkButton, LightMiniButton } from '../../components/ui/styles/styles';
import makeTableData from './makeTableData';
import ToogleSwitch from './ToggleSwitch';

const api = process.env.REACT_APP_API_URL;

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData,
}) => {
    const [value, setValue] = React.useState(initialValue);
    const [updated, setUpdated] = React.useState(false);

    const onBlur = (val) => {
        updateMyData(index, id, val)
    }

    React.useEffect(()=>{
        if(updated){
            updateMyData(index, id, value)
            setUpdated(false);
        }
    },[value, updated])

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (id === 'name' || id === 'warnings')
        return <label className='labels'>{value}</label>
    else
        return (
            <div className='control-group' style={{display: 'flex'}}>
                <CustomInput
                    type="checkbox"
                    name="falta"
                    checked={value? value.falta : value}
                    onChange={e => {setUpdated(true); setValue({ falta: e.target.checked })}}
                    id={`_${index}_${moment(id).format('DD')}CB`}
                />
                <ToogleSwitch
                    disabled={!value || value && value.falta !== true}
                    onChange={e => {setUpdated(true); setValue({...value, paga: e.target.checked })}}
                    checked={value? value.paga : value}
                    id={`_${index}_${moment(id).format('DD')}TB`}
                />
            </div>
        )
}

const defaultColumn = {
    Cell: EditableCell,
}

function Table({ columns, data, updateMyData, skipPageReset }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            autoResetPage: !skipPageReset,
            updateMyData,
        },
        useSortBy,
        usePagination
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? (<FontAwesomeIcon
                                                    icon={faSortDown}
                                                />)
                                                : (<FontAwesomeIcon
                                                    icon={faSortUp}
                                                />)
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <LightMiniButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltFromRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltLeft} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => nextPage()} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltFromLeft} />
                </LightMiniButton>{' '}
                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Ir a la página:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

function Faltas() {
    const [date, setDate] = useState(moment());

    const swal = (title, description, success) => {
        SweetAlert.fire({
            title: title,
            text: description,
            icon: success ? 'success' : 'error',
            confirmButtonText: 'Aceptar'
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Nombre',
                        accessor: 'name',
                    },
                    {
                        Header: `Lunes ${moment(date).startOf('isoWeek').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Martes ${moment(date).startOf('isoWeek').add(1, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(1, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Miércoles ${moment(date).startOf('isoWeek').add(2, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(2, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Jueves ${moment(date).startOf('isoWeek').add(3, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(3, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Viernes ${moment(date).startOf('isoWeek').add(4, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(4, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Sábado ${moment(date).startOf('isoWeek').add(5, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(5, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Domingo ${moment(date).startOf('isoWeek').add(6, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(6, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: '',
                        accessor: 'warnings'
                    }
                ],
            },
        ],
        [date]
    )

    const [listaEmpresas, setEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState({ nombre_empresa: '' });
    const [listaSucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState({ nombreSucursal: '' });
    const [listaDepartamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState({ nombreDepartamento: '' });
    const [listaColaboradores, setColaboradores] = useState([]);
    const [aplicaSeptimoDia, setAplicaSeptimoDia] = useState(true);
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    };

    const [data, setData] = React.useState([])
    const [originalData] = React.useState(data)
    const [skipPageReset, setSkipPageReset] = React.useState(false)

    const updateMyData = (rowIndex, columnId, value) => {
        value.septimoDia = aplicaSeptimoDia
        console.log('value', value)
        setSkipPageReset(true)
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex && value && (value.falta === true || value.falta === false)) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value
                    }
                }
                return row
            })
        )
    }

    React.useEffect(() => {
        setSkipPageReset(false);
    }, [data])

    const saveData = async (plantilla) => {
        try {
            let { data } = await axios.post(`${api}/faltas/crear-masivo?dateStart=${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}&dateEnd=${moment(date).endOf('isoWeek').format('YYYY-MM-DD')}&septimoDia=${aplicaSeptimoDia}`, plantilla, headers)
            if (data)
                swal('Gurdado exitoso', 'Se han registrado las faltas', true);
        } catch (error) {
            swal('Ocurrió un error', error, false);
        }
    }


    const fetchEmpresas = async () => {
        let { data } = await axios.get(`${api}/empresa/obtener_empresas`, headers);
        setEmpresas(data.empresas)
    }

    const fetchSucursales = async (empresa) => {
        const emp = listaEmpresas.filter((e) => e.nombre_empresa === empresa.nombre_empresa);
        if (emp.length === 1) {
            setAplicaSeptimoDia(emp[0].aplicaSeptimoDia)
            let { data } = await axios.get(`${api}/sucursales/por_empresa?empresa=${emp[0]._id}`, headers);
            data.resultado.unshift({ nombreSucursal: 'Todas', _id: 'all' })
            setSucursales(data.resultado);
        }
    }

    const fetchDepartamentos = async (sucursal) => {
        const suc = listaSucursales.filter((e) => e.nombreSucursal === sucursal.nombreSucursal);
        if (suc.length === 1) {
            let { data } = await axios.get(`${api}/departamentos/por_sucursal?sucursal=${suc[0]._id}`, headers);
            data.resultado.unshift({ nombreDepartamento: 'Todos', _id: 'all' })
            setDepartamentos(data.resultado);
        }
    }

    const fetchColaboradores = async (pertenencia, type) => {
        let grupo = [];
        if (type === 'departamento')
            grupo = listaDepartamentos.filter(d => d.nombreDepartamento === pertenencia.nombreDepartamento);
        else if (type === 'sucursal')
            grupo = listaSucursales.filter(d => d.nombreSucursal === pertenencia.nombreSucursal);
        else if (type === 'empresa')
            grupo = listaEmpresas.filter(d => d.nombre_empresa === pertenencia.nombre_empresa);
        if (grupo.length === 1) {
            let { data } = await axios.get(`${api}/faltas/lista-masivo/${grupo[0]._id}?type=${type}&dateStart=${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}&dateEnd=${moment(date).endOf('isoWeek').format('YYYY-MM-DD')}`, headers);
            setColaboradores(data.resultado);
            const cdata = makeTableData(data.resultado, date);
            setData(cdata)
        }
    }

    useEffect(() => {
        fetchEmpresas();
    }, [])

    useEffect(() => {
        if (empresa)
            fetchSucursales(empresa);
    }, [empresa])

    useEffect(() => {
        if (sucursal && sucursal.nombreSucursal !== 'Todas') {
            fetchDepartamentos(sucursal);
        } else {
            setDepartamentos([])
            setDepartamento({})
            fetchColaboradores(empresa, 'empresa');
        }
    }, [sucursal])

    useEffect(() => {
        setData([])
        if (departamento && departamento.nombreDepartamento !== 'Todos' && sucursal.nombreSucursal !== 'Todas') {
            fetchColaboradores(departamento, 'departamento');
        } else if (sucursal && sucursal.nombreSucursal !== 'Todas') {
            fetchColaboradores(sucursal, 'sucursal')
        } else {
            fetchColaboradores(empresa, 'empresa')
        }
    }, [departamento, date])



    return (
        <>
            <ComponenDescrip title={"Faltas"} description={"Registra las inasistencias de tus colaboradores"} />
            <Row>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setEmpresa({ nombre_empresa: e.target.value })}
                        value={empresa.nombre_empresa}
                    >
                        <option value={""}>Empresa...</option>
                        {listaEmpresas.map((e) => (
                            <option value={e.nombre_empresa} key={e._id}>
                                {e.nombre_empresa}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setSucursal({ nombreSucursal: e.target.value })}
                        value={sucursal.nombreSucursal}
                    >
                        <option value={""}>Sucursal...</option>
                        {listaSucursales.map((s) => (
                            <option value={s.nombreSucursal} key={s._id}>
                                {s.nombreSucursal}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setDepartamento({ nombreDepartamento: e.target.value })}
                        value={departamento.nombreDepartamento}
                    >
                        <option value={""}>Departamento...</option>
                        {listaDepartamentos.map((d) => (
                            <option value={d.nombreDepartamento} key={d._id}>
                                {d.nombreDepartamento}
                            </option>
                        ))}
                    </Input>
                </Col>
                <SemanaControl width={4} date={date} setDate={setDate} />
            </Row>
            {listaColaboradores.length > 0 ? (
                <>
                    <ReactTableStyle>
                        <Table
                            className='table table-striped'
                            columns={columns}
                            data={data}
                            updateMyData={updateMyData}
                            skipPageReset={skipPageReset}
                        />
                    </ReactTableStyle>

                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={{ offset: 10, size: 2 }}>
                                    <DarkButton onClick={() => saveData(data)}>Guardar</DarkButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : ''}
        </>
    )
}

export default Faltas
