import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'promociones',
    initialState: {
        loading: false,
        error: null,
        lista: [],
        dto: {
            condicionesLaborales: {
                lugarTrabajo: {}
            },
            InformacionNomina: {},
            pertenencia: {}
        },
        isEdit: false
    },
    reducers: {
        // Action handlers
        promocionesRequested: (promociones, action) => {
            promociones.loading = true;
        },
        promocionesReceived: (promociones, action) => {
            promociones.lista = action.payload.response;
            promociones.loading = false;
            promociones.error = null;
        },
        promocionesRequestedFailed: (promociones, action) => {
            promociones.error = action.payload;
            promociones.loading = false;
        },
        promocionSaved: (promociones, action) => {
            promociones.loading = false;
            promociones.error = null;
            promociones.isEdit = false;
        }, 
        promocionAddFailed: (promociones, action) => {
            promociones.error = action.payload;
            promociones.loading = false;
        },
        promocionEditRequested: (promociones, action) => {
            promociones.loading = true;
        },
        promocionEditRequestSuccess: (promociones, action) => {
            promociones.dto = action.payload.response;
            promociones.isEdit = true;
            promociones.loading = false;
            promociones.error = null;
        },
        promocionEditRequestFailed: (promociones, action) =>{
            promociones.error = action.payload;
            promociones.dto = {
                condicionesLaborales: {
                    lugarTrabajo: {}
                },
                InformacionNomina: {},
                pertenencia: {}
            };
            promociones.isEdit = false;
            promociones.loading = false;
        },
        promocionEditFinished: (promociones, action) =>{
            promociones.dto = {
                condicionesLaborales: {
                    lugarTrabajo: {}
                },
                InformacionNomina: {},
                pertenencia: {}
            };
            promociones.isEdit = false;
            promociones.loading = false;
        }
    }
});

// Actions
const { 
    promocionesRequested, 
    promocionesReceived, 
    promocionesRequestedFailed, 
    promocionSaved, 
    promocionAddFailed, 
    promocionEditRequested,
    promocionEditRequestFailed, 
    promocionEditRequestSuccess, 
    promocionEditFinished 
} = slice.actions;

export default slice.reducer;

// Action creators

const getHeaders = () => ({ headers: { token: localStorage.getItem('pv2token')}});

export const promocionEditFinish = () =>(dispatch, getState) => {
    dispatch(promocionEditFinished());
}

export const fetchPromociones = () => async (dispatch, getState, { axios }) => {
    try {
        dispatch(promocionesRequested());
        const response = await axios.get(`promocion/obtener/${getState().colaborador._id}`, getHeaders());
        dispatch(promocionesReceived(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(promocionesRequestedFailed());
        throw data;
    }
}

export const fetchPromocion = (idPromocion) => async (dispatch, getState, { axios }) => {
    try {
        console.log('Consultar Promocion: ' + idPromocion);
        dispatch(promocionEditRequested());
        const response = await axios.get(`promocion/obtener_promocion/${idPromocion}`, getHeaders());
        dispatch(promocionEditRequestSuccess(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(promocionEditRequestFailed());
        throw data;
    }
}

export const deletePromocion = (idPromocion) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(promocionEditRequested());
        await axios.delete(`promocion/${idPromocion}`, getHeaders());
        dispatch(promocionEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(promocionEditRequestFailed());
        throw data;
    }
}

export const createPromocion = (promocion) => async (dispatch, getState, { axios }) => {
    try {
        console.log('Creando promocion para Colaborador');
        dispatch(promocionesRequested());
        const { _id } = getState().colaborador;
        const { ...rest } = promocion;

        const formData = new FormData();
        formData.append('colaborador', _id);
        formData.append(
            'promocion',
            JSON.stringify({ ...rest })
        );
        const { data: { resultado } } = await axios.post('/promocion/crear', formData, getHeaders());
        dispatch(promocionSaved());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(promocionAddFailed());
        throw data;
    }
}

export const editPromocion = (promocion) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(promocionEditRequested());
        const { _id } = getState().colaborador;
        const { ...rest } = promocion;
        const idPromocion = getState().promociones.dto._id;
        console.log(`Editando promocion ${idPromocion}`);

        const formData = new FormData();
        formData.append('colaborador', _id);
        formData.append(
            'promocion',
            JSON.stringify({ ...rest })
        );
        const { data: { resultado } } = await axios.put(`/promocion/modificar/${idPromocion}`, formData, getHeaders());
        dispatch(promocionEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(promocionEditRequestFailed());
        throw data;
    }
}