import React, {useCallback, useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import BotonEditar from "../ui/BotonEditar";
import {faPencil, faTrash} from "@fortawesome/pro-light-svg-icons";
import {eliminarRolAction, elimiinarRolClean} from "../../store/rolesDucks";
import SweetAlert from "../ui/SweetAlet";
import { CancelButton } from '../ui/styles/styles';

const Rol = ({ rol })=>{
    const dispatch= useDispatch();
    const history = useHistory();
    const {error, msg} = useSelector(state => state.roles)
    const {_id, nombreRol, numeroUsuarios}= rol;

    const eliminarRol = useCallback(async (idRol)=>{
        try {
            await dispatch(eliminarRolAction(idRol))
            await dispatch(elimiinarRolClean())
            history.push('/dashboard/empresa/rol')
        }catch (error) {
            await SweetAlert({icon: 'error', title: 'Error!', text: error});
            
        }
    }, [dispatch])
    const eliminarRolBtn = () =>{
        eliminarRol(_id)
    }
    useEffect( () =>{
        error && SweetAlert({icon: 'error', title: 'Error' , text: error});

    }, [error])
    useEffect( () =>{
        msg && SweetAlert({icon: 'success', title: 'Exito' , text: msg});

    }, [msg])

    return (
        <tr>
            <td>{nombreRol}</td>
            <td>{numeroUsuarios}</td>
            <td>
                <Link to={`/dashboard/empresa/asignar-rol/${_id}`}>
                        <CancelButton>Asignar</CancelButton>
                </Link>
            </td>
            <td><BotonEditar clickHandler={eliminarRolBtn} icon={ faTrash }/></td>
            <td><BotonEditar clickHandler={e => history.push(`/dashboard/empresa/detalle-rol/${_id}`)} icon={ faPencil }/></td>
        </tr>
    )
}
export default Rol;