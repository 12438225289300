import React, { useCallback, useState } from "react";
import { Collapse, CardBody, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEnvelope } from "@fortawesome/pro-light-svg-icons";
import { useSelector } from "react-redux";
import { SaveButton } from "../../../ui/styles/styles";



const CollapseInfo = ({ children }) => {
  return (
    <div>
      <Card style={{ margin: 0 }}>
        <CardBody>{children}</CardBody>
      </Card>
    </div>
  );
};

const TableExamenesMedicos = React.memo(
  ({ proximosExamenesMedicos = [], setTable }) => {
    const {informacionPersonal={}} = useSelector(state => state.colaborador)
    const { permisos = {} } = useSelector((state) => state.user);
    const {email='',apellidoMaterno,apellidoPaterno,nombre}=informacionPersonal;
   
    const handleDelete = useCallback(
      (index) => {
        const dataUpdate = [...proximosExamenesMedicos];
        dataUpdate.splice(index, 1);
        setTable(dataUpdate);
      },
      [setTable, proximosExamenesMedicos]
    );
    return (
      <>
        {proximosExamenesMedicos.length > 0
          ? proximosExamenesMedicos.map(
              (
                {
                  tipoExamen,
                  fechaExamenMedico,
                  hora,
                  observaciones,
                  notificacion = false,
                },
                index
              ) => (
                <tr key={tipoExamen + fechaExamenMedico + index}>
                  <td
                    style={{
                      color: "#3C3C3C",
                      fontWeight: "500",
                      width: "30%",
                      verticalAlign: "middle",
                    }}
                  >
                    <CollapseInfo>{tipoExamen}</CollapseInfo>
                  </td>
                  <td
                    style={{
                      color: "#3C3C3C",
                      fontWeight: "500",
                      verticalAlign: "middle",
                    }}
                  >
                    {fechaExamenMedico}
                  </td>
                  <td
                    style={{
                      color: "#3C3C3C",
                      fontWeight: "500",
                      verticalAlign: "middle",
                    }}
                  >
                    {hora}
                  </td>
                  <td
                    style={{
                      color: "#3C3C3C",
                      fontWeight: "500",
                      verticalAlign: "middle",
                    }}
                  >
                    {observaciones}
                  </td>
                  <td style={{ marginLeft: 20, verticalAlign: "middle" }}>
                    {notificacion ? (
                        <FontAwesomeIcon icon={faEnvelope} />
                    ) : null}
                  </td>
                  <td style={{ marginLeft: 20, verticalAlign: "middle" }} hidden={permisos.colaboradorSalud !== 'write'}>
                    <FontAwesomeIcon
                      cursor="pointer"
                      onClick={() => handleDelete(index)}
                      icon={faTrash}
                    />
                  </td>
                </tr>
              )
            )
          : null}
      </>
    );
  }
);

export default TableExamenesMedicos;
