import React from 'react';
import ComponentDescrip from '../ui/ComponentDescrip';

import {Container, Row, Col, Button} from 'reactstrap'
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import { CancelButton, SaveButton } from '../ui/styles/styles';
const AltaMasiva = ()=>{
    const title='Importación Masiva de Colaboradores';
    const description= 'Descarga la plantilla de excel y al llenarla subela de nuevo para su importación';
    const icon= faUsers;
    return(
        <Container>
            <Row className="mt-5">
                <ComponentDescrip icon={icon} title={title} description={description}/>

            </Row>
            <Row>
                <Col md={6}>
                    <CancelButton size="lg">Descargar</CancelButton>
                </Col>
                <Col md={6}>
                    <SaveButton size="lg">Subir</SaveButton>

                </Col>
            </Row>
        </Container>

    )
}
export default AltaMasiva;