import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { updateColaborador } from '../../../store/colaboradorDucks';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Label,
  Col,
  Input,
  FormGroup,
  CustomInput,
  Button,
  Table,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CustomSelect from '../../ui/CustomSelect';
import optionsContract from '../../../constans/tipoContratos';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaInformacionLaboral } from '../../../constans/yupSchemas';
import colors from '../../../constans/colors';
import { CancelButton, SaveButton, InputUtils } from '../../ui/styles/styles';
import CustomSelectEstados from '../../ui/CustomSelectEstados';
import SweetAlert from '../../ui/SweetAlet';
import CurrencyFormat from 'react-currency-format';
import './CondicionesLaborales.css';
import { validate } from 'isemail';
const FormCondicionesLaborales = React.memo(() => {
  const [horario, setHorario] = useState([]);
  const [listaBeneficiarios, setListaBeneficiarios] = useState([]);
  const [salario, setSalario] = useState({ salarioMensual: '' });
  const [variableHorario, setVariableHorario] = useState({
    end: false,
    start: false,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [rfc, setRFC] = useState('');
  const { salarioMensual } = salario;
  const dispatch = useDispatch();
  useEffect(() => {
    /* return () => {
      dispatch(resetState())
    }; */
  }, [dispatch]);
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaInformacionLaboral),
  });
  const { permisos = {} } = useSelector((state) => state.user);

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) {
      if (!salario.salarioMensual) errors.salarioMensual = 'Salario mensual bruto requerido'
      setErr(errors);
    }
  }, [errors]);

  const onSubmit = async (data) => {
    try {
      data.salarioMensualBruto = salario.salarioMensual;
      data.horarioTrabajo = horario;
      data.beneficiarios = listaBeneficiarios;
      data.fechaIngreso = parseInt(
        moment(data.fechaIngreso).format('x')
      );
      const updateData = { condicionesLaborales: { ...data } };
      await dispatch(updateColaborador(updateData));
      await SweetAlert({
        icon: 'success',
        title: 'Condiciones Laborales Agregada Exitosamente',
      });
    } catch (error) {
      await SweetAlert({ icon: "error", title: "Error!", text: error });
      console.log(error);
    }
  };
  const addingForm = useCallback(() => {
    const { horarioTrabajo } = getValues();
    const cleanInputData = {
      days: [],
      end: '',
      start: ''
    }
    setHorario((state) => [...state, horarioTrabajo]);
    setValue('horarioTrabajo', cleanInputData, { shouldDirty: true });
  }, [getValues, setValue]);
  const addingBeneficiario = useCallback(() => {
    const { beneficiarios } = getValues();
    setListaBeneficiarios((state) => [...state, beneficiarios]);
    const cleanInputData = {
      ciudad: '',
      domicilio: '',
      email: '',
      estado: '',
      nombre: '',
      parentesco: '',
      telefonoCelular: '',
      telefonoFijo: ''
    }
    setValue('beneficiarios', cleanInputData, { shouldDirty: true });
  }, [getValues, setValue]);
  const deleteHorario = (index) => {
    setHorario(horario.filter((rep, i) => i !== index))
  };
  const deleteBeneficiarios = (index) => {
    setListaBeneficiarios(listaBeneficiarios.filter((rep, i) => i !== index))
  };
  const validateRFC = () => {
    console.log(rfc)
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label for='numeroEmpleado' sm={3}>
            Número de Empleado*
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='numeroEmpleado'
              placeholder='Número de empleado'
              innerRef={register()}
            />
            {err.numeroEmpleado && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El número empleado es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='puesto' sm={3}>
            Puesto*
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='puesto'
              placeholder='Nombre puesto en la sucursal'
              innerRef={register()}
            />
            {err.puesto && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El puesto es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='lugarTrabajo.direccion' sm={3}>
            Dirección
          </Label>
          <Col sm={9}>
            <CustomSelect
              control={control}
              options={[
                { value: 'Sucursal', label: 'Sucursal' },
                { value: 'Personal', label: 'Personal' },
                { value: 'Otro', label: 'Otro' },
              ]}
              type='select'
              name='lugarTrabajo.direccion'
              innerRef={register()}
              onChange={(e) => { e.target.value === 'Otro' ? setIsOpen(true) : setIsOpen(false) }}
            />
          </Col>
        </FormGroup>
        {
          isOpen && (
            <>
              <FormGroup row>
                <Label for='lugarTrabajo.estado' sm={3}>
                  Estado
            </Label>
                <Col sm={9}>
                  <CustomSelectEstados
                    type='select'
                    name='lugarTrabajo.estado'
                    id='estadoResidencia'
                    control={control}
                    innerRef={register({ required: true })}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.ciudad' sm={3}>
                  Ciudad
            </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.ciudad'
                    placeholder='Ciudad donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.colonia' sm={3}>
                  Colonia
            </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.colonia'
                    placeholder='Colonia de donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.calle' sm={3}>
                  Calle
            </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.calle'
                    placeholder='Calle de donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='calle' sm={3}>
                  Número Exterior
            </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.numeroExterior'
                    placeholder='Número exterior de donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='telefono' sm={3}>
                  Teléfono*
            </Label>
                <Col sm={9}>
                  <Input
                    type='number'
                    name='telefono'
                    placeholder='Teléfono de donde trabaja'
                    innerRef={register()}
                  />
                  {err.telefono && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                      El teléfono es requerido
                    </span>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='extension' sm={3}>
                  Extension
            </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='extension'
                    placeholder='Extension del telefono de donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            </>
          )
        }
        <FormGroup row>
          <Label for='tipoContrato' sm={3}>
            Tipo Contrato*
          </Label>
          <Col sm={9}>
            <CustomSelect
              type='select'
              name='tipoContrato'
              control={control}
              options={optionsContract}
              placeholder='Tipo contrato de la sucursal donde trabaja'
              innerRef={register()}
            />
            {err.tipoContrato && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El tipo de contrato es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='fechaIngreso' sm={3}>
            Fecha de Ingreso*
          </Label>
          <Col sm={3}>
            <Input
              type='date'
              name='fechaIngreso'
              placeholder='Fecha ingreso de la sucursal donde trabaja'
              innerRef={register()}
            />
            {err.fechaIngreso && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de ingreso es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='rfc' sm={3}>
            RFC*
          </Label>
          <Col sm={3}>
            <Input
              type='text'
              name='rfc'
              placeholder='RFC del colaborador'
              innerRef={register()}
              onChange={(e) => { setRFC(e.target.value) }}
            />
            {err.rfc && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El RFC es requerido
              </span>
            )}
          </Col>
          <Col sm={3}>
            <SaveButton
              type='button'
              onClick={() => { validateRFC(); }}
            >Validar</SaveButton>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='correoInstitucional' sm={3}>
            Correo Institucional
          </Label>
          <Col sm={9}>
            <Input
              type='email'
              name='correoInstitucional'
              placeholder='Correo institucional'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='jefeInmediato' sm={3}>
            Jefe Inmediato
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='jefeInmediato'
              placeholder='Jefe inmediato del colaborador'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='salarioMensualBruto' sm={3}>
            Salario Mensual Bruto*
          </Label>
          <Col sm={9}>
            <CurrencyFormat
              fixedDecimalScale={true}
              decimalScale={2}
              className='inputMoneda'
              value={salarioMensual}
              name='salarioMensualBruto'
              placeholder='Salario mensual bruto'
              innerRef={register()}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setSalario({ salarioMensual: value })
              }}
            />
            {err.salarioMensual && !salario.salarioMensual && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El salario mensual bruto es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <h5>Horario Laboral</h5>
          {horario.length > 0 ? (
            <Table borderless style={{ marginLeft: '0.2rem' }}>
              <thead>
                <tr>
                  <th>Entrada</th>
                  <th>Salida</th>
                  <th>Dias</th>
                </tr>
              </thead>
              <tbody>
                {horario.map(
                  (
                    { start = 'variable', end = 'variable', days },
                    index
                  ) => (
                    <tr key={start + end + index}>
                      <td>{start}</td>
                      <td>{end}</td>
                      <td>{days.join(' ')}</td>
                      <td><FontAwesomeIcon
                        cursor="pointer"
                        onClick={() => deleteHorario(index)}
                        icon={faTrash}
                      /></td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : null}
        </FormGroup>
        <FormGroup row>
          <Label for='horarioEntrada' sm={3}>
            Horario de Entrada
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            {variableHorario.start ? (
              null
            ) : (
              <Input
                type='time'
                name='horarioTrabajo.start'
                innerRef={register}
              />
            )}
          </Col>
          <Col sm={3}>
            <CustomInput
              id='varialbe1'
              type='checkbox'
              label='Variable'
              name='start'
              onChange={() => {
                setVariableHorario((state) => ({ ...state, start: !state.start }));
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='horarioSalida' sm={3}>
            Horario de Salida
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            {variableHorario.end ? (
              null
            ) : (
              <Input
                type='time'
                name='horarioTrabajo.end'
                innerRef={register}
              />
            )}
          </Col>
          <Col sm={3}>
            <CustomInput
              value={true}
              id='varialbe2'
              type='checkbox'
              label='Variable'
              name='end'
              onChange={() => {
                setVariableHorario((state) => ({ ...state, end: !state.end }));
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className='col-sm-12' row inline>
          <CustomInput
            className='mr-3'
            id='lunes'
            name='horarioTrabajo.days'
            value={'L'}
            type='checkbox'
            label='L'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='martes'
            name='horarioTrabajo.days'
            value={'M'}
            type='checkbox'
            label='M'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='miercoles'
            name='horarioTrabajo.days'
            type='checkbox'
            value={'MI'}
            label='MI'
            innerRef={register}
          />
          <CustomInput
            className='mr-1'
            id='jueves'
            name='horarioTrabajo.days'
            value={'J'}
            type='checkbox'
            label='J'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='viernes'
            name='horarioTrabajo.days'
            value={'V'}
            type='checkbox'
            label='V'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='sabado'
            name='horarioTrabajo.days'
            value={'S'}
            type='checkbox'
            label='S'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='domingo'
            name='horarioTrabajo.days'
            value={'D'}
            type='checkbox'
            label='D'
            innerRef={register}
          />
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <CancelButton type='button' onClick={addingForm}>
              Agregar Horario
            </CancelButton>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            {listaBeneficiarios.length > 0 ? (
              <Table striped>
                <thead>
                  <tr>
                    <th>Beneficiario</th>
                    <th>Parentesco</th>
                    <th>Email</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {listaBeneficiarios.length > 0
                    ? listaBeneficiarios.map(
                      ({ nombre = '', parentesco = '', email = '' }, index) => (
                        <tr key={nombre + index}>
                          <td>{nombre}</td>
                          <td>{parentesco}</td>
                          <td>{email}</td>
                          <td>
                            <FontAwesomeIcon
                              color={colors.tashColor}
                              cursor="pointer"
                              onClick={() => deleteBeneficiarios(index)}
                              icon={faTrash}
                            />
                          </td>
                        </tr>
                      )
                    )
                    : null}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <h5>Designación de Beneficiarios</h5>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.nombre' sm={3}>
            Nombre
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.nombre'
              placeholder='Nombre del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.domicilio' sm={3}>
            Domicilio
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.domicilio'
              placeholder='Domicilio del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.estado' sm={3}>
            Estado
          </Label>
          <Col sm={9}>
            <CustomSelectEstados
              type='select'
              name='beneficiarios.estado'
              id='estadoResidencia'
              control={control}
              innerRef={register({ required: true })}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.ciudad' sm={3}>
            Ciudad
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.ciudad'
              placeholder='Ciudad de residencia del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.telefonoCelular' sm={3}>
            Teléfono Celular
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.telefonoCelular'
              placeholder='Número telefono celular beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.telefonoFijo' sm={3}>
            Teléfono Fijo
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.telefonoFijo'
              placeholder='Número telefono fijo beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.parentesco' sm={3}>
            Parentesco
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.parentesco'
              placeholder='Parentesco con el beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.email' sm={3}>
            Correo Electrónico
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.email'
              placeholder='Correo electrónico del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3} className='text-center'>
            <CancelButton type='button' onClick={addingBeneficiario}>
              Agregar Beneficiarios
          </CancelButton>
          </Col>
        </FormGroup>
        <FormGroup hidden={permisos.colaboradorLaborales !== 'write'}>
          <Col sm={{ offset: 9, size: 5 }} className='text-center'>
            <SaveButton
              type='submit'
              onClick={() => {
                setValue('horarioTrabajo', horario);
              }}
            >Guardar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
});

export default FormCondicionesLaborales;
