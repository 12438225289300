const optionsBancos = [
    { value: '002', label: 'BANAMEX' },
    { value: '006', label: 'BANCOMEXT' },
    { value: '009', label: 'BANOBRAS' },
    { value: '012', label: 'BBVA BANCOMER' },
    { value: '014', label: 'SANTANDER' },
    { value: '019', label: 'BANJERCITO' },
    { value: '021', label: 'HSBC' },
    { value: '030', label: 'BAJIO' },
    { value: '032', label: 'IXE' },
    { value: '036', label: 'INBURSA' },
    { value: '037', label: 'INTERACCIONES' },
    { value: '042', label: 'MIFEL' },
    { value: '044', label: 'SCOTIABANK' },
    { value: '058', label: 'BANREGIO' },
    { value: '059', label: 'INVEX' },
    { value: '060', label: 'BANSI' },
    { value: '062', label: 'AFIRME' },
    { value: '072', label: 'BANORTE/IXE' },
    { value: '102', label: 'THE ROYAL BANK' },
    { value: '103', label: 'AMERICAN EXPRESS' },
    { value: '106', label: 'BAMSA' },
    { value: '108', label: 'TOKYO' },
    { value: '110', label: 'JP MORGAN' },
    { value: '112', label: 'BMONEX' },
    { value: '113', label: 'VE POR MAS' },
    { value: '116', label: 'ING' },
    { value: '124', label: 'DEUTSCHE' },
    { value: '126', label: 'CREDIT SUISSE' },
    { value: '127', label: 'AZTECA' },
    { value: '128', label: 'AUTOFIN' },
    { value: '129', label: 'BARCLAYS' },
    { value: '130', label: 'COMPARTAMOS' },
    { value: '131', label: 'BANCO FAMSA' },
    { value: '132', label: 'BMULTIVA' },
    { value: '133', label: 'ACTINVER' },
    { value: '134', label: 'WAL-MART' },
    { value: '135', label: 'NAFIN' },
    { value: '136', label: 'INTERCAM BANCO' },
    { value: '137', label: 'BANCOPPEL' },
    { value: '138', label: 'ABC CAPITAL' },
    { value: '139', label: 'UBS BANK' },
    { value: '140', label: 'CONSUBANCO' },
    { value: '141', label: 'VOLKSWAGEN' },
    { value: '143', label: 'CIBANCO' },
    { value: '145', label: 'BBASE' },
    { value: '147', label: 'BANKAOOL' },
    { value: '148', label: 'PAGATODO' },
    { value: '149', label: 'FORJADORES' },
    { value: '150', label: 'INMOBILIARIO' },
    { value: '151', label: 'DOND' },
    { value: '152', label: 'BANCREA' },
    { value: '153', label: 'PROGRESO' },
    { value: '154', label: 'BANCO FINTERRA' },
    { value: '155', label: 'ICBC' },
    { value: '156', label: 'SABADELL' },
    { value: '157', label: 'SHINHAN' },
    { value: '158', label: 'MIZUHO BANK' },
    { value: '159', label: 'BANK OF CHINA' },
    { value: '160', label: 'BANCO S3' },
    { value: '166', label: 'BANSEFI' },
    { value: '168', label: 'HIPOTECARIA FEDERAL' },
    { value: '600', label: 'MONEXCB' },
    { value: '601', label: 'GBM' },
    { value: '602', label: 'MASARI' },
    { value: '605', label: 'VALUE' },
    { value: '606', label: 'ESTRUCTURADORES' },
    { value: '607', label: 'TIBER' },
    { value: '608', label: 'VECTOR' },
    { value: '610', label: 'B&B' },
    { value: '614', label: 'ACCIVAL' },
    { value: '615', label: 'MERRILL LYNCH' },
    { value: '616', label: 'FINAMEX' },
    { value: '617', label: 'VALMEX' },
    { value: '618', label: 'UNICA' },
    { value: '619', label: 'MAPFRE' },
    { value: '620', label: 'PROFUTURO' },
    { value: '621', label: 'CB ACTINVER' },
    { value: '622', label: 'OACTIN' },
    { value: '623', label: 'SKANDIA' },
    { value: '626', label: 'CBDEUTSCHE' },
    { value: '627', label: 'ZURICH' },
    { value: '628', label: 'ZURICHVI' },
    { value: '629', label: 'SU CASITA' },
    { value: '630', label: 'CB INTERCAM' },
    { value: '631', label: 'CI BOLSA' },
    { value: '632', label: 'BULLTICK CB' },
    { value: '633', label: 'STERLING' },
    { value: '634', label: 'FINCOMUN' },
    { value: '636', label: 'HDI SEGUROS' },
    { value: '637', label: 'ORDER' },
    { value: '638', label: 'AKALA' },
    { value: '640', label: 'CB JPMORGAN' },
    { value: '642', label: 'REFORMA' },
    { value: '646', label: 'STP' },
    { value: '647', label: 'TELECOMM' },
    { value: '648', label: 'EVERCORE' },
    { value: '649', label: 'SKANDIA' },
    { value: '651', label: 'SEGMTY' },
    { value: '652', label: 'ASEA' },
    { value: '653', label: 'KUSPIT' },
    { value: '655', label: 'SOFIEXPRESS' },
    { value: '656', label: 'UNAGRA' },
    { value: '659', label: 'OPCIONES EMPRESARIALES DEL NOROESTE' },
    { value: '670', label: 'LIBERTAD' },
    { value: '901', label: 'CLS' },
    { value: '902', label: 'INDEVAL' },
  ];
  
  export default optionsBancos;