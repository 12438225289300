import moment from "moment";

const initState ={
    listaDemandas:[],
    loading: false,
    error: null,
    editarDemanda:false,
    demanda:{}
}
export const DEMANDA_START = 'DEMANDA_START';

export const GET_LISTA_DEMANDAS = 'GET_LISTA_DEMANDAS';
export const GET_LISTA_DEMANDAS_EXITO = 'GET_LISTA_DEMANDAS_EXITO';
export const GET_LISTA_DEMANDAS_ERROR = 'GET_LISTA_DEMANDAS_ERROR';

export const CREAR_DEMANDA_EXITO = 'CREAR_DEMANDA_EXITO';
export const CREAR_DEMANDA_ERROR = 'CREAR_DEMANDA_ERROR';

export const ELIMINAR_DEMANDA_EXITO = 'ELIMINAR_DEMANDA_EXITO';
export const ELIMINAR_DEMANDA_ERROR = 'ELIMINAR_DEMANDA_ERROR';

export const DETALLE_DEMANDA_EXITO = 'DETALLE_DEMANDA_EXITO';
export const DETALLE_DEMANDA_ERROR = 'DETALLE_DEMANDA_ERROR';

export const EDITAR_DEMANDA_EXITO= 'EDITAR_DEMANDA_EXITO';
export const EDITAR_DEMANDA_ERROR= 'EDITAR_DEMANDA_ERROR';

export const EDITAR_BTN_DEMANDA = 'EDITAR_BTN_DEMANDA';
export const CERRAR_DEMANDA = 'CERRAR_DEMANDA';
export const CERRAR_DEMANDA_EXITO = 'CERRAR_DEMANDA_EXITO';
export const CERRAR_DEMANDA_ERROR = 'CERRAR_DEMANDA_ERROR';


export default function demandasReducer (state=initState, {type, payload}){
    switch (type) {
        case DEMANDA_START:
            return { ...state, loading: true}

        case GET_LISTA_DEMANDAS_EXITO:
            return {...state, loading: false,listaDemandas: [...payload]}
        case GET_LISTA_DEMANDAS_ERROR:
            return {...state, loading: false}

        case CREAR_DEMANDA_EXITO:
            return {...state, loading: false}
        case CREAR_DEMANDA_ERROR:
            return {...state, loading: false}

        case EDITAR_BTN_DEMANDA:
            return { ...state, editarDemanda: !state.editarDemanda };
        case DETALLE_DEMANDA_EXITO:
            return {...state, loading: false , demanda: payload}
        case CERRAR_DEMANDA:
            return { ...state, loading: true };
        case CERRAR_DEMANDA_EXITO:
            return { ...state, loading: false };
        case CERRAR_DEMANDA_ERROR:
            return { ...state, loading: false };
        default:
            return  state

    }
}
const demandaStart = () =>({
    type :DEMANDA_START
})
const getListaDemandas = () =>({
    type : GET_LISTA_DEMANDAS
})
const getListaDemandasExito = payload =>({
    type : GET_LISTA_DEMANDAS_EXITO,
    payload
})
const getListaDemandasError = () =>({
    type : GET_LISTA_DEMANDAS_ERROR
})
export const cerrarDemanda = () => ({
    type: CERRAR_DEMANDA,
});
export const cerrarDemandaExito = () => ({
    type: CERRAR_DEMANDA_EXITO,
});
export const cerrarDemandaError = () => ({
    type: CERRAR_DEMANDA_ERROR,
});
const crearDemandaExito = () =>({
    type : CREAR_DEMANDA_EXITO
})
const crearDemandaError = () =>({
    type: CREAR_DEMANDA_ERROR
})
export const editarDemandaBtn = () => ({
    type: EDITAR_BTN_DEMANDA,
});
const eliminarDemandaExito=() =>({
    type: ELIMINAR_DEMANDA_EXITO
})
const eliminarDemandaError= ()=>({
    type: ELIMINAR_DEMANDA_ERROR
})
export const detalleDemandaExito = payload =>({
    type : DETALLE_DEMANDA_EXITO,
    payload
})
const detalleDemandaError = () =>({
    type : DETALLE_DEMANDA_ERROR
})
const editarDemandaExito = () =>({
    type: EDITAR_DEMANDA_EXITO
})
const editarDemandaError = () =>({
    type: EDITAR_DEMANDA_ERROR
})
export const getDemandasAction = () => async(dispatch, getState, {axios}) =>{
    dispatch(demandaStart())
    try{
        const { data: { respuesta}} = await axios.get('/demandas', {
            headers: { token: localStorage.getItem('pv2token') }});
        dispatch(getListaDemandasExito(respuesta))
    }catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(getListaDemandasError(data))
        throw data;
    }
}
export const crearDemandaAction = ({empresa, fechaDemanda, domicilioTrabajo, puesto, salario, horario, jefeInmediato,
                                       fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral,
                                       colaborador, departamento, demandado, contraparte, antecedentes, estadoActual,audiencias,cierre, archivos}) => async(dispatch, getState, {axios}) =>{
    dispatch(demandaStart())
    try{
        const { data: { respuesta}} = await axios.post('/demandas',
            {empresa, fechaDemanda, domicilioTrabajo, puesto, salario, horario, jefeInmediato,
            fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral,
            colaborador, departamento, demandado, contraparte, antecedentes, estadoActual, audiencias, cierre, archivos},
            {headers: { token: localStorage.getItem('pv2token') }});
        dispatch(crearDemandaExito(respuesta))
    }catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(crearDemandaError(data))
        throw data;
    }
}
export const eliminarDemandaAction = () => async (
    dispatch,
    getState,
    { axios }
  ) => {
    try {
      dispatch(getListaDemandas());
      const { _id } = getState().demanda.demanda;
      await axios.delete(`/demandas/${_id}`, {
        headers: {
          token: localStorage.getItem('pv2token'),
        },
      });
      dispatch(eliminarDemandaExito());
    } catch (e) {
      const { response } = e;
      const { request, ...errorObject } = response;
      const { data } = errorObject;
      dispatch(eliminarDemandaError());
      throw data;
    }
  };
export const detalleDemandaAction  = idDemanda => async(dispatch, getState, {axios}) =>{
    dispatch(getListaDemandas())
    try{
        const { data: { respuesta}} = await axios.get(`/demandas/${idDemanda}`, {
            headers: { token: localStorage.getItem('pv2token') }});
        dispatch(detalleDemandaExito(respuesta))
    }catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
       dispatch(detalleDemandaError(data))
        throw data;
    }
}
export const editarDemandaAction = ({empresa, fechaDemanda, domicilioTrabajo, puesto, salario, horario, jefeInmediato,
                                       fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral,
                                       colaborador, departamento, demandado, contraparte, antecedentes, estadoActual,audiencias,cierre, archivos}) => async(dispatch, getState, {axios}) =>{
    dispatch(demandaStart())
    const { _id } = getState().demanda.demanda;

    try{
        const { data: { respuesta}} = await axios.put(`/demandas/${_id}`,
            {empresa, fechaDemanda, domicilioTrabajo, puesto, salario, horario, jefeInmediato,
                fechaDespido, fechaActualizacion, noExpediente, estado, ciudad, autoridadLaboral,
                colaborador, departamento, demandado, contraparte, antecedentes, estadoActual, audiencias, cierre, archivos},
            {headers: { token: localStorage.getItem('pv2token') }});
        dispatch(editarDemandaExito(respuesta))
    }catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(editarDemandaError(data))
        throw data;
    }
}

export const buscarDemandaPorExpediente = (searchText, filter = "") => async (dispatch, getState, { axios }) => {
    if (searchText == "") {
        getDemandasAction(filter)(dispatch, getState, { axios });
    } else if (filter === "") {
      const lista = getState().demanda.listaDemandas.filter(
        (c) =>
          c.noExpediente.toLowerCase().includes(searchText.toLowerCase())
      );
      dispatch(getListaDemandasExito(lista));
    } else {
      const lista = getState().demanda.listaDemandas.filter(
        (c) =>
          c.noExpediente.toLowerCase().includes(searchText.toLowerCase())
      );
      dispatch(getListaDemandasExito(lista));
    }
  };

  export const cierreDemanda = (dataCierre) => async (
    dispatch,
    getState,
    { axios }
  ) => {
    try {
      const { _id } = getState().demanda.demanda;
      dispatch(cerrarDemanda());
      const { evidencia, ...cierre } = dataCierre;
      const formData = new FormData();
      if (evidencia) {
        formData.append('archivos', evidencia);
      }
      formData.append('cierre', JSON.stringify(cierre));
      const {
        data: { resultado },
      } = await axios.post(`/demandas/cierre/${_id}`, formData, {
        headers: {
          token: localStorage.getItem('pv2token'),
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(cerrarDemandaExito(resultado));
    } catch (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      const { data } = errorObject;
      dispatch(cerrarDemandaError(data));
    }
  };
  