import React from 'react';
import {ButtonDowloadSalud} from '../../../ui/ButtonDowload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';

const TableBody = React.memo(({ examenMedicos = [], handleDelete }) => {
  const { permisos = {} } = useSelector((state) => state.user);
  return (
    <>
      {examenMedicos.map((examenMedico, index) => (
        <tr key={examenMedico.nombreExamen + index}>
          <td style={{color:'#3C3C3C',fontWeight:'500',width:'30%', verticalAlign:'middle'}}>{examenMedico.nombreExamen}</td>
          <td style={{ color: '#3C3C3C', fontWeight: '500', verticalAlign: 'middle'}}>{examenMedico.fechaAplicacion}</td>
          <td style={{ marginLeft: 50, color: '#3C3C3C', verticalAlign: 'middle'}}>
          {examenMedico.archivoExamenMedico
            ?<ButtonDowloadSalud
              Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${examenMedico.archivoExamenMedico}`}
            />
            :null
          }
            
          </td>
          <td style={{ marginLeft: 20, verticalAlign: 'middle'}} hidden={permisos.colaboradorSalud !== 'write'}>
            <FontAwesomeIcon 
              cursor="pointer"
              onClick={() => handleDelete(examenMedico.nombreExamen)}
              icon={faTrash} 
            />
          </td>
        </tr>
      ))}
    </>
  );
});

export default TableBody;
