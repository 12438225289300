import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {getSucursalExito} from '../../store/sucursalDuck';
import { selecRuta } from '../../store/empresasDuck';
import { Tr, BackgroundImage } from '../ui/styles/styles';
import Icons from '../ui/Icons';
import yisus from '../../assets/img/yisus.jpg';

const TableBodySucursal = React.memo(({ listaSucursales = [],history }) => {
  const dispatch = useDispatch();
  const EditEmpresas = useCallback((sucursal) => {
    dispatch(getSucursalExito(sucursal));
    dispatch(selecRuta({empresa:true}));
    history.push('/dashboard/cuenta/sucursal')
  }, [dispatch,history]);
  return (
    <>
      {listaSucursales.map(({ logotipo, nombreSucursal }, index, sucursal) => (
        <Tr
          key={nombreSucursal + index}
          onClick={() => EditEmpresas(sucursal[index])}
          style={{ cursor: 'pointer' }}
        >
          <td style={{ width: '4vw' }}>
            <BackgroundImage
              imageUrl={
                logotipo
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
                  : yisus
              }
            />
          </td>
          <td className='span-label'>{nombreSucursal}</td>
          <td className='middle' style={{ textAlign: 'center', padding: 0, verticalAlign: 'middle' }}>
            <Icons pencil />
          </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBodySucursal;