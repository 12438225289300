export const PERMISOSLABORALES_START = 'PERMISOSLABORALES_START';

export const PERMISOSLABORALES_LISTA_EXITO = 'PERMISOSLABORALES_LISTA_EXITO';
export const PERMISOSLABORALES_LISTA_ERROR = 'PERMISOSLABORALES_LISTA_ERROR';
export const CREAR_PERMISOSLABORALES_EXITO = 'CREAR_PERMISOSLABORALES_EXITO';
export const CREAR_PERMISOSLABORALES_ERROR = 'CREAR_PERMISOSLABORALES_ERROR';
const initialState = {
  loading: false,
  error: null,
  listaPermisosLaborales: [],
  permiso: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PERMISOSLABORALES_START:
      return { ...state, loading: true };
    case PERMISOSLABORALES_LISTA_EXITO:
      return { ...state, loading: false, listaPermisosLaborales: [...payload] };
    case PERMISOSLABORALES_LISTA_ERROR:
      return { ...state, loading: false };
    case CREAR_PERMISOSLABORALES_EXITO:
      return { ...state, loading: false };
    case CREAR_PERMISOSLABORALES_ERROR:
      return { ...state, loading: false };
    case 'AGREGAR_PERMISO':
      return { ...state, permiso: { ...payload } };
    default:
      return state;
  }
};

export const permisosStart = () => ({
  type: PERMISOSLABORALES_START,
});

export const listaPermisosExito = (payload) => ({
  type: PERMISOSLABORALES_LISTA_EXITO,
  payload,
});

export const listaPermisosError = () => ({
  type: PERMISOSLABORALES_LISTA_ERROR,
});

export const createPermisosExito = () => ({
  type: CREAR_PERMISOSLABORALES_EXITO,
});

export const createPermisosError = () => ({
  type: CREAR_PERMISOSLABORALES_ERROR,
});

export const agregarPermiso = (payload) => ({
  type: 'AGREGAR_PERMISO',
  payload,
});

export const fetchListaPermisos = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(permisosStart());
    const { _id } = getState().colaborador;
    const {
      data: { resultado = [] },
    } = await axios.get(`/permisos-laborales/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaPermisosExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaPermisosError());
    throw data;
  }
};

export const fetchCreatePermisos = (dataPermisos) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(permisosStart());
    const { _id } = getState().colaborador;
    const {
      solicitudPermiso = {},
      autorizacionPermiso = {},
    ...rest
    } = dataPermisos;
    const formData = new FormData();
    formData.append('solicitudPermiso', solicitudPermiso[0]);
    formData.append('autorizacionPermiso', autorizacionPermiso[0]);    
    formData.append('colaborador', _id);
    formData.append(
      'dataPermisos',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.post('/permisos-laborales/crear', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createPermisosExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createPermisosError());
    throw data;
  }
};

export const fetchUpdatePermisos = (dataPermisos) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(permisosStart());
    const { _id } = getState().permisos.permiso;
    const {
      solicitudPermiso = {},
      autorizacionPermiso = {},
    ...rest
    } = dataPermisos;
    const formData = new FormData();
    formData.append('solicitudPermiso', solicitudPermiso[0]);
    formData.append('autorizacionPermiso', autorizacionPermiso[0]);    
    formData.append(
      'dataPermisos',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.put(`/permisos-laborales/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createPermisosExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createPermisosError());
    throw data;
  }
};

export const deletePermiso = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(permisosStart());
    const { _id } = getState().permisos.permiso;
    await axios.delete(`/permisos-laborales/${_id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createPermisosExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createPermisosError());
    throw data;
  }
};

export const eliminarExtension = (dataExt) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    console.log(dataExt);
    const { _id } = getState().permisos.permiso;
    await axios.put(`/permisos-laborales/del-extension/${_id}`, {dataExt}, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createPermisosExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createPermisosError());
    throw data;
  }
};

export const fetchAgregarExtension = (dataPermisos) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(permisosStart());
    const { _id } = getState().permisos.permiso;
    const {
      solicitudExtension = {},
      autorizacionExtension = {},
    ...rest
    } = dataPermisos;
    const formData = new FormData();
    formData.append('solicitudExtension', solicitudExtension[0]);
    formData.append('autorizacionExtension', autorizacionExtension[0]);    
    formData.append(
      'dataExtension',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.post(`/permisos-laborales/extension/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createPermisosExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createPermisosError());
    throw data;
  }
};
