import React, { useEffect } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selecRuta } from '../../../store/empresasDuck';
import FormEmpresa from '../../../components/empresa/FormEmpresa';
import PaquetePercepciones from '../../../components/empresa/PaquetePrestaciones';
import SideMenu from '../../../components/layout/EmpresaMenu/SideMenu';
import TablaSucursal from '../../../components/Sucursal/TableSucursal';

const HashRouteEmpresa = React.memo(({ history }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      const { hash } = history.location;
      if (!hash) dispatch(selecRuta({ empresa: true }));
    };
  }, [dispatch,history]);
  const Sucursales = () => {
    return <TablaSucursal history={history}/> ;
  };

  return (
    <HashRouter basename="/">
      <SideMenu />
      <Switch>
        <Route path='/catalogo' component={PaquetePercepciones} />
        <Route path="/sucursales" component={Sucursales} />
        <Route path="/" component={FormEmpresa} />
      </Switch>
    </HashRouter>
  );
});

export default HashRouteEmpresa;
