import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons';

const ContainerButtonDowload = styled.a`
  text-decoration: none !important;
  width: 174px;
  height: 51px;
  /* border: 2px solid #2b388f;  */
  background-color: white;
  border-radius: 4px;
  padding: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  cursor: pointer;
  text-align: center;
  color: #767676 !important;
  &:hover {
    /* background-color: #2b388f; */
    color: #767676 !important;
    border-color: #767676;
  }
`;
const SpanDowload = styled.span`
  margin-left: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  transition: all 200ms;
  &:hover{
    color: #767676;
  }
`;


const ButtonDowloadDisabled = () => {
  return (
    <ContainerButtonDowload
      
    >
      <FontAwesomeIcon
        color={{color:'#767676'}}
        icon={faCloudDownloadAlt}
        style={{ fontSize: '1.5rem' }}
      />
      <SpanDowload>Descargar</SpanDowload>
    </ContainerButtonDowload>
  );
};


export default ButtonDowloadDisabled;
