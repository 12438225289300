import styled from 'styled-components';
import colors from '../../../constans/colors';

export const NavMenu = styled.nav`
  margin-left: auto;
  margin-right: auto;
  background-color: #ebecf4;
  border-radius: 15px;
  min-height: 12rem;
  max-height:15em;
  width:248px;
  font-weight: 400;
  > ul {
    list-style:none;
    padding: 0;
  }
  > ul > li {
    padding: 1rem;
  }
  > ul > li:hover {
    cursor: pointer;
  }
  > ul > li > span {
    height: 4vh;
    display: flex;
    justify-content: left;
    align-items: center;
    color: black;
    font-family: 'Roboto', sans-serif;
    padding-left: 15px;
    /* font-weight: bold; */
  }
  > ul > li > span:hover {
    text-decoration: none;
  }
  .activeEmpresa {
    background-color: ${colors.secondary};
    border-radius: 4px;
    color: white;
    height: 46px;
    font-weight: 600;
  }
`;
