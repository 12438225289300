export const INCAPACIDADES_START = 'INCAPACIDADES_START';

export const INCAPACIDADES_LISTA_EXITO = 'INCAPACIDADES_LISTA_EXITO';
export const INCAPACIDADES_LISTA_ERROR = 'INCAPACIDADES_LISTA_ERROR';
export const CREAR_INCAPACIDADES_EXITO = 'CREAR_INCAPACIDADES_EXITO';
export const CREAR_INCAPACIDADES_ERROR = 'CREAR_INCAPACIDADES_ERROR';
const initialState = {
  loading: false,
  error: null,
  listaIncapacidades: [],
  incapacidad: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case INCAPACIDADES_START:
      return { ...state, loading: true };
    case INCAPACIDADES_LISTA_EXITO:
      return { ...state, loading: false, listaIncapacidades: [...payload] };
    case INCAPACIDADES_LISTA_ERROR:
      return { ...state, loading: false };
    case CREAR_INCAPACIDADES_EXITO:
      return { ...state, loading: false };
    case CREAR_INCAPACIDADES_ERROR:
      return { ...state, loading: false };
    case 'AGREGAR_INCAPACIDAD':
      return { ...state, incapacidad: { ...payload } };
    default:
      return state;
  }
};

export const incapacidadesStart = () => ({
  type: INCAPACIDADES_START,
});

export const listaIncapacidadesExito = (payload) => ({
  type: INCAPACIDADES_LISTA_EXITO,
  payload,
});

export const listaIncapacidadesError = () => ({
  type: INCAPACIDADES_LISTA_ERROR,
});

export const createIncapacidadesExito = () => ({
  type: CREAR_INCAPACIDADES_EXITO,
});

export const createIncapacidadesError = () => ({
  type: CREAR_INCAPACIDADES_ERROR,
});

export const agregarIncapacidad = (payload) => ({
  type: 'AGREGAR_INCAPACIDAD',
  payload,
});

export const fetchListaIncapacidades = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(incapacidadesStart());
    const { _id } = getState().colaborador;
    const {
      data: { resultado = [] },
    } = await axios.get(`/incapacidades/lista/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaIncapacidadesExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaIncapacidadesError());
    throw data;
  }
};

export const fetchCreateIncapacidades = (dataIncapacidades) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(incapacidadesStart());
    const { _id } = getState().colaborador;
    const {
      archivo = {},
    ...rest
    } = dataIncapacidades;
    const formData = new FormData();
    formData.append('archivo', archivo[0]);   
    formData.append('colaborador', _id);
    formData.append(
      'dataIncapacidades',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.post('/incapacidades/crear', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createIncapacidadesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createIncapacidadesError());
    throw data;
  }
};

export const fetchUpdateIncapacidades = (dataIncapacidades) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(incapacidadesStart());
    const { _id } = getState().incapacidades.incapacidad;
    const {
      archivo = {},
    ...rest
    } = dataIncapacidades;
    const formData = new FormData();
    formData.append('archivo', archivo[0]); 
    formData.append(
      'dataIncapacidades',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.put(`/incapacidades/editar_incapacidad/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createIncapacidadesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createIncapacidadesError());
    throw data;
  }
};

export const deleteIncapacidad = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(incapacidadesStart());
    const { _id } = getState().incapacidades.incapacidad;
    await axios.delete(`/incapacidades/eliminar/${_id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createIncapacidadesExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createIncapacidadesError());
    throw data;
  }
};

