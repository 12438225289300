/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selecRuta } from '../../../store/empresasDuck';
import { NavMenu } from './styles';
import { useHistory } from 'react-router-dom';

const SideMenu = React.memo(() => {
  const dispatch = useDispatch();
  const { rutaEmpresa = {} } = useSelector((state) => state.empresas);
  const history = useHistory();
  const { empresa, sucursales, catalogos } = rutaEmpresa;
  return (
    <NavMenu>
      <ul style={{listStyle:'none'}}>
        <li>
          <span
            className={empresa ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                selecRuta({
                  empresa: true,
                })
              );
              history.replace('/');
            }}
          >
            Datos empresa
          </span>
        </li>
        <li>
          <span
            className={sucursales ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                selecRuta({
                  sucursales: true,
                })
              );
              history.replace('/sucursales');
            }}
          >
            <span>Sucursales</span>
          </span>
        </li>
        <li>
          <span
            className={catalogos ? 'activeEmpresa' : ''}
            onClick={() => {
              dispatch(
                selecRuta({
                  catalogos: true,
                })
              );
              history.replace('/catalogo');
            }}
          >
            Catalogos
          </span>
        </li>
      </ul>
    </NavMenu>
  );
});

export default SideMenu;
