import React, { useState } from 'react';
import { Col, Form, FormGroup, CustomInput } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {editarAction} from '../../store/sucursalDuck'
import { SubTitleMenu, InputUtils, SaveButton } from '../ui/styles/styles';
import CustomSelect from '../ui/CustomSelect';
import TableConfiguracion from './TableConfiguracion';
import seccionOptions from '../../constans/seccion';
import SweetAlert from '../ui/SweetAlet';
const Expediente = React.memo(() => {
  const dispatch = useDispatch()
  const { configuracion: configuracionData = [] } = useSelector(
    (state) => state.sucursal
  );
  const [configuracion, setConfiguracion] = useState(configuracionData);
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm();
  const transformValues = (configuracion) => {
    configuracion.obligatorio = !!configuracion.obligatorio;
    configuracion.expiracion = !!configuracion.expiracion;
    return configuracion;
  };
  const addToConfig = () => {
    const { configuracion } = getValues();
    const tranformedData = transformValues(configuracion);
    if (!configuracion.nombre || !configuracion.seccion) return;
    const cleanConfiguracion = {
      nombre: '',
      expiracion: false,
      obligatorio: false,
      seccion: '',
    };
    setConfiguracion((state) => [...state, tranformedData]);
    setValue('configuracion', cleanConfiguracion, { shouldDirty: true });
  };
const onSubmit=async(data)=>{
try {

 await dispatch(editarAction({configuracion}));
 await SweetAlert({ icon: 'success', title: 'Editado exitosamente' });
} catch (e) {
  SweetAlert({ icon: 'error', title: 'Error!', text: e });
}
}
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col sm={12}>
          <SubTitleMenu>
            Documentos relacionados al expediente laboral
          </SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} style={{marginTop:'10px'}}>
          <InputUtils
            type='text'
            name='configuracion.nombre'
            placeholder='Nombre nuevo campo'
            ref={register({required:false})}
          />
        </Col>
        <Col sm={2} style={{marginTop:'15px'}}>
          <CustomInput
            innerRef={register({required:false})}
            type='radio'
            label='Obligatorio'
            value={true}
            name='configuracion.obligatorio'
            id='obligatorio'
          />
        </Col>
        <Col sm={2} style={{marginTop:'15px'}}>
          <CustomInput
            id='configuracion.expiracion'
            innerRef={register({required:false})}
            type='radio'
            label='Expiración'
            value={true}
            name='configuracion.expiracion'
          />
        </Col>
        <Col sm={2} style={{marginTop:'10px'}}>
          <CustomSelect
            type='select'
            placeholder='Sección'
            name='configuracion.seccion'
            id='seccionConfiguracion'
            control={control}
            innerRef={register({required:false})}
            options={seccionOptions}
          />
        </Col>
        <Col sm={{  size: 2 }}>
          <SaveButton type='button' onClick={addToConfig}>
            + Agregar
          </SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row style={{height:'100%'}}>
        <TableConfiguracion
          listaConfiguracion={configuracion.length > 0 ? configuracion : []}
        />
      </FormGroup>
      <FormGroup row style={{marginTop:-55}}>
        <Col sm={{ offset: 9, size: 3 }}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  );
});

export default Expediente;
