export default {
  Salarios: ["salarioAnual", "salarioMensual", "salarioDiario", "salarioBaseCotizacion", "salarioPeriodo"],
  Dias: ["diasPeriodo", "diasTrabajados", "diasCalendario"],
  Impuestos: ["ISR", "ISRAnual", "IMSS"],
  Base: ["UMA", "salarioMinimo"],
  Percepciones: ["totalPercepciones", "totalPercepcionesAdicionales", "totalPercepcionesOrdinarias", "totalPercepcionesExtraordinarias", "totalPercepcionesPrevision"],
  Deducciones: ["totalDeducciones", "totalDescuentos", "totalDeduccionesExtraordinarias", "totalDeduccionesOrdinarias"],
  OtrosPagos: ["totalOtrosPagos", "totalSubsidio", "totalOtrosPagosAdicionales"],
  Contrato: ["diasAntiguedad", "diasVacaciones", "primaVacacional", "primaRiesgo"],
};
