import React from 'react';
import PropTypes from "prop-types";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import colors from "../../constans/colors";

const BotonEditar = ({clickHandler, icon}) =>{
    return(
        <button className="btn" onClick={clickHandler}>
            <FontAwesomeIcon
                color={colors.primary}
                icon={icon}
                style={{ fontSize: '1.0rem' }}/>
        </button>
    )
}
BotonEditar.propTypes={
    clickHandler:PropTypes.func.isRequired
}
export default BotonEditar;