import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Col, Form, FormGroup } from 'reactstrap';
import { EnterButton, ImageContainer, Input, LoginContainer, LogoContainer, RegisterStyled, WelcomeTitle } from './styled';
import axios from 'axios';
import SweetAlert from '../../components/ui/SweetAlet';
import { validate } from 'isemail';

const api = process.env.REACT_APP_API_URL;

const Recuperar = () => {
    const history = useHistory();
    const [data, setData] = useState({email: '', codigo: '', contra: ''});
    const [isEnviado, setEnviado] = useState(false);

    const solicitar = async (e) =>{
        e.preventDefault();
        if(data.email === '') return;
        if(!validate(data.email)){
            SweetAlert({title: 'Revisa la dirección de correo electrónico', icon: 'warning'});
            return;
        }

        const response = await axios.get(`${api}/auth/recuperar_credenciales?email=${data.email}`);
        if(response.data.success){
            setEnviado(true);
            SweetAlert({title: response.data.msg, icon: 'success', 
                    text: "Asegúrese de verificar su correo."})
        }
    }
    
    const cambiar = async (e) =>{
        e.preventDefault();
        if(data.email === '') return;
        if(data.codigo === '') return;
        if(data.contra === '') return;

        if(data.contra.length < 8) {
            SweetAlert({title: 'La contraseña debe tener al menos 8 caracteres.', icon: 'warning'});
            return;
        }

        const response = await axios.post(`${api}/auth/cambiar_contrasena`, data);
        if(response.data){
            SweetAlert({title: response.data.msg, icon: response.data.success === true ? 'success' : 'warning'})
            if(response.data.success === true){
                history.push('/');
            }
        }
    }

    return (
        <Card className='mx-auto' style={{ marginTop: '9rem' }}>
            <LoginContainer>
            <Form className='p-3'>
                <FormGroup row>
                    <Col sm={12}>
                        <LogoContainer />
                    </Col>
                    <Col sm={12}>
                        <WelcomeTitle sm={12}> Reestablecer </WelcomeTitle>
                    </Col>
                    <Col sm={12} className='pl-4'>
                        <RegisterStyled>
                            {'<'}
                            <Link replace={true} to='/'>
                            {' '}Regresar{' '}
                            </Link>
                        </RegisterStyled>
                    </Col>
                    <Col sm={12} className='pl-4'>
                        <Input
                            type='text'
                            name='usuario'
                            placeholder='Ingresa tu correo'
                            bsSize='sm'
                            className='border mt-1 mb-2'
                            value={data.email}
                            onChange={e=> setData({...data, email: e.target.value})}
                            disabled={isEnviado}
                        />
                    </Col>
                    <Col sm={12} className='pl-4' hidden={!isEnviado}>
                        <Input
                            type='text'
                            name='codigo'
                            placeholder='Código de verificación'
                            bsSize='sm'
                            className='border mt-1 mb-2'
                            value={data.codigo}
                            onChange={e=> setData({...data, codigo: e.target.value})}
                        />
                    </Col>
                    <Col sm={12} className='pl-4' hidden={!isEnviado}>
                        <Input
                            type='password'
                            name='contra'
                            placeholder='Nueva contraseña'
                            bsSize='sm'
                            className='border mt-1 mb-2'
                            value={data.contra}
                            onChange={e=> setData({...data, contra: e.target.value})}
                        />
                    </Col>
                    <Col sm={11} hidden={isEnviado} className='mt-4'>
                        <RegisterStyled>
                            <p style={{lineHeight: '2', textAlign: 'justify'}}>Si tu correo es correcto, se enviará un email con el código de verificación.</p>
                        </RegisterStyled>
                    </Col>
                    <Col sm={11} hidden={isEnviado}>
                        <EnterButton onClick={e => solicitar(e)}>SOLICITAR</EnterButton>
                    </Col>
                    <Col sm={11} hidden={!isEnviado}>
                        <EnterButton onClick={e => cambiar(e)}>CAMBIAR CONTRASEÑA</EnterButton>
                    </Col>
                </FormGroup>
            </Form>
            <ImageContainer />
            </LoginContainer>
        </Card>
    );
};

export default Recuperar;