import React, { useState } from "react";
import { Stage, Layer, Group, Rect, Text, Circle } from "react-konva";
import { scaleLinear, max } from "d3";
import Tooltip from "./Tooltip";

const DistribucionPlot = ({ title, data, width, height }) => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState("");
  const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });
  const xScale = scaleLinear()
    .domain([data[0].x0, data[data.length - 1].x1])
    .range([0, width - 70]);
  const yScale = scaleLinear()
    .domain([0, max(data.map((m) => m.y))])
    .range([0, height - 50]);

  const onHover = (text) => {
    setVisible(true);
    setText(text);
  };

  const onMouseMove = (e) => {
    let stage = e.currentTarget;
    stage = e.target.getStage();

    let mosuePos = stage.getPointerPosition();
    setMouseCoords({ ...mouseCoords, x: mosuePos.x, y: mosuePos.y });
  };

  const onMouseLeave = (e) => {
    setVisible(false);
    setText("");
  };

  const bars = (data) => {
    return data.map((d, i) => (
      <Group key={i} x={15} y={10}>
        <Rect
          x={xScale(d.x0)}
          y={height - 25 - yScale(d.y)}
          width={xScale(d.x1) - xScale(d.x0)}
          height={yScale(d.y)}
          fill="#FFFFFF"
          stroke="rgb(235, 236, 244)"
          strokeWidth={4}
          cornerRadius={5}
          onMouseEnter={() => onHover(d.y)}
          onMouseMove={onMouseMove}
          onMouseLeave={onMouseLeave}
        />
        {d.y !== 0 && (
          <>
            <Rect
              x={xScale(d.m) - 1}
              y={height - 20 - yScale(d.y)}
              width={2}
              height={12}
              fill="#2b388f"
              stroke="rgb(235, 236, 244)"
              strokeWidth={1}
              onMouseEnter={() => onHover(d.m.toLocaleString("en-US", { style: "currency", currency: "USD" }))}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            />
            <Circle
              x={xScale(d.m)}
              y={height - 23 - yScale(d.y)}
              radius={7}
              fill="#2b388f"
              stroke="rgb(235, 236, 244)"
              strokeWidth={1}
              onMouseEnter={() => onHover(d.m.toLocaleString("en-US", { style: "currency", currency: "USD" }))}
              onMouseMove={onMouseMove}
              onMouseLeave={onMouseLeave}
            />
          </>
        )}

        <Text text={`${parseInt(d.x0 / 1000)} k`} x={xScale(d.x0) - 10} y={height - 20} fill="rgb(146, 146, 146)" fontSize={12} />
      </Group>
    ));
  };

  return (
    <Stage width={width} height={height}>
      <Layer>
        <Group x={5} y={0} width={width} height={height}>
          <Text text={title} x={0} y={10} width={width} align="left" fill="rgb(146, 146, 146)" fontSize={18} fontVariant="bold" />
          {bars(data)}
          <Text text={`${parseInt(data[data.length - 1].x1 / 1000)} k`} x={xScale(data[data.length - 1].x1) - 10} y={height - 10} fill="rgb(146, 146, 146)" fontSize={12} />
        </Group>
        <Tooltip x={mouseCoords.x} y={mouseCoords.y} visible={visible} text={text} />
      </Layer>
    </Stage>
  );
};

export default DistribucionPlot;
