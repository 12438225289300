import { faArrowAltFromLeft, faArrowAltFromRight, faArrowAltLeft, faArrowAltRight, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Row from 'reactstrap/lib/Row';
import SweetAlert from 'sweetalert2';
import SemanaControl from '../../components/incidencias/semanaControl/SemanaControl';
import ReactTableStyle from '../../components/incidencias/styles';
import ComponenDescrip from '../../components/ui/ComponentDescrip';
import { DarkButton, LightMiniButton } from '../../components/ui/styles/styles';
import makeTableData from './makeTableData';

const api = process.env.REACT_APP_API_URL;

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateMyData(index, id, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if(id === 'name' || id === 'totalDobles' || id === 'totalTriples' || id === 'warnings')
        return <label className='labels'>{value}</label>
    else
        return <input type='number' step={1} min={0} value={value} onChange={e => e.target.value >= 0 || e.target.value === '' ? setValue(e.target.value) : value} onBlur={onBlur} style={{width: '60px'}} />
}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

// Be sure to pass our updateMyData and the skipPageReset option
function Table({ columns, data, updateMyData, skipPageReset }) {
    // For this example, we're using pagination to illustrate how to stop
    // the current page from resetting when our data changes
    // Otherwise, nothing is different here.
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            // use the skipPageReset option to disable page resetting temporarily
            autoResetPage: !skipPageReset,
            // updateMyData isn't part of the API, but
            // anything we put into these options will
            // automatically be available on the instance.
            // That way we can call this function from our
            // cell renderer!
            updateMyData,
        },
        useSortBy,
        usePagination
    )

    // Render the UI for your table
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    {/* Add a sort direction indicator */}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? (<FontAwesomeIcon
                                                    icon={faSortDown}
                                                />)
                                                : (<FontAwesomeIcon
                                                    icon={faSortUp}
                                                />)
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <LightMiniButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltFromRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltLeft} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => nextPage()} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltFromLeft} />
                </LightMiniButton>{' '}
                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Ir a la página:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

function HorasExtra() {
    const [date, setDate] = useState(moment());
    const [dobles, setDobles] = useState([]);
    const [triples, setTriples] = useState([]);

    const swal = (title, description, success) => {
        SweetAlert.fire({
            title: title,
            text: description,
            icon: success ? 'success': 'error',
            confirmButtonText: 'Aceptar'
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    {
                        Header: 'Nombre',
                        accessor: 'name',
                    },
                    {
                        Header: `Lunes ${moment(date).startOf('isoWeek').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Martes ${moment(date).startOf('isoWeek').add(1, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(1, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Miércoles ${moment(date).startOf('isoWeek').add(2, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(2, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Jueves ${moment(date).startOf('isoWeek').add(3, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(3, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Viernes ${moment(date).startOf('isoWeek').add(4, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(4, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Sábado ${moment(date).startOf('isoWeek').add(5, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(5, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Domingo ${moment(date).startOf('isoWeek').add(6, 'day').format('DD')}`,
                        accessor: `${moment(date).startOf('isoWeek').add(6, 'day').format('YYYY-MM-DD')}`,
                    },
                    {
                        Header: `Total Dobles`,
                        accessor: 'totalDobles',
                    },
                    {
                        Header: `Total Triples`,
                        accessor: 'totalTriples',
                    },
                    {
                        Header: '',
                        accessor: 'warnings'
                    }
                ],
            },
        ],
        [date]
    )

    const [listaEmpresas, setEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState({ nombre_empresa: '' });
    const [listaSucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState({ nombreSucursal: '' });
    const [listaDepartamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState({ nombreDepartamento: '' });
    const [listaColaboradores, setColaboradores] = useState([]);
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    };
    
    const [data, setData] = React.useState([])
    const [originalData] = React.useState(data)
    const [skipPageReset, setSkipPageReset] = React.useState(false)
    const [upd, setUpd] = React.useState(false)
    const [updRow, setUpdRow] = React.useState(0)

    const updateTotales = async (row, datos) =>{
        try {
            let { data } = await axios.post(`${api}/horas-extras/clasificar-horas`, datos[row], headers)
            if (data)
                updateMyData(row, 'totalDobles', data.respuesta[0] && data.respuesta[0].dobles ? data.respuesta[0].dobles : 0);
                updateMyData(row, 'totalTriples', data.respuesta[0] && data.respuesta[0].triples ? data.respuesta[0].triples : 0);
        } catch (error) {
            console.log(error);
        }
    }

    // We need to keep the table from resetting the pageIndex when we
    // Update data. So we can keep track of that flag with a ref.

    // When our cell renderer calls updateMyData, we'll use
    // the rowIndex, columnId and new value to update the
    // original data
    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        setSkipPageReset(true)
        setData(old =>
            old.map((row, index) => {
                columnId !== 'totalDobles' && columnId !== 'totalTriples' ? setUpd(true) : setUpd(false);
                if (index === rowIndex) {
                    setUpdRow(rowIndex);
                    return {
                        ...old[rowIndex],
                        [columnId]: value > 0 ? value : 0
                    }
                }
                return row
            })
        ) 
    }

    // After data chagnes, we turn the flag back off
    // so that if data actually changes when we're not
    // editing it, the page is reset
    React.useEffect(() => {
        setSkipPageReset(false);
        if(upd) {
            setUpd(false)
            updateTotales(updRow, data)
        }
    }, [data])

    // Let's add a data resetter/randomizer to help
    // illustrate that flow...
    const resetData = () => setData(originalData)

    const saveData = async (plantilla) =>{
        try {
            let { data } = await axios.post(`${api}/horas-extras/crear-masivo?dateStart=${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}&dateEnd=${moment(date).endOf('isoWeek').format('YYYY-MM-DD')}`, plantilla, headers)
            if(data)
                swal('Gurdado exitoso', 'Se han registrado las horas extras', true);
        } catch (error) {
            swal('Ocurrió un error', error, false);
        }
    }

    
    const fetchEmpresas = async () =>{
        let { data } = await axios.get(`${api}/empresa/obtener_empresas`, headers);
        setEmpresas(data.empresas)
    }

    const fetchSucursales = async (empresa) =>{
        const emp = listaEmpresas.filter((e) => e.nombre_empresa === empresa.nombre_empresa);
        if(emp.length === 1){ 
            let { data } = await axios.get(`${api}/sucursales/por_empresa?empresa=${emp[0]._id}`, headers);
            data.resultado.unshift({nombreSucursal: 'Todas', _id:'all'})
            setSucursales(data.resultado);
        }
    }

    const fetchDepartamentos = async (sucursal) =>{
        const suc = listaSucursales.filter((e) => e.nombreSucursal === sucursal.nombreSucursal);
        if(suc.length === 1){ 
            let { data } = await axios.get(`${api}/departamentos/por_sucursal?sucursal=${suc[0]._id}`, headers);
            data.resultado.unshift({ nombreDepartamento: 'Todos', _id: 'all' })
            setDepartamentos(data.resultado);
        }
    }

    const fetchColaboradores = async (pertenencia, type) =>{
        let grupo = [];
        if(type === 'departamento')
            grupo = listaDepartamentos.filter(d => d.nombreDepartamento === pertenencia.nombreDepartamento);
        else if (type === 'sucursal')
            grupo = listaSucursales.filter(d => d.nombreSucursal === pertenencia.nombreSucursal);
        else if (type === 'empresa')
            grupo = listaEmpresas.filter(d => d.nombre_empresa === pertenencia.nombre_empresa);
        if(grupo.length === 1){
            const { data } = await axios.get(`${api}/horas-extras/lista-masivo/${grupo[0]._id}?type=${type}&dateStart=${moment(date).startOf('isoWeek').format('YYYY-MM-DD')}&dateEnd=${moment(date).endOf('isoWeek').format('YYYY-MM-DD')}`, headers);
            setColaboradores(data.resultado);
            const cdata = makeTableData(data.resultado, date);
            setData(cdata)
        }
    }

    useEffect(()=>{
        fetchEmpresas();
    },[])

    useEffect(()=>{
        if(empresa)
        fetchSucursales(empresa);
    },[empresa])

    useEffect(()=>{
        if(sucursal && sucursal.nombreSucursal !== 'Todas'){
            fetchDepartamentos(sucursal);
        } else {
            setDepartamentos([])
            setDepartamento({})
            fetchColaboradores(empresa, 'empresa');
        }
    },[sucursal])

    useEffect(()=>{
        setData([])
        if (departamento && departamento.nombreDepartamento !== 'Todos' && sucursal.nombreSucursal !== 'Todas') {
            fetchColaboradores(departamento, 'departamento');
        } else if (sucursal && sucursal.nombreSucursal !== 'Todas') {
            fetchColaboradores(sucursal, 'sucursal')
        } else {
            fetchColaboradores(empresa, 'empresa')
        }
    },[departamento, date])



    return (
        <>
            <ComponenDescrip title={"Horas Extra"} description={"Registra las horas extra de tus colaboradores"} />
            <Row>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setEmpresa({ nombre_empresa: e.target.value })}
                        value={empresa.nombre_empresa}
                    >
                        <option value={""}>Empresa...</option>
                        {listaEmpresas.map((e) => (
                            <option value={e.nombre_empresa} key={e._id}>
                                {e.nombre_empresa}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setSucursal({ nombreSucursal: e.target.value })}
                        value={sucursal.nombreSucursal}
                    >
                        <option value={""}>Sucursal...</option>
                        {listaSucursales.map((s) => (
                            <option value={s.nombreSucursal} key={s._id}>
                                {s.nombreSucursal}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setDepartamento({ nombreDepartamento: e.target.value })}
                        value={departamento.nombreDepartamento}
                    >
                        <option value={""}>Departamento...</option>
                        {listaDepartamentos.map((d) => (
                            <option value={d.nombreDepartamento} key={d._id}>
                                {d.nombreDepartamento}
                            </option>
                        ))}
                    </Input>
                </Col>
                <SemanaControl width={4} date={date} setDate={setDate}  />
            </Row>
            {listaColaboradores.length >0 ?(
                <>
            <ReactTableStyle>
                {/* <button onClick={resetData}>Reset Data</button> */}
                <Table
                    className='table table-striped'
                    columns={columns}
                    data={data}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                />
            </ReactTableStyle>
            
            <Row>
                <Col sm={12}>
                    <Row>
                        <Col sm={{ offset: 10, size: 2}}>
                            <DarkButton onClick={() => saveData(data)}>Guardar</DarkButton>
                        </Col>
                    </Row>
                </Col>
            </Row>
            </>
            ) : ''}
        </>
    )
}

export default HorasExtra
