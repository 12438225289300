import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/pro-solid-svg-icons';
import './InputFile.css';
import colors from '../../constans/colors';
const InputFile = React.memo(
  ({
    fileDescription = 'Adjuntar',
    register = () => {},
    getFile = () => {},
    ...rest
  }) => {
    const [file, setFile] = useState([]);
    const handleChange = ({ target }) => {
      setFile(() => target.files);
    };
    useEffect(() => {
      getFile(file);
    }, [file, getFile]);
    return (
      <label 
        className='label' 
        htmlFor={rest.id ? rest.id : rest.name} 
        style={{width:'174px',height:'51px', border:'1px solid #2B388F', borderRadius:'4px'}}
      >
        <FontAwesomeIcon
          id='svg'
          color={colors.primary}
          icon={faCloudUploadAlt}
          style={{ fontSize: '1.5rem', color:'#2B388F' }}
        />

        <span className='title'>{fileDescription}</span>
        <input
          ref={register ? register : ''}
          type='file'
          {...rest}
          onChange={rest.onChange ? rest.onChange : handleChange}
        />
      </label>
    );
  }
);

export default InputFile;
