import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import DescuentosInfonavitView from './DescuentosViews/DescuentosInfonavitView';
import FormDescuentoInfonavit from './DescuentosViews/FormDescuentoInfonavit';

const DescuentosInfonativt = () => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/infonavit/agregar' component={FormDescuentoInfonavit} />
                <Route path='/' component={DescuentosInfonavitView} /> 
            </Switch>
        </HashRouter>
    );
};

export default DescuentosInfonativt;