import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchListaDepartamentos,
  eliminarDepartamentoAccion,
} from '../../store/departamentosDucks';
import { Tr, BackgroundImage } from '../ui/styles/styles';
import Icons from '../ui/Icons';
import SweetAlert from '../../components/ui/SweetAlet';

const TableBodyDepartamentos = React.memo(
  ({
    listaDepartamentos = [],
    history,
    setDepartamento,
    toggleModal,
    isEdit,
  }) => {
    const dispatch = useDispatch();
    const { _id = '' } = useSelector((state) => state.sucursal);
    const eliminarDepartamento = async (departamento) => {
      const { isConfirmed } = await SweetAlert({
        title: '¿Seguro que desea eliminar el departamento?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(eliminarDepartamentoAccion(departamento._id));
        await dispatch(fetchListaDepartamentos(_id, false));
        history.push('sucursal/departamentos');
      }
    };

    return (
      <>
        {listaDepartamentos.map(
          ({ nombreDepartamento }, index, departamento) => (
            <Tr
              key={nombreDepartamento + index}
            >
              <td className='span-label'>{nombreDepartamento}</td>
              <td
                className='middle'
                style={{
                  textAlign: 'center',
                  padding: 0,
                  verticalAlign: 'middle',
                }}
              >
                <Icons
                  pencil
                  onClick={(e) => {
                    setDepartamento(departamento[index], true);
                  }}
                />
              </td>
              <td
                className='middle'
                style={{
                  textAlign: 'center',
                  padding: 0,
                  verticalAlign: 'middle',
                }}
              >
                <Icons
                  onClick={(e) => {
                    eliminarDepartamento(departamento[index]);
                  }}
                />
              </td>
            </Tr>
          )
        )}
      </>
    );
  }
);

export default TableBodyDepartamentos;
