import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import {faUsers} from "@fortawesome/free-solid-svg-icons";

import ComponentDescrip from "../ui/ComponentDescrip";
import Search from "../ui/Search";
import ListaRoles from "./ListaRoles";
import {Link} from "react-router-dom";
import { SaveButton } from '../ui/styles/styles';
const Roles = ()=>{
    return(
        <div>
            <Row>
                <Col lg={12}>
                    <ComponentDescrip title={"Roles"} description={"En este apartado encontraras toda la información respecto a Roles"} icon={faUsers}/>

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Search placeholder="Buscar por correo, rol o empresa"/>

                </Col>
            </Row>
            <Row style={{ marginBottom: '2em'}}>
                <Col lg={{ size: 'auto' }}>
                    <Link to="/dashboard/empresa/agrega-rol">
                        <SaveButton style={{ marginTop: '2em'}} >+ Agregar</SaveButton>
                    </Link>

                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <ListaRoles/>
                </Col>
            </Row>
        </div>
    )
}
export default Roles;