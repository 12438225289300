import React, {useCallback} from 'react';
import SweetAlert from '../../ui/SweetAlet';
import { Tr } from '../VacacionesViews/styled';
import moment from 'moment';
import { eliminarExtension } from '../../../store/permisosLaboralesDuck';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';

const TableBodyExtensiones = React.memo(({extensionPermiso = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const elimnarExtension = useCallback((permiso, index) => {
        let numeroExt = extensionPermiso.length - 1;
        if (numeroExt === index) {
          dispatch(eliminarExtension(permiso))
          history.push('/')
        }else{
          SweetAlert({icon: 'error', title: 'No puedes borrar la extensión'});
        }
        
    }, [dispatch]);
    const now = moment().format('YYYY-MM-DD');
    return(
        <>
            {extensionPermiso.map((permiso, index) => (
              
          <Tr
            key={permiso._id + index}
            
          >
            <td >{moment(permiso.periodoInicialExt).format('DD/MM/YYYY')}</td>
            <td >{moment(permiso.periodoFinalExt).format('DD/MM/YYYY')}</td>
            <td >{permiso.causa}</td>
            <td >
            <FontAwesomeIcon
                onClick={()=> elimnarExtension(permiso,index)}
                color='#626262'
                icon={faTrashAlt}
                style={{ fontSize: '1.5rem' }}
              /></td>
          </Tr>
        ))}
        </>
    );
});

export default TableBodyExtensiones;