import React from 'react';
import {Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPencilAlt} from "@fortawesome/free-solid-svg-icons";

const PageTitle = ({icon, title}) =>{

    return(
        <Row>
            <Col lg={10} md={10} >
                <h2 className="page-title">{title}</h2>

            </Col>
            <Col lg={2} md={2} >
                <FontAwesomeIcon icon={icon}  className="m-3" />
            </Col>
        </Row>

    )
}
export default PageTitle