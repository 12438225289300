import React from 'react';
import {
  CheckBoxWrapper,
  CheckBox,
  CheckBoxLabel,
} from './styles/styledToogleSwitch';

const ToogleSwitch = React.memo((props) => {
  return (
    <CheckBoxWrapper>
      <CheckBox {...props} type='checkbox' />
      <CheckBoxLabel htmlFor={props.id} />
    </CheckBoxWrapper>
  );
});

export default ToogleSwitch;
