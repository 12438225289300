
export const vacacionesDefinition = (diferencia) => {
    let Valor;
    if (diferencia == 0) {
        Valor = 0;
    }
    if (diferencia == 1) {
        Valor = 6;
    }if (diferencia == 2) {
        Valor = 8;
    }if (diferencia == 3) {
        Valor = 10;
    }if (diferencia == 4) {
        Valor = 12;
    }if (diferencia >= 5 && diferencia <= 9) {
        Valor = 14;
    }if (diferencia >= 10 && diferencia <= 14) {
        Valor = 16;
    }if (diferencia >= 15 && diferencia <= 19) {
        Valor = 18;
    }if (diferencia >= 20 && diferencia <= 24) {
        Valor = 20;
    }if (diferencia >= 25 && diferencia <= 29) {
        Valor = 22;
    }if (diferencia >= 30 && diferencia <= 34) {
        Valor = 24;
    }if (diferencia >= 35 && diferencia <= 39) {
        Valor = 26;
    }
    return Valor;
};