import  currency from 'currency.js';

const cero = () => 0;

const monto = (...args) => args[3].monto || 0;

const vecesUMA = (veces) => (sd, SM, UMA, p) => Math.min(veces * UMA, p.monto);

const porcSd = (porc) => (sd, SM, UMA, p) => Math.min(sd * porc, p.monto);

const exentoHorasExtra = (sd, SM, UMA, percepcion) => {
    return percepcion.detalle.salarioDiario <= SM
    ? percepcion.detalle.totales.totalDobles
    : percepcion.detalle.montos.reduce((acc, v) => acc + Math.min(5 * UMA, v.montoDobles * 0.5))
}

const exentoFondoAhorro = (sd, SM, UMA, percepcion) => {
    const tope = 1.3 * UMA * 365
    const aportacionMax = 0.13 * percepcion.detalle.salarioDiario * 365
    return Math.min(tope, aportacionMax, percepcion.monto)
}

const exentoPrimaDominical = (sd, SM, UMA, percepcion) => Math.min(1 * UMA * percepcion.detalle.domingosTrabajados, percepcion.monto)

const mapear = (codigo) => ({
    '001': cero,                //Sueldo
    '002': vecesUMA(30),        //Aguinaldo
    '003': vecesUMA(15),        //PTU
    '004': cero,                //(*)Reembolso gastos medicos
    '005': exentoFondoAhorro,
    '006': monto,               //(*)Caja de ahorro
    '009': cero,                //Contribuciones pagadas por el patron
    '010': cero,                //Premio por puntualidad
    '011': monto,               //Prima seguro de vida
    '012': monto,               //Seguro gastos medicos mayores
    '013': cero,                //Cuotas sindicales pagadas por el patron
    '014': monto,               //Subsidio por incapacidad
    '015': porcSd(0.07),        //Becas para trabajadores / hijos
    '019': exentoHorasExtra,
    '020': exentoPrimaDominical,
    '021': vecesUMA(15),        //Prima vacacional
    '024': porcSd(0.07),        //(*)Seguro de retiro
    '026': monto,               //(*)Reembolso por funeral
    '027': monto,               //(*)Cuotas de Seguro Social pagadas por el patron
    '028': cero,                //Comisiones
    '029': porcSd(0.1),         //(*)Vales de despensa
    '030': porcSd(0.07),        //(*)Vales de restaurante
    '031': porcSd(0.07),        //(*)Vales de gasolina
    '032': porcSd(0.07),        //(*)Vales de ropa
    '033': porcSd(0.07),        //(*)Ayuda para renta
    '034': porcSd(0.07),        //(*)Ayuda para articulos escolares
    '035': porcSd(0.07),        //(*)Ayuda para anteojos
    '036': porcSd(0.07),        //(*)Ayuda para transporte
    '037': porcSd(0.07),        //(*)Ayuda para gastos de funeral
    '038': cero,                //Otros ingresos por salarios
    '047': monto,               //(*)Alimentacion
    '048': monto,               //(*)Habitacion
    '049': cero,                //Premios por asistencia
    '050': monto,               //(*)Viaticos
}[codigo] || cero)

const calcularExento = (sd, SM, UMA, percepcion) => mapear(percepcion.codigo)(sd, SM, UMA, percepcion);

const clasificarMonto = (sd, SM, UMA , percepcion) => {
    const montoExento = currency(calcularExento(sd, SM, UMA, percepcion)).value;
    const montoGravado = currency(percepcion.monto).subtract(montoExento).value;
    percepcion.detalle = { ...percepcion.detalle, montoGravado, montoExento }
    return percepcion
}

const totalizarMontos = percepciones => {
    const [total, gravado, exento] = percepciones.reduce((t, p) => [t[0].add(p.monto), t[1].add(p.detalle.montoGravado), t[2].add(p.detalle.montoExento)], [currency(0), currency(0), currency(0)]);
    return {montoTotal: total.value, montoGravado: gravado.value, montoExento: exento.value};
}

const calcularImpuesto = (tasas, gravado) => {
    const tasa = tasas.find(t => t.limiteInferior <= gravado && gravado <= (t.limiteSuperior || Infinity));
    return currency(tasa.cuotaFija).add((gravado - tasa.limiteInferior) * tasa.porcentaje).value;
}

export const obtenerISR = (sd, SM, UMA, percepciones, tasasPeriodo) => {
    percepciones = percepciones.map(p => clasificarMonto(sd, SM, UMA, p));
    const base = totalizarMontos(percepciones);
    const retencion = calcularImpuesto(tasasPeriodo, base.montoGravado);
    return {
        codigo: '002',
        clave: '002',
        descripcion: 'ISR',
        tipo: 'Ordinaria',
        monto: retencion,
        detalle: {
            totalPercepciones: base.montoTotal,
            totalGravado: base.montoGravado,
            totalExento: base.montoExento,
            baseISR: percepciones.map(p => ({codigo: p.codigo, monto: p.monto, gravado: p.detalle.montoGravado, exento: p.detalle.montoExento}))
        }
    }
}

export const ajustarISR = (isr, montoPago) => {
    isr.detalle.isrCausado = isr.monto;
    isr.detalle.isrPagado = montoPago;
    isr.monto = montoPago;
    return isr;
}

