import React from "react";
import { Route, useHistory } from "react-router-dom";
import DarAlta from "../../../pages/Nomina/DarAlta";
import EditarColaborador from "../../../pages/Nomina/EditarColaborador";
import ListadoColaboradores from "../../../pages/Nomina/ListadoColaboradores";
import NuevaSolicitud from "../../../components/nomina/NuevaSolicitud";
import ReclutamientoActivas from "../../../components/nomina/ReclutamientoActivas";
import ReclutamientoAsignadas from "../../../components/nomina/ReclutamientoAsignadas";
import ReclutamientoConcluidas from "../../../components/nomina/ReclutamientoConcluidas";
import JuridicoCitatorios from "../../../components/nomina/JuridicoCitatorios";
import JuridicoDemandas from "../../../components/nomina/JuridicoDemandas";
import JuridicoFiniquitos from "../../../components/nomina/JuridicoFiniquitos";
import ListadoColaboradoresProceso from "../../../pages/Nomina/ListadoColaboradoresProceso";
import DetalleCitatorio from "../../../components/citatorios/AgregarCitatorios";
import AgregarCitatorios from "../../../components/citatorios/AgregarCitatorios";
import EditarCitatorio from "../../../components/citatorios/EditarCitatorio";
import CerrarCitatorio from "../../../components/citatorios/CerrarCitatorio";
import BajaColaboradores from "../../../pages/Nomina/BajaColaboradores";
import BajaProceso from "../../../pages/Nomina/BajaProceso";
import NuevaDemanda from "../../../components/nomina/Demandas/NuevaDemanda";
import DetalleDemanda from "../../../components/nomina/Demandas/DetalleDemanda";
import Calendario from "../../../pages/Calendario/Calendario";
import HashRouteNomina from "../Nomina/HashRouteNomina";
import MenuEmpresas from "../../../pages/MenuNomina/MenuEmpresas"
import ConciliacionSat from '../../../pages/ConciliacionSat/ConciliacionSat';
import TimbrarEmpresa from "../../../components/nomina/Timbrar";
import HorasExtra from "../../../pages/HorasExtra/HorasExtra";
import Faltas from "../../../pages/Faltas/Faltas";
import Ajustes from "../../../pages/Ajustes/Ajustes";
import Nomina from "../../../components/nomina/Nomina";
import PrenominaEmpresa from "../../../components/nomina/TimbrarEmpresa";
import CerrarDemanda from "../../../components/nomina/Demandas/CerrarDemanda";
import AgregarFiniquito from "../../../components/nomina/Finiquitos/AgregarFiniquito";
import EditarFiniquito from "../../../components/nomina/Finiquitos/EditarFiniquito";
import MenuExtraordinaria from "../../../pages/MenuExtraordinaria/MenuExtraordinaria";
import ExtraordinariaPtu from "../../../pages/Extraordinaria/ExtraordinariaPtu";
import idse from "../../../components/imss/Idse";
import conciliacion from "../../../components/imss/Conciliacion";
import variabilidad from "../../../components/imss/Variabilidad";



const NominaRoutes = ({ path }) => {

  const history = useHistory()

  return (
    <>
      <Route path={`${path}/lista-colaboradores`} component={ListadoColaboradores} />
      <Route path={`${path}/proceso`} component={ListadoColaboradoresProceso} />
      <Route path={`${path}/bajas`} component={BajaColaboradores} />
      <Route path={`${path}/altas`} component={DarAlta} />
      <Route path={`${path}/bajas-proceso`} component={BajaProceso} />
      <Route path={`${path}/editar-colaborador`} component={EditarColaborador} />
      <Route path={`${path}/nueva-solicitud`} component={NuevaSolicitud} />
      <Route path={`${path}/reclutamiento-activas`} component={ReclutamientoActivas} />
      <Route path={`${path}/reclutamiento-asignadas`} component={ReclutamientoAsignadas} />
      <Route path={`${path}/reclutamiento-concluidas`} component={ReclutamientoConcluidas} />
      <Route path={`${path}/juridico-citatorios`} component={JuridicoCitatorios} />
      <Route path={`${path}/juridico-demandas`} component={JuridicoDemandas} />
      <Route path={`${path}/juridico-finiquitos`} component={JuridicoFiniquitos} />
      <Route path={`${path}/citatorio/:idCitatorio`} component={DetalleCitatorio} />
      <Route path={`${path}/agregar-citatorio`} component={AgregarCitatorios} />
      <Route path={`${path}/editar-citatorio`} component={EditarCitatorio} />
      <Route path={`${path}/cerrar-citatorio`} component={CerrarCitatorio} />
      <Route path={`${path}/nueva-demanda`} component={NuevaDemanda} />
      <Route path={`${path}/nuevo-finiquito`} component={AgregarFiniquito} />
      <Route path={`${path}/detalle-finiquito`} component={EditarFiniquito} />
      <Route path={`${path}/detalle-demanda`} component={DetalleDemanda}/>
      <Route path={`${path}/cerrar-demanda`} component={CerrarDemanda}/>
      {/* <Route path={`${path}/configuracion-nomina`} component={HashRouteNomina} /> */}
      <Route path={`${path}/calendario`} component={Calendario} />
      <Route path={`${path}/conciliacion-sat`} component={ConciliacionSat} />
      <Route path={`${path}/timbrar`} component={() => <MenuEmpresas title="Timbrado" />} />
      <Route path={`${path}/timbrar-empresa/:idEmpresa`} component={TimbrarEmpresa} />
      <Route path={`${path}/horas-extra`} component={HorasExtra} />
      <Route path={`${path}/faltas-masivo`} component={Faltas} />
      <Route path={`${path}/ajustes-masivo`} component={Ajustes} />
      <Route path={`${path}/nomina/:idEmpresa`} component={Nomina} />
      <Route path={`${path}/prenomina/:idEmpresa/:status?`} component={PrenominaEmpresa} />
      <Route path={`${path}/configuracion-nomina`} component={() => <MenuEmpresas title="Nomina" />} />
      <Route path={`${path}/extraordinaria`} component={() => <MenuExtraordinaria title="Extraordinaria" />} />
      <Route path={`${path}/configuracion-extraordinaria/:idEmpresa/ptu`} component={() => <ExtraordinariaPtu title="Extraordinaria" />} />
      <Route path={`${path}/imss-idse`} component={idse} />
      <Route path={`${path}/imss-conciliacion`} component={conciliacion} />
      <Route path={`${path}/imss-variabilidad`} component={variabilidad} />
    </>
  );
};



export default NominaRoutes;
