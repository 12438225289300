import styled from 'styled-components';

const ReactTableStyle = styled.div`
    display: block;
    max-width: 100%;
    padding: 1rem;
    
    .cantidad-horas, .cantidad-horas-header{
        border-left: 1px solid #c1c1c1;
        text-align: center;
    }
    .cantidad-horas-razon{
        text-align: center;
        border-right: 1px solid #c1c1c1 !important;
        width: 21%;
    }
    .cantidad-horas-header{
        border: 1px solid #c1c1c1 !important;
    }
    .ajuste-xs, .cantidad-horas{
        width: 13%;
    }
    .ajuste-xxs{
        width: 7%;
    }

    table {
        color: #212529;
        border-spacing: 0;
        width: 100%;
        /* border: 1px solid black; */

        tr {
        :nth-child(even){
            background-color: #ebecf4;
            input {
                background-color: #ebecf4;
            }
        }

        :last-child {
            td {
            border-bottom: 0;
            }
            margin-bottom: 20px;
        }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid #c1c1c1;
            /* border-right: 1px solid #c1c1c1; */
        :last-child {
            border-right: 0;
        }

        :first-child{
            input {
                font-size: 1rem;
                padding: 0;
                margin: 0;
                border: 0;
                width: 300px;
            }
        }

        input {
            font-size: 1rem;
            padding: 0;
            margin: 0;
            border: 0;
            }
        }
    }

    span {
        margin-left: 5px;
    }

    .labels{
        margin-bottom: 0;
    }

    .pagination {
        padding: 0.5rem;
    }
`

export default ReactTableStyle