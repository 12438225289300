import React, {useCallback, useEffect} from 'react';
import {Row, Table} from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux'
import {getRolesAction} from "../../store/rolesDucks";
import SweetAlert from "../ui/SweetAlet";
import Spiner from "../ui/Spiner";
import Rol from "./Rol"
import './rolesList.css';

const ListaRoles = () =>{
    const dispatch = useDispatch();
    const {loading, roles} = useSelector( state => state.roles)
    const getRoles = useCallback(async ()=>{
        try {
            await dispatch(getRolesAction())
        }catch (error) {
            await SweetAlert({ icon: 'error', title: 'Error!', text: error });
        }
    }, [dispatch]);
    useEffect(()=>{
        getRoles()
    }, [getRoles])
    if(loading)return <Spiner/>
    if(!roles || roles.length=== 0) return <h2>Sin roles aún registrados</h2>
    return(
        <div className='roles-list'>
            <Row>
                <Table striped={true}>
                    <thead>
                    <tr className='roles-list-tr'>
                        <th>Rol</th>
                        <th>Usuarios</th>
                        <th>Permisos</th>
                        <th>Eliminar</th>
                    </tr>
                    </thead>
                    <tbody className='roles-list-tr-table'>
                    {roles.map((rol,index) => <Rol key={index} rol={rol}/>)}
                    </tbody>
                </Table>
            </Row>
        </div>
    )
}

export default ListaRoles;