import React from 'react';
import { Stage, Layer, Rect, Text, Group } from 'react-konva';
import { scaleBand } from 'd3';
import _ from 'lodash';

const bandFactory = (domain, h) => scaleBand().domain(domain).range([h, 0]).paddingInner(0.1).round(true);

const agrupar = (coll, prop) => _.groupBy(coll, prop);

const TablePlot = ({ colors, width, height, filtro, colaboradores, categorias }) => {
    const yScale = bandFactory(categorias, height);
    const grupos = agrupar(colaboradores, filtro);

    return (
        <Stage width={width} height={height}>
            <Layer>
                <Group x={10} y={15} >
                    {
                        categorias.map((cat, i) => (
                            <Text
                                key={i}
                                x={0}
                                y={i * yScale.step()}
                                text={cat}
                                fontSize={18}
                                fill="#8E8883"
                                align='left'
                            />
                        ))
                    }
                </Group>
                <Group x={width - 60} y={15} >
                    {
                        categorias.map((cat, i) => (
                            <Text
                                key={i}
                                x={0}
                                y={i * yScale.step()}
                                text={grupos[cat] ? grupos[cat].length : 0}
                                fontSize={18}
                                width={50}
                                fill="#8E8883"
                                align='right'
                            />
                        ))
                    }
                </Group>
                <Group x={130} y={15}>
                    {
                        categorias.map((cat, i) => (
                            <Rect
                                key={i}
                                x={0}
                                y={i * yScale.step()}
                                fill={colors.primary[i]}
                                width={15}
                                height={15}
                                cornerRadius={3}
                            />
                        ))
                    }
                </Group>
            </Layer>
        </Stage>
    )
}

export default TablePlot;
