import React,{useState,useCallback} from 'react';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import ButtonDowload from '../colaboradores/Forms/FormExpedienteDigital/ButtonDownload';
import {
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../constans/colors';
import CitatorioModalEdit from './CitatorioModalEdit';


const TableBody = React.memo(({ extensiones = [],deleteExtension,isEdit}) => {

	const [isOpen, setIsOpen] = useState(false);
  	const [dataModal, setDataModal] = useState({});
  	const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);

	const getDocumento = async (data) => {
		if (!isEdit) {
			setDataModal(data);
			toggleModal();
		}
	};
	
  return (
    <>
		<CitatorioModalEdit isOpen={isOpen} data={dataModal} setIsOpen={setIsOpen} />
      {extensiones.map((info,index) => (
        <Tr
          key={info._id + index}
        >
        	<td 
		  		style={{ paddingTop:'22px' }}
				onClick={() => getDocumento(info)}
			>{moment(info.fecha).format('DD/MM/YYYY')}</td>
          	<td 
		 		style={{paddingTop:'22px' }}
				onClick={() => getDocumento(info)}
			>{info.hora}</td>
			<td 
		 		style={{ paddingTop:'22px' }}
				onClick={() => getDocumento(info)}
			>{info.responsable}</td>
			<td 
		 		style={{ paddingTop:'22px',paddingLeft:50 }}
				onClick={() => getDocumento(info)}
			>{info.numeroCitatorio}</td>
		  <td >{
			info["documento"] ?
			<ButtonDowload
            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${info.documento}`}
          />:null}</td>
		  <td style={{ paddingTop:'22px' }}>
		  	<a href='#' 
              onClick={(e) => deleteExtension(extensiones,info,e,index)} 
              style={{textDecoration:'none',color:colors.tashColor}}
              >
              <FontAwesomeIcon 
                color={colors.tashColor}
                icon={faTrashAlt} 
              />
            </a>
		  </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;
