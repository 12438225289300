import axios from "./axios";
import { saveAs } from 'file-saver';

const getXls= async({body={},reporte})=>{
const {data}=await axios.post(`/reportes/exportar/${reporte}`,body,{
    headers: {
        token: localStorage.getItem('pv2token'),
      },
     responseType: "arraybuffer"
});

const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
return saveAs(blob,`${reporte}.xlsx`);

}

export default getXls;