import React from 'react';
import { CustomInput, FormGroup, Label } from 'reactstrap';

const PermisoRol = ({nombre, id, permiso='', register, disabled=false, isNew=false}) => {

    if(isNew)
        return(
            <FormGroup row>
                <Label for={id} md={3}>
                    {nombre}
                </Label>
                <Label check md={3}>
                    <CustomInput type="radio" id={`${id}1`} name={id} value='write' innerRef={register()} />
                </Label>
                <Label check md={3}>
                    <CustomInput type="radio" id={`${id}2`} name={id} value='read' innerRef={register()} />
                </Label>
                <Label check md={3}>
                    <CustomInput type="radio" id={`${id}3`} name={id} value='none' defaultChecked='true' innerRef={register()} />
                </Label>
            </FormGroup>
        );
    else
        return (
            <FormGroup row>
                <Label for={id} sm={3}>
                {nombre}
                </Label>
                <Label check sm={3}>
                <CustomInput
                    type='radio'
                    id={`${id}1`}
                    name={id}
                    value='write'
                    defaultChecked={permiso === 'write'}
                    innerRef={register()}
                    {...disabled}
                />
                </Label>
                <Label check sm={3}>
                <CustomInput
                    type='radio'
                    id={`${id}2`}
                    name={id}
                    value='read'
                    defaultChecked={permiso === 'read'}
                    innerRef={register()}
                    {...disabled}
                />
                </Label>
                <Label check sm={3}>
                <CustomInput
                    type='radio'
                    id={`${id}3`}
                    name={id}
                    value='none'
                    defaultChecked={permiso === 'none'}                    
                    innerRef={register()}
                    {...disabled}
                />
                </Label>
            </FormGroup>
        );
};

export default PermisoRol;