import React, {useState} from 'react';
import './Search.css'

const Search = ({ onChange, label='', placeholder='', type='text'}) => {

  // const [value, setValue] = useState('')
  return (
    <div>
      {/* <label htmlFor='search'>{label}</label> */}
      <input
        style={{
          border: '1px #979797 solid',
          fontWeight: '600'
        }}
        onChange={e=> onChange(e.target.value)}
        type={type}
        className='search-control-with-border'
        // id='inputPassword2'
        placeholder={placeholder}
      />
    </div>
  );
};

export default Search;
