import React, { useEffect, useState } from 'react';
import './styles.css';
import {
  Container,
  ImageContainer,
  Button,
  Input,
  LogoContainer,
  ReSendTitle,
  Title,
  TermsLabel,
} from '../../pages/RegistroUsuario/styledRegistro';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schemaRegister, schemaRegister2 } from '../../constans/yupSchemas';
import { Col, FormGroup, Form, Card } from 'reactstrap';
import { createUser, authorizedToken, secondForm } from '../../store/userDuck';
import { Link, useHistory } from 'react-router-dom';
import SweetAlert from '../../components/ui/SweetAlet';
import Spiner from '../../components/ui/Spiner';
import { LettersContainer } from '../styled';

const RegistroUsuarioB = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(schemaRegister2),
  });
  console.log(errors);
  const { loading } = useSelector((state) => state.user);
  const onSubmit = async (data) => {
    try {
      dispatch(secondForm(data));
      await dispatch(createUser(data));
      history.push('/dashboard');
    } catch (err) {
      console.log(err);
    }
  };
  if (loading) {
    return <Spiner className='mx-auto' />;
  }

  return (
    <Card className='mx-auto' style={{ marginTop: '3rem' }}>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='p-3'>
          <FormGroup row>
            <Col sm={12}>
              {
                process.env.REACT_APP_USE === 'sign4life' ? <LettersContainer /> : (
                  <LogoContainer />
                )
              }
            </Col>
            <Col sm={12}>
              <Title sm={12}> Registro </Title>
            </Col>

            <Col sm={12} className='pl-4'>
              <Input
                type='text'
                name='correo_electronico'
                placeholder='Correo Electrónico'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-2'>
              <Input
                type='text'
                name='correo_recuperacion'
                placeholder='Correo de Recuperación'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-2'>
              <Input
                type='password'
                name='contrasena'
                placeholder='Contraseña'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-2'>
              <Input
                type='password'
                name='contrasena'
                placeholder='Confirmación de Contraseña'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-2'>
              <Input
                type='text'
                name='numero_verificacion'
                placeholder='Número de Verificación'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-2'>
              <ReSendTitle sm={6}>Reenviar correo de verificación</ReSendTitle>
            </Col>
            <Col sm={12} className='pl-4'>
              <div class='form-check'>
                <Col sm={1}>
                  <Input
                    name='isChecked'
                    className='form-check-input'
                    type='checkbox'
                    value={true}
                    id='terms'
                    ref={register()}
                  />
                </Col>
                <Col sm={11} className='center ml-4' style={{}}>
                  <TermsLabel>
                    Acepto los términos y condiciones y la política de
                    privacidad.
                  </TermsLabel>
                </Col>
                {false ? (
                  <span>
                    Debe de aceptar los terminos y condiciones para continuar...
                  </span>
                ) : null}
              </div>
            </Col>
            <Col sm={12} className='mb-2'>
              <Button type='submit'>ACEPTAR</Button>
            </Col>
          </FormGroup>
        </Form>
        <ImageContainer />
      </Container>
    </Card>
  );
};
export default RegistroUsuarioB;
