import React, { useCallback, useState, useEffect } from "react"
import { Col, Row, Button, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import useDropdown from "../../hooks/useDropdown";
import ComponentDescrip from "../ui/ComponentDescrip";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { usuarioSchema } from "../../constans/yupSchemas";
import SweetAlert from "../ui/SweetAlet";
import { agregarUsuarioAction } from "../../store/usuariosDuck";
import CustomSelect from "../ui/CustomSelect";
import { tipoUsuarios } from "../../constans/tipoUsuarios";
import { createColaborador } from "../../store/colaboradorDucks";
import { SaveButton } from "../ui/styles/styles";

const AddUser = () => {
    const { register, handleSubmit, errors, control } = useForm({
        resolver: yupResolver(usuarioSchema)
    });
    const [err, setErr] = useState({});
    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);

    const history = useHistory();
    const dispatch = useDispatch();

    const onSubmit = useCallback(
        async (data) => {
            try {
                await dispatch(agregarUsuarioAction(data))
                SweetAlert({ icon: 'success', title: 'Registrado exitosamente' });
                history.push('/dashboard/empresa/usuarios')
            } catch (error) {
                SweetAlert({ icon: 'error', title: 'Error!', text: error });

            }
        },
        [dispatch]
    );

    return (
        <div>
            <Row style={{ marginBottom: '2em' }}>
                <ComponentDescrip icon={faUsers} title="Agregar usuarios" description="En este apartado puedes agregar nuevos usuarios" />
            </Row>
            <Row>
                <Col lg={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup row>
                            <Label>Correo*</Label>
                            <Input type="text" name="usuario" placeholder="Correo del usuario (a este correo le llegará el acceso)" innerRef={register()} />
                            {err.usuario && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El correo es requerido
                                </span>
                            )}
                        </FormGroup>
                        <FormGroup row>
                            <Label>Contraseña*</Label>
                            <Input type="password" name="contrasena" placeholder="Asigna una contraseña" innerRef={register()} />
                            {err.usuario && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    La contrasena es requerida
                                </span>
                            )}
                        </FormGroup>
                        <FormGroup row sm={2}>
                            <Label>Tipo usuario*</Label>
                            <CustomSelect
                                defultValue={tipoUsuarios[0].label}
                                type='select'
                                name='tipoUsuario'
                                id='tipoUsuario'
                                control={control}
                                formText=''
                                innerRef={register()}
                                options={tipoUsuarios}
                            />
                            {err.tipoUsuario && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El tipo de usuario es requerido
                                </span>
                            )}
                        </FormGroup>
                        <FormGroup row>
                            <SaveButton type='submit' style={{ marginTop: '2em' }} >Agregar</SaveButton>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default AddUser