import React, { useEffect, useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authorizedToken } from '../../store/userDuck';
import jwt_decode from "jwt-decode";

const ProtectedRoutes = ({ component: Component, auth, ...rest }) => {
  const { isLogged } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isAuthenticated = useCallback(async () => {
    if (isLogged) return;
    const token = localStorage.getItem('pv2token');
    try {
      await dispatch(authorizedToken(token));
      return token;
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, isLogged]);

  const hasSession = () =>{
    const token = localStorage.getItem('pv2token');
    if(! token) return false;
    const { exp } = jwt_decode(token)
    const expirationTime = (exp * 1000) - 60000
    if (Date.now() <= expirationTime) return true;
    else return false;
}

  useEffect(() => {
    !!isLogged && isAuthenticated();
  }, [isAuthenticated, isLogged]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged && hasSession() ? <Component {...props} /> : <Redirect to='/' />
      }
    />
  );
};
export default ProtectedRoutes;
