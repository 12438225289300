import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import InputFile from './InputFileMulti';

const DivContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
`;
const DivFilesList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 10px;
`;

const LabelFileContainer = styled.div`
  background-color: #e4e4e4;
  display: grid;
  grid-template-columns: 10fr 2fr;
  max-height: 3rem;
  padding: 0.5rem;
  padding-right: 0px;
`;

const HyperLinkTag = styled.a`
  color: #15c;
`;

const ButtonDelete = styled.div`
  color: #f92e28;
  margin-left: 2rem;
  cursor: pointer;
`;

const InputMultiFile = ({
  getFiles = () => {},
  setValue = () => {},
  name = 'files',
}) => {
  const [arrayFiles, setArrayFiles] = useState([]);
  const handleChange = ({ target }) => {
    const files = target.files;
    for (const file in files) {
      if (files.hasOwnProperty(file)) {
        setArrayFiles((filesSTate) => [...filesSTate, files[file]]);
      }
    }
    console.log(() => arrayFiles);
    getFiles(arrayFiles);
    setValue(name, arrayFiles);
  };

  const deleteFile = (index) => {
    arrayFiles.splice(index, 1);
    const newArray = [...arrayFiles];
    setArrayFiles((state) => [...newArray]);

    setValue(name, arrayFiles);
  };
  useEffect(() => {
    getFiles(arrayFiles);
  }, [arrayFiles, getFiles]);
  return (
    <DivContainer>
      <InputFile onChange={handleChange} multiple />
      <DivFilesList>
        {arrayFiles.length > 0
          ? arrayFiles.map(({ name }, index) => {
              return (
                <LabelFileContainer key={name + index}>
                  <HyperLinkTag>{name.substring(0, 15)}...</HyperLinkTag>
                  <ButtonDelete onClick={() => deleteFile(index)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </ButtonDelete>
                </LabelFileContainer>
              );
            })
          : null}
      </DivFilesList>
    </DivContainer>
  );
};

export const ViewMultiFile = ({
  getFiles = () => {},
  setValue = () => {},
  name = 'files',
  archivos=[]
}) => {
  const [arrayFiles, setArrayFiles] = useState(archivos);

  const deleteFile = (index) => {
    arrayFiles.splice(index, 1);
    const newArray = [...arrayFiles];
    setArrayFiles((state) => [...newArray]);

    setValue(name, arrayFiles);
  };
  return (
    <DivContainer>
      <DivFilesList>
        {arrayFiles.length > 0
          ? arrayFiles.map(( index) => {
              return (
                <LabelFileContainer key={index}>
                  <HyperLinkTag>{index}</HyperLinkTag>
                  <ButtonDelete onClick={() => deleteFile(index)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </ButtonDelete>
                </LabelFileContainer>
              );
            })
          : null}
      </DivFilesList>
    </DivContainer>
  );
};

export default InputMultiFile;
