import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { sucursalAction,fetchNumeroColaboradores } from '../../store/sucursalDuck';
import HashRouteSucursal from '../../routes/ProtectedRoutes/Sucursal/HashRouteSucursal';
import { GirdContainerRoutes } from '../empresa/styles/styles';
import DetailCardSucursalCard from '../ui/DetailSucursalCard';
import Arrows from '../ui/Arrows';
import { useHistory } from 'react-router-dom';
const Sucursal = React.memo(({history}) => {
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.empresas);
  
  useEffect(() => {
    (async () => {
      try {
        await dispatch(sucursalAction(_id));
        await dispatch(fetchNumeroColaboradores());
      } catch (error) {}
    })();
  }, [dispatch, _id]);
  const historyMain = useHistory();
  return (
    <>
      <Row>
        <Col sm={12} className='breadscrum'>
          <Arrows
            onClick={() => {
              historyMain.push('detalle-empresa#/sucursales');
            }}
          />
          <span >Sucursales</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <DetailCardSucursalCard
    
          />
        </Col>
      </Row>
      <Row sm={12}>
        <GirdContainerRoutes>
          <HashRouteSucursal history={history} />
        </GirdContainerRoutes>
      </Row>
    </>
  );
});

export default Sucursal