import React from "react";
import { Table, Row, Col, Button } from "reactstrap";
import { StyleInputs } from "./styles/styles";
import { H3 } from "./styles/PrestacionesStyles";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import sweet from "sweetalert2";

export default ({ data = [], onAdd, text, onDelete, onDetail, onEdit }) => {
  const onDeletePrestacion = async (item) => {
    let result = await sweet.fire({
      title: "Borrar ",
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      onDelete(item);
    }
  };
  return (
    <>
      <Row>
        <StyleInputs>
          <Col>
            <H3>Paquete {text}</H3>
          </Col>
          <Col className="text-right">
            <Button color="primary" onClick={onAdd}>
              + Agregar
            </Button>
          </Col>
        </StyleInputs>
      </Row>
      <Row>
        <Table striped>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>No. Prestaciones</th>
              <th>Fecha Creación</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <th scope="row">{item.nombre}</th>
                <td>{item.prestaciones.length}</td>
                <td>{moment(item.fechaCreacion).format("DD-MM-YYYY")}</td>
                <td>
                  {/* <Button color="link" size="sm" onClick={() => onDetail(item)}>
                    Ver detalle
                  </Button>{" "} */}
                  <FontAwesomeIcon icon={faEye} className="mx-1" cursor={"pointer"} onClick={() => onEdit(item)} />
                  <FontAwesomeIcon icon={faTrash} className="mx-1" cursor={"pointer"} onClick={() => onDeletePrestacion(item)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </>
  );
};
