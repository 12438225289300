import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchActa } from '../../../store/actasDuck';
import { Tr } from '../VacacionesViews/styled';

const TableBody = React.memo(({ lista = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const editAction = async data => {
        await dispatch(fetchActa(data));
        history.push('/actas/agregar');
    }

    return (
        <React.Fragment>
            {lista.map(d => (
                <Tr className='table-non' key={d._id} style={{ cursor: 'pointer' }} onClick={e => editAction(d._id)}>
                    <td>
                        <div>
                            {d.numeroActa}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.tipoActa}
                        </div>
                    </td>
                    <td>
                        <div>
                            {moment(d.fechaLevantamiento).utc().format('DD/MM/YYYY')}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.relator}
                        </div>
                    </td>
                </Tr>
            ))}
        </ React.Fragment>
    );
});

export default TableBody;