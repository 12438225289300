import React from 'react';
import ComponentDescrip from "../ui/ComponentDescrip";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col} from "reactstrap";
const ReclutamientoAsignadas = () =>{
    const title= 'Solicitudes Asignadas ';
    const icon= faUsers;
    const  description= 'Estas es la lista de las solicitudes asignadas '
    return (
        <Container>
            <Row>
                <Col>
                    <ComponentDescrip title={title} icon={icon} description={description}/>

                </Col>
            </Row>
        </Container>
    )
}
export default ReclutamientoAsignadas;