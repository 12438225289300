import React from 'react';
import { Table } from 'reactstrap';
import './TableCommon.css';
import styled from 'styled-components';
import colors from '../../constans/colors';

const TR = styled.tr`
  color: ${colors.tashColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 29px;
  text-align: justify;
  letter-spacing: 0.322917px;

  color: #404041;
`;
const SpanFloat = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  /* identical to box height */
  text-align: justify;
  letter-spacing: 0.27125px;
  color: #464646;
`;

const TableCommon = React.memo(
  ({ children, striped = false, keysAceepted = [], extraTag = null }) => {
    return (
      <div className='table-container' style={{marginTop:'70px'}}>
        <Table borderless hover striped={striped} responsive autoCorrect='true'>
          <thead>
            <TR>
              {keysAceepted.map((element, index) => {
                return (
                  <th key={element + index}>
                    <span> {element}</span>

                    {extraTag ? (
                      extraTag.key === element ? (
                        <>
                          <SpanFloat>${extraTag.value}</SpanFloat>
                        </>
                      ) : (
                        <span></span>
                      )
                    ) : null}
                  </th>
                );
              })}
            </TR>
          </thead>
          <tbody>{children}</tbody>
        </Table>
        {/*   <TablePagination /> */}
      </div>
    );
  }
);

export default TableCommon;
