import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDescuentos } from '../../../store/descuentosDuck';
import { useHistory } from 'react-router-dom';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import { TableContainer } from '../VacacionesViews/styled';
import TableCommon from '../VacacionesViews/TableVacaciones';
import TableBody from './TableBodyInfonavit';
import classnames from 'classnames';
import './tabs.css';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const DescuentosInfonavitView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { lista = [] } = useSelector((state) => state.descuentos);
    const [activeTab, setActiveTab] = useState('1');
    const { permisos = {} } = useSelector((state) => state.user);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchDescuentos(activeTab === '1', true));
            } catch (error) { }
        })();
    }, [dispatch, activeTab]);

    return (
        <React.Fragment>
            <FormGroup row style={{ marginLeft: 16}}>
                <h5>Crédito Infonavit</h5>
            </FormGroup>
            <Row style={{ marginLeft: 16, marginTop: 20 }}>
                <Col sm={12}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                Activos
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                Inactivos
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Col>
            </Row>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableCommon
                                    keysAceepted={['Fecha', 'Tipo descuento', 'No. Crédito', 'Cantidad', 'Unidad', '']}
                                    striped
                                >
                                    <TableBody lista={lista} />

                                </TableCommon>
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableCommon
                                    keysAceepted={['Fecha', 'Tipo descuento', 'No. Crédito', 'Cantidad', 'Unidad', '']}
                                    striped
                                >
                                    <TableBody lista={lista} />

                                </TableCommon>
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>

            <Row style={{ marginLeft: 16 }} hidden={activeTab !== "1" || lista.length > 0 || permisos.colaboradorInfonavit !== 'write'}>
                <Col sm={12}>
                    <Button type="button" onClick={() => {
                        history.push('/infonavit/agregar')
                    }}
                        style={{ background: colors.secondary, width: '203px', height: '51px', borderRadius: '4px' }}>+ Agregar</Button>
                </Col>
            </Row>
        </ React.Fragment>
    );
};

export default DescuentosInfonavitView;