import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, CustomInput } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ComponenteDescrip from '../ui/ComponentDescrip';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { sucursalSchema } from '../../constans/yupSchemas';
import { creaSucursalAction } from '../../store/sucursalDuck';
import seccionOptions from '../../constans/seccion';
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import CustomSelectEstados from '../ui/CustomSelectEstados';
import {
  fetchEmpresasToDropdown,
  cleanStateEmpresas,
} from '../../store/empresasDuck';
import Spiner from '../ui/Spiner';
import {
  InputUtils,
  SubTitleMenu,
  LabelForms,
  SaveButton,
  CancelButton,
} from '../ui/styles/styles';
import TableConfiguracion from './TableConfiguracion';
let loading = true;
const NuevaSucursal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { empresas } = useSelector((state) => state.empresas);
  const [configuracion, setConfiguracion] = useState([]);

  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(sucursalSchema),
  });

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) { setErr(errors); }
  }, [errors]);

  useEffect(() => {
    dispatch(cleanStateEmpresas());
    (async () => {
      try {
        await dispatch(fetchEmpresasToDropdown());
      } catch (e) {
        console.log(e);
      }
    })();
    loading = false;
    return () => {
      dispatch(cleanStateEmpresas());
    };
  }, [dispatch]);
  const transformValues = (configuracion) => {
    console.log(configuracion);
    configuracion.obligatorio = !!configuracion.obligatorio;
    configuracion.expiracion = !!configuracion.expiracion;
    return configuracion;
  };
  const addToConfig = () => {
    const { configuracion } = getValues();
    const tranformedData = transformValues(configuracion);
    if (!configuracion.nombre || !configuracion.seccion) return;
    const cleanConfiguracion = {
      nombre: '',
      expiracion: false,
      obligatorio: false,
      seccion: '',
    };
    setConfiguracion((state) => [...state, tranformedData]);
    setValue('configuracion', cleanConfiguracion, { shouldDirty: true });
  };
  const submit = async (data) => {
    try {
      data.configuracion = configuracion;
      await dispatch(creaSucursalAction(data));
      await SweetAlert({ icon: 'success', title: 'Creado exitosamente' });
      history.push('/dashboard/cuenta/empresa');
    } catch (e) {
      SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  };
  if (loading) {
    return <Spiner />;
  }
  return (
    <>
      <ComponenteDescrip
        title={'Agregar sucursal'}
        description={'Registra una sucursal aquí'}
        icon={faUsersCog}
      />
      <Form style={{ marginTop: 40 }} onSubmit={handleSubmit(submit)}>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Empresa*</LabelForms>
          </Col>
          <Col sm={3}>
            <CustomSelect
              className='mb-3'
              type='select'
              name='idEmpresa'
              id='idEmpresa'
              control={control}
              innerRef={register()}
              options={empresas}
            />
            {err.idEmpresa && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Empresa es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Nombre Sucursal*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='text' placeholder='Nombre de Surcursal' name='nombreSucursal' ref={register()} />
            {err.nombreSucursal && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El nombre sucursal es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginTop: '35px' }}>
          <Col sm={12}></Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
            <SubTitleMenu
              style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
            >Dirección</SubTitleMenu>
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Estado*</LabelForms>
          </Col>
          <Col sm={3}>
            <CustomSelectEstados
              type='select'
              name='direccion.estado'
              id='estado'
              control={control}
              innerRef={register()}
            />
            {err.direccion && err.direccion.estado && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El estado es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Ciudad*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='text' placeholder='Ciudad' name='direccion.ciudad' ref={register()} />
            {err.direccion && err.direccion.ciudad && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La ciudad es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Colonia*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='text' placeholder='Colonia' name='direccion.colonia' ref={register()} />
            {err.direccion && err.direccion.colonia && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La colonia es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Calle*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='text' placeholder='Calle' name='direccion.calle' ref={register()} />
            {err.direccion && err.direccion.calle && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La calle es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>No. Exterior*</LabelForms>
          </Col>
          <Col sm={3} className='dm-c'>
            <InputUtils
              type='text'
              placeholder='Número exterior'
              name='direccion.numeroExterior'
              ref={register()}
            />
            {err.direccion && err.direccion.numeroExterior && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El No. exterior es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>No. Interior</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils
              type='text'
              placeholder='Número interior'
              name='direccion.numeroInterior'
              ref={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Código Postal*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils
              type='text'
              placeholder='Código postal'
              name='direccion.codigoPostal'
              ref={register()}
            />
            {err.direccion && err.direccion.codigoPostal && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El codigo postal es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginTop: '35px' }}>
          <Col sm={12}></Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
            <SubTitleMenu
              style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
            >Encargado</SubTitleMenu>
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Nombres*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='text' placeholder='Nombres' name='encargado.nombres' ref={register()} />
            {err.encargado && err.encargado.nombres && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El nombre es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Apellido Paterno*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils
              type='text'
              placeholder='Apellido paterno'
              name='encargado.apellidoPaterno'
              ref={register()}
            />
            {err.encargado && err.encargado.apellidoPaterno && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El apellido paterno es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Apellido Materno*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils
              type='text'
              placeholder='Apellido materno'
              name='encargado.apellidoMaterno'
              ref={register()}
            />
            {err.encargado && err.encargado.apellidoMaterno && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El apellido materno es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Teléfono*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils
              type='number'
              placeholder='Teléfono'
              name='encargado.telefono'
              ref={register()}
            />
            {err.encargado && err.encargado.telefono && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El teléfono es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <LabelForms>Email*</LabelForms>
          </Col>
          <Col sm={3}>
            <InputUtils type='email' placeholder='Email' name='encargado.email' ref={register()} />
            {err.encargado && err.encargado.email && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El email es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup style={{ marginTop: '35px' }}>
          <Col sm={12}></Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
            <SubTitleMenu
              style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
            >Documentos relacionados al expediente laboral</SubTitleMenu>
          </Col>
        </FormGroup>
        <FormGroup row className='dm-c'>
          <Col sm={3}>
            <InputUtils
              type='text'
              name='configuracion.nombre'
              placeholder='Nombre nuevo campo'
              ref={register()}
            />
          </Col>
          <Col sm={2}>
            <CustomInput
              innerRef={register}
              type='checkbox'
              label='Obligatorio'
              value={true}
              name='configuracion.obligatorio'
              id='configuracion.obligatorio'
            />
          </Col>
          <Col sm={2}>
            <CustomInput
              id='configuracion.expiracion'
              innerRef={register}
              type='checkbox'
              label='Expiración'
              value={true}
              name='configuracion.expiracion'
            />
          </Col>
          <Col sm={2}>
            <CustomSelect
              type='select'
              name='configuracion.seccion'
              id='seccionConfiguracion'
              control={control}
              innerRef={register({ required: true })}
              options={seccionOptions}
            />
          </Col>
          <Col sm={{ offset: 1, size: 2 }}>
            <SaveButton type='button' onClick={addToConfig}>
              + Agregar
            </SaveButton>
          </Col>
        </FormGroup>
        <FormGroup style={{ overflowY: 'scroll' }}>
          <TableConfiguracion listaConfiguracion={configuracion} />
        </FormGroup>
        <FormGroup row>
          <Col sm={{ offset: 8, size: 2 }} >
            <CancelButton
              type="button"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
          </CancelButton>
          </Col>
          <Col sm={{ size: 2 }}>
            <SaveButton type='submit'>Guardar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
};
export default NuevaSucursal;
