import { faArrowAltFromLeft, faArrowAltFromRight, faArrowAltLeft, faArrowAltRight, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import Col from 'reactstrap/lib/Col';
import Input from 'reactstrap/lib/Input';
import Row from 'reactstrap/lib/Row';
import SweetAlert from 'sweetalert2';
import QuincenaControl from '../../components/incidencias/quincenaControl/QuincenaControl';
import SemanaControl from '../../components/incidencias/semanaControl/SemanaControl';
import ReactTableStyle from '../../components/incidencias/styles';
import ComponenDescrip from '../../components/ui/ComponentDescrip';
import { DarkButton, LightMiniButton } from '../../components/ui/styles/styles';
import makeTableData from './makeTableData';

const api = process.env.REACT_APP_API_URL;

const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, 
}) => {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    if (id === 'name' || id === 'numero')
        return <label className='labels'>{value}</label>
    else if ( id === 'razon')
        return <input type='text' value={value ? value : ''} onChange={e => setValue(e.target.value)} onBlur={e => updateMyData(index, id, value)} style={{ width: '100%' }} />
    else if (id === 'cantidad')
        return <input type='number' step={1} value={value} onChange={e => setValue(e.target.value)} onBlur={e => updateMyData(index, id, value)} style={{ width: '100%' }} />
    else    
        return <input type='number' step={1} max={8} min={0} value={value} onChange={e => e.target.value >= 0 || e.target.value === '' ? setValue(e.target.value) : value} onBlur={e => updateMyData(index, id, value)} style={{ width: '100%' }} />
}

const defaultColumn = {
    Cell: EditableCell,
}

function Table({ columns, data, updateMyData, skipPageReset }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            autoResetPage: !skipPageReset,
            updateMyData,
        },
        useSortBy,
        usePagination
    )

    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps([
                            { className: headerGroup.className }
                        ])}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(
                                        [
                                            { className: column.className },
                                            column.getSortByToggleProps()
                                        ])}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? (<FontAwesomeIcon
                                                    icon={faSortDown}
                                                />)
                                                : (<FontAwesomeIcon
                                                    icon={faSortUp}
                                                />)
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <LightMiniButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltFromRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => previousPage()} disabled={!canPreviousPage}>
                    <FontAwesomeIcon icon={faArrowAltLeft} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => nextPage()} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltRight} />
                </LightMiniButton>{' '}
                <LightMiniButton onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    <FontAwesomeIcon icon={faArrowAltFromLeft} />
                </LightMiniButton>{' '}
                <span>
                    Página{' '}
                    <strong>
                        {pageIndex + 1} de {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Ir a la página:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Mostrar {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}

function Ajustes() {
    const [date, setDate] = useState(moment());

    const swal = (title, description, success) => {
        SweetAlert.fire({
            title: title,
            text: description,
            icon: success ? 'success' : 'error',
            confirmButtonText: 'Aceptar'
        })
    }

    const columns = React.useMemo(
        () => [
            {
                Header: ' ',
                columns: [
                    
                    // {
                    //     Header: 'No.',
                    //     accessor: 'numero',
                    //     className: 'ajuste-xxs'
                    // },
                    {
                        Header: 'Nombre',
                        accessor: 'name',
                    },
                    {
                        Header: 'Descanso Trabajado (horas)',
                        accessor: 'descanso',
                        className: 'ajuste-xs'
                    },
                    {
                        Header: 'Domingo Trabajado',
                        accessor: 'prima',
                        className: 'ajuste-xs'
                    },
                ],
            },
            {
                Header: 'Ajuste de horas',
                className: 'cantidad-horas-header',
                columns: [
                    {
                        Header: 'Cantidad',
                        accessor: 'cantidad',
                        className: 'cantidad-horas'
                    },
                    {
                        Header: 'Razón',
                        accessor: 'razon',
                        className: 'cantidad-horas-razon'
                    },
                ],
            },
        ],
        [date]
    )

    const [listaEmpresas, setEmpresas] = useState([]);
    const [empresa, setEmpresa] = useState({ nombre_empresa: '' });
    const [listaSucursales, setSucursales] = useState([]);
    const [sucursal, setSucursal] = useState({ nombreSucursal: '' });
    const [listaDepartamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState({ nombreDepartamento: '' });
    const [listaColaboradores, setColaboradores] = useState([]);
    const [periodo, setPeriodo] = useState({ nombrePeriodo: '' });
    const listaPeriodos = ['Semanal', 'Quincenal'];
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token")
        }
    };

    const [data, setData] = React.useState([])
    const [skipPageReset, setSkipPageReset] = React.useState(false)

    const updateMyData = (rowIndex, columnId, value) => {
        setSkipPageReset(true)
        setData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value
                    }
                }
                return row
            })
        )
    }

    React.useEffect(() => {
        setSkipPageReset(false);
    }, [data])

    const getDates = () => {
        let startDate = 0;
        let endDate = 0;
        if(periodo.nombrePeriodo === 'Semanal'){
            startDate = moment(date).startOf('week').format('YYYY-MM-DD');
            endDate = moment(date).endOf('week').format('YYYY-MM-DD');
        } else if (periodo.nombrePeriodo === 'Quincenal'){
            startDate = parseInt(moment(date).format('DD')) < 16 ? moment(date).startOf('month').format('YYYY-MM-DD') : moment(`${moment(date).format('YYYY')}-${moment(date).format('MM')}-16`).format('YYYY-MM-DD');
            endDate = parseInt(moment(date).format('DD')) > 15 ? moment(date).endOf('month').format('YYYY-MM-DD') : moment(`${moment(date).format('YYYY')}-${moment(date).format('MM')}-15`).format('YYYY-MM-DD');
        }
        return [startDate, endDate]
    }

    const saveData = async (plantilla) => {
        if(periodo.nombrePeriodo === ''){
            swal('Error', 'Debe seleccionar el periodo antes de continuar', false);
            return;
        }
        try {
            const dates = getDates();
            let { data } = await axios.post(`${api}/ajustes/crear-masivo?dateStart=${dates[0]}&dateEnd=${dates[1]}`, plantilla, headers)
            if (data)
                swal('Gurdado exitoso', 'Se han registrado los ajustes', true);
        } catch (error) {
            swal('Ocurrió un error', error, false);
        }
    }


    const fetchEmpresas = async () => {
        let { data } = await axios.get(`${api}/empresa/obtener_empresas`, headers);
        setEmpresas(data.empresas)
    }

    const fetchSucursales = async (empresa) => {
        const emp = listaEmpresas.filter((e) => e.nombre_empresa === empresa.nombre_empresa);
        if (emp.length === 1) {
            let { data } = await axios.get(`${api}/sucursales/por_empresa?empresa=${emp[0]._id}`, headers);
            data.resultado.unshift({ nombreSucursal: 'Todas', _id: 'all' })
            setSucursales(data.resultado);
        }
    }

    const fetchDepartamentos = async (sucursal) => {
        const suc = listaSucursales.filter((e) => e.nombreSucursal === sucursal.nombreSucursal);
        if (suc.length === 1) {
            let { data } = await axios.get(`${api}/departamentos/por_sucursal?sucursal=${suc[0]._id}`, headers);
            data.resultado.unshift({ nombreDepartamento: 'Todos', _id: 'all' })
            setDepartamentos(data.resultado);
        }
    }

    const fetchColaboradores = async (pertenencia, type) => {
        let grupo = [];
        if (type === 'departamento' && pertenencia.nombreDepartamento)
            grupo = listaDepartamentos.filter(d => d.nombreDepartamento === pertenencia.nombreDepartamento);
        else if (type === 'sucursal')
            grupo = listaSucursales.filter(d => d.nombreSucursal === pertenencia.nombreSucursal);
        else if (type === 'empresa')
            grupo = listaEmpresas.filter(d => d.nombre_empresa === pertenencia.nombre_empresa);
        if (grupo.length === 1) {
            const dates = getDates();
            const { data } = await axios.get(`${api}/ajustes/lista-masivo/${grupo[0]._id}?type=${type}&dateStart=${dates[0]}&dateEnd=${dates[1]}&periodo=${periodo.nombrePeriodo}`, headers);
            setColaboradores(data.resultado);
            setData(makeTableData(data.resultado, date))
        }
    }

    useEffect(() => {
        fetchEmpresas();
    }, [])

    useEffect(() => {
        if (empresa)
            fetchSucursales(empresa);
    }, [empresa])

    useEffect(() => {
        if (sucursal && sucursal.nombreSucursal !== 'Todas') {
            fetchDepartamentos(sucursal);
        } else {
            setDepartamentos([])
            setDepartamento({})
            fetchColaboradores(empresa, 'empresa');
        }
    }, [sucursal])

    useEffect(() => {
        setData([])
        if (departamento && departamento.nombreDepartamento !== 'Todos' && sucursal.nombreSucursal !== 'Todas') {
            fetchColaboradores(departamento, 'departamento');
        } else if (sucursal && sucursal.nombreSucursal !== 'Todas'){
            fetchColaboradores(sucursal, 'sucursal')
        } else{
            fetchColaboradores(empresa, 'empresa')
        }
    }, [departamento, date])

    useEffect(() =>{
        if(periodo.nombrePeriodo === 'Semanal'){
            setDate(moment());
        } else if(periodo.nombrePeriodo === 'Quincenal'){
            const day = moment().format('DD');
            if(parseInt(day) < 16) {
                setDate(moment().startOf('month'));
            } else {
                setDate(moment(`${moment().format('YYYY')}-${moment().format('MM')}-16`));
            }
        }
    }, [periodo])



    return (
        <>
            <ComponenDescrip title={"Ajustes"} description={"Realiza los ajustes de tus colaboradores"} />
            <Row>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setEmpresa({ nombre_empresa: e.target.value })}
                        value={empresa.nombre_empresa}
                    >
                        <option value={""}>Empresa...</option>
                        {listaEmpresas.map((e) => (
                            <option value={e.nombre_empresa} key={e._id}>
                                {e.nombre_empresa}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setSucursal({ nombreSucursal: e.target.value })}
                        value={sucursal.nombreSucursal}
                    >
                        <option value={""}>Sucursal...</option>
                        {listaSucursales.map((s) => (
                            <option value={s.nombreSucursal} key={s._id}>
                                {s.nombreSucursal}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setDepartamento({ nombreDepartamento: e.target.value })}
                        value={departamento.nombreDepartamento}
                    >
                        <option value={""}>Departamento...</option>
                        {listaDepartamentos.map((d) => (
                            <option value={d.nombreDepartamento} key={d._id}>
                                {d.nombreDepartamento}
                            </option>
                        ))}
                    </Input>
                </Col>
                <Col sm={2} md={2}>
                    <Input
                        type="select"
                        name="select"
                        onChange={(e) => setPeriodo({ nombrePeriodo: e.target.value })}
                        value={periodo.nombrePeriodo}
                    >
                        <option value={""}>Periodo...</option>
                        {listaPeriodos.map((d) => (
                            <option value={d} key={listaPeriodos.indexOf(d)}>
                                {d}
                            </option>
                        ))}
                    </Input>
                </Col>
                {
                    periodo && periodo.nombrePeriodo === 'Quincenal' ?
                        <QuincenaControl width={4} date={date} setDate={setDate} />
                        : periodo && periodo.nombrePeriodo === 'Semanal' ?
                        <SemanaControl width={4} date={date} setDate ={setDate} />
                        : ''
                }
            </Row>
            {listaColaboradores.length  > 0 && periodo.nombrePeriodo !== ''? (
                <>
                    <ReactTableStyle>
                        <Table
                            className='table table-striped'
                            columns={columns}
                            data={data}
                            updateMyData={updateMyData}
                            skipPageReset={skipPageReset}
                        />
                    </ReactTableStyle>

                    <Row>
                        <Col sm={12}>
                            <Row>
                                <Col sm={{ offset: 10, size: 2 }}>
                                    <DarkButton onClick={() => saveData(data)}>Guardar</DarkButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            ) : ''}
        </>
    )
}

export default Ajustes
