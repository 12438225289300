import { faCog } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect, useState } from 'react';
import SubSideBar from "../../components/layout/SubSidebar/SubSidebar";
import colors from "../../constans/colors";
import cuenta_routes from "../../routes/cuenta-routes";
import CuentaRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/CuentaRoutes";
import { LettersContainer, PoweredByNomizor } from '../styled';
const Cuenta = ({ match, location, history }) => {
    const [actualPage, setActualpage] = useState('')

    useEffect(() => {
        if (location && location.pathname) {
            const pageIndex = location.pathname.split('/').length;
            setActualpage(location.pathname.split('/')[pageIndex - 1])
        }
    }, [location]);
    return (
        <section className='grid-container'>
            <div style={{ backgroundColor: colors.primary }} className='sub-menu-container'>
                <LettersContainer />
                <SubSideBar icon={faCog} routes={cuenta_routes} match={match} title='Cuenta'
                    className={`${actualPage === 'crear-empersa' || actualPage === 'empresa' ? 'active' : ''}`} />
                <PoweredByNomizor />
            </div>
            <div className='frame-content'>
                <CuentaRoutes path={match.path} history={history} />
            </div>

        </section>
    )
}

export default Cuenta;