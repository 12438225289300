import React, { useState, useEffect } from "react";
import { ListContent, HeaderStyle, StyleInputs } from "../ui/styles/StyleNomina";
import {
  Row,
  Col,
  Button,
  Input,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Table,
  ButtonDropdown,
  DropdownToggle,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import "../ui/styles/Nomina.css";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import styled from "styled-components";
import moment from "moment";
import PaginationComponent from "react-reactstrap-pagination";
import jsZip from "jszip";
import { saveAs } from "file-saver";
import swal from "sweetalert2";
import Dot from "../../components/ui/PulsateDot";
const api = process.env.REACT_APP_API_URL;

const Nomina = ({}) => {
  const [nominas, setNominas] = useState([]);
  const [nominasPaginated, setNominasPaginated] = useState([]);
  const location = useLocation();
  const parameters = useParams();
  const [sucursales, setSucursales] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [filters, setFilters] = useState({ from: moment().startOf("month").format("YYYY-MM-DD"), to: moment().endOf("month").format("YYYY-MM-DD") });
  const [openFile, setOpenFile] = useState({});
  const [colaboradorSelect, setColaboradorSelect] = useState({});
  const [showCancelar, setSwhoCancelar] = useState(false);
  const [keyData, setKeyData] = useState({
    keyBase64: "",
    keyPass: "",
  });

  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const pageSize = 50;
  useEffect(() => {
    getSucursales();
    getColaboradores();
    getPeriodos();
  }, []);

  const buildQuery = (query) =>
    Object.entries(query)
      .filter(([key, val]) => val !== undefined)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

  const getNominas = async () => {
    swal.showLoading();
    const query = buildQuery(filters);
    let { data } = await axios.get(`${api}/nomina/consultar-timbrados/${parameters.idEmpresa}/?${query}`, headers);
    setNominas([...data.response]);
    setNominasPaginated(data.response.slice(0, pageSize));
    setColaboradorSelect({});
    swal.close();
  };

  const getSucursales = async () => {
    let { data } = await axios.get(`${api}/sucursales/por_empresa?empresa=${parameters.idEmpresa}`, headers);
    setSucursales(data.resultado);
  };

  const getDepartamentos = async (idSucursal) => {
    let { data } = await axios.get(`${api}/departamentos/por_sucursal?sucursal=${idSucursal}`, headers);
    setFilters({ ...filters, sucursal: idSucursal === "all" ? undefined : idSucursal });
    setDepartamentos(data.resultado);
  };

  const getColaboradores = async () => {
    let { data } = await axios.get(`${api}/nomina/filtrar-colaborador/${parameters.idEmpresa}/`, headers);    
    setColaboradores(data.response);
  };

  const getPeriodos = async () => {
    let { data } = await axios.get(`${api}/catalogos/?sat=true&catalogo=periodicidadPago`, headers);    
    setPeriodos(data.response);
  };

  const onSelectColaborador = (e) => {
    let [first] = e;
    if (first !== undefined) {
      setFilters({ ...filters, colaborador: first._id });
    } else {
      setFilters({ ...filters, colaborador: undefined });
    }
  };

  const openFiles = (id) => {
    openFile[id] = !openFile[id];
    setOpenFile({ ...openFile });
  };

  const onSelectPage = (number) => {
    number = number - 1;
    setNominasPaginated(nominas.slice(number, number + pageSize));
  };

  const downloadAll = async () => {
    var zip = new jsZip();
    swal.showLoading();
    nominas.forEach((item) => {
      if (item.xml) zip.file(`${item.nombre}-${item.numero}-${item.periodo}.xml`, item.xml, { base64: true });
      if (item.pdf) zip.file(`${item.nombre}-${item.numero}-${item.periodo}.pdf`, item.pdf, { base64: true });
    });
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `timbres ${filters.from}-${filters.to}.zip`);
    });
    swal.close();
  };

  const onCheckColaborador = (id) => {
    setColaboradorSelect({ ...colaboradorSelect, [id]: !colaboradorSelect[id] });
  };

  const statusColor = (status) => {
    switch (status) {
      case "sellada":
        return "green";
      case "error":
        return "red";
      default:
        return "gray";
    }
  };
  const onClickStatus = (e) => {
    if (e.estado !== "error") return;
    swal.fire("Error", e.message, "warning");
  };

  const onCancelar = async () => {
    setSwhoCancelar(false);
    swal.showLoading();
    console.log({
      cfdis: Object.keys(colaboradorSelect).filter((k) => colaboradorSelect[k]),
      ...keyData,
    });
    await new Promise((r) => setTimeout(r, 2000));
    // let { data } = await axios.post(`${api}/nomina/cancelar-nomina/${parameters.idEmpresa}/?${query}`, headers);
    await getNominas();    
    swal.fire("Nomina", "Se han cancelado exitosamente", "success")
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => resolve(btoa(reader.result));
      reader.onerror = (error) => reject(error);
    });

  const onChangeFile = async (e) => {
    let [file] = e.target.files;
    setKeyData({ ...keyData, keyBase64: await toBase64(file) });
  };

  const onAtras = () => {
    setKeyData({ keyBase64: "", keyPass: "" });
    setSwhoCancelar(false);
  };

  const validateKeyData = () => keyData.keyBase64 === "" || keyData.keyPass === "";

  const selectedAll = (select) => {
    nominas.filter((n) => n.estado === "sellada").map((d) => (colaboradorSelect[d._id] = select));
    console.log(colaboradorSelect);
    setColaboradorSelect({ ...colaboradorSelect });
  };

  const onCancelarOne = (id) => {
    setColaboradorSelect({ [id]: true });
    setSwhoCancelar(true);
  };

  return (
    <>
      <Row>
        <Col>
          <HeaderStyle>
            <h1>Nomina</h1>
            <h3>Consulta aqui tu nomina timbrada.</h3>
          </HeaderStyle>
        </Col>
      </Row>

      <Modal isOpen={showCancelar} unmountOnClose={true} toggle={() => setSwhoCancelar(!showCancelar)} centered={true}>
        <ModalHeader toggle={() => setSwhoCancelar(!showCancelar)}>Seleccione la llave para cancelar</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="exampleCustomFileBrowser">Archivo .key</Label>
            <CustomInput type="file" id="exampleCustomFileBrowser" accept=".key" name="customFile" onChange={onChangeFile} placeholder="" label="" multiple={false} />
          </FormGroup>
          <FormGroup>
            <Label for="exampleCustomFileBrowser">Contraseña del archivo</Label>
            <Input
              type="password"
              placeholder="Escribir contraseña"
              onChange={(e) => setKeyData({ ...keyData, keyPass: e.target.value })}
              style={{ border: "1px solid #ced4da !important" }}
            />
          </FormGroup>
        </ModalBody>
        <StyleInputs>
          <ModalFooter>
            <Button color="secondary" onClick={onAtras}>
              Atras
            </Button>{" "}
            <Button color="primary" disabled={validateKeyData()} onClick={onCancelar}>
              Aceptar
            </Button>
          </ModalFooter>
        </StyleInputs>
      </Modal>

      <Row className="mt-4">
        <Col>
          <ListContent>
            <Row className="my-4">
              <Col sm={12} md={6}>
                <SelectStyle>
                  <Typeahead
                    style={{ color: "#404041" }}
                    clearButton={true}
                    id="_id"
                    labelKey="nombre"
                    onInputChange={(text, e) => {
                      console.log(text);
                    }}
                    onChange={onSelectColaborador}
                    options={colaboradores}
                    placeholder="Buscar Colaborador..."
                    minLength={1}
                  />
                </SelectStyle>
              </Col>
              <StyleInputs>
                <Col sm={12} md={2}>
                  <Button color="primary" block onClick={getNominas}>
                    Buscar
                  </Button>
                </Col>
                <Col sm={12} md={2} className="">
                  <Button color="primary" disabled={!Object.keys(colaboradorSelect).some((v) => colaboradorSelect[v])} block onClick={() => setSwhoCancelar(true)}>
                    Cancelar Todo
                  </Button>
                </Col>
                <Col sm={12} md={2} className="">
                  <Button color="primary" block onClick={downloadAll} disabled={!nominas.length}>
                    Descargar Todo
                  </Button>
                </Col>
              </StyleInputs>
            </Row>
            <Row className="my-4">
              <Col sm={2} md={2}>
                <Input type="select" name="select" onChange={(e) => getDepartamentos(e.target.value)}>
                  <option value={undefined}>Sucursal...</option>
                  <option value={"all"}>Todas</option>
                  {sucursales.map((s) => (
                    <option value={s._id} key={s._id}>
                      {s.nombreSucursal}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm={2} md={2}>
                <Input type="select" name="select" onChange={(e) => setFilters({ ...filters, departamento: e.target.value === "all" ? undefined : e.target.value })}>
                  <option value={undefined}>Departamento...</option>
                  <option value={"all"}>Todos</option>
                  {departamentos.map((d) => (
                    <option value={d._id} key={d._id}>
                      {d.nombreDepartamento}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm={2} md={2}>
                <Input type="select" name="select" onChange={(e) => setFilters({ ...filters, periodicidad: e.target.value === "all" ? undefined : e.target.value })}>
                  <option value={undefined}>Periodicidad...</option>
                  <option value={"all"}>Todos</option>
                  {periodos.map((d) => (
                    <option value={d._id} key={d._id}>
                      {d.descripcion}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col sm={3} md={3} className="">
                <InputGroup>
                  <span style={{ border: "none" }} class="input-group-text" id="basic-addon1">
                    Desde
                  </span>
                  <Input
                    type="date"
                    value={filters.from}
                    name="fechaInicio"
                    placeholder="Fecha Inicio"
                    onChange={(e) => setFilters({ ...filters, from: moment(e.target.value).format("YYYY-MM-DD") })}
                  />
                </InputGroup>
              </Col>
              <Col sm={3} md={3}>
                <InputGroup>
                  <span style={{ border: "none" }} class="input-group-text" id="basic-addon1">
                    Hasta
                  </span>
                  <Input
                    type="date"
                    value={filters.to}
                    name="fechaInicio"
                    placeholder="Fecha Inicio"
                    onChange={(e) => setFilters({ ...filters, to: moment(e.target.value).format("YYYY-MM-DD") })}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                  <thead>
                    <tr>
                      <th>
                        <FormGroup check inline>
                          <Label check inline>
                            <Input
                              type="checkbox"
                              onClick={() => selectedAll(!Object.keys(colaboradorSelect).some((v) => colaboradorSelect[v]))}
                              checked={Object.keys(colaboradorSelect).some((v) => colaboradorSelect[v])}
                            />
                            Todo
                          </Label>
                        </FormGroup>
                      </th>
                      <th>Numero</th>
                      <th>Nombre</th>
                      <th className="text-left">Departamento</th>
                      <th className="text-center">Periodo</th>
                      <th>Estado</th>
                      <th className="text-center">Recibo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {nominasPaginated.map((d, i) => (
                      <tr key={i}>
                        <td>
                          {d.estado === "sellada" && (
                            <FormGroup check inline>
                              <Label check inline>
                                <Input type="checkbox" checked={colaboradorSelect[d._id] || false} onClick={() => onCheckColaborador(d._id)} />
                              </Label>
                            </FormGroup>
                          )}
                        </td>
                        <th>{d.numero}</th>
                        <td>{d.nombre}</td>
                        <td className="text-left">{d.departamento}</td>
                        <td className="text-center">{d.periodo}</td>
                        <td className="text-center" onClick={() => onClickStatus(d)} style={{ cursor: d.estado === "error" ? "pointer" : undefined }}>
                          <Dot color={statusColor(d.estado)} title={d.estado} />
                        </td>
                        <td className="text-center">
                          {(d.xml !== undefined || d.pdf !== undefined) && (
                            // <StyleInputs>
                            <ButtonDropdown size="sm" isOpen={openFile[d._id]} toggle={() => openFiles(d._id)}>
                              <DropdownToggle style={{ height: "30px", background: "rgb(43, 56, 143)" }} caret>
                                Descargar
                              </DropdownToggle>
                              <DropdownMenu>
                                {d.xml !== undefined && (
                                  <a href={`data:application/xml;base64,${d.xml}`} download={`XML-${d.nombre}-${d.numero}-${d.periodo}.xml`}>
                                    <DropdownItem>XML </DropdownItem>
                                  </a>
                                )}
                                {d.pdf !== undefined && (
                                  <a href={`data:application/pdf;base64,${d.pdf}`} download={`PDF-${d.nombre}-${d.numero}-${d.periodo}.pdf`}>
                                    <DropdownItem>PDF </DropdownItem>
                                  </a>
                                )}
                              </DropdownMenu>
                            </ButtonDropdown>
                            // </StyleInputs>
                          )}
                        </td>

                        <td>
                          {d.estado === "sellada" && (
                            <Button color={"danger"} size="sm" onClick={() => onCancelarOne(d._id)}>
                              Cancelar
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col>
                <PaginationComponent
                  size={"sm"}
                  firstPageText="Primera"
                  previousPageText="Anterior"
                  nextPageText="Siguiente"
                  lastPageText="Ultima"
                  totalItems={nominas.length}
                  pageSize={pageSize}
                  onSelect={onSelectPage}
                />
              </Col>
            </Row>
          </ListContent>
        </Col>
      </Row>
    </>
  );
};

const SelectStyle = styled.div`
  .close.rbt-close {
    position: absolute;
    top: 7px;
    right: 10px;
  }
`;

export default Nomina;
