import axios from "./axios";
import { saveAs } from 'file-saver';

const getTxt = async ({ body = {}, reporte, nombre, ids }) => {
  const { data } = await axios.post(`/reportes/exportar-txt/${reporte}`, { ...body, ids }, {
    headers: {
      token: localStorage.getItem('pv2token'),
    },
    responseType: "object"
  });

  const blob = new Blob([data.data], { type: "text/plain;charset=utf-8" });
  return saveAs(blob, `Movt_${nombre}_IDSE al ${new Date().toJSON().slice(0, 10)}.txt`);

}

export default getTxt;