import React from "react";
import { Label, Text, Tag } from 'react-konva';

const Tooltip = ({ x, y, visible, text, direction }) => (
    <Label x={x} y={y} opacity={0.75} visible={visible} listening={false} >
        <Tag
            fill="black"
            pointerDirection="right"
            pointerWidth={10}
            pointerHeight={10}
            lineJoin="round"
            shadowColor="black"
            shadowBlur={10}
            shadowOffsetX={10}
            shadowOffsetY={10}
            shadowOpacity={0.2}
            cornerRadius={5}
        />
        <Text
            text={text}
            fontSize={18}
            padding={5}
            fill="#FFFFFF"
        />
    </Label>
)

export default Tooltip
