const seccionOptions = [
  { label: 'Personal', value: 'Personal' },
  { label: 'Laboral', value: 'Laboral' },
  { label: 'Utilitario', value: 'Utilitario' },
  {
    label: 'Terminada la relacion laboral',
    value: 'Terminada la relacion laboral',
  },
];

export default seccionOptions;
