import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tr, BackgroundImage } from '../ui/styles/styles';
import Icons from '../ui/Icons';
import moment from 'moment';

const TableBodyConciliaciones = React.memo(({ listaConciliaciones = [], action, setAction, eliminarAction }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <>
            {listaConciliaciones.map((conciliacion) => {
                const {registro, mes, ano, asegurados, acreditados, createdAt, _id} = conciliacion;

                return (
                <Tr
                    id={_id}
                    key={_id}
                    onClick={() => setAction(conciliacion)}
                    style={{ cursor: 'pointer' }}
                >
                    <td className='span-label'>{registro}</td>
                    <td className='span-label'>{mes}</td>
                    <td className='span-label'>{ano}</td>
                    <td className='span-label'>{asegurados}</td>
                    <td className='span-label'>{acreditados}</td>
                    <td className='span-label'>{moment(createdAt).utc().format('DD-MM-YYYY')}</td>
                    <td className='middle' style={{ textAlign: 'center', padding: 0, verticalAlign: 'middle' }}>
                        <Icons onClick={() => eliminarAction(_id)} />
                    </td>
                </Tr>
            )})}
        </>
    );
});

export default TableBodyConciliaciones