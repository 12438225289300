import React, { useState, useEffect } from 'react';
import { FormGroup, Form, Col, CustomInput, Row, Table } from 'reactstrap';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { agregarEmpresaAction } from '../../store/empresasDuck';
import Spiner from '../ui/Spiner';
import SweetAlert from '../ui/SweetAlet';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { empresaRegistroSchema } from '../../constans/yupSchemas';
import CustomSelectEstados from '../ui/CustomSelectEstados';
import SelectRegimenFiscal from '../ui/SelectRegimenFiscal'
import {
  SaveButton,
  InputUtils,
  LabelForms,
  SubTitleMenu,
  CancelButton,
} from '../ui/styles/styles';
import InputImage from '../ui/InputImage';
import InputFile from '../ui/InputFile';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSelect from '../ui/CustomSelect';
import delegacionesIMSSOptions from '../../constans/delegacionesIMSS';
import colors from '../../constans/colors';
import axios from '../../services/axios';

const api = process.env.REACT_APP_API_URL;

const RegistroEmpresa = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const { loading } = useSelector((state) => state.empresas);
  const history = useHistory();
  const dispatch = useDispatch();
  // const [registroPatronal, setRegistroPatronal] = useState([]);
  const [personType, setPersonType] = useState('');
  const [fileName, setFileName] = useState('');
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(empresaRegistroSchema),
  });
  const [certBase64, setCertBase64] = useState({});
  const [subdelegacionesIMSSOptions, setSubdelegacionesIMSSOptions] = useState([]);
  const [registroPatronal, setRegistroPatronal] = useState({ registroPatronal: '', delegacionIMSS: '', subdelegacionIMSS: ''});
  const [registros, setRegistros] = useState([]);

  const addRegistroPatronal = () => {
    if(registroPatronal.registroPatronal === '' || registroPatronal.delegacionIMSS === '' || registroPatronal.subdelegacionIMSS === ''){
      SweetAlert({ icon: 'warning', title: 'El número de registro, la delegación y subdelegación son requeridos' });
      return;
    }
    setRegistros([...registros, registroPatronal]); 
    setRegistroPatronal({registroPatronal: '', delegacionIMSS: '', subdelegacionIMSS:''})
  }

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const addRegister = () => {
    const { registroPatronal } = getValues();
    if (!registroPatronal) return;
    if (registroPatronal) {
      setRegistroPatronal((registers) => [...registers, registroPatronal]);
      setValue('registroPatronal', '', { shouldDirty: true });
    }
  };

  const submit = async (data) => {
    try {
      if(registros.length === 0){
        SweetAlert({ icon: 'warning', title: 'Debe agregar al menos un registro patronal en la empresa' });
        return
      } 
      data.registrosPatronales = registros;
      data.informacionLegal.certificado = certBase64
      await dispatch(agregarEmpresaAction(data));
      SweetAlert({ icon: 'success', title: 'Registrado exitosamente' });
      history.push('/dashboard/cuenta/empresa');
    } catch (e) {
      SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  };

  const addCert = (e) => {
    const { informacionLegal } = getValues()
    if (informacionLegal.certificado && informacionLegal.certificado[0]) {
      setFileName(informacionLegal.certificado[0].name.replace('.cer', ''));
      getBase64(informacionLegal.certificado[0])
      if (err.informacionLegal && err.informacionLegal.numeroCertificado) {
        delete err.informacionLegal.numeroCertificado
      }
    }
  };

  function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function (e) {
      await setCertBase64(() => e.target.result.replace("data:application/x-x509-ca-cert;base64,", ""))
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const deleteRegistroPatronal = (index) => {
    setRegistroPatronal(registroPatronal.filter((rep, i) => i !== index))
  };

  const changeDelegacion = (e) => {
    setRegistroPatronal({...registroPatronal, delegacionIMSS: e});
    const delegacion = delegacionesIMSSOptions.filter(d=> d.value === e)
    setSubdelegacionesIMSSOptions(delegacion[0].subdelegaciones)
  };

  async function selectZona(zona) {
    console.log(`Se ha seleccionado ${zona}`);
    const { data } = await axios.get(`${api}/empresa/obtener_zona?zona=${zona}`, headers)
    console.log(data)   
    setValue('informacionLegal.salarioMinimo', data.zona.salarioMinimo);
    setValue('informacionLegal.uma', data.zona.uma);
    setValue('informacionLegal.umi', data.zona.umi);
  }

  if (loading) {
    return <Spiner />;
  }
  return (
    <Form onSubmit={handleSubmit(submit)}>
      <FormGroup row>
        <Col sm={3}>
          <SubTitleMenu className='ml-0'>Logotipo</SubTitleMenu>
        </Col>
        <Col sm={9}>
          <InputImage name='logotipo' register={register()} />
          {err.logotipo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El logotipo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Nombre Empresa*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='nombre_empresa'
            placeholder='Introduzca el nombre de la empresa'
            ref={register()}
          />
          {err.nombre_empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre de la empresa es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Razón social</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Tipo persona*</LabelForms>
        </Col>
        <Col sm={3}>
          <CustomInput
            innerRef={register}
            type='radio'
            label='Persona Física'
            value='Persona Fisica'
            name='informacionLegal.tipo_persona'
            onClick={() => setPersonType('fisica')}
          />
        </Col>
        <Col sm={3}>
          <CustomInput
            innerRef={register}
            type='radio'
            label='Persona Moral'
            value='Persona Moral'
            name='informacionLegal.tipo_persona'
            onClick={() => setPersonType('moral')}
          />
        </Col>
      </FormGroup>
      {err.informacionLegal && err.informacionLegal.tipo_persona && (
        <FormGroup row>
          <Col sm={3}>
            <LabelForms sm={3}>
            </LabelForms>
          </Col>
          <Col sm={3}>
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo de persona es requerido
            </span>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col sm={3} row>
          <LabelForms>Razón Social*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.razon_social'
            placeholder='Tu empresa SA de CV'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.razon_social && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La razón social es requerida
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>RFC*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.rfc'
            placeholder='Introduzca el RFC'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.rfc && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El RFC es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      {
        personType === 'fisica' &&
        <FormGroup row>
          <Col sm={3}>
            <LabelForms>CURP*</LabelForms>
          </Col>
          <Col sm={9}>
            <InputUtils
              type='text'
              name='informacionLegal.curp'
              placeholder='Introduzca la CURP'
              ref={register()}
            />
            {err.informacionLegal && err.informacionLegal.curp && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La CURP es requerida
              </span>
            )}
          </Col>
        </FormGroup>
      }

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Área geográfica</LabelForms>
        </Col>
        <Col sm={9}>
        <CustomSelect
            control={control}
            type='select'
            name='informacionLegal.areaGeografica'
            placeholder='Seleccione el área geográfica'
            innerRef={register()}
            options={[
              { value: 'zlfn', label: 'Zona Libre de la Frontera Norte' },
              { value: 'nacional', label: 'Nivel Nacional' },
            ]}
            onChange={e => selectZona(e.target.value)}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>UMA*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            name='informacionLegal.uma'
            placeholder='Introduzca el UMA'
            step="0.01"
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.uma && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La UMA es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Unidad Mixta Infonavit (UMI)*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            step='0.01'
            name='informacionLegal.umi'
            placeholder='Introduzca el valor de UMI'
            ref={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Salario Mínimo*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            name='informacionLegal.salarioMinimo'
            placeholder='Introduzca el salario Mínimo'
            step="0.01"
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.salarioMinimo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario mínimo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Régimen*</LabelForms>
        </Col>
        <Col sm={5}>
          <SelectRegimenFiscal
            control={control}
            type='select'
            name='informacionLegal.regimen'
            placeholder='Introduzca el régimen'
            innerRef={register()}
          />
          {err.informacionLegal && err.informacionLegal.regimen && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El régimen es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Certificado*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputFile
            id='certificado'
            name='informacionLegal.certificado'
            onChange={() => addCert()}
            accept='.cer'
            register={register()}
          />
          {err.informacionLegal && err.informacionLegal.numeroCertificado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El certificado es requerido
            </span>
          )}
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            name='informacionLegal.numeroCertificado'
            ref={register()}
            value={fileName}
            disabeld={true}
          />

        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Correo de notificaciones*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.correNotificaciones'
            placeholder='Introduzca el correo notificaciones'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.correNotificaciones && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El correo de notificaciones es requerido
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Configuración del PAC &nbsp;
          <FontAwesomeIcon
            id='svg'
            color={colors.secondary}
            icon={faInfoCircle}
            title='Si no cuenta con su registro en el PAC, consulte a su asesor Nomizor.'
          />
          </SubTitleMenu>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.contratoPade'
            placeholder='Introduzca el contrato PADE'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.contratoPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El contrato PADE es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Correo del contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.correoContratoPade'
            placeholder='Introduzca el correo contrato PADE'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.correoContratoPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El correo del contrato PADE es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Constraseña del contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='password'
            name='informacionLegal.contraseniaPade'
            placeholder='Introduzca la constraseña contrato PADE'
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.contraseniaPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La contraseña del contrato PADE es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Dirección</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Calle*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.calle'
            placeholder='Introduzca la dirección de la empresa'
            ref={register()}
          />
          {err.direccion && err.direccion.calle && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La calle es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Número*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.numero'
            placeholder='Introduzca el número'
            ref={register()}
          />
          {err.direccion && err.direccion.numero && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Colonia*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.colonia'
            placeholder='Introduzca la colonia de la empresa'
            ref={register()}
          />
          {err.direccion && err.direccion.colonia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La colonia es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Código Postal*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.codigoPostal'
            placeholder='Introduzca el código postal de la empresa'
            ref={register()}
          />
          {err.direccion && err.direccion.codigoPostal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El código postal es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Ciudad*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.ciudad'
            placeholder='Introduzca la ciudad de la empresa'
            ref={register()}
          />
          {err.direccion && err.direccion.ciudad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Estado*</LabelForms>
        </Col>
        <Col sm={5}>
          <CustomSelectEstados
            type='select'
            name='direccion.estado'
            id='estadoResidencia'
            control={control}
            innerRef={register({ required: true })}
          />
          {err.direccion && err.direccion.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      {/* <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Configuración de los registros patronales</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Registro patronal</LabelForms>
        </Col>
        <Col sm={9}>
          <ol>
            {registroPatronal.length > 0
              ? registroPatronal.map((registro, i) => (
                <Row key={i}>
                  <Col md={3}><h5>{registro}</h5></Col>
                  <Col md={1}> <FontAwesomeIcon
                    cursor="pointer"
                    onClick={() => deleteRegistroPatronal(i)}
                    icon={faTrash}
                  />
                  </Col>
                </Row>
              ))
              : null}
          </ol>
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            name='registroPatronal'
            placeholder='Introduzca el registro patronal'
            ref={register()}
          />
        </Col>
        <Col sm={3}>
          <SaveButton onClick={addRegister} type='button'>
            + Agregar registro
          </SaveButton>
        </Col>
      </FormGroup> */}
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Compensación en incidencias</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Número de periodos*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            name='numeroPeriodos'
            placeholder='Introduzca la número periodos'
            ref={register()}
          />
          {err.numeroPeriodos && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número periodos es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >IMSS</SubTitleMenu>
        </Col>
      </FormGroup>

      <FormGroup>
        {registros.length > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>Registro Patronal</th>
                <th>Delegación IMSS</th>
                <th>Subdelegación</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {registros.map(
                (registro, index) => (
                  <tr key={index}>
                    <td>{registro.registroPatronal}</td>
                    <td>{registro.delegacionIMSS}</td>
                    <td>{registro.subdelegacionIMSS}</td>
                    <th>
                      <FontAwesomeIcon icon={faTrash} onClick={() => setRegistros(registros.filter(r => r !== registro))} style={{cursor: 'pointer'}}/>
                    </th>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : null}
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Registro Patronal</LabelForms>
        </Col>
        <Col sm={5}>
          <InputUtils
              type='text'
              name='registroPatronal'
              placeholder='Introduzca el registro patronal'
              value={registroPatronal.registroPatronal}
              onChange={e => setRegistroPatronal({...registroPatronal, registroPatronal: e.target.value})}
            />
          </Col>
        </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Delegación</LabelForms>
        </Col>
        <Col sm={5}>
        <CustomSelect
              type='select'
              name='delegacionIMSS'
              id='delegacionIMSS'
              value={registroPatronal.delegacionIMSS}
              options={delegacionesIMSSOptions}
              control={control}
              onChange={(e) => changeDelegacion(e.target.value)}
            />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Subdelegación</LabelForms>
        </Col>
        <Col sm={5}>
        <CustomSelect
              type='select'
              name='subdelegacionIMSS'
              control={control}
              value={registroPatronal.subdelegacionIMSS}
              options={subdelegacionesIMSSOptions}
              onChange={e=> setRegistroPatronal({...registroPatronal, subdelegacionIMSS: e.target.value})}
            />   
        </Col>
      </FormGroup>
      <FormGroup row>
          <Col sm={3} className='text-center'>
            <CancelButton type='button' onClick={addRegistroPatronal}>
              + Agregar
            </CancelButton>
          </Col>
        </FormGroup>

      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Contrato colectivo de trabajo</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <InputFile
            id='contratoColectivo'
            name='documentos.contratoColectivo'
            register={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Reglamento interior de trabajo</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <InputFile
            id='reglamentoInterioTrabajo'
            name='documentos.reglamentoInterioTrabajo'
            register={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Registro Federal de contribuyentes</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <InputFile
            id='registroFederalContribuyentes'
            name='documentos.registroFederalContribuyentes'
            register={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Comprobrante de domicilio</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <InputFile
            id='comprobanteDomicilio'
            name='documentos.comprobanteDomicilio'
            register={register()}
          />
        </Col>
      </FormGroup>
      <br /><br />
      <FormGroup row>
        <Col sm={{ offset: 7, size: 5 }}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default RegistroEmpresa;
