const gradoEstudiosOptions = [
    {label:'Ninguno', value:'Ninguno'},
    {label:'Primaria', value:'Primaria'},
    {label:'Secundaria', value:'Secundaria'},
    {label:'Bachillerato', value:'Bachillerato'},
    {label:'Licenciatura', value:'Licenciatura'},
    {label:'Posgrado', value:'Posgrado'}
];

export default gradoEstudiosOptions;
