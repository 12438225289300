import React from 'react';
import ComponentDescrip from "../ui/ComponentDescrip";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import {Container, Row, Col} from "reactstrap";
const NuevaSolicitud = () =>{
    const title= 'Nueva Solicitud';
    const icon= faUsers;
    const  description= ' Puedes llenar una nueva solicitud'
    return (
        <Container>
            <Row>
                <Col>
                    <ComponentDescrip title={title} icon={icon} description={description}/>

                </Col>
            </Row>
        </Container>
    )
}
export default NuevaSolicitud;