export const AUDIENCIAS_START = 'AUDIENCIAS_START';

export const AUDIENCIAS_LISTA_EXITO = 'AUDIENCIAS_LISTA_EXITO';
export const AUDIENCIAS_LISTA_ERROR = 'AUDIENCIAS_LISTA_ERROR';
export const CREAR_AUDIENCIAS_EXITO = 'CREAR_AUDIENCIAS_EXITO';
export const CREAR_AUDIENCIAS_ERROR = 'CREAR_AUDIENCIAS_ERROR';
const initialState = {
  loading: false,
  error: null,
  listaAudiencias: [],
  audiencia: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUDIENCIAS_START:
      return { ...state, loading: true };
    case AUDIENCIAS_LISTA_EXITO:
      return { ...state, loading: false, listaAudiencias: [...payload] };
    case AUDIENCIAS_LISTA_ERROR:
      return { ...state, loading: false };
    case CREAR_AUDIENCIAS_EXITO:
      return { ...state, loading: false };
    case CREAR_AUDIENCIAS_ERROR:
      return { ...state, loading: false };
    case 'AGREGAR_AUDIENCIA':
      return { ...state, audiencia: { ...payload } };
    default:
      return state;
  }
};

export const audienciasStart = () => ({
  type: AUDIENCIAS_START,
});

export const listaAudienciasExito = (payload) => ({
  type: AUDIENCIAS_LISTA_EXITO,
  payload,
});

export const listaAudienciasError = () => ({
  type: AUDIENCIAS_LISTA_ERROR,
});

export const createAudienciasExito = () => ({
  type: CREAR_AUDIENCIAS_EXITO,
});

export const createAudienciasError = () => ({
  type: CREAR_AUDIENCIAS_ERROR,
});

export const agregarAudiencia = (payload) => ({
  type: 'AGREGAR_AUDIENCIA',
  payload,
});

export const fetchListaAudiencias = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(audienciasStart());
    const {
      data: { resultado = [] },
    } = await axios.get(`/audiencias/por_demanda/${id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    console.log(resultado);
    dispatch(listaAudienciasExito(resultado));
  } catch (e) {
    console.log(e);
    dispatch(listaAudienciasError());
    throw e;
  }
};

export const fetchCreateAudiencias = (dataAudiencias) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(audienciasStart());
    const {
      documento = {},
      ...rest
    } = dataAudiencias;
    const formData = new FormData();
    formData.append('documento', documento[0]);
    formData.append(
      'dataAudiencias',
      JSON.stringify({ ...rest})
    );
    console.log(dataAudiencias);
    const {
      data: { resultado },
    } = await axios.post('/audiencias', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createAudienciasExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createAudienciasError());
    throw data;
  }
};

export const fetchUpdateAudiencias = (dataAudiencias) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(audienciasStart());
  try {
    const {
      documento = {},
      ...rest
    } = dataAudiencias;
    const formData = new FormData();
    formData.append('documento', documento[0]);
    formData.append(
      'dataAudiencias',
      JSON.stringify({ ...rest})
    );
    console.log(dataAudiencias);
    const {
      data: { resultado },
    } = await axios.put(`/audiencias`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(createAudienciasExito());
  } catch (e) {
    console.log(e);
    dispatch(createAudienciasError());
    throw e;
  }
};

// export const fetchActualizarVacaciones = (dataVacaciones) => async (
//   dispatch,
//   getState,
//   { axios }
// ) => {
//   try {
//     dispatch(vacacionesStart());
//     const {
//       constancia = {},
//       id,
//       ...rest
//     } = dataVacaciones;
//     const formData = new FormData();
//     formData.append('constancia', constancia[0]);
//     formData.append(
//       'dataVacaciones',
//       JSON.stringify({ ...rest})
//     );
//     const {
//       data: { resultado },
//     } = await axios.put(`/vacaciones/editar_vacacion/${id}`, formData, {
//       headers: {
//         token: localStorage.getItem('pv2token'),
//         'Content-Type': 'multipart/form-data',
//       },
//     });
//     dispatch(createVacacionesExito());
//   } catch (e) {
//     const { response } = e;
//     const { request, ...errorObject } = response;
//     const { data } = errorObject;
//     dispatch(createVacacionesError());
//     throw data;
//   }
// };

export const fetchDeleteAudiencia = (id) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(audienciasStart());
    await axios.delete(`/audiencias/${id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createAudienciasExito());
  } catch (e) {
    // const { response } = e;
    // const { request, ...errorObject } = response;
    // const { data } = errorObject;
    console.log(e);
    dispatch(createAudienciasError());
    throw e;
  }
};
