const perfil_routes = [
  {
    path: '/mi-perfil',
    name: 'Mi perfil',
  },
  {
    path: '/mis-documentos',
    name: 'Mis documentos',
  },
  {
    path: '/recibos-nomina',
    name: 'Mis recibos de nómina',
  },
  {
    path: '/vacaciones',
    name: 'Vacaciones',
  },
];
export default perfil_routes;
