import React from "react";
import { Route } from "react-router-dom";
import Roles from "../../../components/Roles/Roles";
import DetalleRol from "../../../components/Roles/DetalleRol";
import AgregarRol from "../../../components/Roles/AgregarRol";
import Usuarios from "../../../components/Users/Usuarios";
import AddUser from "../../../components/Users/AddUser";
import AsignarRol from "../../../components/Roles/AsignarRol";
import Reportes from "../../../components/Reportes/Reportes";
import Metricas from "../../../pages/Metricas/Metricas";

const EmpresaRoutes = ({ path }) => {
  return (
    <>
      <Route path={`${path}/detalle-rol/:idRol`} component={DetalleRol} />
      <Route path={`${path}/agrega-rol`} component={AgregarRol} />
      <Route path={`${path}/rol`} component={Roles} />
      <Route path={`${path}/usuarios`} component={Usuarios} />
      <Route path={`${path}/agrega-usuario`} component={AddUser} />
      <Route path={`${path}/asignar-rol/:idRol`} component={AsignarRol} />
      <Route path={`${path}/reportes`} component={Reportes} />
      <Route path={`${path}/metricas`} component={Metricas} />
    </>
  );
};
export default EmpresaRoutes;
