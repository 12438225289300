import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { updateColaborador } from '../../../../store/colaboradorDucks';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Label,
  Col,
  Input,
  FormGroup,
  CustomInput,
  Table,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import CustomSelect from '../../../ui/CustomSelect';
import optionsContract from '../../../../constans/tipoContratos';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaEditInformacionLaboral } from '../../../../constans/yupSchemas';
import colors from '../../../../constans/colors';
import { CancelButton, SaveButton, InputUtils } from '../../../ui/styles/styles';
import CustomSelectEstados from '../../../ui/CustomSelectEstados';
import SweetAlert from '../../../ui/SweetAlet';
import CurrencyFormat from 'react-currency-format';
import '../CondicionesLaborales.css';
import axios from '../../../../services/axios';
import atob from 'atob';
import { saveAs } from 'file-saver';

const api = process.env.REACT_APP_API_URL;

const FormEditCondicionesLaborales = React.memo(() => {

  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const { permisos = {} } = useSelector((state) => state.user);
  const { condicionesLaborales = {}, _id, estatus } = useSelector(
    (state) => state.colaborador
  );
  const { empresa } = useSelector(
    (state) => state.colaborador.pertenencia
  );
  const {
    numeroEmpleado = null,
    puesto = '',
    lugarTrabajo = {},
    telefono = null,
    extension = '',
    tipoContrato = '',
    fechaIngreso = '',
    rfc = '',
    correoInstitucional = '',
    jefeInmediato = '',
    salarioMensualBruto = '',
    horarioTrabajo = [],
    beneficiarios = [],
  } = condicionesLaborales;


  const {
    direccion = '',
    estado = '',
    ciudad = '',
    colonia = '',
    calle = '',
    numeroExterior = null,
  } = lugarTrabajo;
  const [horario, setHorario] = useState(horarioTrabajo);
  const [listaBeneficiarios, setListaBeneficiarios] = useState(beneficiarios);
  const [variableHorario, setVariableHorario] = useState({
    end: false,
    start: false,
  });
  const [salario, setSalario] = useState({ salarioMensual: salarioMensualBruto });
  const [isOpen, setIsOpen] = useState(direccion === 'Otro' ? true : false);
  const dispatch = useDispatch();
  const [rfc2, setRFC] = useState(rfc);
  const [rfcError, setRFCError] = useState('');
  const [rfcValid, setRFCValid] = useState(false);
  const [carta, setCarta] = useState({ puestoFirmante: '', firmante: '', email: '' });
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schemaEditInformacionLaboral),
  });

  const { salarioMensual } = salario;
  const [err, setErr] = useState({});
  useEffect(() => {
    setErr({})
    if (Object.keys(errors).length) {
      if (!salario.salarioMensual) errors.salarioMensual = 'Salario mensual bruto requerido'
      if (!rfc2 || rfc2 === ' ') setRFCError('RFC es requerido')
      setErr(errors);
    }
  }, [errors]);

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    errors: errors2,
    setValue: setValue2,
    getValues: getValues2,
    reset: reset2,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = async (data) => {
    try {
      await validateRFC()
      if (errors.rfc) return
      data.rfc = rfc2
      data.salarioMensualBruto = salario.salarioMensual;
      data.horarioTrabajo = horario;
      data.beneficiarios = listaBeneficiarios;
      data.fechaIngreso = parseInt(moment(data.fechaIngreso).format('x'));
      const updateData = { condicionesLaborales: { ...data } };
      await dispatch(updateColaborador(updateData));
      await SweetAlert({
        icon: 'success',
        title: 'Condiciones Laborales Agregada Exitosamente',
      });
      return updateData
    } catch (error) {
      await SweetAlert({ icon: "error", title: "Error!", text: error });
      console.log(error);
    }
  };
  const onSubmit2 = (data) => {
    console.log(data);
  };
  const addingForm = useCallback(() => {
    const { horarioTrabajo } = getValues();
    const cleanInputData = {
      days: [],
      end: '',
      start: ''
    }
    setHorario((state) => [...state, horarioTrabajo]);
    setValue('horarioTrabajo', cleanInputData, { shouldDirty: true });
  }, [getValues]);
  const addingBeneficiario = useCallback(() => {
    const { beneficiarios } = getValues();
    setListaBeneficiarios((state) => [...state, beneficiarios]);
    const cleanInputData = {
      ciudad: '',
      domicilio: '',
      email: '',
      estado: '',
      nombre: '',
      parentesco: '',
      telefonoCelular: '',
      telefonoFijo: ''
    }
    setValue('beneficiarios', cleanInputData, { shouldDirty: true });
  }, [getValues]);
  const deleteHorario = (index) => {
    setHorario(horario.filter((rep, i) => i !== index))
  };
  const deleteBeneficiarios = (index) => {
    setListaBeneficiarios(listaBeneficiarios.filter((rep, i) => i !== index))
  };
  const validateRFC = async () => {
    const { data: { res } } = await axios.get(`/colaborador/validar-rfc/${rfc2}/${empresa}`, {
      headers: {
        token: localStorage.getItem("pv2token"),
        "Content-Type": "multipart/form-data",
      },
    });
    if (res && res.ResultConsultaRFC && res.ResultConsultaRFC.rfc) {
      const rfcResult = res.ResultConsultaRFC.rfc
      if (rfcResult.codigo !== '200') {
        errors.rfc = rfcResult.detalle
        setRFCError(rfcResult.detalle)
        setErr(errors);
        setRFCValid(false)
      } else {
        setRFCError('')
        setRFCValid(true)
      }
    }
  };

  const downloadPdf = async (e) => {
    e.preventDefault();
    if(carta.email === '' || carta.firmante === '' || carta.puestoFirmante === ''){
      SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Por favor, especifíque el emisor, puesto y correo electrónico antes de generar la carta de trabajo."
      })
      return;
    }
    // await validateRFC()
    if (schemaEditInformacionLaboral.isValidSync(getValues())) {
      const save = await onSubmit(getValues())
      if (save) {
        // setUtilitario(save)
        const file = await axios.get(`${api}/colaborador/obtener_carta_trabajo/${_id}?puestoFirmante=${carta.puestoFirmante}&firmante=${carta.firmante}&email=${carta.email}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `CartaTrabajo${moment().format('YYYYMMDD')}`)
      }
    } else {
      SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label for='numeroEmpleado' sm={3}>
            Número de Empleado*
          </Label>
          <Col sm={9}>
            <Input
              defaultValue={numeroEmpleado}
              type='text'
              name='numeroEmpleado'
              placeholder='Número de empleado'
              innerRef={register()}
            />
            {err.numeroEmpleado && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El número empleado es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='puesto' sm={3}>
            Puesto*
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='puesto'
              defaultValue={puesto}
              placeholder='Nombre del puesto en la sucursal'
              innerRef={register()}
            />
            {err.puesto && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El puesto es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='direccion' sm={3}>
            Dirección
          </Label>
          <Col sm={9}>
            <CustomSelect
              control={control}
              options={[
                { value: 'Sucursal', label: 'Sucursal' },
                { value: 'Personal', label: 'Personal' },
                { value: 'Otro', label: 'Otro' },
              ]}
              type='select'
              defultValue={direccion}
              name='lugarTrabajo.direccion'
              innerRef={register()}
              onChange={(e) => { e.target.value === 'Otro' ? setIsOpen(true) : setIsOpen(false) }}
            />
          </Col>
        </FormGroup>
        {
          isOpen && (
            <>
              <FormGroup row>
                <Label for='lugarTrabajo.estado' sm={3}>
                  Estado
          </Label>
                <Col sm={9}>
                  <CustomSelectEstados
                    type='select'
                    name='lugarTrabajo.estado'
                    id='lugarTrabajo.estado'
                    defaultValue={estado}
                    control={control}
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.ciudad' sm={3}>
                  Ciudad
          </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.ciudad'
                    defaultValue={ciudad}
                    placeholder='Ciudad donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.colonia' sm={3}>
                  Colonia
          </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.colonia'
                    defaultValue={colonia}
                    placeholder='Colonia donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='lugarTrabajo.calle' sm={3}>
                  Calle
          </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.calle'
                    defaultValue={calle}
                    placeholder='Calle donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='calle' sm={3}>
                  Número Exterior
          </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='lugarTrabajo.numeroExterior'
                    defaultValue={numeroExterior}
                    placeholder='Numero exterior donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='telefono' sm={3}>
                  Teléfono*
          </Label>
                <Col sm={9}>
                  <Input
                    type='number'
                    name='telefono'
                    defaultValue={telefono}
                    placeholder='Teléfono donde trabaja'
                    innerRef={register()}
                  />
                  {err.telefono && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                      El teléfono es requerido
                    </span>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for='extension' sm={3}>
                  Extensión
          </Label>
                <Col sm={9}>
                  <Input
                    type='text'
                    name='extension'
                    defaultValue={extension}
                    placeholder='Extension del teléfono donde trabaja'
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            </>
          )
        }
        <FormGroup row>
          <Label for='tipoContrato' sm={3}>
            Tipo Contrato*
          </Label>
          <Col sm={9}>
            <CustomSelect
              type='select'
              name='tipoContrato'
              defultValue={tipoContrato}
              control={control}
              options={optionsContract}
              placeholder='Tipo contrato donde trabaja'
              innerRef={register()}
            />
            {err.tipoContrato && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El tipo de contrato es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='fechaIngreso' sm={3}>
            Fecha de Ingreso*
          </Label>
          <Col sm={4} md={3}>
            <Input
              type='date'
              name='fechaIngreso'
              defaultValue={moment(fechaIngreso).format('YYYY-MM-DD')}
              placeholder='Fecha ingreso donde trabaja'
              innerRef={register()}
            />
            {err.fechaIngreso && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de ingreso es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='rfc' sm={3}>
            RFC*
          </Label>
          <Col sm={3}>
            <Input
              type='text'
              name='rfc'
              defaultValue={rfc}
              placeholder='RFC del colaborador'
              innerRef={register()}
              onChange={(e) => { setRFC(e.target.value === '' ? ' ' : e.target.value) }}
            />
            {!rfcValid && rfcError !== '' && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                {rfcError}
              </span>
            )}
            {rfcValid && (
              <span style={{ color: "#2b388f", marginLeft: 10, fontSize: 12 }}>
                RFC válido
              </span>
            )}
          </Col>
          <Col sm={3}>
            <SaveButton
              type='button'
              onClick={() => { validateRFC(); }}
            >Validar</SaveButton>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='correoInstitucional' sm={3}>
            Correo Institucional
          </Label>
          <Col sm={9}>
            <Input
              type='email'
              name='correoInstitucional'
              defaultValue={correoInstitucional}
              placeholder='Correo institucional'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='jefeInmediato' sm={3}>
            Jefe Inmediato
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='jefeInmediato'
              defaultValue={jefeInmediato}
              placeholder='Jefe inmediato del colaborador'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='salarioMensualBruto' sm={3}>
            Salario Mensual Bruto*
          </Label>
          <Col sm={9}>
            <CurrencyFormat
              fixedDecimalScale={true}
              decimalScale={2}
              className='inputMoneda'
              name='salarioMensualBruto'
              value={salarioMensual}
              placeholder='Salario mensual bruto'
              innerRef={register()}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                // formattedValue = $2,223
                // value ie, 2223
                setSalario({ salarioMensual: value })
              }}
            />
            {err.salarioMensual && !salario.salarioMensual && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El salario mensual bruto es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <h5>Horario Laboral</h5>
          {horario.length > 0 ? (
            <Table borderless style={{ marginLeft: '0.2rem' }}>
              <thead>
                <tr>
                  <th>Entrada</th>
                  <th>Salida</th>
                  <th>Dias</th>
                </tr>
              </thead>
              <tbody>
                {horario.map(
                  (
                    { start = 'variable', end = 'variable', days },
                    index
                  ) => (
                    <tr key={start + end + index}>
                      <td>{start}</td>
                      <td>{end}</td>
                      <td>{days.length > 0 ? days.join(' ') : ''}</td>
                      <td><FontAwesomeIcon
                        cursor="pointer"
                        onClick={() => deleteHorario(index)}
                        icon={faTrash}
                      /></td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          ) : null}
        </FormGroup>
        <FormGroup row>
          <Label for='horarioEntrada' sm={3}>
            Horario Entrada
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            {variableHorario.start ? null : (
              <Input
                type='time'
                name='horarioTrabajo.start'
                innerRef={register}
              />
            )}
          </Col>
          <Col sm={3}>
            <CustomInput
              value={true}
              id='varialbe1'
              type='checkbox'
              label='Variable'
              name='start'
              onChange={() => {
                setVariableHorario((state) => ({
                  ...state,
                  start: !state.start,
                }));
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='horarioSalida' sm={3}>
            Horario Salida
          </Label>
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            {variableHorario.end ? null : (
              <Input
                type='time'
                name='horarioTrabajo.end'
                innerRef={register}
              />
            )}
          </Col>
          <Col sm={3}>
            <CustomInput
              value={true}
              id='varialbe2'
              type='checkbox'
              label='Variable'
              name='end'
              onChange={() => {
                setVariableHorario((state) => ({ ...state, end: !state.end }));
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup className='col-sm-12' row inline>
          <CustomInput
            className='mr-3'
            id='lunes'
            name='horarioTrabajo.days'
            value={'L'}
            type='checkbox'
            label='L'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='martes'
            name='horarioTrabajo.days'
            value={'M'}
            type='checkbox'
            label='M'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='miercoles'
            name='horarioTrabajo.days'
            type='checkbox'
            value={'MI'}
            label='MI'
            innerRef={register}
          />
          <CustomInput
            className='mr-1'
            id='jueves'
            name='horarioTrabajo.days'
            value={'J'}
            type='checkbox'
            label='J'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='viernes'
            name='horarioTrabajo.days'
            value={'V'}
            type='checkbox'
            label='V'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='sabado'
            name='horarioTrabajo.days'
            value={'S'}
            type='checkbox'
            label='S'
            innerRef={register}
          />
          <CustomInput
            className='mr-3'
            id='domingo'
            name='horarioTrabajo.days'
            value={'D'}
            type='checkbox'
            label='D'
            innerRef={register}
          />
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <CancelButton type='button' onClick={addingForm}>
              Agregar Horario
            </CancelButton>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={12}>
            {listaBeneficiarios.length > 0 ? (
              <Table striped>
                <thead>
                  <tr>
                    <th>Beneficiario</th>
                    <th>Parentesco</th>
                    <th>Email</th>
                    <th>Eliminar</th>
                  </tr>
                </thead>
                <tbody>
                  {listaBeneficiarios.length > 0
                    ? listaBeneficiarios.map(
                      ({ nombre = '', parentesco = '', email = '' }, index) => (
                        <tr key={nombre + index}>
                          <td>{nombre}</td>
                          <td>{parentesco}</td>
                          <td>{email}</td>
                          <td><FontAwesomeIcon
                            color={colors.tashColor}
                            cursor="pointer"
                            onClick={() => deleteBeneficiarios(index)}
                            icon={faTrash}
                          /></td>
                        </tr>
                      )
                    )
                    : null}
                </tbody>
              </Table>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row>
          <h5>Designación de Beneficiarios</h5>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.nombre' sm={3}>
            Nombre
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.nombre'
              placeholder='Nombre del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.domicilio' sm={3}>
            Domicilio
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.domicilio'
              placeholder='Domicilio del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.estado' sm={3}>
            Estado
          </Label>
          <Col sm={9}>
            <CustomSelectEstados
              type='select'
              name='beneficiarios.estado'
              id='beneficiarios.estado'
              control={control}
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.ciudad' sm={3}>
            Ciudad
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.ciudad'
              placeholder='Ciudad de residencia del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.telefonoCelular' sm={3}>
            Teléfono Celular
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.telefonoCelular'
              placeholder='Numero teléfono celular beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.telefonoFijo' sm={3}>
            Teléfono Fijo
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.telefonoFijo'
              placeholder='Numero teléfono fijo beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.parentesco' sm={3}>
            Parentesco
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.parentesco'
              placeholder='Parentesco con el beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='beneficiarios.email' sm={3}>
            Correo Electrónico
          </Label>
          <Col sm={9}>
            <Input
              type='text'
              name='beneficiarios.email'
              placeholder='Correo electrónico del beneficiario'
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={3} className='text-center'>
            <CancelButton type='button' onClick={addingBeneficiario}>
              Agregar Beneficiarios
          </CancelButton>
          </Col>
        </FormGroup>
        { estatus === 'completado' ? <>
          <FormGroup row>
            <h5>Carta de Trabajo </h5>
          </FormGroup>

          <FormGroup row>
            <Label for='carta.firmante' sm={3}>
              Emisor
            </Label>
            <Col sm={9}>
              <Input
                type='text'
                value={carta.firmante}
                onChange={e => setCarta({...carta, firmante: e.target.value})}
                placeholder='Nombre Completo'
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='carta.puestoEmpisor' sm={3}>
              Puesto Emisor
            </Label>
            <Col sm={9}>
              <Input
                type='text'
                value={carta.puestoFirmante}
                onChange={e => setCarta({...carta, puestoFirmante: e.target.value})}
                placeholder='Puesto del emisor'
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label for='carta.email' sm={3}>
              Correo Electrónico
            </Label>
            <Col sm={9}>
              <Input
                type='text'
                value={carta.email}
                onChange={e => setCarta({...carta, email: e.target.value})}
                placeholder='Correo electrónico del emisor'
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3} className='mb-3'>
              <SaveButton onClick={(e) => downloadPdf(e)}>Generar Carta Trabajo</SaveButton>
            </Col>
          </FormGroup>
        </> :''}

        <FormGroup hidden={permisos.colaboradorLaborales !== 'write'}>
          <Col sm={{ offset: 9, size: 5 }} className='text-center'>
            <SaveButton
              type='submit'
              onClick={() => {
                setValue('horarioTrabajo', horario);
              }}
            >Guardar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
});

export default FormEditCondicionesLaborales;
