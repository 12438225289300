import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { TitleMenu, SubTitleMenu, SaveButton, MessageAddUtils } from '../ui/styles/styles';
import { ContainerReportes } from './styles/styles';
import CustomSelect from '../ui/CustomSelect';
import Spiner from '../ui/Spiner';
import PaginationComponent from 'react-reactstrap-pagination';
import './styles/PaginationComponent.css';
import SweetAlert from '../ui/SweetAlet';
import InputFile from "../ui/InputFile";
import moment from "moment";
import {getDetalleConciliacion} from "../../store/empresasDuck";
import axios from '../../services/axios';
import TableConciliaciones from './TableConciliaciones';
import Comparacion from './Comparacion';

const mensual = [
  { value: 'Enero', label: 'Enero' },
  { value: 'Febrero', label: 'Febrero' },
  { value: 'Marzo', label: 'Marzo' },
  { value: 'Abril', label: 'Abril' },
  { value: 'Mayo', label: 'Mayo' },
  { value: 'Junio', label: 'Junio' },
  { value: 'Julio', label: 'Julio' },
  { value: 'Agosto', label: 'Agosto' },
  { value: 'Septiembre', label: 'Septiembre' },
  { value: 'Octubre', label: 'Octubre' },
  { value: 'Noviembre', label: 'Noviembre' },
  { value: 'Diciembre', label: 'Diciembre' }
];

const bimestral = [
  { value: 'Enero-Febrero', label: 'Enero-Febrero' },
  { value: 'Marzo-Abril', label: 'Marzo-Abril' },
  { value: 'Mayo-Junio', label: 'Mayo-Junio' },
  { value: 'Julio-Agosto', label: 'Julio-Agosto' },
  { value: 'Septiembre-Octubre', label: 'Septiembre-Octubre' },
  { value: 'Noviembre-Diciembre', label: 'Noviembre-Diciembre' }
];
const api = process.env.REACT_APP_API_URL;

let loadingFirst = true;
const Reportes = () => {
  const dispatch = useDispatch();
  const { empresas, loading, reportes = {},registrosPatronales=[] } = useSelector(
    (state) => state.empresas
  );
  const [dataBody, setDataBody] = useState([]);
  const [anos,setAnos] = useState([]);
  const [page, setPage] = useState(1);
  const [periodoMes, setPeriodoMes] = useState(false);
  const [mostrarFechas, setMostrarFechas] = useState(false);
  const [periodoBimestre, setPeriodoBimestre] = useState(false);
  const [showPaginador, setPaginador] = useState(false);
  const [tipoReporte, setTipoReporte] = useState('');
  const [isReporteSelected, setReporteSelected] = useState(false)
  const [reporteDto, setReporteDto] = useState({});
  const { register, control, reset, getValues, handleSubmit } = useForm(
    {
      resolver: yupResolver(reportesSchema),
    }
  );
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
      'Content-Type': 'multipart/form-data',
    },
  };
  const [excludedIds, setExcludedIds] = useState([]);

  const fetchConciliaciones = async() =>{
    const { data } = await axios.get(`${api}/conciliacion/${tipoReporte}`, headers);
    setDataBody(data.response);
  }

  useEffect(() => {
    setReporteSelected(false);
    fetchConciliaciones();
  },[tipoReporte]);
  
  useEffect(() => {
    setReporteSelected(true)
  },[reporteDto]);

  useEffect(() => {
    let thisYear = moment().format('YYYY');
    const min = 0;
    const max = 60;

    for (let index = min; index <= max; index++) {
      let year = thisYear - index;
      anos.push({label:year,value:year});
    }
  }, [])


  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEmpresasToDropdown());
      } catch (error) { }
    })();
    loadingFirst = false;
  }, [dispatch]);

  if (loadingFirst) {
    return <Spiner />;
  }

  const eliminarConciliacion = async id =>{
    const { data } = await axios.delete(`${api}/conciliacion/${id}`, headers);
    await SweetAlert({ title: 'Conciliacion eliminada', icon: 'success' });
    fetchConciliaciones();
  }

  const onSubmit = async (objeto) => {
    try {
      const formData = new FormData();
      const {CDEMAS99,CDEMMO99,CDEBAS99,CDEBMO99,...rest} = objeto;
      
      if(objeto.nombreReporte === '' || objeto.mes === '' || objeto.empresa === '' || objeto.registro === '' || objeto.ano === '') {
        await SweetAlert({ title: 'Los campos marcados con * son obligatorios', icon: 'warning' })
        return;
      }
      
      if( objeto.nombreReporte === 'EMA-IMSS' && CDEMAS99[0] === undefined) {
        await SweetAlert({ title: 'Seleccione el archivo CDEMAS99 para continuar', icon: 'warning' })
        return;
      }

      if( objeto.nombreReporte === 'EMA-IMSS' && CDEMMO99[0] === undefined) {
        await SweetAlert({ title: 'Seleccione el archivo CDEMMO99 para continuar', icon: 'warning' })
        return;
      }

      if( objeto.nombreReporte === 'EBA-INFONAVIT' && CDEBAS99[0] === undefined) {
        await SweetAlert({ title: 'Seleccione el archivo CDEBAS99 para continuar', icon: 'warning' })
        return;
      }

      if( objeto.nombreReporte === 'EBA-INFONAVIT' && CDEBMO99[0] === undefined) {
        await SweetAlert({ title: 'Seleccione el archivo CDEBMO99 para continuar', icon: 'warning' })
        return;
      }

      if (objeto.nombreReporte === 'EMA-IMSS') {
        formData.append('CDEMAS99', CDEMAS99[0]);
        formData.append('CDEMMO99', CDEMMO99[0]);
        formData.append('conciliacion', JSON.stringify(rest));
        const { data } = await axios.post(`${api}/conciliacion/imss`,formData, headers);
        await SweetAlert({ title: 'Conciliacion EMA-IMSS procesada', icon: 'success' })
        fetchConciliaciones()
        register({})
      }if (objeto.nombreReporte === 'EBA-INFONAVIT') {
        formData.append('CDEBAS99', CDEBAS99[0]);
        formData.append('CDEBMO99', CDEBMO99[0]);
        formData.append('conciliacion', JSON.stringify(rest));
        const { data } = await axios.post(`${api}/conciliacion/infonavit`,formData, headers)
        await SweetAlert({ title: 'Conciliacion EBA-INFONAVIT procesada', icon: 'success' })
        fetchConciliaciones()

      }
    } catch (error) { 
      console.log(error);
    }
  };

  const seleccionarReporte = (reporte) => {
    setReporteDto(reporte);
    setReporteSelected(true);
  }

  const tipos = [
    { value: 'EMA-IMSS', label: 'EMA-IMSS' },
    { value: 'EBA-INFONAVIT', label: 'EBA-INFONAVIT' }
  ];

  const onChange = ({ target }) => {
    setMostrarFechas(true)
    let valor = target.value;
    setTipoReporte(valor);
    if (!valor) {
      setPeriodoMes(false);
      setPeriodoBimestre(false);
    }
    if(valor === 'EMA-IMSS'){
      setPeriodoMes(true);
      setPeriodoBimestre(false);
    }
    if(valor === 'EBA-INFONAVIT'){
      setPeriodoBimestre(true);
      setPeriodoMes(false);
    }
  };

  const changeEmpresa = async ({target}) => {
    let valor = target.value;
    if (valor !== '') {
      try {
        await dispatch(getDetalleConciliacion(valor));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <ContainerReportes>
      <div style={{ minHeight: '15vh' }}>
        <TitleMenu>Conciliación</TitleMenu>
        <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
          Genera la conciliación del IMSS e INFONAVIT
        </SubTitleMenu>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
        <Label for='reporte' sm={1}> Reporte* </Label>
          <Col sm={2}>
            <CustomSelect
              type='select'
              name='nombreReporte'
              id='reporte'
              control={control}
              innerRef={register({ required: true })}
              options={tipos}
              onChange={onChange}
            />
          </Col>

          <Label for='empresa' sm={1}> Empresa* </Label>
          <Col sm={4}>
            <CustomSelect
              type='select'
              name='empresa'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={empresas}
              onChange={changeEmpresa}
            />
          </Col>

          <Label for='registro' sm={1}> Registro* </Label>
          <Col sm={2}>
            <CustomSelect
              type='select'
              name='registro'
              id='registro'
              control={control}
              innerRef={register()}
              options={registrosPatronales}
            />
          </Col>
          </FormGroup>
          
          <FormGroup row>
          <Col sm={1}>
            <Label for='mes' sm={1} style={{paddingLeft: 0}}>{periodoMes ? 'Mes*' : 'Bimestre*'} </Label>
          </Col>
          <Col sm={2}>
            <CustomSelect
              type='select'
              name='mes'
              id='mes'
              control={control}
              innerRef={register()}
              options={periodoMes ? mensual:bimestral}
            />
          </Col>
          <Col sm={1}>
            <Label for='empresa' sm={1} style={{paddingLeft: 0}}> Año* </Label>
          </Col>
          <Col sm={2}>
            <CustomSelect
              type='select'
              name='ano'
              id='ano'
              control={control}
              innerRef={register()}
              options={anos}
            />
          </Col>
          {/* </>:null} */}
        </FormGroup>

        <FormGroup row>
          {periodoMes ?
          <>
          <Label sm={1}>
            CDEMAS99*
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEMAS99"
              name="CDEMAS99"
              register={register}
            />
          </Col>
          <Label sm={1}>
            CDEMMO99*
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEMMO99"
              name="CDEMMO99"
              register={register}
            />
          </Col>
          <Label sm={1}>
          CDEMPA99
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEMPA99"
              name="CDEMPA99"
              register={register}
            />
          </Col>
          
          </>
          :null}
          {periodoBimestre ?
          <>
          <Label sm={1}>
            CDEBAS99*
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEBAS99"
              name="CDEBAS99"
              register={register}
            />
          </Col>
          <Label sm={1}>
            CDEBMO99*
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEBMO99"
              name="CDEBMO99"
              register={register}
            />
          </Col>
          <Label sm={1}>
            CDEBPA99
          </Label>
          <Col sm={2}>
            <InputFile
              type="file"
              id="CDEBPA99"
              name="CDEBPA99"
              register={register}
            />
          </Col>
          
          </>
          :null}
        </FormGroup>
        <FormGroup row>
          <Col sm={3}>
            <SaveButton type='submit'>Procesar</SaveButton>
          </Col>
        </FormGroup>
        
      </Form>


      <FormGroup row>
        <h5>{tipoReporte}</h5>
      </FormGroup>
      {dataBody && dataBody.length > 0 ?
        (<Row style={{marginTop: '-60px'}}>
          <Col lg={12}>
            {loading ? <Spiner /> : <TableConciliaciones lista={ dataBody } action={reporteDto} setAction={setReporteDto} eliminarAction={eliminarConciliacion} />}
          </Col>
        </Row>)
        : 
        <MessageAddUtils>No se han importado conciliaciones {tipoReporte}</MessageAddUtils>
      }

      {tipoReporte && isReporteSelected ? (
        <>
          <FormGroup row>
            <h5>Comparación</h5>
          </FormGroup>
          <Comparacion loading={loading} tipoReporte={tipoReporte} listas={{noExisteEmpresa: reporteDto.noExisteEmpresa, noExisteImss: reporteDto.noExisteImss, diferencias: reporteDto.diferencias, existe: reporteDto.existe}} />
        </>
      ) : 
        <MessageAddUtils>Seleccione una conciliación</MessageAddUtils>
      }
    </ContainerReportes>
  );
};

export default Reportes;
