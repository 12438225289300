import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Col, CustomInput, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import getTxt from '../../services/dowloadTxt';
import { fetchEmpresasToDropdown, fetchreport, cleanReportes } from '../../store/empresasDuck';
import { TitleMenu, SubTitleMenu, CancelButton, SaveButton } from '../ui/styles/styles';
import { ContainerReportes } from './styles/styles';
import CustomSelect from '../ui/CustomSelect';
import TableDinamic from '../ui/TableDinamicDelete';
import modulos from '../../constans/modulos';
import radiosReportes from '../../constans/radiosReportes';
import Spiner from '../ui/Spiner';
import PaginationComponent from 'react-reactstrap-pagination';
import './styles/PaginationComponent.css';
import { saveAs } from 'file-saver';
import SweetAlert from '../ui/SweetAlet';
import axios from '../../services/axios';

let loadingFirst = true;
const Reportes = () => {
  const dispatch = useDispatch();
  const { empresas, loading, reportes = {} } = useSelector(
    (state) => state.empresas
  );
  const [dataBody, setDataBody] = useState({});
  const [page, setPage] = useState(1);
  const [request, setRequest] = useState({})
  const [showPaginador, setPaginador] = useState(false);
  const { register, control, reset, getValues } = useForm(
    {
      resolver: yupResolver(reportesSchema),
    }
  );
  const [excludedIds, setExcludedIds] = useState([]);

  // let request;
  const handleSelect = (e) => {
    let valor = e.target.value;
    setRequest({ [valor]: 'true' });
    // console.log(request);
  }

  useEffect(() => {
    (async () => {
      console.log('new page');
      const data = getValues();
      console.log(data);
      if (request) {
        data.nombreReporte = 'idse';
        data.requestedData = request;
        data.page = page;
        setDataBody(() => data);
        await dispatch(fetchreport(data));
      }
    })();
  }, [page])

  useEffect(() => {
    (async () => {
      await dispatch(cleanReportes());
    })();
  }, [])

  const onSelectPage = (number) => {
    console.log(number)
    number = number - 1;
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEmpresasToDropdown());
      } catch (error) { }
    })();
    loadingFirst = false;
  }, [dispatch]);
  if (loadingFirst) {
    return <Spiner />;
  }

  const onSubmit = async () => {
    try {
      const data = getValues();
      data.nombreReporte = 'idse';
      data.requestedData = request;
      setDataBody(() => data);
      setPaginador(true);
      setPage(1);
      //await dispatch(fetchreport(data));
      setExcludedIds([])
    } catch (error) { }
  };

  const dowloadTxt = async () => {
    /*if (reportes.docs) {
      const { isConfirmed } = await SweetAlert({
        title: 'Al exportar los Colaboradores seran marcados como enviados a IDSE',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        //getTxt({ body: dataBody, reporte: 'idse', nombre: request, ids: excludedIds });
      }
    } else SweetAlert({ icon: "warning", title: "Información", text: 'No hay resultados a exportar' });*/
  }

  const tipos = [
    { value: 'enero', label: 'Enero-Febrero' },
    { value: 'marzo', label: 'Marzo-Abril' },
    { value: 'mayo', label: 'Mayo-Junio' },
    { value: 'julio', label: 'Julio-Agosto' },
    { value: 'septiembre', label: 'Septiembre-Octubre' },
    { value: 'noviembre', label: 'Noviembre-Diciembre' }
  ];

  const onChange = ({ target }) => {
    if (target.value) setRequest(() => target.value);
  };

  return (
    <ContainerReportes>
      <div style={{ minHeight: '15vh' }}>
        <TitleMenu>Variabilidad</TitleMenu>
        <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
          Genera la variabilidad del IMSS
        </SubTitleMenu>
      </div>
      <Form>
        <FormGroup row>
          <Label for='empresa' sm={1}>
            Empresa
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='empresas[0]'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={empresas}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='empresa' sm={1}>
            Bimestre
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='nombreReporte'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={tipos}
              onChange={onChange}
            />
          </Col>
          <Col sm={3}>
            <SaveButton type='button' onClick={() => onSubmit()}>
              Procesar
            </SaveButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='button' onClick={() => dowloadTxt()}>Aplicar</SaveButton>
          </Col>
          <Col sm={12}>
            {loading ? <Spiner /> : reportes.docs ? <TableDinamic reportes={reportes} excludedIds={excludedIds} setExcludedIds={setExcludedIds} /> : ''}
          </Col>
        </FormGroup>
      </Form>
      <br />
      <br />
      <Col hidden={!showPaginador}>
        <PaginationComponent
          size={"sm"}
          defaultActivePage={page}
          firstPageText="Primera"
          previousPageText="Anterior"
          nextPageText="Siguiente"
          lastPageText="Ultima"
          totalItems={reportes.total}
          pageSize={reportes.limit}
          onSelect={setPage}
        />
      </Col>
    </ContainerReportes>
  );
};

export default Reportes;
