import React from 'react';
import TableCommon from '../ui/TableCommon';
import TableBodySucursal from './TableBodySucursal';
import { useSelector } from 'react-redux';
import {
  SubTitleMenu,
  Container,
  SaveButton,
  MessageAddUtils,
} from '../../components/ui/styles/styles';
const TablaSucursal = React.memo(({ history }) => {
  const { listaSucursales } = useSelector((state) => state.sucursal);
  return (
    <section style={{marginLeft:'35px'}}>
      {listaSucursales.length > 0 ? (
        <>
          <SubTitleMenu style={{ fontSize: '1.5rem', marginBottom: '1rem', color:'#2b388f' }}>
            Sucursales
          </SubTitleMenu>
          <TableCommon>
            <TableBodySucursal
              history={history}
              listaSucursales={listaSucursales}
            />
          </TableCommon>
          <br /><br />
          <SaveButton
            style={{ width: '15rem',float:'left' }}
            onClick={() => {
              history.replace('/dashboard/cuenta/crear-sucursal');
            }}
          >
            + Agregar
          </SaveButton>
        </>
      ) : (
        <Container>
          <MessageAddUtils>Da de alta tu primera Sucursal</MessageAddUtils>
          <SaveButton
            style={{ width: '15rem' }}
            onClick={() => {
              history.replace('/dashboard/cuenta/crear-sucursal');
            }}
          >
            + Agregar
          </SaveButton>
        </Container>
      )}
    </section>
  );
});

export default TablaSucursal;
