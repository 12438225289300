const makeTableData = (listaColaboradores, date) => {
    return listaColaboradores.map(c => {
        return {
            key: c.id,
            name: `${c.informacionPersonal.apellidoPaterno} ${c.informacionPersonal.apellidoMaterno}, ${c.informacionPersonal.nombre}`,
            ...c
        }
    })
}

export default makeTableData;