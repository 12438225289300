import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Tr } from '../colaboradores/UniformeViews/styled';

const TableBody = React.memo(({ listaConfiguracion = [] }) => {
  return (
    <>
      {listaConfiguracion.map((configuracion, index) => (
        <Tr key={configuracion.nombre + index}>
          <td>{configuracion.nombre}</td>
          <td>
            {configuracion.expiracion ? (
              <FontAwesomeIcon
                color='#626262'
                icon={faCheckCircle}
                style={{ fontSize: '1.5rem' }}
              />
            ) : null}
          </td>
          <td>{configuracion.seccion}</td>
          <td>
            <FontAwesomeIcon
              color='#626262'
              icon={faTrashAlt}
              style={{ fontSize: '1.5rem', cursor: 'pointer' }}
            />
          </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;
