import React,{ Fragment,useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch,useSelector } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Label,
	Col,
	CustomInput
} from "reactstrap";
import {TextAreaUtils} from '../../colaboradores/UtilitarioViews/styled';
import { SaveButton,CancelButton } from '../../ui/styles/styles';
import CustomSelect from '../../ui/CustomSelect';
import CurrencyFormat from 'react-currency-format';
import '../../colaboradores/Forms/CondicionesLaborales.css';
import SweetAlert from "../../ui/SweetAlet";
import optionsBancos from "../../../constans/bancos";
import moment from 'moment';
import {updateConvenio,fetchGetFiniquito} from '../../../store/finiquitosDuck';

const pagosOptions = [
	{ label: 'Efectivo', value: 'Efectivo' },
	{ label: 'Cheque', value: 'Cheque' },
	{ label: 'Otro', value: 'Otro' }
  ];

const ModalFiniquitoEdit = ({ isOpen, setIsOpen,listaFiniquitos,setListaFiniquitos,index,montoConvenio,setMontoConvenio }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, control,} = useForm({});
	const { pago = {} } = useSelector((state) => state.finiquitos);
	const [cheque, setCheque] = useState(false);
	const [especifica, setEspecifica] = useState(false);
	const [mont, setMont] = useState(pago['monto']);
	const [notEmail, setNotEmail] = useState(false);
	const onSubmit = async (objeto) => {
		console.log(index);
		objeto.monto = mont;
		const merged = {...pago,...objeto};
		listaFiniquitos[index] = merged;
		setListaFiniquitos([...listaFiniquitos]);
		setIsOpen(false)
	};

	const handleChange = ({target}) =>{
		let valor = target.value;
		console.log(valor);
		if (valor === 'Cheque') {
			setCheque(true);
			setEspecifica(false);
		}if (valor === 'Otro') {
			setEspecifica(true);
			setCheque(false);
		}if (valor === '') {
			setEspecifica(false);
			setCheque(false);
		}if (valor === 'Efectivo') {
			setEspecifica(false);
			setCheque(false);
		}
	};
	
	useEffect(() => {
		if (pago["recordatorio"] === "true") {
			setNotEmail(true)
			setMont(pago["monto"])
		}if (pago["recordatorio"] === "false") {
			setNotEmail(false)
			setMont(pago["monto"])
		}if (pago["formaPago"] === 'Cheque') {
			setCheque(true)
		}if (pago["formaPago"] === 'Otro') {
			setEspecifica(true)
		}if (pago["formaPago"] === 'Efectivo') {
			setEspecifica(false)
			setCheque(false)
		}
	},[isOpen]);

	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Editar Convenio de Pago
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup row>
							<Label sm={3}>Monto</Label>
							<Col sm={9}>
								<CurrencyFormat
									fixedDecimalScale={true}
									decimalScale={2}
									className='inputMoneda'
									thousandSeparator={true}
									name='monto'
									innerRef={register()}
									placeholder='Monto Pagado'
									prefix={'$'}
									value={mont}
									onValueChange={(values) => {
										const {formattedValue, value} = values;
										setMont( value)
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Fecha</Label>
							<Col sm={4}>
								<Input 
									type='date'
									name='fecha'
									innerRef={register()}
									defaultValue={moment(pago["fecha"]).format('YYYY-MM-DD')}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Forma de Pago</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={pagosOptions}
									type='select'
									name='formaPago'
									innerRef={register()}
									onChange={handleChange}
									placeholder='Selecciona forma de pago'
									defaultValue={pago["formaPago"]}
								/>
							</Col>
						</FormGroup>
						{especifica ?
						<FormGroup row>
							<Label sm={3}>Especifica</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='otro'
									innerRef={register()}
									placeholder='Especifica la forma de pago'
									defaultValue={pago["especifica"]}
								/>
							</Col>
						</FormGroup>:null}
						{cheque ?
						<>
						<FormGroup row>
							<Label sm={3}>Numero de cheque</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='numeroCheque'
									innerRef={register()}
									placeholder='Numero de cheque'
									defaultValue={pago["numeroCheque"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Numero de cuenta</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='cuenta'
									innerRef={register()}
									placeholder='Numero de cuenta'
									defaultValue={pago["cuenta"]}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Banco</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={optionsBancos}
									type='select'
									name='banco'
									innerRef={register()}
									placeholder='Selecciona el banco'
									defaultValue={pago["banco"]}
								/>
							</Col>
						</FormGroup>
						</>
						:null}
						<FormGroup row>
							<Label sm={3}>Recordatorio</Label>
							<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="SI"
								value={true}
								name="recordatorio"
								onClick={() => setNotEmail(true)}
								defaultChecked={pago["recordatorio"] === "true"}
							/>
							</Col>
							<Col sm={1}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="NO"
								value={false}
								name="recordatorio"
								onClick={() => setNotEmail(false)}
								defaultChecked={pago["recordatorio"] === "false"}
							/>
							</Col>
						</FormGroup>
						{notEmail ?
						<FormGroup row>
							<Label sm={3}>Agendar recordatorio</Label>
							<Col sm={4}> 
								<Input 
								type='date'
								name='fechaRecordatorio'
								innerRef={register}
								defaultValue={moment(pago["fechaRecordatorio"]).format('YYYY-MM-DD')}
								/>
							</Col>
						</FormGroup>:null}
					</Form>
					<FormGroup row>
						<Col sm={{ offset: 4, size: 4 }}>
							<CancelButton
								onClick={() => setIsOpen(false)}
								style={{ marginLeft: 'auto' }}>
								Cancelar
							</CancelButton>
						</Col>
						<Col sm={3}>
							<SaveButton type='button' onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
						</Col>
					</FormGroup>
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default ModalFiniquitoEdit;