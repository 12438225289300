import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import { schemaDescuentoSalario } from '../../../constans/yupSchemas';
import { createDescuento, descuentoEditFinish, editDescuento, deleteDescuento } from '../../../store/descuentosDuck';
import Arrows from '../../ui/Arrows';
import ButtonDowload from '../../ui/ButtonDowload';
import CustomSelect from '../../ui/CustomSelect';
import Icons from '../../ui/Icons';
import InputFile from '../../ui/InputFile';
import SweetAlert from '../../ui/SweetAlet';
import {
    CancelButton,
    InputUtils,
    LabelForms,
    SaveButton,
    SubTitleMenu
} from '../../ui/styles/styles';
import CustomInput from 'reactstrap/lib/CustomInput';
import CurrencyFormat from 'react-currency-format';
import { fetchEmpresas } from '../../../store/empresasDuck';

const tiposDescuentos = [
    { value: 'Deuda por anticipo de salario', label: 'Deuda por anticipo de salario' },
    { value: 'Pago hecho en exceso', label: 'Pago hecho en exceso' },
    { value: 'Errores, pérdidas o averías', label: 'Errores, pérdidas o averías' },
    { value: 'Adquisición de artículos producidos por la empresa', label: 'Adquisición de artículos producidos por la empresa' },
    { value: 'Pago de renta', label: 'Pago de renta' },
    { value: 'Pensión alimenticia', label: 'Pensión alimenticia' },
    { value: 'Cuotas sindicales', label: 'Cuotas sindicales' }
];

const tiposMonto = [
    { value: 'tasa', label: 'Tasa (%)' },
    { value: 'monto', label: 'Monto' }
];

const FormDescuentoSalario = React.memo(({ history }) => {
    const periodo = useSelector(state => state.colaborador.InformacionNomina ? state.colaborador.InformacionNomina.modalidadDePago : '');
    const isEdit = useSelector(state => state.descuentos.isEdit);
    let dto = useSelector(state => state.descuentos.dto);
    const { permisos = {} } = useSelector((state) => state.user);
    const empresaID = useSelector(state => state.colaborador.pertenencia ? state.colaborador.pertenencia.empresa : '')
    const { empresas } = useSelector((state) => state.empresas);
    const empresa = empresas.filter(e => e._id === empresaID)
    const salarioMinimo = empresa[0] ? empresa[0].informacionLegal.salarioMinimo : 0
    const salarioDiario = useSelector(state => state.colaborador.InformacionNomina ? state.colaborador.InformacionNomina.salarioDiario : 0);
    const { lista = [] } = useSelector((state) => state.descuentos);
    const descuentos = lista.filter(d => d.active && !d.infonavit && d._id !== dto._id)
    
    const dispatch = useDispatch();
    const [finito, setFinito] = useState(false);
    const [isFormEdit, setFormEdit] = useState(false);
    const [recurrente, setRecurrente] = useState(false);
    const [tipo, setTipo] = useState('');
    const [err, setErr] = useState({});

    const { register, handleSubmit, errors, control, getValues } = useForm({
        resolver: yupResolver(schemaDescuentoSalario)
    });

    const [monto, setMonto] = useState(dto.monto)
    const [tipoMonto, setTipoMonto] = useState(dto.tipoMonto)

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchEmpresas());
            } catch (error) {
                console.log(error);
            }
        })();
    }, [dispatch]);

    useEffect(() => {
        if (Object.keys(errors).length) {
            if (!monto) errors.monto = 'Monto requerido'
            setErr(errors);
        }
    }, [errors]);

    useEffect(() => {
        if (isEdit) {
            setRecurrente(dto.periodicidad.recurrente);
            setFinito(dto.periodicidad.infinito === false);
            setTipo(dto.tipoDescuento);
            setFormEdit(true);
        }
    }, [isEdit]);

    const cancel = async () => {
        await dispatch(descuentoEditFinish());
    }

    const elminateUtil = async () => {
        try {
            const { isConfirmed } = await SweetAlert({
                title: 'Seguro que desea eliminar?',
                showCancelButton: 'Cancelar',
            });
            if (isConfirmed) {
                await dispatch(deleteDescuento(dto._id));
                SweetAlert({ title: 'Descuentos actualizados', icon: 'success' });
                history.push('/');
            }
        } catch (err) {
            SweetAlert({ title: 'Error!', text: err, icon: 'error' });
            console.log(err);
        }
    }

    const onSubmit = async (data) => {
        try {
            if (!monto) {
                errors.monto = 'Monto requerido'
                setErr(errors)
                return
            }
            data.monto = monto
            let excedente = validarMonto(data)
            if (validarMonto(data)) {
                SweetAlert({ title: 'Atención', text: 'El monto a descontar no debe superar el 30% (' + formatter.format(excedente) + ') del excedente del salario mínimo vigente (Artículo 110 Ley Federal del Trabajo).', icon: 'warning' });
                return
            }
            if (isEdit)
                await dispatch(editDescuento(data));
            else
                await dispatch(createDescuento(data));
            SweetAlert({ title: 'Descuentos actualizados', icon: 'success' });
            history.push('/');
        } catch (err) {
            SweetAlert({ title: 'Error!', text: err, icon: 'error' });
            console.log(err);
        }
    }

    const validarMonto = (data) => {
        let montoTotal = 0
        let dias = 7
        if (periodo === 'Diario') dias = 1
        if (periodo === 'Semanal') dias = 7
        if (periodo === 'Decenal') dias = 10
        if (periodo === 'Catorcenal') dias = 14
        if (periodo === 'Quincenal') dias = 15.2083
        if (periodo === 'Mensual') dias = 30.4166
        if (periodo === 'Bimestral') dias = 60.8333
        let salario = (salarioDiario * dias) - (salarioMinimo * dias)
        let excedente = salario * 0.30

        descuentos.forEach(d => {
            if (d.tipoMonto === 'tasa') montoTotal = montoTotal + (salarioDiario * 30.4166) * (d.monto /100)
            else montoTotal = montoTotal + d.monto
        });

        if (data.tipoMonto === 'tasa') {
            let salarioMensual = (salarioDiario * 30.4166) * (data.monto /100)
            if ((salarioMensual + montoTotal) > excedente) return excedente
        } 
        console.log('data.monto', parseFloat(data.monto))
        if (data.tipoMonto === 'monto' && (parseFloat(data.monto) + montoTotal) > excedente) return excedente
        return
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
            {isEdit ?
                <FormGroup row>
                    <Col sm={1}>
                        <Arrows
                            onClick={() => {
                                cancel();
                                history.push('/');
                            }}
                        />
                    </Col>
                    <Col sm={1} hidden={!dto.active || permisos.colaboradorDescuentos !== 'write'}>
                        <Icons onClick={elminateUtil} />
                    </Col>
                    <Col sm={1} hidden={!dto.active || permisos.colaboradorDescuentos !== 'write'}>
                        <Icons
                            pencil
                            onClick={() => {
                                setFormEdit(!isFormEdit);
                            }}
                        />
                    </Col>
                </FormGroup>
                : ''}


            <FormGroup row>
                <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                    <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >{isEdit ? 'Editar' : 'Nuevo'} Descuento al Salario</SubTitleMenu>
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo de Descuento*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        onChange={e => setTipo(e.target.value)}
                        name='tipoDescuento'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposDescuentos}
                        defaultValue={dto.tipoDescuento ? dto.tipoDescuento : ''}
                        disabled={isFormEdit}
                    />
                    {err.tipoDescuento && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El tipo de descuento es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <CustomSelect
                        name='tipoMonto'
                        type='select'
                        innerRef={register}
                        control={control}
                        options={tiposMonto}
                        defaultValue={dto.tipoMonto ? dto.tipoMonto : ''}
                        disabled={isFormEdit}
                        onChange={e => setTipoMonto(e.target.value)}
                    />
                    {err.tipoMonto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El tipo de monto es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Monto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    {tipoMonto === 'monto' ? (
                        <CurrencyFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            className='inputMoneda'
                            value={monto}
                            name='monto'
                            innerRef={register()}
                            placeholder="Ingrese el monto"
                            thousandSeparator={true}
                            prefix={'$'}
                            disabled={isFormEdit}
                            onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                setMonto(value)
                            }}
                        />
                    ) : (
                        <InputUtils
                            name='monto'
                            type='number'
                            step='0.01'
                            ref={register}
                            placeholder='Ingrese el monto'
                            defaultValue={dto.monto ? dto.monto : ''}
                            disabled={isFormEdit}
                            onChange={e => setMonto(e.target.value)}
                        />
                    )}
                    {err.monto && !monto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El monto es requerido y debe ser numérico
                        </span>
                    )}
                </Col>
            </FormGroup>
            {
                tipo === 'Pensión alimenticia' ?
                    <React.Fragment>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Autoridad Judicial*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <InputUtils
                                    name='autoridadJudicial'
                                    placeholder='Autoridad judicial'
                                    type='text'
                                    ref={register}
                                    defaultValue={dto.autoridadJudicial ? dto.autoridadJudicial : ''}
                                    disabled={isFormEdit}
                                />
                                {err.autoridadJudicial && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                        La autoridad judicial es requerida
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Número Expediente*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <InputUtils
                                    name='expediente'
                                    placeholder='Número de expediente'
                                    type='text'
                                    ref={register}
                                    defaultValue={dto.expediente ? dto.expediente : ''}
                                    disabled={isFormEdit}
                                />
                                {err.expediente && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                        El monto es requerido y debe ser numérico
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Orden Judicial</LabelForms>
                            </Label>

                            <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
                                {isEdit && dto.archivo && isFormEdit
                                    ?
                                    <ButtonDowload
                                        Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${dto.archivo}`}
                                    />
                                    : <InputFile name='archivo' id='archivo' register={register} />}
                            </Col>
                        </FormGroup>
                    </React.Fragment>
                    : ''
            }

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Recurrente*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <Label sm={2} check style={{ marginLeft: '20px' }}>
                        <CustomInput type='radio' name='periodicidad.recurrente'
                            defaultChecked={dto.periodicidad && dto.periodicidad.recurrente === true ? true : false}
                            value='true' innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setRecurrente(true)} /> Si
                    </Label>
                    <Label sm={2} check style={{ marginLeft: '40px' }}>
                        <CustomInput type='radio' name='periodicidad.recurrente'
                            defaultChecked={dto.periodicidad && dto.periodicidad.recurrente === false ? true : false}
                            value='false'
                            innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setRecurrente(false)} /> No
                    </Label>
                    {err.periodicidad && err.periodicidad.recurrente && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            <br />
                        Debe indicar la recurrencia
                        </span>
                    )}
                </Col>
            </FormGroup>

            {
                recurrente === true ?
                    <React.Fragment>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Periodicidad</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <InputUtils
                                    name='periodo'
                                    type='text'
                                    ref={register}
                                    disabled={true}
                                    value={periodo}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Fecha de Inicio*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <InputUtils
                                    name='periodicidad.fechaInicio'
                                    type='date'
                                    ref={register}
                                    disabled={isFormEdit}
                                    defaultValue={dto.periodicidad && dto.periodicidad.fechaInicio ? moment(dto.periodicidad.fechaInicio).utc().format('YYYY-MM-DD') : ''}
                                />
                                {err.periodicidad && err.periodicidad.fechaInicio && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                        Debe indicar la fecha de inicio
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={3} style={{ marginBottom: '12px' }}>
                                <LabelForms>Indefinidamente*</LabelForms>
                            </Label>
                            <Col sm={{ offset: 1, size: 4 }}>
                                <Label sm={2} check style={{ marginLeft: '20px' }}>
                                    <CustomInput type='radio' name='periodicidad.infinito'
                                        defaultChecked={dto.periodicidad && dto.periodicidad.infinito === true ? true : false}
                                        value={true}
                                        innerRef={register}
                                        disabled={isFormEdit}
                                        onClick={e => setFinito(false)} />Sí
                                </Label>
                                <Label sm={2} check style={{ marginLeft: '40px' }}>
                                    <CustomInput type='radio' name='periodicidad.infinito'
                                        defaultChecked={dto.periodicidad && dto.periodicidad.infinito === false ? true : false}
                                        value={false}
                                        innerRef={register}
                                        disabled={isFormEdit}
                                        onClick={e => setFinito(true)} />No
                                </Label>
                                {err.periodicidad && err.periodicidad.infinito && (
                                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                        <br />
                                    Debe indicar la finitud
                                    </span>
                                )}
                            </Col>
                        </FormGroup>
                        {
                            finito === true ?
                                (<FormGroup row>
                                    <Label sm={3} style={{ marginBottom: '12px' }}>
                                        <LabelForms>Fecha de Finalización*</LabelForms>
                                    </Label>
                                    <Col sm={{ offset: 1, size: 4 }}>
                                        <InputUtils
                                            name='periodicidad.fechaFin'
                                            type='date'
                                            ref={register}
                                            disabled={isFormEdit}
                                            defaultValue={dto.periodicidad && dto.periodicidad.fechaFin ? moment(dto.periodicidad.fechaFin).utc().format('YYYY-MM-DD') : ''}
                                        />
                                        {err.periodicidad && err.periodicidad.fechaFin && (
                                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                                Debe indicar la fecha de finalización
                                            </span>
                                        )}
                                    </Col>
                                </FormGroup>)
                                : ''
                        }
                    </React.Fragment>
                    :
                    (<FormGroup row>
                        <Label sm={3} style={{ marginBottom: '12px' }}>
                            <LabelForms>Fecha de Aplicación*</LabelForms>
                        </Label>
                        <Col sm={{ offset: 1, size: 4 }}>
                            <InputUtils
                                name='fechaAplicacion'
                                type='date'
                                ref={register}
                                disabled={isFormEdit}
                                defaultValue={dto.fechaAplicacion ? moment(dto.fechaAplicacion).utc().format('YYYY-MM-DD') : ''}
                            />
                            {err.fechaAplicacion && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    Debe indicar la fecha de aplicación
                                </span>
                            )}
                        </Col>
                    </FormGroup>
                    )
            }

            <FormGroup row>
                <Col sm={dto.active === false ? { offset: 9, size: 3 } : { offset: 6, size: 3 }}>
                    <CancelButton
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                        style={{ marginLeft: 'auto' }}>
                        Cancelar
                    </CancelButton>
                </Col>
                <Col sm={3} hidden={dto.active === false || permisos.colaboradorDescuentos !== 'write'}>
                    {!isFormEdit && (
                        <SaveButton type='submit' >Guardar</SaveButton>
                    )}
                </Col>
            </FormGroup>
        </Form>
    );
});

export default FormDescuentoSalario;