import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import FormEditPermisosLaborales from './PermisosLaboralesViews/FormEditPermisosLaborales';
import FormPermisosLaborales from './PermisosLaboralesViews/FormPermisosLaborales';
import FromExtenderPermiso from './PermisosLaboralesViews/FromExtenderPermiso';
import PermisosLaboralesView from './PermisosLaboralesViews/PermisosLaboralesView';

const PermisosLaborales = () => {
  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/permisos/agregar' component={FormPermisosLaborales} />
        <Route path='/permisos' component={FormEditPermisosLaborales} />
        <Route path='/extender' component={FromExtenderPermiso} />
        <Route path='/' component={PermisosLaboralesView} />
      </Switch>
    </HashRouter>
  );
};

export default PermisosLaborales;