import React from 'react';
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import TableBodyComparacion from './TableBodyComparacion';

const TableConciliaciones = React.memo(({ lista = [], action, setAction, tipoReporte }) => {
    return (
        <>
            <TableCommon keysAceepted={tipoReporte === 'EMA-IMSS' ? ['Nombre', 'Fecha'] : ['Colaborador', 'Curp', 'NSS']} striped>
                <TableBodyComparacion listaConciliaciones={lista} action={action} tipoReporte={tipoReporte} setAction={setAction} />
            </TableCommon>
        </>
    );
});

export default TableConciliaciones;
