import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StyleInputs } from "../ui/styles/StyleNomina";
import { Row, Col, FormGroup, Input, Form } from "reactstrap";
import axios from "axios";

const api = process.env.REACT_APP_API_URL;

function CalcularNomina({ setPeriodo, periodo }) {
  const [catalogos, setCatalogos] = useState([]);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  useEffect(() => {
    getCatalogos();
  }, []);

  const getCatalogos = async () => {
    let { data } = await axios.get(`${api}/catalogos/?sat=true&catalogo=periodicidadPago`, headers);
    setCatalogos(data.response);
  };

  return (
    <>
      <StyleInputs>
        <Form className="px-0 my-4">
          <FormGroup>
            <Row>
              <Col className="col-4">
                <Input type="select" name="select" onChange={(e) => setPeriodo({ ...periodo, periodicidad: e.target.value })} value={periodo.periodicidad} defaultValue={""}>
                  <option value={""}>Seleccione Periodo ..</option>
                  {catalogos.map((c) => (
                    <option value={c.descripcion} key={c.codigo}>
                      {c.descripcion}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col className="col-4">
                <Input
                  type="date"
                  value={periodo.fechaInicio}
                  name="fechaInicio"
                  onChange={(e) => setPeriodo({ ...periodo, fechaInicio: e.target.value })}
                  placeholder="Fecha de Inicio"
                />
              </Col>
              <Col className="col-4">
                <Input type="date" onChange={(e) => setPeriodo({ ...periodo, fechaFin: e.target.value })} value={periodo.fechaFin} name="fechaFin" placeholder="Fecha de Fin" />
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </StyleInputs>
    </>
  );
}

export default CalcularNomina;
