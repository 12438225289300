import React from "react";
import Utilitario from "../Utilitario";
import Uniformes from "../Uniformes";
import FormEditCondicionesLaboreles from "./EditForms/FormEditCondicionesLaboreles";
import FormEditDatosPersonales from "./EditForms/FormEditDatosPersonales";
import FormEditInformacionNomina from "./EditForms/FormEditInformacionNomina";
import FormEditSaludLaboral from "./EditForms/FormEditSaludLaboral";
import Vacaciones from "../Vacaciones";
import ExpedienteDigital from "../ExpedienteDigital";
import PermisosLaborales from "../PermisosLaborales";
import Incapacidades from "../Incapacidades";
import PrestacionesOrdinarias from "../Prestaciones/PrestacionesOrdinarias";
import DescuentosSalario from '../DescuentosSalario';
import DescuentosInfonativt from '../DescuentosInfonavit';
import ActasAdministrativas from '../ActasAdministrativas'
import SimuladorSalarial from "../SimuladorSalarial/SimuladorSalarial";
import Promociones from "../Promociones";

const EditForms = React.memo(({ form = 0 }) => {
  let component = null;
  switch (form) {
    case 1:
      component = <FormEditDatosPersonales />;
      break;
    case 2:
      component = <FormEditCondicionesLaboreles />;
      break;
    case 3:
      component = <FormEditInformacionNomina />;
      break;
    case 4:
      component = <DescuentosSalario />;
      break;
    case 5:
      component = <Utilitario />;
      break;
    case 6:
      component = <Uniformes />;
      break;
    case 7:
      component = <ExpedienteDigital />;
      break;
    case 8:
      component = <Vacaciones />;
      break;
    case 9:
      component = <PermisosLaborales />;
      break;
    case 10:
      component = <Incapacidades />;
      break;
    case 11:
      component = <FormEditSaludLaboral />;
      break;
    case 12:
      component = <PrestacionesOrdinarias />;
      break;
    case 13:
      component = <SimuladorSalarial />;
      break;
    case 14:
      component = <ActasAdministrativas />;
      break;
    case 15:
      component = <DescuentosInfonativt />;
      break;
    case 16:
      component = <Promociones />;
      break;
    default:
      component = null;
  }
  return <>{component}</>;
});

export default EditForms;
