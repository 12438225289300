import React,{useEffect} from 'react';
import SubSideBar from '../../components/layout/SubSidebar/SubSidebar';
import perfil_routes from '../../routes/perfil-routes'
import PerfilRoutes from "./../../routes/ProtectedRoutes/ViewsRoutes/PerfilRoutes";
import './Perfil.css'
const Perfil = ({match}) => {
    useEffect(() => {
        return () => {
        }
    }, [])
    return (
        <section className='grid-container'>
            <div >
                <SubSideBar routes={perfil_routes} match={match}/>
            </div>

            <div className='frame-content'>
                <PerfilRoutes path={match.path}/>
            </div>
        </section>
    );
}

export default Perfil;