import { faArrowAltLeft, faArrowAltRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import 'moment/locale/es-mx';
import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { LabelForms } from '../../ui/styles/styles';
import { LightMiniButton } from '../../ui/styles/styles';

const QuincenaControl = ({ width, date, setDate }) => {

    const atrasarQuincena = () => parseInt(moment(date).format('DD')) < 16 ? setDate(`${moment(moment(date).subtract(1, 'month')).format('YYYY')}-${moment(moment(date).subtract(1, 'month')).format('MM')}-16`) : setDate(moment(date).startOf('month'))
    const adelantarQuincena = () => parseInt(moment(date).format('DD')) < 16 ? setDate(moment(`${moment(date).format('YYYY')}-${moment(date).format('MM')}-16`)) : setDate(moment(date).add(1, 'month').startOf('month'))

    const getEnd = (date) => {
        if(parseInt(moment(date).format('DD')) < 16) 
            return moment(`${moment(date).format('YYYY')}-${moment(date).format('MM')}-15`);
        else return moment(date).endOf('month');
    }

    return (
        <Col sm={width}>
            <Row>
                <div style={{ marginRight: '10px' }}>
                    <LightMiniButton onClick={atrasarQuincena}><FontAwesomeIcon icon={faArrowAltLeft} /></LightMiniButton>
                    <LightMiniButton onClick={adelantarQuincena}><FontAwesomeIcon icon={faArrowAltRight} /></LightMiniButton>
                </div>
                <LabelForms>Quincena del {moment(date).format('DD-MMM')} al {getEnd(date).format('DD-MMM')}</LabelForms>
            </Row>
        </Col>
    )
}
export default QuincenaControl