import React, { useState,useEffect } from 'react';
import styled from 'styled-components';
import { SubTitleMenu } from './styles/styles';
import ProgressBar from './ProgressBar';
import Arrows from './Arrows';
import { SaveButton } from '../ui/styles/styles';
import { useSelector, useDispatch } from 'react-redux';
import axios from "../../services/axios";
import swal from "sweetalert2";
import jsZip from "jszip";
import { saveAs } from "file-saver";

const Heading = styled.div`
  cursor: default;
`;
const HeadingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  align-content: center;
  margin-bottom: 1vh;
`;
const NumberPercent = styled.span`
  margin-left: 15px;
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 600;
`;
const Content = styled.div`
  /* border: 1px solid gray; */
  margin-top: 20px;
  border-top: none;
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '100%' : '0')};
  overflow: hidden;
  padding: ${(props) => (props.open ? '15px' : '0 15px')};
  transition: all 0.3s;
  display:grid;
  grid-template-columns: 1fr 1.2fr 0.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 5px;
  p {
    margin: 0;
  }
  input[type='date']{
    width:75%;
  }
`;

export const CollapsePeronal =  React.memo(({ title = 'Documentación Personal', objeto, children }) => {
  const dispatch = useDispatch();
  const { documentos = [] } = useSelector((state) => state.colaborador);

  const [toogle, setToogle] = useState(false);
  const [arrayPersonal, setArrayPersonal] = useState([]);
  
  useEffect(() => {
    const array = [];
    documentos.forEach(element => {
    if (element.seccion === "Personal") {
      array.push(element);
      }
    });
    setArrayPersonal(array);
  }, [])
  

  const getDocs = async () =>{
    var zip = new jsZip();
    swal.showLoading();
    if (arrayPersonal.length !== 0) {
      await Promise.all(
        arrayPersonal.map( async (element) => {
          const { data } = await axios.get(`/archivo/descargab64/${element.archivo}`);
          let nombre = element.nombre.replace(/ /g, "_")
          zip.file(`${nombre}.pdf`, data,{base64:true});
        })
      );
    }
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `expediente-personal.zip`);
    });
    swal.close();
  }
  return (
    <>
      <Heading> 
        <HeadingContent>
          <SubTitleMenu style={{ width: '27%', marginBottom: 0, fontSize: '22px' }}>
            {title}
          </SubTitleMenu>
          {toogle 
          ? <Arrows down onClick={() => {
              setToogle((state) => !state);}} 
            /> 
          : <Arrows right onClick={() => {
            setToogle((state) => !state);}}
            />
          }
          <SaveButton 
            style={{ width: '15%', marginLeft:350}} 
            type='button'
            onClick={getDocs}
          >Descarga</SaveButton>
        </HeadingContent>
        <NumberPercent>
          {
            `Obligatorios: ${objeto.obligatoriosSubidos}/${objeto.obligatoriosTotal} 
             Opcionales: ${objeto.opcionalesSubidos}/${objeto.opcionalesTotal} 
             Total: ${objeto.totalSubidos}/${objeto.totalTotal}`
          }
        </NumberPercent>
        {/* <ProgressBar percent={percent} /> */}
      </Heading>
      <Content open={toogle}>
        {children}
      </Content>
    </>
  );
});

export const CollapseUtilitario = React.memo(({ title = 'Documentación Utilitarios', objeto, children}) => {
  const dispatch = useDispatch();
  const { documentos = [] } = useSelector((state) => state.colaborador);
  const [arrayUtilitario, setArrayUtilitario] = useState([]);
  const [toogle, setToogle] = useState(false);
  useEffect(() => {
    const array = [];
    documentos.forEach(element => {
    if (element.seccion === "Utilitario") {
      array.push(element);
      }
    });
    setArrayUtilitario(array);
  }, [])
  

  const getDocs = async () =>{
    var zip = new jsZip();
    swal.showLoading();
    if (arrayUtilitario.length !== 0) {
      await Promise.all(
        arrayUtilitario.map( async (element) => {
          const { data } = await axios.get(`/archivo/descargab64/${element.archivo}`);
          let nombre = element.nombre.replace(/ /g, "_")
          zip.file(`${nombre}.pdf`, data,{base64:true});
        })
      );
    }
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `expediente-utilitario.zip`);
    });
    swal.close();
  }
  return (
    <>
      <Heading>
        <HeadingContent>
          <SubTitleMenu style={{ width: '27%', marginBottom: 0, fontSize: '22px' }}>
            {title}
          </SubTitleMenu>
          {toogle 
          ? <Arrows down onClick={() => {
              setToogle((state) => !state);}} 
            /> 
          : <Arrows right onClick={() => {
            setToogle((state) => !state);}}
            />
          }
          <SaveButton 
            style={{ width: '15%', marginLeft:350}} 
            type='button'
            onClick={getDocs}
          >Descarga</SaveButton>
        </HeadingContent>
        <NumberPercent>
          {
            `Obligatorios: ${objeto.obligatoriosSubidos}/${objeto.obligatoriosTotal} 
             Opcionales: ${objeto.opcionalesSubidos}/${objeto.opcionalesTotal} 
             Total: ${objeto.totalSubidos}/${objeto.totalTotal}`
          }
        </NumberPercent>
        {/* <ProgressBar percent={percent} /> */}
      </Heading>
      <Content open={toogle}>
        {children}
      </Content>
    </>
  );
});

export const CollapseLaboral = React.memo(({ title = 'Documentación Laboral', objeto, children }) => {
  const dispatch = useDispatch();
  const { documentos = [] } = useSelector((state) => state.colaborador);
  const [toogle, setToogle] = useState(false);
  const [arrayLaboral, setArrayLaboral] = useState([]);

  useEffect(() => {
    const array = [];
    documentos.forEach(element => {
    if (element.seccion === "Laboral") {
      array.push(element);
      }
    });
    setArrayLaboral(array)
  }, [])

  const getDocs = async () =>{
    var zip = new jsZip();
    swal.showLoading();
    if (arrayLaboral.length !== 0) {
      await Promise.all(
        arrayLaboral.map( async (element) => {
          const { data } = await axios.get(`/archivo/descargab64/${element.archivo}`);
          let nombre = element.nombre.replace(/ /g, "_")
          zip.file(`${nombre}.pdf`, data,{base64:true});
        })
      );
    }
    await zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `expediente-laboral.zip`);
    });
    swal.close();
  }
  
  return (
    <>
      <Heading>
        <HeadingContent>
          <SubTitleMenu style={{ width: '27%', marginBottom: 0, fontSize: '22px' }}>
            {title}
          </SubTitleMenu>
          {toogle 
          ? <Arrows down onClick={() => {
              setToogle((state) => !state);}} 
            /> 
          : <Arrows right onClick={() => {
            setToogle((state) => !state);}}
            />
          }
          <SaveButton 
            style={{ width: '15%', marginLeft:350}} 
            type='button'
            onClick={getDocs}
          >Descarga</SaveButton>
        </HeadingContent>
        <NumberPercent>
          {
            `Obligatorios: ${objeto.obligatoriosSubidos}/${objeto.obligatoriosTotal} 
             Opcionales: ${objeto.opcionalesSubidos}/${objeto.opcionalesTotal} 
             Total: ${objeto.totalSubidos}/${objeto.totalTotal}`
          }
        </NumberPercent>
        {/* <ProgressBar percent={percent} /> */}
      </Heading>
      <Content open={toogle}>
        {children}
      </Content>
    </>
  );
});


