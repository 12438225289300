import React from 'react';
import yisus from '../../assets/img/yisus.jpg';
import './ColaboradorCard.css';
import { useSelector } from 'react-redux';
import {
  Nombre,
  JobPosition,
  InfoHeader,
  InfoHeaderBlue,
  BackgroundImageDetail,
  SectionColaborator,
} from './styles/styledColaboradorCard';

const DetailCard = React.memo(() => {
  const { logotipo, nombre_empresa, informacionLegal,numeroColaboradores} = useSelector(
    (state) => state.empresas
  );

  const { listaSucursales } = useSelector((state) => state.sucursal);
  return (
    <SectionColaborator className='colaborador-container'>
      <BackgroundImageDetail
        imageUrl={
          logotipo
            ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
            : yisus
        }
      />
      <div className='description'>
        <Nombre>{nombre_empresa}</Nombre>
        <JobPosition>{informacionLegal? informacionLegal.razon_social : ''}</JobPosition>
        <br /><br /><br />
        <div className='info'>
          <div className='id'>
            <InfoHeader>#Sucursales</InfoHeader>
            <InfoHeaderBlue>{listaSucursales.length}</InfoHeaderBlue>
          </div>
          <div className='department' style={{paddingLeft:'8vw'}}>
            <InfoHeader>#Colaboradores</InfoHeader>
            <InfoHeaderBlue>{numeroColaboradores}</InfoHeaderBlue>
          </div>
          {/* <div className='salary'>
            {    <InfoHeader>Salario</InfoHeader>
              <InfoHeaderGrey>K</InfoHeaderGrey> }*/}
          </div> 
        </div>
    </SectionColaborator>
  );
});

DetailCard.propTypes = {};

export default DetailCard;
