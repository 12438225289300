import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Input, FormFeedback, FormText } from "reactstrap";

const GroupSelect = React.memo(
  ({
    options = {},
    control,
    defualtValue = "Seleccione opción",
    placeholder = "Seleccione opción",
    ...rest
  }) => {
    if (!control) return <span>props invalidos</span>;
    const [groups, setGroups] = useState([]);
    useEffect(() => {
      setGroups(() => Object.keys(options));
    }, []);
    return (
      <>
        <Controller
          control={control}
          {...rest}
          defaultValue=""
          render={() => {
            return (
              <Input {...rest}>
                {groups.length > 0
                  ? groups.map((group, index) => (
                      <optgroup
                        key={group + index}
                        style={{ textTransform: "capitalize" }}
                        label={group.replace(/([A-Z])/g, " $1").trim()}
                      >
                        {options[group].map((value) => {
                          return value ? (
                            <option
                              key={value + index}
                              value={value}
                              selected={defualtValue === value ? true : false}
                            >
                              {value}
                            </option>
                          ) : null;
                        })}
                      </optgroup>
                    ))
                  : null}
              </Input>
            );
          }}
        />
        <FormFeedback>{rest.error}</FormFeedback>
        <FormText>{rest.formText}</FormText>
      </>
    );
  }
);

export default GroupSelect;
