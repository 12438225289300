import React from 'react';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import ButtonDowload from '../colaboradores/Forms/FormExpedienteDigital/ButtonDownload';
import {
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../constans/colors';


const TableBody = React.memo(({ arrayInfo = [],handleDelete }) => {
	
	
  return (
    <>
      {arrayInfo.map((info) => (
        <Tr
          key={info.id}
        >
          <td style={{ width: '20%',paddingTop:'22px' }}>{moment(info.fecha).format('DD/MM/YYYY')}</td>
          <td style={{ width: '50%',paddingTop:'22px' }}>{info.nombre.substring(0, 70)}...</td>
          <td style={{ width: '20%' }}>{<ButtonDowload
            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${info.id}`}
          />}</td>
		  <td style={{ width: '20%',paddingTop:'22px' }}>
		  	<a href='#' 
              onClick={(e) => handleDelete(info,e)} 
              style={{textDecoration:'none',color:colors.tashColor}}
              >
              <FontAwesomeIcon 
                color={colors.tashColor}
                icon={faTrashAlt} 
              />
            </a>
		  </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;
