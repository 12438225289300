import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import IncapacidadesAdd from './IncapacidadesViews/IncapacidadesAdd';
import IncapacidadesEdit from './IncapacidadesViews/IncapacidadesEdit';
import IncapacidadesView from './IncapacidadesViews/IncapacidadesView';

const Incapacidades = () => {
  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/incapacidades/agregar' component={IncapacidadesAdd} />
        <Route path='/incapacidades' component={IncapacidadesEdit} />
        <Route path='/' component={IncapacidadesView} />
      </Switch>
    </HashRouter>
  );
};

export default Incapacidades;