import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'actas',
    initialState: {
        loading: false,
        error: null,
        lista: [],
        dto: {
            participantes: [],
            testigos: [],
            cierreNotificaciones: []
        },
        isEdit: false
    },
    reducers:{
        // Action handlers
        actasRequested: (actas, action) =>{
            actas.loading = true;
        },
        actasReceived: (actas, action) =>{
            actas.lista = action.payload.resultado;
            actas.loading = false;
            actas.error = null;
        },
        actasRequestedFailed: (actas, action) =>{
            actas.error = action.payload;
            actas.loading = false;
        },
        actaSaved: (actas, action) =>{
            actas.loading = false;
            actas.error = null;
            actas.isEdit = false;
        },
        actaAddFailed: (actas, action) =>{
            actas.error = action.payload;
            actas.loading = false;
        },
        actaEditRequested: (actas, action) =>{
            actas.loading = true;
        },
        actaEditRequestSuccess: (actas, action) =>{
            actas.dto = action.payload.resultado;
            actas.isEdit = true;
            actas.loading = false;
            actas.error = null;
        },
        actaEditRequestFailed: (actas, action) =>{
            actas.error = action.payload;
            actas.dto = {};
            actas.isEdit = false;
            actas.loading = false;
        },
        actaEditFinished: (actas, action) =>{
            actas.dto = {};
            actas.isEdit = false;
            actas.loading = false;
        }
    }
});

// Actions
const { actasRequested, 
    actasReceived, 
    actasRequestedFailed, 
    actaSaved, 
    actaAddFailed, 
    actaEditRequested, 
    actaEditRequestSuccess, 
    actaEditRequestFailed, 
    actaEditFinished } = slice.actions;

export default slice.reducer;

// Action creators

const getHeaders = () => ({ headers: { token: localStorage.getItem('pv2token')}});

export const actaEditFinish = () =>(dispatch, getState) => {
    dispatch(actaEditFinished());
}

export const fetchActas = () => async (dispatch, getState, { axios }) => {
    try {
        dispatch(actasRequested());
        const response = await axios.get(`actas/obtener_actas/${getState().colaborador._id}`, getHeaders());
        dispatch(actasReceived(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(actasRequestedFailed());
        throw data;
    }
}

export const fetchActa = (idActa) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(actaEditRequested());
        const response = await axios.get(`actas/obtener_acta/${idActa}`, getHeaders());
        dispatch(actaEditRequestSuccess(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(actaEditRequestFailed());
        throw data;
    }
}

export const deleteActa = (idActa) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(actaEditRequested());
        const response = await axios.delete(`actas/eliminar_acta/${idActa}`, getHeaders());
        dispatch(actaEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(actaEditRequestFailed());
        throw data;
    }
}

export const createActa = (acta) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(actasRequested());
        const { _id } = getState().colaborador;
        const { archivo = {}, ...rest } = acta;

        const { data: { resultado } } = await axios.post('/actas/crear', 
        {
            acta, colaborador: _id
        }, getHeaders());
        dispatch(actaSaved());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(actaAddFailed());
        throw data;
    }
}

export const editActa = (acta) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(actaEditRequested());
        const { _id } = getState().colaborador;
        const idActa = getState().actas.dto._id;

        const { data: { resultado } } = await axios.put(`/actas/editar_acta/${idActa}`, {
            acta
        }, getHeaders());
        dispatch(actaEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(actaEditRequestFailed());
        throw data;
    }
}