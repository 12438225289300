import React from 'react';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../constans/colors';
import {fetchListaUtilitarioFiniquitos,deleteUtilFiniquitos} from '../../../store/utilitarioDucks';
import SweetAlert from '../../ui/SweetAlet';


const TableUtilitarios = React.memo(({listaUtilitarios}) => {
    const history = useHistory();
    const dispatch = useDispatch();

	const elminateUtil = async (f) => {
		try {
		  const { isConfirmed } = await SweetAlert({
			title: 'Seguro que desea eliminar?',
			showCancelButton: 'Cancelar',
		  });
		  if (isConfirmed) {
			await dispatch(deleteUtilFiniquitos(f._id));
			await dispatch(fetchListaUtilitarioFiniquitos(f.colaborador));
		  }
		} catch (error) { }
	  };
    
    return(
        <>
            {listaUtilitarios.map((f, index) => (
              
          <Tr
            key={f.index}
          >
            <td>{f.nombre}</td>
            <td>{f.numeroSerie}</td>
            <td>{moment(f.fechaEntrega).format('DD/MM/YYYY')}</td>
            <td>{
                <CurrencyFormat
                    value={f.valorFactura}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={value => <span>{value}</span>}
                />
            }</td>
            <td>
				<a href='#' 
				onClick={(e) => elminateUtil(f)} 
				style={{textDecoration:'none',color:colors.tashColor}}
				>
				<FontAwesomeIcon 
					color={colors.tashColor}
					icon={faTrashAlt} 
				/>
				</a>
			</td>
          </Tr>
        ))}
        </>
    );
});

export default TableUtilitarios;