import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyleInputs } from "../ui/styles/StyleNomina";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, ModalFooter, Form, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Faltas from "./Faltas";
import HorasExtras from "./HorasExtras";
import Incapacidades from "./Incapacidades";

const Incidencias = ({ toggle, typeModal, colaborador = { incidencias: { faltas: [], horasExtra: [], incapacidades: [] } }, onUpdate }) => {
  const [type, setType] = useState("faltas");
  const tabs = { faltas: "Faltas", horasExtras: "Horas Extras", incapacidades: "Incapacidades" };

  return (
    <Modal centered isOpen={typeModal === "incidencias"} toggle={toggle} size="lg" style={{ maxWidth: "1600px", width: "80%", margin: "10px auto" }}>
      <ModalHeader style={{ background: "#2B388F", color: "white" }} toggle={toggle}>
        Incidencias de {colaborador.nombre}
      </ModalHeader>
      <StyleInputs>
        <ModalBody>
          <Row className="mb-3">
            <Col>
              <Nav tabs>
                {Object.keys(tabs).map((key, i) => (
                  <NavItem key={i}>
                    <NavLink style={{ textDecoration: "none", color: "#404041", cursor: "pointer" }} onClick={() => setType(key)} className={`${type === key && "active"}`}>
                      {tabs[key]}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={type}>
                <TabPane tabId="faltas">
                  <Faltas onUpdate={onUpdate} colaboradorId={colaborador._id} arregloFaltas={colaborador.incidencias.faltas} />
                </TabPane>
                <TabPane tabId="horasExtras">
                  <HorasExtras onUpdate={onUpdate} colaboradorId={colaborador._id} horasExtra={colaborador.incidencias.horasExtra} />
                </TabPane>
                <TabPane tabId="incapacidades">
                  <Incapacidades onUpdate={onUpdate} colaboradorId={colaborador._id} arregloIncapacidades={colaborador.incidencias.incapacidades} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Form>
            <FormGroup>
              <Row></Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} style={{ width: "200px" }}>
            Cerrar
          </Button>
        </ModalFooter>
      </StyleInputs>
    </Modal>
  );
};

Incidencias.propTypes = {
  toggle: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  colaborador: PropTypes.object,
  typeModal: PropTypes.string,
};

export default Incidencias;
