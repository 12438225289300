import moment from 'moment';
/**
 * Retorna un Objecto sin propiedades invalidos
 * Invalid:null, "",undefined
 * @param {Object} obj
 */
export const cleanInvalidFieldsInObject = (obj) => {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
};
/**
 * Retorna un Objecto sin propiedades invalidos
 * Invalid:null, "",undefined
 * Y parsea booleanos string a bools
 * @param {Object} obj
 */
export const cleanInvalidFieldsInObjectAndParseBools = (obj) => {
  const cleanObject= Object.entries(obj).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
  for (const property in cleanObject){
    cleanObject[property]==='true'?cleanObject[property]=true:cleanObject[property]=false;
  }
  return cleanObject;
};
/**
 * Recibe un Array objetos y limpia los objetos vacios
 * y los parametros de objetos Invalid:null, "",undefined
 * @param {Array} array
 */
export const cleanInvalidObjectsInsideArray = (array) => {
  const cleanArray = array.filter((value) => {
    const cleandObject = cleanInvalidFieldsInObject(value);
    return Object.keys(cleandObject).length !== 0;
  });
  return cleanArray;
};
/**
 * Retorna objeto SortFiles con la propiedadName
 * @param {Array} newFilesArray array files
 * @return {Array} -retorna un array con los indices deben eliminarse
 */
export const determineFileToBeUpdate = (newFilesArray = []) => {
  const indexFiles=[];
   newFilesArray.forEach((file, index) => {
  
    if (file.length > 0) {
     indexFiles.push(index);
    }
  });
return indexFiles
};
/**
 * Recibe un Objeto 
 * retornando verdadero o falso si esta vacio
 * @param {Object} files
 * @returns {Boolean}
 */
export const isEmptyObject = (object) => {
  const resp =cleanInvalidFieldsInObject(object)
    
  return Object.keys(resp).length === 0 && resp.constructor === Object;;
};

export const diccionarioDias = async (horario=[], ) => {
  const arrayDias =[];
  horario.forEach(e => {
      if (e === 'L') {
          arrayDias.push('lunes');
      }if (e === 'M') {
          arrayDias.push('martes');
      }if (e === 'MI') {
          arrayDias.push('miércoles');
      }if (e === 'J') {
          arrayDias.push('jueves');
      }if (e === 'V') {
          arrayDias.push('viernes');
      }if (e === 'S') {
          arrayDias.push('sabado');
      }if (e === 'D') {
          arrayDias.push('domingo');
      }
  });
  return arrayDias;
};

export const lapsoDias = async (periodoInicial,periodoFinal)=>{
  const desde = moment(periodoInicial);
  const hasta = moment(periodoFinal);
  const fechas = [];
  while (desde.isSameOrBefore(hasta)) {
      fechas.push({'fecha':desde.format('YYYY-MM-DD'),'day':desde.format('dddd')});
      desde.add(1, 'days');
  }
  return fechas;
};

export const salarioCaido = async (fechaDespido, salario)=>{
  const now = moment();
  const despido = moment(fechaDespido);
  const viejaReforma = moment("01/01/2012");
  const mesesTranscurridos = now.diff(despido,'months');
  const diasTranscurridos = now.diff(despido,'days');
  if (despido.isAfter(viejaReforma)) {
    let mesesPendientes = 0;
    let totalPrimerAno = 0;
    let total = 0;
    let quinceMeses = 0;
    let indemnizacion = salario * 90;
    let contingencia = indemnizacion;
    if (mesesTranscurridos > 0) {
      const unMesSalario = salario * 30;
      quinceMeses = unMesSalario * 15;
      totalPrimerAno = unMesSalario * mesesTranscurridos;
      total = totalPrimerAno;
      contingencia = total + indemnizacion;
    };
    if (mesesTranscurridos > 12) {
      mesesPendientes = mesesTranscurridos - 12;
      const porcentaje = 0.02 + 1;
      const elevado = Math.pow(porcentaje, mesesPendientes);
      const result = (quinceMeses*elevado) - quinceMeses;
      total = totalPrimerAno + result;
      contingencia = (salario * 90) + total;
    };
    return {total,contingencia};
  }else{
    let total = salario * diasTranscurridos;
    let indemnizacion = salario * 90;
    let contingencia = indemnizacion + total;
    
    return {total,contingencia};
  
  }
}