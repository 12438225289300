const initialState = {
  loading: false,
  error: null,
  listaSucursales: [],
  rutaSucursal: {
    sucursal: true,
  },
  editar: false,
  configuracion: [],
};
export const FETCH_SUCURSALES = 'FETCH_SUCURSALES';
export const FETCH_SUCURSALES_EXITO = 'FETCH_SUCURSALES_EXITO';
export const FETCH_SUCURSALES_ERROR = 'FETCH_SUCURSALES_ERROR';

export const FETCH_LISTA_SUCURSALES = 'FETCH_LISTA_SUCURSALES';
export const FETCH_LISTA_SUCURSALES_EXITO = 'FETCH_LISTA_SUCURSALES_EXITO';
export const FETCH_LISTA_SUCURSALES_ERROR = 'FETCH_LISTA_SUCURSALES_ERROR';

export const FETCH_SUCURSAL = 'FETCH_SUCURSAL';
export const FETCH_SUCURSAL_EXITO = 'FETCH_SUCURSAL_EXITO';
export const FETCH_SUCURSAL_ERROR = 'FETCH_SUCURSAL_ERROR';

export const EDITAR_SUCURSAL = 'EDITAR_SUCURSAL';
export const EDITAR_SUCURSAL_EXITO = 'EDITAR_SUCURSAL_EXITO';
export const EDITAR_SUCURSAL_ERROR = 'EDITAR_SUCURSAL_ERROR';
export const EDITAR_BUTTON = 'EDITAR_BUTTON';
export const BORRAR_SUCURSAL = 'BORRAR_SUCURSAL';
export const BORRAR_SUCURSAL_EXITO = 'BORRAR_SUCURSAL_EXITO';
export const BORRAR_SUCURSAL_ERROR = 'BORRAR_SUCURSAL_ERROR';

export const CREAR_SUCURSAL = 'CREAR_SUCURSAL';
export const CREAR_SUCURSAL_EXITO = 'CREAR_SUCURSAL_EXITO';
export const CREAR_SUCURSAL_ERROR = 'CREAR_SUCURSAL_ERROR';

export const NUMERO_COLABORADORES_EXITO = 'NUMERO_COLABORADORES_EXITO';
export const ACTIVE_ROUTE = 'ACTIVE_ROUTE';
export const CLEAN_STATE_SUCURSAL = 'CLEAN_STATE_SUCURSAL';

export default function sucursalReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_SUCURSALES:
      return { ...state, loading: true };
    case FETCH_SUCURSALES_EXITO:
      return { ...state, listaSucursales: payload.resultado, loading: false };
    case FETCH_SUCURSALES_ERROR:
      return { ...state, error: payload, loading: false };
    case FETCH_LISTA_SUCURSALES:
      return { ...state, loading: true };
    case FETCH_LISTA_SUCURSALES_EXITO:
      return { ...state, loading: false, listaSucursales: [...payload] };
    case FETCH_LISTA_SUCURSALES_ERROR:
      return { ...state, error: payload, loading: false };
    case FETCH_SUCURSAL:
      return { ...state, loading: true };
    case FETCH_SUCURSAL_EXITO:
      return { ...state, loading: false, ...payload };
    case EDITAR_SUCURSAL:
      return { ...state, loading: true };
    case EDITAR_SUCURSAL_EXITO:
      return { ...state, loading: false, ...payload };
    case EDITAR_SUCURSAL_ERROR:
      return { ...state, loading: false };

    case EDITAR_BUTTON:
      return { ...state, editar: !state.editar };
    case BORRAR_SUCURSAL:
      return { ...state, loading: true };
    case BORRAR_SUCURSAL_EXITO:
      return { ...state, loading: false };
    case BORRAR_SUCURSAL_ERROR:
      return { ...state, loading: false };
    case CREAR_SUCURSAL:
      return { ...state, loading: true };
    case CREAR_SUCURSAL_EXITO:
      return { ...state, loading: false };
    case CREAR_SUCURSAL_ERROR:
      return { ...state, loading: false };
    case ACTIVE_ROUTE:
      return { ...state, rutaSucursal: payload };
    case NUMERO_COLABORADORES_EXITO:
      return { ...state, numeroColaboradores: payload };
    case CLEAN_STATE_SUCURSAL:
      return initialState;
    case 'AGREGAR_CONFIGURACION':
      return { ...state, configuracion: [...payload] };
    default:
      return { ...state };
  }
}

const getSucursales = () => ({
  type: FETCH_SUCURSALES,
});
const getSucursalesExito = (payload) => ({
  type: FETCH_SUCURSALES_EXITO,
  payload,
});
const getSucursalesError = (payload) => ({
  type: FETCH_SUCURSALES_ERROR,
  payload,
});
const eliminarSucursal = () => ({
  type: BORRAR_SUCURSAL,
});
const eliminarSucursalExito = () => ({
  type: BORRAR_SUCURSAL_EXITO,
});
const eliminarSucursalError = () => ({
  type: BORRAR_SUCURSAL_ERROR,
});
const getSucursal = () => ({
  type: FETCH_SUCURSAL,
});
export const getSucursalExito = (payload) => ({
  type: FETCH_SUCURSAL_EXITO,
  payload,
});
const getSucursalError = () => ({
  type: FETCH_SUCURSAL_ERROR,
});
const getListaScuruscalesStart = () => ({
  type: FETCH_LISTA_SUCURSALES,
});
const getListaScuruscalesSucces = (payload) => ({
  type: FETCH_LISTA_SUCURSALES_EXITO,
  payload,
});
const getListaScuruscalesError = () => ({
  type: FETCH_LISTA_SUCURSALES_ERROR,
});
const editarSucursal = () => ({
  type: EDITAR_SUCURSAL,
});
const editarSucursalExito = (payload) => ({
  type: EDITAR_SUCURSAL_EXITO,
  payload,
});
const editarSucursalError = (payload) => ({
  type: EDITAR_SUCURSAL_ERROR,
  payload,
});
export const editarBoton = () => ({
  type: EDITAR_BUTTON,
});
const creaSucursal = () => ({
  type: CREAR_SUCURSAL,
});
const crearSucursalExito = () => ({
  type: CREAR_SUCURSAL_EXITO,
});
const crearSucursalError = () => ({
  type: CREAR_SUCURSAL_ERROR,
});

export const sucursalRuta = (payload) => ({
  type: ACTIVE_ROUTE,
  payload,
});

export const setNumeroColaboradores = (payload) => ({
  type: NUMERO_COLABORADORES_EXITO,
  payload
})


export const cleanStateSucursal = () => ({
  type: CLEAN_STATE_SUCURSAL,
});

export const agregarConfig = (payload) => ({
  type: 'AGREGAR_CONFIGURACION',
  payload,
});

export const fetchListaSucursales = (idEmpresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getListaScuruscalesStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/sucursales/por_empresa?empresa=${idEmpresa}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    const listSucursales =
      resultado.length > 0
        ? resultado.map(({ _id, nombreSucursal }) => {
            return { value: _id, label: nombreSucursal };
          })
        : [];
    dispatch(getListaScuruscalesSucces(listSucursales));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getListaScuruscalesError(e));
    throw data;
  }
};

export const getSucursalesAction = () => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getSucursales());
  try {
    const { resultado } = (
      await axios.get(`/sucursales`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;

    dispatch(getSucursalesExito({ resultado }));
  } catch (e) {
    const { data } = e.response;
    dispatch(getSucursalesError(data));
    throw data;
  }
};

export const sucursalAction = (idEmpresa) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getSucursal());
  try {
    const {
      data: { resultado = [] },
    } = await axios.get('/sucursales/por_empresa', {
      params: {
        empresa: idEmpresa,
      },
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(getListaScuruscalesSucces(resultado));
  } catch (e) {
    dispatch(getSucursalError());
    const { data } = e.response;
    throw data;
  }
};

export const editarAction = (sucursalData) => (
  dispatch,
  getState,
  { axios }
) => {
  const { _id, ...sucursal } = getState().sucursal;
  const editData = { ...sucursal, ...sucursalData };
  dispatch(editarSucursal);
  try {
    const { resultado } = axios.put(`/sucursales/${_id}`, editData, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(editarSucursalExito({ resultado }));
  } catch (error) {
    dispatch(editarSucursalError(error));
    const { data } = error.response;
    throw data;
  }
};

export const eliminarSucursalAction = (_id, password) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(eliminarSucursal());
  try {
    await axios.delete(`/sucursales/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(eliminarSucursalExito());
  } catch (e) {
    dispatch(eliminarSucursalError(e));
    throw e;
  }
};
export const creaSucursalAction = (nuevaSucursal) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(creaSucursal());
  try {
    await axios.post('/sucursales/', nuevaSucursal, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(crearSucursalExito());
  } catch (error) {
    dispatch(crearSucursalError());
    const { data } = error.response;
    throw data;
  }
};

export const fetchConfig = (idSuc) => async (dispatch, getState, { axios }) => {
  dispatch(getListaScuruscalesStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/sucursales/${idSuc}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(agregarConfig(resultado.configuracion));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getListaScuruscalesError(e));
    throw data;
  }
};
export const fetchNumeroColaboradores = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { _id } = getState().sucursal;
    const {
      data: { resultado },
    } = await axios.get(`colaborador/numero-colaboradores?idSucursal=${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(setNumeroColaboradores(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(setNumeroColaboradores(0));
    throw data;
  }
};
