import React, { useState, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { useParams, useLocation } from "react-router-dom";
import { StyleInputs } from "../ui/styles/StyleNomina";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faPen, faSearch, faBars, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input, Table, Nav, NavItem, NavLink } from "reactstrap";
import axios from "axios";

const api = process.env.REACT_APP_API_URL;
const prestacionesIinit = {
  deducciones: [],
  prestaciones: [],
  otrosPagos: [],
};
const initial = {
  codigo: "",
  clave: "",
  descripcion: "",
  tipo: "Extraordinaria",
  monto: 0,
  detalle: {},
};

const reducerPercepcion = (state, e) => {
  let current = { ...state };
  switch (e.action) {
    case "clear":
      current = initial;
      return current;
    case "setCodigo":
      current.codigo = e.value.codigo;
      current.clave = e.value.clave;
      return current;
    case "setDesc":
      current.descripcion = e.value;
      return current;
    case "setTipo":
      current.tipo = e.value;
      return current;
    case "setMonto":
      current.monto = parseFloat(e.value);
      return current;

    default:
      return current;
  }
};
const prestacionesTypes = {
  percepciones: "Percepciones",
  deducciones: "Deducciones",
  otrosPagos: "Otros Pagos",
};
const PercepcionesModal = ({ toggle, typeModal, colaborador = { prestacionesExtraordinarias: prestacionesIinit }, onUpdate }) => {
  const [type, setType] = useState("extraordinaria");
  const [percepciones, setPercepciones] = useState([]);
  const [percepctionSelect, setPercepcionSelect] = useState("");
  const [percepcionCola, setPerceptionCola] = useState([]);
  const params = useParams();
  const location = useLocation();
  const [prestacion, dispacherPrestacion] = useReducer(reducerPercepcion, initial);
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {
    setPerceptionCola(colaborador.prestacionesExtraordinarias[typeModal] || []);
    getPercepciones();
  }, []);

  const getPercepciones = async () => {
    let query = `catalogo=${typeModal}&sat=true`;
    if (typeModal === "percepciones") {
      query += `&tipo=Extraordinaria`
    }
    let { data } = await axios.get(`${api}/catalogos/?${query}`, headers);
    setPercepciones(data.response);
  };

  const agregarPrestacion = async () => {
    let { state } = location;
    let query = `?empresaId=${params.idEmpresa}&`;
    query += `prestacion=${typeModal}&`;
    query += `periodicidad=${state.periodicidad}&`;
    query += `fechaInicio=${state.fechaInicio}&fechaFin=${state.fechaFin}`;

    let { data } = await axios.post(`${api}/nomina/extraordinarias/${colaborador._id}/${query}`, prestacion, headers);
    setPerceptionCola(data.response);
    onUpdate();
    dispacherPrestacion({ action: "clear" });
  };

  const removeItem = async (codigo) => {
    let { state } = location;
    let query = `?empresaId=${params.idEmpresa}&`;
    query += `prestacion=${typeModal}&`;
    query += `prestacionExtraordinaria=${codigo}&`;
    query += `periodicidad=${state.periodicidad}&`;
    query += `fechaInicio=${state.fechaInicio}&fechaFin=${state.fechaFin}`;

    let { data } = await axios.delete(`${api}/nomina/extraordinarias/${colaborador._id}/${query}`, headers);
    setPerceptionCola(data.response);
    onUpdate();
    dispacherPrestacion({ action: "clear" });
  };

  return (
    <Modal centered isOpen={true} toggle={toggle} className={"modal-"} size="lg" style={{ maxWidth: "1600px", width: "80%", margin: "10px auto" }}>
      <ModalHeader style={{ background: "#2B388F", color: "white" }} toggle={toggle}>
        {prestacionesTypes[typeModal]} de {colaborador.nombre}
      </ModalHeader>
      <StyleInputs>
        <ModalBody>
          <Row className="mb-3">
            <Col>
              <Nav tabs>
                <NavItem>
                  <NavLink style={{ textDecoration: "none", color: "#D8D8D8" }} onClick={() => setType("extraordinaria")} className={`${type === "extraordinaria" && "active"}`}>
                    Extraordinaria
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  defaultValue=""
                  value={prestacion.codigo}
                  onChange={(e) =>
                    dispacherPrestacion({
                      action: "setCodigo",
                      value: percepciones.find((p) => p.codigo === e.target.value),
                    })
                  }
                >
                  <option value="">Seleccione..</option>
                  {percepciones.map((p) => (
                    <option value={p.codigo} key={p.codigo}>
                      {p.descripcion}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>

            {type === "extraordinaria" && (
              <>
                <Col>
                  <FormGroup>
                    <Input
                      type="text"
                      name="descripcion"
                      placeholder="Descripción"
                      value={prestacion.descripcion}
                      onChange={(e) => dispacherPrestacion({ action: "setDesc", value: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
            <Col>
              <FormGroup>
                <Input
                  type="number"
                  name="number"
                  placeholder="Monto"
                  value={prestacion.monto}
                  onChange={(e) => dispacherPrestacion({ action: "setMonto", value: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Button color="primary" block onClick={agregarPrestacion}>
                  Agregar
                </Button>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table color={"white"} style={{ background: "white" }}>
                <thead>
                  <tr>
                    <th>Codigo</th>
                    <th>Clave</th>
                    <th>Descripción</th>
                    <th>Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {percepcionCola.map((d, i) => (
                    <tr key={i}>
                      <th>{d.codigo}</th>
                      <td>{d.clave}</td>
                      <td>{d.descripcion}</td>
                      <td>{d.monto}</td>
                      <td className="text-rigth">
                        <FontAwesomeIcon icon={faTrash} cursor="pointer" onClick={() => removeItem(d.codigo)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>
      </StyleInputs>
    </Modal>
  );
};

PercepcionesModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  colaborador: PropTypes.object,
  typeModal: PropTypes.string,
};

export default PercepcionesModal;
