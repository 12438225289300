import React, { useEffect } from 'react';
import { Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CollapsePeronal, CollapseLaboral, CollapseUtilitario } from '../../ui/Collapse';
import InputFileToUpload from '../../ui/InputFileToUpload';
import { fetchConfig } from '../../../store/sucursalDuck';



const FormExpedienteDigital = () => {
  let subidosObligPersonal = 0;
  let subidosObligLaboral = 0;
  let subidosObligUtilitario = 0;
  let subidosOpcPersonal = 0;
  let subidosOpcLaboral = 0;
  let subidosOpcUtilitario = 0;
  const dispatch = useDispatch();
  const { pertenencia = {} } = useSelector((state) => state.colaborador);
  const { permisos = {} } = useSelector((state) => state.user);

  useEffect(() => {
    let idSuc = '';
    if (Object.keys(pertenencia).length !== 0) {
      idSuc = pertenencia.sucursal._id;
    }
    (async () => {
      try {
        await dispatch(fetchConfig(idSuc));
      } catch (error) { }
    })();
  }, [dispatch]);
  const configSuc = useSelector((state) => state.sucursal.configuracion);
  const { documentos = [] } = useSelector((state) => state.colaborador);
  let acomodar = {};
  configSuc.forEach((element) => {
    acomodar[element.nombre] = element;
  });
  documentos.forEach((element) => {
    if (typeof acomodar[element.nombre] !== "undefined") {
      acomodar[element.nombre] = element;
    }
  });
  const configuracion = Object.values(acomodar);
  const personal = [];
  const laboral = [];
  const utilitario = [];
  const totalObligPersonal = [];
  const totalObligLaboral = [];
  const totalObligUtilitario = [];
  const totalOpcPersonal = [];
  const totalOpcLaboral = [];
  const totalOpcUtilitario = [];
  configuracion.forEach((element) => {
    if (element.seccion === 'Personal') {
      personal.push(element);
      if (element.archivo && element.obligatorio === true) {
        subidosObligPersonal++
      }
      if (element.archivo && element.obligatorio === false) {
        subidosOpcPersonal++
      }
    } if (element.seccion === 'Utilitario') {
      utilitario.push(element);
      if (element.archivo && element.obligatorio === true) {
        subidosObligUtilitario++
      }
      if (element.archivo && element.obligatorio === false) {
        subidosOpcUtilitario++
      }
    } if (element.seccion === 'Laboral') {
      laboral.push(element);
      if (element.archivo && element.obligatorio === true) {
        subidosObligLaboral++
      }
      if (element.archivo && element.obligatorio === false) {
        subidosOpcLaboral++
      }
    }
  });
  configuracion.forEach((element) => {
    // Total obligatorios
    if (element.seccion === 'Personal' && element.obligatorio === true) {
        totalObligPersonal.push(element);
    } if (element.seccion === 'Utilitario' && element.obligatorio === true) {
        totalObligUtilitario.push(element);
    } if (element.seccion === 'Laboral' && element.obligatorio === true) {
        totalObligLaboral.push(element);
    }
    // Total opcionales
    if (element.seccion === 'Personal' && element.obligatorio === false) {
      totalOpcPersonal.push(element);
    } if (element.seccion === 'Utilitario' && element.obligatorio === false) {
      totalOpcUtilitario.push(element);
    } if (element.seccion === 'Laboral' && element.obligatorio === false) {
      totalOpcLaboral.push(element);
    }
  });
  const objetoPersonal = {
    obligatoriosSubidos: subidosObligPersonal,
    obligatoriosTotal: totalObligPersonal.length,
    opcionalesSubidos: subidosOpcPersonal,
    opcionalesTotal: totalOpcPersonal.length,
    totalSubidos: subidosObligPersonal + subidosOpcPersonal,
    totalTotal: personal.length
  }
  const objetoUtilitario = {
    obligatoriosSubidos: subidosObligUtilitario,
    obligatoriosTotal: totalObligUtilitario.length,
    opcionalesSubidos: subidosOpcUtilitario,
    opcionalesTotal: totalOpcUtilitario.length,
    totalSubidos: subidosObligUtilitario + subidosOpcUtilitario,
    totalTotal: utilitario.length
  }
  const objetoLaboral = {
    obligatoriosSubidos: subidosObligLaboral,
    obligatoriosTotal: totalObligLaboral.length,
    opcionalesSubidos: subidosOpcLaboral,
    opcionalesTotal: totalOpcLaboral.length,
    totalSubidos: subidosObligLaboral + subidosOpcLaboral,
    totalTotal: laboral.length
  }
  console.log(objetoPersonal);

  return (
    <Form >
      <CollapsePeronal objeto={objetoPersonal}>
        {personal.map(({ obligatorio, expiracion, nombre, archivo, fechaExpiracion,historial }, index) => (
          <InputFileToUpload
            archivo={archivo}
            nombre={nombre}
            fecha={fechaExpiracion}
            expiracion={expiracion}
            key={nombre + index}
            id={nombre + index}
            name={`test.[${index}].nombre`}
            seccion='Personal'
            obligatorio={obligatorio}
            historial={historial}
            disabled={permisos.colaboradorExpediente !== 'write'}
          />
        ))
        }
      </CollapsePeronal>
      <br />
      <br />
      <CollapseLaboral objeto={objetoLaboral} array={laboral}>
        {laboral.map(({ obligatorio, expiracion, nombre, archivo, fechaExpiracion,historial }, index) => (
          <InputFileToUpload
            archivo={archivo}
            nombre={nombre}
            fecha={fechaExpiracion}
            expiracion={expiracion}
            key={nombre + index}
            id={nombre + index}
            name={`test.[${index}].nombre`}
            seccion='Laboral'
            obligatorio={obligatorio}
            historial={historial}
            disabled={permisos.colaboradorExpediente !== 'write'}
          />
        ))
        }
      </CollapseLaboral>
      <br />
      <br />
      <CollapseUtilitario objeto={objetoUtilitario} array={utilitario}>
        {utilitario.map(({ obligatorio, expiracion, nombre, archivo, fechaExpiracion,historial }, index) => (
          <InputFileToUpload
            archivo={archivo}
            fecha={fechaExpiracion}
            nombre={nombre}
            expiracion={expiracion}
            key={nombre + index}
            id={nombre + index}
            name={`test.[${index}].nombre`}
            seccion='Utilitario'
            obligatorio={obligatorio}
            historial={historial}
            disabled={permisos.colaboradorExpediente !== 'write'}
          />
        ))
        }
      </CollapseUtilitario>
    </Form>
  );
};

export default FormExpedienteDigital;
