import React from 'react';
import { Fragment } from 'react';
import {
	Table,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import CurrencyFormat from 'react-currency-format';

const ModalRecibo = ({ 
	openRecibo, 
	setOpenRecibo, 
	calPercepcion={},
	calDeduccion=[],
	montoDeduccion,
	objetoTotales 
}) => {
	let arrayPercepciones;
	if(Object.keys(calPercepcion).length){
		const {percepciones} = calPercepcion;
		arrayPercepciones=percepciones;
	}

	return (
		<Fragment>
			<Modal isOpen={openRecibo} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Resumen finiquito
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setOpenRecibo(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					{Object.keys(calPercepcion).length ?
						<>
							<div style={{ width: '50%', float: 'left' }}>
								<Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
									<thead>
										<tr>
											<th>Percepciones</th>
										</tr>
									</thead>
									<tbody>
										{arrayPercepciones.map((d, i) => (
											<tr key={i}>
												<td>{d.clave || d.codigo}</td>
												<td>{d.descripcion}</td>
												<CurrencyFormat
													value={d.monto}
													displayType={'text'}
													decimalScale={2}
													thousandSeparator={true}
													prefix={'$'}
													renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
												/>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
							<div style={{ width: '50%', float: 'right' }}>
								<Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
									<thead>
										<tr>
											<th>Deducciones</th>
										</tr>
									</thead>
									<tbody>
										{calDeduccion.map((d, i) => (
											<tr key={i}>
												<td>{d.clave || d.codigo}</td>
												<td>{d.descripcion}</td>
												<CurrencyFormat
													value={d.monto}
													displayType={'text'}
													decimalScale={2}
													thousandSeparator={true}
													prefix={'$'}
													renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
												/>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
							<div style={{width: '51%',float: 'left', textAlign: 'end' }}>
									<CurrencyFormat
										value={calPercepcion.total}
										displayType={'text'}
										decimalScale={2}
										thousandSeparator={true}
										prefix={'$'}
										renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Suma de Percepciones: {value}</h4>}
									/>
								</div>
							<div style={{ width: '49%', float: 'right', textAlign: 'end' }}>
								<CurrencyFormat
									value={montoDeduccion}
									displayType={'text'}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$'}
									renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Suma de Deducciones: {value}</h4>}
								/>
								<CurrencyFormat
									value={calPercepcion.total - montoDeduccion}
									decimalScale={2}
									displayType={'text'}
									thousandSeparator={true}
									prefix={'$'}
									renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Total: {value}</h4>}
								/>
							</div>
						</>
						: null}
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default ModalRecibo;