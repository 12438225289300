import React from 'react';
import { Tr } from '../ui/styles/styles';

const TableBodyConciliaciones = React.memo(({ listaConciliaciones = [], action, setAction, tipoReporte }) => {
    return listaConciliaciones.map((conciliacion) => {
        const {nombre, curp, nss, _id, fecha} = conciliacion;

        if(tipoReporte === 'EMA-IMSS')
        return <Tr className='table-non' id={_id} key={_id}>
            <td><div>{nombre}</div></td>
            <td><div>{fecha}</div></td>
        </Tr>
        else
        return <Tr className='table-non' id={_id} key={_id}>
            <td><div>{nombre}</div></td>
            <td><div>{curp}</div></td>
            <td><div>{nss}</div></td>
        </Tr>
    });
});

export default TableBodyConciliaciones