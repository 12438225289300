import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaPermisosLaborales } from '../../../constans/yupSchemas';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
  TableContainer
} from '../VacacionesViews/styled';
import { fetchUpdatePermisos, deletePermiso, fetchListaPermisos } from '../../../store/permisosLaboralesDuck';
import ButtonDowload from '../../ui/ButtonDowload';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import TableBodyExtenciones from './TableBodyExtenciones';
import TableCommon from '../VacacionesViews/TableVacaciones';
import CustomInput from 'reactstrap/lib/CustomInput';

const tiposPermisos = [
  { value: 'Con goce de sueldo', label: 'Con goce de sueldo' },
  { value: 'Sin goce de sueldo', label: 'Sin goce de sueldo' },
  { value: 'Otros', label: 'Otros' },
];

const FormEditPermisosLaborales = React.memo(({ history }) => {
  const [otros, setOtros] = useState(false);
  const [haySuplente, setHaySuplente] = useState(false);
  const dispatch = useDispatch();
  const { permiso = {} } = useSelector((state) => state.permisos);
  const { permisos = {} } = useSelector((state) => state.user);
  const {
    periodoInicial = '',
    periodoFinal = '',
    observaciones = '',
    personaAutoriza = '',
    suplente = '',
    datosSuplente = {},
    permisoOtros = '',
    tipoPermiso = '',
    solicitudPermiso = '',
    autorizacionPermiso = '',
    extensionPermiso = [],
    calendario = ''
  } = permiso;
  const {
    nombreColaborador = '',
    puesto = '',
    telefono = '',
    email = '',
  } = datosSuplente;
  useEffect(() => {
    if (suplente === true) {
      setHaySuplente(true);
    }
    if (permisoOtros != '') {
      setOtros(true);
    }
  }, [dispatch])
  const { register, handleSubmit, errors, control, reset } = useForm({
    resolver: yupResolver(schemaPermisosLaborales),
  });

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const [isEdit, setIsEdit] = useState(true);
  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(deletePermiso());
        await dispatch(fetchListaPermisos());
        history.push('/');
      }
    } catch (error) { }
  };
  const onSubmit = async (data) => {
    try {
      await dispatch(fetchUpdatePermisos(data));
      SweetAlert({ title: 'Permiso actualizado', icon: 'success' });
      history.push('/');
    } catch (error) {
      SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    }
  };

  const handleTipoPermiso = (value) => {
    let valor = value.target.value;
    console.log(valor);
    if (valor === 'Otros') {
      setOtros(true);
    } else {
      setOtros(false);
    }
  };
  const clickSuplente = e => {
    let value = e.target.value;
    if (value === 'true') {
      setHaySuplente(true);
    } else {
      setHaySuplente(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.push('/');
            }}
          />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorPermisos !== 'write'}>
          <Icons onClick={elminateUtil} />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorPermisos !== 'write'}>
          <Icons
            pencil
            onClick={() => {
              setIsEdit((state) => !state);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Inicio*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={moment(periodoInicial).utc().format('YYYY-MM-DD')}
            name='periodoInicial'
            type='date'
            ref={register}
          />
          {err.periodoInicial && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de inicio es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Fecha de Conclusión*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={moment(periodoFinal).utc().format('YYYY-MM-DD')}
            name='periodoFinal'
            type='date'
            ref={register}
          />
          {err.periodoFinal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de conclusión es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        {/* </FormGroup>
        <FormGroup row> */}
        <Label sm={3}>
          <LabelForms>Tipo de Permiso*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CustomSelect
            onChange={(value) => handleTipoPermiso(value)}
            disabled={isEdit}
            name='tipoPermiso'
            type='select'
            defaultValue={tipoPermiso}
            innerRef={register}
            control={control}
            options={tiposPermisos}
          />
          {err.tipoPermiso && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo de permiso es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        {otros
          ? <>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Especifica</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                disabled={isEdit}
                defaultValue={permisoOtros}
                name='permisoOtros'
                type='text'
                placeholder='Especifica'
                ref={register}
              />
            </Col>
            <Col sm={4}></Col>
          </>
          : null
        }

        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Suplente*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <Label sm={2} check style={{ marginLeft: '20px' }}>
            <CustomInput disabled={isEdit} id='suplenteR1' type='radio' defaultChecked={suplente === true} name='suplente' value='true' innerRef={register()} onClick={clickSuplente} />
              Si
            </Label>
          <Label sm={2} check style={{ marginLeft: '40px' }}>
            <CustomInput disabled={isEdit} id='suplenteR2' type='radio' defaultChecked={suplente === false} name='suplente' value='false' innerRef={register()} onClick={clickSuplente} />
              No
            </Label>
          {err.suplente && (
            <FormGroup row>
              <Label>
              </Label>
              <Col sm={9}>
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Suplente es requerido
                </span>
              </Col>
            </FormGroup>
          )}
        </Col>
        <Col sm={4}></Col>
        {haySuplente
          ? <>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Nombre del Suplente*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                disabled={isEdit}
                defaultValue={nombreColaborador}
                name='datosSuplente.nombreColaborador'
                type='text'
                placeholder='Nombre del suplente'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.nombreColaborador && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El nombre del suplente es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Puesto*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                disabled={isEdit}
                defaultValue={puesto}
                name='datosSuplente.puesto'
                type='text'
                placeholder='Puesto del suplente'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.puesto && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El puesto es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Teléfono*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                disabled={isEdit}
                defaultValue={telefono}
                name='datosSuplente.telefono'
                type='text'
                placeholder='Telefono del suplente'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.telefono && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El teléfono es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{ marginBottom: '12px' }}>
              <LabelForms>Email*</LabelForms>
            </Label>
            <Col sm={{ offset: 1, size: 4 }}>
              <InputUtils
                disabled={isEdit}
                defaultValue={email}
                name='datosSuplente.email'
                type='text'
                placeholder='Email del suplente'
                ref={register}
              />
              {err.datosSuplente && err.datosSuplente.email && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  El email es requerido
                </span>
              )}
            </Col>
            <Col sm={4}></Col>
          </>
          : null
        }
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Persona que Autoriza*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={personaAutoriza}
            name='personaAutoriza'
            type='text'
            ref={register}
            placeholder='Persona que autoriza'
          />
          {err.personaAutoriza && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La persona que autoriza es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
          <Label sm={3} style={{ marginBottom: '12px', marginTop: '7px' }}>
            <LabelForms>Agregar a Calendario*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <Label sm={2} check style={{ marginLeft: '20px' }}>
              <CustomInput id='calendario1' type='radio' name='calendario' defaultChecked={calendario === true} value='true' innerRef={register()} />
                Si
              </Label>
            <Label sm={2} check style={{ marginLeft: '40px' }}>
              <CustomInput id='calendario2' type='radio' name='calendario'  defaultChecked={calendario === false} value='false' innerRef={register()} />
                No
              </Label>
              {err.calendario && (
              <FormGroup row>
                <Label>
                </Label>
                <Col sm={9}>
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    Indique si desea mostrar este permiso en el calendario
                  </span>
                </Col>
              </FormGroup>
            )}
          </Col>
        <Col sm={4}></Col>
        <Col sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Observaciones</LabelForms>
        </Col>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            name='observaciones'
            placeholder='Observaciones'
            defaultValue={observaciones}
            ref={register}
            style={{ maxHeight: '3rem' }}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Solicitud de Permiso</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {solicitudPermiso
            ?
            !isEdit ? <InputFile disabled={isEdit} name='solicitudPermiso' id='solicitudPermiso' register={register} />
              : <ButtonDowload Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${solicitudPermiso}`} />
            : <InputFile disabled={isEdit} name='solicitudPermiso' id='solicitudPermiso' register={register} />}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Autorizacion de Permiso</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {autorizacionPermiso
            ?
            !isEdit ? <InputFile disabled={isEdit} style={{backGround: '#ccc'}} name='autorizacionPermiso' id='autorizacionPermiso' register={register} />
              : <ButtonDowload Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${autorizacionPermiso}`} />
            : <InputFile disabled={isEdit} name='autorizacionPermiso' id='autorizacionPermiso' register={register} />}
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Extensiones del Permiso</SubTitleMenu>
        </Col>
        <TableContainer style={{ marginTop: 10 }}>
          <TableCommon
            keysAceepted={['Inicio', 'Fin', 'Causa', '']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
          >
            <TableBodyExtenciones extensionPermiso={extensionPermiso} />
          </TableCommon>
        </TableContainer>
      </FormGroup>

      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          {!isEdit && (
            <SaveButton type='submit'>Guardar</SaveButton>
          )}
        </Col>
      </FormGroup>
    </Form>
  );
});

export default FormEditPermisosLaborales;
