import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import DescuentosSalarioView from './DescuentosViews/DescuentosSalarioView';
import FormDescuentoSalario from './DescuentosViews/FormDescuentoSalario';

const DescuentosSalario = () => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/descuentos/agregar' component={FormDescuentoSalario} />
                <Route path='/' component={DescuentosSalarioView} /> 
            </Switch>
        </HashRouter>
    );
};

export default DescuentosSalario;