import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import InputImage from '../../ui/InputImage';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from './styled';
import InputFile from '../../ui/InputFile';
import { schemaUtilitario } from '../../../constans/yupSchemas';
import {
  fetchUpdateUtilitario,
  fetchListaUtilitario,
  patchStatus,
  deleteUtil,
} from '../../../store/utilitarioDucks';
import { determineFileToBeUpdate } from '../../../helpers';
import ButtonDowload from '../../ui/ButtonDowload';
import ToogleSwitch from '../../ui/ToogleSwitch';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import CurrencyFormat from 'react-currency-format';
import '../Forms/CondicionesLaborales.css';
import atob from 'atob';
import { saveAs } from 'file-saver';
import axios from 'axios';


const api = process.env.REACT_APP_API_URL;

const estado = [
  { value: 'usado', label: 'Usado' },
  { value: 'nuevo', label: 'Nuevo' },
];

const ShowUtil = React.memo(({ history }) => {
  const dispatch = useDispatch();
  const { util = {} } = useSelector((state) => state.utilitario);
  const { permisos = {} } = useSelector((state) => state.user);

  const {
    nombre = '',
    cantidad = 1,
    numeroSerie = '',
    numeroFactura = '',
    color = '',
    fotos = [],
    estado: estadoUtil = '',
    entregadoPor = '',
    valorFactura = 0,
    fechaEntrega = '',
    fechaRestitucion = '',
    otrasCaracteristicas = '',
    observaciones = '',
    factura = '',
    oficioEntrega = '',
    comodato = '',
    status = '',
  } = util;
  const { register, handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(schemaUtilitario),
  });
  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const [isEdit, setIsEdit] = useState(true);
  const [valor, setValor] = useState({ valorF: valorFactura });
  const { valorF } = valor;
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const downloadPdf = async (id, e) => {
    e.preventDefault()
    if (schemaUtilitario.isValidSync(getValues())) {
      
      const save = permisos.colaboradorUniforme === 'write' ? await onSubmit(getValues()) : true;
      if (save) {
        const file = await axios.get(`${api}/utilitario/generar-oficio/utilitario?idUtilitario=${id}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `Responsiva${moment().format('YYYYMMDD')}`)
      }
    } else {
      SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  const changeStatus = async () => {
    try {
      await dispatch(patchStatus());
      await dispatch(fetchListaUtilitario('herramienta'));
      console.log('finished');
    } catch (error) { }
  };
  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(deleteUtil());
        await dispatch(fetchListaUtilitario('herramienta'));
        history.push('/');
      }
    } catch (error) { }
  };

  const save = async (data) => {
    const save = await onSubmit(data)
    if (save) {
      SweetAlert({ title: 'Utilitario actualizado', icon: 'success' });
      history.push('/');
    }
  }

  const onSubmit = async (data) => {
    try {
      data.valorFactura = !valor.valorF ? 0 : valor.valorF;
      data.numberIndexes = determineFileToBeUpdate(data.fotos);
      data.fechaEntrega = parseInt(moment(data.fechaEntrega).format('x'));
      data.fechaRestitucion = parseInt(
        moment(data.fechaRestitucion).format('x')
      );
      const save = await dispatch(fetchUpdateUtilitario(data, 'herramienta'));
      await dispatch(fetchListaUtilitario('herramienta'));
      return save
    } catch (error) {
      SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    }
  };
  return (
    <Form onSubmit={handleSubmit(save)}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.push('/');
            }}
          />
        </Col>
        <Col sm={3} className='p-2'>
          <LabelForms>{nombre}</LabelForms>
        </Col>
        <Col sm={1} hidden={permisos.colaboradorUtilitario !== 'write'}>
          <Icons onClick={elminateUtil} />
        </Col>
        <Col sm={{ offset: 4, size: 1 }} className='p-2'>
          <LabelForms>{status === 'vencido' ? 'Vencido' : 'Activo'}</LabelForms>
        </Col>
        <Col sm={{ size: 1 }}>
          <ToogleSwitch
            onChange={changeStatus}
            defaultChecked={status !== 'vencido'}
            id='switch123'
            disabled={permisos.colaboradorUtilitario !== 'write'}
          />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorUtilitario !== 'write'}>
          <Icons
            pencil
            onClick={() => {
              setIsEdit((state) => !state);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[0]'
            id='foto0'
            loadImageUrl={
              fotos[0]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[0]}`
                : ''
            }
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[1]'
            loadImageUrl={
              fotos[1]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[1]}`
                : ''
            }
            id='foto1'
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[2]'
            loadImageUrl={
              fotos[2]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[2]}`
                : ''
            }
            id='foto2'
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[3]'
            loadImageUrl={
              fotos[3]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[3]}`
                : ''
            }
            id='foto3'
            register={register}
          />
        </Col>
      </FormGroup>
      <br />
      <br />
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Nombre*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4, push: 12 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={nombre}
            name='nombre'
            type='text'
            placeholder='Ej. Macbook'
            ref={register}
          />
          {err.nombre && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Cantidad</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            name='cantidad'
            min='1'
            type='number'
            defaultValue={cantidad}
            placeholder='Ej. $15000'
            ref={register({
              min: 1,
            })}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>No. de Serie</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={numeroSerie}
            name='numeroSerie'
            type='text'
            ref={register}
            placeholder='Ej. 12yu28un'
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>No. de Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={numeroFactura}
            name='numeroFactura'
            type='text'
            placeholder='Ej. 451'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Color</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={color}
            name='color'
            type='text'
            placeholder='Ej. Plata'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Estado*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CustomSelect
            disabled={isEdit}
            defultValue={estadoUtil}
            type='select'
            name='estado'
            innerRef={register}
            control={control}
            options={estado}
          />
          {err.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Entregado por*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={entregadoPor}
            name='entregadoPor'
            type='text'
            placeholder='Entregado por'
            ref={register}
          />
          {err.entregadoPor && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Debes especificar por quien fue entregado
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Valor Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <CurrencyFormat
            disabled={isEdit}
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            value={valorF}
            name='valorFactura'
            placeholder='Valor Factura'
            innerRef={register()}
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setValor({ valorF: value })
            }}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '23px' }}>
          <LabelForms>Fecha Entrega*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={moment(fechaEntrega).format('YYYY-MM-DD')}
            name='fechaEntrega'
            type='date'
            placeholder='Fecha entrega'
            ref={register}
          />
          {err.fechaEntrega && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de entrega es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Fecha Restitución*</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={isEdit}
            defaultValue={moment(fechaRestitucion).format('YYYY-MM-DD')}
            name='fechaRestitucion'
            type='date'
            placeholder='Fecha restitución'
            ref={register}
          />
          {err.fechaRestitucion && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La fecha de restitución es requerida
            </span>
          )}
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Otras Características</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            disabled={isEdit}
            defaultValue={otrasCaracteristicas}
            name='otrasCaracteristicas'
            placeholder='Otras caracteristicas'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '21px' }}>
          <LabelForms>Observaciones Generales</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            disabled={isEdit}
            defaultValue={observaciones}
            name='observaciones'
            placeholder='Observaciones generales'
            ref={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} className='mb-3'>
          <SaveButton onClick={(e) => downloadPdf(util._id, e)}>Generar Responsiva</SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: '1.5rem', marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
        <Label sm={3}>
          <LabelForms>Factura</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {isEdit ? (
            <ButtonDowload
              Url={
                factura
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${factura}`
                  : ''
              }
            />
          ) : <InputFile name='factura' id='factura' register={register()} />
          }
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Oficio de Entrega</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {isEdit ? (
            <ButtonDowload
              Url={
                oficioEntrega
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${oficioEntrega}`
                  : ''
              }
            />
          ) : <InputFile name='oficioEntrega' id='oficioEntrega' register={register()} />
          }
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          <LabelForms>Comodato</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          {isEdit ? (
            <ButtonDowload
              Url={
                comodato
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${comodato}`
                  : ''
              }
            />
          ) : <InputFile name='comodato' id='comodato' register={register({ required: true })} />
          }
        </Col>
      </FormGroup>
      <br /><br />
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          {!isEdit && (
            <SaveButton type='submit'>Guardar</SaveButton>
          )}
        </Col>
      </FormGroup>
    </Form>
  );
});

export default ShowUtil;
