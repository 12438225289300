import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { get } from '../../functions/restAxios';
import { Card, HeaderStyle } from "./styles";
import { Col, Row } from "reactstrap";
import GenderMetric from '../../components/Metricas/GenderMetric';
import HorizontalBar from '../../components/Metricas/HorizontalBar';
import VerticalBar from '../../components/Metricas/VerticalBar';
import VerticalBarOrd from '../../components/Metricas/VerticalBarOrd';
import ScatterPlot from '../../components/Metricas/ScatterPlot';
import colors from './colors';
import TablePlot from '../../components/Metricas/TablePlot';
import CalendarPlot from '../../components/Metricas/CalendarPlot';
import TermometerPlot from '../../components/Metricas/TermometerPlot';

const Metricas = () => {
    const [colaboradores, setColaboradores] = useState([])

    useEffect(() => {
        get(`${process.env.REACT_APP_API_URL}/metricas/personales/`, r => setColaboradores(r.response), () => { }, true)
    }, [])

    const getWidth = (id) => document.getElementById(id).clientWidth
    const height = 300

    // const categories = (collection, name) => _.uniq(collection.map(d => d[name]));

    const ageCategories = (collection, name) => collection.reduce((a, v) => {
        if (v[name] <= 25) a[0].c.push(v)
        else if (v[name] > 60) a[a.length - 1].c.push(v)
        else {
            const groupIndex = Math.ceil((v[name] - 25) / 5)
            a[groupIndex].c.push(v)
        }

        return a
    }, [{ c: [], g: '<= 25' }, { c: [], g: '25 - 30' }, { c: [], g: '30-35' }, { c: [], g: '35-40' }, { c: [], g: '40-45' }, { c: [], g: '45-50' }, { c: [], g: '50-55' }, { c: [], g: '55-60' }, { c: [], g: '> 60' }])

    const solos = ['Soltero', 'Divorciado', 'Separado', 'Viudo'];
    const estadoCivilCategories = (collection, name) => collection.reduce((a, v) => {
        solos.includes(v[name]) ? a.Solos.push(v) : a.Pareja.push(v);
        return a;
    }, { Solos: [], Pareja: [] });

    return (
        <>
            <HeaderStyle>
                <h1>Metricas</h1>
                <h2>Personales</h2>
            </HeaderStyle>
            <Row>
                <Col className="col-xl-4 px-1 my-4">
                    <HeaderStyle>
                        <h3 style={{ color: '#525151' }}>Genero</h3>
                    </HeaderStyle>
                    <Card id="plot-card-1" className="my-2">
                        {
                            colaboradores.length ? (
                                <GenderMetric colors={colors} width={getWidth('plot-card-1')} height={height} colaboradores={colaboradores} />
                            ) : null
                        }
                    </Card>
                </Col>
                <Col className="col-xl-8 px-1 my-4">
                    <HeaderStyle>
                        <h3 style={{ color: '#525151' }}>Edades</h3>
                    </HeaderStyle>
                    <Card id="plot-card-2" className="my-2">
                        {
                            colaboradores.length ? (
                                <VerticalBarOrd colors={colors} width={getWidth('plot-card-2')} height={height} xOffset={30} yOffset={60} categorias={ageCategories(colaboradores, 'edad')} />
                            ) : null
                        }
                    </Card>
                </Col>
                <Col className="col-xl-2 px-1 my-4">
                    <HeaderStyle>
                        <h3 style={{ color: '#525151' }}>Educación</h3>
                    </HeaderStyle>
                    <Card id="plot-card-3" className="my-2">
                        {
                            colaboradores.length ? (
                                <TablePlot colors={colors} width={getWidth('plot-card-3')} height={height} filtro="educacion" colaboradores={colaboradores} categorias={['Ninguno', 'Primaria', 'Secundaria', 'Bachillerato', 'Licenciatura', 'Posgrado']} />
                            ) : null
                        }
                    </Card>
                </Col>
                <Col className="col-xl-6 px-1 my-4">
                    <HeaderStyle>
                        <h3 style={{ color: '#525151' }}>Cumpleaños</h3>
                    </HeaderStyle>
                    <Card id="plot-card-4" className="my-2">
                        {
                            colaboradores.length ? (
                                <CalendarPlot colors={colors} width={getWidth('plot-card-4')} height={height} filtro="fechaNacimiento" colaboradores={colaboradores} />
                            ) : null
                        }
                    </Card>
                </Col>
                <Col className="col-xl-4 px-1 my-4">
                    <HeaderStyle>
                        <h3 style={{ color: '#525151' }}>Estado Civil</h3>
                    </HeaderStyle>
                    <Card id="plot-card-5" className="my-2">
                        {
                            colaboradores.length ? (
                                <TermometerPlot colors={colors} width={getWidth('plot-card-1')} height={height} colaboradores={estadoCivilCategories(colaboradores, 'estadoCivil')} />
                            ) : null
                        }
                    </Card>
                </Col>
            </Row>
            {/*<HeaderStyle>
                <h2>Empresariales</h2>
            </HeaderStyle>
            <Row>
                <Col className="col-xl-6 px-1">
                    <HeaderStyle>
                        <h3>Departamentos</h3>
                    </HeaderStyle>
                    <Card id="plot-card-4" className="my-2">
                        {
                            colaboradores.length ? (
                                <VerticalBar width={getWidth('plot-card-4')} height={height} xOffset={30} yOffset={60} filtro="departamento" colaboradores={colaboradores} categorias={categories(colaboradores, 'departamento')} />
                            ) : null
                        }
                    </Card>
                </Col>
                <Col className="col-xl-6 px-1">
                    <HeaderStyle>
                        <h3>Sueldo vs Antiguedad</h3>
                    </HeaderStyle>
                    <Card id="plot-card-5" className="my-2">
                        {
                            colaboradores.length ? (
                                <ScatterPlot width={getWidth('plot-card-5')} height={height} filtroX="sueldo" filtroY="antiguedad" xOffset={90} yOffset={70} colaboradores={colaboradores} />
                            ) : null
                        }
                    </Card>
                </Col> 
            </Row>*/}
        </>
    );
}

export default Metricas;
