import { yupResolver } from "@hookform/resolvers/yup";
import axios from '../../../../services/axios';
import React, { useState, useEffect } from "react";
import CurrencyFormat from 'react-currency-format';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Col, CustomInput, Form, FormGroup, Input, Label } from "reactstrap";
import Row from "reactstrap/lib/Row";
import optionsBancos from "../../../../constans/bancos";
import { optionsJornada, optionsModalidadPago, optionsRegimen, optionsRiesgo, optionsTipoCuentaPago, optionsMetodoPago } from "../../../../constans/informacionNomina";
import { schemaInformacionNomina } from "../../../../constans/yupSchemas";
import { updateColaborador } from "../../../../store/colaboradorDucks";
import CustomSelect from "../../../ui/CustomSelect";
import { SaveButton } from "../../../ui/styles/styles";
import SweetAlert from "../../../ui/SweetAlet";
import '../CondicionesLaborales.css';

const api = process.env.REACT_APP_API_URL;

const FormEditInformacionNomina = ({ onChange = () => { } }) => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const { InformacionNomina = {}, pertenencia } = useSelector((state) => state.colaborador);
  const { permisos = {} } = useSelector((state) => state.user);
  const {
    modalidadDePago = "",
    banco = "",
    tipoCuentaPago = "",
    numeroCuentaBancaria = "",
    sindicalizado = false,
    diasVacaciones = 0,
    diasAguinaldo = 0,
    regimen = '',
    salarioBase = 0,
    salarioDiario = 0,
    riesgoPuesto = "",
    tipoJornada = "",
    numeroSeguroSocial = 0,
    aplicaPtu = false,
    metodoDePago = "",
    registroPatronal = ""
  } = InformacionNomina;

  const [tipoPago, setTipoPago] = useState(tipoCuentaPago);
  const [bancos, setBancos] = useState(banco);
  const [modalidadPago, setModalidadPago] = useState(modalidadDePago);
  const [showPresatacion, setShowPrestacion] = useState(false);
  const [tipoPrestacion, setTipoPrestacion] = useState("");
  const [color, setColor] = useState('#2b388f')
  const [salD, setSalD] = useState({ salDiario: salarioDiario });
  const [salB, setSalB] = useState({ salBase: salarioBase })
  const yo = useSelector((state) => state.colaborador);
  const { salBase } = salB;
  const { salDiario } = salD;
  const [metodoPago, setMetodoPago] = useState(metodoDePago);
  const [registros, setRegistros] = useState([]);
  const addTipoPago = (e) => {
    onChange(e);
    setTipoPago(e.target.value);
  };
  const addBancos = (e) => {
    onChange(e);
    setBancos(e.target.value);
  };
  const addModalidadPago = (e) => {
    onChange(e);
    setModalidadPago(e.target.value);
  };
  const addMetodoPago = (e) => {
    onChange(e);
    setMetodoPago(e.target.value);
  };
  const { register, handleSubmit, errors, control, getValues } = useForm({
    mode: 'onBlur', resolver: yupResolver(schemaInformacionNomina),
  });

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) {
      if (!salD.salDiario) errors.salarioDiario = 'Salario diario requerido'
      if (!salB.salBase) errors.salarioBase = 'Salario base requerido'
      setErr(errors);
    }
  }, [errors]);

  const registrosToDwopDown = async (pertenencia) =>{
    const { data } = await axios.get(`${api}/empresa/obtener_registros/${pertenencia.empresa}`, headers)
    if(data){
      const rp = data.response.map(r => ({label: r, value: r}));
      setRegistros(rp)
    }
    console.log('asdc');
  }

  useEffect(() =>{
    if(pertenencia.empresa)
      registrosToDwopDown(pertenencia)
  }, [pertenencia])

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    try {
      console.log(data)
      data.salarioBase = salB.salBase;
      data.salarioDiario = salD.salDiario;
      const updateData = { InformacionNomina: { ...data } };

      const tipo = getValues().tipoCuentaPago ? getValues().tipoCuentaPago : tipoCuentaPago;
      const numCuenta = getValues().numeroCuentaBancaria ? getValues().numeroCuentaBancaria : numeroCuentaBancaria;
      if (tipo === 'CLABE Interbancaria' && numCuenta.length !== 18) return setColor('red');
      if (tipo === 'Cuenta bancaria' && !(numCuenta.length === 11 || numCuenta.length === 10)) return setColor('red');
      if (tipo === 'Tarjeta bancaria' && numCuenta.length !== 16) return setColor('red');

      setColor('#2b388f')

      await dispatch(updateColaborador(updateData));
      await SweetAlert({
        icon: "success",
        title: "Se actualizó Información Nómina correctamente",
      });

    } catch (error) {
      await SweetAlert({ icon: "error", title: "Error!", text: error });
      console.log(error);
    }
  };

  const validateBankAccountType = () => {
    const tipo = getValues().tipoCuentaPago ? getValues().tipoCuentaPago : tipoCuentaPago;
    let text = 'Selecciona el tipo de cuenta de pago';
    if (tipo === 'CLABE Interbancaria') text = 'La CLABE debe contener 18 digitos';
    if (tipo === 'Cuenta bancaria') text = 'La Cuenta debe contener 10 u 11 digitos';
    if (tipo === 'Tarjeta bancaria') text = 'La Tarjeta debe contener 16 digitos';
    return <span style={{ fontSize: 11, color, marginLeft: 10 }}>{text}</span>
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label for='regimen' sm={3}>
          Régimen*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="regimen"
            id="regimen"
            defaultValue={regimen}
            innerRef={register({ required: true })}
            //invalid={errors.regimen ? true : false}
            onChange={onChange}
            control={control}
            options={optionsRegimen}
          />
          {err.regimen && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El régimen es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='modalidadDePago' sm={3}>
          Modalidad de Pago*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="modalidadDePago"
            defaultValue={modalidadDePago}
            id="modalidadDePago"
            innerRef={register({ required: true })}
            //invalid={errors.modalidadDePago ? true : false}
            onChange={addModalidadPago}
            control={control}
            options={optionsModalidadPago}
          />
          {err.modalidadDePago && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La modalidad de pago es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='tipoJornada' sm={3}>
          Seleccione Tipo Jornada*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="tipoJornada"
            id="tipoJornada"
            defaultValue={tipoJornada}
            innerRef={register({ required: true })}
            //invalid={errors.tipoJornada ? true : false}
            onChange={onChange}
            control={control}
            options={optionsJornada}
          />
          {err.tipoJornada && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo jornada es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='salarioDiario' sm={3}>
          Salario Diario*
        </Label>
        <Col sm={9}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            name='salarioDiario'
            value={salDiario}
            innerRef={register()}
            placeholder="Ingrese el salario diario del colaborador"
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setSalD({ salDiario: value })
            }}
          />
          {err.salarioDiario && !salD.salDiario && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario diario es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='diasAguinaldo' sm={3}>
          Días de Aguinaldo*
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='diasAguinaldo'
            defaultValue={diasAguinaldo}
            innerRef={register({ required: true })}
            placeholder='Ingrese los días de aguinaldo'
          />
          {err.diasAguinaldo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Los días de aguinaldo son requeridos
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='diasVacaciones' sm={3}>
          Días de Vacaciones*
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='diasVacaciones'
            defaultValue={diasVacaciones}
            innerRef={register({ required: true })}
            placeholder='Ingrese los días de vacaciones'
          />
          {err.diasVacaciones && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Los días de vacaciones son requeridos
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Sindicalizado*</Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="sindicalizado" id="sindicalizado1" defaultChecked={sindicalizado === true} value={true} innerRef={register()} />
          Si
        </Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="sindicalizado" defaultChecked={sindicalizado === false} id="sindicalizado2" value={false} innerRef={register()} />
          No
        </Label>
      </FormGroup>
      {err.sindicalizado && (
        <FormGroup row>
          <Label sm={3}>
          </Label>
          <Col sm={9}>
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Sindicalizado requerido
            </span>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Label sm={3}>Aplica PTU</Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="aplicaPtu" id="aplicaPtu1" defaultChecked={aplicaPtu === true} value={true} innerRef={register()} />
          Si
        </Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="aplicaPtu" defaultChecked={aplicaPtu === false} id="aplicaPtu2" value={false} innerRef={register()} />
          No
        </Label>
      </FormGroup>
      <FormGroup row>
        <Label for='numeroSeguroSocial' sm={3}>
          Número Seguro Social*
        </Label>
        <Col sm={9}>
          <Input
            type='number'
            name='numeroSeguroSocial'
            defaultValue={numeroSeguroSocial}
            innerRef={register({ required: true })}
            placeholder='Ingrese el número seguro social'
          />
          {err.numeroSeguroSocial && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número Seguro Social es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='registroPatronal' sm={3}>
          Registro Patronal*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type='select'
            name='registroPatronal'
            defaultValue={registroPatronal}
            innerRef={register({ required: true })}
            control={control}
            options={registros}
          />
          {err.registroPatronal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El registro patronal es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='salarioBase' sm={3}>
          Salario Base Cotización*
        </Label>
        <Col sm={9}>
          <CurrencyFormat
            fixedDecimalScale={true}
            decimalScale={2}
            className='inputMoneda'
            value={salBase}
            name='salarioBase'
            innerRef={register()}
            placeholder="Ingrese el salario base cotización del colaborador"
            thousandSeparator={true}
            prefix={'$'}
            onValueChange={(values) => {
              const { formattedValue, value } = values;
              setSalB({ salBase: value })
            }}
          />
          {err.salarioBase && !salB.salBase && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario base cotización es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='riesgoPuesto' sm={3}>
          Riesgo de Puesto*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="riesgoPuesto"
            defaultValue={riesgoPuesto}
            id="riesgoPuesto"
            innerRef={register({ required: true })}
            //invalid={errors.riesgoPuesto ? true : false}
            onChange={onChange}
            control={control}
            options={optionsRiesgo}
          />
          {err.riesgoPuesto && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El riesgo puesto es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label for='metodoDePago' sm={3}>
          Método de Pago*
        </Label>
        <Col sm={9}>
          <CustomSelect
            type="select"
            name="metodoDePago"
            defaultValue={metodoDePago}
            id="metodoDePago"
            innerRef={register({ required: true })}
            onChange={addMetodoPago}
            control={control}
            options={optionsMetodoPago}
          />
          {err.metodoDePago && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Método de pago es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      {
        metodoPago === 'Depósito' ?
          <React.Fragment>
            <FormGroup row>
              <Label for='banco' sm={3}>
                Banco*
        </Label>
              <Col sm={9}>
                <CustomSelect
                  type="select"
                  name="banco"
                  defaultValue={banco}
                  id="banco"
                  innerRef={register({ required: true })}
                  //invalid={errors.banco ? true : false}
                  onChange={addBancos}
                  control={control}
                  options={optionsBancos}
                />
                {err.banco && (
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    El banco es requerido
                  </span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='tipoCuentaPago' sm={3}>
                Tipo de Cuenta de Pago*
        </Label>
              <Col sm={9}>
                <CustomSelect
                  type="select"
                  name="tipoCuentaPago"
                  id="tipoCuentaPago"
                  defaultValue={tipoCuentaPago}
                  innerRef={register({ required: true })}
                  //invalid={errors.tipoCuentaPago ? true : false}
                  onChange={addTipoPago}
                  control={control}
                  options={optionsTipoCuentaPago}
                />
                {err.tipoCuentaPago && (
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    El tipo de cuenta de pago es requerido
                  </span>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='numeroCuentaBancaria' sm={3}>
                Número Cuenta Bancaria*
        </Label>
              <Col sm={9}>
                <Input
                  type="text"
                  name="numeroCuentaBancaria"
                  defaultValue={numeroCuentaBancaria}
                  innerRef={register({ required: true })}
                  placeholder="Ingrese el numero Cuenta Bancaria"
                />
                {validateBankAccountType()}
                {err.numeroCuentaBancaria && (
                  <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                    El número cuenta bancaria es requerido
                  </span>
                )}
              </Col>
            </FormGroup>
          </React.Fragment>
          : ''
      }
      <FormGroup className="text-center" hidden={permisos.colaboradorNomina !== 'write'}>
        <Row>
          <Col sm={{ offset: 9, size: 3 }}>
            <SaveButton type="submit">Guardar</SaveButton>
          </Col>
        </Row>
      </FormGroup>
    </Form >
  );
};

export default FormEditInformacionNomina;
