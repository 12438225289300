import React,{useEffect,useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";
import {Row, Col, Button} from "reactstrap";
import Search from "../ui/Search";
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import TableBody from './Demandas/TableBody';
import {getDemandasAction,buscarDemandaPorExpediente} from "../../store/demandaDuck";
import colors from '../../constans/colors';

const JuridicoDemandas = () =>{
    const dispatch = useDispatch();
	const history = useHistory();
	const { listaDemandas = {} } = useSelector(
	  (state) => state.demanda
	);
    const onSearch = useCallback(text => {dispatch(buscarDemandaPorExpediente(text))})
	useEffect(() => {
		(async () => {
		  try {
			await dispatch(getDemandasAction());
		  } catch (e) {
			//SweetAlert({ icon: 'error', title: 'Error!', text: e });
		  }
		})();
	  }, [dispatch]);
    return (
        <>
        <HeaderStyle style={{marginBottom:50}}>
            <h1>Demandas</h1>
            <h3>Esta es la lista de demandas</h3>
        </HeaderStyle>
        <Row>
            <Col lg={12}>
                <Search onChange={onSearch} placeholder="Buscar por N° de expediente "/>
            </Col>
        </Row>
        <TableContainer style={{ marginTop:10}}>
            <TableCommon 
                keysAceepted={['Expediente', 'Colaborador', 'Demandado', 'Fecha', 'Hora','Estatus','']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
            >
            <TableBody listaDemandas={listaDemandas} />
            </TableCommon>
            <Button type="button" onClick={()=>{
                history.push('nueva-demanda')
            }} 
            style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}>+ Agregar</Button>
        </TableContainer>
        </>
    )
}
export default JuridicoDemandas;