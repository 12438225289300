import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { asignarRol } from '../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { detalleRolAction, fetchAsignarRol, fetchRolesToDropdown } from '../../store/rolesDucks';
import { fetchUsuariosToDropdown } from '../../store/usuariosDuck';
import ComponentDescrip from '../ui/ComponentDescrip';
import CustomSelect from '../ui/CustomSelect';
import { SaveButton } from '../ui/styles/styles';
import SweetAlert from '../ui/SweetAlet';

const AsignarRol = () => {
  const { idRol } = useParams();
  const dispatch = useDispatch();
  const { rol, roles } = useSelector((state) => state.roles);
  const { listaUsuarios } = useSelector((state) => state.usuarios);
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(asignarRol),
  });
  const history= useHistory();
  console.log(errors);
  const { empresas } = useSelector((state) => state.empresas);

  const getDetalle = useCallback(
    async (idRol) => {
      try {
        dispatch(detalleRolAction(idRol));
        dispatch(fetchUsuariosToDropdown());
      } catch (e) {
        await SweetAlert({ icon: 'error', title: 'Error!', text: e });
      }
    },
    [dispatch]
  );
  useEffect(() => {
    getDetalle(idRol);
  }, [getDetalle, idRol]);

  const getEmpresas = useCallback(async () => {
    try {
      await dispatch(fetchEmpresasToDropdown());
      await dispatch(fetchRolesToDropdown());
    } catch (e) {
        SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  }, [dispatch]);

  useEffect(() => {
    getEmpresas();
  }, [getEmpresas]);
  const onSubmit = useCallback(
    async (data) => {
      try {
        data.rol = idRol
        await dispatch(fetchAsignarRol(data))
        SweetAlert({ icon: 'success', title: 'Asignacion usuario Exitosa'});
        history.push('/dashboard/empresa/rol')
      } catch (e) {
        SweetAlert({ icon: 'error', title: 'Error!', text: e });
      }
    },
    [dispatch]
  );

  return (
    <div>
      <Row style={{ marginBottom: '2em' }}>
        <Col lg={12}>
          <ComponentDescrip
            title='Asignar rol'
            description='Asigna aquí el rol a tu usuario'
            icon={faUsers}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <h5>Asignar rol {rol.nombreRol}</h5>
          <hr />
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label for='empresa' sm={2}>
            Empresa
          </Label>
          <Col sm={10}>
            <CustomSelect
              type='select'
              name='empresa'
              id='idEmpresa'
              control={control}
              innerRef={register({ required: true })}
              invalid={errors.empresa ? true : false}
              options={empresas}
              formText=''
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='rol' sm={2}>
            Usuario
          </Label>
          <Col sm={10}>
            <CustomSelect
              type='select'
              name='usuario'
              id='idUsuario'
              control={control}
              innerRef={register({ required: true })}
              invalid={errors.roles ? true : false}
              options={listaUsuarios}
              formText=''
            />
          </Col>
        </FormGroup>
        <Col style={{marginLeft: 'auto'}}>
          <FormGroup row>
            <SaveButton type='submit' style={{ marginTop: '2em', marginLeft: 'auto' }} >Guardar</SaveButton>
          </FormGroup>
        </Col>
      </Form>
    </div>
  )};
export default AsignarRol;
