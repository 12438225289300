import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Label, Col, CustomInput,Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import getXls from '../../services/dowloadXls';
import { fetchEmpresasToDropdown, fetchreport, cleanReportes } from '../../store/empresasDuck';
import { TitleMenu, SubTitleMenu, CancelButton, SaveButton } from '../ui/styles/styles';
import { ContainerReportes } from './styles/styles';
import CustomSelect from '../ui/CustomSelect';
import TableDinamic from '../ui/TableDinamic';
import modulos from '../../constans/modulos';
import radiosReportes from '../../constans/radiosReportes';
import Spiner from '../ui/Spiner';
import PaginationComponent from 'react-reactstrap-pagination';
import './styles/PaginationComponent.css';
import axios from '../../services/axios';

const api = process.env.REACT_APP_API_URL;

let loadingFirst = true;
const Reportes = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  
  const dispatch = useDispatch();
  const { empresas, loading, reportes = {} } = useSelector(
    (state) => state.empresas
  );
  const [radioSelected, setRadioSelected] = useState('');
  const [dataBody, setDataBody] = useState({});
  const [page, setPage] = useState(1);
  const [request, setRequest] = useState({})
  const [showPaginador, setPaginador] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterNomina, setFilterNomina] = useState(false);
  const [registros, setRegistros] = useState([]);
  const [registroSelected, setRegistroSelected] = useState('');
  const { register, control, reset, getValues } = useForm(
    {
      resolver: yupResolver(reportesSchema),
    }
  );

  // let request;
  const handleSelect = (e) => {
    let valor = e.target.value;
    console.log(valor);
    setRequest({ [valor]: 'true' });

    setFilter(valor === "historial");
    setFilterNomina(valor === "nomina")
  }

  const registrosToDwopDown = async (empresa) =>{
    const { data } = await axios.get(`${api}/empresa/obtener_registros/${empresa}`, headers)
    if(data){
      const rp = [{label: "Seleccionar...", value: ""}]
      data.response.map(r => {
        rp.push({label: r, value: r})
      });
      setRegistros(rp)
    }
    console.log('fetch registros to dd');
  }

  useEffect(() => {
    (async () => {
      console.log('new page');
      const data = getValues();
      console.log(data);
      if (request) {
        data.requestedData = request;
        data.page = page;
        setDataBody(() => data);
        await dispatch(fetchreport(data));
      }
    })();
  }, [page])

  useEffect(() => {
    (async () => {
      await dispatch(cleanReportes());
    })();
  }, [])

  const onSelectPage = (number) => {
    console.log(number)
    number = number - 1;
  };

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEmpresasToDropdown());
      } catch (error) { }
    })();
    loadingFirst = false;
  }, [dispatch]);

  if (loadingFirst) {
    return <Spiner />;
  }

  const onChange = ({ target }) => {
    const data = getValues();
    if (target.value) setRadioSelected(() => target.value);
    console.log(data);
    if (data["requestedData"]) {
      let valor = data["requestedData"];
      if(valor === "historial"){
        setFilter(true);
      }else{
        setFilter(false);
      }
    }else{
      setFilter(false);
    }
  };


  const onSubmit = async () => {
    try {
      const data = getValues();
      data.requestedData = request;
      data.registro = registroSelected;
      console.log(data);
      setDataBody(() => data);
      setPaginador(true);
      setPage(1);
      await dispatch(fetchreport(data));
    } catch (error) { 
      console.log(error);
    }
  };

  const dowloadXls = () => {
    getXls({ body: dataBody, reporte: radioSelected });
  }

  const CreateRadios = React.memo(() => {
    return (
      <>
      <FormGroup row>
        {radiosReportes[radioSelected]
          ? radiosReportes[radioSelected].map(({ label, value }) => (
            <Label sm={4} check className='radio-organize' inline='true'>
              <CustomInput
                type='radio'
                name={`requestedData`}
                onClick={(e) => handleSelect(e)}
                id='radio'
                value={value}
                checked={request[value]}
                innerRef={register()}
              />
              {label}
            </Label>
          ))
          : null}
      </FormGroup>
      {filter || filterNomina ?
      <FormGroup row>
        <Label sm={1}>Inicio</Label>
          <Col sm={3}>
            <Input
              type='date'
              name='filtroInicio'
              innerRef={register()}
            />
          </Col>
          <Label sm={1}>Fin</Label>
          <Col sm={3}>
            <Input
              type='date'
              name='filtroFin'
              innerRef={register()}
            />
          </Col>

          {filterNomina ? 
          <>
          <Label sm={1}>Registro</Label>
          <Col sm={3}>
            <Input
              type='select'
              name='registro'
              id='registro'
              value={registroSelected}
              onChange={e => setRegistroSelected(e.target.value)}
            >
              {registros && registros.length > 0 ?
                registros.map(r => (<option value={r.value}>{r.label}</option>))
              : null}
            </Input>
          </Col>
          </> : null}
      </FormGroup>
      
      :null}
      </>
    );
  });

  return (
    <ContainerReportes>
      <div style={{ minHeight: '15vh' }}>
        <TitleMenu>Reportes</TitleMenu>
        <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
          Genera reportes específicos de tus colaboradores
        </SubTitleMenu>
      </div>
      <Form>
        <FormGroup row>
          <Label for='empresa' sm={3}>
            Seleccionar Empresa
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='empresas[0]'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={empresas}
              onChange={e => registrosToDwopDown(e.target.value)}
            />
          </Col>
        {/* </FormGroup>
        <FormGroup row> */}
          <Label for='empresa' sm={3}>
            Seleccionar Reporte
          </Label>
          <Col sm={3}>
            <CustomSelect
              type='select'
              name='nombreReporte'
              id='empresa'
              control={control}
              innerRef={register({ required: true })}
              options={modulos}
              onChange={onChange}
            />
          </Col>
        </FormGroup>
        <CreateRadios />
        <FormGroup row>
        <Col sm={3}>
            <CancelButton type='button' onClick={() => {
              dispatch(cleanReportes())
              reset();
              setDataBody(() => { });
              setRadioSelected(() => '')
              setFilter(false)
            }}>Limpiar</CancelButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='button' onClick={() => onSubmit()}>
              Generar
            </SaveButton>
          </Col>
          <Col sm={{ offset: 3, size: 3 }}>
            <SaveButton type='button' onClick={() => dowloadXls()}>Exportar</SaveButton>
          </Col>
          </FormGroup>
          {/* <FormGroup row hidden={!loading} className="mt-5">
          
        </FormGroup> */}
      </Form>
      <Col sm={12}>
        {loading ? <Spiner /> : <TableDinamic reportes={reportes} />}
      </Col>
      <br />
      <br />
      <Col>
        <PaginationComponent
          size={"sm"}
          defaultActivePage={page}
          firstPageText="Primera"
          previousPageText="Anterior"
          nextPageText="Siguiente"
          lastPageText="Ultima"
          totalItems={reportes.total}
          pageSize={reportes.limit}
          onSelect={setPage}
          hidden={!showPaginador}
        />
      </Col>
    </ContainerReportes>
  );
};

export default Reportes;
