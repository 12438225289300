import React from 'react';
import TableCommon from '../ui/TableCommon';
import TableBodyConciliaciones from './TableBodyConciliaciones';

const TableConciliaciones = React.memo(({ lista = [], action, setAction, eliminarAction }) => {
    return (
        <>
            <TableCommon 
                keysAceepted={[
                    "Registro", "Periodo", "Año", "Asegurados", "Acreditados", "Importada"
                ]}>
                <TableBodyConciliaciones listaConciliaciones={lista} action={action} setAction={setAction} eliminarAction={eliminarAction} />
            </TableCommon>
        </>
    );
});

export default TableConciliaciones;
