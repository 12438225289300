import React, {useCallback,useEffect} from 'react';
import colors from '../../constans/colors';
import { Tr } from '../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {faArchive} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCitatorioExito } from '../../store/citatorioDuck';
import PulsateDot from '../ui/PulsateDot';


const TableBody = React.memo(({listaCitatorios = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((citatorio) => {
        dispatch(getCitatorioExito(citatorio))
        history.push('editar-citatorio')
    }, [dispatch]);
  


	const redireccionarCerrar = (citatorio,e) => {
		dispatch(getCitatorioExito(citatorio));
		history.push(`/dashboard/nomina/cerrar-citatorio`);
	  };
  

    return(
        <>
            {listaCitatorios.map((citatorio, index) => (
              
          <Tr
            key={citatorio._id}
          >
            <td onClick={() => showUtil(citatorio)}>{citatorio.nombreColaborador}</td>
            <td onClick={() => showUtil(citatorio)}>{citatorio.empresaCitada}</td>
            <td onClick={() => showUtil(citatorio)}>{citatorio.ciudad}</td>
            <td onClick={() => showUtil(citatorio)}>{citatorio.estado}</td>
            <td onClick={() => showUtil(citatorio)} style={{paddingLeft:50}}>{citatorio.numeroCitatorio}</td>
			      <td onClick={() => showUtil(citatorio)}>
			      {citatorio.status === 'activo' ? (
              <PulsateDot />
            ) : (
              <PulsateDot color={colors.closeStatus} time='500ms' />
            )}
			      </td>
			      <td>
				    <a href='#' 
              onClick={(e) => redireccionarCerrar(citatorio,e)} 
              style={{textDecoration:'none',color:'#3c3c3c',zIndex:100}}
              >
              <FontAwesomeIcon 
                color='#151e5d'
                icon={faArchive} 
              />
            </a>
			    </td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;