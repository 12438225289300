import styled from "styled-components";
export const Card = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  text-align: center;
  border: 3px solid #ebecf4;
  border-radius: 8px;
`;

export const HeaderStyle = styled.div`
  h1 {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 48px;
    line-height: 58.2px;
    color: #404041;
    font-style: normal;
    letter-spacing: 0.62px;
  }
  h3 {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.258333px;
    color: #9b9b9b;
    margin-bottom: 8px;
  }
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    /* identical to box height */
    letter-spacing: 0.297083px;
    color: #404041;
  }
`;

