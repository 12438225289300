import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

function getThirdMonday(startDate) {
    let startOfMonth = moment(startDate).utc().startOf('month').startOf('isoweek');
    let studyDate = moment(startDate).utc().startOf('month').startOf('isoweek').add(3, 'week');

    if (studyDate.month() == startOfMonth.month()) {
        studyDate = studyDate.subtract(1, 'w');
    }
    console.log(studyDate.format('YYYY-MM-DD'));
    return studyDate.format('YYYY-MM-DD');
}

const diasFestivos = [
    //LFT A. 74 F. I
    {
        title: 'Año Nuevo',
        display: 'list-event',
        event: 'lft',
        date: moment().startOf('year').utc().format('YYYY-MM-DD'),
        extendedProps: { description: 'Descanso obligatorio por el 1ro. de Enero' },
        className: 'lft-event'
    },
    //LFT A. 74 F. II
    {
        title: 'Conmemoración del día de la Constitución',
        display: 'list-event',
        event: 'lft',
        date: moment().startOf('year').utc().add(1,'month').format('YYYY-MM-DD'),
        extendedProps: { description: 'Descanso obligatorio por la conmemoración del 5 de Febrero' },
        className: 'lft-event'
    },
    //LFT A. 74 F. III
    {
        title: 'Conmemoración del natalicio de Benito Juárez',
        display: 'list-event',
        event: 'lft',
        date: getThirdMonday(moment().startOf('year').add(2,'month')),
        extendedProps: { description: 'Descanso obligatorio por la conmemoración del 21 de Marzo' },
        className: 'lft-event'
    },
    //LFT A. 74 F. IV
    {
        title: 'Día del trabajo',
        display: 'list-event',
        event: 'lft',
        date: moment().startOf('year').utc().add(4,'month').format('YYYY-MM-DD'),
        extendedProps: { description: 'Descanso obligatorio por la conmemoración del 1ro. de Mayo' },
        className: 'lft-event'
    },
    //LFT A. 74 F. V
    {
        title: 'Día de la Independencia',
        display: 'list-event',
        event: 'lft',
        date: moment().startOf('year').utc().add(8,'month').add(15, 'day').format('YYYY-MM-DD'),
        extendedProps: { description: 'Descanso obligatorio por la conmemoración del 16 de Septiembre' },
        className: 'lft-event'
    },
    //LFT A. 74 F. VI
    {
        title: 'Conmemoración de la Revolución Mexicana',
        display: 'list-event',
        event: 'lft',
        date: getThirdMonday(moment().startOf('year').add(10,'month')),
        extendedProps: { description: 'Descanso obligatorio por la conmemoración del 20 de Noviembre' },
        className: 'lft-event'
    },
    //LFT A. 74 F. VIII
    {
        title: 'Navidad',
        display: 'list-event',
        event: 'lft',
        date: moment().startOf('year').utc().add(11,'month').add(24, 'day').format('YYYY-MM-DD'),
        extendedProps: { description: 'Descanso obligatorio por el 25 de Diciembre' },
        className: 'lft-event'
    },
]

const allEvents = [
    { event: 'all', description: 'Todos', checked: true },
    { event: 'aniversary', description: 'Aniversario Laboral', checked: true },
    { event: 'birthday', description: 'Cumpleaños', checked: true },
    { event: 'tool', description: 'Reposición de Herramienta', checked: true },
    { event: 'uniform', description: 'Reposición de Uniforme', checked: true },
    { event: 'document', description: 'Expiración de Documento', checked: true },
    { event: 'promotion', description: 'Promoción Laboral', checked: true },
    { event: 'medical', description: 'Examen Médico', checked: true },
    { event: 'summon', description: 'Citatorio', checked: true },
    { event: 'audience', description: 'Audiencia', checked: true },
    { event: 'vacation', description: 'Vacaciones', checked: true },
    { event: 'permission', description: 'Permiso Laboral', checked: true },
    { event: 'inability', description: 'Incapacidades', checked: true },
    { event: 'agreement', description: 'Convenio', checked: true }
]

const slice = createSlice({
    name: 'events',
    initialState:{
        loading: false,
        error: null,
        lista: diasFestivos,
        dto: {},
        filter: allEvents,
        args: [],
        modal: false
    },
    reducers:{
        // Action handlers
        modalToggled: (events, action) => {
            events.modal = !events.modal
        },
        eventsRequested: (events, action) => {
            events.args = events.filter.map(f => {if (f.checked) return f.event})
            events.loading = true;
        },
        eventsReceived: (events, action) => {
            events.lista = diasFestivos;
            const eventos = action.payload.response;
            eventos.map(e => {
                const dto = {...e,
                    date: (e.event === 'aniversary' || e.event === 'birthday') ? moment(e.date).set('year', moment().get('year')).format('YYYY-MM-DD') : e.date,
                    className: e.event + '-event',
                    display: e.display === 'block' ? 'block' : 'list-item'
                }
                events.lista.push(dto);
            })

            events.loading = false;
        },
        eventsRequestedFailed: (events, action) => {
            events.loading = false;
        },
        filterDeleted: (events, action) =>{
            const { remove } = action.payload;
            let newFilters = [];
            if( remove.event === 'all'){
                if(remove.checked === true)
                newFilters = events.filter.filter(f => {
                    f.checked = false;
                    return f
                });
            } else {
                newFilters = events.filter.filter(f => {
                    if(f.event !== remove.event && f.event !== 'all') return f
                    f.checked = false;
                    return f
                });
            }
            events.filter = newFilters;
        },
        filterAdded: (events, action) => {
            const { add } = action.payload;
            let newFilters = [];
            if( add.event === 'all'){
                newFilters = events.filter.filter(f => {
                    f.checked = true;
                    return f
                });
            } else { 
                newFilters = events.filter.filter(f => {
                    if (f.event !== add.event) return f
                    f.checked = true;
                    return f
                });
            }
            events.filter = newFilters;
        },
        eventAdded: (events, action) => {
            console.log(action.payload);
            const {dto} = action.payload;
        }
    }
});

// Actions
const { eventsRequested, eventsReceived, eventsRequestedFailed, filterDeleted, filterAdded, modalToggled, eventAdded } = slice.actions;

export default slice.reducer;

//Action Creators
export const toggleModal = () =>(dispatch, getState) => {
    dispatch(modalToggled());
}
export const fetchEvents = () => async (dispatch, getState, { axios }) => {
    try {
        dispatch(eventsRequested());
        const response = await axios.get(`/calendario?filtro=${getState().calendario.args.join(',')}`, { 
            headers: { token: localStorage.getItem('pv2token') }
        });
        dispatch(eventsReceived(response.data))
    } catch (error) {
        console.log(error);
        dispatch(eventsRequestedFailed())
    }
};

export const deleteFilter = filter => (dispatch, getState) => {
    dispatch(filterDeleted({ remove: filter }));

    dispatch(fetchEvents(dispatch, getState));
};

export const addFilter = filter => (dispatch, getState) => {
    dispatch(filterAdded({
        add: filter
    }));
    
    dispatch(fetchEvents(dispatch, getState));
}

export const addEvent = () => async (dispatch, getState, { axios }) => {
    const dto = getState().form.AddEventForm.values;
    const response = await axios.post(`/calendario/agregar`, dto, {
        headers: { token: localStorage.getItem('pv2token') }
    });
    
    dispatch(fetchEvents(dispatch, getState));
}