import styled from 'styled-components';
import lettersImage from '../../src/assets/img/LetrasNomizor.png';
import lettersSign4Life from '../../src/assets/img/letrasSign4Life.svg';
import poweredByNomizor from '../../src/assets/img/poweredByNomizor.svg';

export const LettersContainer = styled.div`
  background-size: contain;
  background-position: 3vw;
  background-repeat: no-repeat;
  background-image: url(${process.env.REACT_APP_USE === 'sign4life' ? lettersSign4Life : lettersImage});
  margin-top: 1rem;
  min-height: 2.4rem;
  margin-bottom: 2.9rem;
  width: 100%;
`;
export const BackgroundImage = styled.div`
  width: 68px;
  height: 68px;
  min-height: 68px;
  border-radius: 100px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
`;

export const PoweredByNomizor = styled.div`
  background-size: contain;
  background-position: 3vw;
  background-repeat: no-repeat;
  background-image: url(${process.env.REACT_APP_USE === 'sign4life' ? poweredByNomizor : lettersImage});
  margin-top: 1rem;
  min-height: 2.4rem;
  margin-bottom: 2.9rem;
  width: 16%;
  bottom: 0px;
  position: fixed;
`;
