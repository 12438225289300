import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { sucursalAction } from '../../store/sucursalDuck';
import { fetchNumeroColaboradores } from '../../store/empresasDuck';
import HashRouteEmpresa from '../../routes/ProtectedRoutes/Empresa/HashRouteEmpresa';
import { GirdContainerRoutes } from './styles/styles';
import DetailCard from '../ui/DetailCard';
import Arrows from '../ui/Arrows';
import { useHistory } from 'react-router-dom';
import './DetalleEmpresa.css';

const DetalleEmpresa = React.memo(({history}) => {
  const dispatch = useDispatch();
  const { _id } = useSelector((state) => state.empresas);
  
  useEffect(() => {
    (async () => {
      try {
        await dispatch(sucursalAction(_id));
        await dispatch(fetchNumeroColaboradores());
      } catch (error) {}
    })();
  }, [dispatch, _id]);
  const historyMain = useHistory();
  return (
    <>
      <Row>
        <Col sm={12} className='breadscrum'>
          <Arrows
            onClick={() => {
              historyMain.push('empresa');
            }}
          />
          <span >Empresas</span>
        </Col>
        </Row>
        <Row>
        <Col sm={12}>
          <DetailCard
            icon={faUsers}
            title='Detalle empresa'
            description='Información  más detallada de la empresa'
          />
        </Col>
      </Row>
      <Row sm={12}>
        <GirdContainerRoutes>
          <HashRouteEmpresa history={history} />
        </GirdContainerRoutes>
      </Row>
    </>
  );
});
export default DetalleEmpresa;
