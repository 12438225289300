import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt,faCheck } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import './InputFile.css';
import colors from '../../constans/colors';

const Label = styled.label`
  	width: 100%;
    border: none !important;
    background-color: white;
    border-radius: 15px;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 500ms ease;
    cursor: pointer;
    text-align: center;
   	&:hover {
		background-color: transparent !important;
		color: #2B388F !important;
		border-color: #2B388F;
	}
	&:hover #svg1{
		color: #2B388F ;
	}
	&:hover .title1{
		color: #2B388F ;
	}
	.title1{
		margin-left: 0.5rem;
		font-family: 'Roboto', sans-serif;
		font-weight:bold;
		color: #2B388F;
		transition: all 200ms color;
	}
`;

const InputFileFiniquitos = React.memo(
  ({
    fileDescription = 'Adjuntar',
    register = () => { },
    getFile = () => { },
    ...rest
  }) => {
    const [fileName, setFileName] = useState('');
    const [status, setStatus] = useState(true);
    const [file, setFile] = useState([]);
    const handleChange = ({ target }) => {
      setFileName(target.files[0].name);
      setStatus(false);
      setFile(() => target.files);
    };
    useEffect(() => {
      getFile(file);
    }, [file, getFile]);
    return (
      <React.Fragment>
        <Label
          className={rest.disabled ? 'labelDisabled' : 'label'}
          htmlFor={rest.id ? rest.id : rest.name}
          style={{ width: '174px', height: '51px' }}
        >
          <FontAwesomeIcon
            id='svg1'
            color={!rest.disabled ? colors.secondary : colors.primary}
            icon={status ? faCloudUploadAlt : faCheck}
            style={{
              fontSize: '1.5rem', color: {
                ...rest.disabled ? '#white' : '#2B388F'
              }
            }}
          />

          < span className='title1' > {fileDescription}</span>
          <input
            ref={register ? register : ''}
            type='file'
            {...rest}
            onChange={rest.onChange ? rest.onChange : handleChange}
          />
        </Label>
        <div style={{width: '174px',fontSize:9,color:"#3C3C3C",fontFamily:'monospace'}}>{fileName}</div>
      </React.Fragment >
    );
  }
);

export default InputFileFiniquitos;
