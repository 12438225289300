import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaVacaciones } from '../../../store/vacacionesDucks';
import { useHistory } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import TableBody from './TableBody';
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer, LabelForms } from './styled';
import FirstAdd from '../../../components/ui/FirstAdd';

const VacacionesView = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { listaVacaciones = [] } = useSelector((state) => state.vacaciones);
  const { permisos = {} } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchListaVacaciones());
      } catch (error) { }
    })();
  }, [dispatch]);
  if(listaVacaciones && listaVacaciones.length > 0)
    return (
      <>
        <FormGroup row style={{ marginLeft: 16 }}>
          <h5>Periodos Vacacionales</h5>
        </FormGroup>
        <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
          <TableCommon
            keysAceepted={['Antigüedad', 'Periodos', 'Días Pendientes', 'Notas']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
          >
            <TableBody listaVacaciones={listaVacaciones} />
          </TableCommon>
          <Button type="button" onClick={() => {
            history.push('/vacaciones/agregar')
          }}
            style={{ background: colors.secondary, width: '203px', height: '51px', borderRadius: '4px' }}
            hidden={permisos.colaboradorVacaciones !== 'write'}>+ Agregar</Button>
        </TableContainer>
      </>
    )
    else return (<FirstAdd mensaje="Agrega aquí las vacaciones de tu colaborador" vista='vacaciones' disabled={permisos.colaboradorVacaciones !== 'write'} />)
};

export default VacacionesView;