import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaUtilitario } from '../../store/utilitarioDucks';
import { Switch, Route, HashRouter, useHistory,useLocation } from 'react-router-dom';
import FormUtilitario from './UtilitarioViews/FormUtilitario';
import TablaUtilitario from './UtilitarioViews/Utilitario';
import FirstAddUtil from './UtilitarioViews/FirstAddUtil';
import ShowUtil from './UtilitarioViews/ShowUtil';

const Utilitario = () => {
  const location=useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { listaUtilitarios=[] } = useSelector((state) => state.utilitario);

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchListaUtilitario());
      } catch (error) {}
    })();
  }, [dispatch, history,location.pathname]);

  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/agregar-utilitario' component={FormUtilitario} />
        <Route path='/tabla-utilitario' component={TablaUtilitario} />
        <Route path='/utilitario' component={ShowUtil} />
       {listaUtilitarios.length>0? <Route path='/' component={TablaUtilitario} />:<Route path='/' component={FirstAddUtil} />}
      </Switch>
    </HashRouter>
  );
};

export default Utilitario;
