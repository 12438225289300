import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import LogIn from './pages/LogIn/LogIn';
import Recuperar from './pages/LogIn/Recuperar';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';

import ProtectedRoutes from './routes/ProtectedRoutes/ProtectedRoutes';
import RegistroComponente from './pages/RegistroUsuario/RegistroComponente';

const App = () => {
  return (
    <Switch>
      <Route exact path='/login/:signToken?'>
        <LogIn />
      </Route>
      <Route exact path='/recuperar-credenciales'>
        <Recuperar />
      </Route>
      <Route exact path='/registro-usuario' component={RegistroComponente}>
      </Route>
      <ProtectedRoutes path='/dashboard' component={Dashboard} />
      <Redirect strict from="/" to="/login" />
    </Switch>
  );
};

export default App;
