import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import { faCommentAltEdit, faUserPlus, faGripHorizontal, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FormGroup, Label } from 'reactstrap';
import Col from 'reactstrap/lib/Col';
import './CalendarioAddModal.css';

const AddEventForm = props => {
    const dispatch = useDispatch();

    const { AddEventForm } = useSelector((state) => state.form);
    
    const { handleSubmit, pristine, reset, submitting, addEvent, className } = props
    return (
        <form className={className}>
            <FormGroup row>
                <FontAwesomeIcon icon={faPencil} color='#737373' className='form-icon' style={{fontSize: '1.4rem'}} />
                <Label sm={5}>Título</Label>
                <Col sm={7}>
                    <Field
                        name="titulo"
                        component="input"
                        type="text"
                        placeholder="Cumpleaños Ana Topete"
                        required="true"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <FontAwesomeIcon icon={ faGripHorizontal } color='#737373' className='form-icon' style={{fontSize: '1.4rem'}} />
                <Label sm={5}>Evento</Label>
                <Col sm={7}>
                    <Field name="evento" component="select" className={`${AddEventForm && AddEventForm.values && AddEventForm.values.evento ? AddEventForm.values.evento : ''}`}>
                        <option value='placeholder'>Seleccionar evento</option>
                        <option value="aniversary">Aniversario Laboral</option>
                        <option value="birthday">Cumpleaños</option>
                        <option value="tool">Reposición de Herramienta</option>
                        <option value="uniform">Reposición de Uniforme</option>
                        <option value="document">Expiración de Documento</option>
                        <option value="promotion">Promoción Laboral</option>
                        <option value="medical">Examen Médico</option>
                        <option value="summon">Citatorio</option>
                        <option value="audience">Audiencia</option>
                        <option value="vacation">Vacaciones</option>
                    </Field>
                </Col>
            </FormGroup>
            <FormGroup row>
                <FontAwesomeIcon icon={ faCalendarAlt } color='#737373' className='form-icon' style={{fontSize: '1.4rem'}} />
                <Label sm={5}>Fecha Evento</Label>
                <Col sm={7}>
                    <Field
                        name="fecha"
                        component="input"
                        type="date"
                        placeholder="Seleccionar fecha"
                        style={{width:'200'}}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <FontAwesomeIcon icon={ faCommentAltEdit } color='#737373' className='form-icon' style={{fontSize: '1.4rem'}} />
                <Label sm={5}>Notas</Label>
                <Col sm={7}>
                    <Field
                        name="notas"
                        component="input"
                        type="text"
                        placeholder="Agregar notas"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <FontAwesomeIcon icon={ faUserPlus } color='#737373' className='form-icon' style={{fontSize: '1.4rem'}} />
                <Label sm={5}>Invitados</Label>
                <Col sm={7}>
                    <Field
                        name="invitados"
                        component="input"
                        type="text"
                        placeholder="Agregar invitados"
                    />
                </Col>
            </FormGroup>
        </form>
    )
}

export default reduxForm({
    form: 'AddEventForm' // a unique identifier for this form
})(AddEventForm)