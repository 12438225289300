import React,{ Fragment,useState,useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
	Modal,
	ModalHeader,
	ModalBody,
	FormGroup,
	Form,
	Input,
	Label,
	Col,
	CustomInput
} from "reactstrap";
import {TextAreaUtils} from '../colaboradores/UtilitarioViews/styled';
import { SaveButton,CancelButton } from '../ui/styles/styles';
import InputFile from "../ui/InputFile";
import CustomSelect from '../ui/CustomSelect';
import {editarExtension} from '../../store/citatorioDuck';
import ButtonDowload from '../ui/ButtonDowload';
import moment from 'moment';
import SweetAlert from "../ui/SweetAlet";

const numerocitatorio = [
	{ label: 'Primer Citatorio', value: 1 },
	{ label: 'Segundo Citatorio', value: 2 },
	{ label: 'Tercer Citatorio', value: 3 },
	{ label: 'Cuarto Citatorio', value: 4},
	{ label: 'Quinto Citatorio', value: 5},
	{ label: 'Sexto Citatorio', value:6}
  ];

const CitatorioModalEdit = ({ isOpen, setIsOpen, data }) => {
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, control, setValue } = useForm({});
	const [notEmail, setNotEmail] = useState(true);
	const onSubmit = async (objeto) => {
		try {
			objeto.idExtension = data._id;
			console.log(objeto);
			await dispatch(editarExtension(objeto));
			setIsOpen(false)
			await SweetAlert({ icon: 'success', title: 'Citatorio actualizado correctamente' });
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Agregar Extensión de Citatorio
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<FormGroup row>
							<Label sm={3}>Fecha</Label>
							<Col sm={4}>
								<Input
									type='date'
									name='fecha'
									innerRef={register()}
									defaultValue={moment(data.fecha).format('YYYY-MM-DD')}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Hora</Label>
							<Col sm={4}>
								<Input 
									type='time'
									name='hora'
									innerRef={register()}
									defaultValue={data.hora}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>No. Citatorio</Label>
							<Col sm={9}>
								<CustomSelect
									control={control}
									options={numerocitatorio}
									type='select'
									name='numeroCitatorio'
									innerRef={register()}
									placeholder='Seleccion el numero de citatorio'
									defaultValue={data.numeroCitatorio}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Persona que atiende</Label>
							<Col sm={9}>
								<Input
									type='text'
									name='responsable'
									innerRef={register()}
									placeholder='Persona que atiende'
									defaultValue={data.responsable}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Notificar</Label>
							<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="SI"
								value={true}
								name="notificar"
								defaultChecked={data.notificar === true}
								onClick={() => setNotEmail(true)}
							/>
							</Col>
							<Col sm={1}>
							<CustomInput
								innerRef={register}
								type="radio"
								label="NO"
								value={false}
								name="notificar"
								defaultChecked={data.notificar === false}
								onClick={() => setNotEmail(false)}
							/>
							</Col>
						</FormGroup>
						{notEmail ?
						<FormGroup row>
							<Label sm={3}>Email</Label>
							<Col sm={9}> 
								<Input 
								type='text'
								name='email'
								innerRef={register}
								placeholder='Email'
								defaultValue={data.email}
								/>
							</Col>
						</FormGroup>:null}
						<FormGroup row>
							<Label sm={3}>Notas</Label>
							<Col sm={9}>
								<TextAreaUtils
									name='notas'
									ref={register}
									placeholder='Ingrese notas, de ser necesario'
									defaultValue={data.notas}
								/>
							</Col>
						</FormGroup>
						<FormGroup row>
							<Label sm={3}>Documento</Label>
							<Col sm={{ offset: 1, size: 3 }}>
								<InputFile
									type='file'
									id='documento'
									name='documento'
									register={register}
								/>
							</Col>
							{data["documento"]?
							<Col sm={3}>
								<ButtonDowload
									Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${data.documento}`}
								/>
							</Col>
							:null}
						</FormGroup>
					</Form>
					<FormGroup row>
						<Col sm={{ offset: 4, size: 4 }}>
							<CancelButton
								onClick={() => setIsOpen(false)}
								style={{ marginLeft: 'auto' }}>
								Cancelar
							</CancelButton>
						</Col>
						<Col sm={3}>
							<SaveButton type='button' onClick={handleSubmit(onSubmit)}>Guardar</SaveButton>
						</Col>
					</FormGroup>
				</ModalBody>
			</Modal>
		</Fragment>
	);
}

export default CitatorioModalEdit;