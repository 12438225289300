import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment, { now } from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import InputFile from '../../ui/InputFile';
import {Input} from 'reactstrap';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from '../VacacionesViews/styled';
import {fetchAgregarExtension} from '../../../store/permisosLaboralesDuck';
import ButtonDowload from '../../ui/ButtonDowload';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';

export const FromExtenderPermiso = React.memo(({ history }) => {
    const { permiso = {} } = useSelector((state) => state.permisos);
    const {periodoFinal='', extensionPermiso=[]} = permiso;
    let periodoFinalExt='';
    extensionPermiso.forEach(element => {
        periodoFinalExt=element.periodoFinalExt;
    });
    console.log(periodoFinalExt);
    const dispatch = useDispatch();
    const { register, handleSubmit, errors, control, reset } = useForm({});
    console.log(errors);
    const onSubmit = async (data) => {
    try {
        console.log(data);
        await dispatch(fetchAgregarExtension(data));
        history.push('/');
    } catch (error) {
        console.log(error);
    }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{marginLeft:'30px'}}>
            <FormGroup row>
            <Col sm={12} style={{marginBottom: 5, marginLeft:'-17px'}}>
                <SubTitleMenu
                    style={{color:'#2B388F',fontWeight:'bold',fontSize:'20px'}}
                    >Extensión de Permiso</SubTitleMenu>
                </Col>
                <Label sm={3} style={{marginBottom:'12px'}}>
            <LabelForms>Fecha de inicio</LabelForms>
            </Label>
            <Col sm={{offset:1, size:4}}>
            <InputUtils
                name='periodoInicialExt'
                defaultValue={periodoFinalExt !=''
                ?moment(periodoFinalExt).format('YYYY-MM-DD') 
                :moment(periodoFinal).format('YYYY-MM-DD')}
                type='date'
                ref={register}
            />
            </Col>
            <Col sm={4}></Col>
            <Label sm={3} style={{marginBottom:'12px'}}>
            <LabelForms>Fecha de conclusión</LabelForms>
            </Label>
            <Col sm={{offset:1, size:4}}>
            <InputUtils
                name='periodoFinalExt'
                type='date'
                ref={register}
            />
            </Col>
            <Col sm={4}></Col>
                <Label sm={3} style={{marginBottom:'12px'}}>
                <LabelForms>Causa</LabelForms>
                </Label>
                <Col sm={{offset:1, size:4}}>
                <InputUtils
                    placeholder='Causa de extensión'
                    name='causa'
                    type='text'
                    ref={register}
                />
                </Col>
                <Col sm={4}></Col>
                <Label sm={3}>
                <LabelForms>Solicitud de Extensión</LabelForms>
                </Label>
                <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
                <InputFile name='solicitudExtension' id='solicitudExtension' register={register} /> 
                </Col>
                <Col sm={4}></Col> 
                <Label sm={3}>
                <LabelForms>Autorizacion de Extension</LabelForms>
                </Label>
                <Col sm={{ offset: 2, size: 3 }} className='mb-3'>
                <InputFile name='autorizacionExtension' id='autorizacionExtension' register={register} /> 
                </Col>
                <Col sm={4}></Col>
            </FormGroup>
            <FormGroup row style={{marginTop:'60px'}}>
            <Col sm={{ offset: 4, size: 3 }}>
              <CancelButton type='button'
                onClick={() => {
                  history.push('/');
                }}
                style={{}}
              >
                Cancelar
              </CancelButton>
            </Col>
            <Col sm={3}>
              <SaveButton type='submit'>Guardar</SaveButton>
            </Col>
          </FormGroup>
            </Form>
    )
});

export default FromExtenderPermiso;