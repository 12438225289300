import React from 'react';
import moment from 'moment';
import yisus from '../../assets/img/yisus.jpg';
import './ColaboradorCard.css';
import { useDispatch, useSelector } from 'react-redux';
import { fecthCompletarColaborador } from '../../store/colaboradorDucks';
import SweetAlert from '../ui/SweetAlet';
import { Nombre, JobPosition, InfoHeader, InfoHeaderBlue, BlueButton, SectionColaborator, BackgroundImageDetail } from './styles/styledColaboradorCard';
import { useHistory } from 'react-router-dom';

function getPeriod(initDate, endDate) {
  let totalDays = moment(initDate).diff(moment(endDate), 'days') || 0;
  let years = 0;
  let months = 0;
  let days = 0;
  while (totalDays > 365) {
    totalDays -= 365;
    years++;
  }
  while (totalDays > 30) {
    totalDays -= 30;
    months++;
  }
  days = totalDays;
  let text;
  if (days === 0 && months === 0 && years === 0) {
    text = 'Por definir';
  }else{
    text = `${years > 0 ? years + (years > 1 ? ' años,' : ' año,') : ''} ${months} ${months == 1 ? 'mes' : 'meses'} y ${days} ${days == 1 ? 'día' : 'días'}`
  }
  return text;
}

function numberWithCommas(x) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

const ColaboradorCard = React.memo(
  ({
    foto = '',
    informacionPersonal = {},
    condicionesLaborales = {},
    InformacionNomina = {},
  }) => {
    const {
      nombre = '',
      apellidoMaterno = '',
      apellidoPaterno = '',
    } = informacionPersonal;

    const { salarioMensualBruto = '', fechaIngreso = '', puesto = '' } = condicionesLaborales;
    
    const dispatch = useDispatch();
    
    const history = useHistory();
    
    const { estatus, pertenencia } = useSelector((state) =>  state.colaborador);

    const checkColaborador = () => {
      if (!condicionesLaborales.puesto) return false;
      if (informacionPersonal.nombre === '' || informacionPersonal.apellidoMaterno === '' || informacionPersonal.apellidoPaterno === '') return false;
      if (!InformacionNomina || !InformacionNomina.regimen) return false;
      return true;
    }

    const handleSubmit = async () => {
      try {
        if(!checkColaborador() && estatus === 'proceso'){
          SweetAlert({icon: 'error', title: 'Información incompleta', text:'La información personal, condiciones laborales e información de nómina son requeridos.'})
          return;
        }
        await dispatch(fecthCompletarColaborador());
        await SweetAlert({
          icon: 'success',
          title: `El estado cambio a ${
            estatus === 'proceso' ? 'completado' : 'proceso'
          }`,
        });
        history.replace('/dashboard/nomina');
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <SectionColaborator className='colaborador-container'>
        <BackgroundImageDetail
          imageUrl={
            foto
              ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${foto}`
              : yisus
          }
        />

        <div className='description'>
          <Nombre>{`${nombre} ${apellidoPaterno} ${apellidoMaterno}`}</Nombre>
          <BlueButton
            className='btn btn-primary float-right'
            onClick={handleSubmit}
            style={{borderRadius: '4px'}}
          >
            {estatus}
          </BlueButton>
          <JobPosition>{puesto}</JobPosition>
          <br /><br /><br />
        <div className='info'>
          <div className='id'>
            <InfoHeader>ID</InfoHeader>
              <InfoHeaderBlue>{condicionesLaborales.numeroEmpleado }</InfoHeaderBlue>
          </div>
          <div className='deparment'>
            <InfoHeader>Departamento</InfoHeader>
            <InfoHeaderBlue>{pertenencia && pertenencia.departamento? pertenencia.departamento.nombreDepartamento : ''}</InfoHeaderBlue>
          </div>
          <div className='salary'>
            <InfoHeader>Salario Mensual</InfoHeader>
            <InfoHeaderBlue>{salarioMensualBruto ? `$ ${numberWithCommas(salarioMensualBruto)}`: 'Por definir'}</InfoHeaderBlue>
          </div>
          <div className='antiquity'>
            <InfoHeader>Antigüedad</InfoHeader>
            <InfoHeaderBlue>
              {getPeriod(new Date, fechaIngreso)}
            </InfoHeaderBlue>
          </div>
        </div>
        </div>
      </SectionColaborator>
    );
  }
);

ColaboradorCard.propTypes = {};

export default ColaboradorCard;
