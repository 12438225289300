import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchPromocion } from '../../../store/promocionesDuck';
import { Tr } from '../VacacionesViews/styled';

const TableBody = ({ lista = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const editAction = async data => {
        await dispatch(fetchPromocion(data));
        history.push('/promociones/agregar');
    }

    return (
        <React.Fragment>
            {lista.map(p => (
                <Tr className='table-non' key={p._id} style={{ cursor: 'pointer' }} onClick={e => editAction(p._id)}>
                    <td>
                        <div>
                            {p.condicionesLaborales && p.condicionesLaborales.puesto ? p.condicionesLaborales.puesto : '-'}
                        </div>
                    </td>
                    <td>
                    <div>
                            {p.fechaCambio ? moment(p.fechaCambio).utc().format('DD/MM/YYYY') : '-'}
                        </div>
                    </td>
                    <td>
                        <div>
                            {p.cambioAutorizadoPor ? p.cambioAutorizadoPor : '-'}
                        </div>
                    </td>
                    <td>
                        <div>
                            {p.estatus ? p.estatus : '-'}
                        </div>
                    </td>
                </Tr>
            ))}
        </ React.Fragment>
    );
};

export default TableBody;