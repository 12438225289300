import React from 'react';
import { Fragment } from 'react';
import {
	Table,
	Modal,
	ModalHeader,
	ModalBody,
} from "reactstrap";
import CurrencyFormat from 'react-currency-format';
import ReactTooltip from "react-tooltip";

const ResumenModal = ({ isOpen, setIsOpen, data }) => {
	let arrayDeducciones = [];
	let arrayPercepciones = [];
	const { otrosPagos = [], totales = {} } = data
	if (Object.keys(data).length) {
		const { deducciones = {}, percepciones = {} } = data;
		const ordinariasDeducciones = deducciones.ordinarias;
		const extraOrdinariasDeducciones = deducciones.extraordinarias;
		const ordinariasPercepciones = percepciones.ordinarias;
		const extraOrdinariasPercepciones = percepciones.extraordinarias;
		arrayDeducciones = [].concat(ordinariasDeducciones, extraOrdinariasDeducciones);
		arrayPercepciones = [].concat(ordinariasPercepciones, extraOrdinariasPercepciones);
	}


	return (
		<Fragment>
			<Modal isOpen={isOpen} centered size='lg'>
				<ModalHeader>
					<span style={{ color: 'white', fontWeight: '400' }}>
						Resumen
					</span>
					<button
						className='close'
						style={{ position: 'absolute', top: '15px', right: '15px' }}
						onClick={() => setIsOpen(false)}
					>x
					</button>
				</ModalHeader>
				<ModalBody>
					{Object.keys(totales).length ?
						<>
							<div style={{ width: '50%', float: 'left' }}>
								<Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
									<thead>
										<tr>
											<th>Percepciones</th>
										</tr>
									</thead>
									<tbody>
										{arrayPercepciones.map((d, i) => (
											<tr
												key={i}
												data-for={`main`}
												data-tip={`Exento: ${d.detalle.montoExento.toFixed(2)} <br />Gravado: ${d.detalle.montoGravado.toFixed(2)}`}
												// data-tip="Hello<br />multiline<br />tooltip"
												data-iscapture="true"
											>
												<td>{d.clave || d.codigo}</td>
												<td>{d.descripcion}</td>
												<CurrencyFormat
													value={d.monto}
													displayType={'text'}
													decimalScale={2}
													thousandSeparator={true}
													prefix={'$'}
													renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
												/>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
							<div style={{ width: '50%', float: 'right' }}>
								<Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
									<thead>
										<tr>
											<th>Deducciones</th>
										</tr>
									</thead>
									<tbody>
										{arrayDeducciones.map((d, i) => (
											<tr key={i}>
												<td>{d.clave || d.codigo}</td>
												<td>{d.descripcion}</td>
												<CurrencyFormat
													value={d.monto}
													displayType={'text'}
													decimalScale={2}
													thousandSeparator={true}
													prefix={'$'}
													renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
												/>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
							{otrosPagos.length > 0 ?
								<div style={{ width: '51%', float: 'left' }}>
									<Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
										<thead>
											<tr>
												<th>Otros Pagos</th>
											</tr>
										</thead>
										<tbody>
											{otrosPagos.map((d, i) => (
												<tr key={i}>
													<td>{d.clave || d.codigo}</td>
													<td>{d.descripcion}</td>
													<CurrencyFormat
														value={d.monto}
														displayType={'text'}
														decimalScale={2}
														thousandSeparator={true}
														prefix={'$'}
														renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
													/>
												</tr>
											))}
										</tbody>
									</Table>
									<CurrencyFormat
										value={totales.totalPercepciones}
										displayType={'text'}
										decimalScale={2}
										thousandSeparator={true}
										prefix={'$'}
										renderText={value => <h4 style={{ float: 'right', fontSize: '1.2rem' }}>Total: {value}</h4>}
									/>
								</div>
								:
								<div style={{ width: '51%', float: 'left', textAlign: 'end' }}>
									<CurrencyFormat
										value={totales.totalPercepciones}
										displayType={'text'}
										decimalScale={2}
										thousandSeparator={true}
										prefix={'$'}
										renderText={value => <h4 style={{ float: 'right', fontSize: '1.2rem' }}>Total: {value}</h4>}
									/>
								</div>
							}
							<div style={{ width: '51%', float: 'right', textAlign: 'end' }}>
								<CurrencyFormat
									value={totales.totalPercepciones}
									displayType={'text'}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$'}
									renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Subtotal: {value}</h4>}
								/>
								<CurrencyFormat
									value={totales.totalDeducciones}
									displayType={'text'}
									decimalScale={2}
									thousandSeparator={true}
									prefix={'$'}
									renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Descuentos: {value}</h4>}
								/>
								<CurrencyFormat
									value={totales.total}
									decimalScale={2}
									displayType={'text'}
									thousandSeparator={true}
									prefix={'$'}
									renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Total: {value}</h4>}
								/>
							</div>
						</>
						: null}
				</ModalBody>
				<ReactTooltip
					id="main"
					place="top"
					type="dark"
					effect="float"
					multiline={true}
				/>
			</Modal>
		</Fragment>
	);
}

export default ResumenModal;