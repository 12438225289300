import React, { useCallback } from 'react';
import { Tr } from './styled';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { SaveButton } from '../../colaboradores/VacacionesViews/styled';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../../constans/colors';
import PulsateDot from '../../ui/PulsateDot';
import { useSelector } from 'react-redux';

const TableBody = React.memo(({ tablaDias = [], handleDelete, downloadPdf, isEdit }) => {
  const { permisos = {} } = useSelector((state) => state.user);
  return (
    <>
      {tablaDias.map((Dias, index) => (

        <Tr
          key={index}
        >
          <td>{moment(Dias.inicioPeriodo).utc().format('DD/MM/YYYY')}</td>
          <td>{moment(Dias.finPeriodo).utc().format('DD/MM/YYYY')}</td>
          <td>{Dias.notificacion ? moment(Dias.notificacion).utc().format('DD/MM/YYYY') : ''}</td>
          <td>{Dias.numeroDias}</td>
          <td>
            <a href='#'
              onClick={(e) => downloadPdf(e, Dias)}
              style={{ textDecoration: 'none', color: '#3c3c3c' }}
            >
              <FontAwesomeIcon
                color='#151e5d'
                icon={faCloudDownloadAlt}
              /> Descargar
              </a>
          </td>
          <td>
            {
              Dias.firstPeriodFlag === 1
                ?
                <PulsateDot title='Primer periodo mayor a 6 dias' />
                :
                <PulsateDot color={colors.yellow} title='Primer periodo menor a 6 dias' />

            }
          </td>
          <td>
            {
              Dias.sixMontsFlag === 1
                ?
                <PulsateDot title='Dentro de los 6 meses' />
                :
                <PulsateDot color={colors.yellow} title='Fuera de los 6 meses' />

            }
          </td>
          <td hidden={!isEdit || permisos.colaboradorVacaciones !== 'write'}><FontAwesomeIcon
            cursor="pointer"
            onClick={() => handleDelete(index, Dias)}
            icon={faTrash}
          /></td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;