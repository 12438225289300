import {
  faFileUpload,
  faFileDownload,
  faFileInvoice
} from '@fortawesome/pro-solid-svg-icons';
const mantenimiento_routes =[
  {
      subMenuTitle: '',
      subRoutes:[
          {
              path:'/alta-masiva',
              name: 'Alta masiva',
              icon: faFileUpload
          },
          // {
          //     path:'/baja-masiva',
          //     name: 'Baja masiva'
          //     icon: faFileDownload
          // },
          {
              path:'/facturacion',
              name: 'Facturacion',
              icon : faFileInvoice
          },
          // {
          //     path:'/metodos-pago',
          //     name: 'Métodos de pago'
          // }

      ]
  }
]
export default mantenimiento_routes;