import React,{useState,useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { cierreDemanda } from '../../../store/demandaDuck';
import { Form, Input, FormGroup, Label, Col, Button } from 'reactstrap';
import InputFile from '../../ui/InputFile';
import SweetAlert from "../../ui/SweetAlet";
import { SaveButton,CancelButton } from '../../ui/styles/styles';

const CerrarDemanda = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [mont, setMont] = useState({monto:''});
  const [err, setErr] = useState({});
  const {monto} = mont;
  const { register, handleSubmit, errors, setValue } = useForm({});
  console.log(errors);
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors])

  const getFile = (files) => {
    register({ name: 'evidencia' }, { required: false, validate: true });
    setValue('evidencia', files[0]);
  };

  const onSubmit = async (data) => {
    try {
      await dispatch(cierreDemanda(data));
      await SweetAlert({ icon: 'success', title: 'Demanda cerrada correctamente' });
      history.replace('/dashboard/nomina/juridico-demandas');
    } catch (error) {}
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Cerrar Demanda
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Razon</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='razon'
            innerRef={register()}
            placeholder='Motivo por el cual se esta cerrando la demanda'
          />
          {err.razon && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Razon es requerida
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={2} for='evidencia'>
          Evidencia
        </Label>
        <Col sm={10}>
          <InputFile
            name='evidencia'
            type='file'
            id='evidencia'
            getFile={getFile}
          />
        </Col>
      </FormGroup>

      <FormGroup row>
          <Col sm={{ offset: 6, size: 3 }}>
            <CancelButton
              onClick={() => {
                history.replace('/dashboard/nomina/juridico-demandas');
              }}
              style={{ marginLeft: 'auto' }}>
              Cancelar
            </CancelButton>
          </Col>
          <Col sm={3}>
            <SaveButton type='submit'>Cerrar Demanda</SaveButton>
          </Col>
      </FormGroup>
    </Form>
  );
};

export default CerrarDemanda;
