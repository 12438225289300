import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import colors from '../../constans/colors';
import { MessageAddUtils, Container } from './styles/styles';
const FirstAddUtil = ({mensaje, vista, disabled=false}) => {
    const history= useHistory()
    return (
        <Container>
            <MessageAddUtils>{mensaje}</MessageAddUtils>
            <Button onClick={()=>{
                history.push('/' + vista + '/agregar')
        }} style={{background:colors.secondary}}
        hidden={disabled}>+ Agregar</Button>
    </Container>
    );
};

export default FirstAddUtil;
