import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SubTitleMenu,
  TextAreaUtils,
} from './styled';
import { schemaVacaciones } from '../../../constans/yupSchemas';
import {
  fetchUpdateVacaciones,
  fetchListaVacaciones,
  fetchActualizarVacaciones,
  deleteVaca,
} from '../../../store/vacacionesDucks';
import ButtonDowload from '../../ui/ButtonDowload';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import { ExamLabel } from "../Forms/FormsSaludLaboral/styledSaludLaboral";
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer } from './styled';
import TableBody from './TableBodyPendientes';
import { vacacionesDefinition } from './CatalogoVacaciones';
import { SaveButton } from "../../ui/styles/styles";
import { diccionarioDias, lapsoDias } from '../../../helpers';
import { saveAs } from 'file-saver';
import axios from 'axios';

const api = process.env.REACT_APP_API_URL;
const VacacionesEdit = React.memo(({ history }) => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const [showAdd, setShowAdd] = useState(false);
  const [tablaDias, setTablaDias] = useState([]);
  const dispatch = useDispatch();
  const { vaca = {} } = useSelector((state) => state.vacaciones);
  const { permisos = {} } = useSelector((state) => state.user);

  const {
    numeroDiasPendientes = '',
    prima = '',
    diasCorrespondientes = '',
    anosTrabajando = '',
    notas = '',
    periodos = []
  } = vaca;
  const { condicionesLaborales = {}, _id } = useSelector((state) => state.colaborador);
  const {
    fechaIngreso = '',
    horarioTrabajo = []
  } = condicionesLaborales;
  const fecha1 = moment();
  const fecha2 = moment(fechaIngreso);
  const [rest, setRest] = useState(diasCorrespondientes);
  const [pend, setPend] = useState(numeroDiasPendientes);
  const [periodDeleted, setPeriodDeleted] = useState([])
  const toggleAdd = () => setShowAdd(!showAdd);
  useEffect(() => {
    if (periodos.length > 0) {
      setTablaDias(periodos)
    }
  }, [dispatch])
  const { register, handleSubmit, errors, getValues, setValue } = useForm({
    resolver: yupResolver(schemaVacaciones),
  });

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const [isEdit, setIsEdit] = useState(false);
  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(deleteVaca());
        await dispatch(fetchListaVacaciones());
        history.push('/');
      }
    } catch (error) { }
  };

  const onSubmit = async (data) => {
    try {
      data.periodosBorrados = periodDeleted;
      data.periodos = tablaDias;
      data.numeroDiasPendientes = pend;
      data.anosTrabajando = anosTrabajando;
      await dispatch(fetchUpdateVacaciones(data));
      await dispatch(fetchListaVacaciones());
      history.push('/');
    } catch (error) { }
  };

  const addToTable = async () => {
    const { periodos } = getValues();
    let firstPeriodFlag = 1; //1 = verde, 2 = amarillo
    let sixMontsFlag = 1; // 1 = verde, 2 = amarillo
    const actual = anosTrabajando + 1.5;
    const sixMonts = anosTrabajando + 0.5;
    const ingreso = fecha1.diff(fecha2, 'years', true);
    const aniversario = moment(fechaIngreso);
    const fInicio = moment(periodos.inicioPeriodo);
    const fFinal = moment(periodos.finPeriodo);
    const diffInicio = fInicio.diff(aniversario, 'years', true);
    const diffFinal = fFinal.diff(aniversario, 'years', true);
    console.log(diffInicio);
    try {
      if (actual > diffInicio || actual > diffFinal) {
        if (diffInicio <= diffFinal) {
          if (
            periodos.inicioPeriodo === '' ||
            periodos.finPeriodo === ''
          ) {
            await SweetAlert({
              icon: 'error',
              title: 'Favor de llenar los campos',
            })
          } else {
            if (horarioTrabajo.length === 0) {
              await SweetAlert({
                icon: 'error',
                title: 'Se debe asignar horario laboral primero',
              });
            } else {
              const horario = horarioTrabajo[0].days;
              const arrayDias = await diccionarioDias(horario);
              const fechas = await lapsoDias(periodos.inicioPeriodo, periodos.finPeriodo);
              const reg = [];
              fechas.forEach(fechas => {
                arrayDias.forEach(dias => {
                  if (fechas.day === dias) {
                    reg.push(fechas);
                  }
                });
              });
              const diff = reg.length;
              if (tablaDias.length === 0 && diff < 6 && diff !== 0) {
                firstPeriodFlag = 2;
                await SweetAlert({
                  icon: 'warning',
                  title: 'El primer periodo debe ser al menos de 6 dias',
                });
              }
              if (diffInicio >= sixMonts) {
                sixMontsFlag = 2;
                await SweetAlert({
                  icon: 'warning',
                  title: 'Vacaciones deben ser dentro de los 6 meses...',
                });
              }
              if (diff <= pend) {
                if (diff === 0) {
                  await SweetAlert({
                    icon: 'error',
                    title: `Las fechas deben ser de ${arrayDias[0]} a ${arrayDias[horario.length - 1]}`,
                  });
                } else {
                  let lastDate = 0;
                  let diffDate = 0;
                  if (tablaDias.length > 0) {
                    tablaDias.forEach(element => {
                      lastDate = moment(element.finPeriodo);
                    });
                    diffDate = lastDate.diff(aniversario, 'years', true);
                  }
                  if (lastDate != 0 && diffInicio < diffDate) {
                    await SweetAlert({
                      icon: 'error',
                      title: 'Ya hay vacaciones en este periodo',
                    });
                  } else {
                    console.log(firstPeriodFlag);
                    periodos.numeroDias = diff;
                    periodos.firstPeriodFlag = firstPeriodFlag;
                    periodos.sixMontsFlag = sixMontsFlag;
                    setTablaDias((actualState) => [...actualState, periodos]);
                    const cleanInputData = {
                      inicioPeriodo: "",
                      finPeriodo: "",
                      notificacion: "",
                    };
                    setValue("diasPendientes", cleanInputData, { shouldDirty: true });
                    const resta = pend - diff;
                    setPend(resta);
                    setShowAdd(false)
                  }
                }
              } else {
                await SweetAlert({
                  icon: 'error',
                  title: 'Sobrepasa los dias pendientes',
                });
              }
            }
          }
        } else {
          await SweetAlert({
            icon: 'error',
            title: 'Error en el rango de fechas',
          });
        }
      } else {
        await SweetAlert({
          icon: 'error',
          title: 'Ya no puedes asignar vacaciones a este año',
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = (index, data) => {
    setPeriodDeleted([...periodDeleted, data])
    const filtro = tablaDias.filter(
      (obj) => obj != data
    );
    setTablaDias(() => filtro);
    console.log(pend);
    const suma = pend + data.numeroDias;
    setPend(suma)
    setShowAdd(false)
  };

  const downloadPdf = async (e, data) => {
    e.preventDefault();
    data.antiguedad = anosTrabajando;
    const file = await axios.post(`${api}/vacaciones/generar-constancia`, { ...data, colaborador: _id }, headers)


    let byteString = atob(file.data);

    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    let blob = new Blob([ia], { type: 'application/pdf' });
    saveAs(blob, `constancia-${moment().format('YYYYMMDD')}`)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.push('/');
            }}
          />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorVacaciones !== 'write'}>
          <Icons onClick={elminateUtil} />
        </Col>
        <Col sm={1} hidden={permisos.colaboradorVacaciones !== 'write'}>
          <Icons
            pencil
            onClick={() => {
              setIsEdit((state) => !state);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Años Trabajando</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4, push: 12 }}>
          <InputUtils
            disabled={true}
            defaultValue={anosTrabajando}
            name='anosTrabajando'
            type='number'
            placeholder='Ej. 5'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Días Correspondientes</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={!isEdit}
            name='diasCorrespondientes'
            type='number'
            value={rest}
            placeholder='Ej. 10'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Días Pendientes</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={!isEdit}
            className='pendientes'
            name='numeroDiasPendientes'
            type='number'
            value={pend}
            placeholder='Ej. 5'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Prima Vacacional (%)</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputUtils
            disabled={!isEdit}
            defaultValue={prima}
            name='prima'
            type='number'
            placeholder='Ej. 100'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3} style={{ marginBottom: '12px' }}>
          <LabelForms>Notas</LabelForms>
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <TextAreaUtils
            disabled={!isEdit}
            defaultValue={notas}
            name='notas'
            placeholder='Ej. Notas'
            ref={register}
          />
        </Col>
        <Col sm={4}></Col>
      </FormGroup>
      <FormGroup row >
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Planeación de Vacaciones</SubTitleMenu>
        </Col>
        <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
          <TableCommon
            keysAceepted={['Inicio', 'Fin', 'Notificación', 'Días', 'Constancia', '']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
          >
            <TableBody tablaDias={tablaDias} handleDelete={handleDelete} downloadPdf={downloadPdf} isEdit={isEdit} />
          </TableCommon>
        </TableContainer>
        <ExamLabel sm={3} onClick={e => toggleAdd()} style={{ cursor: 'pointer' }} hidden={!isEdit || permisos.colaboradorVacaciones !== 'write'}>+ Agregar</ExamLabel>
      </FormGroup>
      {showAdd
        ? <FormGroup row>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Inicio Vacaciones*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <InputUtils
              disabled={!isEdit}
              className='pendientes'
              name='periodos.inicioPeriodo'
              type='date'
              ref={register}
            />
          </Col>
          <Col sm={4}></Col>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Final Vacaciones*</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <InputUtils
              disabled={!isEdit}
              className='pendientes'
              name='periodos.finPeriodo'
              type='date'
              ref={register}
            />
          </Col>
          <Col sm={4}></Col>
          <Label sm={3} style={{ marginBottom: '12px' }}>
            <LabelForms>Notificación</LabelForms>
          </Label>
          <Col sm={{ offset: 1, size: 4 }}>
            <InputUtils
              disabled={!isEdit}
              className='pendientes'
              name='periodos.notificacion'
              type='date'
              ref={register}
            />
          </Col>
          <Col sm={4}></Col>
          <Col sm={3} hidden={!isEdit} className="text-center">
            <SaveButton disabled={!isEdit} type="button" onClick={addToTable}>
              + Agregar
          </SaveButton>
          </Col>
        </FormGroup>
        : null
      }

      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3} hidden={!isEdit || permisos.colaboradorVacaciones !== 'write'}>
          <SaveButton disabled={!isEdit} type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  );
});

export default VacacionesEdit;
