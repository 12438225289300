import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, CustomInput, Label } from 'reactstrap';
import { CancelButton, InputUtils, SaveButton, SubTitleMenu, } from '../ui/styles/styles';
import SweetAlert from '../ui/SweetAlet';
import { crearDepartamentoAction, editarDepartamentoAction, fetchListaDepartamentos } from '../../store/departamentosDucks';
import { yupResolver } from '@hookform/resolvers/yup';
import { departamento } from '../../constans/yupSchemas';

const DepartamentoModal = ({ isOpen, setIsOpen, pertenenciaData, isEdit }) => {
    const tipoCatalogo = '';
    const { empresa = '', sucursal = '', nombreDepartamento = '', _id } = pertenenciaData;
    const dispatch = useDispatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(departamento),
    });

    const [err, setErr] = useState({});
    useEffect(() => {
        if (Object.keys(errors).length) { setErr(errors); }
    }, [errors]);

    const onSubmit = async (data) => {
        const departamento = { empresa, sucursal, nombreDepartamento: data.nombreDepartamento, _id }
        console.log(departamento);
        try {
            await dispatch(
                isEdit ?
                    editarDepartamentoAction(departamento)
                    :
                    crearDepartamentoAction(departamento)
            );

            await dispatch(fetchListaDepartamentos(sucursal, false));
            await SweetAlert({
                icon: 'success',
                title: `Departamento ${isEdit ? 'actualizado' : 'creado'}`,
            });
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    };
    return (
        <Modal isOpen={isOpen} centered>
            <ModalHeader>
                <span style={{ color: 'white', fontWeight: '400' }}>
                    <b>{isEdit ? 'Editar' : 'Agregar'}</b> Departamento
                </span>
                <button
                    className='close'
                    style={{ position: 'absolute', top: '15px', right: '15px' }}
                    onClick={() => setIsOpen(false)}
                >
                </button>
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup row>
                        <Label sm={3}>Nombre*</Label>
                        <Col>
                            <InputUtils
                                placeholder='Nombre del departamento'
                                name='nombreDepartamento'
                                type='text'
                                defaultValue={nombreDepartamento}
                                ref={register()}
                            />
                            {err.nombreDepartamento && (
                                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                    El nombre es requerido
                                </span>
                            )}
                        </Col>
                    </FormGroup>
                    <ModalFooter>
                        <FormGroup row style={{ width: '100%' }}>
                            <Col sm={6}>
                                <CancelButton
                                    style={{ width: '100%' }}
                                    onClick={() => setIsOpen(false)}
                                    color='primary'
                                    type='button'
                                >
                                    Cancelar
                                </CancelButton>
                            </Col>
                            <Col sm={6}>
                                <SaveButton style={{ width: '100%' }} color='primary' type='submit'>
                                    Guardar
                                </SaveButton>
                            </Col>
                        </FormGroup>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default DepartamentoModal;
