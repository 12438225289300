import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import OpcionesAlta from '../../components/colaboradores/OpcionesAlta';
import ComponentDescrip from '../../components/ui/ComponentDescrip';
import ColaboradorCard from '../../components/ui/ColaboradorCard';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import Forms from '../../components/colaboradores/Forms/Forms';
import './DarAlta.css';
import EmptyList from '../../components/ui/EmptyList';
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import FormDatosPersonales from "../../components/colaboradores/Forms/FormDatosPersonales";

const DarAlta = React.memo(() => {
  const history = useHistory();
  const colaborador = useSelector((state) => state.colaborador);
  const { _id } = colaborador;
  const [SelectOption, setSelectOption] = useState(0);
  
  const onChange = useCallback(({ target }) => {
    setSelectOption(() => parseInt(target.value));
  }, [_id]);

  return (
    <>
      <HeaderStyle style={{marginBottom:50}}>
        <h1>Nuevo Colaborador</h1>
        <h3>Agrega aquí a tu nuevo colaborador siguiendo los pasos.</h3>
      </HeaderStyle>
      <FormDatosPersonales />
    </>
  );
});

export default DarAlta;
