import styles from "styled-components";

export const H3 = styles.h3`

font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 23px;
line-height: 27px;
/* identical to box height */

text-align: justify;
letter-spacing: 0.297083px;

color: #2B388F;
`;
