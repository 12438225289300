import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import ComponentDescrip from '../ui/ComponentDescrip';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { departamentoSchema } from '../../constans/yupSchemas';
import { cleanStateEmpresas, fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { colaboradorPorEmpresaAction } from '../../store/colaboradorDucks';
import {
  cleanStateDepartamentos,
  crearDepartamentoAction,
  fetchListaDepartamentos,
} from '../../store/departamentosDucks';
import Spiner from '../ui/Spiner';
import SweetAlert from '../ui/SweetAlet';
import CustomSelect from '../ui/CustomSelect';
import { cleanStateSucursal, fetchListaSucursales } from '../../store/sucursalDuck';

let loading = true;
const NuevoDepartamento = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { empresas } = useSelector((state) => state.empresas);
  console.log(empresas);
  const { listaSucursales } = useSelector((state) => state.sucursal);
  const { listaColaboradores } = useSelector((state) => state.colaborador);
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(departamentoSchema),
  });
  useEffect(() => {
   
  }, []);
  useEffect(() => {
    dispatch(cleanStateEmpresas());
    dispatch(cleanStateDepartamentos());
    dispatch(cleanStateSucursal());
    (async () => {
      try {
        dispatch(fetchEmpresasToDropdown());
      } catch (e) {
        console.log(e);
      }
    })();
    loading = false;
    return () => { 
      dispatch(cleanStateEmpresas());
      dispatch(cleanStateDepartamentos());
      dispatch(cleanStateSucursal());
    };
  }, [dispatch]);

  const crearDepartamento = useCallback(
    async (data) => {
      
      try {
        await dispatch(crearDepartamentoAction(data));
        SweetAlert({ icon: 'success', title: 'Creado' });
        history.push('/dashboard/cuenta/empresa');
      } catch (e) {
        await SweetAlert({ icon: 'error', title: 'Error!', text: e });
      }
    },
    [dispatch]
  );

  const onChange = useCallback(
    async ({ target }) => {
      if (!target.value) return;
      try {
        if (target.name === 'empresa') {
          await dispatch(fetchListaSucursales(target.value));
          await dispatch(colaboradorPorEmpresaAction(target.value));
        } else if (target.name === 'sucursal') {
          await dispatch(fetchListaDepartamentos(target.value));
        }
      } catch (error) {}
    },
    [dispatch]
  );
  const submit = ({ nombreDepartamento, empresa, sucursal, idLider }) => {
    crearDepartamento({ nombreDepartamento, empresa, sucursal, idLider });
  };
  if (loading) return <Spiner />;

  return (
    <Container>
      <Row style={{ marginBottom: '2em' }}>
        <Col lg={12}>
          <ComponentDescrip
            title={'Nuevo Departamento'}
            description={'Agrega aquí el nuevo departamento para tu empresa'}
            icon={faUsers}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Form onSubmit={handleSubmit(submit)}>
            <FormGroup row style={{ marginBottom: '2em' }}>
              <Label>Nombre departamento:</Label>
              <Input
                type='text'
                name='nombreDepartamento'
                placeholder='Nombre departamento'
                innerRef={register()}
              />
            </FormGroup>

            <FormGroup row>
              <Label for='empresa' sm={2}>
                Empresa
              </Label>
              <Col sm={10}>
                <CustomSelect
                  type='select'
                  name='empresa'
                  id='empresa'
                  control={control}
                  innerRef={register({ required: true })}
                  options={empresas}
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='sucursal' sm={2}>
                Sucursal
              </Label>
              <Col sm={10}>
                <CustomSelect
                  type='select'
                  name='sucursal'
                  id='sucursal'
                  control={control}
                  innerRef={register({ required: true })}
                  options={listaSucursales}
                  disabled={listaSucursales.length === 0}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for='idLider' sm={2}>
                Líder
              </Label>
              <Col sm={10}>
                <CustomSelect
                  type='select'
                  name='idLider'
                  id='idLider'
                  control={control}
                  innerRef={register({ required: true })}
                  options={listaColaboradores}
                  disabled={listaColaboradores.length === 0}
                />
              </Col>
            </FormGroup>
            <Button type='submit'>Guardar</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default NuevoDepartamento;
