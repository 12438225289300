import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { schemaFiniquitos } from '../../../constans/yupSchemas';
import { fetchEmpresasToDropdown } from '../../../store/empresasDuck';
import { getListaColaboradoresByEmpresa } from '../../../store/colaboradorDucks';
import { fetchUpdateFiniquito, deleteFiniquito,changeStatus } from '../../../store/finiquitosDuck';
import { Form, Input, FormGroup, Label, Col,CustomInput,Button } from 'reactstrap';
import SweetAlert from '../../ui/SweetAlet';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import { SaveButton,CancelButton } from '../../ui/styles/styles';
import estados from '../../../constans/estados';
import CurrencyFormat from 'react-currency-format';
import '../../colaboradores/Forms/CondicionesLaborales.css';
import { HeaderStyle } from "../../../components/ui/styles/StyleNomina";
import TableCommon from '../../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../../colaboradores/VacacionesViews/styled';
import TableBody from './TableBodyPagos';
import { ExamLabel } from "../../colaboradores/Forms/FormsSaludLaboral/styledSaludLaboral";
import ModalFiniquitoAdd from './ModalFiniquitoAdd';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import optionsBancos from "../../../constans/bancos";
import ButtonDowload from '../../ui/ButtonDowload';
import InputFileFiniquitos from '../../ui/InputFileFiniquitos';
import { fetchListaUtilitarioFiniquitos } from '../../../store/utilitarioDucks';
import TableUtilitarios from './TableUtilitarios';
import TablePercepcionOtra from './TablePercepcionOtra';
import TableDeducciones from './TableDeducciones';
import axios from "axios";
import { saveAs } from 'file-saver';
import ButtonDowloadDigital from '../../colaboradores/Forms/FormExpedienteDigital/ButtonDownload';
import ButtonDowloadDisabled from '../../colaboradores/Forms/FormExpedienteDigital/ButtonDownloadDisabled';
import {finiquit,liquidacion,primaAntiguedad} from './CalculosFiniquitos';
import { vacacionesDefinition } from '../../../components/colaboradores/VacacionesViews/CatalogoVacaciones';
import {obtenerISR} from './CalculosIsr';
import {obtenerIsrSeparacion} from './CalculosIsrSeparacion';
import ModalRecibo from './ModalRecibo';
import { listaVacacionesFiniquitos } from '../../../store/vacacionesDucks';

const motivoOptions = [
  { label: 'Separación voluntaria', value: 'Separación voluntaria' },
  { label: 'Liquidación', value: 'Liquidación' },
  { label: 'Término de contrato', value: 'Término de contrato' },
  { label: 'Pensión', value: 'Pensión'},
  { label: 'Jubilación', value: 'Jubilación'},
  { label: 'Defunción', value:'Defunción'},
  { label: 'Recisión de contrato', value:'Recisión de contrato'},
  { label: 'Negociación', value:'Negociación'},
  { label: 'Otras', value:'Otras'}
];
const estatus = [
  { label: 'Activo', value: 'activo' },
  { label: 'Cerrado', value: 'cerrado' },
];

const pagosOptions = [
	{ label: 'Efectivo', value: 'Efectivo' },
	{ label: 'Cheque', value: 'Cheque' },
	{ label: 'Otro', value: 'Otro' }
];

const api = process.env.REACT_APP_API_URL;

const EditarFiniquito = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openRecibo, setOpenRecibo] = useState(false);
  const { empresas = [],empresasForFiniquitos=[] } = useSelector((state) => state.empresas);
  const { listaColaboradores = [],listaForFiniquitos=[] } = useSelector((state) => state.colaborador);
  const {finiquito={}} = useSelector((state) => state.finiquitos);
	const { listaUtilitarios=[] } = useSelector((state) => state.utilitario);
	const { listaVacaciones=[] } = useSelector((state) => state.vacaciones);
  const {pagos=[],prestaciones=[],deducciones=[]} = finiquito;
  const [isEdit, setIsEdit] = useState(true);
  const [err, setErr] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [notConvenio, setNotConvenio] = useState(finiquito["convenio"]);
  const [listaFiniquitos, setListaFiniquitos] = useState(pagos);
  const [cant, setCant] = useState({cantidad:finiquito["cantidad"]});
  const {cantidad} = cant;
  const [cheque, setCheque] = useState(false);
  const [especifica, setEspecifica] = useState(false);
  const [listaPercepcionOtra, setListaPercepcionOtra] = useState(prestaciones);
	const [listaDeduccion, setListaDeduccion] = useState(deducciones);
	const [addDeduccion, setAddDeduccion] = useState(false);
	const [selector, setSelector] = useState('');
  const [grat, setGrat] = useState(finiquito["gratificacion"]);
	const [montoGratificacion, setMontoGratificacion] = useState(finiquito["montoGratificacion"] || '');
	const [otraPrestacion, setOtraPrestacion] = useState(false);
	const [montoOtra, setMontoOtra] = useState('');
	const [montoDeduccion, setMontoDeduccion] = useState('');
  const [utilitarios, setUtilitarios] = useState([]);
	const [opcionesDeducciones, setOpcionesDeducciones] = useState([]);
	const [opcionesPercepciones, setOpcionesPercepciones] = useState([]);
	const [uma,setUma] = useState('');
	const [montoDeduc, setMontoDeduc] = useState('');
	const [salarioMinimo,setSalarioMinimo] = useState(finiquito['salarioMinimo']);
	const [fechaIngreso,setFechaIngreso] = useState('');
	const [salarioDiario,setSalarioDiario] = useState('');
	const [diasAguinaldo, setDiasAguinaldo] = useState(finiquito['diasAguinaldo']);
	const [diasVacaciones,setDiasVacaciones] = useState(finiquito['diasVacaciones']);
	const [primaVacacional,setPrimaVacacional] = useState(finiquito['primaVacacional']);
	const [tablaMensual,setTablaMensual] = useState();
	const [calPercepcion,setCalPercepcion] = useState();
	const [calDeduccion, setCalDeduccion] = useState();
	const [objTotales, setObjTotales] = useState({});
	const [montoConvenio, setMontoConvenio] = useState('');
	const [restante, setRestante] = useState('');
  
  const { register, handleSubmit, errors, control, setValue,getValues } = useForm({
    resolver: yupResolver(schemaFiniquitos),
  });
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  const getEmpresas = useCallback(async () => {
    try {
      await dispatch(fetchEmpresasToDropdown());
      await dispatch(getListaColaboradoresByEmpresa(finiquito['empresa']));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  const toggleModal = async() => {
    if (cantidad === '') {
      await SweetAlert({
        icon: 'error',
        title: 'Proporcionar Cantidad',
      });
    }else{
      setIsOpen(!isOpen)
    }
    
  };
  const toggleModalConvenios = async() =>{
		console.log(restante);
		if (parseFloat(restante) <= 0 ) {
			await SweetAlert({ icon: 'warning', title: 'No se puede agregar convenio' });
		}else if(montoConvenio === ''){
			await SweetAlert({icon: 'error', title: 'Primero se debe calcular finiquito'});
		}else{
			setIsOpen(!isOpen)
		}
	};
  const onChange = async ({ target }) => {
    setUtilitarios([]);
		if (!target.value) return;
		const empresa = empresasForFiniquitos.filter(x => x._id.toString() === target.value);
		const [e] = empresa;
		setUma(e.informacionLegal.uma);
		setSalarioMinimo(e.informacionLegal.salarioMinimo);
		try {
			await dispatch(getListaColaboradoresByEmpresa(target.value));
		} catch (error) {console.log(error);}
  };
  const changeColaborador = async ({target}) => {
		let id = target.value;
		if (id === '') {
			setUtilitarios([]);
		}else{
			try {
				await dispatch(listaVacacionesFiniquitos(id));
				await dispatch(fetchListaUtilitarioFiniquitos(id));
				const colaborador = listaForFiniquitos.filter(x => x._id.toString() === id);
				const [c] = colaborador;
				setFechaIngreso(c.condicionesLaborales.fechaIngreso);
				setSalarioDiario(c.InformacionNomina.salarioDiario);
				setDiasAguinaldo(c.InformacionNomina.diasAguinaldo);
				setPrimaVacacional(25);
				
				if(listaVacaciones.length){
					const [v] = listaVacaciones;
					setDiasVacaciones(v.diasPendientes);
				}else{
					const now = moment();
					const ingreso = moment(c.condicionesLaborales.fechaIngreso);
					const antiguedad = now.diff(ingreso,'years');
					const dias = await vacacionesDefinition(antiguedad);
					setDiasVacaciones(dias);
					
				}
			} catch (error) {}
		}
	}
  useEffect(()  => {
		setUtilitarios(listaUtilitarios);
	}, [listaUtilitarios]);
	useEffect(() => {
		async function getUtils() {
			await dispatch(listaVacacionesFiniquitos(finiquito["colaborador"]._id));
			await dispatch(fetchListaUtilitarioFiniquitos(finiquito["colaborador"]._id));
			setFechaIngreso(finiquito["colaborador"].condicionesLaborales.fechaIngreso);
			setSalarioDiario(finiquito["colaborador"].InformacionNomina.salarioDiario);
			setDiasAguinaldo(finiquito["colaborador"].InformacionNomina.diasAguinaldo);
			setPrimaVacacional(25);
			
			if(listaVacaciones.length){
				const [v] = listaVacaciones;
				setDiasVacaciones(v.diasPendientes);
			}else{
				const now = moment();
				const ingreso = moment(finiquito["colaborador"].condicionesLaborales.fechaIngreso);
				const antiguedad = now.diff(ingreso,'years');
				const dias = await vacacionesDefinition(antiguedad);
				setDiasVacaciones(dias);
				
			}
		}
		getUtils();
	}, [])
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
    getEmpresas();
  }, [getEmpresas, register,errors]);
  useEffect(() => {
	async function getEmpresa() {
		const idEmpresa = finiquito["empresa"];
		const { data } = await axios.get(`${api}/empresa/obtener_empresa/${idEmpresa}`, headers);
		const empresa = data.empresa[0];
		setUma(empresa.informacionLegal.uma);
		setSalarioMinimo(empresa.informacionLegal.salarioMinimo);
	}
	getEmpresa();
  }, [])

  useEffect(() => {
		async function getcatalogo() {
			const query = `catalogo=impuestos&sat=true`;
			const { data } = await axios.get(`${api}/catalogos/?${query}`, headers);
			const array = data.response;
			const tabla = array.filter(t => t.periodicidadPago === '05');
			setTablaMensual(tabla);
		}
		getcatalogo();
	}, [])

  const onSubmit = async (data) => {
    try {
      console.log(data);
      data.cantidad = cantidad;
      data.pagos = listaFiniquitos;
	  data.deducciones = listaDeduccion;
	  data.prestaciones = listaPercepcionOtra;
	  data.total = objTotales;
      console.log(data);
      await dispatch(fetchUpdateFiniquito(data));
      await SweetAlert({
        icon: 'success',
        title: 'Finiquito editado correctamente',
      });
      history.replace('/dashboard/nomina/juridico-finiquitos');
    } catch (error) {
      console.log(error);
    }
  };

  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        await dispatch(deleteFiniquito());
        history.replace('/dashboard/nomina/juridico-finiquitos');
      }
    } catch (error) {}
  };

  const handleChange = ({target}) =>{
		let valor = target.value;
		if (valor === 'Cheque') {
			setCheque(true);
			setEspecifica(false);
		}if (valor === 'Otro') {
			setEspecifica(true);
			setCheque(false);
		}if (valor === '') {
			setEspecifica(false);
			setCheque(false);
		}if (valor === 'Efectivo') {
			setEspecifica(false);
			setCheque(false);
		}
	}
  const clickGratificacion = () => {
		const {gratificacion} = getValues();
		if (gratificacion === 'true') {
			setGrat(true);
		}else{
			setGrat(false);
			setMontoGratificacion('');
		}
	};
	const clickOtra = async () => {
		const query = `catalogo=percepciones&sat=true&tipo=Extraordinaria`;
		const { data } = await axios.get(`${api}/catalogos/?${query}`, headers);
		const array = data.response;
		const optionsPercepciones=[];
		array.forEach(x => {
			optionsPercepciones.push({
				label:x.descripcion,value:x.codigo
			})
		});
		setOpcionesPercepciones([...optionsPercepciones]);
		const {otraPrestacion} = getValues();
		if (otraPrestacion === 'true') {
			setOtraPrestacion(true);
		}else{
			setOtraPrestacion(false);
			setMontoOtra('');
		}
	};
	const generateDocs = async ({target}) => {
		// const data = getValues();
		// data.totales = objTotales;
		console.log(finiquito);
		let query = target.value;
		let second = 'finiquito-';
		if (query==='carta') {
			second='recomendacion-'
		}
		
		const file = await axios.post(`${api}/finiquito/${query}`, finiquito , headers)
	
	
		let byteString = atob(file.data);
	
		let ab = new ArrayBuffer(byteString.length);
		let ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
		  ia[i] = byteString.charCodeAt(i);
		}
		let blob = new Blob([ia], { type: 'application/pdf' });
		saveAs(blob, `${query}-${second}${moment().format('YYYYMMDD')}`)
	};

	const addOtraPrestacion = async () => {
		const {especificaConcepto,conceptoPercepcion} = getValues();
		if (especificaConcepto !== '' && montoOtra !== '') {
			const objeto = {especificaConcepto,selector,montoOtra,conceptoPercepcion};
			setListaPercepcionOtra(oldArray => [...oldArray,objeto]);
			setValue('especificaConcepto', '');
			setValue('conceptoPercepcion','');
			setMontoOtra('');
			setSelector('');
		}else{
			await SweetAlert({
				icon: 'error',
				title: 'Proporcionar percepción y monto',
			})
		}
	};
	const deduccionAdd = async () => {
		const {deduccion, conceptoDeduccion} = getValues();
		if (deduccion !== ''  && montoDeduccion !== '') {
			const objeto = {deduccion,selector,conceptoDeduccion,montoDeduccion};
			setListaDeduccion(oldArray => [...oldArray,objeto]);
			setValue('deduccion', '');
			setValue('conceptoDeduccion', '');
			setMontoDeduccion('');
			setSelector('');
		}else{
			await SweetAlert({
				icon: 'error',
				title: 'Proporcionar deduccion y monto',
			})
		}
	};
	const addDeducciones = async () => {
		const query = `catalogo=deducciones&sat=true`;
		const { data } = await axios.get(`${api}/catalogos/?${query}`, headers);
		const array = data.response;
		const optionsDeducciones=[];
		array.forEach(x => {
			optionsDeducciones.push({
				label:x.descripcion,value:x.codigo
			})
		});
		setAddDeduccion(true)
		setOpcionesDeducciones([...optionsDeducciones])
	};
	const calculatFiniquito = async () => {
		const now = moment();
		const ingreso = moment(fechaIngreso);
		const antiguedad = now.diff(ingreso,'years');
		let totalPercepciones=[];
		let totalDeducciones=[];
		let montoLiquidacion={};
		let montoDeduccion;
		const objetoTotales={};
		const data = getValues();
		console.log(data);
		const montoFiniquito = finiquit(
			moment(fechaIngreso).format('YYYY-MM-DD'),
			moment(data.fechaCalculo).format('YYYY-MM-DD'),
			salarioDiario,
			parseInt(data.diasVacaciones,10),
			parseInt(data.diasAguinaldo,10)
		)
		totalPercepciones = montoFiniquito;
		objetoTotales.finiquito = montoFiniquito.total;
		if (data.indemnizacion === "true") {
			const liq = liquidacion(
				moment(fechaIngreso).format('YYYY-MM-DD'),
				moment(data.fechaCalculo).format('YYYY-MM-DD'),
				salarioDiario,
				parseInt(data.diasVacaciones,10),
				parseInt(data.diasAguinaldo,10),
				parseInt(salarioMinimo,10),
			);
			const {percepciones} = liq;
			const isrLiquidacion = obtenerIsrSeparacion(
				salarioDiario,
				antiguedad,
				percepciones,
				uma,
				tablaMensual
			);
			montoLiquidacion = liq;
			totalDeducciones.push(isrLiquidacion);
			totalPercepciones.total += liq.total; 
			objetoTotales.indemnizacion = liq.total;
		}
		if (data.primaAntiguedad === "true") {
			const prima = primaAntiguedad(
				moment(fechaIngreso).format('YYYY-MM-DD'),
				moment(data.fechaCalculo).format('YYYY-MM-DD'),
				salarioDiario,
				parseInt(salarioMinimo,10),
			)
			totalPercepciones.percepciones.push(prima);
			totalPercepciones.total += prima.monto;
			objetoTotales.primaAntiguedad = prima.monto;
		}
		if (montoGratificacion !== '') {
			console.log(montoGratificacion);
			const gratificacion={
				codigo:'038',
				clave: '138',
				descripcion: 'Gratificacion',
				tipo:'Extraordinaria',
				monto: parseInt(montoGratificacion,10),
				detalle: {}
			}
			totalPercepciones.percepciones.push(gratificacion);
			totalPercepciones.total += parseInt(montoGratificacion,10);
			objetoTotales.gratificacion = parseInt(montoGratificacion,10);
		}
		const isr = obtenerISR(
			salarioDiario,
			salarioMinimo,
			uma,
			totalPercepciones.percepciones,
			tablaMensual
		)
		if (Object.keys(montoLiquidacion).length) {
			const [a,b,c] = montoLiquidacion.percepciones;
			totalPercepciones.percepciones.push(a,b,c);
		}
		totalDeducciones.push(isr);
		
		if(listaPercepcionOtra.length){
			const percepciones = listaPercepcionOtra.map(p => parseInt(p.montoOtra,10));
			const montoPercepciones = percepciones.reduce((a, c) => (c || 0) + a, 0);
			totalPercepciones.total += montoPercepciones;
			listaPercepcionOtra.forEach(x => {
				totalPercepciones.percepciones.push({
					codigo:x.especificaConcepto,
					clave:x.especificaConcepto,
					descripcion:x.selector,
					monto:parseInt(x.montoOtra,10),
					tipo:'Extraordinaria',
					detalle:{}
				});
			});
		}
		if (listaDeduccion.length) {
			listaDeduccion.forEach(x => {
				totalDeducciones.push({
					codigo:x.deduccion,
					clave:x.deduccion,
					descripcion:x.selector,
					monto:parseInt(x.montoDeduccion,10),
					tipo:'Extraordinaria',
					detalle:{}
				});
			});
		}
		
		const deducciones = totalDeducciones.map(d => parseInt(d.monto,10));
		const montoDeducciones = deducciones.reduce((a,b) => (b || 0) + a, 0);
		montoDeduccion = montoDeducciones;
		setMontoDeduc(montoDeducciones);
		setCalDeduccion(totalDeducciones);
		console.log(totalDeducciones);
		setCalPercepcion(totalPercepciones);
		console.log(totalPercepciones);
		objetoTotales.percepciones = totalPercepciones.percepciones;
		objetoTotales.deducciones = totalDeducciones;
		objetoTotales.totalDeduccion = montoDeduccion;
		objetoTotales.totalPagar = totalPercepciones.total - montoDeduccion;
		setCant({ cantidad: totalPercepciones.total - montoDeduccion });
		setMontoConvenio(totalPercepciones.total - montoDeduccion);
		setRestante(totalPercepciones.total - montoDeduccion);
		setOpenRecibo(true);
		setObjTotales(objetoTotales);
	}
	const verFiniquito = () => {
		setOpenRecibo(true);
		console.log(finiquito);
		setObjTotales(finiquito["total"]);
		setMontoDeduc(finiquito.total.totalDeduccion);
		setCalDeduccion(finiquito.total.deducciones);
		let total=0;
		finiquito.total.percepciones.forEach(x =>{
			total += x.monto;
			console.log(x.monto);
		});
		console.log(total);
		const objetoPercepciones = {
			total: total,
			percepciones: finiquito.total.percepciones
		}
		setCalPercepcion(objetoPercepciones);
	};
	const handleSelector = ({target}) => {
		const index = target.selectedIndex;
    	setSelector(target.options[index].text);
	}
	const changeEstatus = async () => {
		try {
      await dispatch(changeStatus());
      SweetAlert({
        icon: 'success',
        title: 'Estatus cambiado correctamente',
      });
			history.replace('/dashboard/nomina/juridico-finiquitos');
    } catch (error) {
      console.log(error);
    }
	}

  return (
    <>
	<ModalRecibo 
		openRecibo={openRecibo}
		setOpenRecibo={setOpenRecibo}
		calPercepcion={calPercepcion}
		calDeduccion={calDeduccion}
		montoDeduccion={montoDeduc}
		objetoTotales={objTotales}
	/>
  	<ModalFiniquitoAdd 
	  isOpen={isOpen} 
	  listaFiniquitos={listaFiniquitos} 
	  setListaFiniquitos={setListaFiniquitos} 
	  setIsOpen={setIsOpen} 
	  cantidad={cantidad}
	  montoConvenio={montoConvenio}
	  setMontoConvenio={setMontoConvenio}
	  restante={restante}
	  setRestante={setRestante}
	/>
	<div className='row'>
		<Col lg={10}>
			<HeaderStyle style={{marginBottom:50}}>
				<h1>Editar Finiquito</h1>
			</HeaderStyle>
		</Col>
		<Col lg={2} >
			<Button style={{
				fontFamily:'Roboto',
				height:'59px',
				padding:'15px',
				width:'170px',
				background:'#2B388F',
			}}
			onClick={changeEstatus}
			>{finiquito["status"] === 'proceso' ?'En Proceso':'Concluido'}</Button>
		</Col>
	</div>
    
	<Form onSubmit={handleSubmit(onSubmit)}>
		<FormGroup row>
			<Col sm={1}>
			<Arrows
				onClick={() => {
				history.replace('/dashboard/nomina/juridico-finiquitos');
				}}
			/>
			</Col>
			<Col sm={1}>
			<Icons onClick={elminateUtil} />
			</Col>
			<Col sm={1}>
			<Icons
				pencil
				onClick={() => {
				setIsEdit((state) => !state);
				}}
			/>
			</Col>
      	</FormGroup>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Datos
        </h5>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Empresa*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={empresas}
            disabled={empresas.length > 0 ? false : true}
            type='select'
            name='empresa'
            defultValue={finiquito['empresa']}
            innerRef={register()}
            onChange={onChange}
			      disabled={isEdit}
          />
          {err.empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Empresa es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Colaborador*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={listaColaboradores}
            disabled={listaColaboradores.length > 0 ? false : true}
            type='select'
            name='colaborador'
            innerRef={register()}
			      disabled={isEdit}
            defultValue={finiquito["colaborador"]._id}
						onChange={changeColaborador}
          />
		      {err.colaborador && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Colaborador es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Entregado por*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='representante'
            innerRef={register()}
            placeholder='Señalar la(s) persona(s) fisica(s) y/o moral(es) que efectúan en pago de finiquito'
            defaultValue={finiquito["representante"]}
            disabled={isEdit}
          />
		      {err.representante && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Representante es requerido
            </span>
          )}
        </Col>
      </FormGroup>
	  <FormGroup row>
        <Label sm={2}>Autoridad laboral*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='autoridadLaboral'
            innerRef={register()}
            placeholder='Ej. Inspección del trabajo'
            defaultValue={finiquito["autoridadLaboral"]}
            disabled={isEdit}
          />
          {err.autoridadLaboral && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Autoridad laboral es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Motivo*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={motivoOptions}
            type='select'
            name='motivo'
            innerRef={register()}
            placeholder='Seleccione el motivo'
            defaultValue={finiquito["motivo"]}
            disabled={isEdit}
          />
          {err.motivo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Motivo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
	  <FormGroup row>
        <Label sm={2}>Especifique causa</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='especificaMotivo'
            innerRef={register()}
            placeholder='Escribe aqui'
            defaultValue={finiquito["especificaMotivo"]}
            disabled={isEdit}
          />
        </Col>
      </FormGroup>
	  <FormGroup row>
        <Label sm={2}>Fecha de pago de finiquito*</Label>
        <Col sm={3}>
          <Input
            type='date'
            name='fecha'
            innerRef={register()}
            placeholder='Introduzca la fecha'
            defaultValue={moment(finiquito["fecha"]).format('YYYY-MM-DD')}
            disabled={isEdit}
          />
          {err.fecha && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Fecha es requerida
            </span>
          )}
        </Col>
      </FormGroup>
	  <FormGroup row>
        <Label sm={2}>Estado*</Label>
        <Col sm={10}>
          <CustomSelect
            control={control}
            options={estados}
            type='select'
            name='estado'
            innerRef={register()}
            placeholder='Seleccione un Estado'
            defaultValue={finiquito["estado"]}
            disabled={isEdit}
          />
          {err.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El Estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2}>Ciudad*</Label>
        <Col sm={10}>
          <Input
            type='text'
            name='ciudad'
            innerRef={register()}
            placeholder='Ej. Hermosillo'
            defaultValue={finiquito["ciudad"]}
            disabled={isEdit}
          />
          {err.ciudad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La Ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      {utilitarios.length ?
      <>
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Lista de utilitarios
        </h5>
      </FormGroup>
      <TableContainer style={{ marginTop:10}}>
        <TableCommon 
            keysAceepted={['Nombre', 'Serie', 'Fecha', 'Valor Factura','']}
            extraTag={{ key: 'Valor Total', value: 1200 }}
            striped
        >
        <TableUtilitarios listaUtilitarios={utilitarios} />
        </TableCommon>
      </TableContainer>
      </>
      :null}
      <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Calculadora de Finiquitos
        </h5>
      </FormGroup>
      <FormGroup row>
        {/* <Label sm={2}>Cantidad*</Label> */}
        <Col sm={12}>
          <CurrencyFormat
              fixedDecimalScale={true}
              decimalScale={2}
              style={{backgroundColor:'#EBECF4',height:'60px', fontSize:'23px'}}
              className='inputMoneda'
              name='cantidad'
              value={cantidad}
              placeholder='Ej. $15,000.00'
              innerRef={register()}
              thousandSeparator={true}
							disabled={isEdit}
              prefix={'$'}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setCant({ cantidad: value })
              }}
          />
        </Col>
      </FormGroup>
			<FormGroup row>
				<Label sm={12} style={{fontWeight:700,fontSize:'19px'}}>
					Negociación
				</Label>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Fecha de inicio de la relación laboral</Label>
				<Col sm={3}>
					<Input
						type='date'
						innerRef={register()}
						name='fechaInicio'
						disabled={isEdit}
						defaultValue={moment(finiquito["fechaInicio"]).format('YYYY-MM-DD')}
					/>
				</Col>
			</FormGroup>
				<FormGroup row>
				<Label sm={2}>Fecha de último dia laborado</Label>
				<Col sm={3}>
					<Input
						type='date'
						innerRef={register()}
						name='fechaCalculo'
						disabled={isEdit}
						defaultValue={moment(finiquito["fechaCalculo"]).format('YYYY-MM-DD')}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Prima vacacional</Label>
				<Col sm={10}>
					<Input
						type='text'
						innerRef={register()}
						name='primaVacacional'
						placeholder='25%'
						disabled={isEdit}
						value={primaVacacional}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Dias de vacaciones</Label>
				<Col sm={10}>
					<Input
						type='text'
						innerRef={register()}
						name='diasVacaciones'
						placeholder='7'
						disabled={isEdit}
						defaultValue={diasVacaciones}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Dias de aguinaldo</Label>
				<Col sm={10}>
					<Input
						type='text'
						innerRef={register()}
						name='diasAguinaldo'
						placeholder='4'
						disabled={isEdit}
						defaultValue={diasAguinaldo}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={2}>Salario mínimo</Label>
				<Col sm={10}>
					<CurrencyFormat
						fixedDecimalScale={true}
						decimalScale={2}
						className='inputMoneda'
						name='cantidad'
						value={salarioMinimo}
						placeholder='4'
						innerRef={register()}
						thousandSeparator={true}
						prefix={'$'}
						onValueChange={(values) => {
							const { formattedValue, value } = values;
							setSalarioMinimo( value )
						}}
					/>
				</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={12} style={{fontWeight:700,fontSize:'19px'}}>
					<small>Opcional</small>
					<br/>
					Prestaciones adicionales
				</Label>
			</FormGroup>
			<div className='row' style={{marginBottom:'0 !important'}}>
				<div style={{paddingTop:'9px',marginLeft:15}}>
					<CustomInput
						type="checkbox"
						id="indemnizacion"
						name="indemnizacion"
						value={true}
						innerRef={register()}
						disabled={isEdit}
						defaultChecked={finiquito["indemnizacion"] === true}
					/>
				</div>
				<label style={{paddingLeft:5,paddingTop:10}}>Indemnización</label>
			</div>
			<div className='row' style={{marginTop:'0rem !important'}}>
				<div style={{paddingTop:'9px',marginLeft:15}}>
					<CustomInput
						type="checkbox"
						id="primaAntiguedad"
						name="primaAntiguedad"
						value={true}
						innerRef={register()}
						disabled={isEdit}
						defaultChecked={finiquito["primaAntiguedad"] === true}
					/>
				</div>
				<label style={{paddingLeft:5,paddingTop:10}}>Prima de antigüedad</label>
			</div>
			<div className='row' style={{marginBottom:'0 !important'}}>
				<div style={{paddingTop:'9px',marginLeft:15}}>
					<CustomInput
						type="checkbox"
						id="gratificacion"
						name="gratificacion"
						value={true}
						innerRef={register()}
						onClick={clickGratificacion}
						disabled={isEdit}
						defaultChecked={finiquito["gratificacion"] === true}
					/>
				</div>
				<label style={{paddingLeft:5,paddingTop:10}}>Gratificación</label>
			</div>
			{grat ?
			<FormGroup row>
				<Label sm={2}>Monto</Label>
				<Col sm={10}>
					<CurrencyFormat
						fixedDecimalScale={true}
						decimalScale={2}
						className='inputMoneda'
						thousandSeparator={true}
						value={montoGratificacion}
						name='montoGratificacion'
						thousandSeparator={true}
						disabled={isEdit}
						defaultValue={finiquito["montoGratificacion"]}
						prefix={'$'}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setMontoGratificacion(value)
						}}
						innerRef={register()}
						placeholder='Monto de Gratificación'
					/>
				</Col>
			</FormGroup>
			:null}
			<FormGroup row>
				<div style={{paddingTop:'9px',marginLeft:15}}>
					<CustomInput
						type="checkbox"
						id="otraPrestacion"
						name="otraPrestacion"
						value={true}
						innerRef={register()}
						onClick={clickOtra}
						disabled={isEdit}
					/>
				</div>
				<label style={{paddingLeft:5,paddingTop:10}}>Otra</label>
			</FormGroup>
			{otraPrestacion?
			<>
			<FormGroup row>
				<Col sm={3}>
					<CustomSelect
						control={control}
						options={opcionesPercepciones}
						type='select'
						name='especificaConcepto'
						innerRef={register()}
						placeholder='Seleccione percepcion'
						onChange={handleSelector}
					/>
				</Col>
				<Col sm={4}>
					<Input
						type='text'
						name='conceptoPercepcion'
						innerRef={register}
						placeholder='Concepto'
					/>
				</Col>
				<Col sm={3}>
					<CurrencyFormat
						fixedDecimalScale={true}
						decimalScale={2}
						className='inputMoneda'
						thousandSeparator={true}
						value={montoOtra}
						name='montoOtra'
						thousandSeparator={true}
						prefix={'$'}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setMontoOtra(value)
						}}
						innerRef={register()}
						placeholder='$ Monto'
					/>
				</Col>
				<Col sm={2}>
					<SaveButton 
						type='button' 
						style={{width:'160px',height:'43px'}}
						onClick={addOtraPrestacion}
					>+ Agregar</SaveButton>
				</Col>
			</FormGroup>
			</>
			:null}
			{listaPercepcionOtra.length ?
			<>
			<FormGroup row>
				<Label sm={12} style={{fontWeight:700,fontSize:'19px'}}>
					Otras Prestaciones
				</Label>
			</FormGroup>
			<TableContainer style={{ marginTop:10}}>
				<TableCommon 
						keysAceepted={['Clave','Nombre','Concepto', 'Monto', '']}
						extraTag={{ key: 'Valor Total', value: 1200 }}
						striped
				>
				<TablePercepcionOtra 
					listaPercepcion={listaPercepcionOtra} 
					setListaPercepcionOtra={setListaPercepcionOtra}
				/>
				</TableCommon>
			</TableContainer>
			</>
			:null}
			<FormGroup row>
				<Label sm={3}>¿Aplicar deducciones?</Label>
				<Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
					<CustomInput
						innerRef={register}
						type="radio"
						label="SI"
						value={true}
						name="deducciones"
						onClick={addDeducciones}
						disabled={isEdit}
					/>
				</Col>
				<Col sm={1}>
					<CustomInput
						innerRef={register}
						type="radio"
						label="NO"
						value={false}
						name="deducciones"
						onClick={() => setAddDeduccion(false)}
						disabled={isEdit}
					/>
				</Col>
			</FormGroup>
			{addDeduccion ?
			<>
			<FormGroup row>
				<Col sm={3}>
					<CustomSelect
						control={control}
						options={opcionesDeducciones}
						type='select'
						name='deduccion'
						innerRef={register()}
						placeholder='Seleccione deduccion'
						onChange={handleSelector}
					/>
				</Col>
				<Col sm={4}>
					<Input
						type='text'
						name='conceptoDeduccion'
						innerRef={register}
						placeholder='Concepto'
					/>
				</Col>
				<Col sm={3}>
					<CurrencyFormat
						fixedDecimalScale={true}
						decimalScale={2}
						className='inputMoneda'
						thousandSeparator={true}
						value={montoDeduccion}
						name='montoDeduccion'
						thousandSeparator={true}
						prefix={'$'}
						onValueChange={(values) => {
							const {formattedValue, value} = values;
							setMontoDeduccion(value)
						}}
						innerRef={register()}
						placeholder='$ Monto'
					/>
				</Col>
				<Col sm={2}>
					<SaveButton 
						type='button' 
						style={{width:'160px',height:'43px'}}
						onClick={deduccionAdd}
					>+ Agregar</SaveButton>
				</Col>
			</FormGroup>
			</>
			:null}
			{listaDeduccion.length ?
			<>
			<FormGroup row>
				<Label sm={12} style={{fontWeight:700,fontSize:'19px'}}>
					Deducciones
				</Label>
			</FormGroup>
			<TableContainer style={{ marginTop:10}}>
				<TableCommon 
						keysAceepted={['Clave','Nombre','Concepto', 'Monto','']}
						extraTag={{ key: 'Valor Total', value: 1200 }}
						striped
				>
				<TableDeducciones 
					listaDeduccion={listaDeduccion} 
					setListaDeduccion={setListaDeduccion}
				/>
				</TableCommon>
			</TableContainer>
			</>
			:null}
			<FormGroup row>
				<Col sm={9}>
					<SaveButton type='button' style={{float:'right'}} onClick={verFiniquito}>Ver Calculo Finiquito</SaveButton>
				</Col>
				<Col sm={3}>
					<SaveButton type='button' style={{float:'right'}} onClick={calculatFiniquito}>Recalcular Finiquito</SaveButton>
				</Col>
			</FormGroup>
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Documentación a generar
				</h5>
			</FormGroup>
			{/* <FormGroup row>
				<Label sm={3} style={{paddingTop:'12px'}}>Calculo del finiquito</Label>
					<Col sm={3}>
						<SaveButton type='button' value='calculo' onClick={generateDocs}>Generar</SaveButton>
					</Col>
			</FormGroup> */}
			<FormGroup row>
				<Label sm={3} style={{paddingTop:'12px'}}>Recibo del pago de finiquito</Label>
					<Col sm={3}>
						<SaveButton type='button' value='recibo' onClick={generateDocs}>Generar</SaveButton>
					</Col>
			</FormGroup>
			<FormGroup row>
				<Label sm={3} style={{paddingTop:'12px'}}>Carta de trabajo</Label>
					<Col sm={3}>
						<SaveButton type='button' value='carta' onClick={generateDocs}>Generar</SaveButton>
					</Col>
			</FormGroup>
			<FormGroup row>
				<h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
					Documentación digital
				</h5>
			</FormGroup>
			<div className="row" style={{marginBottom:'0 !important'}}>
				<Label sm={4}>Recibo de pago de finiquito</Label>
				<Col sm={2}>
					<InputFileFiniquitos
						type='file'
						id='reciboFiniquitoFile'
						name='reciboFiniquitoFile'
						register={register}
						disabled={isEdit}
					/>
				</Col>
				<Col sm={2}>
					{finiquito["reciboFiniquitoFile"]?
						<ButtonDowloadDigital
							Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["reciboFiniquitoFile"]}`}
						/>
					:
						<ButtonDowloadDisabled />
					}
				</Col>
			</div>
			<div className="row" style={{marginBottom:'0 !important'}}>
				<Label sm={4}>Finiquito ante autoridad laboral</Label>
				<Col sm={2}>
					<InputFileFiniquitos
						type='file'
						id='finiquitoLaboralFile'
						name='finiquitoLaboralFile'
						register={register}
						disabled={isEdit}
					/>
				</Col>
				<Col sm={2}>
					{finiquito["finiquitoLaboralFile"]?
						<ButtonDowloadDigital
							Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["finiquitoLaboralFile"]}`}
						/>
					:
						<ButtonDowloadDisabled />
					}
				</Col>
			</div>
			<FormGroup row>
				<Label sm={4}>Otro</Label>
				<Col sm={2}>
					<InputFileFiniquitos
						type='file'
						id='otroFile'
						name='otroFile'
						register={register}
						disabled={isEdit}
					/>
				</Col>
				<Col sm={2}>
					{finiquito["otroFile"]?
						<ButtonDowloadDigital
							Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["otroFile"]}`}
						/>
					:
						<ButtonDowloadDisabled />
					}
				</Col>
			</FormGroup>

	    <FormGroup row>
        <Label sm={2}>Convenio*</Label>
        <Col sm={{ offset: 1, size: 1 }} style={{marginLeft:0}}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="SI"
            value={true}
            disabled={isEdit}
            name="convenio"
            defaultChecked={finiquito["convenio"] === true}
            onClick={() => setNotConvenio(true)}
          />
        </Col>
        <Col sm={1}>
          <CustomInput
            innerRef={register}
            type="radio"
            label="NO"
            value={false}
            disabled={isEdit}
            name="convenio"
            defaultChecked={finiquito["convenio"] === false}
            onClick={() => setNotConvenio(false)}
          />
        </Col>
		    {err.convenio && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              Especifica convenio 
            </span>
          )}
      </FormGroup>

  {notConvenio ?
	 <>
	  <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Calendario de Pagos
        </h5>
      </FormGroup>
      <TableContainer style={{ marginTop:10}}>
          <TableCommon 
              keysAceepted={['Pago', 'Monto', 'Fecha de pago', 'Forma de pago','Recordatorio','']}
              extraTag={{ key: 'Valor Total', value: 1200 }}
              striped
          >
          <TableBody listaFiniquitos={listaFiniquitos} setListaFiniquitos={setListaFiniquitos} />
          </TableCommon>
          <ExamLabel sm={3} onClick={e => toggleModal()} style={{ cursor: 'pointer' }}>+ Agregar</ExamLabel>
      </TableContainer>


	  <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Documentos Adjuntos del Convenio
        </h5>
      </FormGroup>
    <FormGroup row>
		<Label sm={2}>Convenio Finiquito</Label>
		<Col sm={10}>
      {finiquito["convenioFiniquito"]?
        <ButtonDowload
          Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["convenioFiniquito"]}`}
        />
      :
      <InputFile
				type='file'
				id='convenioFiniquito'
				name='convenioFiniquito'
				register={register}
        disabled={isEdit}
			/>
      }
			
		</Col>
	  </FormGroup>
	  <FormGroup row>
		<Label sm={2}>Otros</Label>
		<Col sm={10}>
    {finiquito["otros"]?
      <ButtonDowload
        Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["otros"]}`}
      />
      :
      <InputFile
				type='file'
				id='otros'
				name='otros'
				register={register}
        disabled={isEdit}
			/>
    }
		</Col>
	  </FormGroup>
	  </>
	  : notConvenio === false ?
	  <>
    <FormGroup row>
      <Label sm={2}>Forma de Pago</Label>
      <Col sm={10}>
        <CustomSelect
          control={control}
          options={pagosOptions}
          type='select'
          name='formaPago'
          innerRef={register()}
          onChange={handleChange}
          placeholder='Selecciona forma de pago'
          disabled={isEdit}
          defaultValue={finiquito["formaPago"]}
        />
      </Col>
    </FormGroup>
    {especifica ?
    <FormGroup row>
      <Label sm={2}>Especifica</Label>
      <Col sm={10}>
        <Input
          type='text'
          name='otro'
          innerRef={register()}
          placeholder='Especifica la forma de pago'
          disabled={isEdit}
          defaultValue={finiquito["especidica"]}
        />
      </Col>
    </FormGroup>:null}
    {cheque ?
    <>
    <FormGroup row>
      <Label sm={2}>Numero de cheque</Label>
      <Col sm={10}>
        <Input
          type='text'
          name='numeroCheque'
          innerRef={register()}
          placeholder='Numero de cheque'
          disabled={isEdit}
          defaultValue={finiquito["numeroCheque"]}
        />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label sm={2}>Numero de cuenta</Label>
      <Col sm={10}>
        <Input
          type='text'
          name='cuenta'
          innerRef={register()}
          placeholder='Numero de cuenta'
          disabled={isEdit}
          defaultValue={finiquito["cuenta"]}
        />
      </Col>
    </FormGroup>
    <FormGroup row>
      <Label sm={2}>Banco</Label>
      <Col sm={10}>
        <CustomSelect
          control={control}
          options={optionsBancos}
          type='select'
          name='banco'
          innerRef={register()}
          placeholder='Selecciona el banco'
          disabled={isEdit}
          defaultValue={finiquito["banco"]}
        />
      </Col>
    </FormGroup>
    </>
    :null}
	  <FormGroup row>
        <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
          Documentos Adjuntos
        </h5>
      </FormGroup>
      <FormGroup row>
		<Label sm={2}>Finiquito Laboral</Label>
		<Col sm={10}>
      {finiquito["finiquitoLaboral"] ?
        <ButtonDowload
            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["finiquitoLaboral"]}`}
        />
      :
			<InputFile
				type='file'
				id='finiquitoLaboral'
				name='finiquitoLaboral'
				register={register}
			/>}
		</Col>
	  </FormGroup>
	  <FormGroup row>
		<Label sm={2}>Ratificación</Label>
		<Col sm={10}>
    {finiquito["ratificacion"] ?
      <ButtonDowload
        Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${finiquito["ratificacion"]}`}
      />
    :<InputFile
				type='file'
				id='ratificacion'
				name='ratificacion'
				register={register}
			/>
    }
			
		</Col>
	  </FormGroup>
	  <FormGroup row>
		<Label sm={2}>Otros</Label>
		<Col sm={10}>
			<InputFile
				type='file'
				id='otros'
				name='otros'
				register={register}
			/>
		</Col>
	  </FormGroup>
	  </>
	  :null}
  
    <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.replace('/dashboard/nomina/juridico-finiquitos');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
    </FormGroup>
  </Form>
  </>
  );
};

export default EditarFiniquito;
