import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import sweet from "sweetalert2";
import optionsBancos from '../../../constans/bancos';
import { optionsTipoCuentaPago } from "../../../constans/informacionNomina";
import optionsContract from '../../../constans/tipoContratos';
import { schemaPromocion } from '../../../constans/yupSchemas';
import axios from '../../../services/axios';
import { createPromocion, deletePromocion, editPromocion, promocionEditFinish } from '../../../store/promocionesDuck';
import { TextAreaUtils } from '../../colaboradores/UtilitarioViews/styled';
import Arrows from '../../ui/Arrows';
import CustomSelect from '../../ui/CustomSelect';
import CustomSelectEstados from '../../ui/CustomSelectEstados';
import Icons from '../../ui/Icons';
import {
    CancelButton,
    InputUtils,
    LabelForms,
    SaveButton,
    SubTitleMenu
} from '../../ui/styles/styles';
import SweetAlert from '../../ui/SweetAlet';
import PrestacionFrom from '../Prestaciones/PrestacionFrom';
import HorarioLaboral from './HorarioLaboral';

const api = process.env.REACT_APP_API_URL;

const FormPromocionView = ({ history })=> {
    const headers = { headers: { token: localStorage.getItem("pv2token") }};

    const isEdit = useSelector(state => state.promociones.isEdit);
    let dto = useSelector(state => state.promociones.dto);
    const colaborador = useSelector(state => state.colaborador);
    const { permisos = {} } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [isFormEdit, setFormEdit] = useState(false);
    const [colaboradorDto, setColaborador] = useState({});
    const [err, setErr] = useState({});

    const [isCambioDomicilio, setCambioDomicilio] = useState(false);
    const [isOtroDomicilio, setOtroDomicilio] = useState(false);
    const [isOtroBanco, setOtroBanco] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [horario, setHorario] = useState([]);
    const [salarios, setSalarios] = useState({ salDiario: '', salBase: '', salMensual: '' });

    const { register, handleSubmit, errors, control, getValues, setValue } = useForm({
        resolver: yupResolver(schemaPromocion)
    });

    useEffect(() => {
        setOtroBanco(dto.cambioInformacionBancaria);
        setCambioDomicilio(dto.cambioDomicilio);
        setOtroDomicilio(dto.cambioDomicilio && dto.condicionesLaborales && dto.condicionesLaborales.lugarTrabajo && dto.condicionesLaborales.lugarTrabajo.direccion === 'Otro');
    }, [dto])

    useEffect(() => {
        setColaborador(colaborador);
    }, [colaborador])
    
    useEffect(() => {
        if(dto.condicionesLaborales && dto.InformacionNomina){
            const { horarioTrabajo } = dto.condicionesLaborales;
            console.log(horarioTrabajo);
            setHorario(horarioTrabajo)
            setSalarios({
                salDiario: dto.InformacionNomina.salarioDiario, 
                salBase: dto.InformacionNomina.salarioBase, 
                salMensual: dto.condicionesLaborales.salarioMensualBruto
            })
        }
    }, [dto.condicionesLaborales, dto.InformacionNomina])

    useEffect(() => {
        if(colaboradorDto.pertenencia){
            setLoading(false);
            getPrestacionesOrdinarias();
        }
        
    }, [colaboradorDto.pertenencia])

    useEffect(() => {
        if (Object.keys(errors).length) {
            console.log(salarios)
            console.log(errors)
            if (!salarios.salDiario) errors.salarioDiario = 'Salario diario requerido'
            if (!salarios.salBase) errors.salarioBase = 'Salario base cotización requerido'
            if (!salarios.salMensual) errors.salarioMensualBruto = 'Salario mensual requerido'
            setErr(errors);
        }
    }, [errors]);

    useEffect(() => {
        if (isEdit) {
            setFormEdit(true);
        }
    }, [isEdit]);

    const cancel = async () => {
        await dispatch(promocionEditFinish());
    }

    const [prestacionesOr, setPrestacionesOr] = useState({ percepciones: [], deducciones: [] });

    const getPrestacionesOrdinarias = async () => {
        if(isEdit) {
            setPrestacionesOr(dto.prestaciones)
        } else {
            const { data } = await axios.get(`/nomina/colaborador/prestaciones/ordinarias/${colaboradorDto.pertenencia.empresa}/${colaboradorDto._id}/`, headers);
            setPrestacionesOr(data.response);
        }
    };

    const addPrestacion = (item, type) => {
        let idx = -1;
        let prestaciones = prestacionesOr[type] ? prestacionesOr[type] : [] 
        if(prestacionesOr[type]){
            console.log('ee' + type);
            idx = prestacionesOr[type].findIndex((p) => p.codigo === item.codigo);
            
        }
        if (idx >= 0) {
            prestaciones[idx] = item;
        } else {
            prestaciones = [...prestaciones, item];
            console.log(prestaciones);
        }
        if(type === 'percepciones')
        setPrestacionesOr({...prestacionesOr, 'percepciones': prestaciones });
        else
        setPrestacionesOr({...prestacionesOr, 'deducciones': prestaciones });
    };

    const onDeletePrestacion = (i, type) => {
        const prestaciones = prestacionesOr[type].filter((p, id) => id !== i);
        if(type === 'percepciones')
        setPrestacionesOr({...prestacionesOr, 'percepciones': prestaciones });
        else
        setPrestacionesOr({...prestacionesOr, 'deducciones': prestaciones });
    };

    const onChangeMonto = (value, idx, type) => {
        prestacionesOr[type][idx].monto = value;
        setPrestacionesOr({ ...prestacionesOr });
    };

    const eliminar = async () => {
        try {
            const { isConfirmed } = await SweetAlert({
                title: 'Seguro que desea eliminar?',
                showCancelButton: 'Cancelar',
            });
            if (isConfirmed) {
                await dispatch(deletePromocion(dto._id));
                SweetAlert({ title: 'Promociones actualizadas', icon: 'success' });
                history.push('/');
            }
        } catch (err) {
            SweetAlert({ title: 'Error!', text: err, icon: 'error' });
            console.log(err);
        }
    }

    const onSubmit = async (data) => {
        try {
            let montoDeducEmpty = prestacionesOr['deducciones'].findIndex((p) => p.monto === '' || p.monto === null);
            let montoPerceEmpty = prestacionesOr['percepciones'].findIndex((p) => p.monto === '' || p.monto === null);
            if (montoDeducEmpty !== -1 || montoPerceEmpty !== -1) {
                sweet.fire("Guardar", "El monto es requerido", "warning");
                return;
            }
            if(!data.InformacionNomina) data.InformacionNomina = {};
            data.InformacionNomina.salarioDiario = salarios.salDiario;
            data.InformacionNomina.salarioBase = salarios.salBase;
            data.condicionesLaborales.salarioMensualBruto = salarios.salMensual;
            
            data.prestaciones = prestacionesOr;
            if(horario && horario.length > 0) data.condicionesLaborales.horarioTrabajo = horario;

            if (isEdit)
                await dispatch(editPromocion(data));
            else
                await dispatch(createPromocion(data));
                
            SweetAlert({ title: 'Promociones actualizadas', icon: 'success' });
            history.push('/');
        } catch (err) {
            SweetAlert({ title: 'Error!', text: err, icon: 'error' });
            console.log(err);
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: '30px' }}>
            {isEdit ?
                <FormGroup row>
                    <Col sm={1}>
                        <Arrows
                            onClick={() => {
                                cancel();
                                history.push('/');
                            }}
                        />
                    </Col>
                    <Col sm={1}>
                        <Icons onClick={eliminar} />
                    </Col>
                    <Col sm={1}>
                        <Icons
                            pencil
                            onClick={() => {
                                setFormEdit(!isFormEdit);
                            }}
                        />
                    </Col>
                </FormGroup>
                : ''}

            <FormGroup row> 
                <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
                    <SubTitleMenu style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }} >{isEdit ? 'Editar' : 'Nueva'} Promoción Laboral</SubTitleMenu>
                </Col>
            </FormGroup>

            <FormGroup row hidden={isEdit}>
                <Col sm={12}>
                    <h5 className="px-0 pl-0 py-0 ml-0 mt-2">Condiciones Laborales Actuales</h5>
                </Col>
            </FormGroup>

            <FormGroup row hidden={isEdit}>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Puesto Actual</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales ? colaboradorDto.condicionesLaborales.puesto : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Salario Diario</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.InformacionNomina && colaboradorDto.InformacionNomina.salarioBase ? `$ ${colaboradorDto.InformacionNomina.salarioBase.toFixed(2)}` : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Antigüedad</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales && colaboradorDto.condicionesLaborales.fechaIngreso ? moment(colaboradorDto.condicionesLaborales.fechaIngreso).utc().format('DD/MM/YYYY') : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Tipo de Contrato</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales ? colaboradorDto.condicionesLaborales.tipoContrato : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Domicilio Laboral</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales && colaboradorDto.condicionesLaborales.lugarTrabajo ? colaboradorDto.condicionesLaborales.lugarTrabajo.direccion : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Ciudad</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales && colaboradorDto.condicionesLaborales.lugarTrabajo ? colaboradorDto.condicionesLaborales.lugarTrabajo.ciudad : '-'} disabled={true}/>
                </Col>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Estado</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <Input value={colaboradorDto.condicionesLaborales && colaboradorDto.condicionesLaborales.lugarTrabajo ? colaboradorDto.condicionesLaborales.lugarTrabajo.estado : '-'} disabled={true}/>
                </Col>
            </FormGroup>

            <FormGroup row>
                <Col sm={12}>
                    <h5 className="px-0 pl-0 py-0 ml-0 mt-2">Condiciones Laborales del Nuevo Puesto</h5>
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Nuevo Puesto*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <InputUtils
                        name='condicionesLaborales.puesto'
                        placeholder='Nuevo Puesto'
                        type='text'
                        ref={register}
                        defaultValue={dto.condicionesLaborales ? dto.condicionesLaborales.puesto : ''}
                        disabled={isFormEdit}
                    />
                    {err.condicionesLaborales && err.condicionesLaborales.puesto && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El nuevo puesto es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label for='tipoContrato' sm={3}>
                    Tipo Contrato*
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <CustomSelect
                    type='select'
                    name='condicionesLaborales.tipoContrato'
                    defultValue={dto.condicionesLaborales.tipoContrato}
                    control={control}
                    options={optionsContract}
                    innerRef={register()}
                    disabled={isFormEdit}
                    />
                    {err.condicionesLaborales && err.condicionesLaborales.tipoContrato && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El tipo de contrato es requerido
                    </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label for='fechaCambio' sm={3}>
                    Fecha de Cambio*
                </Label>
                <Col sm={{ offset: 1, size: 4 }} md={{ offset: 1, size: 3 }}>
                    <Input
                    type='date'
                    name='fechaCambio'
                    defaultValue={moment(dto.fechaCambio).format('YYYY-MM-DD')}
                    innerRef={register()}
                    disabled={isFormEdit}
                    />
                    {err.fechaCambio && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        La fecha de cambio es requerida
                    </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Correo Institucional*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <InputUtils
                        name='condicionesLaborales.correoInstitucional'
                        placeholder='Correo Institucional'
                        type='text'
                        ref={register}
                        defaultValue={dto.condicionesLaborales.correoInstitucional ? dto.condicionesLaborales.correoInstitucional : ''}
                        disabled={isFormEdit}
                    />
                    {err.condicionesLaborales && err.condicionesLaborales.correoInstitucional && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El correo institucional es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Personal a Cargo*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <InputUtils
                        name='personalCargo'
                        placeholder='Número de subordinados'
                        type='number'
                        ref={register}
                        defaultValue={dto.personalCargo}
                        disabled={isFormEdit}
                    />
                    {err.personalCargo && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            Escifíque el personal a cargo
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Cambio de Domicilio de Trabajo*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <Label sm={2} check style={{ marginLeft: '20px' }}>
                        <CustomInput type='radio' name='cambioDomicilio'
                            defaultChecked={dto.cambioDomicilio === true ? true : false}
                            value={true} 
                            innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setCambioDomicilio(true)} /> Si
                    </Label>
                    <Label sm={2} check style={{ marginLeft: '40px' }}>
                        <CustomInput type='radio' name='cambioDomicilio'
                            defaultChecked={dto.cambioDomicilio === false ? true : false}
                            value={false}
                            innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setCambioDomicilio(false)} /> No
                    </Label>
                    {err.cambioDomicilio && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            <br />
                        Debe indicar si cambiará de domicilio de trabajo
                        </span>
                    )}
                </Col>
            </FormGroup>




            {isCambioDomicilio && ( <>

            <FormGroup row>
                <Label for='lugarTrabajo' sm={3}>
                    Domicilio de Trabajo*
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <CustomSelect
                    type='select'
                    name='condicionesLaborales.lugarTrabajo.direccion'
                    defultValue={dto.condicionesLaborales.lugarTrabajo? dto.condicionesLaborales.lugarTrabajo.direccion: ''}
                    control={control}
                    options={[
                        { value: 'Sucursal', label: 'Sucursal' },
                        { value: 'Personal', label: 'Personal' },
                        { value: 'Otro', label: 'Otro' },
                    ]}
                    innerRef={register()}
                    onChange={(e) => { e.target.value === 'Otro' ? setOtroDomicilio(true) : setOtroDomicilio(false) }}
                    disabled={isFormEdit}
                    />
                    {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.direccion && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        Especifique el lugar de trabajo
                    </span>
                    )}
                </Col>
            </FormGroup>

            { isOtroDomicilio && (
                    <>
                    <FormGroup row>
                        <Label for='condicionesLaborales.lugarTrabajo.estado' sm={3}> Estado* </Label>
                        <Col sm={{ offset: 1, size: 6 }}>
                        <CustomSelectEstados
                            type='select'
                            name='condicionesLaborales.lugarTrabajo.estado'
                            id='lugarTrabajo.estado'
                            defaultValue={dto.condicionesLaborales.lugarTrabajo ? dto.condicionesLaborales.lugarTrabajo.estado : ''}
                            control={control}
                            innerRef={register()}
                            disabled={isFormEdit}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.estado && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique el estado
                            </span>
                        )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='lugarTrabajo.ciudad' sm={3}> Ciudad* </Label>
                        <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.lugarTrabajo.ciudad'
                            defaultValue={dto.condicionesLaborales.lugarTrabajo ? dto.condicionesLaborales.lugarTrabajo.ciudad : ''}
                            placeholder='Ciudad donde trabaja'
                            ref={register}
                            disabled={isFormEdit}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.ciudad && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique la ciudad
                            </span>
                        )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='condicionesLaborales.lugarTrabajo.colonia' sm={3}> Colonia* </Label>
                        <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.lugarTrabajo.colonia'
                            defaultValue={dto.condicionesLaborales.lugarTrabajo ? dto.condicionesLaborales.lugarTrabajo.colonia : ''}
                            placeholder='Colonia donde trabaja'
                            ref={register}
                            disabled={isFormEdit}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.colonia && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique la colonia
                            </span>
                        )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='condicionesLaborales.lugarTrabajo.calle' sm={3}> Calle* </Label>
                        <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.lugarTrabajo.calle'
                            defaultValue={dto.condicionesLaborales.lugarTrabajo ? dto.condicionesLaborales.lugarTrabajo.calle : ''}
                            placeholder='Calle donde trabaja'
                            ref={register}
                            disabled={isFormEdit}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.calle && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique la calle
                            </span>
                        )}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for='condicionesLaborales.lugarTrabajo.numeroExterior' sm={3}> Número Exterior* </Label>
                        <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.lugarTrabajo.numeroExterior'
                            defaultValue={dto.condicionesLaborales.lugarTrabajo ? dto.condicionesLaborales.lugarTrabajo.numeroExterior : ''}
                            placeholder='Numero exterior donde trabaja'
                            ref={register}
                            disabled={isFormEdit}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.lugarTrabajo && err.condicionesLaborales.lugarTrabajo.numeroExterior && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                Especifique el número exterior
                            </span>
                        )}
                        </Col>
                    </FormGroup>
                </> )}
            </> )}

                <FormGroup row>
                    <Label for='telefono' sm={3}> Teléfono </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='number'
                            name='condicionesLaborales.telefono'
                            defaultValue={dto.condicionesLaborales.telefono}
                            placeholder='Número telefónico trabajo'
                            ref={register}
                            disabled={isFormEdit}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='extension' sm={3}> Extensión </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.extension'
                            defaultValue={dto.condicionesLaborales.extension}
                            placeholder='Extension telefónica'
                            ref={register}
                            disabled={isFormEdit}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='jefeInmediato' sm={3}> Jefe Inmediato </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <InputUtils
                            type='text'
                            name='condicionesLaborales.jefeInmediato'
                            defaultValue={dto.condicionesLaborales.jefeInmediato}
                            placeholder='Jefe Inmediato'
                            ref={register}
                            disabled={isFormEdit}
                        />
                    </Col>
                </FormGroup>

                <HorarioLaboral register={register} horario={horario} setHorario={setHorario} getValues={getValues} setValue={setValue} readOnly={isFormEdit} />

                <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Persona que Autoriza*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <InputUtils
                        name='cambioAutorizadoPor'
                        placeholder='Autorizado Por'
                        type='text'
                        ref={register}
                        defaultValue={dto.cambioAutorizadoPor}
                        disabled={isFormEdit}
                    />
                    {err.cambioAutorizadoPor && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            La persona que autoriza es requerida
                        </span>
                    )}
                </Col>
            </FormGroup>

            <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Motivo del Cambio*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 6 }}>
                    <TextAreaUtils
                        name='motivoCambio'
                        ref={register}
                        placeholder='Ingrese el motivo del cambio'
                        defaultValue={dto.motivoCambio}
                        disabled={isFormEdit}
                    />
                    {err.motivoCambio && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El motivo del cambio es requerido
                        </span>
                    )}
                </Col>
            </FormGroup>

                <FormGroup row>
                    <Col sm={12}>
                        <h5 className="px-0 pl-0 py-0 ml-0 mt-2">Condiciones Salariales</h5>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label for='salarioDiario' sm={3}> Salario Diario* </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <CurrencyFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            className='inputMoneda'
                            name='InformacionNomina.salarioDiario'
                            value={salarios.salDiario}
                            innerRef={register()}
                            placeholder="Ingrese el salario diario del colaborador"
                            thousandSeparator={true}
                            disabled={isFormEdit}
                            prefix={'$'}
                            onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                setSalarios({...salarios, salDiario: value })
                            }}
                        />
                        {err.InformacionNomina && err.InformacionNomina.salarioDiario && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                <br />
                                Debe indicar el salario diario
                            </span>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='salarioMensualBruto' sm={3}> Salario Mensual Bruto* </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <CurrencyFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            className='inputMoneda'
                            name='condicionesLaborales.salarioMensualBruto'
                            value={salarios.salMensual}
                            innerRef={register()}
                            placeholder="Ingrese el salario del colaborador"
                            thousandSeparator={true}
                            disabled={isFormEdit}
                            prefix={'$'}
                            onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                setSalarios({...salarios, salMensual: value })
                            }}
                        />
                        {err.condicionesLaborales && err.condicionesLaborales.salarioMensualBruto && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                <br />
                                Debe indicar el salario mensual bruto
                            </span>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='salarioBase' sm={3}> Salario Base Cotización* </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <CurrencyFormat
                            fixedDecimalScale={true}
                            decimalScale={2}
                            className='inputMoneda'
                            name='InformacionNomina.salarioBase'
                            value={salarios.salBase}
                            innerRef={register()}
                            placeholder="Ingrese el base del colaborador"
                            thousandSeparator={true}
                            disabled={isFormEdit}
                            prefix={'$'}
                            onValueChange={(values) => {
                                const { formattedValue, value } = values;
                                setSalarios({...salarios, salBase: value })
                            }}
                        />
                        {err.InformacionNomina && err.InformacionNomina.salarioBase && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                <br />
                                Debe indicar el salario base de cotización
                            </span>
                        )}
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Col sm={12}>
                        <h5 className="px-0 pl-0 py-0 ml-0 mt-2">Información Bancaria</h5>
                    </Col>
                </FormGroup>

                <FormGroup row>
                <Label sm={3} style={{ marginBottom: '12px' }}>
                    <LabelForms>Cambio de Información Bancaria*</LabelForms>
                </Label>
                <Col sm={{ offset: 1, size: 4 }}>
                    <Label sm={2} check style={{ marginLeft: '20px' }}>
                        <CustomInput type='radio' name='cambioInformacionBancaria'
                            defaultChecked={dto.cambioInformacionBancaria === true ? true : false}
                            value='true' innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setOtroBanco(true)} /> Si
                    </Label>
                    <Label sm={2} check style={{ marginLeft: '40px' }}>
                        <CustomInput type='radio' name='cambioInformacionBancaria'
                            defaultChecked={dto.cambioInformacionBancaria === false ? true : false}
                            value='false'
                            innerRef={register}
                            disabled={isFormEdit}
                            onClick={e => setOtroBanco(false)} /> No
                    </Label>
                    {err.cambioInformacionBancaria && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            <br />
                        Debe indicar si cambiará la información bancaria
                        </span>
                    )}
                </Col>
            </FormGroup>

            {isOtroBanco && ( <>
                <FormGroup row> <Label for='banco' sm={3}> Banco* </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <CustomSelect
                            type="select"
                            name="InformacionNomina.banco"
                            defaultValue={dto.InformacionNomina.banco}
                            id="banco"
                            innerRef={register({ required: true })}
                            control={control}
                            options={optionsBancos}
                        />
                        {err.InformacionNomina && err.InformacionNomina.banco && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El banco es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='tipoCuentaPago' sm={3}> Tipo de Cuenta de Pago* </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                        <CustomSelect
                            type="select"
                            name="InformacionNomina.tipoCuentaPago"
                            id="tipoCuentaPago"
                            defaultValue={dto.InformacionNomina.tipoCuentaPago}
                            innerRef={register({ required: true })}
                            control={control}
                            options={optionsTipoCuentaPago}
                        />
                        {err.InformacionNomina && err.InformacionNomina.tipoCuentaPago && (
                            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                            El tipo de cuenta de pago es requerido
                            </span>
                        )}
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for='numeroCuentaBancaria' sm={3}>
                    Número Cuenta Bancaria*
                    </Label>
                    <Col sm={{ offset: 1, size: 6 }}>
                    <InputUtils
                        type="text"
                        name="InformacionNomina.numeroCuentaBancaria"
                        defaultValue={dto.InformacionNomina.numeroCuentaBancaria}
                        ref={register}
                        placeholder="Ingrese el numero Cuenta Bancaria"
                    />
                    {err.InformacionNomina && err.InformacionNomina.numeroCuentaBancaria && (
                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                        El número cuenta bancaria es requerido
                        </span>
                    )}
                    </Col>
                </FormGroup>
            </>)}

            <FormGroup row>
                <Col sm={12}>
                    <h5 className="px-0 pl-0 py-0 ml-0 mt-2">Prestaciones Ordinarias</h5>
                </Col>
            </FormGroup>

        {!isLoading ? <>
            <PrestacionFrom
                label="Percepciones"
                type="percepciones"
                prestacionesOrdinarias={prestacionesOr.percepciones}
                colaboradorId={colaboradorDto._id}
                empresaId={colaboradorDto.pertenencia.empresa}
                addPrestacion={addPrestacion}
                onDeletePrestacion={onDeletePrestacion}
                onChange={onChangeMonto}
            />

            <PrestacionFrom
                label="Deducciones"
                type="deducciones"
                prestacionesOrdinarias={prestacionesOr.deducciones}
                colaboradorId={colaboradorDto._id}
                empresaId={colaboradorDto.pertenencia.empresa}
                addPrestacion={addPrestacion}
                onDeletePrestacion={onDeletePrestacion}
                onChange={onChangeMonto}
            />

        </> : ''}




            <FormGroup row>
                <Col sm={{ offset: 6, size: 3 }}>
                    <CancelButton
                        onClick={() => {
                            cancel();
                            history.push('/');
                        }}
                        style={{ marginLeft: 'auto' }}>
                        Cancelar
                    </CancelButton>
                </Col>
                <Col sm={3}>
                    {!isFormEdit && (
                        <SaveButton type='submit' >Guardar</SaveButton>
                    )}
                </Col>
            </FormGroup>


        </Form>
    )
};

export default FormPromocionView;