import React, {useEffect, useCallback, useState} from 'react';
import {Card, CardImg, CardBody, CardTitle, Col} from 'reactstrap';
import './../../assets/styles/main.css'
import { Container, Row } from 'reactstrap';
import {faTrashAlt, faUserEdit, faPencilAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../ui/PageTitle";
import {useSelector} from "react-redux";
import './../../assets/styles/main.css'
import PageName from "../ui/PageName";
const MiPerfil = () => {
    const { data}= useSelector( state => state.user)
    return (

        <Container>
            <PageName  icon={faUser}/>
            <PageTitle icon={faPencilAlt} title="Datos personales"/>
            <Row>
                <Col>
                    <p><strong>Nombre:</strong>{data.nombre} {data.apellidos} </p>
                    <p><strong>Edad:</strong></p>
                    <p><strong>Direción:</strong></p>
                    <p> <strong>Celular:</strong> {data.telefono}</p>
                    <p> <strong>Correo personal:</strong> {data.correo_electronico}</p>
                    <p>  <strong>Estado civil: </strong> </p>
                </Col>
            </Row>
        </Container>
    );
};

export default MiPerfil;
