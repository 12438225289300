import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStarHalfAlt } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../constans/colors';
import PropTypes from 'prop-types';
const ComponentDescrip = React.memo(
    ({ title = '', description = '', descSize = '', align ='left'}) => {
        return (
            <div>
                <br /><br /><br /><br /><br /><br /><br /><br />
                <div className='row'>
                    <div className='col-9' style={{textAlign: align}}>
                        <FontAwesomeIcon
                            color={colors.primary}
                            icon={faStarHalfAlt}
                            style={{ fontSize: '1.5rem' }}
                        />
                        <h4 style={{ color: colors.textColorPrimary }}>{title}</h4>
                        <p
                            style={{
                                color: colors.textColorSecondary,
                                fontSize: descSize ? descSize : '1rem',
                            }}>
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
);

ComponentDescrip.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descSize: PropTypes.string,
};

export default ComponentDescrip;
