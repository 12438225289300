import React, {useState, useEffect} from 'react';
import Tooltip from 'reactstrap/lib/Tooltip';
import {
    CheckBoxWrapper,
    CheckBox,
    CheckBoxLabel,
} from './styled';

const ToogleSwitch = React.memo((props) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <CheckBoxWrapper id={`${props.id}-wrapper`}>
            <CheckBox {...props} type='checkbox' />
            <CheckBoxLabel htmlFor={props.id} />
            <Tooltip placement='right' isOpen={tooltipOpen} target={`${props.id}-wrapper`} toggle={toggle}>
                {props.checked ? 'Con goce de Sueldo' : 'Sin goce de sueldo'}
            </Tooltip>
        </CheckBoxWrapper>
    );
});

export default ToogleSwitch;
