const makeTableData = (listaColaboradores, date) => {

    return listaColaboradores.map(c => ({
        key: c.id,
        name: `${c.informacionPersonal.apellidoPaterno} ${c.informacionPersonal.apellidoMaterno}, ${c.informacionPersonal.nombre}`,
        numero: `${c.condicionesLaborales && c.condicionesLaborales.numeroEmpleado ? c.condicionesLaborales.numeroEmpleado : 0}`,
        ...c
    }))
}

export default makeTableData;