import React from 'react';
import {Container, Row, Col, Button} from 'reactstrap';
import ComponentDescrip from "../ui/ComponentDescrip";
import {faUsers} from "@fortawesome/free-solid-svg-icons";

const MetodoPago = () =>{
    const title= 'Métodos de Pago';
    const icon = faUsers;
    return(
        <Container>
            <Row>
                <ComponentDescrip icon={icon}  title={title}/>

            </Row>
            <Row>
                <h5>Información de Pago</h5>
            </Row>
            <Row>
                <h5>Información Tarjeta</h5>
            </Row>
            <Row>

                <Col lg={6}>
                    <p>Número de tarjeta</p>
                    <p>Fecha de expiración</p>
                    <p>CVV</p>
                    <p>Nombre completo</p>

                </Col>
                <Col lg={6}>
                    <p>Ingresa los 16 digitos de la tarjeta</p>
                    <p>00/00</p>
                    <p>***</p>
                    <p>***</p>

                </Col>
            </Row>
            <Row>
                <h5>Elige la razón social de este método de pago</h5>
            </Row>
            <Row>
                <Button>Agregar método de pago</Button>
            </Row>
        </Container>
    )
}
export default MetodoPago;