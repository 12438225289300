import axios from "axios"

/**
 * Función para obtener datos del servicio rest que se consulta
 * 
 * @param {string} url dirección del servicio rest que se desea invocar (incluir parametros de consulta).
 * @param {function} onResult función que se ejecuta al recibir la respuesta.
 * @param {function} onError función que se ejecuta si hay un error.
 * @param {boolean} addAuth indica si se agrega la autorización default false.
 */
export const get = async (url, onResult, onError, addAuth = false) => {
    try {
        const headers = addAuth ? ({ token: localStorage.getItem("pv2token") }) : undefined;
        const { data } = await axios.get(url, { headers });
        onResult(data)
    } catch (error) {
        onError(error)
    }
}

/**
 * Función para mandar datos del servicio rest que se consulta
 * 
 * @param {string} url dirección del servicio rest que se desea invocar (incluir parametros de consulta).
 * @param {object} body objeto que se manda al servicio rest.
 * @param {function} onResult función que se ejecuta al recibir la respuesta.
 * @param {function} onError función que se ejecuta si hay un error.
 * @param {boolean} addAuth indica si se agrega la autorización default false.
 */
export const post = async (url, body, onResult, onError, addAuth = false) => {
    try {
        const headers = addAuth ? ({ token: localStorage.getItem("pv2token") }) : undefined;
        const { data } = await axios.post(url, body, { headers });
        onResult(data)
    } catch (error) {
        onError(error)
    }
}

/**
 * Función para actualizar datos del servicio rest que se consulta
 * 
 * @param {string} url dirección del servicio rest que se desea invocar (incluir parametros de consulta).
 * @param {object} body objeto que se manda al servicio rest.
 * @param {function} onResult función que se ejecuta al recibir la respuesta.
 * @param {function} onError función que se ejecuta si hay un error.
 * @param {boolean} addAuth indica si se agrega la autorización default false.
 */
export const put = async (url, body, onResult, onError, addAuth = false) => {
    try {
        const headers = addAuth ? ({ token: localStorage.getItem("pv2token") }) : undefined;
        const { data } = await axios.put(url, body, { headers });
        onResult(data)
    } catch (error) {
        onError(error)
    }
}

/**
 * Función para eliminar datos del servicio rest que se consulta
 * 
 * @param {string} url dirección del servicio rest que se desea invocar (incluir parametros de consulta).
 * @param {function} onResult función que se ejecuta al recibir la respuesta.
 * @param {function} onError función que se ejecuta si hay un error.
 * @param {boolean} addAuth indica si se agrega la autorización default false.
 */
export const remove = async (url, onResult, onError, addAuth = false) => {
    try {
        const headers = addAuth ? ({ token: localStorage.getItem("pv2token") }) : undefined;
        const { data } = await axios.delete(url, { headers });
        onResult(data)
    } catch (error) {
        onError(error)
    }
}
