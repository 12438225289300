import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Form,
  Button,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import {
  editarBoton,
  editarDepartamentoAction,
} from '../../store/departamentosDucks';
import ComponentDescrip from '../ui/ComponentDescrip';
import { faPencilAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { departamento } from '../../constans/yupSchemas';
import BotonEditar from '../ui/BotonEditar';
import { yupResolver } from '@hookform/resolvers/yup';
import Spiner from '../ui/Spiner';
import SweetAlert from '../ui/SweetAlet';
import { fecthColaborador } from '../../store/colaboradorDucks';

const DetalleDepartamento = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { detalleDepartamento = {}, editar } = useSelector(
    (state) => state.departamentos
  );
  const { nombreDepartamento, idLider = '' } = detalleDepartamento;
  const { nombreSucursal = '' } = useSelector((state) => state.sucursal);
  const { nombre_empresa = '' } = useSelector((state) => state.empresas);
  const { informacionPersonal={} ,loading} = useSelector((state) => state.colaborador);
 const {nombre='',apellidoMaterno='',apellidoPaterno=''}=informacionPersonal;
 const nombreLider=`${nombre} ${apellidoPaterno} ${apellidoMaterno}`;
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(departamento),
  });
  let disabled = !editar ? { disabled: 'disabled' } : {};

  const editarClick = () => {
    dispatch(editarBoton());
  };
  const submit = async (data) => {
    try {
      await dispatch(editarDepartamentoAction(data));
      await SweetAlert({ icon: 'success', title: 'Editado exitosamente' });
      history.replace('/dashboard/cuenta/empresa');
    } catch (e) {
      await SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  };
useEffect(() => {
 (async()=>{
   try {
     if (idLider)await dispatch(fecthColaborador(idLider));
   } catch (error) {
     console.log(error);
   }
 
 })()
}, [dispatch,idLider])
  if (loading) return <Spiner />;
  return (
    <Container>
      <Row>
        <Col>
          <ComponentDescrip
            icon={faUsers}
            title='Detalle del departamento'
            description='Información detallada del Departamento'
          />
        </Col>
      </Row>
      <Row>
        <Col lg={{ size: 'auto', offset: 9 }}>
          <BotonEditar icon={faPencilAlt} clickHandler={() => editarClick()} />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Form onSubmit={handleSubmit(submit)}>
            <FormGroup>
              <Label>Nombre Departamento</Label>
              <Input
                type='text'
                name='nombreDepartamento'
                defaultValue={nombreDepartamento}
                {...disabled}
                innerRef={register()}
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Nombre Empresa</Label>
                  <Input
                    type='text'
                    name='nombre_empresa'
                    defaultValue={nombre_empresa}
                    required
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Nombre Sucursal</Label>
                  <Input
                    type='text'
                    name='nombreSucursal'
                    defaultValue={nombreSucursal}
                    required
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Nombre Encargado </Label>
                  <Input
                    type='text'
                    name='nombreSucursal'
                    defaultValue={nombreLider.trim()?nombreLider:'Sin lider asignado'}
                    required
                    disabled={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            {editar ? <Button type='submit'>Editar</Button> : null}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default DetalleDepartamento;
