import React, { useState } from 'react';
import { Stage, Layer, Rect, Text, Group } from 'react-konva';
import { max, scaleBand, scaleLinear } from 'd3';
import _ from 'lodash';
import Tooltip from './Tooltip';

const categoriesFactory = (domain, v) => scaleBand().domain(domain).range([0, v]);

const linearFactory = (col, v) => scaleLinear().domain([0, col]).range([0, v]);

const VerticalBarOrd = ({ colors, width, height, xOffset = 0, yOffset = 0, categorias }) => {
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState("");
    const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

    const yScale = linearFactory(max(categorias.map(c => c.c.length)), height - yOffset);
    const xScale = categoriesFactory(categorias.map(m => m.g), width - xOffset);

    const onHover = (text) => {
        setVisible(true);
        setText(text);
    };

    const onMouseMove = (e) => {
        let stage = e.currentTarget;
        stage = e.target.getStage();

        let mosuePos = stage.getPointerPosition();
        setMouseCoords({ ...mouseCoords, x: mosuePos.x, y: mosuePos.y });
    };

    const onMouseLeave = (e) => {
        setVisible(false);
        setText("");
    };

    return (
        <Stage width={width} height={height}>
            <Layer>
                <Group x={10} y={15} >
                    {
                        categorias.map((cat, i) => (
                            <Text
                                key={i}
                                x={xScale(cat.g)}
                                y={height - 45}
                                text={cat.g}
                                fontSize={12}
                                fill="#8E8883"
                                width={xScale.bandwidth()}
                                align='center'
                            />
                        ))
                    }
                </Group>
                <Group x={10} y={15}>
                    {
                        categorias.map((cat, i) => {
                            let yPos = cat.c.length;
                            return (
                                <Rect
                                    key={i}
                                    x={xScale(cat.g)}
                                    y={height - yOffset - yScale(yPos)}
                                    fill={colors.primary[i]}
                                    width={xScale.bandwidth()}
                                    height={yScale(yPos)}
                                    cornerRadius={5}
                                    onMouseEnter={() => onHover(yPos)}
                                    onMouseMove={onMouseMove}
                                    onMouseLeave={onMouseLeave}
                                />
                            )
                        })
                    }
                </Group>
                <Tooltip x={mouseCoords.x} y={mouseCoords.y} visible={visible} text={text} />
            </Layer>
        </Stage>
    )
}

export default VerticalBarOrd;
