import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import CustomInput from "reactstrap/lib/CustomInput";
import actividadFisica from "../../../../constans/actividadFisica";
import tiposExamenes from "../../../../constans/tipoExamanes";
import { tiposSangre } from "../../../../constans/tipoSangre";
import { schemaSaludLaboral } from "../../../../constans/yupSchemas";
import Upload from "../../../../services/uploadFile";
import { fetchAgregarSaludLaboral } from "../../../../store/colaboradorDucks";
import CustomSelect from "../../../ui/CustomSelect";
import GroupSelect from "../../../ui/GroupSelect";
import InputFile from "../../../ui/InputFile";
import { SaveButton } from "../../../ui/styles/styles";
import SweetAlert from "../../../ui/SweetAlet";
import "../../Forms/FormSaludLaboral.css";
import { ExamLabel } from "../../Forms/FormsSaludLaboral/styledSaludLaboral";
import TableBody from "../../Forms/FormsSaludLaboral/TableBody";
import Notifiacion from '../FormsSaludLaboral/Notificacion';
import TableCommonSaludLaboral from "../FormsSaludLaboral/TableCommonSaludLaboral";
import TableExamenesMedicos from "../FormsSaludLaboral/TableExamenesMedicos";

const FormSaludLaboral = React.memo(() => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
  } = useForm({});
  const dispatch = useDispatch({
    resolver: yupResolver(schemaSaludLaboral),
  });

  const { permisos = {} } = useSelector((state) => state.user);
  const { saludLaboral = {}, informacionPersonal = {} } = useSelector(
    (state) => state.colaborador
  );
  const {
    examenMedico = [],
    alimentacion = {},
    estatura = "",
    peso = "",
    presion = "",
    frecuenciaCardiaca = "",
    frecuenciaRespiratoria = "",
    temperatura = "",
    padeciminetoActual = "",
    tabaquismo = "",
    toxicomanias = "",
    alcoholismo = "",
    medicamentos = "",
    alergias = "",
    donadorOrganos = "",
    donadorSangre = "",
    grupoSanguineo = "",
    nombreMedicoFamiliar = "",
    telefonoMedicoFamiliar = "",
    vih,
    obesidad,
    embarazada,
    actividadFisica: actividadFisicaValue,
    diabetes,
    cancer,
    enfermedadPulmunar,
    enfermedadHepatica,
    transplante,
    especificaMedicamentos = "",
    especificaAlergias = "",
    hipertension,
    especificaDiscapacidad,
    discapacidad: discapacidadState = false,
    proximoExamenMedico = [],
  } = saludLaboral;
  let carne = "";
  let verduras = "";
  let pescado = "";
  let frutas = "";
  if (Object.keys(alimentacion).length !== 0) {
    carne = alimentacion.carne;
    verduras = alimentacion.verduras;
    pescado = alimentacion.pescado;
    frutas = alimentacion.frutas;
  }
  const [especifica, setEspecifica] = useState(medicamentos);
  const [alergiasState, setAlergiasState] = useState(alergias);
  const [discapacidad, setDiscapacidad] = useState(discapacidadState);
  const [tablaExamenMedico, setTablaExamenMedico] = useState(examenMedico);
  const [tablaProximoExamenMedico, setTablaProximoExamenMedico] = useState(
    proximoExamenMedico
  );
  const [showAdd, setShowAdd] = useState(true);
  const toggleAdd = () => setShowAdd(!showAdd);
  const [estudios, setEstudios] = useState('');
  const [errorEstudios, setErrorEstudios] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorTime, setErrorTime] = useState(false);
  const [fechaEstudios, setFechaEstudios] = useState('');
  const [horaEstudios, setHoraEstudios] = useState('');

  const [showProgram, setShowProgram] = useState(true);
  const toggleProgram = () => setShowProgram(!showProgram);

  const addToTable = async () => {
    const { examenMedico } = getValues();
    try {
      const upload = await Upload(examenMedico.archivoExamenMedico);
      examenMedico.archivoExamenMedico = upload;
      if (examenMedico.nombreExamen !== "") {
        setTablaExamenMedico((actualState) => [...actualState, examenMedico]);
        const cleanInputData = {
          nombreExamen: "",
          fechaAplicacion: "",
          archivoExamenMedico: "",
        };
        setValue("examenMedico", cleanInputData, { shouldDirty: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updatePosition = (fechaExamenMedico, tipoExamen, hora) => {
    const index = tablaProximoExamenMedico.findIndex(
      (x) => x.fechaExamenMedico === fechaExamenMedico && x.hora === hora
    );
    if (index < 0) return false;
    if (tablaProximoExamenMedico[index].tipoExamen === tipoExamen) return true;
    tablaProximoExamenMedico[
      index
    ].tipoExamen = `${tablaProximoExamenMedico[index].tipoExamen},
   ${tipoExamen}`;
    const updateTable = [...tablaProximoExamenMedico];
    setTablaProximoExamenMedico(() => updateTable);
    return true;
  };
  const parseToBoolean = (data) => {
    return data === "true" ? (data = true) : (data = false);
  };
  const addToTableProximoExamen = () => {
    setEstudios(estudios.replace(',,', ','))
    const {
      proximoExamenMedico,
      notificacion = false,
      observaciones,
    } = getValues();
    const { fechaExamenMedico, tipoExamen, hora } = proximoExamenMedico;
    if (!fechaExamenMedico) { setErrorDate(true) } else setErrorDate(false)
    if (!tipoExamen) { setErrorEstudios(true) } else setErrorEstudios(false)
    if (!hora) { setErrorTime(true) } else setErrorTime(false)
    if (!fechaExamenMedico || !tipoExamen || !hora) return;
    if (updatePosition(fechaExamenMedico, tipoExamen, hora)) return;
    parseToBoolean(notificacion);
    const updateTable = [
      ...tablaProximoExamenMedico,
      {
        ...proximoExamenMedico,
        notificacion: parseToBoolean(notificacion),
        observaciones,
      },
    ];
    setTablaProximoExamenMedico(() => updateTable);
    const cleanInputData = {
      fechaExamenMedico: '',
      tipoExamen: '',
      hora: "",
    };
    setEstudios('')
    setFechaEstudios('')
    setHoraEstudios('')
    setValue("proximoExamenMedico", cleanInputData, { shouldDirty: true });
    setValue("notificacion", false, { shouldDirty: true });
    setValue("observaciones", '', { shouldDirty: true });
    setValue("tipoExamen", 'Seleccionar', { shouldDirty: true });
  };


  const clickMedicamentos = (e) => {
    let value = e.target.value;
    if (value === "true") {
      setEspecifica(true);
    } else {
      setEspecifica(false);
    }
  };

  const clickAlergias = (e) => {
    console.log()
    let value = e.target.value;
    if (value === "true") {
      setAlergiasState(true);
    } else {
      setAlergiasState(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      data.examenMedico = tablaExamenMedico;
      data.proximoExamenMedico = tablaProximoExamenMedico;
      await dispatch(fetchAgregarSaludLaboral(data));
      await SweetAlert({
        icon: "success",
        title: "Se completo Salud Laboral correctamente, puede continuar...",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = useCallback(
    (data) => {
      console.log("entre aqui");
      const filtro = tablaExamenMedico.filter(
        (obj) => obj.nombreExamen !== data
      );
      setTablaExamenMedico(() => filtro);
    },
    [tablaExamenMedico, setTablaExamenMedico]
  );
  useEffect(() => { }, [tablaProximoExamenMedico]);

  const onChange = ({ target }) => {
    if (target.value === 'Seleccionar' || estudios.includes(target.value)) return;    
    setEstudios(estudios && estudios !== '' ? estudios + (estudios.endsWith(',') ? '' : ',' ) + target.value : target.value)
    setValue("tipoExamen", 'Seleccionar', { shouldDirty: true });
  };

  const onChangeText = ({ target }) => {
    setEstudios(target.value)
  };

  const onChangeFecha = ({ target }) => {
    setFechaEstudios(target.value)
  };

  const onChangeHora = ({ target }) => {
    setHoraEstudios(target.value)
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Notas de evolución
          </h5>
          <Label sm={12} className="labelGray">
            Signos Vitales
          </Label>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Peso(kg)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="peso"
              defaultValue={peso}
              placeholder="ej. 56 kg"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Estatura(mts)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="estatura"
              defaultValue={estatura}
              placeholder="ej. 1.80 mts"
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Presión(mmHg)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="presion"
              defaultValue={presion}
              placeholder="ej. 90/110"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Frecuencia cardiaca(lat/min)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="frecuenciaCardiaca"
              defaultValue={frecuenciaCardiaca}
              placeholder="ej. 90"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Frecuencia respiratoria(resp/min)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="frecuenciaRespiratoria"
              defaultValue={frecuenciaRespiratoria}
              placeholder="ej. 18"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Temperatura(°C)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="temperatura"
              defaultValue={temperatura}
              placeholder="Ej. 36.6 °C"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Padecimiento actual
          </Label>
          <Col sm={5}>
            <Input
              type="text"
              name="padeciminetoActual"
              defaultValue={padeciminetoActual}
              placeholder="Ej. cáncer, diabetes, etc..."
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Personales no patológicos
          </h5>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Fuma</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={tabaquismo === true}
                    name="tabaquismo"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={tabaquismo === false}
                    name="tabaquismo"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toxicomanías</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="toxicomanias"
                    defaultChecked={toxicomanias === true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={toxicomanias === false}
                    name="toxicomanias"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toma alcohol</Label>
              <FormGroup check>
                <Label sm={2} style={{ color: "#B1B1B1" }} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={alcoholismo === true}
                    name="alcoholismo"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check >
                <Label sm={4} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={alcoholismo === false}
                    name="alcoholismo"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={12} className="labelGray">
            Alimentación
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1", marginLeft: '17px' }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.carne"
              defaultChecked={carne === true}
              value={true}
              innerRef={register()}
            />
            Carne
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.pescado"
              defaultChecked={pescado === true}
              value={true}
              innerRef={register()}
            />
            Pescado
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              defaultChecked={frutas === true}
              name="alimentacion.frutas"
              value={true}
              innerRef={register()}
            />
            Frutas
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              defaultChecked={verduras === true}
              name="alimentacion.verduras"
              value={true}
              innerRef={register()}
            />
            Verduras
          </Label>
        </FormGroup>
        <FormGroup row style={{ marginTop: '1.2rem' }}>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toma medicamento</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="medicamentos"
                    defaultChecked={medicamentos === true}
                    value={true}
                    innerRef={register()}
                    onClick={clickMedicamentos}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="medicamentos"
                    defaultChecked={medicamentos === false}
                    value={false}
                    innerRef={register()}
                    onClick={clickMedicamentos}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {especifica ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    defaultValue={especificaMedicamentos}
                    name="especificaMedicamentos"
                    placeholder="Ej. paracetamol, etc"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Alergias</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="alergias"
                    defaultChecked={alergias === true}
                    value={true}
                    innerRef={register()}
                    onClick={clickAlergias}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="alergias"
                    value={false}
                    defaultChecked={alergias === false}
                    innerRef={register()}
                    onClick={clickAlergias}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {alergiasState ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    defaultValue={especificaAlergias}
                    name="especificaAlergias"
                    placeholder="Ej. sol, polve etc"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Donador de Órganos</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={donadorOrganos === true}
                    name="donadorOrganos"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={donadorOrganos === false}
                    name="donadorOrganos"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Donador de Sangre</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={donadorSangre === true}
                    name="donadorSangre"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={donadorSangre === false}
                    name="donadorSangre"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Obesidad</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="obesidad"
                    defaultChecked={obesidad === true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="obesidad"
                    defaultChecked={obesidad === false}
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            {informacionPersonal["sexo"] === "F" ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">Embarazada</Label>
                <FormGroup check style={{ color: "#B1B1B1" }}>
                  <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                    <CustomInput
                      type="radio"
                      value={true}
                      defaultChecked={embarazada === true}
                      name="embarazada"
                      innerRef={register()}
                    />
                    Si
                  </Label>
                </FormGroup>
                <FormGroup check style={{ color: "#B1B1B1" }}>
                  <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                    <CustomInput
                      type="radio"
                      value={false}
                      defaultChecked={embarazada === false}
                      name="embarazada"
                      innerRef={register()}
                    />
                    No
                  </Label>
                </FormGroup>
              </FormGroup>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Deportes (actividad física)
          </Label>
          <Col sm={5}>
            <CustomSelect
              className="mb-3"
              name="actividadFisica"
              defultValue={actividadFisicaValue}
              control={control}
              innerRef={register({ required: true })}
              options={actividadFisica}
              type="select"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Diabetes</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={diabetes === true}
                    name="diabetes"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={diabetes === false}
                    value={false}
                    name="diabetes"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">VIH</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={vih === true}
                    name="vih"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={vih === false}
                    value={false}
                    name="vih"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Cáncer</Label>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={cancer === true}
                    value={true}
                    name="cancer"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={4} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={cancer === false}
                    value={false}
                    name="cancer"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Discapacidad</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="discapacidad"
                    defaultChecked={discapacidad === true}
                    value={true}
                    innerRef={register()}
                    onClick={() => setDiscapacidad(true)}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="discapacidad"
                    defaultChecked={discapacidad === false}
                    value={false}
                    innerRef={register()}
                    onClick={() => setDiscapacidad(false)}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {discapacidad ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    name="especificaDiscapacidad"
                    defaultValue={especificaDiscapacidad}
                    placeholder="Ej. camaron, latex"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Enfermedad Pulmonar</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={enfermedadPulmunar === true}
                    name="enfermedadPulmunar"
                    value={true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    defaultChecked={enfermedadPulmunar === false}
                    name="enfermedadPulmunar"
                    value={false}
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Enfermedad Hepática</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={enfermedadHepatica === true}
                    name="enfermedadHepatica"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={enfermedadHepatica === false}
                    name="enfermedadHepatica"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Transplante</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="transplante"
                    defaultChecked={transplante === true}
                    value={true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="transplante"
                    defaultChecked={transplante === false}
                    value={false}
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Hipertensión</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    defaultChecked={hipertension === true}
                    name="hipertension"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    defaultChecked={hipertension === false}
                    name="hipertension"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Grupo Sanguíneo
          </Label>
          <Col sm={5}>
            <CustomSelect
              className="mb-3"
              name="grupoSanguineo"
              control={control}
              defultValue={grupoSanguineo}
              innerRef={register({ required: true })}
              options={tiposSangre}
              type="select"
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Médico Familiar
          </Label>
          <Col sm={5}>
            <Input
              type="text"
              name="nombreMedicoFamiliar"
              defaultValue={nombreMedicoFamiliar}
              placeholder="Nombre de médico familiar"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="LabelGray">
            Teléfono del médico
          </Label>
          <Col sm={5}>
            <Input
              type="number"
              defaultValue={telefonoMedicoFamiliar}
              name="telefonoMedicoFamiliar"
              placeholder="Telefono de médico familiar"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Historial Exámenes Médicos
          </h5>
        </FormGroup>

        <FormGroup row style={{ marginLeft: '-3px' }}>
          <TableCommonSaludLaboral
            striped={true}
            keysAceepted={["Nombre Examen", "Fecha", "", ""]}
          >
            <TableBody
              handleDelete={handleDelete}
              examenMedicos={tablaExamenMedico}
            />
          </TableCommonSaludLaboral>
        </FormGroup>
        <ExamLabel sm={3} onClick={e => toggleAdd()} style={{ cursor: 'pointer' }} hidden={permisos.colaboradorSalud !== 'write'}>+ Agregar Examen</ExamLabel>
        <div style={{ display: showAdd ? 'none' : 'initial' }} >
          <FormGroup row>
            <Label
              for="fecha"
              sm={3}
              className="LabelGray"
              style={{ marginRight: "130px" }}
            >
              Fecha
            </Label>
            <Col sm={4}>
              <Input
                type="date"
                name="examenMedico.fechaAplicacion"
                innerRef={register()}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label
              for="nombreExamen"
              sm={3}
              className="LabelGray"
              style={{ marginRight: "130px" }}
            >
              Nombre
            </Label>
            <Col sm={5}>
              <Input
                type="text"
                name="examenMedico.nombreExamen"
                placeholder="Nombre del examen médico"
                innerRef={register()}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} className="LabelGray" style={{ marginRight: "130px" }}>
              Subir Archivo
            </Label>
            <Col sm={4}>
              <InputFile
                type="file"
                id="examenMedicoArchivo"
                name="examenMedico.archivoExamenMedico"
                register={register}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3} className="text-center">
              <SaveButton type="button" onClick={addToTable}>
                + Agregar
              </SaveButton>
            </Col>
          </FormGroup>

        </div>


        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Exámenes Programados
          </h5>
        </FormGroup>

        <FormGroup row style={{ marginTop: "40px", marginLeft: '-3px' }}>
          <TableCommonSaludLaboral
            striped={true}
            keysAceepted={["Examen por aplicar", "Fecha", "Hora", 'Observaciones', '', '']}
          >
            <TableExamenesMedicos
              proximosExamenesMedicos={tablaProximoExamenMedico}
              setTable={setTablaProximoExamenMedico}
            />
          </TableCommonSaludLaboral>
        </FormGroup>


        <ExamLabel sm={3} onClick={e => toggleProgram()} style={{ cursor: 'pointer' }} hidden={permisos.colaboradorSalud !== 'write'}>+ Programar Examen</ExamLabel>
        <div style={{ display: showProgram ? 'none' : 'initial' }} >
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Próximo Examen Médico*
            </Label>
            <Col sm={3}>
              <Input
                type="date"
                name="proximoExamenMedico.fechaExamenMedico"
                placeholder="Seleccione la fecha del examen"
                innerRef={register()}
                onChange={onChangeFecha}
              />
              {!fechaEstudios && errorDate && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Por favor inidque la fecha.
                </span>
              )}
            </Col>
            <Col sm={3}>
              <Input
                type="time"
                name="proximoExamenMedico.hora"
                placeholder="Seleccione la fecha del examen"
                innerRef={register()}
                onChange={onChangeHora}
              />
              {!horaEstudios && errorTime && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Por favor inidque la hora.
                </span>
              )}
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Tipo de Estudios
            </Label>
            <Col sm={6}>
              <GroupSelect
                options={tiposExamenes}
                control={control}
                type="select"
                name="tipoExamen"
                innerRef={register()}
                onChange={onChange}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Estudios*
            </Label>
            <Col sm={6}>
              <Input
                name="proximoExamenMedico.tipoExamen"
                value={estudios}
                onChange={onChangeText}
                innerRef={register()}
                type="textarea"
                placeholder="Seleccione uno o varios tipos estudio y/o introduzca el nombre de otro estudio"
              />
              {!estudios && errorEstudios && (
                <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                  Por favor inidque al menos un estudio.
                </span>
              )}
            </Col>
          </FormGroup><FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Observaciones
            </Label>
            <Col sm={6}>
              <Input
                name="observaciones"
                innerRef={register}
                type="textarea"
                placeholder="Observaciones, Ej. Domicilio del laboratiorio."
              />
            </Col>
          </FormGroup>
          <Notifiacion register={register} />
          <FormGroup row>
            <Col sm={3} className="text-center">
              <SaveButton type="button" onClick={addToTableProximoExamen}>
                + Agregar
              </SaveButton>
            </Col>
          </FormGroup>
        </div>
        <FormGroup row className="text-center" hidden={permisos.colaboradorSalud !== 'write'}>
          <Col sm={{ offset: 9, size: 3 }}>
            <SaveButton type="submit">Guardar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
    </>
  )
});
export default FormSaludLaboral;
