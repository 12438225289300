import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import PromocionesView from './PromocionesViews/PromocionesView';
import FormPromocion from './PromocionesViews/FormPromocionView';

const Promociones = () => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/promociones/agregar' component={FormPromocion} />
                <Route path='/' component={PromocionesView} /> 
            </Switch>
        </HashRouter>
    );
};

export default Promociones;