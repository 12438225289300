import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaPermisos } from '../../../store/permisosLaboralesDuck';
import { useHistory } from 'react-router-dom';
import { Button, FormGroup } from 'reactstrap';
import colors from '../../../constans/colors';
import TableBody from './TableBody';
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer, LabelForms } from '../VacacionesViews/styled';
import FirstAdd from '../../../components/ui/FirstAdd';

const PermisosLaboralesView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { listaPermisosLaborales=[] } = useSelector((state) => state.permisos);
    const { permisos = {} } = useSelector((state) => state.user);

    useEffect(() => {
      (async () => {
        try {
          await dispatch(fetchListaPermisos());
        } catch (error) {}
      })();
    }, [dispatch]);
    if(listaPermisosLaborales && listaPermisosLaborales.length > 0)
      return (
        <>
          <FormGroup row style={{ marginLeft: 16 }}>
            <h5>Permisos Laborales</h5>
          </FormGroup>
          <TableContainer style={{marginLeft:30, marginTop:10}}>
            <TableCommon
              keysAceepted={['Inicio', 'Fin', 'Días', 'Sueldo','Ext.','']}
              extraTag={{ key: 'Valor Total', value: 1200 }}
              striped
            >
            <TableBody listaPermisosLaborales={listaPermisosLaborales}/>
            </TableCommon>
            <Button type="button" onClick={()=>{
              history.push('/permisos/agregar')
          }} 
          style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}
          hidden={permisos.colaboradorPermisos !== 'write'}>+ Agregar</Button>
          </TableContainer>
        </>
      );
    else return(<FirstAdd mensaje="Agrega aquí los permisos laborales de tu colaborador" vista='permisos' disabled={permisos.colaboradorPermisos !== 'write'}/>)
};

export default PermisosLaboralesView;