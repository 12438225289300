import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 12px;
  width: 100%;
  position: relative;
  margin-top: 7px;
`;
const BaseBox = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  transition: width 2s ease-in-out;
`;
const Progress = styled(BaseBox)`
  background: #2b388f;
  margin-left: 16px;
  width: ${({ percent }) => percent}%;
  `;
const Background = styled(BaseBox)`
  margin-left: 16px;
  background: #ebecf4;
  width: 100%;
`;
const ProgressBar = ({ percent }) => {
  return (
    <Container>
      <Background />
      <Progress percent={percent} />
    </Container>
  );
};

export default ProgressBar;
