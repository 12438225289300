import React from 'react';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../constans/colors';
import SweetAlert from '../../ui/SweetAlet';


const TablePercepcionOtra = React.memo(({listaPercepcion,setListaPercepcionOtra}) => {
    const history = useHistory();
    const dispatch = useDispatch();

	const deletePercepcion = async (f,index) => {
		const removed = listaPercepcion.splice(index,1);
		await setListaPercepcionOtra([...listaPercepcion]);	
	};
    
    return(
        <>
            {listaPercepcion.map((f, index) => (
              
          <Tr
            key={index}
          >
            <td>{f.especificaConcepto}</td>
            <td>{f.selector}</td>
            <td>{f.conceptoPercepcion}</td>
            <td>{
                <CurrencyFormat
                    value={f.montoOtra}
                    displayType={'text'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    thousandSeparator={true}
                    prefix={'$'}
                    renderText={value => <span>{value}</span>}
                />
            }</td>
            <td>
				<a href='#' 
				onClick={(e) => deletePercepcion(f,index)} 
				style={{textDecoration:'none',color:colors.tashColor}}
				>
				<FontAwesomeIcon 
					color={colors.tashColor}
					icon={faTrashAlt} 
				/>
				</a>
			</td>
          </Tr>
        ))}
        </>
    );
});

export default TablePercepcionOtra;