import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt,faHistory } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../constans/colors';
import { LabelForms, LabelFormsUp, InputUtils,CancelButton,SaveButton } from '../ui/styles/styles';
import ButtonDowload from '../colaboradores/Forms/FormExpedienteDigital/ButtonDownload';
import ButtonDowloadDisabled from '../colaboradores/Forms/FormExpedienteDigital/ButtonDownloadDisabled';
import SweetAlert from '../ui/SweetAlet';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { fecthExpedienteColaborador } from '../../store/colaboradorDucks';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Col, CustomInput, Label } from 'reactstrap';
import axios from '../../services/axios';
import TableBody from '../colaboradores/Forms/FormExpedienteDigital/TableBody';
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';


const InputFile = styled.input`
  outline: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
  width: 0%;
`;
const InputLabel = styled.label`
  width: ${(props) => (props.imagePreview ? '50%' : '100%')};
  /* border: 2px solid #2b388f; */
  background-color: white;
  border-radius: 4px;
  padding: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b388f;
  transition: all 200ms ease;
  cursor: pointer;
  text-align: center;
  > i {
    display: block;
    font-size: 42px;
    padding-bottom: 16px;
    margin-left: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #2b388f;
    transition: all 200ms color;
  }
 
  > span {
    margin-left: 0.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    transition: all 200ms color;
  }
  > input {
  }

  &:hover {
    /* background-color: #2B388F; */
    color: #2b3852; 
    border-color: #2B388F;
    > i {
    background-color: #2b3852; 
    color: white; 
    border-color: #2B388F;
    }
    > #svg {
    color: #2b3852 !important; 
    }  
  }
`;

const InputFileToUpload = React.memo(
  ({ fileDescription = 'Adjuntar', register = () => {}, nombre, archivo, seccion, obligatorio, expiracion, fecha='',historial=[], disabled=false }) => {
    const [objectId, setObjectId] = useState('');
    const [date, setDate] = useState({fechaExpiracion:''});
    const [estado, setEstado] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [arrayInfo, setArrayInfo] = useState([]);
    const dispatch = useDispatch();
    const {fechaExpiracion} = date;
    useEffect(() => {
      setDate({fechaExpiracion:fecha})
      if(estado === true) {
        SweetAlert({
          icon: 'success',
          title: 'Expediente agregado correctamente',
        });
      }
      setEstado(false);
    }, [estado]);
    const onChange = async ({ target }) => {
      console.log('aqui');
      if (!target.files[0]) return;
      const objectId = 'test'; //await uploadFile(target.files)
      setObjectId(() => objectId);
      const objeto = {
        "nombre": nombre,
        "seccion":seccion,
        "obligatorio": obligatorio,
        "expiracion":expiracion,
        "fechaExpiracion":date.fechaExpiracion ? parseInt(moment(date.fechaExpiracion).format('x')):''
      }
      console.log(objeto);
      try {
        if (objeto.fechaExpiracion !== '' || objeto.expiracion === false) {
          await dispatch(fecthExpedienteColaborador(objeto, target.files[0]));
          setEstado(true)
        }else{
          await SweetAlert({
            icon: 'error',
            title: 'La fecha es obligatoria',
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    const handleChange = e =>{
      let date = e.target.value;
      setDate({
        fechaExpiracion: date
      })
      
    }
    const onClick = async (historial) =>{
      if (historial.length !== 0) {
        await Promise.all(
          historial.map( async (element) => {
            const {data} = await axios.get(`/archivo/descargaInfo/${element}`);
            arrayInfo.push({
              id: element,
              fecha: data.fecha,
              nombre: data.nombreOriginal,
              type: data.mimetype,
              espacio: data.espacio
            })
          })
        );
      }
      setIsOpen(true)
    }
    const clickClose = () => {
      setIsOpen(false);
      setArrayInfo([])
    }
    
    return (
      <>
        {objectId || archivo ? (
          <>
          <Label sm={12} style={{marginTop:'15px'}}>
            <LabelFormsUp>{nombre}{obligatorio?'*':null}</LabelFormsUp>
          </Label>
            {expiracion
              ?
              <Col sm={{ offset:1, size:12}}>
                <InputUtils
                  type='date'
                  name='fechaExpiracion'
                  value={moment(fechaExpiracion).format('YYYY-MM-DD')}
                  onChange={handleChange}
                  style={{marginTop:'15px'}}
                  disabled={disabled}
                />
              </Col>
              :
              <Col sm={12}></Col>
            }
            {historial.length > 0 
            ?<a href='#' onClick={() => onClick(historial)} style={{zIndex:100}}>
              <FontAwesomeIcon
                id='modal'
                color={colors.submenuTextColor}
                icon={faHistory}
                style={{fontSize:'1.5rem', marginTop:'15px'}}
              />
              </a>
            :
            <Col sm={10}></Col>
            }
            <InputLabel style={{ width:'174px', height:'51px' }}>
              <FontAwesomeIcon
                id='svg'
                color={colors.secondary}
                icon={faCloudUploadAlt}
                style={{ fontSize: '1.5rem'}}
              />
              <span>{fileDescription}</span>
              <InputFile type='file' 
                name={nombre} 
                seccion={seccion} 
                obligatorio={obligatorio}
                expiracion={expiracion}
                onChange={onChange} 
                disabled={disabled}
              />
              <InputFile
                type='text'
                value={objectId}
                name={nombre}
                ref={register}
              />
            </InputLabel>
            <ButtonDowload
              Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${archivo}`}
            />
          </>
        ) : (
          <>
          <Label sm={12} style={{marginTop:'15px'}}>
            {archivo ?<LabelFormsUp>{nombre}{obligatorio?'*':null}</LabelFormsUp>:<LabelForms>{nombre}{obligatorio?'*':null}</LabelForms>} 
          </Label>
          {expiracion
            ?
            <Col sm={{ offset:1, size:12}}>
              <InputUtils
                type='date'
                name='fechaExpiracion'
                value={moment(fechaExpiracion).format('YYYY-MM-DD')}
                onChange={handleChange}
                style={{marginTop:'15px'}}
                disabled={disabled}
              />
            </Col>
            :
            <Col sm={12}></Col>
          }
          {historial.length > 0 
            ?<a href='#' onClick={() => onClick(historial)} style={{zIndex:100}}>
              <FontAwesomeIcon
                id='modal'
                color={colors.submenuTextColor}
                icon={faHistory}
                style={{fontSize:'1.5rem', marginTop:'15px'}}
              />
              </a>
            :
            <Col sm={10}></Col>
            }
          <InputLabel style={{ width:'174px', height:'51px' }}>
            <FontAwesomeIcon
              id='svg'
              color={colors.secondary}
              icon={faCloudUploadAlt}
              style={{ fontSize: '1.5rem' }}
            />
            <span>{fileDescription}</span>
            <InputFile type='file' 
              style={{maxHeight: '38.3px'}}
              name={nombre} 
              seccion={seccion} 
              obligatorio={obligatorio}
              expiracion={expiracion}
              onChange={onChange} 
              disabled={disabled}
            />
          </InputLabel>
           <ButtonDowloadDisabled
              
            />
          </>
        )}
        <Modal isOpen={isOpen} centered size='lg'>
            <ModalHeader>
                <span style={{ color: 'white', fontWeight: '400' }}>
                    Historial
                </span>
                <button
                    className='close'
                    style={{ position: 'absolute', top: '15px', right: '15px' }}
                    onClick={clickClose}
                >x
                </button>
            </ModalHeader>
            <ModalBody>
                <Form >
                    <FormGroup row>
                    <TableContainer style={{marginLeft:30, marginTop:10,height:550}}>
                      <TableCommon
                        keysAceepted={['Fecha', 'Nombre', '' ]}
                        extraTag={{ key: 'Valor Total', value: 1200 }}
                        striped
                      >
                      <TableBody arrayInfo={arrayInfo}/>
                      </TableCommon>
                    </TableContainer>
                    </FormGroup>
                    <ModalFooter>
                       
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
      </>
    );
  }
);

export default InputFileToUpload;
