import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/pro-solid-svg-icons';
import colors from '../../../../constans/colors';

const ContainerButtonDowload = styled.a`
  text-decoration: none !important;
  width: 174px;
  height: 51px;
  /* border: 2px solid #2b388f;  */
  background-color: transparent;
  border-radius: 4px;
  padding: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  cursor: pointer;
  text-align: center;
  color: #2b388f !important;
  &:hover {
    /* background-color: #2b388f; */
    color: #2b3852 !important;
    border-color: #2b388f;
  }
`;
const SpanDowload = styled.span`
  margin-left: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  transition: all 200ms;
  &:hover{
    color: #2b3852;
  }
`;
const ContainerButtonDowloadSalud = styled.a`
  text-decoration: none !important;
  width: 100%;
  height: 4vh;
  /* border: 2px solid #2b388f; 
  background-color: white;*/
  border-radius: 4px; 
  padding: 0.15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
  cursor: pointer;
  text-align: center;
  /* &:hover {
    background-color: #767fb9;
    color: white;
    border-color: #767fb9;
  } */
`;
const SpanDowloadSalud = styled.span`
  margin-left: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 250;
  color:black;
  transition: all 200ms;
`;

const ButtonDowload = ({ fileDescription = 'Descargar', Url = '' }) => {
  return (
    <ContainerButtonDowload
      href={Url}
      target='_blank'
      rel='noopener noreferrer'
      download
      disabled={true}
    >
      <FontAwesomeIcon
        color={{color:'#3C3C3C'}}
        icon={faCloudDownloadAlt}
        style={{ fontSize: '1.5rem' }}
      />
      <SpanDowload>{fileDescription}</SpanDowload>
    </ContainerButtonDowload>
  );
};
export const ButtonDowloadSalud = ({ fileDescription = 'Descargar', Url = '' }) => {
  return (
    <ContainerButtonDowloadSalud
      href={Url}
      target='_blank'
      rel='noopener noreferrer'
      download
    >
      <SpanDowloadSalud style={{color:'#3C3C3C',fontWeight:'500'}}>{fileDescription}</SpanDowloadSalud>
      <FontAwesomeIcon
        color={colors.primary}
        icon={faCloudDownloadAlt}
        style={{ fontSize: '1vw', color:'#2B388F', marginLeft:5 }}
      />
    </ContainerButtonDowloadSalud>
  );
};

export default ButtonDowload;
