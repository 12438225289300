import React from 'react';
import { Switch, Route, HashRouter,  } from 'react-router-dom';
import FormExpedienteDigital from './Forms/FormExpedienteDigital'
const ExpedienteDigital = () => {
  return (
    <HashRouter basename='/'>
      <Switch>
        <Route path='/' component={FormExpedienteDigital} />
      </Switch>
    </HashRouter>
  );
};

export default ExpedienteDigital;