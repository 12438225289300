import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { fetchEmpresas, cleanStateEmpresas } from '../../store/empresasDuck';
import ComponentDescrip from '../ui/ComponentDescrip';
import TablaEmpresas from './TablaEmpresas';
import Spiner from '../ui/Spiner';
import { SaveButton } from '../ui/styles/styles';
import { useHistory } from 'react-router-dom';
import { DarkButton } from '../../components/ui/styles/styles';


const Empresa = () => {
  const { empresas, loading } = useSelector((state) => state.empresas);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchEmpresas());
      } catch (error) {
        console.log(error);
      }
    })();
  }, [dispatch]);
  useEffect(() => {
    cleanStateEmpresas()
  }, []);
  const history = useHistory();
  const handleSubmit = async () => {
    try {
      history.push('crear-empresa')
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <ComponentDescrip
            title='Empresas'
            description='Encuentra aquí tus empresas registradas'
            icon={faUsers}
          />
        </Col>
      </Row>
      <div className='row pr-3 mt-3' style={{ width: '200px', marginLeft: 'auto' }}>
        {/* <DarkButton onClick={(e) => history.push('/dashboard/cuenta/crear-empresa')}>+ Agregar</DarkButton> */}
      </div>
      <Row>
        <Col lg={12}>
            
            </Col>
          {/* TODO:Crear despues el buscador 
          <Search placeholder='Buscar empresa, departamento, sucursal' />
        */}
      
      </Row>
      
      <Row>
        <Col lg={12}>
          {loading ? <Spiner /> : <TablaEmpresas lista={empresas} />}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Empresa;
