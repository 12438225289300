import React, { useEffect, useState } from 'react';
import { FormGroup, Form, Col, CustomInput, Table, Label } from 'reactstrap';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEditarEmpresa } from '../../store/empresasDuck';
import Spiner from '../ui/Spiner';
import SweetAlert from '../ui/SweetAlet';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { empresaSchema } from '../../constans/yupSchemas';
import CustomSelectEstados from '../ui/CustomSelectEstados';
import SelectRegimenFiscal from '../ui/SelectRegimenFiscal';
import {
  SaveButton,
  CancelButton,
  InputUtils,
  LabelForms,
  SubTitleMenu,
  SubTitleLogotipo,
} from '../ui/styles/styles';
import InputImageEmpresa from '../ui/InputImageEmpresa';
import InputFile from '../ui/InputFile';
import ButtonDowload from '../ui/ButtonDowload';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomSelect from '../ui/CustomSelect';
import delegacionesIMSSOptions from '../../constans/delegacionesIMSS';
import colors from '../../constans/colors';
import axios from '../../services/axios';

const api = process.env.REACT_APP_API_URL;

const FormEmpresa = () => {
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const {
    loading,
    registrosPatronales,
    numeroPeriodos,
    informacionLegal = {},
    nombre_empresa = '',
    documentos = {},
    logotipo = '',
    direccion = '',
    aplicaSeptimoDia = true,
  } = useSelector((state) => state.empresas);

  const history = useHistory();
  const dispatch = useDispatch();

  const [personType, setPersonType] = useState(informacionLegal['tipo_persona'] === 'Persona Moral' ? 'moral' : 'fisica');
  const [fileName, setFileName] = useState(informacionLegal['numeroCertificado']);
  const {
    register,
    handleSubmit,
    errors,
    control,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(empresaSchema),
  });
  const [subdelegacionesIMSSOptions, setSubdelegacionesIMSSOptions] = useState([]);

  const [registroPatronal, setRegistroPatronal] = useState({ registroPatronal: '', delegacionIMSS: '', subdelegacionIMSS: '' });
  const [registros, setRegistros] = useState([]);

  const addRegistroPatronal = () => {
    if (registroPatronal.registroPatronal === '' || registroPatronal.delegacionIMSS === '' || registroPatronal.subdelegacionIMSS === '') {
      SweetAlert({ icon: 'warning', title: 'El número de registro, la delegación y subdelegación son requeridos' });
      return;
    }
    setRegistros([...registros, registroPatronal]);
    setRegistroPatronal({ registroPatronal: '', delegacionIMSS: '', subdelegacionIMSS: '' })
  }

  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const changeDelegacion = (value) => {
    setRegistroPatronal({ ...registroPatronal, delegacionIMSS: value });
    if (value === '') setSubdelegacionesIMSSOptions([])
    else {
      const delegacion = delegacionesIMSSOptions.filter(d => d.value === value)
      setSubdelegacionesIMSSOptions(delegacion[0].subdelegaciones)
    }
  };

  useEffect(() => {
    setRegistros(registrosPatronales);
  }, [registrosPatronales]);

  const submit = async (data) => {
    try {
      if (registros.length === 0) {
        SweetAlert({ icon: 'warning', title: 'Debe agregar al menos un registro patronal en la empresa' });
        return
      }
      data.registrosPatronales = registros;
      data.informacionLegal.certificado = informacionLegal.certificado
      await dispatch(fetchEditarEmpresa(data));
      SweetAlert({ icon: 'success', title: 'Registrado exitosamente' });
      history.push('/dashboard/cuenta/empresa');
    } catch (e) {
      SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  };

  const addCert = (e) => {
    const { informacionLegal } = getValues()
    if (informacionLegal.certificado && informacionLegal.certificado[0]) {
      setFileName(informacionLegal.certificado[0].name.replace('.cer', ''));
      getBase64(informacionLegal.certificado[0])
      setValue('informacionLegal.certificado', informacionLegal.certificado);
      if (err.informacionLegal && err.informacionLegal.numeroCertificado) {
        delete err.informacionLegal.numeroCertificado
      }
    }
  };

  if (loading) {
    return <Spiner />;
  }
  const getFile = (files) => {
    register({ name: 'informacionLegal.certificado' }, { required: false, validate: true });
    setValue('informacionLegal.certificado', files[0]);
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      informacionLegal.certificado = reader.result.replace("data:application/x-x509-ca-cert;base64,", "")
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  async function selectZona(zona) {
    console.log(`Se ha seleccionado ${zona}`);
    const { data } = await axios.get(`${api}/empresa/obtener_zona?zona=${zona}`, headers)
    console.log(data)
    setValue('informacionLegal.salarioMinimo', data.zona.salarioMinimo);
    setValue('informacionLegal.uma', data.zona.uma);
    setValue('informacionLegal.umi', data.zona.umi);
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <FormGroup row>
        <Col sm={3}>
          <SubTitleMenu className='ml-0'
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Logotipo</SubTitleMenu>
        </Col>
        <Col sm={9} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <InputImageEmpresa
            loadImageUrl={
              logotipo
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
                : ''
            }
            type='file'
            id='logotipo'
            name='logotipo'
            accept='image/jpg,image/jpeg,image/png'
            getFile={getFile}
            register={register}
            style={{ widt: '4vw', minHeigt: '4vw', marginLeft: '16px' }}
          />
          {err.logotipo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El logotipo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Nombre Empresa*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='nombre_empresa'
            defaultValue={nombre_empresa}
            placeholder='Introduzca el nombre de la empresa'
            ref={register()}
          />
          {err.nombre_empresa && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El nombre de la empresa es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Razón Social</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Tipo persona*</LabelForms>
        </Col>
        <Col sm={3}>
          <CustomInput
            innerRef={register}
            type='radio'
            label='Persona Física'
            value='Persona Fisica'
            name='informacionLegal.tipo_persona'
            defaultChecked={informacionLegal['tipo_persona'] === 'Persona Fisica'}
            onClick={() => setPersonType('fisica')}
          />
        </Col>
        <Col sm={3}>
          <CustomInput
            innerRef={register}
            type='radio'
            label='Persona Moral'
            value='Persona Moral'
            name='informacionLegal.tipo_persona'
            defaultChecked={informacionLegal['tipo_persona'] === 'Persona Moral'}
            onClick={() => setPersonType('moral')}
          />
        </Col>
      </FormGroup>
      {err.informacionLegal && err.informacionLegal.tipo_persona && (
        <FormGroup row>
          <Col sm={3}>
            <LabelForms sm={3}>
            </LabelForms>
          </Col>
          <Col sm={3}>
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El tipo de persona es requerido
            </span>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Col sm={3} row>
          <LabelForms>Razón Social*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.razon_social'
            placeholder='Tu Empresa SA de CV'
            defaultValue={informacionLegal['razon_social']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.razon_social && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La razón social es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>RFC*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.rfc'
            placeholder='Introduzca el RFC'
            defaultValue={informacionLegal['rfc']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.rfc && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El RFC es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      {
        personType === 'fisica' &&
        <FormGroup row>
          <Col sm={3}>
            <LabelForms>CURP*</LabelForms>
          </Col>
          <Col sm={9}>
            <InputUtils
              type='text'
              name='informacionLegal.curp'
              placeholder='Introduzca la CURP'
              defaultValue={informacionLegal['curp']}
              ref={register()}
            />
            {err.informacionLegal && err.informacionLegal.curp && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La CURP es requerida
              </span>
            )}
          </Col>
        </FormGroup>
      }
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Área geográfica</LabelForms>
        </Col>
        <Col sm={9}>
          <CustomSelect
            control={control}
            type='select'
            name='informacionLegal.areaGeografica'
            placeholder='Seleccione el área geográfica'
            defaultValue={informacionLegal['areaGeografica']}
            innerRef={register()}
            options={[
              { value: 'zlfn', label: 'Zona Libre de la Frontera Norte' },
              { value: 'nacional', label: 'Nivel Nacional' },
            ]}
            onChange={e => selectZona(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>UMA*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            step={0.01}
            name='informacionLegal.uma'
            placeholder='Introduzca el UMA'
            defaultValue={informacionLegal['uma']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.uma && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La UMA es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Unidad Mixta Infonavit (UMI)*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            step={0.01}
            name='informacionLegal.umi'
            placeholder='Introduzca el valor de UMI'
            defaultValue={informacionLegal['umi']}
            required
            ref={register()}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Salario Mínimo*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            name='informacionLegal.salarioMinimo'
            placeholder='Introduzca el salario mínimo'
            defaultValue={informacionLegal['salarioMinimo']}
            step={0.01}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.salarioMinimo && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El salario mínimo es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Régimen*</LabelForms>
        </Col>
        <Col sm={5}>
          <SelectRegimenFiscal
            control={control}
            type='select'
            name='informacionLegal.regimen'
            placeholder='Introduzca el régimen'
            defaultValue={informacionLegal['regimen']}
            innerRef={register()}
          />
          {err.informacionLegal && err.informacionLegal.regimen && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El régimen es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Certificado*</LabelForms>
        </Col>
        <Col sm={3}>
          <InputFile
            id='informacionLegal.certificado'
            name='informacionLegal.certificado'
            getFile={getFile}
            onChange={() => addCert()}
            accept='.cer'
            register={register}
          />
          {err.informacionLegal && err.informacionLegal.numeroCertificado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El certificado es requerido
            </span>
          )}
        </Col>
        <Col sm={3}>
          <InputUtils
            type='text'
            name='informacionLegal.numeroCertificado'
            placeholder='Introduzca el número de certificado'
            ref={register()}
            defaultValue={informacionLegal['numeroCertificado']}
            value={fileName}
            disabeld={true}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Correo de notificaciones*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.correNotificaciones'
            placeholder='Introduzca el correo notificaciones'
            defaultValue={informacionLegal['correNotificaciones']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.correNotificaciones && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El correo de notificaciones es requerido
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Configuración del PAC &nbsp;
          <FontAwesomeIcon
              id='svg'
              color={colors.secondary}
              icon={faInfoCircle}
              title='Si no cuenta con su registro en el PAC, consulte a su asesor Nomizor.'
            />
          </SubTitleMenu>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.contratoPade'
            placeholder='Introduzca el contrato PADE'
            defaultValue={informacionLegal['contratoPade']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.contratoPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El contrato PADE es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Correo del contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='informacionLegal.correoContratoPade'
            placeholder='Introduzca el correo contrato PADE'
            defaultValue={informacionLegal['correoContratoPade']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.correoContratoPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El correo del contrato PADE es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Contraseña del contrato PADE*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='password'
            name='informacionLegal.contraseniaPade'
            placeholder='Introduzca la constraseña contrato PADE'
            defaultValue={informacionLegal['contraseniaPade']}
            ref={register()}
          />
          {err.informacionLegal && err.informacionLegal.contraseniaPade && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La contraseña del contrato PADE es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Dirección</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Calle*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.calle'
            placeholder='Introduzca la dirección de la empresa'
            defaultValue={direccion['calle']}
            ref={register()}
          />
          {err.direccion && err.direccion.calle && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La calle es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Número*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.numero'
            placeholder='Introduzca el número'
            defaultValue={direccion['numero']}
            ref={register()}
          />
          {err.direccion && err.direccion.numero && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Colonia*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.colonia'
            placeholder='Introduzca la colonia de la empresa'
            defaultValue={direccion['colonia']}
            ref={register()}
          />
          {err.direccion && err.direccion.colonia && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La colonia es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Código Postal*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.codigoPostal'
            placeholder='Introduzca el código postal de la empresa'
            defaultValue={direccion['codigoPostal']}
            ref={register()}
          />
          {err.direccion && err.direccion.codigoPostal && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El código postal es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Ciudad*</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='text'
            name='direccion.ciudad'
            placeholder='Introduzca la ciudad de la empresa'
            defaultValue={direccion['ciudad']}
            ref={register()}
          />
          {err.direccion && err.direccion.ciudad && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              La ciudad es requerida
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Estado*</LabelForms>
        </Col>
        <Col sm={5}>
          <CustomSelectEstados
            type='select'
            name='direccion.estado'
            id='estadoResidencia'
            defultValue={direccion['estado']}
            control={control}
            innerRef={register({ required: true })}
          />
          {err.direccion && err.direccion.estado && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El estado es requerido
            </span>
          )}
        </Col>
      </FormGroup>

      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Compensación en incidencias</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Número de periodos</LabelForms>
        </Col>
        <Col sm={9}>
          <InputUtils
            type='number'
            name='numeroPeriodos'
            placeholder='Introduzca el número periodos'
            defaultValue={numeroPeriodos}
            ref={register()}
          />
          {err.numeroPeriodos && (
            <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
              El número periodos es requerido
            </span>
          )}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Faltas: Aplica séptimo día</Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="aplicaSeptimoDia" id="aplicaSeptimoDia1" defaultChecked={aplicaSeptimoDia === true} value={true} innerRef={register()} />
          Si
        </Label>
        <Label check sm={1} className='radio-organize' inline='true' style={{ marginLeft: '15px' }}>
          <CustomInput type="radio" name="aplicaSeptimoDia" defaultChecked={aplicaSeptimoDia === false} id="aplicaSeptimoDia2" value={false} innerRef={register()} />
          No
        </Label>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >IMSS</SubTitleMenu>
        </Col>
      </FormGroup>

      <FormGroup>
        {registros.length > 0 ? (
          <Table striped>
            <thead>
              <tr>
                <th>Registro Patronal</th>
                <th>Delegación IMSS</th>
                <th>Subdelegación</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {registros.map(
                (registro, index) => (
                  <tr key={index}>
                    <td>{registro.registroPatronal}</td>
                    <td>{registro.delegacionIMSS}</td>
                    <td>{registro.subdelegacionIMSS}</td>
                    <th>
                      <FontAwesomeIcon icon={faTrash} onClick={() => setRegistros(registros.filter(r => r !== registro))} style={{ cursor: 'pointer' }} />
                    </th>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        ) : null}
      </FormGroup>

      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Registro Patronal</LabelForms>
        </Col>
        <Col sm={5}>
          <InputUtils
            type='text'
            name='registroPatronal'
            placeholder='Introduzca el registro patronal'
            value={registroPatronal.registroPatronal}
            onChange={e => setRegistroPatronal({ ...registroPatronal, registroPatronal: e.target.value })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Delegación</LabelForms>
        </Col>
        <Col sm={5}>
          <CustomSelect
            type='select'
            name='delegacionIMSS'
            id='delegacionIMSS'
            value={registroPatronal.delegacionIMSS}
            options={delegacionesIMSSOptions}
            control={control}
            onChange={(e) => changeDelegacion(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Subdelegación</LabelForms>
        </Col>
        <Col sm={5}>
          <CustomSelect
            type='select'
            name='subdelegacionIMSS'
            control={control}
            value={registroPatronal.subdelegacionIMSS}
            options={subdelegacionesIMSSOptions}
            onChange={e => setRegistroPatronal({ ...registroPatronal, subdelegacionIMSS: e.target.value })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3} className='text-center'>
          <CancelButton type='button' onClick={addRegistroPatronal}>
            + Agregar
            </CancelButton>
        </Col>
      </FormGroup>
      <FormGroup row style={{ marginTop: '50px' }}>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Contrato colectivo de trabajo</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <ButtonDowload
            Url={
              documentos['contratoColectivo']
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${documentos['contratoColectivo']}`
                : ''
            }
            id='documentos.contratoColectivo'
            name='documentos.contratoColectivo'
            getFile={getFile}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Reglamento interior de trabajo</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <ButtonDowload
            Url={
              documentos['reglamentoInterioTrabajo']
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${documentos['reglamentoInterioTrabajo']}`
                : ''
            }
            id='documentos.reglamentoInterioTrabajo'
            name='documentos.reglamentoInterioTrabajo'
            getFile={getFile}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Registro Federal de contribuyentes</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <ButtonDowload
            Url={
              documentos['registroFederalContribuyentes']
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${documentos['registroFederalContribuyentes']}`
                : ''
            }
            id='documentos.registroFederalContribuyentes'
            name='documentos.registroFederalContribuyentes'
            getFile={getFile}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <LabelForms>Comprobrante de domicilio</LabelForms>
        </Col>
        <Col sm={{ offset: 2, size: 2 }}>
          <ButtonDowload
            Url={
              documentos['comprobanteDomicilio']
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${documentos['comprobanteDomicilio']}`
                : ''
            }
          />
        </Col>
      </FormGroup>
      <br /><br />
      <FormGroup row>
        <Col sm={{ offset: 7, size: 5 }}>
          <SaveButton type='submit'>Guardar</SaveButton>
        </Col>
      </FormGroup>
    </Form>
  );
};

export default FormEmpresa;
