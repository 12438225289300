import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import {
    Table
} from "reactstrap";
import CurrencyFormat from 'react-currency-format';

const ResumenModal = ({ data }) => {
    const { otrosPagos = [], totales = {} } = data
    const [arrayDeducciones, setArrayDeducciones] = useState([])
    const [arrayPercepciones, setArrayPercepciones] = useState([])

    useEffect(() => {
        if (Object.keys(data).length) {
            const { deducciones = {}, percepciones = {} } = data;
            const ordinariasDeducciones = deducciones.ordinarias;
            const extraOrdinariasDeducciones = deducciones.extraordinarias;
            const ordinariasPercepciones = percepciones.ordinarias;
            const extraOrdinariasPercepciones = percepciones.extraordinarias;
            setArrayDeducciones([].concat(ordinariasDeducciones, extraOrdinariasDeducciones));
            setArrayPercepciones([].concat(ordinariasPercepciones, extraOrdinariasPercepciones));
        }
    }, [data])


    return (
        <Fragment>
            {Object.keys(totales).length ?
                <>
                    <div style={{ width: '50%', float: 'left' }}>
                        <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                            <thead>
                                <tr>
                                    <th>Percepciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrayPercepciones.map((d, i) => (
                                    <tr key={i}>
                                        <td>{d.clave || d.codigo}</td>
                                        <td>{d.descripcion}</td>
                                        <CurrencyFormat
                                            value={d.monto}
                                            displayType={'text'}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
                                        />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                        <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                            <thead>
                                <tr>
                                    <th>Deducciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {arrayDeducciones.map((d, i) => (
                                    <tr key={i}>
                                        <td>{d.clave || d.codigo}</td>
                                        <td>{d.descripcion}</td>
                                        <CurrencyFormat
                                            value={d.monto}
                                            displayType={'text'}
                                            decimalScale={2}
                                            thousandSeparator={true}
                                            prefix={'$'}
                                            renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
                                        />
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    {otrosPagos.length > 0 ?
                        <div style={{ width: '51%', float: 'left' }}>
                            <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
                                <thead>
                                    <tr>
                                        <th>Otros Pagos</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {otrosPagos.map((d, i) => (
                                        <tr key={i}>
                                            <td>{d.clave || d.codigo}</td>
                                            <td>{d.descripcion}</td>
                                            <CurrencyFormat
                                                value={d.monto}
                                                displayType={'text'}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                                renderText={value => <td style={{ textAlign: 'end' }}>{value}</td>}
                                            />
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <CurrencyFormat
                                value={totales.totalPercepciones}
                                displayType={'text'}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <h4 style={{ float: 'right', fontSize: '1.2rem' }}>Total: {value}</h4>}
                            />
                        </div>
                        :
                        <div style={{ width: '51%', float: 'left', textAlign: 'end' }}>
                            <CurrencyFormat
                                value={totales.totalPercepciones}
                                displayType={'text'}
                                decimalScale={2}
                                thousandSeparator={true}
                                prefix={'$'}
                                renderText={value => <h4 style={{ float: 'right', fontSize: '1.2rem' }}>Total: {value}</h4>}
                            />
                        </div>
                    }
                    <div style={{ width: '51%', float: 'right', textAlign: 'end' }}>
                        <CurrencyFormat
                            value={totales.totalPercepciones}
                            displayType={'text'}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Subtotal: {value}</h4>}
                        />
                        <CurrencyFormat
                            value={totales.totalDeducciones}
                            displayType={'text'}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Descuentos: {value}</h4>}
                        />
                        <CurrencyFormat
                            value={totales.total}
                            decimalScale={2}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => <h4 style={{ fontSize: '1.2rem' }}>Total: {value}</h4>}
                        />
                    </div>
                </>
                : null}
        </Fragment>
    );
}

export default ResumenModal;