const initState = {
    loading: false,
    error: null,
    listaUsuarios : [],
    editar: false,
    usuario:{}
}

const FETCH_USUARIOS = 'FETCH_USUARIOS';
const FETCH_USUARIOS_EXITO = 'FETCH_USUARIOS_EXITO';
const FETCH_USUARIOS_ERROR = 'FETCH_USUARIOS_ERROR';

const ELIMINAR_USARIO = 'ELIMINAR_USUARIO';
const ELIMINAR_USARIO_EXITO = 'ELIMINAR_USARIO_EXITO';
const ELIMINAR_USARIO_ERROR = 'ELIMINAR_USARIO_ERROR';

const CAMBIAR_CONTRASENA = 'CAMBIAR_CONTRASENA';
const CAMBIAR_CONTRASENA_EXITO = 'CAMBIAR_CONTRASENA_EXITO';
const CAMBIAR_CONTRASENA_ERROR = 'CAMBIAR_CONTRASENA_ERROR';

const AGREGAR_USUARIO = 'AGREGAR_USUARIO';
const AGREGAR_USUARIO_EXITO = 'AGREGAR_USUARIO_EXITO';
const AGREGAR_USUARIO_ERROR = 'AGREGAR_USUARIO_ERROR';
const FETCH_USUARIOS_DROPDOWN = 'FETCH_USUARIOS_DROPDOWN';

export default function usuariosReducer( state=initState,{type,payload}){
    switch (type) {
        case FETCH_USUARIOS:
            return {...state,loading: true}
        case FETCH_USUARIOS_EXITO:
            return {...state, loading: false, listaUsuarios: payload.listaUsuarios}
        case FETCH_USUARIOS_ERROR:
            return {...state, loading: false}
        case ELIMINAR_USARIO:
            return {...state, loading: true}
        case ELIMINAR_USARIO_EXITO:
            return {...state, loading: false}
        case ELIMINAR_USARIO_ERROR:
            return {...state, loading: false}
        case AGREGAR_USUARIO:
            return {...state, loading: true}
        case AGREGAR_USUARIO_EXITO:
            return {...state, loading: false}
        case AGREGAR_USUARIO_ERROR:
            return {...state, loading: false}
        case CAMBIAR_CONTRASENA:
            return {...state, loading: true}
        case CAMBIAR_CONTRASENA_EXITO:
            return {...state, loading: false}
        case CAMBIAR_CONTRASENA_ERROR:
            return {...state, loading: false}
        case FETCH_USUARIOS_DROPDOWN:
            return {...state, loading: false, listaUsuarios:[...payload]}
        default:
        return {...state}
    }
}
const cambiarContrasena = () =>({
    type: CAMBIAR_CONTRASENA
});

const cambiarContrasenaExito = () =>({
    type: CAMBIAR_CONTRASENA_EXITO
});

const cambiarContrasenaError = () =>({
    type: CAMBIAR_CONTRASENA_ERROR
});

const fetchUsuarios = () =>({
    type: FETCH_USUARIOS
})
const fetchUsuariosExito = payload =>({
    type: FETCH_USUARIOS_EXITO,
    payload
})
const fetchUsuariosError = () =>({
    type: FETCH_USUARIOS_ERROR
})
const eliminarUsuario = () =>({
    type :ELIMINAR_USARIO
})
const eliminarUsuarioExito = () =>({
    type: ELIMINAR_USARIO_EXITO
})
const eliminarUsuarioError = () =>({
    type : ELIMINAR_USARIO_ERROR
})
const agregarUsuario = () =>({
    type: AGREGAR_USUARIO
})
const agregarUsuarioExito = ()=>({
    type: AGREGAR_USUARIO_EXITO
})
const agregarUsuarioError = () =>({
    type: AGREGAR_USUARIO_ERROR
})
const fetchUsuariosToDropDown = payload =>({
    type: FETCH_USUARIOS_DROPDOWN,
    payload
})

export const cambiarContrasenaAction = (data, _id) => async(dispatch, getState, {axios}) =>{
    try {
        dispatch(cambiarContrasena());
        await axios.patch(`/usuario/${_id}`, data, { headers: { token: localStorage.getItem('pv2token') }});
        dispatch(cambiarContrasenaExito());
    } catch (e) {
        console.log('error');
        console.log(e.response);
        const { data } = e.response;
        dispatch(cambiarContrasenaError({data}))
        throw data;
    }
}

export const fetchUsuariosAction = () => async(dispatch,getState, {axios}) =>{
    const {_id} = getState().user
    dispatch(fetchUsuarios())
    try {
        const {listaUsuarios}  = (await axios.get(`/usuario/lista_usuarios/?createdBy=${_id}`, { headers: { token: localStorage.getItem('pv2token') } })).data;
        dispatch(fetchUsuariosExito({listaUsuarios}))
    }catch (e) {
        const { data } = e.response;
        dispatch(fetchUsuariosError({data}))
        throw data;
    }
}
export const eliminarUsuarioAction = (idUsuario) => async(dispatch, getState, {axios}) =>{
    dispatch(eliminarUsuario())
    try {
        const { data }  = await axios.delete(`/usuario/eliminar_usuario/${idUsuario}`, { headers: { token: localStorage.getItem('pv2token') } });
        dispatch(fetchUsuariosAction())
        dispatch(eliminarUsuarioExito())
    }catch (e) {
        const { data } = e.response;
        dispatch(eliminarUsuarioError())
        throw data;
    }
}
export const agregarUsuarioAction = ({usuario, contrasena, tipoUsuario}) => async(dispatch,getState, {axios}) =>{
    const {_id} = getState().user;
    dispatch(agregarUsuario())
    try {
        const {msg}  = (await axios.post(`/usuario/crear_usuario/`,{usuario, contrasena, tipoUsuario, _id}, { headers: { token: localStorage.getItem('pv2token') } })).data;
        dispatch(agregarUsuarioExito())
    }catch (error) {
        const { data } = error.response;
        dispatch(agregarUsuarioError(data));
        throw data;
    }
}
export const fetchUsuariosToDropdown = () => async(dispatch,getState, {axios}) =>{
    const {_id}=getState().user
    dispatch(fetchUsuarios())
    try {
        const {listaUsuarios}  = (await axios.get(`/usuario/lista_usuarios/?createdBy=${_id}`, { headers: { token: localStorage.getItem('pv2token') } })).data;
        const list = listaUsuarios.map(({ _id, usuario }) => {
            return { value: _id, label: usuario };
        });
        dispatch(fetchUsuariosToDropDown(list));
    }catch (e) {
        const { data } = e.response;
        dispatch(fetchUsuariosError({data}))
        throw data;
    }
}