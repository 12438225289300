import React, { useState, useCallback, useEffect } from "react";
import { Col, Form, FormGroup, Input, CustomInput, Label } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { ExamLabel } from "../Forms/FormsSaludLaboral/styledSaludLaboral";
import { schemaSaludLaboral } from "../../../constans/yupSchemas";
import { tiposSangre } from "../../../constans/tipoSangre";
import actividadFisica from "../../../constans/actividadFisica";
import tiposExamenes from "../../../constans/tipoExamanes";
import CustomSelect from "../../ui/CustomSelect";
import { useForm } from "react-hook-form";
import InputFile from "../../ui/InputFile";
import TableCommonSaludLaboral from "../Forms/FormsSaludLaboral/TableCommonSaludLaboral";
import TableBody from "./FormsSaludLaboral/TableBody";
import TableExamenesMedicos from "./FormsSaludLaboral/TableExamenesMedicos";
import Notifiacion from "./FormsSaludLaboral/Notificacion";
import { fetchAgregarSaludLaboral } from "../../../store/colaboradorDucks";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../../services/uploadFile";
import "./FormSaludLaboral.css";
import SweetAlert from "../../ui/SweetAlet";
import { SaveButton, CancelButton } from "../../ui/styles/styles";
import GroupSelect from "../../ui/GroupSelect";

const FormSaludLaboral = React.memo(() => {
  const dispatch = useDispatch();
  const { informacionPersonal = {} } = useSelector(
    (state) => state.colaborador
  );
  const {
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schemaSaludLaboral),
  });
  console.log(errors);
  const [tablaExamenMedico, setTablaExamenMedico] = useState([]);
  const [tablaProximoExamenMedico, setTablaProximoExamenMedico] = useState([]);
  const [especifica, setEspecifica] = useState(false);
  const [alergias, setAlergias] = useState(false);
  const [discapacidad, setDiscapacidad] = useState(false);

  const [showAdd, setShowAdd] = useState(true);
  const [showProgram, setShowProgram] = useState(true);

  const toggleAdd = () => setShowAdd(!showAdd);
  const toggleProgram = () => setShowProgram(!showProgram);

  const addToTable = async () => {
    const { examenMedico } = getValues();
    try {
      const upload = await Upload(examenMedico.archivoExamenMedico);
      examenMedico.archivoExamenMedico = upload;
      if (examenMedico.nombreExamen !== "") {
        setTablaExamenMedico((actualState) => [...actualState, examenMedico]);
        const cleanInputData = {
          nombreExamen: "",
          fechaAplicacion: "",
          archivoExamenMedico: "",
        };
        setValue("examenMedico", cleanInputData, { shouldDirty: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updatePosition = (fechaExamenMedico, tipoExamen) => {
    const index = tablaProximoExamenMedico.findIndex(
      (x) => x.fechaExamenMedico === fechaExamenMedico
    );
    if (index < 0) return false;
    if (tablaProximoExamenMedico[index].tipoExamen === tipoExamen) return true;
    tablaProximoExamenMedico[
      index
    ].tipoExamen = `${tablaProximoExamenMedico[index].tipoExamen},
   ${tipoExamen}`;
    const updateTable = [...tablaProximoExamenMedico];
    setTablaProximoExamenMedico(() => updateTable);
    return true;
  };
  const parseToBoolean = (data) => {
    return data === "true" ? (data = true) : (data = false);
  };
  const addToTableProximoExamen = () => {
    const {
      proximoExamenMedico,
      notificacion = false,
      otrosEstudios,
    } = getValues();
    const { fechaExamenMedico, tipoExamen, hora } = proximoExamenMedico;
    if (!fechaExamenMedico || !tipoExamen || !hora) return;
    if (updatePosition(fechaExamenMedico, tipoExamen)) return;
    parseToBoolean(notificacion);
    const updateTable = [
      ...tablaProximoExamenMedico,
      {
        ...proximoExamenMedico,
        notificacion: parseToBoolean(notificacion),
        otrosEstudios,
      },
    ];
    setTablaProximoExamenMedico(() => updateTable);
  };

  const clickMedicamentos = (e) => {
    let value = e.target.value;
    if (value === "true") {
      setEspecifica(true);
    } else {
      setEspecifica(false);
    }
  };

  const clickAlergias = (e) => {
    let value = e.target.value;
    if (value === "true") {
      setAlergias(true);
    } else {
      setAlergias(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      //console.log(data);
      data.examenMedico = tablaExamenMedico;
      data.proximoExamenMedico = tablaProximoExamenMedico;
      console.log(data);
      await dispatch(fetchAgregarSaludLaboral(data));
      await SweetAlert({
        icon: "success",
        title: "Se completo Salud Laboral correctamente, puede continuar...",
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleDelete = useCallback(
    (data) => {
      console.log("entre aqui");
      const filtro = tablaExamenMedico.filter(
        (obj) => obj.nombreExamen !== data
      );
      setTablaExamenMedico(() => filtro);
    },
    [tablaExamenMedico, setTablaExamenMedico]
  );
  useEffect(() => { }, [tablaProximoExamenMedico]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Notas de evolución
          </h5>
          <Label sm={12} className="labelGray">
            Signos Vitales
          </Label>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Peso(kg)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="peso"
              placeholder="ej. 56 kg"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Estatura(mts)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="estatura"
              placeholder="ej. 1.80 mts"
              innerRef={register()}
            />
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Presión(mmHg)
          </Label>
          <Col sm={2}>
            <Input
              type="text"
              name="presion"
              placeholder="ej. 90/110"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Frecuencia cardiaca(lat/min)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="frecuenciaCardiaca"
              placeholder="ej. 90"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Frecuencia respiratoria(resp/min)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="frecuenciaRespiratoria"
              placeholder="ej. 18"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Temperatura(°C)
          </Label>
          <Col sm={2}>
            <Input
              type="number"
              name="temperatura"
              placeholder="Ej. 36.6 °C"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Padecimiento actual
          </Label>
          <Col sm={5}>
            <Input
              type="text"
              name="padeciminetoActual"
              placeholder="Ej. cancer, diabetes, etc..."
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Personales no patológicos
          </h5>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Fuma</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="tabaquismo"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="tabaquismo"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toxicomanias</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="toxicomanias"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="toxicomanias"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toma alcohol</Label>
              <FormGroup check>
                <Label sm={2} style={{ color: "#B1B1B1" }} check inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="alcoholismo"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check >
                <Label sm={4} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="alcoholismo"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={12} className="labelGray">
            Alimentacion
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1", marginLeft: '17px' }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.carne"
              value={true}
              innerRef={register()}
            />
            Carne
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.pescado"
              value={true}
              innerRef={register()}
            />
            Pescado
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.frutas"
              value={true}
              innerRef={register()}
            />
            Frutas
          </Label>
          <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
            <CustomInput
              type="checkbox"
              id="alimentacion"
              name="alimentacion.verduras"
              value={true}
              innerRef={register()}
            />
            Verduras
          </Label>
        </FormGroup>
        <FormGroup row style={{ marginTop: '1.2rem' }}>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Toma medicamento</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="medicamentos"
                    value={true}
                    innerRef={register()}
                    onClick={clickMedicamentos}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="medicamentos"
                    value={false}
                    innerRef={register()}
                    onClick={clickMedicamentos}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {especifica ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    name="especificaMedicamentos"
                    placeholder="Ej. paracetamol, etc"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Alergias</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="alergias"
                    value={true}
                    innerRef={register()}
                    onClick={clickAlergias}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="alergias"
                    value={false}
                    innerRef={register()}
                    onClick={clickAlergias}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {alergias ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    name="especificaAlergias"
                    placeholder="Ej. sol, polve etc"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Donador de Órganos</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="donadorOrganos"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="donadorOrganos"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Donador de Sangre</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="donadorSangre"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="donadorSangre"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Obesidad</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="obesidad"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="obesidad"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            {informacionPersonal["sexo"] === "F" ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">Embarazada</Label>
                <FormGroup check style={{ color: "#B1B1B1" }}>
                  <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                    <CustomInput
                      type="radio"
                      value={true}
                      name="embarazada"
                      innerRef={register()}
                    />
                    Si
                  </Label>
                </FormGroup>
                <FormGroup check style={{ color: "#B1B1B1" }}>
                  <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                    <CustomInput
                      type="radio"
                      value={false}
                      name="embarazada"
                      innerRef={register()}
                    />
                    No
                  </Label>
                </FormGroup>
              </FormGroup>
            ) : null}
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Deportes (actividad fisica)
          </Label>
          <Col sm={5}>
            <CustomSelect
              className="mb-3"
              name="actividadFisica"
              control={control}
              innerRef={register({ required: true })}
              options={actividadFisica}
              type="select"
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Diabetes</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="diabetes"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="diabetes"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">VIH</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="vih"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="vih"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Cancer</Label>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="cancer"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={4} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="cancer"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Discapacidad</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="discapacidad"
                    value={true}
                    innerRef={register()}
                    onClick={() => setDiscapacidad(true)}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="discapacidad"
                    value={false}
                    innerRef={register()}
                    onClick={() => setDiscapacidad(false)}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
            {discapacidad ? (
              <FormGroup className="dm-c">
                <Label className="labelGray">
                  Especifica
                </Label>
                <Col style={{ paddingLeft: 0 }}>
                  <Input
                    type="text"
                    name="especificaDiscapacidad"
                    placeholder="Ej. camaron, latex"
                    innerRef={register()}
                  />
                </Col>
              </FormGroup>
            ) : null}
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Enfermedad Pulmonar</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="enfermedadPulmunar"
                    value={true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="enfermedadPulmunar"
                    value={false}
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Enfermedad Hepatica</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="enfermedadHepatica"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="enfermedadHepatica"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Transplante</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="transplante"
                    value={true}
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    name="transplante"
                    value={false}
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup className="dm-c">
              <Label className="labelGray">Hipertensión</Label>
              <FormGroup check style={{ color: "#B1B1B1" }}>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={true}
                    name="hipertension"
                    innerRef={register()}
                  />
                  Si
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label sm={2} check style={{ color: "#B1B1B1" }} inline="true" className="radio-organize">
                  <CustomInput
                    type="radio"
                    value={false}
                    name="hipertension"
                    innerRef={register()}
                  />
                  No
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Grupo Sanguíneo
          </Label>
          <Col sm={5}>
            <CustomSelect
              className="mb-3"
              name="grupoSanguineo"
              control={control}
              innerRef={register({ required: true })}
              options={tiposSangre}
              type="select"
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="labelGray">
            Médico Familiar
          </Label>
          <Col sm={5}>
            <Input
              type="text"
              name="nombreMedicoFamiliar"
              placeholder="Nombre de médico familiar"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row className="dm-c">
          <Label sm={4} className="LabelGray">
            Teléfono del médico
          </Label>
          <Col sm={5}>
            <Input
              type="number"
              name="telefonoMedicoFamiliar"
              placeholder="Telefono de medico familiar"
              innerRef={register()}
            />
          </Col>
        </FormGroup>

        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Historial Exámenes Médicos
          </h5>
        </FormGroup>

        <FormGroup row style={{ marginLeft: '-3px' }}>
          <TableCommonSaludLaboral
            striped={true}
            keysAceepted={["Nombre Examen", "Fecha", "", ""]}
          >
            <TableBody
              handleDelete={handleDelete}
              examenMedicos={tablaExamenMedico}
            />
          </TableCommonSaludLaboral>
        </FormGroup>

        <ExamLabel sm={3} onClick={e => toggleAdd()} style={{ cursor: 'pointer' }}>+ Agregar Examen</ExamLabel>
        <div style={{ display: showAdd ? 'none' : 'initial' }} >
          <FormGroup row>
            <Label
              for="fecha"
              sm={3}
              className="LabelGray"
              style={{ marginRight: "130px" }}
            >
              Fecha
          </Label>
            <Col sm={2}>
              <Input
                type="date"
                name="examenMedico.fechaAplicacion"
                innerRef={register()}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label
              for="nombreExamen"
              sm={3}
              className="LabelGray"
              style={{ marginRight: "130px" }}
            >
              Nombre
          </Label>
            <Col sm={5}>
              <Input
                type="text"
                name="examenMedico.nombreExamen"
                placeholder="Nombre del exámen médico"
                innerRef={register()}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} className="LabelGray" style={{ marginRight: "130px" }}>
              Subir Archivo
          </Label>
            <Col sm={4}>
              <InputFile
                type="file"
                id="examenMedicoArchivo"
                name="examenMedico.archivoExamenMedico"
                register={register}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={3} className="text-center">
              <SaveButton type="button" onClick={addToTable}>
                + Agregar
            </SaveButton>
            </Col>
          </FormGroup>
        </div>

        <FormGroup row style={{ marginTop: "1rem" }}>
          <h5 sm={12} style={{ fontWeight: "bold", fontSize: "20px" }}>
            Programar Nuevo Exámen
          </h5>
        </FormGroup>
        <FormGroup row style={{ marginTop: "40px", marginLeft: '-3px' }}>
          <TableCommonSaludLaboral
            striped={true}
            keysAceepted={["Tipo de estudios", "Fecha", "Hora", 'Otros estudios', '', '']}
          >
            <TableExamenesMedicos
              proximosExamenesMedicos={tablaProximoExamenMedico}
              setTable={setTablaProximoExamenMedico}
            />
          </TableCommonSaludLaboral>
        </FormGroup>
        <ExamLabel sm={3} onClick={e => toggleProgram()} style={{ cursor: 'pointer' }}>+ Programar Examen</ExamLabel>
        <div style={{ display: showProgram ? 'none' : 'initial' }} >
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Exámenes Programados
            </Label>
            <Col sm={3}>
              <Input
                type="date"
                name="proximoExamenMedico.fechaExamenMedico"
                placeholder="Seleccione la fecha del examen"
                innerRef={register()}
              />
            </Col>
            <Col sm={3}>
              <Input
                type="time"
                name="proximoExamenMedico.hora"
                placeholder="Seleccione la fecha del examen"
                innerRef={register()}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Tipo de Estudios
            </Label>
            <Col sm={3}>
              <GroupSelect
                options={tiposExamenes}
                control={control}
                type="select"
                name="proximoExamenMedico.tipoExamen"
                innerRef={register()}
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Estudios
            </Label>
            <Col sm={3}>
              <Input
                name="otrosEstudios"
                innerRef={register}
                type="textarea"
                placeholder="Introduzca el nombre del otro estudio"
              />
            </Col>
          </FormGroup>
          <FormGroup row style={{ marginTop: "40px" }}>
            <Label for="fecha" sm={3} className="LabelGray">
              Observaciones
            </Label>
            <Col sm={6}>
              <Input
                name="observaciones"
                innerRef={register}
                type="textarea"
                placeholder="Introduzca observación"
              />
            </Col>
          </FormGroup>
          <Notifiacion register={register} />
          <FormGroup row>
            <Col sm={3} className="text-center">
              <SaveButton type="button" onClick={addToTableProximoExamen}>
                + Agregar
              </SaveButton>
            </Col>
          </FormGroup>
        </div>
        <FormGroup row className="text-center">
          <Col sm={{ offset: 9, size: 3 }}>
            <SaveButton type="submit">Guardar</SaveButton>
          </Col>
        </FormGroup>
      </Form>
    </>
  );
});
export default FormSaludLaboral;
