import React, { useState, useContext, useEffect, useCallback, useMemo, useRef } from "react";
import { Button, Row, Col, FormGroup, Input, Label, Form, Table } from "reactstrap";
import { StyleInputs } from "../ui/styles/StyleNomina";
import axios from "axios";
import moment from "moment";
import InputFile from "../ui/InputFile";
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faDownload } from "@fortawesome/free-solid-svg-icons";
import Incidencias from "./Incidencias";
import sweet from "sweetalert2";

const api = process.env.REACT_APP_API_URL;

export default ({ onUpdate, colaboradorId, arregloIncapacidades }) => {
  const initialIncapa = {
    tipo: "",
    folio: "",
    observacion: "",
    fechaInicio: moment().utc().format("YYYY-MM-DD"),
    fechaFin: moment().utc().format("YYYY-MM-DD"),
  };
  const location = useLocation();
  const [catalogoIncapacidades, setCatalogo] = useState([]);
  const [incapacidades, setIncapacidades] = useState([]);
  const [incapacidad, setIncapacidad] = useState(initialIncapa);
  const inputFile = useRef();
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    getCatalogoIncapacidades();
    setIncapacidades(JSON.parse(JSON.stringify(arregloIncapacidades)));
  }, []);

  const getCatalogoIncapacidades = async () => {
    let { data } = await axios.get(`${api}/catalogos/?catalogo=incapacidades&sat=true`, headers);
    setCatalogo(data.response);
  };

  const getIncapacidades = async () => {
    let { data } = await axios.get(`${api}/incapacidades/lista/${colaboradorId}`, headers);
    setIncapacidades(data.resultado);
  };

  const agregarIncapacidad = async () => {
    incapacidad.colaborador = colaboradorId;
    const inacapacidadForm = new FormData();
    let action = incapacidad._id ? "put" : "post";
    let url = incapacidad._id ? `editar_incapacidad/${incapacidad._id}` : "crear";
    if (incapacidad.file) {
      inacapacidadForm.append("files", incapacidad.file[0]);
      delete incapacidad.file;
    }
    inacapacidadForm.append("incapacidad", JSON.stringify(incapacidad));
    let { data } = await axios[action](`${api}/incapacidades/${url}`, inacapacidadForm, headers);
    inputFile.current.value = null;
    
    setIncapacidad(initialIncapa);
    let i = incapacidades.findIndex((h) => h._id === data.resultado._id);

    let datoGuardado = data.resultado;
    i >= 0 ? (incapacidades[i] = datoGuardado) : incapacidades.push(datoGuardado);
    setIncapacidades([...incapacidades]);
    onUpdate();
  };

  const setFile = (e) => {
    if (e.target.files.length) setIncapacidad({ ...incapacidad, file: e.target.files });
  };

  const borrarIncapacidad = async (incap) => {
    const title = "Borrar Incapacidad";
    let result = await sweet.fire({
      title,
      text: "Seguro que desea borrar?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    });
    if (result.isConfirmed) {
      let { data } = await axios.delete(`${api}/incapacidades/eliminar/${incap._id}`, headers);
      await sweet.fire({
        title,
        text: "Se elimino exitosamente",
        icon: "success",
      });
      setIncapacidades([...incapacidades.filter((f) => f._id !== incap._id)]);
      onUpdate();
    }
  };

  const onValidate = () => {
    return incapacidad.tipo === "" || incapacidad.folio === "" || incapacidad.observacion === "" || incapacidad.tipo === "";
  };

  return (
    <StyleInputs>
      <Row>
        <Col>
          <Form onSubmit={(e) => e.preventDefault()} inline className="py-4">
            <Row style={{ width: "100%" }}>
              <FormGroup className="mb-0 col-md-4">
                <Input className="col-md-12" type="select" name="select" value={incapacidad.tipo} onChange={(e) => setIncapacidad({ ...incapacidad, tipo: e.target.value })}>
                  <option value="">Seleccione..</option>
                  {catalogoIncapacidades.map((c) => (
                    <option value={c.descripcion} key={c.descripcion}>
                      {c.descripcion}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="mb-0 col-md-2">
                <Button color="secondary" block onClick={() => document.getElementById("fileIncapacidades").click()}>
                  Subir Archivo
                </Button>
              </FormGroup>
            </Row>
            <Row style={{ width: "100%" }} className="mt-4">
              <FormGroup className="mb-0 col-md-1">
                <Input
                  type="text"
                  value={incapacidad.folio}
                  className="col-md-12"
                  name="folio"
                  placeholder="Folio "
                  onChange={(e) => setIncapacidad({ ...incapacidad, folio: e.target.value })}
                />
              </FormGroup>
              <FormGroup className="mb-0 col-md-3">
                <Input
                  type="text"
                  value={incapacidad.observacion}
                  name="observeacion"
                  placeholder="Observacion "
                  className="col-md-12"
                  onChange={(e) => setIncapacidad({ ...incapacidad, observacion: e.target.value })}
                />
              </FormGroup>
              <FormGroup className="mb-0 col-md-3">
                <Label className="mb-2 mr-sm-2 mb-sm-0">Inicio</Label>
                <Input
                  className="col-md-9"
                  value={incapacidad.fechaInicio}
                  type="date"
                  name="fecha"
                  placeholder="Fecha "
                  onChange={(e) => setIncapacidad({ ...incapacidad, fechaInicio: e.target.value })}
                />
              </FormGroup>
              <FormGroup className="mb-0 col-md-3">
                <Label className="mb-2 mr-sm-2 mb-sm-0">Fin</Label>
                <Input
                  className="col-md-9"
                  value={incapacidad.fechaFin}
                  type="date"
                  name="fecha"
                  placeholder="Fecha "
                  onChange={(e) => setIncapacidad({ ...incapacidad, fechaFin: e.target.value })}
                />
              </FormGroup>

              <Input ref={inputFile} onChange={setFile} hidden={true} id="fileIncapacidades" type="file"></Input>
              <FormGroup className="mb-0 px-0 col-md-1">
                <Button disabled={onValidate()} isab style={{ height: "50px" }} color="primary" onClick={agregarIncapacidad}>
                  {incapacidad._id ? "Guardar" : "Agregar"}
                </Button>
              </FormGroup>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table color={"white"} style={{ background: "white", marginTop: "10px" }}>
            <thead>
              <tr>
                <th>Folio</th>
                <th>Tipo</th>
                <th>Observación</th>
                <th>Fechas</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {incapacidades.map((d, i) => (
                <tr key={i}>
                  <td>{d.folio}</td>
                  <td>{d.tipo}</td>
                  <td> {d.observacion}</td>
                  <td>
                    {moment(d.fechaInicio).utc().format("YYYY-MM-DD")} al {moment(d.fechaFin).utc().format("YYYY-MM-DD")}
                  </td>
                  <td>
                    {d.archivo && (
                      <a href={`${process.env.REACT_APP_API_URL}/archivo/descarga/${d.archivo}`} _blank>
                        <FontAwesomeIcon icon={faDownload} cursor="pointer" className="mx-1" />
                      </a>
                    )}
                  </td>
                  <td>
                    <FontAwesomeIcon
                      onClick={() =>
                        setIncapacidad({
                          ...d,
                          fechaInicio: moment(d.fechaInicio).utc().format("YYYY-MM-DD"),
                          fechaFin: moment(d.fechaFin).utc().format("YYYY-MM-DD"),
                        })
                      }
                      icon={faPen}
                      cursor="pointer"
                      className="mx-1"
                    />
                    <FontAwesomeIcon onClick={() => borrarIncapacidad(d)} icon={faTrash} cursor="pointer" className="mx-1" />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </StyleInputs>
  );
};
