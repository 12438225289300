import React, {useCallback,useState} from 'react';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {
	
	faTrashAlt,
  } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { agregarVaca } from '../../../store/vacacionesDucks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ButtonDowload from './ButtonDownload';
import AudienciaModal from './AudienciaModal';
import {agregarAudiencia} from '../../../store/audienciasDuck';

const TableBody = React.memo(({listaAudiencias = [],deleteAudiencia,isEdit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState({});
  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const getDocumento = async (data) => {
    if (!isEdit) {
      await dispatch(agregarAudiencia(data))
      setDataModal(data);
      toggleModal();
    }
  };
  return(
      <>
        <AudienciaModal isOpen={isOpen} data={dataModal} setIsOpen={setIsOpen}/>
          {listaAudiencias.map((audiencia, index) => (
            
        <Tr
          key={audiencia._id}
        >
          <td onClick={() => getDocumento(audiencia)}>{moment(audiencia.fecha).format('DD/MM/YYYY')}</td>
          <td onClick={() => getDocumento(audiencia)}>{audiencia.hora}</td>
          <td onClick={() => getDocumento(audiencia)}>{audiencia.abogado}</td>
          <td onClick={() => getDocumento(audiencia)}>{audiencia.tipo}</td>
          <td>{audiencia.documento ?<ButtonDowload
            Url={`${process.env.REACT_APP_API_URL}/archivo/descarga/${audiencia.documento}`}
          />:'No aplica'}</td>
          <td>
          <a href='#' 
            onClick={(e) => deleteAudiencia(e,audiencia)}
            style={{textDecoration:'none',color:'#3c3c3c',zIndex:100}}
            >
            <FontAwesomeIcon 
              color='#151e5d'
              icon={faTrashAlt} 
            />
          </a>
        </td>
        </Tr>
      ))}
      </>
  );
});

export default TableBody;