import { ELIMINAR_DEPARTAMENTO_ERROR } from './departamentosDucks';
import {
  GET_EMPRESA_LIST_SUCESS,
  getEmpresasError,
  getEmpresasListSucess,
  getStart,
} from './empresasDuck';

const initState = {
  loading: false,
  roles: [],
  rol: {
    permisos: [],
  },
  editar: false,
  error: null,
  msg: null,
};
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_EXITO = 'GET_ROLES_EXITO';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';
export const GET_ROLES_LIST_SUCESS = ' GET_ROLES_LIST_SUCESS';
export const DETALLE_ROL = 'DETALLE_ROL';
export const DETALLE_ROL_EXITO = 'DETALLE_ROL_EXITO';
export const DETALLE_ROL_ERROR = 'DETALLE_ROL_ERROR';

export const ELIMINAR_ROL = 'ELIMINAR_ROL';
export const ELIMINAR_ROL_EXITO = 'ELIMINAR_ROL_EXITO';
export const ELIMINAR_ROL_ERROR = 'ELIMINAR_ROL_ERROR';
export const ELIMINAR_DEPARTAMENTO_CLEAN = 'ELIMINAR_DEPARTAMENTO_CLEAN';

export const AGREGAR_ROL = 'AGREGAR_ROL';
export const AGREGAR_ROL_EXITO = 'AGREGAR_ROL_EXITO';
export const AGREGAR_ROL_ERROR = 'AGREGAR_ROL_ERROR';

export const EDITAR_ROL = 'EDITAR_ROL';
export const EDITAR_ROL_EXITO = 'EDITAR_ROL_EXITO';
export const EDITAR_ROL_ERROR = 'EDITAR_ROL_ERROR';

export const ASIGNAR_ROL = 'ASIGNAR_ROL';
export const ASIGNAR_ROL_EXITO = 'ASIGNAR_ROL_EXITO';
export const ASIGNAR_ROL_ERROR = 'ASIGNAR_ROL_ERROR';

export const EDITAR_BTN = 'EDITAR_BTN';

export default function rolesReducer(state = initState, { type, payload }) {
  switch (type) {
    case GET_ROLES:
      return { ...state, loading: true };
    case GET_ROLES_EXITO:
      return { ...state, loading: false, roles: payload.resultado };
    case GET_ROLES_ERROR:
      return { ...state, loading: false };
    case GET_ROLES_LIST_SUCESS:
      return { ...state, roles: payload.list };
    case DETALLE_ROL:
      return { ...state, loading: true };
    case DETALLE_ROL_EXITO:
      return { ...state, loading: false, rol: payload.resultado };
    case DETALLE_ROL_ERROR:
      return { ...state, loading: false };
    case ELIMINAR_ROL:
      return { ...state, loading: true };
    case ELIMINAR_ROL_EXITO:
      return { ...state, loading: false, msg: payload.msg };
    case ELIMINAR_ROL_ERROR:
      return { ...state, loading: false, error: payload.data };
    case ELIMINAR_DEPARTAMENTO_CLEAN:
      return { ...state, error: null, msg: null };
    case AGREGAR_ROL:
      return { ...state, loading: true };
    case AGREGAR_ROL_EXITO:
      return { ...state, loading: false };
    case AGREGAR_ROL_ERROR:
      return { ...state, loading: false };
    case EDITAR_BTN:
      return { ...state, editar: !state.editar };
    case EDITAR_ROL:
      return { ...state, loading: true };
    case EDITAR_ROL_EXITO:
      return { ...state, loading: false };
    case EDITAR_ROL_ERROR:
      return { ...state, loading: false };
    case ASIGNAR_ROL:
      return { ...state, loading: true };
    case ASIGNAR_ROL_EXITO:
      return { ...state, loading: false, roles: payload.resultado };
    case ASIGNAR_ROL_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}
const getRoles = () => ({
  type: GET_ROLES,
});
const getRolesExito = (payload) => ({
  type: GET_ROLES_EXITO,
  payload,
});
const getRolesError = () => ({
  type: GET_ROLES_ERROR,
});
const detalleRol = () => ({
  type: DETALLE_ROL,
});
const detalleRolExito = (payload) => ({
  type: DETALLE_ROL_EXITO,
  payload,
});
const detalleRolError = () => ({
  type: DETALLE_ROL_ERROR,
});
const eliminarRol = () => ({
  type: ELIMINAR_ROL,
});
const eliminarRolExito = (payload) => ({
  type: ELIMINAR_ROL_EXITO,
  payload,
});
const eliminarRolError = (payload) => ({
  type: ELIMINAR_DEPARTAMENTO_ERROR,
  payload,
});
export const elimiinarRolClean = () => ({
  type: ELIMINAR_DEPARTAMENTO_CLEAN,
});
const agregarRol = () => ({
  type: AGREGAR_ROL,
});
const agregarRolExito = () => ({
  type: AGREGAR_ROL_EXITO,
});
const agregarRolError = () => ({
  type: AGREGAR_ROL_ERROR,
});
export const editaBtn = () => ({
  type: EDITAR_BTN,
});
const editarRol = () => ({
  type: EDITAR_ROL,
});
const editarRolExito = () => ({
  type: EDITAR_ROL_EXITO,
});
const editarRolError = () => ({
  type: EDITAR_ROL_ERROR,
});
export const getRolesListSucess = (payload) => ({
  type: GET_ROLES_LIST_SUCESS,
  payload,
});

export const asignarRolStart = () => ({
  type: ASIGNAR_ROL,
});
export const asignarRolSucces = (payload) => ({
  type: ASIGNAR_ROL_EXITO,
  payload,
});
export const asignarRolError = (payload) => ({
  type: ASIGNAR_ROL_ERROR,
  payload,
});

export const getRolesAction = () => async (dispatch, getState, { axios }) => {
  const user_id = getState().user._id;

  dispatch(getRoles());
  try {
    const { resultado } = (
      await axios.get(`/roles?idCliente=${user_id}`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;
    dispatch(getRolesExito({ resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getRolesError(data));
    throw data;
  }
};
export const detalleRolAction = (idRol) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(detalleRol());
  try {
    const { resultado } = (
      await axios.get(`/roles/${idRol}`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;
    dispatch(detalleRolExito({ resultado }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(detalleRolError());
    throw data;
  }
};
export const eliminarRolAction = (idRol) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(eliminarRol);
  try {
    const { msg } = (
      await axios.delete(`/roles/${idRol}`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;
    dispatch(getRolesAction());
    dispatch(eliminarRolExito({ msg }));
  } catch (error) {
    const { data } = error.response;
    dispatch(eliminarRolError({ data }));
    throw data;
  }
};
export const agregarRolAction = ({ nombreRol, permisos }) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { _id } = getState().user;
  dispatch(agregarRol());
  try {
    const { msg } = (
      await axios.post(
        `/roles`,
        { nombreRol, permisos, cliente: _id },
        {
          headers: { token: localStorage.getItem('pv2token') },
        }
      )
    ).data;
    dispatch(agregarRolExito({ msg }));
  } catch (error) {
    const { data } = error.response;
    dispatch(agregarRolError({ data }));
    throw data;
  }
};
export const editarRolAction = ({
  idRol,
  nombreRol,
  permisos,
  cliente,
}) => async (dispatch, getState, { axios }) => {
  const { _id } = getState().user;
  dispatch(editarRol());
  try {
    const { msg } = (
      await axios.put(
        `/roles/${idRol}`,
        { nombreRol, permisos, cliente: _id },
        {
          headers: { token: localStorage.getItem('pv2token') },
        }
      )
    ).data;
    dispatch(editarRolExito({ msg }));
  } catch (error) {
    //Quitar el console log cuando haya try catch en el server
    const { data } = error.response;
    dispatch(editarRolError({ data }));
    throw data;
  }
};
export const fetchRolesToDropdown = () => async (
  dispatch,
  getState,
  { axios }
) => {
  const user_id = getState().user._id;

  dispatch(getRoles());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/roles?idCliente=${user_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    const list = resultado.map(({ _id, nombreRol }) => {
      return { value: _id, label: nombreRol };
    });
    dispatch(getRolesListSucess({ list }));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getRolesError(data));
    throw data;
  }
};

export const fetchAsignarRol = (asignarRolData) => async (
    dispatch,
    getState,
    { axios }
  ) => {
    const { _id } = getState().user;
    dispatch(asignarRolStart());
    try {
      const { data } = (
        await axios.post(
          `/usuario/asignacion`,
          asignarRolData,
          {
            headers: { token: localStorage.getItem('pv2token') },
          }
        )
      );
      dispatch(asignarRolSucces({ data }));
    } catch (error) {
      const { data } = error.response;
      dispatch(asignarRolError(data));
      throw data;
    }
  };