import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Nomina from '../../pages/Nomina/Nomina';
import Perfil from '../../pages/Perfil/Perfil';
import Empresa from '../../pages/Empresa/Empresa'
import Cuenta from "../../pages/Cuenta/Cuenta";
import Mantenimiento from "../../pages/Mantenimiento/Mantenimiento";

const AppRoutes = () => {
  return (
    <Switch>
      <Route  path='/dashboard/perfil' component={Perfil} />
      <Route  path='/dashboard/nomina' component={Nomina} />
      <Route  path='/dashboard/cuenta' component={Cuenta} />
      <Route  path='/dashboard/mantenimiento' component={Mantenimiento} />
      <Route  path='/dashboard/empresa' component={Empresa}/>
    </Switch>
  );
};

export default AppRoutes;
