import React from 'react';
import Utilitario from '../Utilitario';
import FormCondicionesLaborales from './FormCondicionesLaborales';
import FormDatosPersonales from './FormDatosPersonales';
import FormInformacionNomina from './FormInformacionNomina';
import FormSaludLaboral from './FormSaludLaboral';
import ExpedienteDigital from '../ExpedienteDigital';
import Uniformes from '../Uniformes';
import Vacaciones from '../Vacaciones';
import PermisosLaborales from '../PermisosLaborales';
import Incapacidades from '../Incapacidades';
import DescuentosSalario from '../DescuentosSalario';

const Forms = React.memo(({ form = 0 }) => {
  let component = null;
  switch (form) {
    case 1:
      component = <FormDatosPersonales />;
      break;
    case 2:
      component = <FormCondicionesLaborales />;
      break;
    case 3:
      component = <FormInformacionNomina />;
      break;
    case 4:
      component = <DescuentosSalario />;
      break;
    case 5:
      component = <Utilitario />;
      break;
    case 6:
      component = <Uniformes />;
      break;
    case 7:
      component = <ExpedienteDigital />;
      break;
    case 8:
      component = <Vacaciones />;
      break;
    case 9:
      component = <PermisosLaborales />;
      break;
    case 10:
      component = <Incapacidades />;
      break;
    case 11:
      component = <FormSaludLaboral />;
      break;
    default:
      component = null;
  }
  return <>{component}</>;
});

export default Forms;
