import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ActasView from './ActasViews/ActasView';
import FormActa from './ActasViews/FormActa';

const ActasAdministrativas = () => {
    return (
        <HashRouter basename='/'>
            <Switch>
                <Route path='/actas/agregar' component={FormActa} />
                <Route path='/' component={ActasView} /> 
            </Switch>
        </HashRouter>
    );
};

export default ActasAdministrativas;