import React, { useEffect, useCallback } from 'react';
import {Row, Table} from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux';
import {fetchUsuariosAction} from "../../store/usuariosDuck";
import UserItem from './UserItem';
import Spiner from '../ui/Spiner';
import SweetAlert from "../ui/SweetAlet";
import './usersList.css';

const UserList = ({modal, toggle, setUser}) => {
  const dispatch = useDispatch();
  const {loading, listaUsuarios} = useSelector(state =>state.usuarios)
  const fetchUsuarios = useCallback(async ()=>{
    try {
      dispatch(fetchUsuariosAction())
    }catch (e) {
      await SweetAlert({ icon: 'error', title: 'Error!', text: e });

    }
  }, [dispatch])
  useEffect(() => {
    fetchUsuarios();
  }, []);

  if(loading) return <Spiner/>
  if(listaUsuarios.length === 0) return (<h2>Sin usuarios registrados</h2>)

  return (
      <div className='users-list'>
        <Row>
          <Table striped={true}>
            <thead>
            <tr className='users-list-tr'>
              <th>Nombre de Usuario</th>
              <th>Rol Asignado</th>
            </tr>
            </thead>
            <tbody className='users-list-tr-table'>
              {listaUsuarios.map( (usuario, index) => 
                <UserItem modal={modal} toggle={toggle} key={`user-${index}`} usuario={usuario} setUser={setUser} />)}
            </tbody>
          </Table>
        </Row>
      </div>
  )
}

export default UserList;
