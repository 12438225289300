import React, { useState } from 'react';
import { Stage, Layer, Line, Rect, Text, Arc } from 'react-konva';
import { scaleBand, scaleLinear } from 'd3';
import _ from 'lodash';
import Tooltip from './Tooltip';

const categories = ['Solos', 'Pareja'];

const scaleFactory = (w) => scaleBand().domain(categories).range([0, w]).round(true);

const linearFactory = (n, w) => scaleLinear().domain([0, n]).range([0, w]);

const xCenters = (scale) => categories.map(c => scale(c) + scale.bandwidth() / 2);

const yCenters = h => [h * 0.05, h * 0.5, h * 0.8];

const porcentaje = (coll, total) => i => (100 * coll[categories[i]].length / total)

const TermometerPlot = ({ colors, width, height, colaboradores }) => {
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState("");
    const [mouseCoords, setMouseCoords] = useState({ x: 0, y: 0 });

    const total = colaboradores.Solos.length + colaboradores.Pareja.length;
    const xScale = scaleFactory(width);
    const yScale = linearFactory(total, height * 0.6);
    const centersX = xCenters(xScale);
    const centersY = yCenters(height);
    const fnPorc = porcentaje(colaboradores, total)

    const onHover = (text) => {
        setVisible(true);
        setText(text);
    };

    const onMouseMove = (e) => {
        let stage = e.currentTarget;
        stage = e.target.getStage();

        let mosuePos = stage.getPointerPosition();
        setMouseCoords({ ...mouseCoords, x: mosuePos.x, y: mosuePos.y });
    };

    const onMouseLeave = (e) => {
        setVisible(false);
        setText("");
    };

    return (
        <Stage width={width} height={height}>
            {
                categories.map((c, i) => (
                    <Layer key={i}>
                        <Rect
                            x={centersX[i] - 25}
                            y={(height * 0.77) - yScale(colaboradores[c].length)}
                            width={50}
                            height={yScale(colaboradores[c].length)}
                            fill={colors.primary[i + 6]}
                            cornerRadius={5}
                            onMouseEnter={() => onHover(colaboradores[c].length)}
                            onMouseMove={onMouseMove}
                            onMouseLeave={onMouseLeave}
                        />
                        <Text
                            x={i * xScale.step()}
                            y={centersY[0]}
                            text={fnPorc(i).toFixed() + '%'}
                            fontSize={xScale.bandwidth() * 0.15}
                            width={xScale.bandwidth()}
                            fill="#8E8883"
                            align='center'
                        />
                        <Text
                            x={i * xScale.step()}
                            y={centersY[2]}
                            text={categories[i]}
                            fontSize={xScale.bandwidth() * 0.25}
                            width={xScale.bandwidth()}
                            fill="#8E8883"
                            align='center'
                        />
                        <Tooltip x={mouseCoords.x} y={mouseCoords.y} visible={visible} text={text} />
                    </Layer>
                ))
            }
        </Stage>
    )
}

export default TermometerPlot;
