import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchListaIncapacidades } from '../../../store/incapacidadesDuck';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import colors from '../../../constans/colors';
import TableBody from './TableBody';
import TableCommon from '../VacacionesViews/TableVacaciones';
import { TableContainer, LabelForms } from '../VacacionesViews/styled';
import FirstAdd from '../../../components/ui/FirstAdd';

const IncapacidadesView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { listaIncapacidades=[] } = useSelector((state) => state.incapacidades);
    const { permisos = {} } = useSelector((state) => state.user);

    useEffect(() => {
      (async () => {
        try {
          await dispatch(fetchListaIncapacidades());
        } catch (error) {}
      })();
    }, [dispatch]);
    if(listaIncapacidades && listaIncapacidades.length > 0)
      return (
        <>
          <LabelForms style={{marginLeft:40, fontSize:'17px'}}>Incapacidades</LabelForms>
          <TableContainer style={{marginLeft:30, marginTop:10}}>
            <TableCommon
              keysAceepted={['Inicio', 'Fin', 'Días', 'Tipo','Observaciones']}
              extraTag={{ key: 'Valor Total', value: 1200 }}
              striped
            >
            <TableBody listaIncapacidades={listaIncapacidades}/>
            </TableCommon>
            <Button type="button" onClick={()=>{
              history.push('/incapacidades/agregar')
          }} 
          style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}
          hidden={permisos.colaboradorIncapacidades !== 'write'}>+ Agregar</Button>
          </TableContainer>
        </>
      );
    else return (<FirstAdd mensaje="Agrega aquí las incapacidades de tu colaborador" vista='incapacidades' disabled={permisos.colaboradorIncapacidades !== 'write'}/>)
};

export default IncapacidadesView;