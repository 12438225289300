import React, { useEffect } from 'react';
import './styles.css';
import {
  Container,
  Input,
  Button,
  ImageContainer,
  LogoContainer,
  Title,
} from '../../pages/RegistroUsuario/styledRegistro';
import { useSelector, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { schemaRegister } from '../../constans/yupSchemas';
import { Col, FormGroup, Form, Card } from 'reactstrap';
import { firstForm, authorizedToken } from '../../store/userDuck';
import { Link, useHistory } from 'react-router-dom';
import SweetAlert from '../../components/ui/SweetAlet';
import Spiner from '../../components/ui/Spiner';
import { LettersContainer } from '../styled';

const RegistroUsuarioA = ({ setComponentRegister }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaRegister),
  });
  console.log(errors);
  const { loading } = useSelector((state) => state.user);
  const onSubmit = (data) => {
    dispatch(firstForm(data));
    setComponentRegister(2);
  };
  if (loading) {
    return <Spiner className='mx-auto' />;
  }
  return (
    <Card className='mx-auto' style={{ marginTop: '3rem' }}>
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)} className='p-3'>
          <FormGroup row>
            <Col sm={12}>
              {
                process.env.REACT_APP_USE === 'sign4life' ? <LettersContainer /> : (
                  <LogoContainer />
                )
              }
            </Col>
            <Col sm={12}>
              <Title sm={12}> Registro </Title>
            </Col>

            <Col sm={12} className='pl-4 mt-3'>
              <Input
                type='text'
                name='nombre'
                placeholder='Nombre'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-3'>
              <Input
                type='text'
                name='apellidos'
                placeholder='Apellido'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-3'>
              <Input
                type='text'
                name='razon_social'
                placeholder='Empresa (Razón social)'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-3'>
              <Input
                type='text'
                name='nombre_comercial'
                placeholder='Empresa (Nombre comercial)'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-3'>
              <Input
                type='text'
                name='telefono'
                placeholder='Teléfono'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12} className='pl-4 mt-3 mb-2'>
              <Input
                type='time'
                name='horario_contacto'
                placeholder='Horario de contacto'
                ref={register()}
                size='sm'
                className='border'
              />
            </Col>
            <Col sm={12}>
              <Button type='submit'>SIGUIENTE</Button>
            </Col>
          </FormGroup>
        </Form>
        <ImageContainer />
      </Container>
    </Card>
  );
};
export default RegistroUsuarioA;
