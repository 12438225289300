import {
  faIdCard,
  faMoneyBillWave,
  faCog,
  faBuilding,
  faTrafficCone,
} from '@fortawesome/pro-regular-svg-icons';

const routes = [
  {
    path: '/perfil',
    icon: faIdCard,
    name: 'perfil'
  },
  {
    path: '/nomina/lista-colaboradores',
    name: 'nomina',
    icon: faMoneyBillWave,
  },
  {
    path: '/empresa/usuarios',
    name: 'empresa',
    icon: faBuilding,
  },
  {
    path: '/cuenta/empresa',
    name: 'cuenta',
    icon: faCog,
  },
  // {
  //   path: '/mantenimiento',
  //   name: 'mantenimiento',
  //   icon: faTrafficCone,
  // }
];
export default routes;
