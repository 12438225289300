import React from 'react';
import {Route} from 'react-router-dom';
import MiPerfil from "../../../components/Profile/MiPerfil";
const PerfilRoutes =({path}) =>{
    console.log(path);
    return(
        <>
            <Route path={`${path}/mi-perfil`} component={MiPerfil}/>
        </>

    )
}
export default PerfilRoutes;