import styled from 'styled-components';

export const ContainerReportes = styled.section`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  .radio-organize > div {
    margin-left: 0.5rem !important; 
  }
`;
