import styled from 'styled-components';
import loginImage from '../../assets/img/loginImage.png';
import logoImage from '../../assets/img/nomizoricon.png';
import './styles.css';

export const CardContainer = styled.div`
   position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50rem;
`;
export const ImageContainer = styled.div`
  background-image: url(${loginImage});
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
export const LogoContainer = styled.div`
  width: 100%;
  min-height: 5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${logoImage});
`;

export const ReSendTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: x-small;
  color: #7f7f7f;
  line-height: 43px;
`;

export const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 650;
  font-style: normal;
  font-size: 2rem;
  color: #4f4f51;
  line-height: 59.77px;
  text-align: justify;
  margin-top: -1.5rem;
  margin-left: 1rem;
`;

export const Button = styled.button`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.258333px;
  color: #ffffff;
  width: 95%;
  height: 37px;
  left: 100%;
  top: 100%;
  background: linear-gradient(90deg, #1011bb 25.55%, #080882 64.65%);
  border-radius: 2px;
  text-align: center;
  border: none;
  margin-left: 1rem;
`;

export const TermsLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #3c3c3c;
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 1.5;
  color: #2B388F;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #2B388F;
    background-color: #fff;
    border-color: #2B388F;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(43, 56, 143, 0.8);
  }
`;

