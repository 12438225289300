export const FETCH_LISTA_DEPARTAMETNOS = 'FETCH_LISTA_DEPARTAMETNOS';
export const FETCH_LISTA_DEPARTAMENTOS_EXISTO =
  'FETCH_LISTA_DEPARTAMENTOS_EXISTO';
export const FETCH_LISTA_DEPARTAMENTOS_ERROR =
  'FETCH_LISTA_DEPARTAMENTOS_ERROR';
export const LISTA_DEPARTAMENTOS = 'LISTA DEPARTAMENTOS';
export const LISTA_DEPARTAMENTOS_EXITO = 'LISTA DEPARTAMENTOS_EXITO';
export const LISTA_DEPARTAMENTOS_ERROR = 'LISTA DEPARTAMENTOS_ERROR';

export const DETALLE_DEPARTAMENTO = 'DETALLE_DEPARTAMENTO';
export const DETALLE_DEPARTAMENTO_EXITO = 'DETALLE_DEPARTAMENTO_EXITO';
export const DETALLE_DEPARTAMENTO_ERROR = 'DETALLE_DEPARTAMENTO_ERROR';

export const ELIMINAR_DEPARTAMENTO = 'ELIMINAR_DEPARTAMENTO';
export const ELIMINAR_DEPARTAMENTO_EXITO = 'ELIMINAR_DEPARTAMENTO_EXITO';
export const ELIMINAR_DEPARTAMENTO_ERROR = 'ELIMINAR_DEPARTAMENTO_ERROR';

export const EDITAR_BOTON = 'EDITAR_BOTON';

export const EDITAR_DEPARTAMENTO = 'EDITAR_DEPARTAMENTO';
export const EDITAR_DEPARTAMENTO_EXITO = 'EDITAR_DEPARTAMENTO_EXITO';
export const EDITAR_DEPARTAMENTO_ERROR = 'EDITAR_DEPARTAMENTO_ERROR';

export const CREAR_DEPARTAMENTO = 'CREAR_DEPARTAMENTO';
export const CREAR_DEPARTAMENTO_EXITO = 'CREAR_DEPARTAMENTO_EXITO';
export const CREAR_DEPARTAMENTO_ERROR = 'CREAR_DEPARTAMENTO_ERROR';

export const CLEAN_STATE_DEPARTAMENTOS = 'CLEAN_STATE_DEPARTAMENTOS';

const initialState = {
  loading: false,
  listaDepartamentos: [],
  departamentos: [],
  editar: false,
  detalleDepartamento: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_LISTA_DEPARTAMETNOS:
      return { ...state, loading: true };
    case FETCH_LISTA_DEPARTAMENTOS_EXISTO:
      return { ...state, listaDepartamentos: [...payload], loading: false };
    case FETCH_LISTA_DEPARTAMENTOS_ERROR:
      return { ...state, listaDepartamentos: [...payload] };
    case LISTA_DEPARTAMENTOS:
      return { ...state, loading: true };
    case LISTA_DEPARTAMENTOS_EXITO:
      return { ...state, loading: false, departamentos: payload.resultado };
    case LISTA_DEPARTAMENTOS_ERROR:
      return { ...state, loading: false };
    case DETALLE_DEPARTAMENTO:
      return { ...state, loading: true };
    case DETALLE_DEPARTAMENTO_EXITO:
      return {
        ...state,
        loading: false,
        detalleDepartamento: payload,
      };
    case DETALLE_DEPARTAMENTO_ERROR:
      return { ...state, loading: false };
    case EDITAR_BOTON:
      return { ...state, editar: !state.editar };
    case EDITAR_DEPARTAMENTO:
      return { ...state, loading: true };
    case EDITAR_DEPARTAMENTO_EXITO:
      return { ...state, loading: false };
    case EDITAR_DEPARTAMENTO_ERROR:
      return { ...state, loading: false };
    case ELIMINAR_DEPARTAMENTO:
      return { ...state, loading: true };
    case ELIMINAR_DEPARTAMENTO_EXITO:
      return { ...state, loading: false };
    case ELIMINAR_DEPARTAMENTO_ERROR:
      return { ...state, loading: false };
    case CREAR_DEPARTAMENTO:
      return { ...state, loadin: true };
    case CREAR_DEPARTAMENTO_EXITO:
      return { ...state, loading: false };
    case CREAR_DEPARTAMENTO_ERROR:
      return { ...state, loading: false };

    case CLEAN_STATE_DEPARTAMENTOS:
      return initialState;
    default:
      return state;
  }
};

export const fetchListStart = () => ({
  type: FETCH_LISTA_DEPARTAMETNOS,
});

export const fetchListSucces = (payload) => ({
  type: FETCH_LISTA_DEPARTAMENTOS_EXISTO,
  payload,
});

export const fetchListError = (payload) => ({
  type: FETCH_LISTA_DEPARTAMENTOS_ERROR,
  payload,
});
export const fetchDepartamentos = () => ({
  type: LISTA_DEPARTAMENTOS,
});
export const fetchDepartamentosExito = (payload) => ({
  type: LISTA_DEPARTAMENTOS_EXITO,
  payload,
});
export const fetchDepartamentosError = (payload) => ({
  type: LISTA_DEPARTAMENTOS_ERROR,
  payload,
});
export const detalleDepartamento = () => ({
  type: DETALLE_DEPARTAMENTO,
});
export const detalleDepartamentoExito = (payload) => ({
  type: DETALLE_DEPARTAMENTO_EXITO,
  payload,
});
export const detalleDepartamentoError = () => ({
  type: DETALLE_DEPARTAMENTO_ERROR,
});
export const eliminarDepartamento = () => ({
  type: ELIMINAR_DEPARTAMENTO,
});
export const eliminarDepartamentoExito = () => ({
  type: ELIMINAR_DEPARTAMENTO_EXITO,
});
export const eliminarDepartamentoError = () => ({
  type: ELIMINAR_DEPARTAMENTO_ERROR,
});
export const editarBoton = () => ({
  type: EDITAR_BOTON,
});
export const editarDepartamento = () => ({
  type: EDITAR_DEPARTAMENTO,
});
export const editarDepartamentoExito = () => ({
  type: EDITAR_DEPARTAMENTO_EXITO,
});
export const editarDepartamentoError = () => ({
  type: EDITAR_DEPARTAMENTO_ERROR,
});

export const crearDepartamento = () => ({
  type: CREAR_DEPARTAMENTO,
});
export const crearDepartamentoExito = () => ({
  type: CREAR_DEPARTAMENTO_EXITO,
});
export const crearDepartamentoError = () => ({
  type: CREAR_DEPARTAMENTO_ERROR,
});

export const cleanStateDepartamentos = () => ({
  type: CLEAN_STATE_DEPARTAMENTOS,
});

export const fetchListaDepartamentos = (
  idSucursal,
  isDropdown = true
) => async (dispatch, getState, { axios }) => {
  dispatch(fetchListStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/departamentos/por_sucursal?sucursal=${idSucursal}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });

    let listDepartamentos = [];
    if (isDropdown)
      listDepartamentos =
        resultado.length > 0
          ? resultado.map(({ _id, nombreDepartamento }) => {
              return { value: _id, label: nombreDepartamento };
            })
          : [];
    if (!isDropdown) listDepartamentos = resultado;
    dispatch(fetchListSucces(listDepartamentos));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(fetchListError(e));
    throw data;
  }
};

export const fetchNombreDepartamento = (idSucursal) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(fetchListStart());
  try {
    const {
      data: { resultado },
    } = await axios.get(`/departamentos/por_sucursal?sucursal=${idSucursal}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });

    const listDepartamentos =
      resultado.length > 0
        ? resultado.map(({ _id, nombreDepartamento }) => {
            return { value: _id, label: nombreDepartamento };
          })
        : [];
    dispatch(fetchListSucces(listDepartamentos));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(fetchListError(e));
    throw data;
  }
};

export const getDepartamentos = () => async (dispatch, getState, { axios }) => {
  dispatch(fetchDepartamentos());
  try {
    const { resultado } = (
      await axios.get(`/departamentos`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;

    dispatch(fetchDepartamentosExito({ resultado }));
  } catch (error) {
    dispatch(fetchDepartamentosError(error));
    dispatch(fetchDepartamentosError(error));
    const { data } = error.response;
    throw data;
  }
};
export const detalleDepartamentoAction = (idDepartamento) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(detalleDepartamento());
  try {
    const { resultado } = (
      await axios.get(`/departamentos/consultar/${idDepartamento}`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;
    dispatch(detalleDepartamentoExito({ resultado }));
  } catch (e) {
    dispatch(detalleDepartamentoError());
  }
};
export const eliminarDepartamentoAccion = (_id, password) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(eliminarDepartamento());
  try {
    const response = await axios.delete(`/departamentos/eliminar/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(eliminarDepartamentoExito());
  } catch (e) {
    dispatch(eliminarDepartamentoError());
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    throw data;
  }
};
export const editarDepartamentoAction = ({
  nombreDepartamento,
  idLider,
  empresa,
  sucursal,
  _id
}) => async (dispatch, getState, { axios }) => {
  // const { _id } = getState().departamentos.detalleDepartamento;
  dispatch(editarDepartamento());
  try {
    const { resultado } = (
      await axios.put(
        `/departamentos/editar/${_id}`,
        // { nombreDepartamento, empresa, sucursal, idLider },
        { nombreDepartamento, empresa, sucursal },
        { headers: { token: localStorage.getItem('pv2token') } }
      )
    ).data;
    dispatch(editarDepartamentoExito({ resultado }));
  } catch (e) {
    dispatch(editarDepartamentoError());
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;

    throw data;
  }
};
export const crearDepartamentoAction = ({
  nombreDepartamento,
  sucursal,
  idLider,
  empresa,
}) => async (dispatch, getState, { axios }) => {
  dispatch(crearDepartamento());
  try {
    const { resultado } = (
      await axios.post(
        `/departamentos/crear/${sucursal}`,
        { nombreDepartamento, idLider, empresa, sucursal },
        { headers: { token: localStorage.getItem('pv2token') } }
      )
    ).data;
    dispatch(crearDepartamentoExito({ resultado }));
  } catch (e) {
    dispatch(crearDepartamentoError());
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;

    throw data;
  }
};
