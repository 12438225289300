import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchDescuento } from '../../../store/descuentosDuck';
import { Tr } from '../VacacionesViews/styled';

const TableBody = ({ lista = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const editAction = async data => {
        await dispatch(fetchDescuento(data));
        history.push('/descuentos/agregar');
    }

    return (
        <React.Fragment>
            {lista && lista.length > 0 ? lista.map(d => (
                <Tr className='table-non' key={d._id} style={{ cursor: 'pointer' }} onClick={e => editAction(d._id)}>
                    <td>
                        <div>
                            {d.periodicidad && d.periodicidad.recurrente && d.periodicidad.fechaInicio? moment(d.periodicidad.fechaInicio).utc().format('DD/MM/YYYY') : moment(d.fechaAplicacion).utc().format('DD/MM/YYYY')}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.tipoDescuento}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.expediente? `Expediente: ${d.expediente} ${d.autoridadJudicial? 'Autoridad: ' + d.autoridadJudicial :''}` :
                            d.numeroCredito? d.numeroCredito : ''}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.monto}
                        </div>
                    </td>
                    <td>
                        <div>
                            {d.tipoMonto === 'tasa' ? '%' : d.tipoMonto === 'monto' ? '$' : 'Factor'}
                        </div>
                    </td>
                </Tr>
            )) : <Tr><td colSpan={5} className='center'>No se encontraron descuentos.</td></Tr>}
        </ React.Fragment>
    );
};

export default TableBody;