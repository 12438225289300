const delegacionesIMSS = [
    {
        label: "Aguascalientes",
        value: "Aguascalientes",
        subdelegaciones: [
            {
                value: "01",
                label: "Aguascalientes Norte"
            },
            {
                value: "19",
                label: "Aguascalientes Sur"
            }
        ],
    },
    {
        label: "Baja California norte",
        value: "Baja California norte",
        subdelegaciones: [
            {
                value: "01",
                label: "Mexicali"
            },
            {
                value: "02",
                label: "Tecate"
            },
            {
                value: "03",
                label: "Ensenada"
            },
            {
                value: "04",
                label: "San Luis Rio Colorado"
            },
            {
                value: "05",
                label: "Tijuana"
            }
        ],
    },
    {
        label: "Baja California sur",
        value: "Baja California sur",
        subdelegaciones: [
            {
                value: "01",
                label: "La Paz"
            },
            {
                value: "08",
                label: "Los Cabos"
            }
        ],
    },
    {
        label: "Campeche",
        value: "Campeche",
        subdelegaciones: [
            {
                value: "01",
                label: "Campeche"
            },
            {
                value: "04",
                label: "Cd del Carmen"
            }
        ],
    },
    {
        label: "Coahuila",
        value: "Coahuila",
        subdelegaciones: [
            {
                value: "03",
                label: "Saltillo"
            },
            {
                value: "04",
                label: "Torreón"
            },
            {
                value: "11",
                label: "Cd Acuña"
            },
            {
                value: "12",
                label: "Pirdras Negras"
            },
            {
                value: "17",
                label: "Monclova"
            },
            {
                value: "23",
                label: "Sabinas"
            }
        ],
    },
    {
        label: "Colima",
        value: "Colima",
        subdelegaciones: [
            {
                value: "01",
                label: "Colima"
            },
            {
                value: "03",
                label: "Manzanillo"
            },
            {
                value: "07",
                label: "Tecomán"
            }
        ],
    },
    {
        label: "Chiapas",
        value: "Chiapas",
        subdelegaciones: [
            {
                value: "01",
                label: "Tluxca Gutierrez"
            },
            {
                value: "02",
                label: "Tapachula"
            }
        ],
    },
    {
        label: "Chihuahua",
        value: "Chihuahua",
        subdelegaciones: [
            {
                value: "01",
                label: "Chihuahua"
            },
            {
                value: "03",
                label: "Cd Cuautemoc"
            },
            {
                value: "05",
                label: "Cd Delicias"
            },
            {
                value: "08",
                label: "Nuevo Casas Grandes"
            },
            {
                value: "10",
                label: "Juárez 1"
            },
            {
                value: "22",
                label: "Hidalgo del Parral"
            },
            {
                value: "60",
                label: "Tuárez 2"
            },
        ],
    },
    {
        label: "Durango",
        value: "Durango",
        subdelegaciones: [
            {
                value: "01",
                label: "Durango"
            },
            {
                value: "13",
                label: "Gómez Palacio"
            }
        ],
    },
    {
        label: "Guanajuato",
        value: "Guanajuato",
        subdelegaciones: [
            {
                value: "01",
                label: "Guanajuato"
            },
            {
                value: "05",
                label: "Irapuato"
            },
            {
                value: "08",
                label: "Celaya"
            },
            {
                value: "14",
                label: "Salamanca"
            },
            {
                value: "17",
                label: "León"
            }
        ],
    },
    {
        label: "Guerrero",
        value: "Guerrero",
        subdelegaciones: [
            {
                value: "01",
                label: "Chipancingo"
            },
            {
                value: "02",
                label: "Acapulco"
            },
            {
                value: "03",
                label: "Iguala"
            },
            {
                value: "13",
                label: "Zihuatanejo"
            }
        ],
    },
    {
        label: "Hidalgo",
        value: "Hidalgo",
        subdelegaciones: [
            {
                value: "01",
                label: "Pachuca"
            },
            {
                value: "05",
                label: "Tulancingo"
            },
            {
                value: "07",
                label: "Cd Sigagún"
            },
            {
                value: "10",
                label: "Tula de Allende"
            }
        ],
    },
    {
        label: "Jalisco",
        value: "Jalisco",
        subdelegaciones: [
            {
                value: "12",
                label: "Tepatitlán de M."
            },
            {
                value: "15",
                label: "Ocotlán"
            },
            {
                value: "22",
                label: "Cd Guzmán"
            },
            {
                value: "38",
                label: "Guadalajara Hidalgo"
            },
            {
                value: "39",
                label: "Guadalajara Libertad"
            },
            {
                value: "40",
                label: "Guadalajara Juárez"
            },
            {
                value: "50",
                label: "Puerto Vallarta"
            }
        ],
    },
    {
        label: "Estado de México Oriente",
        value: "Estado de México Oriente",
        subdelegaciones: [
            {
                value: "06",
                label: "Planepantla"
            },
            {
                value: "54",
                label: "Ecatepec"
            },
            {
                value: "80",
                label: "Los Reyes la Paz"
            }
        ],
    },
    {
        label: "Estado de México Poniente",
        value: "Estado de México Poniente",
        subdelegaciones: [
            {
                value: "01",
                label: "Toluca"
            },
            {
                value: "05",
                label: "Naucalpan"
            }
        ],
    },
    {
        label: "Michoacán",
        value: "Michoacán",
        subdelegaciones: [
            {
                value: "03",
                label: "Morelia"
            },
            {
                value: "09",
                label: "Uruapan"
            },
            {
                value: "13",
                label: "Zamora"
            },
            {
                value: "17",
                label: "Zitacuaro"
            },
            {
                value: "27",
                label: "Cardenas"
            }
        ],
    },
    {
        label: "Morelos",
        value: "Morelos",
        subdelegaciones: [
            {
                value: "01",
                label: "Cuernavaca"
            },
            {
                value: "11",
                label: "Cuautla"
            },
            {
                value: "15",
                label: "Zacatepec"
            }
        ],
    },
    {
        label: "Nayarit",
        value: "Nayarit",
        subdelegaciones: [
            {
                value: "01",
                label: "Tepic"
            }
        ],
    },
    {
        label: "Nuevo León",
        value: "Nuevo León",
        subdelegaciones: [
            {
                value: "06",
                label: "Montemorelos"
            },
            {
                value: "08",
                label: "Apodaca"
            },
            {
                value: "31",
                label: "2 NE Monterrey"
            },
            {
                value: "32",
                label: "1 NO Monterrey"
            },
            {
                value: "33",
                label: "4 SE Monterrey"
            },
            {
                value: "34",
                label: "2 SO Monterrey"
            }
        ],
    },
    {
        label: "Oaxaca",
        value: "Oaxaca",
        subdelegaciones: [
            {
                value: "02",
                label: "Oaxaca"
            },
            {
                value: "03",
                label: "Salina Cruz"
            },
            {
                value: "04",
                label: "Tuxtepec"
            },
            {
                value: "53",
                label: "Huatulco"
            }
        ],
    },
    {
        label: "Puebla",
        value: "Puebla",
        subdelegaciones: [
            {
                value: "01",
                label: "Puebla Norte"
            },
            {
                value: "05",
                label: "Teziutlán"
            },
            {
                value: "06",
                label: "Tehuacan"
            },
            {
                value: "08",
                label: "Izúcar de Matamoros"
            },
            {
                value: "22",
                label: "Puebla Sur"
            }
        ],
    },
    {
        label: "Querétaro",
        value: "Querétaro",
        subdelegaciones: [
            {
                value: "01",
                label: "Querétaro"
            },
            {
                value: "03",
                label: "San Juan del Rio"
            }
        ],
    },
    {
        label: "Quinatana Roo",
        value: "Quinatana Roo",
        subdelegaciones: [
            {
                value: "01",
                label: "Chetumal"
            },
            {
                value: "02",
                label: "Cozumel"
            },
            {
                value: "07",
                label: "Cancún"
            }
        ],
    },
    {
        label: "San Luis Potosí",
        value: "San Luis Potosí",
        subdelegaciones: [
            {
                value: "01",
                label: "S.L.P Oriente"
            },
            {
                value: "03",
                label: "Matehuala"
            },
            {
                value: "05",
                label: "Cd Valles"
            },
            {
                value: "60",
                label: "S.L.P Poniente"
            }
        ],
    },
    {
        label: "Sinaloa",
        value: "Sinaloa",
        subdelegaciones: [
            {
                value: "01",
                label: "Culiacan"
            },
            {
                value: "03",
                label: "Los Mochis"
            },
            {
                value: "04",
                label: "Guasave"
            },
            {
                value: "05",
                label: "Mazatlan"
            }
        ],
    },
    {
        label: "Sonora",
        value: "Sonora",
        subdelegaciones: [
            {
                value: "01",
                label: "Hermosillo"
            },
            {
                value: "03",
                label: "Guaymas"
            },
            {
                value: "07",
                label: "Navojoa"
            },
            {
                value: "10",
                label: "Nogales"
            },
            {
                value: "13",
                label: "Caborca"
            },
            {
                value: "51",
                label: "Agua Prieta"
            },
            {
                value: "57",
                label: "Nacosari de García"
            },
            {
                value: "70",
                label: "Cd Obregón"
            }
        ],
    },
    {
        label: "Tabasco",
        value: "Tabasco",
        subdelegaciones: [
            {
                value: "01",
                label: "Villahermosa"
            },
            {
                value: "02",
                label: "Cárdenas"
            }
        ],
    },
    {
        label: "Tamaulipas",
        value: "Tamaulipas",
        subdelegaciones: [
            {
                value: "01",
                label: "Cd Victoria"
            },
            {
                value: "04",
                label: "Reynosa"
            },
            {
                value: "10",
                label: "Tampico"
            },
            {
                value: "13",
                label: "Cd Mante"
            },
            {
                value: "18",
                label: "Nuevo Laredo"
            },
            {
                value: "19",
                label: "Matamoros"
            }
        ],
    },
    {
        label: "Tlaxcala",
        value: "Tlaxcala",
        subdelegaciones: [
            {
                value: "01",
                label: "Tlaxcala"
            }
        ],
    },
    {
        label: "Veracruz Norte",
        value: "Veracruz Norte",
        subdelegaciones: [
            {
                value: "02",
                label: "Jalapa"
            },
            {
                value: "07",
                label: "Poza Rica"
            },
            {
                value: "09",
                label: "Martínez de la Torre"
            },
            {
                value: "12",
                label: "Veracrúz Puerto"
            },
            {
                value: "25",
                label: "Lerdo de Tejada"
            }
        ],
    },
    {
        label: "Veracruz Sur",
        value: "Veracruz Sur",
        subdelegaciones: [
            {
                value: "02",
                label: "Cordoba"
            },
            {
                value: "03",
                label: "Orizaba"
            },
            {
                value: "38",
                label: "Cosamaloapan"
            },
            {
                value: "45",
                label: "Coatzacoalcos"
            }
        ],
    },
    {
        label: "Yucatán",
        value: "Yucatán",
        subdelegaciones: [
            {
                value: "01",
                label: "Merida Norte"
            },
            {
                value: "33",
                label: "Merida Sur"
            }
        ],
    },
    {
        label: "Zacatecas",
        value: "Zacatecas",
        subdelegaciones: [
            {
                value: "01",
                label: "Zacatecas"
            },
            {
                value: "09",
                label: "Fresnillo"
            }
        ],
    },
    {
        label: "Norte Valle de México",
        value: "Norte Valle de México",
        subdelegaciones: [
            {
                value: "11",
                label: "Magdalena de las Salinas"
            },
            {
                value: "16",
                label: "Polanco"
            },
            {
                value: "54",
                label: "Santa Ma la Ribera"
            },
            {
                value: "56",
                label: "Guerrero"
            },
            {
                value: "57",
                label: "Centro"
            }
        ],
    },
    {
        label: "Sur Valle de México",
        value: "Sur Valle de México",
        subdelegaciones: [
            {
                value: "01",
                label: "San Angel"
            },
            {
                value: "06",
                label: "Del Valle"
            },
            {
                value: "11",
                label: "Santa Anita"
            },
            {
                value: "54",
                label: "Curubusco"
            },
            {
                value: "58",
                label: "Piedad Narvarte"
            }
        ]
    }
];

export default delegacionesIMSS;
