import React from 'react';
import {Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";

const PageName = ({icon}) =>{
    return(
        <Row>
            <h2>
                <FontAwesomeIcon icon={icon}  className="m-2 " />
                Mi Perfil
            </h2>

        </Row>
    )
}
export default PageName;