export const optionsJornada = [
  {
    value: 'Diurna',
    label: 'Diurna',
  },
  {
    value: 'Nocturna',
    label: 'Nocturna',
  },
  {
    value: 'Mixta',
    label: 'Mixta',
  },
  // {
  //   value: 'Por hora',
  //   label: 'Por hora',
  // },
  // {
  //   value: 'Reducida',
  //   label: 'Reducida',
  // },
  // {
  //   value: 'Continuada',
  //   label: 'Continuada',
  // },
  // {
  //   value: 'Partida',
  //   label: 'Partida',
  // },
  // {
  //   value: 'Por turnos',
  //   label: 'Por turnos',
  // },
  // {
  //   value: 'Otro',
  //   label: 'Otro',
  // },
];

export const optionsRiesgo = [
  {
    value: 'Clase I',
    label: 'Clase I',
  },
  {
    value: 'Clase II',
    label: 'Clase II',
  },
  {
    value: 'Clase III',
    label: 'Clase III',
  },
  {
    value: 'Clase IV',
    label: 'Clase IV',
  },
  {
    value: 'Clase V',
    label: 'Clase V',
  },
  {
    value: 'No aplica',
    label: 'No aplica',
  },
];

export const optionsRegimen = [
  {
    value: 'Sueldos',
    label: 'Sueldos',
  },
  {
    value: 'Jubilados',
    label: 'Jubilados',
  },
  {
    value: 'Pensionados',
    label: 'Pensionados',
  },
  {
    value: 'Asimilados Miembros de Sociedades Cooperativas Producción',
    label: 'Asimilados Miembros de Sociedades Cooperativas Producción',
  },
  {
    value: 'Asimilados Integrantes Sociedades Asociaciones Civiles',
    label: 'Asimilados Integrantes Sociedades Asociaciones Civiles',
  },
  {
    value: 'Asimilados Miembros Consejos',
    label: 'Asimilados Miembros Consejos',
  },
  {
    value: 'Asimilados Comisionistas',
    label: 'Asimilados Comisionistas',
  },
  {
    value: 'Asimilados Honorarios',
    label: 'Asimilados Honorarios',
  },
  {
    value: 'Asimilados Acciones',
    label: 'Asimilados Acciones',
  },
  {
    value: 'Asimilados Otros',
    label: 'Asimilados Otros',
  },
  {
    value: 'Jubilados o Pensionados',
    label: 'Jubilados o Pensionados',
  },
  {
    value: 'Indemnización o Separación',
    label: 'Indemnización o Separación',
  },
  {
    value: 'Autotransportes de Carga',
    label: 'Autotransportes de Carga',
  },
  {
    value: 'Otro',
    label: 'Otro',
  },
];

export const optionsTipoCuentaPago = [
  {
    value: 'Cuenta bancaria',
    label: 'Cuenta bancaria',
  },
  {
    value: 'CLABE Interbancaria',
    label: 'CLABE Interbancaria',
  },
  {
    value: 'Tarjeta bancaria',
    label: 'Tarjeta bancaria',
  },
  {
    value: 'Número de teléfono',
    label: 'Número de teléfono',
  },
];

export const optionsModalidadPago = [
  // {
  //   value: 'Diario',
  //   label: 'Diario',
  // },
  {
    value: 'Semanal',
    label: 'Semanal',
  },
  // {
  //   value: 'Decenal',
  //   label: 'Decenal',
  // },
  // {
  //   value: 'Catorcenal',
  //   label: 'Catorcenal',
  // },
  {
    value: 'Quincenal',
    label: 'Quincenal',
  },
  // {
  //   value: 'Mensual',
  //   label: 'Mensual',
  // },
  // {
  //   value: 'Otro',
  //   label: 'Otro',
  // },
];

export const optionsMetodoPago = [
  {
    value: 'Cheque',
    label: 'Cheque',
  },
  {
    value: 'Efectivo',
    label: 'Efectivo',
  },
  {
     value: 'Depósito',
     label: 'Depósito',
  },
];
