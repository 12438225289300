import React, { useEffect } from 'react';
import FirstAdd from '../../../components/ui/FirstAdd';
import colors from '../../../constans/colors';
import TableCommon from '../VacacionesViews/TableVacaciones';
import TableBody from './TableBody';
import { Button, FormGroup } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPromociones } from '../../../store/promocionesDuck';
import { TableContainer } from '../VacacionesViews/styled';

const PromocionesView = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const { lista = [] } = useSelector((state) => state.promociones);
    const { permisos = {} } = useSelector((state) => state.user);

    useEffect(() => {
        (async () => {
            try {
                await dispatch(fetchPromociones());
            } catch (error) { }
        })();
    }, [dispatch]);

    if(lista && lista.length > 0)
        return (
            <React.Fragment>
                <FormGroup row style={{ marginLeft: 16 }}>
                    <h5>Promociones Laborales</h5>
                </FormGroup>
                <TableContainer style={{marginLeft:30, marginTop:10}}>
                    <TableCommon
                        keysAceepted={['Puesto', 'Fecha Inicio', 'Autorizado Por', 'Estatus']}
                        striped
                    >
                        <TableBody lista={lista}/>
                    </TableCommon>

                    <Button type="button" onClick={()=>{ history.push('/promociones/agregar') }} 
                        style={{ background:colors.secondary, width:'203px', height:'51px', borderRadius:'4px' }}
                        hidden={permisos.colaboradores !== 'write'}>+ Agregar</Button>
                </TableContainer>
            </React.Fragment>
        );
        else return(<FirstAdd mensaje="Agrega aquí las promociones laborales de tu colaborador" vista='promociones' disabled={permisos.colaboradorPermisos !== 'write'}/>)
};

export default PromocionesView;