import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
    name: 'descuentos',
    initialState: {
        loading: false,
        error: null,
        lista: [],
        dto: {},
        isEdit: false
    },
    reducers:{
        // Action handlers
        descuentosRequested: (descuentos, action) =>{
            descuentos.loading = true;
        },
        descuentosReceived: (descuentos, action) =>{
            descuentos.lista = action.payload.resultado;
            descuentos.loading = false;
            descuentos.error = null;
        },
        descuentosRequestedFailed: (descuentos, action) =>{
            descuentos.error = action.payload;
            descuentos.loading = false;
        },
        descuentoSaved: (descuentos, action) =>{
            descuentos.loading = false;
            descuentos.error = null;
            descuentos.isEdit = false;
        },
        descuentoAddFailed: (descuentos, action) =>{
            descuentos.error = action.payload;
            descuentos.loading = false;
        },
        descuentoEditRequested: (descuentos, action) =>{
            descuentos.loading = true;
        },
        descuentoEditRequestSuccess: (descuentos, action) =>{
            descuentos.dto = action.payload.resultado;
            descuentos.isEdit = true;
            descuentos.loading = false;
            descuentos.error = null;
        },
        descuentoEditRequestFailed: (descuentos, action) =>{
            descuentos.error = action.payload;
            descuentos.dto = {};
            descuentos.isEdit = false;
            descuentos.loading = false;
        },
        descuentoEditFinished: (descuentos, action) =>{
            descuentos.dto = {};
            descuentos.isEdit = false;
            descuentos.loading = false;
        }
    }
});

// Actions
const { descuentosRequested, descuentosReceived, descuentosRequestedFailed, descuentoSaved, descuentoAddFailed, descuentoEditRequested, descuentoEditRequestSuccess, descuentoEditRequestFailed, descuentoEditFinished } = slice.actions;

export default slice.reducer;

// Action creators

const getHeaders = () => ({ headers: { token: localStorage.getItem('pv2token')}});

export const descuentoEditFinish = () =>(dispatch, getState) => {
    dispatch(descuentoEditFinished());
}

export const fetchDescuentos = (active, infonavit) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(descuentosRequested());
        const response = await axios.get(`descuentos/descuentos_colaborador/${getState().colaborador._id}?active=${active}&infonavit=${infonavit}`, getHeaders());
        dispatch(descuentosReceived(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(descuentosRequestedFailed());
        throw data;
    }
}

export const fetchDescuento = (idDescuento) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(descuentoEditRequested());
        const response = await axios.get(`descuentos/descuento/${idDescuento}`, getHeaders());
        dispatch(descuentoEditRequestSuccess(response.data));
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(descuentoEditRequestFailed());
        throw data;
    }
}

export const deleteDescuento = (idDescuento) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(descuentoEditRequested());
        const response = await axios.delete(`descuentos/eliminar_descuento/${idDescuento}`, getHeaders());
        dispatch(descuentoEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(descuentoEditRequestFailed());
        throw data;
    }
}

export const createDescuento = (descuento) => async (dispatch, getState, { axios }) => {
    try {
        console.log('creando descuento infonavit');
        dispatch(descuentosRequested());
        const { _id } = getState().colaborador;
        const { archivo = {}, ...rest } = descuento;

        const formData = new FormData();
        if (archivo[0]) formData.append('archivo', archivo[0]);
        formData.append('colaborador', _id);
        formData.append(
            'descuento',
            JSON.stringify({ ...rest })
        );
        const { data: { resultado } } = await axios.post('/descuentos/crear', formData, getHeaders());
        dispatch(descuentoSaved());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(descuentoAddFailed());
        throw data;
    }
}

export const editDescuento = (descuento) => async (dispatch, getState, { axios }) => {
    try {
        dispatch(descuentoEditRequested());
        const { _id } = getState().colaborador;
        const { archivo = {}, ...rest } = descuento;
        const idDescuento = getState().descuentos.dto._id;
        console.log(`Editando descuento ${idDescuento}`);

        const formData = new FormData();
        if (archivo[0]) formData.append('archivo', archivo[0]);
        formData.append('colaborador', _id);
        formData.append(
            'descuento',
            JSON.stringify({ ...rest })
        );
        const { data: { resultado } } = await axios.put(`/descuentos/editar_descuento/${idDescuento}`, formData, getHeaders());
        dispatch(descuentoEditFinished());
    } catch (error) {
        const { response } = error;
        const { request, ...errorObject } = response;
        const { data } = errorObject;
        dispatch(descuentoEditRequestFailed());
        throw data;
    }
}