import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Label, Form, CustomInput, FormGroup, Input, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { detalleRolAction, eliminarRolAction, editaBtn, editarRolAction, elimiinarRolClean } from '../../store/rolesDucks';
import { useParams, useHistory } from 'react-router-dom';
import SweetAlert from '../ui/SweetAlet';
import Spiner from '../ui/Spiner';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { schemaRol } from '../../constans/yupSchemas';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import colors from "../../constans/colors";
import ComponentDescrip from '../ui/ComponentDescrip';
import { SaveButton } from '../ui/styles/styles';
import PermisoRol from './PermisoRol';
const DetalleRol = () => {
  const { idRol } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schemaRol),
  });
  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const { loading, rol, editar } = useSelector((state) => state.roles);
  const { nombreRol = '', permisos = {} } = rol;
  const {
    empresas = 'none',
    sucursales = 'none',
    departamentos = 'none',
    usuarios = 'none',
    roles = 'none',
    colaboradores = 'none',
    incidencias = 'none',
    nomina = 'none',
    autorizacionNomina = 'none',
    timbradoNomina = 'none',
    reportes = 'none',
    calendario = 'none',
    serviciosVerificacion = 'none',
    serviciosSocioeconomicos = 'none',
    serviciosPsicometrias = 'none',
    serviciosJuicios = 'none',
    reclutamiento = 'none',
    //Nuevos permisos para colaborador
    colaboradorPersonales = 'none',
    colaboradorLaborales = 'none',
    colaboradorNomina = 'none',
    colaboradorSimulador = 'none',
    colaboradorOrdinarias = 'none',
    colaboradorDescuentos = 'none',
    colaboradorInfonavit = 'none',
    colaboradorUtilitario = 'none',
    colaboradorUniforme = 'none',
    colaboradorExpediente = 'none',
    colaboradorActas = 'none',
    colaboradorVacaciones = 'none',
    colaboradorPermisos = 'none',
    colaboradorIncapacidades = 'none',
    colaboradorSalud = 'none',
    imss = 'nose'
  } = permisos;

  const getDetalle = useCallback(
    async (idRol) => {
      try {
        dispatch(detalleRolAction(idRol));
      } catch (e) {
        await SweetAlert({ icon: 'error', title: 'Error!', text: e });
      }
    },
    [dispatch]
  );

  const eliminarRol = useCallback(async (idRol) => {
    try {
      await dispatch(eliminarRolAction(idRol))
      await SweetAlert({ icon: 'success', title: 'Exito', text: "Rol eliminado exitosamente" });
      history.push('/dashboard/empresa/rol')
      await dispatch(elimiinarRolClean())
    } catch (e) {
      await SweetAlert({ icon: 'error', title: 'Error!', text: e });
    }
  }, [dispatch])

  const eliminarRolBtn = () => {
    eliminarRol(idRol);
  };

  const editarRol = useCallback(async (nuevoRol) => {
    try {
      console.log(nuevoRol)
      await dispatch(editarRolAction(nuevoRol))
      await SweetAlert({ icon: 'success', title: 'Exito', text: "Rol editado exitosamente" });
      history.push('/dashboard/empresa/rol')
    } catch (e) {
      await SweetAlert({ icon: 'error', title: 'Error!', text: e });

    }
  }, [dispatch])
  const submit = (data) => {
    const { nombreRol, usuarios, sucursales, serviciosVerificacion, serviciosSocioeconomicos, serviciosPsicometrias, serviciosJuicios, roles, reportes,
      reclutamiento, nomina, empresas, departamentos, colaboradores, autorizacionNomina, timbradoNomina, calendario, incidencias, colaboradorPersonales,
      colaboradorLaborales,
      colaboradorNomina,
      colaboradorSimulador,
      colaboradorOrdinarias,
      colaboradorDescuentos,
      colaboradorInfonavit,
      colaboradorUtilitario,
      colaboradorUniforme,
      colaboradorExpediente,
      colaboradorActas,
      colaboradorVacaciones,
      colaboradorPermisos,
      colaboradorIncapacidades,
      colaboradorSalud, imss } = data;
    const nuevoRol = {
      idRol: idRol,
      nombreRol: nombreRol,
      permisos: {
        timbradoNomina,
        calendario,
        incidencias,
        autorizacionNomina: autorizacionNomina,
        colaboradores: colaboradores,
        departamentos: departamentos,
        empresas: empresas,
        nomina: nomina,
        reclutamiento: reclutamiento,
        reportes: reportes,
        roles: roles,
        serviciosJuicios: serviciosJuicios,
        serviciosPsicometrias: serviciosPsicometrias,
        serviciosSocioeconomicos: serviciosSocioeconomicos,
        serviciosVerificacion: serviciosVerificacion,
        sucursales: sucursales,
        usuarios: usuarios,
        colaboradorPersonales,
        colaboradorLaborales,
        colaboradorNomina,
        colaboradorSimulador,
        colaboradorOrdinarias,
        colaboradorDescuentos,
        colaboradorInfonavit,
        colaboradorUtilitario,
        colaboradorUniforme,
        colaboradorExpediente,
        colaboradorActas,
        colaboradorVacaciones,
        colaboradorPermisos,
        colaboradorIncapacidades,
        colaboradorSalud,
        imss: imss
      }
    }
    editarRol(nuevoRol)

  };

  useEffect(() => {
    getDetalle(idRol);
  }, [getDetalle, idRol]);
  const editarClick = () => {
    dispatch(editaBtn())
  }
  let disabled = !editar ? { 'disabled': 'disabled' } : {};

  if (!rol) return null;
  if (loading) return <Spiner />;

  return (
    <div>
      <Row style={{ marginBottom: '2em' }}>
        <Col lg={12}>
          <ComponentDescrip title={"Detalles de rol"} description={"Edita aquí los permisos para este rol"} />
        </Col>

      </Row>
      <Row style={{ marginBottom: '2em' }}>
        <Col lg={3}>
          <h5>Rol</h5>
          <p style={{ marginLeft: '14px' }}>{nombreRol}</p>
        </Col>
        <Col lg={3}>
          <h5>Usuarios</h5>
          <p style={{ marginLeft: '14px' }}>0</p>
        </Col>
        <Col lg={3}>
          <h5>Editar</h5>
          <div className="clearfix" style={{ padding: '.5rem' }}>
            <button className="btn" onClick={() => editarClick()} >
              <FontAwesomeIcon
                color={colors.primary}
                icon={faPencilAlt}
                style={{ fontSize: '1.0rem' }} />
            </button>
          </div>
        </Col>
      </Row>

      <Row className='permisos-form'>
        <Col lg={12}>
          <Row style={{ marginBottom: '2em' }}>
            <h5 style={{ marginLeft: '11px' }}>Permisos</h5>
          </Row>
          <Row>
            <Col lg={12}>
              <Form onSubmit={handleSubmit(submit)}>
                <FormGroup>
                  <Label>Nombre Rol*</Label>
                  <Input
                    type='text'
                    name='nombreRol'
                    defaultValue={nombreRol}
                    innerRef={register()}
                    {...disabled}
                  />
                  {err.nombreRol && (
                    <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                      El nombre del rol es requerido
                    </span>
                  )}
                </FormGroup>

                <Row>
                  <Col sm={3}></Col>
                  <Col sm={3}><h5>Escritura</h5></Col>
                  <Col sm={3}><h5>Lectura</h5></Col>
                  <Col sm={3}><h5>Ninguno</h5></Col>
                </Row>
                <Table borderless hover responsive autoCorrect='true'>
                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Empresas
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='empresas1'
                        name='empresas'
                        value='write'
                        defaultChecked={empresas === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='empresas2'
                        name='empresas'
                        value='read'
                        defaultChecked={empresas === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='empresas3'
                        name='empresas'
                        value='none'
                        defaultChecked={empresas === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Sucursales
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='sucursales1'
                        name='sucursales'
                        value='write'
                        defaultChecked={sucursales === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='sucursales2'
                        name='sucursales'
                        value='read'
                        defaultChecked={sucursales === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='sucursales3'
                        name='sucursales'
                        value='none'
                        defaultChecked={sucursales === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Departamentos
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='departamentos1'
                        name='departamentos'
                        value='write'
                        defaultChecked={departamentos === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='departamentos2'
                        name='departamentos'
                        value='read'
                        defaultChecked={departamentos === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='departamentos3'
                        name='departamentos'
                        value='none'
                        defaultChecked={departamentos === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Usuarios
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='usuarios1'
                        name='usuarios'
                        value='write'
                        defaultChecked={usuarios === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='usuarios2'
                        name='usuarios'
                        value='read'
                        defaultChecked={usuarios === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='usuarios3'
                        name='usuarios'
                        value='none'
                        defaultChecked={usuarios === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Roles
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='roles1'
                        name='roles'
                        value='write'
                        defaultChecked={roles === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='roles2'
                        name='roles'
                        value='read'
                        defaultChecked={roles === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='roles3'
                        name='roles'
                        value='none'
                        defaultChecked={roles === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='incidencias' sm={3}>
                      Incidencias
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='incidencias1'
                        name='incidencias'
                        value='write'
                        defaultChecked={incidencias === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='incidencias2'
                        name='incidencias'
                        value='read'
                        defaultChecked={incidencias === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='incidencias3'
                        name='incidencias'
                        value='none'
                        defaultChecked={incidencias === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='nomina' sm={3}>
                      Nómina
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='nomina'
                        name='nomina'
                        value='write'
                        defaultChecked={nomina === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='nomina2'
                        name='nomina'
                        value='read'
                        defaultChecked={nomina === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='nomina3'
                        name='nomina'
                        value='none'
                        defaultChecked={nomina === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='autorizacionNomina' sm={3}>
                      Autorización Nómina
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='autorizacionNomina1'
                        name='autorizacionNomina'
                        value='write'
                        defaultChecked={autorizacionNomina === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      {/* Solo escritura */}
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='autorizacionNomina3'
                        name='autorizacionNomina'
                        value='none'
                        defaultChecked={autorizacionNomina === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='timbradoNomina' sm={3}>
                      Timbrado Nómina
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='timbradoNomina1'
                        name='timbradoNomina'
                        value='write'
                        defaultChecked={timbradoNomina === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      {/* Solo escritura */}
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='timbradoNomina3'
                        name='timbradoNomina'
                        value='none'
                        defaultChecked={timbradoNomina === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <FormGroup row>
                    <Label for='exampleCheckbox' sm={3}>
                      Reportes
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='reportes1'
                        name='reportes'
                        value='write'
                        defaultChecked={reportes === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='reportes2'
                        name='reportes'
                        value='read'
                        defaultChecked={reportes === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='reportes3'
                        name='reportes'
                        value='none'
                        defaultChecked={reportes === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>
                  <FormGroup row>
                    <Label for='calendario' sm={3}>
                      Calendario
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='calendario1'
                        name='calendario'
                        value='write'
                        defaultChecked={calendario === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='calendario2'
                        name='calendario'
                        value='read'
                        defaultChecked={calendario === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='calendario3'
                        name='calendario'
                        value='none'
                        defaultChecked={calendario === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>              

                  <PermisoRol nombre='Jurídico RH' id='serviciosJuicios' permiso={serviciosJuicios} register={register} disabled={disabled} />
                  <PermisoRol nombre='Colaboradores' id='colaboradores' permiso={colaboradores} register={register} disabled={disabled} />

                  <FormGroup row>
                    <Label for='imss' sm={3}>
                      IMSS
                  </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='imss1'
                        name='imss'
                        value='write'
                        defaultChecked={imss === 'write'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='imss2'
                        name='imss'
                        value='read'
                        defaultChecked={imss === 'read'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                    <Label check sm={3}>
                      <CustomInput
                        type='radio'
                        id='imss3'
                        name='imss'
                        value='none'
                        defaultChecked={imss === 'none'}
                        innerRef={register()}
                        {...disabled}
                      />
                    </Label>
                  </FormGroup>

                  <Row style={{ marginBottom: '2em', marginTop: '2em' }}>
                    <h5 style={{ marginLeft: '16px' }}>Permisos para el submódulo de colaborador</h5>
                  </Row>

                  <PermisoRol nombre='Información Personal' id='colaboradorPersonales' permiso={colaboradorPersonales} register={register} disabled={disabled} />
                  <PermisoRol nombre='Condiciones Laborales' id='colaboradorLaborales' permiso={colaboradorLaborales} register={register} disabled={disabled} />
                  <PermisoRol nombre='Información Nómina' id='colaboradorNomina' permiso={colaboradorNomina} register={register} disabled={disabled} />
                  <PermisoRol nombre='Simulador Salarial' id='colaboradorSimulador' permiso={colaboradorSimulador} register={register} disabled={disabled} />
                  <PermisoRol nombre='Prestaciones Ordinarias' id='colaboradorOrdinarias' permiso={colaboradorOrdinarias} register={register} disabled={disabled} />
                  <PermisoRol nombre='Descuentos al Salario' id='colaboradorDescuentos' permiso={colaboradorDescuentos} register={register} disabled={disabled} />
                  <PermisoRol nombre='Crédito Infonavit' id='colaboradorInfonavit' permiso={colaboradorInfonavit} register={register} disabled={disabled} />
                  <PermisoRol nombre='Utilitario' id='colaboradorUtilitario' permiso={colaboradorUtilitario} register={register} disabled={disabled} />
                  <PermisoRol nombre='Uniformes' id='colaboradorUniforme' permiso={colaboradorUniforme} register={register} disabled={disabled} />
                  <PermisoRol nombre='Expediente Digital' id='colaboradorExpediente' permiso={colaboradorExpediente} register={register} disabled={disabled} />
                  <PermisoRol nombre='Actas Administrativas' id='colaboradorActas' permiso={colaboradorActas} register={register} disabled={disabled} />
                  <PermisoRol nombre='Vacaciones' id='colaboradorVacaciones' permiso={colaboradorVacaciones} register={register} disabled={disabled} />
                  <PermisoRol nombre='Permisos Laborales' id='colaboradorPermisos' permiso={colaboradorPermisos} register={register} disabled={disabled} />
                  <PermisoRol nombre='Incapacidades' id='colaboradorIncapacidades' permiso={colaboradorIncapacidades} register={register} disabled={disabled} />
                  <PermisoRol nombre='Salud Laboral' id='colaboradorSalud' permiso={colaboradorSalud} register={register} disabled={disabled} />
                </Table>
                <FormGroup row>
                  {editar ?
                    <SaveButton type='submit' style={{ marginTop: '2em' }} >Guardar</SaveButton>
                    : ''}
                </FormGroup>
              </Form>
            </Col>
          </Row>

        </Col>
      </Row>
    </div>
  );
};
export default DetalleRol;
