import React from 'react'
import { TableContainer } from '../colaboradores/UniformeViews/styled'
import TableCommon from '../ui/TableCommon'
import TableBody from './TableBody'

const TableConfiguracion = React.memo(({listaConfiguracion=[]}) => {
    return (
        <TableContainer>
            <TableCommon  keysAceepted={["Documento","Expiración",'Sección','']} striped>
                <TableBody listaConfiguracion={listaConfiguracion} />
            </TableCommon>
        </TableContainer>
    )
})

export default TableConfiguracion
