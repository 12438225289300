import React, { useCallback } from 'react';
import { agregarUtil } from '../../../store/utilitarioDucks';
import colors from '../../../constans/colors';
import PulsateDot from '../../ui/PulsateDot';
import { Tr } from './styled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

const TableBody = React.memo(({ listaUtilitarios = [] }) => {
  const history= useHistory();
  const dispatch = useDispatch();
  const showUtil = useCallback((utilitario) => {
    dispatch(agregarUtil(utilitario))
    history.replace('/uniforme')
  }, [dispatch,history]);
  return (
    <>
      {listaUtilitarios.map((utilitario, index) => (
        <Tr
          key={utilitario.nombre + index}
          onClick={() => showUtil(utilitario)}
        >
          <td style={{ paddingLeft: 40,  width: '30%' }}>{utilitario.nombre}</td>
          <td style={{ width: '25%' }}>{utilitario.color}</td>
          <td style={{ width: '15%' }}>{moment(utilitario.fechaEntrega).format('DD/MM/YYYY')}</td>
          <td style={{ width: '15%' }}>
            {utilitario.status === 'asignado' ? (
              <PulsateDot />
            ) : (
              <PulsateDot color={colors.closeStatus} time='500ms' />
            )}
          </td>
          <td style={{ width: '15%' }}><CurrencyFormat value={utilitario.valorFactura.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimal={2} /></td>
        </Tr>
      ))}
    </>
  );
});

export default TableBody;
