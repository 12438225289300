import React, { useState, useEffect, useCallback } from 'react';
import TableCommon from '../ui/TableCommon';
import TableBodyDepartamentos from './TableBodyDepartamentos';
import { useSelector } from 'react-redux';
import DepartamentoModal from './DepartamentoModal';
import { SubTitleMenu, Container, SaveButton, MessageAddUtils, } from '../../components/ui/styles/styles';

const TableDepartamentos = React.memo(({ history }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [departamento, setDepartamento] = useState({});

  const { listaDepartamentos = [] } = useSelector((state) => state.departamentos);
  const sucursal = useSelector((state) => state.sucursal);

  useEffect(()=>{
    if(!isEdit)
      setDepartamento({sucursal: sucursal._id, empresa: sucursal.idEmpresa})
  },[sucursal,isEdit])
  
  const toggleModal = useCallback(() => setIsOpen(!isOpen),[isOpen]);

  const fetchDepartamento = (departamento, isEdit) =>{
    setDepartamento(departamento);
    toggleModal();
    setIsEdit(isEdit)
  }

  return (
    <section style={{marginLeft: '35px'}}>

      <DepartamentoModal
        isOpen={isOpen}
        pertenenciaData={departamento}
        setIsOpen={setIsOpen}
        isEdit={isEdit}
      />

      {listaDepartamentos.length > 0 ? (
        <>
          <SubTitleMenu style={{ fontSize: '1.5rem', marginBottom: '0rem', color:'#2b388f'}}>
            Departamentos
          </SubTitleMenu>
          <TableCommon style={{ marginTop: '60px' }}>
            <TableBodyDepartamentos
              history={history}
              listaDepartamentos={listaDepartamentos}
              setDepartamento={fetchDepartamento}
              toggleModal={toggleModal}
              isEdit={isEdit}
            />
          </TableCommon>
          <br /><br />
          <SaveButton
            style={{ width: '15rem' ,float:'left'}}
            onClick={toggleModal}
          >
            + Agregar
          </SaveButton>

        </>
      ) : (
        <Container>
          <MessageAddUtils>Da de alta tu primer Departamento</MessageAddUtils>
            <SaveButton
              style={{ width: '15rem', float: 'right' }}
              onClick={toggleModal}
            >
              + Agregar
          </SaveButton>
        </Container>
      )}
    </section>
  );
});

export default TableDepartamentos;
