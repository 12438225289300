import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyleInputs } from "../ui/styles/StyleNomina";
import { Button, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input, Table, Nav, NavItem, NavLink } from "reactstrap";
import axios from "axios";
import fileDownload from 'js-file-download'
import moment from 'moment'

const api = process.env.REACT_APP_API_URL;

const LayoutBancario = ({ isOpen, toggle, nominas }) => {
    const [bank, setBank] = useState('banorte');
    const [info, setInfo] = useState({
        operation: '',
        claveId: '',
        originAccount: '',
        description: '',
        applicationDate: new Date(),
        payInstructions: 'X'
    })
    const headers = {
        headers: {
            token: localStorage.getItem("pv2token"),
        },
    };

    const handleGenerat = async () => {
        let body = {}
        body.bank = bank
        body.info = info
        body.payrollList = nominas
        const { data } = await axios.post(`${api}/documentos/bancos/${bank}`, body, headers)
        handleDownload(data.response);
    }

    const handleDownload = (res) => {
        fileDownload(res.txt, `${bank.toUpperCase()}-${moment(info.applicationDate).format('DDMMYYYY')}.txt`)
        toggle(false)
    }

    return (
        <Modal centered isOpen={isOpen} toggle={toggle} className={"modal-"} size="lg" style={{ maxWidth: "1600px", width: "80%", margin: "10px auto" }}>
            <ModalHeader style={{ background: "#2B388F", color: "white" }} toggle={toggle}>
                Layout Bancario
            </ModalHeader>
            <StyleInputs>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="select"
                                    name="select"
                                    defaultValue=""
                                    value={bank}
                                    onChange={(e) => setBank(e.target.value)}
                                >
                                    <option value="banorte">Banorte</option>
                                    <option value="santander">Santander</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {
                            bank !== '' ? (
                                <Col>
                                    {
                                        bank === 'banorte' ? (
                                            <>
                                                <Col>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="descripcion"
                                                            placeholder="Descripción"
                                                            value={info.description}
                                                            onChange={e => setInfo({ ...info, description: e.target.value })}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        type="date"
                                                        name="fecha"
                                                        value={info.applicationDate}
                                                        placeholder="Fecha de Aplicación"
                                                        onChange={(e) => setInfo({ ...info, applicationDate: moment(e.target.value).format("YYYY-MM-DD") })}
                                                    />
                                                </Col>
                                            </>
                                        ) : null
                                    }

                                    {
                                        bank === 'santander' ? (
                                            <>
                                                <Col>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="descripcion"
                                                            placeholder="Descripción"
                                                            value={info.description}
                                                            onChange={e => setInfo({ ...info, description: e.target.value })}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Input
                                                            type="text"
                                                            name="origen"
                                                            placeholder="Origen"
                                                            value={info.originAccount}
                                                            onChange={e => setInfo({ ...info, originAccount: e.target.value })}
                                                        ></Input>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Input
                                                            type="select"
                                                            name="selectDay"
                                                            defaultValue=""
                                                            value={info.applicationDate}
                                                            onChange={e => setInfo({ ...info, applicationDate: e.target.value })}
                                                        >
                                                            <option value="1">Mismo día</option>
                                                            <option value="2">Día siguiente</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </>
                                        ) : null
                                    }
                                </Col>
                            ) : null
                        }
                        <Col>
                            <FormGroup>
                                <Button color="primary" block onClick={() => toggle(false)}>
                                    Cancelar
                                </Button>
                                <Button color="primary" block onClick={handleGenerat}>
                                    Generar
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
            </StyleInputs>
        </Modal>
    );
};

LayoutBancario.propTypes = {
    toggle: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    colaborador: PropTypes.object,
    typeModal: PropTypes.string,
};

export default LayoutBancario;
