import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulsate = keyframes`
 0%{
     transform: scale(0.1, 0.1);
     opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {transform: scale(1.2, 1.2); 
    opacity: 0.0;
    }
`;

const RingContainer = styled.div`
  position: relative;
  /* margin: 0 auto; */
  width: 20%;
`;
const Ringring = styled.div`
  border: 3px solid ${(props) => props.color};
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: 0;
  top: 0;
  animation: ${pulsate} ${(props) => props.time} ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
`;
const Circle = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
`;
const PulsateDot = ({ color = '#62bd19', time = '1s',title='' }) => {
  return (
    <RingContainer>
      {/* <Ringring color={color} time={time} /> */}
      <Circle color={color} title={title} />
    </RingContainer>
  );
};

export default PulsateDot;
