import React, { useState, useEffect } from 'react';
import { Col, FormGroup, Label, Form, CustomInput, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import CustomSelect from '../../components/ui/CustomSelect';
import Spiner from '../../components/ui/Spiner';
import { LabelForms, SaveButton, SubTitleMenu, TitleMenu } from '../../components/ui/styles/styles';
import { fetchEmpresasToDropdown } from '../../store/empresasDuck';
import { yupResolver } from '@hookform/resolvers/yup';
import { reportesSchema } from '../../constans/yupSchemas';
import InputFile from '../../components/ui/InputFile';

const ConciliacionSat = () => {
    let loadingFirst = true;
    const dispatch = useDispatch();
    const [request, setRequest] = useState({});
    const [conciliacion, setConcicliacion] = useState('');
    const { empresas, loading, reportes = {} } = useSelector(
        (state) => state.empresas
    );

    const { register, control, reset, getValues } = useForm({
        resolver: yupResolver(reportesSchema)
    });

    const tipos = [
        { value: 'reingresos', label: 'Reingresos' },
        { value: 'bajas', label: 'Bajas' },
        { value: 'cambiosSBC', label: 'Cambios S.B.C' }
    ];

    useEffect(() => {
        (async () => {
            try {
            await dispatch(fetchEmpresasToDropdown());
            loadingFirst = false;
            } catch (error) { }
        })();
    }, [dispatch]);

    const onChange = ({ target }) => {
        if (target.value) setRequest(() => target.value);
    };

    const onSubmit = async () => {
        try {
            const data = getValues();
            data.nombreReporte = 'conciliacion-sat';
            data.requestedData = request;
            } 
        catch (error) {
            console.log(error);
        }
    };

    return (
        <React.Fragment>
            <div style={{ minHeight: '15vh' }}>
                <TitleMenu>Conciliación SAT</TitleMenu>
                <SubTitleMenu style={{ color: '#404041', fontWeight: '400' }}>
                    Concilia tus XML contra el SAT
                </SubTitleMenu>
            </div>
            <Form>
                <FormGroup row>
                    <Label for='empresa' sm={2}>
                        Empresa*
                    </Label>
                    <Col sm={3}>
                        <CustomSelect
                        type='select'
                        name='empresas[0]'
                        id='empresa'
                        control={control}
                        innerRef={register({ required: true })}
                        options={empresas}
                        />
                    </Col>

                    <Label for='inicio' sm={1}>
                        Inicio*
                    </Label>
                    <Col sm={2}>
                        <Input
                        type='date'
                        name='inicio'
                        id='inicio'
                        control={control}
                        innerRef={register({ required: true })}
                        />
                    </Col>

                    <Label for='Fin' sm={1}>
                        Fin*
                    </Label>
                    <Col sm={2}>
                        <Input
                        type='date'
                        name='fin'
                        id='fin'
                        control={control}
                        innerRef={register({ required: true })}
                        />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2} style={{ marginBottom: '12px' }}>
                        <LabelForms>Forma de Conciliación*</LabelForms>
                    </Label>
                    <Col sm={3}>
                        <CustomSelect
                        type='select'
                        name='tipoConciliacion'
                        id='tipoConciliacion'
                        control={control}
                        innerRef={register({ required: true })}
                        options={[
                            {label: 'Archivos XML', value: 'xml'},
                            {label: 'Solicitud PAC', value: 'pac'}
                        ]}
                        onChange={(e) => setConcicliacion(e.target.value)}
                        />
                    </Col>
                    {conciliacion === 'xml' ? (
                        <Col sm={3}>
                            <InputFile name='Adjuntar Archivos' id='comodato' register={register({ required: true })} />
                        </Col>
                    ) : ''}
                    {conciliacion === 'pac' ? (
                        <Col sm={3}>
                            <SaveButton type='button' onClick={() => {}}>
                                Procesar Solicitud
                            </SaveButton>
                        </Col>
                    ) : ''}
                    {conciliacion === 'pac' || conciliacion === 'xml'  ? (
                        <Col sm={3}>
                            <SaveButton type='button' onClick={() => {}}>
                                Comparar
                            </SaveButton>
                        </Col>
                    ) : ''}
                </FormGroup>
            </Form>
            <br />
            <br />
        </React.Fragment>
    );
};

export default ConciliacionSat;