import React, { useCallback, useState, useEffect } from 'react';
import { Col, Row, Label, Form, CustomInput, FormGroup, Input, Table } from "reactstrap";
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'
import SweetAlert from "../ui/SweetAlet";
import Spiner from "../ui/Spiner";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { schemaRol } from "../../constans/yupSchemas";
import ComponentDescrip from "../ui/ComponentDescrip";
import { agregarRolAction } from "../../store/rolesDucks";
import { SaveButton } from '../ui/styles/styles';
import PermisoRol from './PermisoRol';

const AgregarRol = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let { loading } = useSelector(state => state.roles)

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schemaRol)
    })

    const [err, setErr] = useState({});
    useEffect(() => {
        if (Object.keys(errors).length) setErr(errors);
    }, [errors]);

    const crearRol = useCallback(async (nuevoRol) => {
        try {
            await dispatch(agregarRolAction(nuevoRol))
            await SweetAlert({ icon: 'success', title: 'Exito', text: "Rol creado exitosamente" });
            history.push('/dashboard/empresa/rol')
        } catch (e) {
            await SweetAlert({ icon: 'error', title: 'Error!', text: e });
        }
    }, [dispatch])

    const submit = (data) => {
        const { nombreRol, usuarios, sucursales, serviciosVerificacion, serviciosSocioeconomicos, serviciosPsicometrias, serviciosJuicios, roles, reportes,
            reclutamiento, nomina, empresas, departamentos, colaboradores, autorizacionNomina, timbradoNomina, calendario, incidencias, colaboradorPersonales,
            colaboradorLaborales,
            colaboradorNomina,
            colaboradorSimulador,
            colaboradorOrdinarias,
            colaboradorDescuentos,
            colaboradorInfonavit,
            colaboradorUtilitario,
            colaboradorUniforme,
            colaboradorExpediente,
            colaboradorActas,
            colaboradorVacaciones,
            colaboradorPermisos,
            colaboradorIncapacidades,
            colaboradorSalud, imss } = data;

        const nuevoRol = {
            nombreRol: nombreRol,
            permisos: {
                timbradoNomina,
                calendario,
                incidencias,
                autorizacionNomina,
                colaboradores,
                departamentos,
                empresas,
                nomina,
                reclutamiento,
                reportes,
                roles,
                serviciosJuicios,
                serviciosPsicometrias,
                serviciosSocioeconomicos,
                serviciosVerificacion,
                sucursales,
                usuarios,
                colaboradorPersonales,
                colaboradorLaborales,
                colaboradorNomina,
                colaboradorSimulador,
                colaboradorOrdinarias,
                colaboradorDescuentos,
                colaboradorInfonavit,
                colaboradorUtilitario,
                colaboradorUniforme,
                colaboradorExpediente,
                colaboradorActas,
                colaboradorVacaciones,
                colaboradorPermisos,
                colaboradorIncapacidades,
                colaboradorSalud,
                imss
            }
        }
        crearRol(nuevoRol)
    }

    if (loading) return <Spiner />
    return (
        <div>
            <Row style={{ marginBottom: '2em' }}>
                <Col lg={12}>
                    <ComponentDescrip title={"Nuevo Rol"} description={"Agrega aquí el nuevo rol para tu empresa"}
                        icon={faUsers} />
                </Col>
            </Row>
            <Row className='permisos-form'>
                <Col lg={12}>
                    <Row>
                        <Col lg={12}>
                            <Form onSubmit={handleSubmit(submit)}>
                                <FormGroup row style={{ marginBottom: '2em' }}>
                                    <Label style={{ marginLeft: '11px' }}>Rol*</Label>
                                    <Input type="text" name="nombreRol" placeholder={"Nombre del rol"}
                                        innerRef={register()} />
                                    {err.nombreRol && (
                                        <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                                            El nombre del rol es requerido
                                        </span>
                                    )}
                                </FormGroup>

                                <FormGroup row>
                                    <Col sm={3}><h5>Permisos</h5></Col>
                                    <Col sm={3}><h5>Escritura</h5></Col>
                                    <Col sm={3}><h5>Lectura</h5></Col>
                                    <Col sm={3}><h5>Ninguno</h5></Col>

                                </FormGroup>
                                <Table borderless hover responsive autoCorrect='true'>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Empresas
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="empresas1" name="empresas"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="empresas2" name="empresas" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="empresas3" name="empresas" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Sucursales
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="sucursales1" name="sucursales"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="sucursales2" name="sucursales"
                                                value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="sucursales3" name="sucursales"
                                                value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Departamentos
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="departamentos1" name="departamentos"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="departamentos2" name="departamentos"
                                                value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="departamentos3" name="departamentos"
                                                value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Usuarios
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="ususarios1" name="usuarios"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="usuarios2" name="usuarios" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="usuarios3" name="usuarios" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Roles
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="roles1" name="roles" value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="roles2" name="roles" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="roles3" name="roles" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="incidencias" md={3}>
                                            Incidencias
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="incidencias1" name="incidencias"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="incidencias2" name="incidencias"
                                                value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="incidencias3" name="incidencias"
                                                value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Nómina
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="nomina1" name="nomina" value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="nomina2" name="nomina" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="nomina3" name="nomina" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="autorizacionNomina" md={3}>
                                            Autorización Nómina
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="autorizacionNomina1" name="autorizacionNomina"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            {/* Solo escritura */}
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="autorizacionNomina3" name="autorizacionNomina"
                                                value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="timbradoNomina" md={3}>
                                            Timbrado Nómina
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="timbradoNomina1" name="timbradoNomina" value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            {/* Solo escritura */}
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="timbradoNomina3" name="timbradoNomina" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleCheckbox" md={3}>
                                            Reportes
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="reportes1" name="reportes"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="reportes2" name="reportes" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="reportes3" name="reportes" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="calendario" md={3}>
                                            Calendario
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="calendario1" name="calendario"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="calendario2" name="calendario" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="calendario3" name="calendario" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="colaboradores" md={3}>
                                            Colaboradores
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="colaboradores1" name="colaboradores"
                                                value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="colaboradores2" name="colaboradores"
                                                value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="colaboradores3" name="colaboradores"
                                                value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="imss" md={3}>
                                            IMSS
                                    </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="imss1" name="imss" value='write' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="imss2" name="imss" value='read' innerRef={register()} />
                                        </Label>
                                        <Label check md={3}>
                                            <CustomInput type="radio" id="imss3" name="imss" value='none' defaultChecked='true' innerRef={register()} />
                                        </Label>
                                    </FormGroup>

                                    <Row style={{ marginBottom: '2em', marginTop: '2em' }}>
                                        <h5 style={{ marginLeft: '16px' }}>Permisos para el submódulo de colaborador</h5>
                                    </Row>

                                    <PermisoRol nombre='Información Personal' id='colaboradorPersonales' register={register} isNew={true} />
                                    <PermisoRol nombre='Condiciones Laborales' id='colaboradorLaborales' register={register} isNew={true} />
                                    <PermisoRol nombre='Información Nómina' id='colaboradorNomina' register={register} isNew={true} />
                                    <PermisoRol nombre='Simulador Salarial' id='colaboradorSimulador' register={register} isNew={true} />
                                    <PermisoRol nombre='Prestaciones Ordinarias' id='colaboradorOrdinarias' register={register} isNew={true} />
                                    <PermisoRol nombre='Descuentos al Salario' id='colaboradorDescuentos' register={register} isNew={true} />
                                    <PermisoRol nombre='Crédito Infonavit' id='colaboradorInfonavit' register={register} isNew={true} />
                                    <PermisoRol nombre='Utilitario' id='colaboradorUtilitario' register={register} isNew={true} />
                                    <PermisoRol nombre='Uniformes' id='colaboradorUniforme' register={register} isNew={true} />
                                    <PermisoRol nombre='Expediente Digital' id='colaboradorExpediente' register={register} isNew={true} />
                                    <PermisoRol nombre='Actas Administrativas' id='colaboradorActas' register={register} isNew={true} />
                                    <PermisoRol nombre='Vacaciones' id='colaboradorVacaciones' register={register} isNew={true} />
                                    <PermisoRol nombre='Permisos Laborales' id='colaboradorPermisos' register={register} isNew={true} />
                                    <PermisoRol nombre='Incapacidades' id='colaboradorIncapacidades' register={register} isNew={true} />
                                    <PermisoRol nombre='Salud Laboral' id='colaboradorSalud' register={register} isNew={true} />
                                </Table>
                                <FormGroup row>
                                    <SaveButton type='submit' style={{ marginTop: '2em' }} >Guardar</SaveButton>
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default AgregarRol;