import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import './TableCommon.css';
import styled from 'styled-components';
import colors from '../../constans/colors';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from './SweetAlet';
import axios from '../../services/axios';

const TR = styled.tr`
  color: ${colors.tashColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: initial;
  letter-spacing: 0.322917px;

  color: #404041;
`;
const SpanFloat = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  /* identical to box height */
  text-align: justify;
  letter-spacing: 0.27125px;
  color: #464646;
`;

const api = process.env.REACT_APP_API_URL;

const TableCommon = React.memo(({ striped = true, reportes = {}, excludedIds, setExcludedIds }) => {
  const [columns, setColumns] = useState([]);
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    setColumns(reportes.columns);
    setDocs(reportes.docs);
  }, [reportes])

  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  const deleteRegistro = async (id) => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Eliminar y marcar como enviado a IDSE, presione OK. Solo eliminar presione Cancel',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        const { data } = await axios.put(`${api}/colaborador/modificar-colaborador-idse`, { ids: id }, headers)
        console.log(data)
      }
      setExcludedIds([...excludedIds, id])
      setDocs(docs.filter(d => d.id !== id));
    } catch (error) { console.log(error) }
  };

  return (
    <div className='table-containers' style={{ marginTop: '70px' }}>
      <Table className='table-responsiver' borderless hover striped={striped} responsive autoCorrect='true'>
        <thead>
          <tr>
            {columns.map(({ title }) => (
              <th>{title}</th>
            ))}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {docs.map((element) => (
            <tr>
              {columns.map(({ name = '' }) => (
                <td>{element[name]}</td>
              ))}
              <td>
                <FontAwesomeIcon
                  cursor="pointer"
                  onClick={() => deleteRegistro(element.id)}
                  icon={faTrash}
                /></td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/*   <TablePagination /> */}
    </div>
  );
});

export default TableCommon;
