import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPencilAlt } from '@fortawesome/pro-light-svg-icons';
import colors from '../../constans/colors';
const Icons = ({ pencil, ...props }) => {
  return (
    <>
      {pencil ? (
        <FontAwesomeIcon
          color={colors.iconsColors}
          icon={faPencilAlt}
          {...props}
          style={{ fontSize: '25px', cursor: 'pointer' }}
        />
      ) : (
        <FontAwesomeIcon
          color={colors.iconsColors}
          icon={faTrashAlt}
          {...props}
          style={{ fontSize: '25px', cursor: 'pointer' }}
        />
      )}
    </>
  );
};

export default Icons;
