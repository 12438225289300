import React, { useEffect } from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sucursalRuta } from '../../../store/sucursalDuck';
import { fetchListaDepartamentos } from '../../../store/departamentosDucks';
import DetalleSucursal from '../../../components/Sucursal/DetalleSucursal';
import SideMenu from '../../../components/layout/SucursalMenu/SucursalMenu';
import Expediente from '../../../components/Sucursal/Expediente';
import TableDepartamentos from '../../../components/departamento/TableDepartamento';
const HashRouteSucursal = React.memo(({ history }) => {
  const { _id } = useSelector((state) => state.sucursal);
  console.log( history);
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      dispatch(fetchListaDepartamentos(_id, false));
    })();
    return () => {
      const { hash } = history.location;
      if (!hash) dispatch(sucursalRuta({ sucursal: true }));
    };
  }, [dispatch, history, _id]);
const Departamentos=React.memo(()=>{
  return(<TableDepartamentos history={history}/>)
})
  return (
    <HashRouter basename='/'>
      <SideMenu />
      <Switch>
        <Route path='/departamentos' component={Departamentos} />
        <Route path='/expediente' component={Expediente} />
        <Route path='/' component={DetalleSucursal} />
      </Switch>
    </HashRouter>
  );
});

export default HashRouteSucursal;
