import React, { useState } from 'react';
import { Col, Nav, NavItem, Row, NavLink, TabContent, TabPane } from 'reactstrap';
import TableComparacion from './TableComparacion';
import classnames from 'classnames';
import { TableContainer } from '../ui/styles/styles';

const Comparacion = ({ listas, loading, tipoReporte }) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div>
            <Row style={{ marginLeft: 16, marginTop: 20 }}>
                <Col sm={12}>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}>
                                Asegurados Faltantes en la Empresa ({listas.noExisteEmpresa ? listas.noExisteEmpresa.length : '0'})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}>
                                Asegurados Faltantes en Cédula EMA ({listas.noExisteImss ? listas.noExisteImss.length : '0'})
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}>
                                Asegurados Existentes ({listas.existe ? listas.existe.length : '0'})
                            </NavLink>
                        </NavItem>
                        {tipoReporte === "EMA-IMSS" ?
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }}>
                                Diferencias ({listas.diferencias ? listas.diferencias.length : '0'})
                            </NavLink>
                        </NavItem> :''}
                    </Nav>
                </Col>
            </Row>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableComparacion lista={listas.noExisteEmpresa ? listas.noExisteEmpresa : []} />
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableComparacion lista={listas.noExisteImss ? listas.noExisteImss : []}  />
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableComparacion lista={listas.existe ? listas.existe : []} />
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="4">
                    <Row>
                        <Col>
                            <TableContainer style={{ marginLeft: 30, marginTop: 10 }}>
                                <TableComparacion lista={listas.existe ? listas.existe : []} tipoReporte={tipoReporte} />
                            </TableContainer>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </div>
    );
};

export default Comparacion;