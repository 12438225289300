const initialState = {
  loading: false,
  error: null,
  listaFiniquitos: [],
  finiquito: {},
  pago:{}
};

export const FINIQUITO_START = 'FINIQUITO_START';
export const FINIQUITO_LISTA_EXITO = 'FINIQUITO_LISTA_EXITO';
export const FINIQUITO_LISTA_ERROR = 'FINIQUITO_LISTA_ERROR';
export const CREAR_FINIQUITO_EXITO = 'CREAR_FINIQUITO_EXITO';
export const CREAR_FINIQUITO_ERROR = 'CREAR_FINIQUITO_ERROR';
export const GET_FINIQUITO_EXITO = 'GET_FINIQUITO_EXITO';
export const GET_FINIQUITO_ERROR = 'GET_FINIQUITO_ERROR';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FINIQUITO_START:
      return { ...state, loading: true };
    case FINIQUITO_LISTA_EXITO:
      return { ...state, loading: false, listaFiniquitos: [...payload] };
    case FINIQUITO_LISTA_ERROR:
      return { ...state, loading: false };
    case GET_FINIQUITO_EXITO:
      return {...state, loading:false,finiquito:{...payload}};
    case GET_FINIQUITO_ERROR:
      return { ...state, loading: false };
    case CREAR_FINIQUITO_EXITO:
      return { ...state, loading: false };
    case CREAR_FINIQUITO_ERROR:
      return { ...state, loading: false };
    case 'AGREGAR_FINIQUITO':
      return { ...state, finiquito: { ...payload } };
    case 'AGREGAR_PAGO':
      return { ...state, pago: { ...payload } };
    default:
      return state;
  }
};

export const finiquitoStart = () => ({
  type: FINIQUITO_START,
});

export const listaFiniquitoExito = (payload) => ({
  type: FINIQUITO_LISTA_EXITO,
  payload,
});

export const listaFiniquitoError = () => ({
  type: FINIQUITO_LISTA_ERROR,
});

export const getFiniquitoExito = (payload) => ({
  type: GET_FINIQUITO_EXITO,
  payload,
});

export const getFiniquitoError = () => ({
  type: GET_FINIQUITO_ERROR,
});

export const createFiniquitoExito = () => ({
  type: CREAR_FINIQUITO_EXITO,
});

export const createFiniquitoError = () => ({
  type: CREAR_FINIQUITO_ERROR,
});

export const agregarFiniquito = (payload) => ({
  type: 'AGREGAR_FINIQUITO',
  payload,
});

export const agregarPago = (payload) => ({
  type: 'AGREGAR_PAGO',
  payload,
});

export const fetchListaFiniquito = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const {
      data: { resultado = [] },
    } = await axios.get(`/finiquito`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(listaFiniquitoExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(listaFiniquitoError());
    throw data;
  }
};

export const fetchGetFiniquito = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const { _id } = getState().finiquitos.finiquito;
    const {
      data: { resultado = [] },
    } = await axios.get(`/finiquito/finiquito/${_id}`, {
      headers: { token: localStorage.getItem('pv2token') },
    });
    dispatch(getFiniquitoExito(resultado));
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(getFiniquitoError());
    throw data;
  }
};

export const fetchCreateFiniquito = (dataFiniquito) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const {
      convenioFiniquito = {},
      finiquitoLaboral = {},
      ratificacion = {},
      calculoFiniquitoFile={},
      reciboFiniquitoFile={},
      finiquitoLaboralFile={},
      ratificacionFile={},
      otroFile={},
      otros = {},
      ...rest
    } = dataFiniquito;
    const formData = new FormData();
    formData.append('convenioFiniquito', convenioFiniquito[0]);
    formData.append('finiquitoLaboral', finiquitoLaboral[0]);
    formData.append('ratificacion', ratificacion[0]);
    formData.append('calculoFiniquitoFile', calculoFiniquitoFile[0]);
    formData.append('reciboFiniquitoFile',reciboFiniquitoFile[0]);
    formData.append('finiquitoLaboralFile',finiquitoLaboralFile[0]);
    formData.append('ratificacionFile',ratificacionFile[0]);
    formData.append('otroFile',otroFile[0]);
    formData.append('otros', otros[0]);
    formData.append(
      'dataFiniquito',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.post('/finiquito', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(resultado);
    dispatch(createFiniquitoExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createFiniquitoError());
    throw data;
  }
};

export const fetchUpdateFiniquito = (dataFiniquito) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const { _id } = getState().finiquitos.finiquito;
    const {
      convenioFiniquito = {},
      finiquitoLaboral = {},
      ratificacion = {},
      calculoFiniquitoFile={},
      reciboFiniquitoFile={},
      finiquitoLaboralFile={},
      ratificacionFile={},
      otroFile={},
      otros = {},
      ...rest
    } = dataFiniquito;
    const formData = new FormData();
    formData.append('convenioFiniquito', convenioFiniquito[0]);
    formData.append('finiquitoLaboral', finiquitoLaboral[0]);
    formData.append('ratificacion', ratificacion[0]);
    formData.append('calculoFiniquitoFile', calculoFiniquitoFile[0]);
    formData.append('reciboFiniquitoFile',reciboFiniquitoFile[0]);
    formData.append('finiquitoLaboralFile',finiquitoLaboralFile[0]);
    formData.append('ratificacionFile',ratificacionFile[0]);
    formData.append('otroFile',otroFile[0]);
    formData.append('otros', otros[0]);
    formData.append(
      'dataFiniquito',
      JSON.stringify({ ...rest})
    );
    const {
      data: { resultado },
    } = await axios.put(`/finiquito/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(createFiniquitoExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createFiniquitoError());
    throw data;
  }
};

export const deleteFiniquito = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const { _id } = getState().finiquitos.finiquito;
    await axios.delete(`/finiquito/${_id}`, {
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(createFiniquitoExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createFiniquitoError());
    throw data;
  }
};

export const updateConvenio = (dataConvenio) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const { _id } = getState().finiquitos.finiquito;
    await axios.put(`/finiquito/convenio/${_id}`,dataConvenio, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'application/json',
      },
    });
    dispatch(createFiniquitoExito());
  } catch (e) {
    const { response } = e;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(createFiniquitoError());
    throw data;
  }
};

export const changeStatus = (dataFiniquito) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    dispatch(finiquitoStart());
    const { _id } = getState().finiquitos.finiquito;
    await axios.put(`/finiquito/estatus/${_id}`, dataFiniquito,{
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'application/json',
      },
    });
    dispatch(createFiniquitoExito());
  } catch (e) {
    console.log(e);
  }
};

export const buscarFiniquito = (searchText, filter = "") => async (dispatch, getState, { axios }) => {
  console.log(searchText);
  console.log(filter);
  if (searchText == "") {
    fetchListaFiniquito(filter)(dispatch, getState, { axios });
  } else if (filter === "") {
    const lista = getState().finiquitos.listaFiniquitos.filter(
      (c) =>
        c.colaborador.informacionPersonal.nombre.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(listaFiniquitoExito(lista));
  } else {
    const lista = getState().finiquitos.listaFiniquitos.filter(
      (c) =>
        c.colaborador.informacionPersonal.nombre.toLowerCase().includes(searchText.toLowerCase())
    );
    dispatch(listaFiniquitoExito(lista));
  }
};
