import styled from 'styled-components';

export const ExamLabel = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 0.9rem;
  color: #2B388F;
  line-height: 59.77px;
  text-align: justify;
  margin-top: -2.5rem;
  margin-left: 1rem;
`;

export const Label = styled.h3`
font-family: 'Roboto', sans-serif;
font-weight: 900;
font-style: normal;
font-size: 0.9rem;
color: #3C3C3C;
line-height: 59.77px;
text-align: justify;
margin-top: -2.5rem;
margin-left: 1rem;
`;

export const BlueButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.258333px;
  color: #ffffff;
  height: 37px;
  left: 100%;
  top: 100%;
  background: #2B388F;
  border-radius: 2px;
  text-align: center;
  border: none;
  margin-left: -30rem;
  border-radius: 0.7rem;
  width: 9rem;
  margin-top: -4rem;
`;

export const BlueSave = styled.button`
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 19px;
    text-align: center;
    -webkit-letter-spacing: 0.258333px;
    -moz-letter-spacing: 0.258333px;
    -ms-letter-spacing: 0.258333px;
    letter-spacing: 0.258333px;
    color: #ffffff;
    height: 41px;
    left: 100%;
    top: 100%;
    background: #2B388F;
    border-radius: 2px;
    text-align: center;
    border: none !important;
    margin-left: 13rem;
    border-radius: 0.7rem;
    width: 9rem;
    margin-top: -4rem;
    ;`

export const WhiteButton = styled.button`
font-family: 'Roboto',sans-serif;
font-style: normal;
font-weight: 200;
font-size: 19px;
text-align: center;
color: #2B388F;
height: 41px;
left: 100%;
top: 100%;
background: #ffffff;
border-radius: 2px;
text-align: center;
border-color: #2B388F;
margin-left: 20rem;
border-radius: 0.7rem;
width: 9rem;
margin-top: -4rem;
;`


    