import React from 'react';
import { Stage, Layer, Line, Rect, Text, Arc } from 'react-konva';
import { scaleBand } from 'd3';
import _ from 'lodash';

const categories = ['Mujer', 'Hombre'];

const scaleFactory = w => scaleBand().domain(categories).range([0, w]).round(true);

const xCenters = scale => categories.map(c => scale(c) + scale.bandwidth() / 2);

const yCenters = h => [h * 0.1, h * 0.5, h * 0.8];

const agrupar = (coll, prop) => _.groupBy(coll, prop);

const porcentaje = (coll, gpos) => i => (100 * gpos[categories[i]].length / coll.length)

const GenderMetric = ({ colors, width, height, colaboradores }) => {
    const scale = scaleFactory(width);
    const centersX = xCenters(scale);
    const centersY = yCenters(height);
    const grupos = agrupar(colaboradores, 'sexo')
    const fnPorc = porcentaje(colaboradores, grupos)

    return (
        <Stage width={width} height={height}>
            {
                categories.map((c, i) => (
                    <Layer key={i}>
                        <Rect
                            x={i * scale.step()}
                            y={0}
                            width={scale.bandwidth()}
                            height={height}
                        />
                        <Line
                            x={centersX[i]}
                            y={0}
                            points={[0, 0, 0, height]}
                            // stroke="black"
                        />

                        <Arc
                            x={centersX[i]}
                            y={centersY[1]}
                            width={scale.bandwidth()}
                            height={scale.bandwidth()}
                            outerRadius={(scale.bandwidth() - 60) / 2}
                            innerRadius={((scale.bandwidth() - 60) / 2) - 20}
                            angle={(fnPorc(i) / 100) * 360}
                            fill={i === 0 ? colors.primary[4] : colors.primary[2]}
                            rotation={-90}
                        />

                        <Text
                            x={i * scale.step()}
                            y={centersY[0]}
                            text={fnPorc(i).toFixed() + '%'}
                            fontSize={scale.bandwidth() * 0.15}
                            width={scale.bandwidth()}
                            fill="#8E8883"
                            align='center'
                        />
                        <Text
                            x={i * scale.step()}
                            y={centersY[1] - scale.bandwidth() * 0.10}
                            text={grupos[categories[i]].length}
                            fontSize={40}
                            width={scale.bandwidth()}
                            fill='#635F5D'
                            align='center'
                            fontStyle="bold"
                        />
                        <Text
                            x={i * scale.step()}
                            y={centersY[2]}
                            text={categories[i]}
                            fontSize={scale.bandwidth() * 0.25}
                            width={scale.bandwidth()}
                            fill="#8E8883"
                            align='center'
                        />
                    </Layer>
                ))
            }
        </Stage>
    )
}

export default GenderMetric;
