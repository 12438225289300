import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal, addEvent, fetchEvents } from '../../store/calendarioDuck';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LightButton, DarkButton } from '../../components/ui/styles/styles';
import './CalendarioAddModal.css';

import AddEventForm from './AddEventForm';

const CalendarioAddModal = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.calendario);

    const toggle = () => dispatch(toggleModal());

    const sendForm = () =>{
        toggle();
        dispatch(addEvent());
    }

    return (
        <div>
            <LightButton type='button' onClick={toggle}>
                {buttonLabel}
            </LightButton>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}><b>Agregar</b> Evento</ModalHeader>
                <ModalBody>
                    <AddEventForm className='calendar-form' toggle={toggle} handleSubmit={dispatch(fetchEvents)}/>
                </ModalBody>
                <ModalFooter>
                    <div className='row' style={{width:'155px'}}>
                        <DarkButton color="primary" onClick={sendForm}>Guardar</DarkButton>
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CalendarioAddModal;