import { faArrowAltLeft, faArrowAltRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import 'moment/locale/es-mx';
import React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { LabelForms } from '../../ui/styles/styles';
import { LightMiniButton } from '../../ui/styles/styles';

const SemanaControl = ({ width, date, setDate }) => {

    const atrasarSemana = () => setDate(moment(date).subtract(1, 'isoWeek'));
    const adelantarSemana = () => setDate(moment(date).add(1, 'isoWeek'));

    return (
        <Col sm={width}>
            <Row>
                <div style={{ marginRight: '10px' }}>
                    <LightMiniButton onClick={atrasarSemana}><FontAwesomeIcon icon={faArrowAltLeft} /></LightMiniButton>
                    <LightMiniButton onClick={adelantarSemana}><FontAwesomeIcon icon={faArrowAltRight} /></LightMiniButton>
                </div>
                <LabelForms>Semana {date.week()} de {date.startOf('isoWeek').format('YYYY')}: del {date.startOf('isoWeek').format('DD-MMM')} al {date.endOf('isoWeek').format('DD-MMM')}</LabelForms>
            </Row>
        </Col>
    )
}
export default SemanaControl