import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { agregarEmpresaSucess } from '../../store/empresasDuck';
import { Tr, BackgroundImage } from '../ui/styles/styles';
import Icons from '../ui/Icons';
import yisus from '../../assets/img/yisus.jpg';

const TableBodyEmpresas = React.memo(({ listaEmpresas = [] }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const EditEmpresas = useCallback((empresa) => {
    dispatch(agregarEmpresaSucess(empresa));
    history.push('detalle-empresa')

  }, [dispatch, history]);
  return (
    <>
      {listaEmpresas.map(({ logotipo, nombre_empresa }, index, empresas) => (
        <Tr
          key={nombre_empresa + index}
          onClick={() => EditEmpresas(empresas[index])}
          style={{ cursor: 'pointer' }}
        >
          <td style={{ width: '59px' }}>
            <BackgroundImage
              imageUrl={
                logotipo
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
                  : yisus
              }
            />
          </td>
          <td className='span-label'>{nombre_empresa}</td>
          <td className='middle' style={{ textAlign: 'center', padding: 0, verticalAlign: 'middle' }}>
            <Icons pencil />
          </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBodyEmpresas;
