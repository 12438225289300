import React from 'react';
import { Stage, Layer, Rect, Text, Group } from 'react-konva';
import _ from 'lodash';
import { range } from 'd3-array';

const agrupar = (coll, prop) => {
    coll.map(m => m.month = new Date(m[prop]).getMonth());
    return _.groupBy(coll, 'month')
};

const CalendarPlot = ({ colors, width, height, filtro, colaboradores }) => {
    const grupos = agrupar(colaboradores, filtro);

    console.log(grupos);
    return (
        <Stage width={width} height={height}>
            <Layer>
                <Group x={10} y={15}>
                    {
                        range(12).map((mes, i) => (
                            <Group key={i}>
                                <Rect
                                    key={i}
                                    x={(i % 4) * ((width - 20) / 4)}
                                    y={Math.floor(i / 4) * ((height - 30) / 3)}
                                    width={(width - 20) / 4}
                                    height={(height - 30) / 3}
                                    fill={mes === new Date().getMonth() ? '#FFFADE' : '#FFFFFF'}
                                    cornerRadius={5}
                                // stroke={colors.secondary.textPrimary}
                                />
                                <Text
                                    x={(i % 4) * ((width - 20) / 4)}
                                    y={Math.floor(i / 4) * ((height - 30) / 3) + 10}
                                    width={(width - 20) / 4}
                                    verticalAlign="center"
                                    align="center"
                                    text={months[i]}
                                    fill={colors.primary[0]}
                                    fontSize={18}
                                />
                                <Text
                                    x={(i % 4) * ((width - 20) / 4)}
                                    y={Math.floor(i / 4) * ((height - 30) / 3) + 40}
                                    width={(width - 20) / 4}
                                    align="center"
                                    text={grupos[i] ? grupos[i].length : 0}
                                    fill={colors.secondary.textPrimary}
                                    fontSize={30}
                                />
                            </Group>
                        ))
                    }
                </Group>
            </Layer>
        </Stage>
    )
}

const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

export default CalendarPlot;
