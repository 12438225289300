import styled from 'styled-components';

export const BackgroundImageDetail = styled.div`
  width: 248px;
  margin-left:30px;
  height: 248px;
  border-radius: 426px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
`;

export const BackgroundImage = styled.div`
  width: 11vw;
  height: 150px;
  min-height: 11vw;
  border-radius: 426px;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 400ms ease;
  background-image: url(${(props) => props.imageUrl});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: width 2s;
`;

export const Nombre = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 33px;
  color: #3c3c3c;
  line-height: 33px;
  text-align: justify;
  margin-top: 0px;
  margin-left: 0px;
`;

export const JobPosition = styled.h3`
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  line-height: 26px;
  text-align: justify;
  letter-spacing: 0.284167px;
  color: #2b388f;
`;

export const InfoHeader = styled.h6`
  font-family: 'Roboto', sans-serif;
  font-weight: 607px;
  font-style: normal;
  font-size: 22px;
  color: #3c3c3c;
  line-height: 26px;
  text-align: justify;
  margin-top: 0px;
  margin-left: 0px;
  font-weight: 600;
`;

export const InfoHeaderGrey = styled.h6`
  font-size: 24px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.31px;
  font-weight: 600;
  color: #B1B1B1;
`;

export const InfoHeaderBlue = styled.h6`
  font-size: 22px;
  line-height: 28px;
  text-align: justify;
  letter-spacing: 0.31px;
  color: #2b388f;
`;

export const BlueButton = styled.button`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 0.258333px;
  color: #ffffff;
  height: 37px;
  left: 100%;
  top: 100%;
  background: #2B388F;
  border-radius: 4px;
  text-align: center;
  border: none;
  margin-left: 1rem;
  border-radius: 0.7rem;
  width: 9rem;
  margin-top: -4rem;

  
 /*  &:hover{
    color:#2B388F;
    background-color:white;
    border: #2B388F 1px solid;
  } */
`;

export const SectionColaborator = styled.section`
  max-height: 30vh;
  .colaborador-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'picture description description description description'
      'picture info info info info';
  }
  .description {
    margin-top: 15px;
    margin-left: 30px;
  }
`;
