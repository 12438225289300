import currency from 'currency.js';

const calcularExento = (monto, antiguedad, UMA) => Math.min(monto, 90 * UMA * antiguedad);

const clasificarMonto = (monto, antiguedad, UMA) => {
    const montoExento = currency(calcularExento(monto, antiguedad, UMA)).value;
    const montoGravado = currency(monto).subtract(montoExento).value;
    return {montoTotal: monto, montoGravado, montoExento};
}

const impuestoUltimoSueldo = (sd, tasas) => {
    const sueldoMensual = currency(sd).multiply(365 / 12).value;
    const tasa = tasas.find(t => t.limiteInferior <= sueldoMensual && sueldoMensual <= (t.limiteSuperior || Infinity));
    const isrUSMO =  currency(tasa.cuotaFija).add((sueldoMensual - tasa.limiteInferior) * tasa.porcentaje).value;
    return {factor: isrUSMO / sueldoMensual, baseFactorISR: {isrUSMO, sueldoMensual}}
}

export const obtenerIsrSeparacion = (sd, antiguedad, percepciones, UMA, tasaMensual) => {
    const montoPercepciones = percepciones.reduce((t, p) => t.add(p.monto), currency(0)).value;
    const base = clasificarMonto(montoPercepciones, antiguedad, UMA);
    const {factor, baseFactorISR} = impuestoUltimoSueldo(sd, tasaMensual);
    const retencion = currency(base.montoGravado).multiply(factor).value;
    return {
        codigo: '002',
        clave: '002',
        descripcion: 'ISR (SEP)',
        tipo: 'Extraordinaria',
        monto: retencion,
        detalle: {
            totalPercepciones: base.montoTotal,
            totalGravado: base.montoGravado,
            totalExento: base.montoExento,
            baseISR: {tasa: `${(factor * 100).toFixed(2)}%`, factor, baseFactorISR}
        }
    }
}

