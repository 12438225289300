import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt,faCheck } from '@fortawesome/pro-solid-svg-icons';
import './InputFile.css';
import colors from '../../constans/colors';
const InputFile = React.memo(
  ({
    fileDescription = 'Adjuntar',
    register = () => { },
    getFile = () => { },
    ...rest
  }) => {
    const [fileName, setFileName] = useState('');
    const [status, setStatus] = useState(true);
    const [file, setFile] = useState([]);
    const handleChange = ({ target }) => {
      setFileName(target.files[0].name);
      setStatus(false);
      setFile(() => target.files);
    };
    useEffect(() => {
      getFile(file);
    }, [file, getFile]);
    return (
      <React.Fragment>
        <label
          className={rest.disabled ? 'labelDisabled' : 'label'}
          htmlFor={rest.id ? rest.id : rest.name}
          style={{ width: '174px', height: '51px', border: { ...rest.disabled ? '#ccc' : '1px solid #2B388F' }, borderRadius: '4px' }}
        >
          <FontAwesomeIcon
            id='svg'
            color={!rest.disabled ? colors.secondary : colors.primary}
            icon={status ? faCloudUploadAlt : faCheck}
            style={{
              fontSize: '1.5rem', color: {
                ...rest.disabled ? '#white' : '#2B388F'
              }
            }}
          />

          < span className={rest.disabled ? 'titleDisabled' : 'title'} > {fileDescription}</span>
          <input
            ref={register ? register : ''}
            type='file'
            {...rest}
            onChange={rest.onChange ? rest.onChange : handleChange}
          />
        </label>
        <div style={{width: '174px',fontSize:9,color:"#3C3C3C",fontFamily:'monospace'}}>{fileName}</div>
      </React.Fragment >
    );
  }
);

export default InputFile;
