const estadoCivilOptions = [
  { label: 'Soltero', value: 'Soltero' },
  { label: 'Casado', value: 'Casado' },
  { label: 'Divorciado', value: 'Divorciado' },
  {
    label: 'Separación en proceso judicial',
    value: 'Separación en proceso judicial',
  },
  { label: 'Viudo', value: 'Viudo' },
  { label: 'Concubinato', value: 'Concubinato' },
];

export default estadoCivilOptions;
